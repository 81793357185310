import React from 'react';
import styled from 'styled-components';

import EmployersFooter from 'components/EmployersFooter';

import StatusBar from '../components/insightsBar/StatusBar';
import Dashboard from '../components/InsightsDashboard';

const Insights = () => {
  return (
    <>
      <Container>
        <StatusBar />
        <Dashboard />
      </Container>
      <FooterWrapper>
        <EmployersFooter />
      </FooterWrapper>
    </>
  );
};

const FooterWrapper = styled.div`
  margin-top: 83px;
`;

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Insights;
