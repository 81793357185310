import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AdminLogin from 'modules/auth/pages/AdminLogin';
import { AdminRoutes, BaseRoutes } from 'modules/router/models';

import AdminRoute from './AdminRoute';
import EmployersRoute from './EmployersRoute';

const RouterContainer: React.FC = () => (
  <Routes>
    <Route
      path={BaseRoutes.Base}
      element={<Navigate to={AdminRoutes.Accounts} replace />}
    />
    <Route path={BaseRoutes.Login} element={<AdminLogin />} />
    <Route path="admin/*" element={<AdminRoute />} />
    <Route path="employers/*" element={<EmployersRoute />} />

    <Route path="*" element={<Navigate to={BaseRoutes.Login} replace />} />
  </Routes>
);

export default RouterContainer;
