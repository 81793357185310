/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import DefaultButton from 'components/buttons/DefaultButton';
import HidePasswordIcon from 'components/icons/HidePasswordIcon';
import ShowPasswordIcon from 'components/icons/ShowPasswordIcon';

import PasswordBars from '../components/PasswordBars';
import {
  ContinueButtonContainer,
  FormContainer,
  FormInput,
  FormLabel,
  InputContainer,
} from '../components/styledElements/formsStyledElements';

type PasswordProps = {
  isEmployer?: boolean;
  inputId: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  isValid: boolean;
  watch: UseFormWatch<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  passPasswordToValidate: (password: string) => void;
};

const PasswordFormTemplate = ({
  inputId,
  register,
  setValue,
  isValid,
  watch,
  handleSubmit,
  passPasswordToValidate,
}: PasswordProps) => {
  const [active, setActive] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const passwordValue = watch()[inputId];

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(inputId, value, { shouldValidate: true });
  };

  const formSubmit = () => {
    passPasswordToValidate(passwordValue);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <FormContainer onSubmit={handleSubmit(formSubmit)}>
      <InputContainer>
        <FormLabel htmlFor={inputId} $active={active}>
          Enter password
        </FormLabel>
        <FormInput
          id={inputId}
          type={isPasswordVisible ? 'password' : 'text'}
          autoComplete="off"
          {...register(inputId, {
            required: true,
          })}
          onFocus={() => setActive(true)}
          onChange={changeInputHandler}
        />
        <ActionButtonContainer>
          <ActionButton
            icon={
              isPasswordVisible ? (
                <HidePasswordIcon color={theme.colors.funtainBlue} />
              ) : (
                <ShowPasswordIcon color={theme.colors.funtainBlue} />
              )
            }
            clickHandler={togglePasswordVisibility}
          />
        </ActionButtonContainer>
      </InputContainer>
      <PasswordBars passwordValue={passwordValue} active={active} />
      <ContinueButtonContainer>
        <DefaultButton
          isSubmit
          withShadow
          borderSize="lg"
          buttonSize="lg"
          text="continue"
          isActive={isValid}
          isUppercase
          textColor={theme.colors.white}
          buttonColor={
            isValid ? theme.colors.funtainBlue : theme.colors.quillGray
          }
          clickHandler={() => handleSubmit(formSubmit)}
        />
      </ContinueButtonContainer>
    </FormContainer>
  );
};

const ActionButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
`;

export default PasswordFormTemplate;
