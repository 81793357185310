import config from 'config';
import React from 'react';
import styled from 'styled-components';

import AppstoreIcon from 'components/icons/AppstoreIcon';
import Arrow from 'components/icons/Arrow';
import { LogoIcon } from 'components/icons/LogoIcon';

import ShidoIsTheBestAssistantSection from './components/ShidoIsTheBestAssistantSection';
import custom from './custom.png';
import BackgroundGraph from './icons/BackgroundGraph';
import MobileBackground from './icons/MobileBackground';

type MobilePreviewProps = {
  image: string;
  title: string;
  subtitle: string;
  bodyText: string;
  term?: string;
  chosenColor: {
    light: string;
    strong: string;
  };
};

const MobilePreview = ({
  image,
  title,
  subtitle,
  bodyText,
  term,
  chosenColor,
}: MobilePreviewProps) => (
  <Container $color={chosenColor.light}>
    <Header>
      <ShidoIconWrapper>
        <LogoIcon width={61} height={37} />
        <HeaderTitle>
          <ShidoText>shido</ShidoText>
          <HeaderMark>®</HeaderMark>
        </HeaderTitle>
      </ShidoIconWrapper>
    </Header>
    <Title>{title ? title : `Reduce your stress and anxiety naturally.`}</Title>
    <FreeTrial>Start a free {term} trial</FreeTrial>
    <MobileBackground color={chosenColor.strong} />
    <DownloadButtonWrapper>
      <PromoButton target="_blank" href={config.appStoreLink}>
        <AppstoreIcon color="white" />
        <ButtonInfoWrapper>
          <p>DOWNLOAD ON</p>
          <span>App Store</span>
        </ButtonInfoWrapper>
      </PromoButton>
    </DownloadButtonWrapper>
    <ArrowIconWrapper>
      <Arrow />
    </ArrowIconWrapper>
    <DashedLine />
    <Description $color={chosenColor.strong}>
      <div>
        <FirstParagraph>
          {subtitle
            ? subtitle
            : `Shido is a new app that will change how you approach your life - for
          good.`}
        </FirstParagraph>
        <SecondParagraph>
          {bodyText
            ? bodyText
            : `Based on the teachings of expert nutritionists, coaches & fitness
          experts, Shido will allow you to transform & live a life free from
          stress & anxiety.`}
        </SecondParagraph>
      </div>
      <CustomImage src={image ? image : custom} />
      <ArrowIconWrapper>
        <Arrow />
      </ArrowIconWrapper>
      <DashedLine />
    </Description>
    <Carousel $color={chosenColor.strong}>
      <ShidoIsTheBestAssistantSectionWrapper>
        <ShidoIsTheBestAssistantSection isMobile />
      </ShidoIsTheBestAssistantSectionWrapper>
    </Carousel>
    <Footer $color={chosenColor.strong}>
      <FooterTitle>Start a free 7 day trial!</FooterTitle>
      <InstallText>Install the app to continue</InstallText>
      <DownloadButtonWrapperFooter>
        <PromoButton target="_blank" href={config.appStoreLink}>
          <AppstoreIcon color="white" />
          <ButtonInfoWrapper>
            <p>DOWNLOAD ON</p>
            <span>App Store</span>
          </ButtonInfoWrapper>
        </PromoButton>
      </DownloadButtonWrapperFooter>
      <StyledShidoIconWrapper>
        <ShidoTitle>
          <LogoIcon width={48} height={28} />
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </StyledShidoIconWrapper>
    </Footer>
    <BackgroundGraph color={chosenColor.light} />
  </Container>
);

const HeaderMark = styled.div`
  right: -14px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 0;
`;

const HeaderTitle = styled.div`
  position: relative;
  top: -3px;
  left: 2px;
`;

const StyledShidoIconWrapper = styled.div`
  margin: 40px auto;
  width: 70px;
  position: relative;
  z-index: 2;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const InstallText = styled.div`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 300;
  text-align: center;
  margin-top: 36px;
`;

const FooterTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  padding-top: 36px;
`;

const Footer = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  padding-bottom: 89px;
  display: block;
`;

const Carousel = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
`;

const ShidoIsTheBestAssistantSectionWrapper = styled.div`
  max-width: 100%;
  margin: auto;
  overflow: hidden;
`;

const Description = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
`;

const CustomImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 36px;
`;

const SecondParagraph = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 300;
  margin-top: 23px;
  line-height: 25px;
  padding-left: 32px;
  padding-right: 28px;
  position: relative;
  right: 2px;
  word-break: break-word;
`;

const FirstParagraph = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  line-height: 30px;
  padding-top: 33px;
  padding-left: 54px;
  padding-right: 62px;
  word-break: break-word;
`;

const DashedLine = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px dashed rgb(216 218 224);
  margin-top: 41px;
`;

const ArrowIconWrapper = styled.div`
  margin-top: 21px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const ButtonInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;

  p {
    font-size: 10px;
    font-weight: 400;
    color: white;
  }

  span {
    font-size: 14px;
    color: white;
  }
`;

const DownloadButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const DownloadButtonWrapperFooter = styled(DownloadButtonWrapper)`
  top: -3px;
`;

const PromoButton = styled.a`
  width: 158px;
  height: 50px;
  text-decoration: none;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.funtainBlue};
  border: 2px solid white;
  color: white;
  box-shadow: 0 6px 8px -3px #bebaba;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 13px;
  cursor: pointer;
`;

const FreeTrial = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-weight: 300;
  margin-top: 31px;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 37px;
  line-height: 36px;
  word-break: break-word;
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: -2px;
  right: 3px;
`;

const ShidoTitle = styled.div`
  position: relative;
  left: 10px;
  top: 0;
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 39px;
  font-weight: 700;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 17px;
  position: relative;
  left: -8px;

  svg {
    left: 2px;
    position: relative;
    top: 2px;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const Container = styled.div<{ $color: string }>`
  width: 375px;
  margin: 80px auto 78px;
  box-shadow: 0 3px 58px -20px grey;
  padding-top: 133px;
  position: relative;
  overflow: hidden;
  background-color: ${({ $color }) => $color};

  svg.mobile-background {
    position: absolute;
    margin-top: 16px;
  }

  svg.background-graph {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -110px;
    width: 749px;
    height: 465px;
  }
`;

export default MobilePreview;
