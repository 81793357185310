import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import SearchBar from 'components/searchBar/SearchBar';
import { STANDARD_PAGE_SIZE } from 'components/table/models';

import OnboardingOffersMockData from '../../OnboardingOffersMockData';

import LandingTable from './LandingTable';

type LandingProps = {
  chooseActiveItem: (i: number) => void;
  activeNavigationItem: string;
};

const Landing = ({ chooseActiveItem, activeNavigationItem }: LandingProps) => {
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);
  const [sortByListItemIndex, setSortByListItemIndex] = useState(1);
  const [pageSize, setPageSize] = useState(STANDARD_PAGE_SIZE);
  const [nameForSearching, setNameForSearching] = useState('');

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
  }, [isCreatingModalOpen]);

  return (
    <Container>
      <SearchBar
        isEmployerPage
        isHiddenButton
        title="ARNOLD CLARK"
        buttonText="Create account"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter={nameForSearching}
        setNewNameToFilter={setNameForSearching}
      />
      <LandingTable
        activeNavigationItem={activeNavigationItem}
        chooseActiveItem={chooseActiveItem}
        sortByListItemIndex={sortByListItemIndex}
        sortUsers={setSortByListItemIndex}
        data={OnboardingOffersMockData}
        pageSize={pageSize}
        changePageSize={setPageSize}
        dataAmount={OnboardingOffersMockData.length}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  box-sizing: border-box;
`;

export default Landing;
