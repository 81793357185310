import React from 'react';

type CalendarIconProps = {
  color: string;
};

const CalendarIcon = ({ color }: CalendarIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23"
    height="24"
    viewBox="0 0 23 24"
  >
    <defs>
      <clipPath id="calendar-clip-path">
        <path
          id="Path_6900"
          data-name="Path 6900"
          d="M21.5-21H18.75a.25.25,0,0,1-.25-.25V-23a1,1,0,0,0-1-1,1,1,0,0,0-1,1v4.75a.75.75,0,0,1-.75.75h0a.75.75,0,0,1-.75-.75V-20.5a.5.5,0,0,0-.5-.5H8.25A.25.25,0,0,1,8-21.249V-23a1,1,0,0,0-1-1,1,1,0,0,0-1,1v4.75a.75.75,0,0,1-.75.75h0a.75.75,0,0,1-.75-.75V-20.5A.5.5,0,0,0,4-21H2.5a2,2,0,0,0-2,2V-2a2,2,0,0,0,2,2h19a2,2,0,0,0,2-2V-19A2,2,0,0,0,21.5-21ZM21-2H3a.5.5,0,0,1-.5-.5v-12A.5.5,0,0,1,3-15H21a.5.5,0,0,1,.5.5v12A.5.5,0,0,1,21-2Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g id="Group_10904" data-name="Group 10904" transform="translate(-0.5 24)">
      <g
        id="Group_6216"
        data-name="Group 6216"
        clipPath="url(#calendar-clip-path)"
      >
        <path
          id="Path_6899"
          data-name="Path 6899"
          d="M-4.5-29h33V5h-33Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
