import { LocalStorageName } from 'components/actionModal/models';
import {
  CorporateAccountDetailsData,
  CorporatePrimaryContactData,
  ReferrerAccountDetailsData,
} from 'modules/accounts/models';
import {
  CorporateSettings,
  CorporateOfferAccountId,
  OfferCodePage,
  PromotionOfferData,
  PromotionOfferCodes,
} from 'modules/offers/models';

type Data =
  | PromotionOfferCodes
  | PromotionOfferData
  | OfferCodePage
  | CorporateSettings
  | CorporateOfferAccountId
  | ReferrerAccountDetailsData
  | CorporateAccountDetailsData
  | CorporatePrimaryContactData;

export const saveDataToLocalStorage = (name: LocalStorageName, data: Data) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const cleanAccountLocalStorage = () => {
  localStorage.removeItem(LocalStorageName.PrimaryContact);
  localStorage.removeItem(LocalStorageName.CorporateAccountDetails);
  localStorage.removeItem(LocalStorageName.ReferrerAccountDetails);
};

export const cleanOfferLocalStorage = () => {
  localStorage.removeItem(LocalStorageName.AccountId);
  localStorage.removeItem(LocalStorageName.CorporateSettings);
  localStorage.removeItem(LocalStorageName.CorporateOfferCodePage);
  localStorage.removeItem(LocalStorageName.PromotionOfferCodePage);
  localStorage.removeItem(LocalStorageName.PromotionSettings);
  localStorage.removeItem(LocalStorageName.PromotionOfferCodes);
};
