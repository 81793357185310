import React, { useCallback, useState } from 'react';

import ActionModal from 'components/actionModal/ActionModal';
import CorporateIcon from 'components/icons/CorporateIcon';
import ReferrerIcon from 'components/icons/ReferrerIcon';

import CorporateAccountContextProvider from '../context/CorporateAccountContext';
import { AccountCategory, AccountModalWindowStages } from '../models';

import CreateCorporateModal from './corporateAccounts/CreateCorporateModal';
import CreateReferrerModal from './referrerAccounts/CreateReferrerModal';

export const dataForSelectingCategory = [
  {
    name: 'Corporate account',
    text: 'Organisations with prearranged sales agreements to onboard multiple users.',
    category: AccountCategory.Corporate,
    icon: <CorporateIcon />,
  },
  {
    name: 'Referrer',
    text: 'Individuals or entities who receive a commission for each subscriber they refer.',
    category: AccountCategory.Referrer,
    icon: <ReferrerIcon />,
  },
];

type CreateAccountModalProps = {
  closeModalWindow: () => void;
};

const CreateAccountModal = ({ closeModalWindow }: CreateAccountModalProps) => {
  const [isNavbarTitleShown, setIsNavbarTitleShown] = useState(false);
  const [activeCategory, setActiveCategory] = useState(
    AccountCategory.Corporate
  );
  const [activatedCorporateStages, setActivatedCorporateStages] = useState([
    AccountModalWindowStages.SelectType,
  ]);
  const [activatedReferrerStages, setActivatedReferrerStages] = useState([
    AccountModalWindowStages.SelectType,
  ]);

  const showNavbarTitle = useCallback(
    (value: boolean) => setIsNavbarTitleShown(value),
    []
  );

  const changeActiveCategory = useCallback(
    (category: AccountCategory) => setActiveCategory(category),
    []
  );

  const addNewStageToActivatedCorporate = useCallback(
    (stage: AccountModalWindowStages, disable?: boolean) => {
      if (disable) {
        const arrayWithoutContactStage = activatedCorporateStages.filter(
          (item) => item !== AccountModalWindowStages.PrimaryContact
        );

        setActivatedCorporateStages(arrayWithoutContactStage);

        return;
      }
      if (!activatedCorporateStages.includes(stage)) {
        setActivatedCorporateStages([...activatedCorporateStages, stage]);
      }
    },
    [activatedCorporateStages]
  );

  const addNewStageToActivatedReferrer = useCallback(
    (stage: AccountModalWindowStages) => {
      if (!activatedReferrerStages.includes(stage)) {
        setActivatedReferrerStages([...activatedReferrerStages, stage]);
      }
    },
    [activatedReferrerStages]
  );

  return (
    <CorporateAccountContextProvider>
      <ActionModal closeModalWindow={closeModalWindow}>
        {activeCategory === AccountCategory.Corporate ? (
          <CreateCorporateModal
            isNavbarTitleShown={isNavbarTitleShown}
            activatedStages={activatedCorporateStages}
            closeModalWindow={closeModalWindow}
            showNavbarTitle={showNavbarTitle}
            setActiveCategory={changeActiveCategory}
            setActivatedStages={addNewStageToActivatedCorporate}
          />
        ) : (
          <CreateReferrerModal
            isNavbarTitleShown={isNavbarTitleShown}
            activatedStages={activatedReferrerStages}
            closeModalWindow={closeModalWindow}
            showNavbarTitle={showNavbarTitle}
            setActiveCategory={changeActiveCategory}
            setActivatedStages={addNewStageToActivatedReferrer}
          />
        )}
      </ActionModal>
    </CorporateAccountContextProvider>
  );
};

export default CreateAccountModal;
