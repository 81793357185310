import React from 'react';

const EllipseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="7"
    viewBox="0 0 7 7"
  >
    <circle
      id="Ellipse_718"
      data-name="Ellipse 718"
      cx="3.5"
      cy="3.5"
      r="3.5"
      fill="#404d73"
      opacity="0.75"
    />
  </svg>
);

export default EllipseIcon;
