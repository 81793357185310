import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import createErrorMessage from 'helpers/createErrorMessages';

import { CreateCorporateAccountPayload } from '../accountsServices/CorporateAccountsService';
import { QueryKey } from '../models';

const useCreateCorporateAccount = (
  setTitle: (title: string) => void,
  mutationFn: ({ data }: CreateCorporateAccountPayload) => Promise<unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.GetCorporateAccounts);
      setTitle('Account created!');
    },
    onError: (error: AxiosError) => {
      const message = createErrorMessage(error);

      setTitle(message);
      console.error(error.message);
    },
  });
};

export default useCreateCorporateAccount;
