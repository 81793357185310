import React from 'react';

type CloseIconProps = {
  color: string;
  prefix?: string;
  size?: number;
};

const CloseIcon = ({ color, size, prefix }: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size || '16'}
    height={size || '16'}
    viewBox="0 0 15.996 16.004"
  >
    <defs>
      <clipPath id={`${prefix}-close-clip-path`}>
        <path
          id="Path_4133"
          data-name="Path 4133"
          d="M1.881-15.683,8-9.689l6.12-5.994a1.116,1.116,0,0,1,1.442-.1l.1.088h0l.008.008a1.062,1.062,0,0,1,.026,1.5h0L9.721-8l5.941,6.2a1.066,1.066,0,0,1-.023,1.5,1.076,1.076,0,0,1-1.506,0h0L8-6.315,1.868-.306a1.076,1.076,0,0,1-1.506,0A1.066,1.066,0,0,1,.338-1.8L6.279-8,.3-14.187a1.062,1.062,0,0,1,.026-1.5L.334-15.7h0A1.115,1.115,0,0,1,1.881-15.683Z"
          fill={color}
        />
      </clipPath>
      <clipPath id={`${prefix}-close-clip-path-2`}>
        <path
          id="Path_4132"
          data-name="Path 4132"
          d="M-339,751H36V-61H-339Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_6056"
      data-name="Group 6056"
      transform="translate(-0.002 16.002)"
    >
      <g
        id="Group_4342"
        data-name="Group 4342"
        clipPath={`url(#${prefix}-close-clip-path)`}
      >
        <g
          id="Group_4341"
          data-name="Group 4341"
          clipPath={`url(#${prefix}-close-clip-path-2)`}
        >
          <path
            id="Path_4131"
            data-name="Path 4131"
            d="M-5-21H21V5H-5Z"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CloseIcon;
