/* eslint-disable react/display-name */
import memoize from 'memoize-one';
import React, { useState, useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { FixedSizeList as List } from 'react-window';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import theme from 'theme';

import 'simplebar-react/dist/simplebar.min.css';

import ActionModal from 'components/actionModal/ActionModal';
import DefaultButton from 'components/buttons/DefaultButton';
import { ContentInfo } from 'modules/auth/models';

import { ContentQueryKey, EditableTopicType } from '../../models';

import SelectTopicItem from './SelectTopicItem';

const createItemData = memoize(
  (items: EditableTopicType[], toggleItemActive: (id: string) => void) => ({
    items,
    toggleItemActive,
  })
);

const MIN_TOPICS = 3;
const MAX_TOPICS = 5;
const NUMBER_OF_ITEMS_TO_SLICE = -2;
const PADDING_MODAL = 40;

export const innerElementType = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${Number(style?.height) + PADDING_MODAL}px`,
    }}
    {...rest}
  />
));

type Props = {
  closeModalWindow: () => void;
  topics: EditableTopicType[];
};

const ChooseModulesModal = ({ topics, closeModalWindow }: Props) => {
  const queryClient = useQueryClient();
  const [editableTopics, setEditableTopics] =
    useState<EditableTopicType[]>(topics);
  const [selectedTopics, setSelectedTopics] = useState<EditableTopicType[]>([]);

  const toggleTopic = useCallback(
    (id: string) => {
      setEditableTopics(
        editableTopics.map((topic) => {
          if (topic.id === id) {
            if (!topic.isChosen) {
              setSelectedTopics([...selectedTopics, topic]);
            } else {
              setSelectedTopics(
                selectedTopics.filter((item) => item.id !== topic.id)
              );
            }

            return {
              ...topic,
              isChosen: !topic.isChosen,
            };
          }

          return topic;
        })
      );
    },
    [editableTopics, selectedTopics]
  );

  const isRightQuantityChosen = useMemo(() => {
    const quantity = selectedTopics.length;

    return quantity >= MIN_TOPICS && quantity <= MAX_TOPICS;
  }, [selectedTopics.length]);

  const setTopicsInfoToLocalStorage = useCallback(() => {
    const idTopics = selectedTopics.map((topic) => topic.id);

    localStorage.setItem(
      ContentInfo.DateOfSelectingTopics,
      JSON.stringify(new Date().getTime())
    );
    localStorage.setItem(ContentInfo.TopicIds, JSON.stringify(idTopics));
    closeModalWindow();
  }, [closeModalWindow, selectedTopics]);

  const memoizedTopicsData = createItemData([...editableTopics], toggleTopic);

  return (
    <ActionModal isEmployerModal closeModalWindow={closeModalWindow}>
      <Wrapper>
        <Container>
          <BackgroundContainer>
            <Title>Select modules</Title>
            <Subtitle>
              Add topics to include in Work Life (Min 3 - Max 5)
            </Subtitle>
            <SelectTopicContainer>
              <SimpleBar
                autoHide={false}
                style={{ height: 376, width: '105%' }}
              >
                {({ scrollableNodeRef, contentNodeRef }) => {
                  return (
                    <StyledList
                      height={376}
                      itemCount={editableTopics.length}
                      itemSize={95}
                      width={690}
                      itemData={memoizedTopicsData}
                      innerRef={contentNodeRef}
                      outerRef={scrollableNodeRef}
                      innerElementType={innerElementType}
                    >
                      {SelectTopicItem}
                    </StyledList>
                  );
                }}
              </SimpleBar>
            </SelectTopicContainer>
          </BackgroundContainer>
          <ButtonWrapper>
            <DefaultButton
              isActive={isRightQuantityChosen}
              withShadow
              borderSize="md"
              buttonSize="md"
              text="APPLY"
              textColor={theme.colors.white}
              buttonColor={
                isRightQuantityChosen
                  ? theme.colors.funtainBlue
                  : theme.colors.quillGray
              }
              clickHandler={() => {
                setTopicsInfoToLocalStorage();
                queryClient.invalidateQueries(ContentQueryKey.GetTopics);
              }}
            />
          </ButtonWrapper>
        </Container>

        {!!selectedTopics.length && (
          <ChosenList>
            <ChosenListTitle>Selected</ChosenListTitle>
            <ListsContainer>
              {selectedTopics.map((item, i) => (
                <ListItem key={item.id}>
                  <ChosenNumber>
                    {`0${i + 1}`.slice(NUMBER_OF_ITEMS_TO_SLICE)}
                  </ChosenNumber>
                  <ChosenTopicTitle>{item.title}</ChosenTopicTitle>
                </ListItem>
              ))}
            </ListsContainer>
          </ChosenList>
        )}
      </Wrapper>
    </ActionModal>
  );
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (width <= 1350px) {
    justify-content: flex-end;
  }
`;

export const StyledList = styled(List)`
  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const BackgroundContainer = styled.div`
  position: relative;
`;

export const ChosenTopicTitle = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 8px;
`;

export const ChosenNumber = styled.p`
  font-size: 12px;
  opacity: 0.75;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ListItem = styled.div`
  width: 263px;
  height: 40px;
  border-bottom: 1.8px dashed ${({ theme }) => theme.colors.lightGreyV2};
  border-top: 1.8px dashed ${({ theme }) => theme.colors.lightGreyV2};

  @media (height <= 780px) {
    width: 200px;
  }

  display: flex;
  align-items: center;

  &:nth-child(n + 2) {
    border-top: none;
  }
`;

export const ListsContainer = styled.div`
  margin-top: 18px;
`;

export const ChosenListTitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

export const ChosenList = styled.div`
  position: absolute;
  top: 87px;
  right: 20px;

  @media (width <= 1100px) {
    right: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  right: 4px;
`;

export const SelectTopicContainer = styled.div`
  width: 100%;
  padding: 0 20px 10px 30px;
  position: relative;
  right: 50px;
  top: -5px;

  & *:focus-visible {
    outline: 0;
  }

  .simplebar-track {
    right: -9px;
  }

  .simplebar-track.simplebar-vertical {
    top: -4px;
    width: 6px;
    border-radius: 3px;
    margin-top: 21px;
    background: ${({ theme }) => theme.colors.gray};
  }

  .simplebar-scrollbar.simplebar-visible {
    width: 6px;
    height: 40px;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.funtainBlue};
  }

  .simplebar-scrollbar::before {
    background: ${({ theme }) => theme.colors.funtainBlue};
  }

  .simplebar-content-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    position: unset;
    border-radius: 5px;
  }

  .simplebar-content {
    width: 656px;

    @media (width <= 800px) {
      width: 485px;
    }
  }

  .simplebar-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgb(255 255 255 / 0%),
      rgb(255 255 255 / 100%) 90%
    );
    width: 100%;
    height: 5em;
  }

  @media (height <= 770px) {
    [data-simplebar] {
      height: 250px !important;
    }
  }
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  position: relative;
  height: 20px;
  z-index: 120;
  background: white;
  padding: 0 20px;
  right: 20px;
`;

export const Title = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 78px;

  @media (height <= 770px) {
    margin-top: 30px;
  }
`;

export const Container = styled.div`
  position: relative;
  top: 3px;
  right: -3px;
  margin: 0 auto;
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 41px;

  @media (width <= 1350px) {
    margin: 0 330px 0 10px;
  }

  @media (width <= 1100px) {
    margin: 0 300px 0 10px;
  }
`;

export default ChooseModulesModal;
