import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import ChevronIcon from 'components/icons/ChevronIcon';
import PreviewList from 'components/listForSelectingData/lists/PreviewList';

type Props = {
  label: string;
  inputValue: string;
  options: {
    id: string;
    displayName: string;
  }[];
  onChange: (id: string) => void;
};

const AffiliateDropdown = ({ options, label, inputValue, onChange }: Props) => {
  const [isListOpen, setIsListOpen] = useState(false);

  return (
    <InputContainerWrapper>
      <FormLabel $isActive={!!inputValue} htmlFor={label}>
        {label}
      </FormLabel>
      <FormInput
        id={label}
        type="text"
        readOnly
        autoComplete="off"
        value={inputValue}
        onFocus={() => setIsListOpen(true)}
      />
      <IconWrapper onClick={() => setIsListOpen(true)}>
        <ChevronIcon color={theme.colors.white} />
      </IconWrapper>
      {isListOpen && (
        <PreviewListWrapper>
          <PreviewList
            listWidth="lg"
            data={options}
            label="Select"
            height="180px"
            lineWidth="6px"
            chooseId={onChange}
            closeList={() => setIsListOpen(false)}
          />
        </PreviewListWrapper>
      )}
    </InputContainerWrapper>
  );
};

const PreviewListWrapper = styled.div`
  width: 335px;
  position: relative;
  padding-bottom: 220px;
  top: -65px;
  height: 100%;
`;

const FormInput = styled.input`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  font-size: 14px;
  outline: none;
  border: none;
  font-weight: 400;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.funtainBlue};
  position: relative;
  padding: 0;
  cursor: pointer;
`;

const FormLabel = styled.label<{
  $isActive?: boolean;
}>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  z-index: 2;
  opacity: 0.6;
  top: 50%;
  left: 0;
  ${({ $isActive }) =>
    $isActive &&
    css`
      top: 0;
      transition: all 0.4s;
      font-size: 11px;
      color: white;
    `}
  transform: translate(0, -50%);
`;

const InputContainerWrapper = styled.div`
  position: relative;
  width: 318px;
  border-bottom: 1px solid white;
  height: 39px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
`;

export default AffiliateDropdown;
