import React from 'react';

type BackgroundColor = {
  color: string;
};

const Background = ({ color }: BackgroundColor) => (
  <svg
    className="background-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="1920"
    height="2806"
    viewBox="0 0 1920 2806"
  >
    <path
      data-name="Path 5803"
      d="M.002-.001s377.555 234.7 963.277 234.7 956.723-234.7 956.723-234.7v2806H.002Z"
      fill={color}
    />
  </svg>
);

export default Background;
