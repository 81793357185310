import theme from 'theme';

const ONE_THIRD = 33;
const TWO_THIRDS = 66;

const colorChecker = (percent: number) => {
  if (percent < ONE_THIRD) {
    return theme.colors.cherry;
  }

  if (percent < TWO_THIRDS) {
    return theme.colors.orange;
  }

  return theme.colors.greenApple;
};

export default colorChecker;
