import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import styled, { css } from 'styled-components';
import theme from 'theme';

import AnimatedProgressProvider from 'contexts/AnimatedProgressProvider';
import colorChecker from 'helpers/colorChecker';

type GraphCircleProps = {
  percent: number;
  title: string;
  isSectionVisible: boolean;
  noColor?: boolean;
  openModal?: () => void;
};

const GraphCircle = ({
  percent,
  title,
  isSectionVisible,
  noColor,
  openModal,
}: GraphCircleProps) => {
  return (
    <AnimatedProgressProvider
      key={percent}
      valueStart={0}
      valueEnd={percent}
      duration={1.4}
      startAnimation={isSectionVisible}
    >
      {(value) => {
        const color = colorChecker(percent);
        const roundedValue = Math.round(value);

        const isNegativeValue = color === theme.colors.cherry;

        return (
          <Icon
            $noColor={noColor}
            $isNegativeValue={isNegativeValue}
            key={percent}
          >
            {isNegativeValue && !noColor && (
              <WarningWrapper onClick={openModal}>
                <img src="/images/warningIcon.svg" alt="" />
              </WarningWrapper>
            )}

            <CircularProgressbarWithChildren
              value={value}
              strokeWidth={4}
              background
              styles={buildStyles({
                strokeLinecap: 'round',
                pathTransition: 'none',
                pathColor: color,
                backgroundColor: noColor ? 'transparent' : theme.colors.white,
              })}
            >
              <p>{title}</p>

              <span>
                <p>{roundedValue}</p>
                <p>%</p>
              </span>
            </CircularProgressbarWithChildren>
          </Icon>
        );
      }}
    </AnimatedProgressProvider>
  );
};

const Icon = styled.div<{ $isNegativeValue: boolean; $noColor?: boolean }>`
  position: relative;
  width: ${({ $noColor }) => ($noColor ? '120px' : '180px')};
  height: ${({ $noColor }) => ($noColor ? '120px' : '180px')};
  margin-bottom: 80px;
  border-radius: 50%;
  box-shadow: ${({ $noColor }) => !$noColor && '0 5px 10px rgba(0, 0, 0, 0.2)'};

  div {
    width: 100%;
    height: 100%;
  }

  &::after {
    display: ${({ $isNegativeValue, $noColor }) =>
      (!$isNegativeValue || $noColor) && 'none'};
    content: '';
    position: absolute;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border: 2px dashed ${({ theme }) => theme.colors.secondary};
    z-index: 1;
  }

  ${({ $noColor }) =>
    $noColor &&
    css`
      /* stylelint-disable-next-line selector-class-pattern */
      .CircularProgressbar-trail {
        stroke-width: 1px !important;
        stroke: #d6d6d6;
        opacity: 0.7;
      }
    `};

  p {
    margin-bottom: ${({ $noColor }) => ($noColor ? '34px' : '39px')};
    font-size: ${({ $noColor }) => ($noColor ? '12px' : '16px')};
    color: ${({ theme, $noColor }) =>
      $noColor ? theme.colors.white : theme.colors.primary};
  }

  span:nth-child(2) {
    position: absolute;
    font-size: 14px;
    left: 50%;
    transform: ${({ $noColor }) =>
      $noColor ? 'translate(-35%, 33px)' : 'translate(-35%, 42px)'};
    display: flex;
    align-items: flex-start;

    p {
      font-size: ${({ $noColor }) => ($noColor ? '20px' : '30px')};
      font-weight: 700;
      color: ${({ theme, $noColor }) =>
        $noColor ? theme.colors.white : theme.colors.primary};
    }

    p:nth-child(2) {
      font-size: ${({ $noColor }) => ($noColor ? '12px' : '14px')};
    }
  }
`;

const WarningWrapper = styled.div`
  width: 40px !important;
  height: 40px !important;
  border: 3px solid ${({ theme }) => theme.colors.funtainBlue};
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 69px;
  right: -50px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 2;
  cursor: pointer;
`;

export default GraphCircle;
