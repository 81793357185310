import { StatusBE } from 'components/detailsModal/models';

import { OnboardingOfferItem } from './models';

const OnboardingOffersMockData: OnboardingOfferItem[] = [
  {
    id: '0',
    background: {
      url: '',
      name: 'background',
    },
    logo: {
      url: '',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A01',
    users: '67',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '1',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: '6 months',
    account: 'arnoldclark-A02',
    users: '330',
    status: StatusBE.Expired,
    expiry: '20.12.21',
  },
  {
    id: '2',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A03',
    users: '123',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '3',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A04',
    users: '335',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '4',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A05',
    users: '1,567',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '5',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A06',
    users: '288',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '6',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A07',
    users: '608',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '7',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A08',
    users: '2,330',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '8',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A09',
    users: '911',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '9',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A10',
    users: '283',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '10',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A11',
    users: '424',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '11',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A12',
    users: '997',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '12',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A13',
    users: '37,544',
    status: StatusBE.Suspend,
    expiry: '20.12.21',
  },
  {
    id: '13',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A14',
    users: '223',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '14',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A15',
    users: '130',
    status: StatusBE.Expired,
    expiry: '20.12.21',
  },
  {
    id: '15',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A16',
    users: '787',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '16',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A17',
    users: '59',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '17',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A18',
    users: '745',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '18',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A19',
    users: '875',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
  {
    id: '19',
    background: {
      url: 'back.jpg',
      name: 'background',
    },
    logo: {
      url: 'logo.svg',
      name: 'logo',
    },
    offerCode: 'CODENAMEA',
    term: 'OneMonth',
    account: 'arnoldclark-A20',
    users: '220',
    status: StatusBE.Active,
    expiry: '20.12.21',
  },
];

export default OnboardingOffersMockData;
