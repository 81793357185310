import styled, { css } from 'styled-components';

export const PlayButtonContainer = styled.div<{ $size?: number }>`
  width: ${({ $size }) => ($size ? $size : '68')}px;
  height: ${({ $size }) => ($size ? $size : '68')}px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 5px 8px rgb(0 0 0 / 20%);
  background-color: ${({ theme }) => theme.colors.white};
`;

export const MainContent = styled.div<{
  $isTablet?: boolean;
  $isMobile?: boolean;
  $backgroundImg?: string;
}>`
  height: 585px;
  background-position-x: center;
  padding-top: 80px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background:
    0 475px no-repeat url('/images/desktop_background_2.svg'),
    ${({ theme }) => theme.colors.primary} 50% 90% / contain no-repeat
      url(${({ $backgroundImg }) =>
        $backgroundImg || '/images/desktop_background_v2.png'});

  ${({ $isTablet: isTablet, $backgroundImg }) =>
    isTablet &&
    css`
      background:
        0 525px no-repeat url('/images/tablet_background_2.svg'),
        ${({ theme }) => theme.colors.primary} 50% 90% / contain no-repeat
          url(${() =>
            $backgroundImg
              ? `${$backgroundImg}`
              : '/images/desktop_background_v2.png'});
      background-position-x: center;
    `}

  ${({ $isMobile, $backgroundImg }) =>
    $isMobile &&
    css`
      height: 506px;
      background:
        0 556px no-repeat url('/images/mobile_background_2.svg'),
        ${({ theme }) => theme.colors.primary} 50% bottom / contain no-repeat
          url(${() =>
            $backgroundImg
              ? `${$backgroundImg}`
              : '/images/default_mobile_background_image.png'});
      padding-top: 100px;
      background-position-x: center;
    `};
`;

export const BroughtToYou = styled.p<{
  $isMobile?: boolean;
  $isTablet?: boolean;
}>`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 20px;
  margin-bottom: 7px;
  font-weight: 400;
  padding-left: 6px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding-left: 8px;
      font-size: 10px;
      text-transform: uppercase;
    `}

  ${({ $isTablet }) =>
    $isTablet &&
    css`
      padding-left: 0;
      font-size: 12px;
      text-transform: uppercase;
    `}
`;

export const Notification = styled.p<{
  $isMobile?: boolean;
  $isTablet?: boolean;
}>`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 36px;
  padding-left: 5px;
  margin-top: 2px;

  ${({ $isTablet }) =>
    $isTablet &&
    css`
      padding-left: 0;
      margin-top: 0;
    `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 20px;
      margin-bottom: 16px;
      margin-top: 0;
    `}
`;

export const EnteringOfferCode = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ $isMobile }) => ($isMobile ? ' 139px 0' : '133px 0')};
`;

export const DashedLineContainer = styled.div<{
  $paddingTopDisable?: boolean;
}>`
  max-width: 450px;
  margin: 40px auto 0;
  padding-top: ${({ $paddingTopDisable }) =>
    $paddingTopDisable ? '0' : '40px'};
  padding-bottom: 70px;

  hr {
    display: block;
    margin: 0;
    height: 1px;
    border: 0;
    background-image: url('/images/dashedLine.svg');
  }
`;

export const Footer = styled.div<{ $isDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ $isDesktop }) => ($isDesktop ? '0' : '70px')};

  img,
  svg {
    object-fit: contain;
    margin-top: 10px;
    max-width: 200px;
    height: 60px;
  }
`;

export const FooterTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;

export const Header = styled.div`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ShidoLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;

  svg:first-child {
    margin-right: 16px;
  }

  svg:nth-child(2) {
    position: relative;
    left: -2px;
    top: -6px;
  }
`;

export const RegisterSymbol = styled.div<{
  $color: string;
  $isTablet?: boolean;
}>`
  position: absolute;
  top: -2px;
  right: -10px;
  font-size: 10px;
  color: ${({ $color }) => $color};

  ${({ $isTablet }) =>
    $isTablet &&
    css`
      top: -11px;
    `}
`;

export const OfferCodeForm = styled.form`
  position: relative;
  margin: 0 auto;
  border-radius: 4px;
  width: 335px;
`;

export const FormLabel = styled.label<{
  $isDesktop?: boolean;
  $isActive?: boolean;
}>`
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: ${({ $isDesktop }) => ($isDesktop ? '20px' : '10px')};
  ${({ $isActive }) =>
    $isActive &&
    css`
      top: 12px;
      transition: all 0.4s;
      font-size: 12px;
      color: black;
    `}
  transform: translate(0, -50%);
  z-index: 2;
`;

export const FormInput = styled.input<{
  $error?: boolean;
  $isDesktop?: boolean;
}>`
  color: ${({ $error, theme }) =>
    $error ? theme.colors.red : theme.colors.darkBlueV2};
  width: 100%;
  height: 100%;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 4px;
  box-sizing: border-box;
  padding: ${({ $isDesktop }) => ($isDesktop ? '0 20px' : '0 10px')};
  margin-bottom: 21px;
  background: rgba(255 255 255 / 80%);
  position: relative;
`;

export const ShidoSlogan = styled.h3<{
  $isMobile?: boolean;
  $isDesktop?: boolean;
}>`
  font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '20px')};
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 7px;
  margin-top: 50px;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      position: relative;
      top: -11px;
      padding-top: 10px;
      margin-top: 0;
      margin-bottom: 0;
      background-color: ${({ theme }) => theme.colors.white};
    `}
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin: 41px auto 12px;
      position: relative;
      width: 261px;
      line-height: 20px;
    `}
`;

export const ShidoExplanation = styled.h3<{ $isDesktop?: boolean }>`
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 740px;
  margin: 0 auto 30px;
  line-height: 30px;
  padding: 0 10px;

  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      position: relative;
      top: -3px;
      margin: 0 80px 27px;
    `}
`;

export const SuggestionToWatchVideo = styled.p`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 14px;
`;

export const InputContainerWrapper = styled.div<{ $isDesktop?: boolean }>`
  height: 60px;
  position: relative;
  margin-bottom: 21px;
  width: ${({ $isDesktop }) => ($isDesktop ? '300px' : '335px')};
`;
