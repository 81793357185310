import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import TableLoader from 'components/table/TableLoader';
import { ContentInfo } from 'modules/auth/models';
import useContentful from 'modules/content/hooks/useContentful';
import {
  ContentQueryKey,
  ContentType,
  CourseType,
  EditableCourseType,
  TWENTY_EIGHT_DAYS,
} from 'modules/content/models';

import GetStarted from '../GetStarted';

import ChooseCoursesModal from './ChooseCoursesModal';
import CourseTable from './CourseTable';

type Props = {
  isModalOpen: boolean;
  appliedDate: number | undefined;
  diffDays: number | undefined;
  toggleModal: () => void;
};

const Courses = ({
  isModalOpen,
  appliedDate,
  diffDays,
  toggleModal,
}: Props) => {
  const [courses, setCourses] = useState<EditableCourseType[]>([]);
  const [tableCourses, setTableCourses] = useState<CourseType[]>([]);

  const { getCourses } = useContentful();

  const chosenCourseIds = useMemo(() => {
    if (isModalOpen) return;

    return JSON.parse(
      localStorage.getItem(ContentInfo.CourseIds) as string
    ) as string[];
  }, [isModalOpen]);

  const [isFirstTime, setIsFirstTime] = useState(!chosenCourseIds);

  const { isLoading } = useQuery(ContentQueryKey.GetCourses, getCourses, {
    onSuccess: (data) => {
      const previousCourses: EditableCourseType[] = data.items.map(
        (course) => ({
          id: course.sys.id.toString(),
          title: course.fields.title,
          description: course.fields.description,
          courseLength: course.fields.length,
          lessonNr: course.fields.lesson.length,
          appliedDate,
          isChosen: false,
        })
      );

      if (chosenCourseIds) {
        setIsFirstTime(false);
        const chosenCourses: CourseType[] = previousCourses.filter((topic) =>
          chosenCourseIds.includes(topic.id)
        );

        setTableCourses(chosenCourses);
      } else {
        setIsFirstTime(true);
      }

      setCourses(previousCourses);
    },
  });

  const renderContent = () => {
    if (isLoading) {
      return <TableLoader />;
    }

    if (isFirstTime) {
      return (
        <GetStarted
          toggleModal={toggleModal}
          contentType={ContentType.Courses}
        />
      );
    }

    return (
      <CourseTable
        data={tableCourses}
        isShowButtons={!!diffDays && diffDays > TWENTY_EIGHT_DAYS}
      />
    );
  };

  return (
    <>
      <ContentInformation>
        {isLoading ? '' : `${tableCourses.length} courses`}
      </ContentInformation>
      {renderContent()}
      {isModalOpen && (
        <ChooseCoursesModal courses={courses} closeModalWindow={toggleModal} />
      )}
    </>
  );
};

const ContentInformation = styled.div`
  padding: 0 19px 18px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export default Courses;
