import React from 'react';
import styled from 'styled-components';

import { FormLabel } from '../../styledElements/formsStyledElements';

type CheckBoxInputProps = {
  label: string;
  options: { id: number; value: string }[];
  selectedId: number;
  onSelectedChange: (selectedId: number) => void;
};

const CheckBoxInput = ({
  label,
  options,
  selectedId,
  onSelectedChange,
}: CheckBoxInputProps) => (
  <InputContainer>
    <FormLabel $active>{label}</FormLabel>
    <RadioInputContainer>
      {options.map(({ value, id }) => (
        <RadioLabel key={id} htmlFor={value}>
          {value}
          <input
            name={value}
            type="radio"
            id={value}
            checked={id === selectedId}
            data-testid={value}
            value={value}
            onChange={() => onSelectedChange(id)}
          />
          <RadioCheckmark />
        </RadioLabel>
      ))}
    </RadioInputContainer>
  </InputContainer>
);

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 33px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.funtainBlue};
  box-sizing: border-box;
  width: 100%;

  @media (height <= 769px) {
    margin-bottom: 20px;
  }
`;

const RadioInputContainer = styled.div`
  display: flex;
  padding-top: 23px;
  padding-bottom: 11px;
  column-gap: 41px;
`;

const RadioCheckmark = styled.span`
  position: absolute;
  top: 1px;
  left: 1px;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.darkBlueV2};
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &::after {
    content: '';
    display: none;
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    box-sizing: border-box;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.darkBlueV2};
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  padding-top: 2px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkBlueV2};

  /* Hide the browser's default radio button */

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Show the indicator (dot/circle) when checked, I added disabling because stylelint do not recognize RadioCheckmark style component, but it is work correctly */
  /* stylelint-disable */
  & input:checked ~ ${RadioCheckmark}::after {
    display: block;
  }
`;

export default CheckBoxInput;
