/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useState } from 'react';

import ActionModal from 'components/actionModal/ActionModal';
import OfferPage from 'components/actionModal/stages/offerPage/OfferPage';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import Result from 'components/actionModal/stages/Result';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import { ImageVariant, OfferModalWindowStages } from 'modules/offers/models';

import { OnboardingOfferContext } from '../../context/OnboardingOfferContext';
import useSaveDataForEditOnboardingOffer from '../../hooks/useSaveDataForEditOnboardingOffer';
import { OnboardingOfferItem } from '../../models';

type EditLandingModalProps = {
  offer: OnboardingOfferItem;
  closeModalWindow: () => void;
};

const EditLandingModal = ({
  offer,
  closeModalWindow,
}: EditLandingModalProps) => {
  const [saved, setSaved] = useState(false);
  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.OfferPage
  );
  const [title, setTitle] = useState('');
  const [isUpdatedImage, setIsUpdatedImage] = useState(false);

  const {
    saveOfferData,
    logoImage,
    logoName,
    setLogoName,
    setLogoImage,
    backgroundName,
    backgroundImage,
    setBackgroundName,
    setBackgroundImage,
  } = useContext(OnboardingOfferContext);

  const goToResultStage = useCallback(async () => {
    setTitle('Offer page updated!');
    setActiveStage(OfferModalWindowStages.Result);
  }, []);

  const saveImageName = useCallback(
    (name: string, imageVariant: ImageVariant) => {
      if (imageVariant === ImageVariant.Logo) {
        setLogoName(name);
      } else {
        setBackgroundName(name);
      }
    },
    [setLogoName, setBackgroundName]
  );

  const saveImage = useCallback(
    (
      imageURL: string,
      imageVariant: ImageVariant,
      openError: (value: boolean) => void,
      setErrorText: (value: string) => void,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      setIsUpdatedImage(true);
      if (imageVariant === ImageVariant.Logo) {
        setLogoImage(imageURL);
      } else {
        setBackgroundImage(imageURL);
      }
    },
    [setBackgroundImage, setLogoImage]
  );

  useSaveDataForEditOnboardingOffer(
    offer,
    saveOfferData,
    setSaved,
    setLogoImage,
    setBackgroundImage,
    setLogoName,
    setBackgroundName,
    saved
  );

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {activeStage === OfferModalWindowStages.Result ? (
        <Result title={title} closeModal={closeModalWindow} />
      ) : (
        <ActionModalMainContent>
          <OfferPage
            isShowStage
            isEdit
            previewOffer={PreviewOffer.Insight}
            title="Edit onboarding page"
            logoImage={logoImage}
            logoName={logoName}
            backgroundName={backgroundName}
            backgroundImage={backgroundImage}
            isUpdatedImage={isUpdatedImage}
            saveImage={saveImage}
            saveImageName={saveImageName}
            submitButtonTitle="Publish"
            goToNextStage={goToResultStage}
          />
        </ActionModalMainContent>
      )}
    </ActionModal>
  );
};

export default EditLandingModal;
