import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import theme from 'theme';

import AppInsightsContextProvider from 'contexts/AppInsightsContext';
import ModalContextProvider from 'contexts/ModalContext';
import AuthContextProvider from 'modules/auth/AuthContext';
import Routes from 'modules/router/routes';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GlobalStyle } from 'theme/globalStyles';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StyleSheetManager enableVendorPrefixes>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <AppInsightsContextProvider>
              <AuthContextProvider>
                <ModalContextProvider>
                  <Routes />
                </ModalContextProvider>
              </AuthContextProvider>
            </AppInsightsContextProvider>
          </Router>
        </ThemeProvider>
      </StyleSheetManager>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
