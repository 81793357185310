import { useQuery } from 'react-query';

import { getCorporateAccounts } from 'modules/accounts/accountsServices/CorporateAccountsService';

const useAccountsDashboard = () => {
  const { data, isLoading } = useQuery('GetDashboardAccounts', () =>
    getCorporateAccounts({
      data: {
        page: 1,
        pageSize: 1000,
        search: '',
        sortBy: 'Latest',
      },
    })
  );

  return {
    accounts: data?.items,
    totalCount: data?.totalCount,
    isLoading,
  };
};

export default useAccountsDashboard;
