/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import WarningIcon from 'components/icons/WarningIcon';
import codeFormKeyDownHandler from 'helpers/codeFormKeyDownHandler';
import patterns from 'helpers/patterns';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import {
  FormInput,
  FormLabel,
  OfferCodeForm,
} from '../../styledElements/formsStyledElements';

type Props = {
  isEdit?: boolean;
  error: boolean;
  fields: string[];
  inputValue: string;
  isPassCodeValid: boolean;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  setFocus: UseFormSetFocus<any>;
  saveData: () => void;
};

const PasscodeInput = ({
  isEdit,
  error,
  register,
  setValue,
  fields,
  watch,
  setFocus,
  saveData,
  isPassCodeValid,
  inputValue,
}: Props) => {
  const [isOfferCodeActive, setIsOfferCodeNameActive] = useState(false);
  const [firstRenderDone, setFirstRenderDone] = useState(false);
  const [isPassCodeSaved, setIsPassCodeSaved] = useState(false);
  const [deleteStart, setDeleteStars] = useState(true);

  const changeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { value, name } = e.target;

    if (isEdit && deleteStart) {
      fields.forEach((field) => {
        setValue(field, '', { shouldValidate: true });
      });
      setFocus(fields[0]);
      setDeleteStars(false);
    }

    // index can be 0
    // 'if' handles input with broken letters
    if (index !== undefined) {
      const matchingValue = value.match(patterns.passCodePattern);

      setValue(name, matchingValue ? value : '', {
        shouldValidate: true,
      });

      if (matchingValue && index !== fields.length - 1) {
        setFocus(fields[index + 1]);
      }

      if (
        matchingValue &&
        index === fields.length - 1 &&
        document.activeElement
      ) {
        (document.activeElement as HTMLElement).blur();
      }

      saveData();
    }
  };

  useEffect(() => {
    if (inputValue) {
      setFirstRenderDone(true);
      if (!firstRenderDone) {
        setIsOfferCodeNameActive(true);
        fields.forEach((field, index) => {
          setValue(field, inputValue[index], { shouldValidate: true });
        });
      }
    }
  }, [fields, firstRenderDone, inputValue, setValue]);

  useEffect(() => {
    if (firstRenderDone && !isPassCodeSaved) {
      setIsPassCodeSaved(true);
      saveData();
    }
  }, [firstRenderDone, isPassCodeSaved, saveData]);

  return (
    <div>
      <OfferCodeForm>
        <FormLabel
          $error={(error || !isPassCodeValid) && isOfferCodeActive}
          $active={isOfferCodeActive}
          onClick={() => setIsOfferCodeNameActive(true)}
        >
          Passcode (6 numeric chars)
        </FormLabel>
        {isOfferCodeActive ? (
          <>
            {fields.map((field, i) => (
              <InputContainer
                key={field}
                $error={(error || !isPassCodeValid) && isOfferCodeActive}
              >
                <BoldFormInput
                  data-testid="passcode-code"
                  key={field}
                  id={field}
                  $disabled={false}
                  type="text"
                  autoComplete="off"
                  {...register(field, {
                    required: false,
                  })}
                  onKeyDown={(e) =>
                    codeFormKeyDownHandler(
                      e,
                      i,
                      setFocus,
                      fields,
                      watch()[i + 1]
                    )
                  }
                  maxLength={1}
                  onSelect={(e) => e.currentTarget.select()}
                  onChange={(e) => changeInputHandler(e, i)}
                  $error={(error || !isPassCodeValid) && isOfferCodeActive}
                />
              </InputContainer>
            ))}
          </>
        ) : (
          <InputContainerWithTextCursor
            onClick={() => setIsOfferCodeNameActive(true)}
          />
        )}
      </OfferCodeForm>
      {(error || !isPassCodeValid) && isOfferCodeActive && (
        <Error $error>
          <WarningIcon color={theme.colors.primary} />
          {!isPassCodeValid ? 'Min length is 6 digits' : null}
        </Error>
      )}
    </div>
  );
};

const InputContainerWithTextCursor = styled(InputContainer)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: text;
`;

const BoldFormInput = styled(FormInput)`
  font-weight: 700;
`;

const Error = styled(ErrorText)`
  margin-top: 10px;
`;

PasscodeInput.displayName = 'PassCodeInput';

export default PasscodeInput;
