import React, { createContext, ReactNode, useCallback, useState } from 'react';

import {
  AffiliateOfferData,
  AffiliateOfferPageAttributes,
  AffiliateSettingsPageAttributes,
  ConversionFunnelBE,
} from '../models';

type AffiliateOfferContextType = {
  imageFile?: File;
  imageName: string;
  image: string;
  offerDraft: {
    settings: AffiliateOfferData;
    affiliateOfferId: string;
    status: string;
  };
  offerTermId: string;
  prevData?: AffiliateOfferPageAttributes;
  isValidFormOffer: boolean;
  setIsValidFormOffer: (value: boolean) => void;
  setOfferTermId: (id: string) => void;
  setImageFile: (value?: File) => void;
  setImageName: (name: string) => void;
  setImage: (image: string) => void;
  saveOfferId: (id: string) => void;
  saveSettings: (settings: AffiliateSettingsPageAttributes) => void;
  setPrevData: (value: AffiliateOfferPageAttributes) => void;
  saveOfferSettings: (value: AffiliateOfferPageAttributes) => void;
  saveAllOfferData: (data: {
    settings: AffiliateOfferData;
    affiliateOfferId: string;
    status: string;
  }) => void;
};

const initialValue: AffiliateOfferContextType = {
  offerDraft: {
    settings: {
      offerStage: {
        affiliateName: '',
        affiliateHandle: '',
        intro: '',
        affiliateStatement: '',
        bodyText: '',
      },
      settingsStage: {
        backgroundColour: 'Sand',
        conversionFunnel: ConversionFunnelBE.App,
      },
    },
    status: '',
    affiliateOfferId: '',
  },
  offerTermId: '',
  imageName: '',
  image: '',
  isValidFormOffer: false,
  setIsValidFormOffer: () => null,
  setOfferTermId: () => null,
  setImageName: () => null,
  setImage: () => null,
  setImageFile: () => null,
  saveOfferId: () => null,
  saveSettings: () => null,
  setPrevData: () => null,
  saveOfferSettings: () => null,
  saveAllOfferData: () => null,
};

export const AffiliateOfferContext =
  createContext<AffiliateOfferContextType>(initialValue);

const AffiliateOfferContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [offerDraft, setOfferDraft] = useState(initialValue.offerDraft);
  const [imageName, setImageName] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState<File>();
  const [prevData, setPrevData] = useState<AffiliateOfferPageAttributes>();
  const [offerTermId, setOfferTermId] = useState('');
  const [isValidFormOffer, setIsValidFormOffer] = useState(false);

  const saveOfferId = useCallback(
    (id: string) => setOfferDraft({ ...offerDraft, affiliateOfferId: id }),
    [offerDraft]
  );

  const saveAllOfferData = useCallback(
    (data: {
      settings: AffiliateOfferData;
      affiliateOfferId: string;
      status: string;
    }) => {
      setOfferDraft(data);
    },
    []
  );

  const saveOfferSettings = useCallback(
    (settings: AffiliateOfferPageAttributes) => {
      setOfferDraft({
        ...offerDraft,
        settings: {
          ...offerDraft.settings,
          offerStage: {
            ...settings,
          },
        },
      });
    },
    [offerDraft]
  );

  const saveSettings = useCallback(
    (settings: AffiliateSettingsPageAttributes) => {
      setOfferDraft({
        ...offerDraft,
        settings: {
          ...offerDraft.settings,
          settingsStage: {
            ...settings,
          },
        },
      });
    },
    [offerDraft]
  );

  return (
    <AffiliateOfferContext.Provider
      value={{
        imageFile,
        image,
        imageName,
        offerDraft,
        prevData,
        offerTermId,
        isValidFormOffer,
        setIsValidFormOffer,
        setOfferTermId,
        saveAllOfferData,
        saveOfferSettings,
        setPrevData,
        saveOfferId,
        saveSettings,
        setImageName,
        setImage,
        setImageFile,
      }}
    >
      {children}
    </AffiliateOfferContext.Provider>
  );
};

export default AffiliateOfferContextProvider;
