import React from 'react';
import Switch from 'react-switch';
import styled, { css } from 'styled-components';

type ActionAgreementProps = {
  employerModal?: boolean;
  note: string;
  isChecked: boolean;
  actionTypeText: string;
  toggleStatus: () => void;
};

const ActionAgreement = ({
  employerModal,
  note,
  isChecked,
  actionTypeText,
  toggleStatus,
}: ActionAgreementProps) => (
  <>
    {!employerModal && <ConfidenceQuestion>Are you sure?</ConfidenceQuestion>}
    <ApproveDecisions $employerModal={employerModal}>
      <Text>
        <p>{actionTypeText}</p>
        <p>{note}</p>
      </Text>
      <ToggleSwitch
        onChange={toggleStatus}
        checked={isChecked}
        height={30}
        width={52}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.5)"
      />
    </ApproveDecisions>
  </>
);

// important is for overlapping default switch library styles
const ToggleSwitch = styled(Switch)`
  position: relative;
  top: -3px;

  div {
    background-color: rgb(255 255 255 / 50%) !important;
  }

  div.react-switch-handle {
    background-color: white !important;
  }
`;

const ConfidenceQuestion = styled.p`
  font-size: 16px;
  margin-bottom: 39px;
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
`;

const ApproveDecisions = styled.div<{ $employerModal?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 400;

  p {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.white};
  }

  p:first-child {
    text-transform: uppercase;
    font-size: 10px;
  }

  ${({ $employerModal }) =>
    $employerModal &&
    css`
      justify-content: space-between;
      padding-top: 40px;

      div {
        width: auto;
      }
    `}
`;

const Text = styled.div`
  width: 280px;
  margin-right: 3px;
`;

export default ActionAgreement;
