import { useEffect } from 'react';

import { OfferCodeDetails } from '../context/OnboardingOfferContext';
import { OnboardingOfferItem } from '../models';

const useSaveDataForEditOnboardingOffer = (
  corporateOffer: OnboardingOfferItem,
  saveOfferData: (data: OfferCodeDetails) => void,
  setSaved: (value: boolean) => void,
  setLogoImage: (url: string) => void,
  setBackgroundImage: (url: string) => void,
  setLogoName: (name: string) => void,
  setBackgroundName: (name: string) => void,
  saved?: boolean
) => {
  useEffect(() => {
    if (!saved) {
      const { background, logo, account, term } = corporateOffer;

      setBackgroundImage(background.url);
      setLogoImage(logo.url);
      setLogoName('logo');
      setBackgroundName('background');
      saveOfferData({
        account,
        offerTerm: term,
      });

      setSaved(true);
    }
  }, [
    corporateOffer,
    saved,
    setSaved,
    setBackgroundImage,
    setLogoImage,
    setLogoName,
    setBackgroundName,
    saveOfferData,
  ]);
};

export default useSaveDataForEditOnboardingOffer;
