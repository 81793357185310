export const filterElements = [
  {
    option: 'Date range',
    result: 'Dec 2020',
  },
  {
    option: 'Location',
    result: 'All',
  },
  {
    option: 'Department',
    result: 'All',
  },
];

export const monthsData = [
  {
    name: 'JUL',
    uniqueVisitors: 1000,
  },
  {
    name: 'AUG',
    uniqueVisitors: 1500,
  },
  {
    name: 'SEP',
    uniqueVisitors: 1400,
  },
  {
    name: 'OCT',
    uniqueVisitors: 800,
  },
  {
    name: 'NOV',
    uniqueVisitors: 1300,
  },
  {
    name: 'DEC',
    uniqueVisitors: 1500,
  },
];

export const percentageData = [93, 76, 67];
export const percentageDataV2 = [22, 56, 43];
