import React from 'react';
import styled from 'styled-components';

import DownRatingIcon from 'components/icons/DownRatingIcon';
import UpRatingIcon from 'components/icons/UpRatingIcon';
import ProgressBar from 'components/ProgressBar';
import colorChecker from 'helpers/colorChecker';

import { RATING_COLORS, RatingResult } from '../models';

type Props = {
  title: string;
  chart: React.ReactNode;
  rating?: {
    result: RatingResult;
    percentage: number;
  };
  params?: {
    values: string[];
    activeParam: number;
    setActiveParam: React.Dispatch<number>;
  };
  progressBars?: { title: string; percent: number; color?: string }[];
};

const DashboardGraphCard = ({
  title,
  rating,
  chart,
  params,
  progressBars,
}: Props) => (
  <Container>
    <GraphInfoContainer>
      <GraphTitle>{title}</GraphTitle>
      {!!rating && (
        <GraphInfoRatingContainer>
          {rating.result === 'falls' ? (
            <DownRatingIcon color={RATING_COLORS[rating.result]} />
          ) : (
            <UpRatingIcon color={RATING_COLORS[rating.result]} />
          )}
          <PercentageResult $ratingResult={rating.result}>
            {rating.percentage}%
          </PercentageResult>
        </GraphInfoRatingContainer>
      )}
    </GraphInfoContainer>
    <ChartContainer>{chart}</ChartContainer>
    {!!params && (
      <ParamsContainer>
        {params.values.map((param, index) => (
          <ParamName
            key={param}
            $isActive={index === params.activeParam}
            onClick={() => params.setActiveParam(index)}
          >
            {param}
          </ParamName>
        ))}
      </ParamsContainer>
    )}
    {!!progressBars?.length && (
      <ProgressBarsContainer>
        {progressBars.map((bar) => (
          <ProgressBarContainer key={bar.title}>
            <ProgressBarInfo>
              <ProgressBarTitle>{bar.title}</ProgressBarTitle>
              <ProgressBarPercent>{bar.percent}%</ProgressBarPercent>
            </ProgressBarInfo>
            <ProgressBar
              isVisible
              color={bar.color || colorChecker(bar.percent)}
              percent={bar.percent}
            />
          </ProgressBarContainer>
        ))}
      </ProgressBarsContainer>
    )}
  </Container>
);

const ProgressBarTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const ProgressBarPercent = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const ProgressBarInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ProgressBarContainer = styled.div`
  margin-top: 19px;
`;

const ProgressBarsContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

const ParamsContainer = styled.div`
  margin: 15px 0 9px;
  padding: 0 5px;
  width: 100%;
  font-size: 10px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const ParamName = styled.div<{ $isActive: boolean }>`
  position: relative;
  font-weight: ${({ $isActive: active }) => (active ? '700' : '300')};
  width: 58px;
  display: flex;
  color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: -5px;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 90px;
  margin-top: 25px;
`;

const PercentageResult = styled.div<{ $ratingResult: RatingResult }>`
  color: ${({ $ratingResult }) => RATING_COLORS[$ratingResult]};
  font-weight: 700;
`;

const GraphInfoRatingContainer = styled.div`
  align-items: center;
  display: flex;
  column-gap: 10px;
`;

const GraphTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 19px;
`;

const GraphInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  flex-grow: 1;
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;
  min-width: 280px;
  border-radius: 5px;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  padding: 16px 19px 25px 20px;
  display: flex;
  flex-direction: column;
`;

export default DashboardGraphCard;
