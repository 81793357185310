import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { checkValidAdminEmail } from 'modules/auth/AuthService';
import Header from 'modules/auth/components/Header';
import {
  AuthStage,
  CustomTrackEvent,
  emailValidationSchema,
} from 'modules/auth/models';

import EmailFormTemplate from '../../../forms/EmailFormTemplate';

const SIGN_IN_EMAIL_INPUT_ID = 'email';

type EmployerEmailFormProps = {
  goToNextStage: (
    isSecondTimeLogin: boolean,
    isFirstemail: string,
    lastTwoDigitsOnMobile: string
  ) => void;
};

const EmployerEmailStage = ({ goToNextStage }: EmployerEmailFormProps) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
  });
  const appInsights = useAppInsightsContext();
  const trackInvalidEmail = useTrackEvent(
    appInsights,
    CustomTrackEvent.InvalidEmail,
    {}
  );

  useMutation(checkValidAdminEmail, {
    onSuccess: ({ mobileNumberLastDigits }) =>
      goToNextStage(
        false,
        watch()[SIGN_IN_EMAIL_INPUT_ID],
        mobileNumberLastDigits
      ),
    onError: () => {
      setError(SIGN_IN_EMAIL_INPUT_ID, {
        message: 'Email not recognised',
        type: 'invalid email',
      });
      trackInvalidEmail({ email: watch()[SIGN_IN_EMAIL_INPUT_ID] });
    },
  });

  const checkEmailRequest = useCallback(() => {
    goToNextStage(true, watch()[SIGN_IN_EMAIL_INPUT_ID], '');
  }, [goToNextStage, watch]);

  return (
    <>
      <Header headerType={AuthStage.SignIn} />
      <EmailFormTemplate
        errors={errors}
        inputId={SIGN_IN_EMAIL_INPUT_ID}
        register={register}
        watch={watch}
        setValue={setValue}
        handleSubmit={handleSubmit}
        passEmailToValidate={checkEmailRequest}
        isValid={isValid}
      />
    </>
  );
};

export default React.memo(EmployerEmailStage);
