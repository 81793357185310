export const saveExternalSvg = async (
  qrCodeSvgUrl: string,
  publicCode: string
) => {
  const response = await fetch(qrCodeSvgUrl);
  const blobFile = await response.blob();

  const reader = new FileReader();

  reader.addEventListener('load', () => {
    const link = document.createElement('a');

    link.setAttribute('download', `${publicCode}.svg`);
    link.href = reader.result as string;
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

  reader.readAsDataURL(blobFile);
};
