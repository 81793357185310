import React from 'react';

type PinkLockIconProps = {
  width: number;
  height: number;
};

const PinkLockIcon = ({ width, height }: PinkLockIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19 24"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 8800"
          d="M17.5-14.5h-.75v-2.75A6.75 6.75 0 0 0 10-24a6.75 6.75 0 0 0-6.75 6.75v2.75H2.5a2 2 0 0 0-2 2V-2a2 2 0 0 0 2 2h15a2 2 0 0 0 2-2v-10.5a2 2 0 0 0-2-2Zm-7.5 9a2 2 0 0 1-2-2 2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2Zm4.25-9.5a.5.5 0 0 1-.5.5h-7.5a.5.5 0 0 1-.5-.5v-2.25A4.25 4.25 0 0 1 10-21.5a4.25 4.25 0 0 1 4.25 4.25Z"
          fill="#d2a4ae"
        />
      </clipPath>
    </defs>
    <g data-name="Group 10960">
      <g
        data-name="Group 10959"
        clipPath="url(#a)"
        transform="translate(-.5 24)"
      >
        <path data-name="Path 8799" d="M-4.5-29h29V5h-29Z" fill="#d2a4ae" />
      </g>
    </g>
  </svg>
);

export default PinkLockIcon;
