import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';
import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

type CloseBlockProps = {
  closeModalWindow: () => void;
};

const CloseBlock = ({ closeModalWindow }: CloseBlockProps) => {
  return (
    <Container>
      <VerticalLine $withOpacity $color={theme.colors.primary} />
      <ActionButton
        icon={<CloseIcon color={theme.colors.funtainBlue} prefix="modal" />}
        clickHandler={closeModalWindow}
      />
    </Container>
  );
};

const BUTTON_VERTICAL_RANGE = 10;
const BUTTON_HORIZONTAL_RANGE = 10;

const Container = styled.div`
  position: absolute;
  top: 25px;
  right: 21px;
  display: flex;
  align-items: center;

  svg {
    transform: scale(1.2);
    margin-left: 22px;
  }

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }
`;

export default CloseBlock;
