import React from 'react';
import styled, { css } from 'styled-components';

import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

type TableInfoProps = {
  dataAmount: number;
  pageSizes: number[];
  activePageSize: number;
  employersPage?: boolean;
  changePageSize: (value: number) => void;
};

const TableInfo = ({
  pageSizes,
  dataAmount,
  employersPage,
  changePageSize,
  activePageSize,
}: TableInfoProps) => (
  <Container $employersPage={employersPage}>
    <Results>
      {dataAmount} {dataAmount === 1 ? 'result' : 'results'}
    </Results>
    <RowsPerPage>
      <span>Per page:</span>
      {pageSizes.map((pages) => (
        <RowsNumber
          key={pages}
          $active={activePageSize === pages}
          onClick={() => changePageSize(pages)}
        >
          {pages}
        </RowsNumber>
      ))}
    </RowsPerPage>
  </Container>
);

const Container = styled.div<{ $employersPage?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 19px 18px;
  ${({ $employersPage }) =>
    $employersPage &&
    css`
      padding: 85px 22px 18px 19px;
    `}
`;

const Results = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

const RowsPerPage = styled.div`
  display: flex;
  padding-top: 3px;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 400;
`;

const BUTTON_VERTICAL_RANGE = 5;
const BUTTON_HORIZONTAL_RANGE = 7;

const RowsNumber = styled.span<{ $active?: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-weight: 700;
  cursor: pointer;
  margin-left: 19px;
  ${increaseClickableAreaForHtmlElement(
    BUTTON_VERTICAL_RANGE,
    BUTTON_HORIZONTAL_RANGE
  )}
  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
      font-weight: 400;
    `}
  &:hover {
    text-decoration: underline;
  }
`;

export default React.memo(TableInfo);
