import React, { useState } from 'react';
import styled from 'styled-components';

import ParameterNavigation from 'components/ParameterNavigation';

import AffiliateOffers from '../components/affiliateOffers/AffiliateOffers';
import CorporateOffers from '../components/corporateOffers/CorporateOffers';
import PromotionOffers from '../components/promotionOffers/PromotionOffers';
import CorporateOffersContextProvider from '../context/CorporateOffersContext';
import PromotionOffersContextProvider from '../context/PromotionOffersContext';
import { OfferCategory } from '../models';

const offerCategories = [
  OfferCategory.Corporate,
  OfferCategory.Promotion,
  OfferCategory.Affiliate,
];

const Offers = () => {
  const [activeOfferCategory, setActiveOfferCategory] = useState<OfferCategory>(
    OfferCategory.Corporate
  );

  const changeOfferCategory = (index: number) =>
    setActiveOfferCategory(offerCategories[index]);

  return (
    <CorporateOffersContextProvider>
      <PromotionOffersContextProvider>
        <Container>
          <ParameterNavigation
            withOffset
            parameters={offerCategories}
            setNewParameter={changeOfferCategory}
            activeParameter={activeOfferCategory}
          />
          {activeOfferCategory === OfferCategory.Corporate && (
            <CorporateOffers />
          )}

          {activeOfferCategory === OfferCategory.Promotion && (
            <PromotionOffers />
          )}

          {activeOfferCategory === OfferCategory.Affiliate && (
            <AffiliateOffers />
          )}
        </Container>
      </PromotionOffersContextProvider>
    </CorporateOffersContextProvider>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Offers;
