import styled, { css } from 'styled-components';

export const FormContainer = styled.form`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormLabel = styled.label<{
  $active: boolean;
  $error?: boolean;
  $colored?: boolean;
}>`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: ${({ $active }) => ($active ? '11px' : '16px')};
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  transition: 0.5s;
  top: ${({ $active }) => ($active ? '2px' : '20px')};
  cursor: ${({ $active }) => ($active ? 'default' : 'text')};
  ${({ $colored, $active, theme }) =>
    $colored &&
    css`
      color: ${$active ? theme.colors.darkBlue : theme.colors.turquoise};
    `}
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.colors.lightRedV1};
    `}
`;

export const FormInput = styled.input<{ $error?: boolean }>`
  width: 100%;
  height: 100%;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  background: none;
  padding: 6px 0 0;
  color: ${({ $error, theme }) =>
    $error ? theme.colors.lightRedV1 : theme.colors.funtainBlue};
`;

export const InputContainer = styled.div<{
  $error?: boolean;
  $disabled?: boolean;
  $withDifferentBorderColor?: boolean;
  $emptyInputs?: number;
}>`
  position: relative;
  height: 50px;
  margin-bottom: 33px;
  border-bottom: 1px solid
    ${({ $error, theme }) =>
      $error ? theme.colors.lightRedV1 : theme.colors.funtainBlue};
  box-sizing: border-box;
  width: 100%;
  ${({ $error, $withDifferentBorderColor }) =>
    $withDifferentBorderColor &&
    css`
      &:nth-last-child(-n + 2) {
        border-bottom: 1px solid
          ${({ theme }) =>
            $error ? theme.colors.lightRedV1 : theme.colors.darkBlue};
      }
    `}
  ${({ $disabled, $emptyInputs, theme }) =>
    $disabled &&
    css`
      border-bottom: 1px solid
        ${$emptyInputs === -1
          ? theme.colors.funtainBlue
          : theme.colors.darkBlue};
      opacity: 0.5;
      user-select: text;
      pointer-events: none;
      &:nth-last-child(-n + ${$emptyInputs || 1}) {
        border-bottom: none;
      }
    `};

  @media (height <= 769px) {
    margin-bottom: 20px;
  }
`;

export const ErrorText = styled.div<{ $error?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary} !important;
  visibility: ${({ $error }) => ($error ? 'visible' : 'hidden')};
  font-weight: 400;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }
`;

export const InputErrorText = styled(ErrorText)`
  visibility: visible;
  position: absolute;
  right: -70px;
  top: 11px;
`;

export const ContinueButtonContainer = styled.div`
  position: relative;
  margin-top: 73px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AsteriskText = styled.span<{ $hidePassword: boolean }>`
  padding-top: 7px;
  letter-spacing: 3.5px;
  font-size: 18px;
  height: 48%;
  background: white;
  position: absolute;
  display: ${({ $hidePassword }) => ($hidePassword ? 'block' : 'none')};
  color: ${({ theme }) => theme.colors.funtainBlue};
  margin-top: 18px;
`;
