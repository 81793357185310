import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export type LinksArray = {
  path: string;
  label: string;
};

const Navigation = ({ linksArray }: { linksArray: LinksArray[] }) => (
  <Container>
    {linksArray.map(({ label, path }) => {
      return (
        <LinkContainer key={label}>
          <Link to={path}>{label}</Link>
        </LinkContainer>
      );
    })}
  </Container>
);

const Container = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 12px;
`;

const LinkContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 30px;
  border-right: 1px solid rgb(255 255 255 / 20%);
  box-sizing: border-box;

  &:last-child {
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 87px;
  }
`;

const Link = styled(NavLink)`
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 120px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.funtainBlue};
    cursor: default;

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 87px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 10px;
  }
`;

export default Navigation;
