import { RefObject, useEffect } from 'react';

const useEscapeClick = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    const keyDownEvent = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback();
      }
    };

    document.addEventListener('keydown', keyDownEvent);

    return () => {
      document.removeEventListener('keydown', keyDownEvent);
    };
  }, [callback]);
};

export default useEscapeClick;
