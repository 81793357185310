import React, { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import { DottedLine } from 'components/Divider';

import DemographicsInfo from './DemographicsInfo';

const TeamDemographics = () => {
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  return (
    <Container>
      <DottedLineWrapper>
        <DottedLine />
      </DottedLineWrapper>
      <ReactVisibilitySensor
        onChange={(isVisible: boolean) => {
          if (!isSectionVisible && isVisible) {
            setIsSectionVisible(true);
          }
        }}
      >
        <Title>Team demographics</Title>
      </ReactVisibilitySensor>

      <DemographicsInfo isSectionVisible={isSectionVisible} />
    </Container>
  );
};

const DottedLineWrapper = styled.div`
  margin-bottom: 80px;
`;

const Container = styled.div`
  width: 100%;
  min-height: 686px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-bottom: 105px;
`;

export default TeamDemographics;
