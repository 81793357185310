import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import { VerticalLine } from 'components/Divider';
import BookMarkIcon from 'components/icons/BookMarkIcon';

const StatusBar = () => {
  return (
    <Container>
      <img src="/images/insightsLogo.png" />

      <BarActions>
        <BookMarkTitle>Save report</BookMarkTitle>
        <BookMarkIcon />
        <VerticalLine $withOpacity $color={theme.colors.primary} />
        <ButtonWrapper>
          <DefaultButton
            isActive
            withShadow
            borderSize="md"
            buttonSize="md"
            text="Filter"
            textColor={theme.colors.white}
            buttonColor={theme.colors.funtainBlue}
          />
        </ButtonWrapper>
      </BarActions>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: -40px;
  width: 100%;
  height: 80px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 19px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 10px;
  }
`;

const BarActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  top: -1px;
  left: 1px;
`;

const BookMarkTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.funtainBlue};
  cursor: pointer;
`;

export default StatusBar;
