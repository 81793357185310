import React from 'react';
import { useForm } from 'react-hook-form';

import Header from 'modules/auth/components/Header';
import { AuthStage } from 'modules/auth/models';

import PasswordFormTemplate from '../PasswordFormTemplate';

const CHOOSE_PASSWORD_INPUT_ID = 'choosePassword';

type Props = {
  goToNextStage: (value: string) => void;
};

const ReferrerPasswordForm = ({ goToNextStage }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
    watch,
  } = useForm();
  const passwordValue = watch()[CHOOSE_PASSWORD_INPUT_ID];

  const passPasswordToValidate = () => {
    goToNextStage(passwordValue);
  };

  return (
    <>
      <Header headerType={AuthStage.Password} />
      <PasswordFormTemplate
        inputId={CHOOSE_PASSWORD_INPUT_ID}
        register={register}
        setValue={setValue}
        isValid={isValid}
        watch={watch}
        handleSubmit={handleSubmit}
        passPasswordToValidate={passPasswordToValidate}
      />
    </>
  );
};

export default ReferrerPasswordForm;
