import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sticky from 'react-sticky-el';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import ReferrerAccountInformation from 'components/detailsModal/information/ReferrerAccountInformation';
import ModalAction from 'components/detailsModal/ModalAction';
import ModalNavigation from 'components/detailsModal/ModalNavigation';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
  StatusFE,
  statusesFE,
} from 'components/detailsModal/models';
import {
  DetailsModalContainer,
  DetailsModalContent,
} from 'components/detailsModal/styledElements/detailsModalStyledElements';
import SuccessStage from 'components/detailsModal/SuccessStage';
import useOutsideClick from 'hooks/useOutsideClick';
import usePrevious from 'hooks/usePrevious';
import { ReferrerAccount } from 'modules/accounts/models';

type ReferrerDetailsModalProps = {
  data: ReferrerAccount;
  dataLength: number;
  closeWindow: () => void;
  setDataForEditing: (data: ReferrerAccount) => void;
  deleteItem: (id: string) => void;
  openEditingModalWindow: () => void;
};

const ReferrerDetailsModal = ({
  data,
  closeWindow,
  setDataForEditing,
  deleteItem,
  openEditingModalWindow,
}: ReferrerDetailsModalProps) => {
  const [activeStatus, setActiveStatus] = useState(StatusFE.Active);
  const [isSuspendConfirmed, setIsSuspendConfirmed] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [isActivateConfirmed, setIsActivateConfirmed] = useState(false);
  const [isStatusSuccess, setIsStatusSuccess] = useState(false);
  const [shouldRender, setRender] = useState(true);
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const previousActiveStatus = usePrevious(activeStatus);
  const modalRef = useRef<HTMLDivElement>(null);

  const onSuccessModal = useCallback((title: string, text: string) => {
    setIsStatusSuccess(true);
    setSuccessTitle(title);
    setSuccessText(text);
  }, []);

  const resetAllConfirmedStatuses = useCallback(() => {
    setIsSuspendConfirmed(false);
    setIsDeleteConfirmed(false);
    setBackgroundColor('');
  }, []);

  const toggleActivateStatus = useCallback(() => {
    setIsActivateConfirmed(!isActivateConfirmed);
    setBackgroundColor(
      !isActivateConfirmed ? theme.colors.funtainBlue : theme.colors.secondary
    );
  }, [isActivateConfirmed]);

  const toggleSuspendStatus = useCallback(() => {
    setIsSuspendConfirmed(!isSuspendConfirmed);
    setBackgroundColor(!isSuspendConfirmed ? theme.colors.secondary : '');
  }, [isSuspendConfirmed]);

  const toggleDeleteStatus = useCallback(() => {
    setIsDeleteConfirmed(!isDeleteConfirmed);

    if (!isDeleteConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else if (data.status === StatusBE.Suspend) {
      setBackgroundColor(theme.colors.secondary);
    } else {
      setBackgroundColor('');
    }
  }, [data.status, isDeleteConfirmed]);

  useOutsideClick(modalRef, () => setRender(false));

  useEffect(() => {
    const { status } = data;

    if (previousActiveStatus !== activeStatus) {
      setIsDeleteConfirmed(false);
      setIsSuspendConfirmed(false);
      setIsActivateConfirmed(false);

      if (status !== StatusBE.Suspend) {
        setBackgroundColor('');
      } else if (status === StatusBE.Suspend) {
        setBackgroundColor(theme.colors.secondary);
      }
    }
  }, [activeStatus, data, previousActiveStatus]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isStatusSuccess) setRender(false);
    }, CLOSE_DETAILS_MODAL_TIMER);

    return () => clearTimeout(timeout);
  }, [isStatusSuccess]);

  useEffect(() => {
    const { status } = data;

    if (status === StatusBE.Pending) {
      // create logic for pending status
      setActiveStatus(StatusFE.Active);
    } else if (status === StatusBE.Active) {
      setActiveStatus(StatusFE.Active);
    } else {
      setActiveStatus(StatusFE.Suspend);
      setBackgroundColor(theme.colors.secondary);
    }
  }, [data]);

  return (
    <DetailsModalContainer
      ref={modalRef}
      $shouldRender={shouldRender}
      onAnimationEnd={() => {
        if (!shouldRender) closeWindow();
      }}
    >
      <Sticky
        topOffset={-80}
        stickyStyle={{
          top: 85,
        }}
      >
        <DetailsModalContent $backgroundColor={backgroundColor}>
          {isStatusSuccess ? (
            <SuccessStage
              name={`${data.firstName} ${data.lastName}`}
              text={successText}
              title={successTitle}
            />
          ) : (
            <>
              <Header
                name={`${data.firstName} ${data.lastName}`}
                closeModal={() => setRender(false)}
                openEditingModalWindow={() => {
                  openEditingModalWindow();
                  setDataForEditing(data);
                }}
              />
              <ModalNavigation
                parameters={statusesFE}
                activeStatus={activeStatus}
                setActiveStatus={(index: number) =>
                  setActiveStatus(statusesFE[index])
                }
              />
              {activeStatus === StatusFE.Active && (
                <ReferrerAccountInformation
                  color={backgroundColor}
                  closeModal={() => setRender(false)}
                  isChecked={isActivateConfirmed}
                  toggleStatus={toggleActivateStatus}
                  accountStatus={data.status}
                  activateAccount={() => {
                    onSuccessModal(
                      'activated',
                      'Commission payments have been activated'
                    );
                    resetAllConfirmedStatuses();
                  }}
                  primaryContact={{
                    email: data.email,
                    mobile: {
                      code: data.mobile.code,
                      number: data.mobile.number,
                    },
                  }}
                  referralsInAccount={{
                    primaryReferralsCount: 4,
                    downlineReferralsCount: 1,
                  }}
                  commission={{
                    thisMonthCommission: 0,
                    allTimeCommission: 0,
                  }}
                  validation={{
                    bankValidation: 'Verified',
                    agreementsValidation: 'Accepted',
                  }}
                />
              )}
              {activeStatus === StatusFE.Suspend &&
                data.status !== StatusBE.Suspend && (
                  <ModalAction
                    buttonText="suspend"
                    isChecked={isSuspendConfirmed}
                    color={backgroundColor}
                    submitAction={() => {
                      onSuccessModal(
                        'suspended',
                        'Commissions will stop until reactivated'
                      );
                    }}
                    closeModal={() => setRender(false)}
                    toggleStatus={toggleSuspendStatus}
                    actionTypeText="YES SUSPEND THIS ACCOUNT"
                    note="Commission payments will stop until reactivated"
                  />
                )}
              {activeStatus === StatusFE.Delete && (
                <ModalAction
                  buttonText="delete"
                  isChecked={isDeleteConfirmed}
                  color={backgroundColor}
                  submitAction={() => {
                    onSuccessModal(
                      'deleted',
                      'Assigned referrals have reverted to Shido'
                    );
                    deleteItem(data.id);
                  }}
                  closeModal={() => setRender(false)}
                  toggleStatus={toggleDeleteStatus}
                  actionTypeText="YES DELETE THIS ACCOUNT"
                  note="Assigned referrals will revert to Shido"
                />
              )}
            </>
          )}
        </DetailsModalContent>
      </Sticky>
    </DetailsModalContainer>
  );
};

export default ReferrerDetailsModal;
