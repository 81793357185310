import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { TableRowGrid } from 'components/table/models';

import ManageRow from './ManageRow';
import ManageRowDetailsModal from './ManageRowDetailsModal';
import { ManageUserOnboardingItem } from './models';

type ManageContainerRowProps = {
  item: ManageUserOnboardingItem;
};

export type DetailsInfo = {
  photo: string;
  email: string;
  name: string;
};

const ManageContainerRow = ({ item }: ManageContainerRowProps) => {
  const [isRowModalOpen, setIsRowModalOpen] = useState(false);

  const toggleRowModal = useCallback(() => {
    setIsRowModalOpen(!isRowModalOpen);
  }, [isRowModalOpen]);

  return (
    <Container $isRowModalOpen={isRowModalOpen}>
      <ManageRow
        detailsInfo={{
          photo: item.photo,
          name: item.name,
          email: item.email,
        }}
        key={item.department}
        tableName={TableRowGrid.ManageUsers}
        data={[item.department, item.sex, item.age]}
        openModalWindow={toggleRowModal}
      />
      {isRowModalOpen && (
        <ManageRowDetailsModal offer={item} closeWindow={toggleRowModal} />
      )}
    </Container>
  );
};

const Container = styled.div<{ $isRowModalOpen?: boolean }>`
  position: relative;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  font-size: 12px;
  overflow: hidden;

  &:nth-child(even) {
    .row:first-child {
      background-color: ${({ theme }) => theme.colors.lightYellow};
    }
  }

  ${({ $isRowModalOpen }) =>
    $isRowModalOpen &&
    css`
      height: 356px;
    `}
`;

export default React.memo(ManageContainerRow);
