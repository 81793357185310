import { QueryFunctionContext, useQuery } from 'react-query';

import { QueryKey } from '../models';
import { AffiliateOffersResponse } from '../offersServices/AffiliateOffersService';

const useGetAffiliateOffers = (
  queryKeys: (string | number)[],
  queryFn: (data: QueryFunctionContext) => Promise<AffiliateOffersResponse>
) =>
  useQuery([QueryKey.GetAffiliateOffers, ...queryKeys], queryFn, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

export default useGetAffiliateOffers;
