import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { LogoIcon } from 'components/icons/LogoIcon';

import DetailsSection from './components/DetailsSection';
import IntroSection, {
  QrCodeContainer,
  QrCodeContainerInfo,
} from './components/IntroSection';
import ShidoIsTheBestAssistantSection from './components/ShidoIsTheBestAssistantSection';
import BackgroundGraph from './icons/BackgroundGraph';

export type DesktopPreviewProps = {
  disableInterval?: boolean;
  image: string | null;
  title: string;
  subtitle: string;
  bodyText: string;
  qrCodeUrl: string;
  term?: string;
  chosenColor: {
    light: string;
    strong: string;
  };
};

const DesktopPreview = ({
  qrCodeUrl,
  image,
  title,
  subtitle,
  bodyText,
  term,
  chosenColor,
  disableInterval,
}: DesktopPreviewProps) => {
  return (
    <>
      <IntroSection
        qrCodeUrl={qrCodeUrl}
        term={term}
        chosenColor={chosenColor}
        title={title}
        isDesktop
      />
      <DetailsSection
        subtitle={subtitle}
        body={bodyText}
        logoImage={image}
        isDesktop
      />
      <ShidoIsTheBestAssistantSection
        disableInterval={disableInterval}
        isDesktop
      />
      <Footer>
        <FreeTrial>Start a free {term} trial!</FreeTrial>
        <Subtitle>
          Scan the code with your mobile device to get the app!
        </Subtitle>
        <StyledQr>
          <QrCodeContainerInfo $isDesktop>
            <ReactSVG src={qrCodeUrl} />
          </QrCodeContainerInfo>
        </StyledQr>
        <ShidoIconWrapper>
          <ShidoTitle>
            <LogoIcon width={70} height={44} />
            <ShidoMark>®</ShidoMark>
          </ShidoTitle>
        </ShidoIconWrapper>
      </Footer>
      <BackgroundGraphWrapper>
        <BackgroundGraph color={chosenColor.light} />
      </BackgroundGraphWrapper>
    </>
  );
};

const BackgroundGraphWrapper = styled.div`
  z-index: 2;
  position: absolute;
  margin-top: 911px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Footer = styled.div`
  position: relative;
  z-index: 3;
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: -2px;
  right: -21px;
`;

const ShidoTitle = styled.div`
  position: relative;
`;

const ShidoIconWrapper = styled.div`
  margin: 43px auto 162px;
  width: 70px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledQr = styled(QrCodeContainer)`
  margin-top: 34px;
`;

const FreeTrial = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 93px;
`;

const Subtitle = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 20px;
  font-weight: 300;
`;

export default DesktopPreview;
