import React from 'react';
import styled from 'styled-components';

import SearchBarAction from './SearchBarAction';
import SearchBarResult from './SearchBarResult';

type SearchBarProps = {
  isEmployerPage?: boolean;
  isHiddenButton?: boolean;
  title: string;
  buttonText: string;
  nameToFilter: string;
  activeFilterParameter: string;
  openModalWindow: () => void;
  setNewNameToFilter: (value: string) => void;
};

const SearchBar = ({
  isEmployerPage,
  title,
  buttonText,
  nameToFilter,
  isHiddenButton,
  openModalWindow,
  setNewNameToFilter,
  activeFilterParameter,
}: SearchBarProps) => (
  <Container data-testid="search-bar-container">
    <SearchBarResult
      isEmployerPage={isEmployerPage}
      title={title}
      nameToFilter={nameToFilter}
      setNewNameToFilter={setNewNameToFilter}
      activeFilterParameter={activeFilterParameter}
    />
    <SearchBarAction
      isHiddenButton={isHiddenButton}
      buttonText={buttonText}
      nameToFilter={nameToFilter}
      openModalWindow={openModalWindow}
      setNewNameToFilter={setNewNameToFilter}
    />
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: -40px;
  width: 100%;
  height: 80px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 19px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 10px;
  }
`;

export default React.memo(SearchBar);
