import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';

export const checkValidAdminEmail = (
  email: string
): Promise<{ mobileNumberLastDigits: string }> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/Account/SendMobileSignInCode`, {
      email,
    })
    .then((response) => response.data.payload);

export const checkValidMobileCode = ({
  email,
  mobileSignInCode,
}: {
  email: string;
  mobileSignInCode: string;
}): Promise<{ token: string; refreshToken: string }> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/Token/MobileSignInCodeToken`, {
      email,
      mobileSignInCode,
    })
    .then((response) => response.data.payload);

export const checkUserRoles = (): Promise<{ roles: string[] }> =>
  axiosClient
    .get(`/api/v${config.apiVersion}/Account/UserRoles`)
    .then((response) => response.data.payload);

export const refreshRequest = ({
  accessToken,
  refreshToken,
}: {
  accessToken?: string;
  refreshToken?: string;
}): Promise<{ token: string; refreshToken: string }> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/Token/RefreshToken`, {
      token: accessToken,
      refreshToken: refreshToken,
    })
    .then((response) => response.data.payload);
