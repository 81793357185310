/* eslint-disable @typescript-eslint/no-explicit-any */
import { find } from 'lodash';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { ErrorMessage } from '@hookform/error-message';
import ActionButton from 'components/buttons/ActionButton';
import { VerticalLine } from 'components/Divider';
import ChevronIcon from 'components/icons/ChevronIcon';
import WarningIcon from 'components/icons/WarningIcon';
import {
  currencies,
  listLabels,
} from 'components/listForSelectingData/dataForLists';
import ListForSelectingDataFE from 'components/listForSelectingData/lists/ListForSelectingDataFE';
import patterns from 'helpers/patterns';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import {
  DropdownAndInput,
  DropdownAndInputContent,
  DropdownAndInputText,
  FormInput,
  FormLabel,
} from '../../styledElements/formsStyledElements';

type Props = {
  inputId: string;
  currency?: string;
  inputValue: string;
  errors: FieldErrors;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  saveUnitPrice: () => void;
  saveCurrency: (currency: string) => void;
};

const UnitPriceInput = ({
  watch,
  saveUnitPrice,
  saveCurrency,
  inputValue,
  currency,
  errors,
  register,
  setValue,
  inputId,
}: Props) => {
  const [isUnitPriceActive, setIsUnitPriceActive] = useState(false);
  const [isUnitPriceOpen, setIsUnitPriceOpen] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(patterns.unitPricePattern)) {
      const { value, name } = e.target;
      const dots = value.match(/[.]+/g);

      if (dots?.length === 1 || !dots) {
        setValue(name, value, { shouldValidate: true });
      }
    }
  };

  const saveChosenCurrency = useCallback(
    (index: string) => {
      const activeItemIndex = Number(index);

      saveCurrency(currencies[activeItemIndex].BEformat);
    },
    [saveCurrency]
  );

  const closeUnitPriceList = useCallback(() => setIsUnitPriceOpen(false), []);

  useLayoutEffect(() => {
    if (inputValue) {
      setIsUnitPriceActive(true);
      setValue(inputId, inputValue, { shouldValidate: true });
    }
  }, [inputId, inputValue, setValue]);

  return (
    <InputContainer
      $error={!!errors[inputId]}
      onClick={() => setIsUnitPriceActive(true)}
    >
      <FormLabel
        htmlFor={inputId}
        $error={!!errors[inputId]}
        $active={!!(isUnitPriceActive || inputValue)}
      >
        Unit price P/M
      </FormLabel>
      <DropdownAndInput>
        {isUnitPriceActive && (
          <>
            <UnitPriceDropdownAndInputContent>
              <DropdownAndInputText $color={theme.colors.funtainBlue}>
                {find(currencies, { BEformat: currency })?.displayName}
              </DropdownAndInputText>
              <ActionButton
                icon={
                  <ChevronIcon
                    color={theme.colors.funtainBlue}
                    width={10}
                    height={6}
                  />
                }
                clickHandler={() => setIsUnitPriceOpen(true)}
              />
              {isUnitPriceOpen && (
                <ListForSelectingDataFE
                  listWidth="sm"
                  listHeight="sm"
                  data={currencies}
                  label={listLabels.currency}
                  closeList={closeUnitPriceList}
                  setNewActiveListItem={saveChosenCurrency}
                />
              )}
            </UnitPriceDropdownAndInputContent>
            <VerticalLine $color={theme.colors.primary} $withOpacity />
          </>
        )}
        <Input
          id={inputId}
          {...register(inputId, {
            required: true,
          })}
          onBlur={saveUnitPrice}
          $error={!!errors[inputId]}
          onChange={(e) => changeInputHandler(e)}
          value={watch()[inputId] || ''}
          onFocus={() => setIsUnitPriceActive(true)}
        />
      </DropdownAndInput>
      <ErrorMessage
        name={inputId}
        errors={errors}
        render={({ message }) => (
          <ErrorText $error={!!errors[inputId]}>
            <WarningIcon color={theme.colors.primary} />
            {message}
          </ErrorText>
        )}
      />
    </InputContainer>
  );
};

const UnitPriceDropdownAndInputContent = styled(DropdownAndInputContent)`
  min-width: 61px;
  margin-top: 4px;
`;

const Input = styled(FormInput)`
  font-size: 12px;
  padding-bottom: 2px;
  margin-left: 9.5px;
`;

export default UnitPriceInput;
