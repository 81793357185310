import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import patterns from 'helpers/patterns';
import Header from 'modules/auth/components/Header';
import { AuthStage } from 'modules/auth/models';

import AsteriskPasswordFormTemplate from '../../../forms/AsteriskPasswordFormTemplate';

const CHOOSE_PASSWORD_INPUT_ID = 'password';

type EmployerChooseNewPasswordProps = {
  goToNextStage: (value: string) => void;
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('This field is required')
    .matches(patterns.passwordUpperCase)
    .matches(patterns.passwordNumberExist)
    .min(9),
});

const EmployerChooseNewPassword = ({
  goToNextStage,
}: EmployerChooseNewPasswordProps) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const passwordValue = watch()[CHOOSE_PASSWORD_INPUT_ID];

  const passPasswordToValidate = () => {
    goToNextStage(passwordValue);
  };

  return (
    <>
      <Header headerType={AuthStage.NewPassword} />
      <AsteriskPasswordFormTemplate
        isShowPasswordStrength
        inputId={CHOOSE_PASSWORD_INPUT_ID}
        register={register}
        isValid={isValid}
        watch={watch}
        handleSubmit={handleSubmit}
        goToNextStage={passPasswordToValidate}
      />
    </>
  );
};

export default EmployerChooseNewPassword;
