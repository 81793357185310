import React, { useCallback, useContext, useState } from 'react';

import Navbar from 'components/actionModal/Navbar';
import ReferrerAccountDetails from 'components/actionModal/stages/accountDetails/ReferrerAccountDetails';
import Result from 'components/actionModal/stages/Result';
import SelectCategory from 'components/actionModal/stages/SelectCategory';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';

import { ReferrerAccountsContext } from '../../context/ReferrerAccountsContext';
import { AccountCategory, AccountModalWindowStages } from '../../models';
import { dataForSelectingCategory } from '../CreateAccountModal';

const navbarItemsForReferrer = [
  {
    text: 'Select type',
    name: AccountModalWindowStages.SelectType,
  },
  {
    text: 'Account details',
    name: AccountModalWindowStages.AccountDetails,
  },
];

type CreateReferrerModalProps = {
  isNavbarTitleShown: boolean;
  activatedStages: AccountModalWindowStages[];
  closeModalWindow: () => void;
  showNavbarTitle: (value: boolean) => void;
  setActiveCategory: (category: AccountCategory) => void;
  setActivatedStages: (stage: AccountModalWindowStages) => void;
};

const CreateReferrerModal = ({
  activatedStages,
  showNavbarTitle,
  closeModalWindow,
  setActiveCategory,
  isNavbarTitleShown,
  setActivatedStages,
}: CreateReferrerModalProps) => {
  const [activeStage, setActiveStage] = useState<AccountModalWindowStages>(
    AccountModalWindowStages.SelectType
  );
  const { addNewAccount } = useContext(ReferrerAccountsContext);

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(navbarItemsForReferrer[stage].name),
    []
  );

  const goToSelectCategoryStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.SelectType);
  }, []);

  const goToAccountDetailsStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.AccountDetails);
    setActivatedStages(AccountModalWindowStages.AccountDetails);

    if (!isNavbarTitleShown) {
      showNavbarTitle(true);
    }
  }, [isNavbarTitleShown, setActivatedStages, showNavbarTitle]);

  const goToResultStage = useCallback(
    () => setActiveStage(AccountModalWindowStages.Result),
    []
  );

  return (
    <>
      {activeStage === AccountModalWindowStages.Result ? (
        <Result
          title="Referrer account created!"
          closeModal={closeModalWindow}
        />
      ) : (
        <>
          <Navbar
            title="Create account"
            activeStage={activeStage}
            showTitle={isNavbarTitleShown}
            accountCategory={AccountCategory.Referrer}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
            navbarItems={navbarItemsForReferrer}
          />
          <ActionModalMainContent>
            {activeStage === AccountModalWindowStages.SelectType && (
              <SelectCategory
                saveData={setActiveCategory}
                accountCategory={AccountCategory.Referrer}
                categories={dataForSelectingCategory}
                goToNextStage={goToAccountDetailsStage}
              />
            )}
            {activeStage === AccountModalWindowStages.AccountDetails && (
              <ReferrerAccountDetails
                title="Account details"
                goToNextStage={goToResultStage}
                submitButtonTitle="create"
                saveData={addNewAccount}
                goToPreviousStage={goToSelectCategoryStage}
              />
            )}
          </ActionModalMainContent>
        </>
      )}
    </>
  );
};

export default CreateReferrerModal;
