import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import { StatusBE } from 'components/detailsModal/models';

export type CorporateAccountDetailsData = {
  companyName: string;
  website: string;
  businessSector: string;
  country: string;
};

export type CorporateAccountPrimaryContactData = {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  mobileNumber: string;
  mobileNumberCode: string;
};

export interface CorporateAccount extends CorporateAccountDetailsData {
  id: string;
  status: StatusBE;
  createdDate: string;
  primaryContact: CorporateAccountPrimaryContactData;
}

export type PageInfo = {
  page: number;
  pageSize: number;
  sortBy: string;
  search: string;
};

type CorporateAccountsPayload = {
  data: PageInfo;
};

export type CorporateAccountsResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  items: CorporateAccount[];
};

export const getCorporateAccounts = ({
  data,
}: CorporateAccountsPayload): Promise<CorporateAccountsResponse> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/CorporateAccounts/GetPage`, data)
    .then((response) => response.data.payload.corporateAccounts);

interface CorporateAccountForCreation extends CorporateAccountDetailsData {
  primaryContact: CorporateAccountPrimaryContactData;
}

export type CreateCorporateAccountPayload = {
  data: CorporateAccountForCreation;
};

export const createCorporateAccount = ({
  data,
}: CreateCorporateAccountPayload) =>
  axiosClient
    .post(`/api/v${config.apiVersion}/CorporateAccounts`, data)
    .then((response) => response.data.payload);

type DeleteCorporateAccountPayload = {
  corporateAccountId: string;
};

export const deleteCorporateAccount = ({
  corporateAccountId,
}: DeleteCorporateAccountPayload) =>
  axiosClient.delete(
    `/api/v${config.apiVersion}/CorporateAccounts/${corporateAccountId}`
  );

type SuspendCorporateAccountPayload = {
  corporateAccountId: string;
};

export const suspendCorporateAccount = ({
  corporateAccountId,
}: SuspendCorporateAccountPayload) =>
  axiosClient.put(
    `/api/v${config.apiVersion}/CorporateAccounts/${corporateAccountId}/Suspend`,
    null
  );

type ActivateCorporateAccountPayload = {
  corporateAccountId: string;
};

export const activateCorporateAccount = ({
  corporateAccountId,
}: ActivateCorporateAccountPayload) =>
  axiosClient.put(
    `/api/v${config.apiVersion}/CorporateAccounts/${corporateAccountId}/Activate`,
    null
  );

export type EditCorporateAccountPayload = {
  data: CorporateAccount;
};

export const editCorporateAccount = ({ data }: EditCorporateAccountPayload) =>
  axiosClient.put(
    `/api/v${config.apiVersion}/CorporateAccounts/${data.id}`,
    data
  );

type GetCorporateAccount = {
  corporateAccountId: string;
};

export const getCorporateAccount = ({
  corporateAccountId,
}: GetCorporateAccount) =>
  axiosClient.get(
    `/api/v${config.apiVersion}/CorporateAccounts/${corporateAccountId}`
  );
