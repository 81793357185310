import { CorporateOffer } from 'modules/offers/offersServices/CorporateOffersService';

export const CLOSE_DETAILS_MODAL_TIMER = 2000;

export enum StatusBE {
  Active = 'Active',
  Draft = 'Draft',
  Suspend = 'Suspended',
  Pending = 'Pending',
  Expired = 'Expired',
}

export enum RequestStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL',
}

export enum StatusFE {
  Active = 'ACTIVE',
  Suspend = 'SUSPEND',
  Delete = 'DELETE',
}

export type OfferPageData = {
  code: string;
  url: string;
};

export type CorporateOfferDownloadDetails = {
  offer: CorporateOffer;
};

export const statusesFE = [StatusFE.Active, StatusFE.Suspend, StatusFE.Delete];
