const NUMBER_OF_ITEMS_TO_SLICE = -2;

// dd.mm.yy - return value
export const utcToCustom = (dateUTC: string | Date) => {
  const date = new Date(dateUTC);
  const day = `0${date.getDate()}`.slice(NUMBER_OF_ITEMS_TO_SLICE);
  const month = `0${date.getMonth() + 1}`.slice(NUMBER_OF_ITEMS_TO_SLICE);
  const year = `${date.getFullYear()}`.slice(NUMBER_OF_ITEMS_TO_SLICE);

  return `${day}.${month}.${year}`;
};

// dd month yyyy - return value
export const utcToCustomV2 = (
  dateUTC: string | Date,
  isShortMonth?: boolean
) => {
  const date = new Date(dateUTC);
  const day = `0${date.getDate()}`.slice(NUMBER_OF_ITEMS_TO_SLICE);
  const month = date.toLocaleString('default', {
    month: isShortMonth ? 'short' : 'long',
  });
  const year = `${date.getFullYear()}`;

  return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
};
