import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';
import DesktopIcon from 'components/icons/DesktopIcon';
import MobileIcon from 'components/icons/MobileIcon';
import ShidoWithSignIcon from 'components/icons/ShidoWithSignIcon';
import TabletIcon from 'components/icons/TabletIcon';

import { Device } from './models';

export const devices = [
  {
    device: Device.Mobile,
    icon: <MobileIcon color={theme.colors.white} />,
  },
  {
    device: Device.Tablet,
    icon: <TabletIcon color={theme.colors.white} />,
  },
  {
    device: Device.Desktop,
    icon: <DesktopIcon color={theme.colors.white} />,
  },
];

type PreviewHeaderProps = {
  title: string;
  activeDevice: Device;
  closePreview: () => void;
  setActiveDevice: (device: Device) => void;
};

const PreviewHeader = ({
  title,
  activeDevice,
  closePreview,
  setActiveDevice,
}: PreviewHeaderProps) => (
  <Container data-testid="preview-header-container">
    <LeftSide>
      <ShidoWithSignIcon width={64} height={41} color={theme.colors.white} />
      <p>preview {title} page</p>
    </LeftSide>
    <RightSide>
      <Devices>
        {devices.map(({ icon, device }) => (
          <React.Fragment key={device}>
            <ButtonContainer
              data-testid={`${device}-device-button`}
              $isActive={device === activeDevice}
              onClick={() => setActiveDevice(device)}
            >
              <ActionButton icon={icon} />
            </ButtonContainer>
            <VerticalLine
              $height={40}
              $withOpacity
              $color={theme.colors.white}
            />
          </React.Fragment>
        ))}
      </Devices>
      <ClosePreview>
        <VerticalLine $height={40} $withOpacity $color={theme.colors.white} />
        <ActionButton
          icon={
            <CloseIcon
              prefix="in_preview"
              size={20}
              color={theme.colors.white}
            />
          }
          clickHandler={closePreview}
        />
      </ClosePreview>
    </RightSide>
  </Container>
);

const Container = styled.div`
  z-index: 100;
  width: 100%;
  height: 81px;
  display: flex;
  padding: 0 40px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.darkBlue};
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;

  p {
    position: relative;
    top: 3px;
    font-size: 12px;
    margin-left: 7px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
  }

  div:first-child {
    margin-right: 15px;
  }
`;

const RightSide = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Devices = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 63px;

  div:last-child {
    display: none;
  }

  button:nth-child(2) {
    padding-left: 3px;
  }
`;

const ClosePreview = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 19px;
  }
`;

const ButtonContainer = styled.div<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 58px;
  box-sizing: border-box;
  z-index: 5;
  cursor: pointer;
  padding-top: 2px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ${({ $isActive }) =>
      $isActive &&
      css`
        border-bottom: 4px solid ${({ theme }) => theme.colors.funtainBlue};
      `};
  }
`;

export default PreviewHeader;
