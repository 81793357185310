import React from 'react';
import styled, { css } from 'styled-components';

type FontSize = 'sm' | 'md';

const fontSizes = {
  sm: '12px',
  md: '14px',
};

type Props = {
  isActive: boolean;
  parameter: string;
  fontSize: FontSize;
  textColor?: string;
  buttonColor?: string;
  clickHandler?: () => void;
};

const RadioButton = ({
  fontSize,
  isActive,
  parameter,
  textColor,
  buttonColor,
  clickHandler,
}: Props) => (
  <Container data-testid="navigation-button" onClick={clickHandler}>
    <Button $color={buttonColor} $isActive={isActive} />
    <Text $fontSize={fontSizes[fontSize]} $color={textColor}>
      {parameter.toLowerCase()}
    </Text>
  </Container>
);

const Button = styled.div<{ $isActive?: boolean; $color?: string }>`
  position: relative;
  width: 14px;
  height: 14px;
  border: 1px solid
    ${({ $color, theme }) => ($color ? $color : theme.colors.funtainBlue)};
  background: none;
  border-radius: 50%;
  margin-right: 9px;
  ${({ $isActive, $color }) =>
    $isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background-color: ${({ theme }) =>
          $color ? $color : theme.colors.funtainBlue};
        border-radius: 50%;
      }
    `}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
`;

const Text = styled.p<{ $fontSize: string; $color?: string }>`
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}

  &::first-letter {
    text-transform: uppercase;
  }
`;

export default RadioButton;
