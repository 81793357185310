import { filter } from 'lodash';
import React, { createContext, ReactNode, useCallback, useState } from 'react';

import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
} from 'components/detailsModal/models';
import { TemporaryStatus } from 'components/table/models';

import {
  AccountCategory,
  ReferrerAccount,
  ReferrerAccountDetailsData,
} from '../models';
import referrerAccountsMockData from '../referrerAccountsMockData';

const ID_MULTIPLIER = 1000;

type ReferrerAccountsContextType = {
  accounts: ReferrerAccount[];
  deleteAccount: (id: string) => void;
  addNewAccount: (account: ReferrerAccountDetailsData) => void;
  editAccount: (account: ReferrerAccountDetailsData, id: string) => void;
  setNewStatusForAccount: (
    temporaryStatus: TemporaryStatus,
    id: string,
    status?: StatusBE
  ) => void;
};

const initialValue: ReferrerAccountsContextType = {
  accounts: referrerAccountsMockData,
  deleteAccount: () => null,
  addNewAccount: () => null,
  editAccount: () => null,
  setNewStatusForAccount: () => null,
};

export const ReferrerAccountsContext =
  createContext<ReferrerAccountsContextType>(initialValue);

const ReferrerAccountsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [accounts, setAccounts] = useState(referrerAccountsMockData);

  const deleteAccount = useCallback(
    (id: string) => {
      const newAccounts = filter(accounts, (account) => account.id !== id);

      setTimeout(() => setAccounts(newAccounts), CLOSE_DETAILS_MODAL_TIMER);
    },
    [accounts]
  );

  const addNewAccount = useCallback(
    (account: ReferrerAccountDetailsData) => {
      setAccounts([
        ...accounts,
        {
          ...account,
          status: StatusBE.Pending,
          id: String(Math.random() * ID_MULTIPLIER),
          category: AccountCategory.Referrer,
          temporaryStatus: undefined,
        },
      ]);
    },
    [accounts]
  );

  const setNewStatusForAccount = useCallback(
    (temporaryStatus: TemporaryStatus, id: string, status?: StatusBE) => {
      const newAccounts = accounts.map((account) => {
        if (account.id === id) {
          if (status) {
            return { ...account, status, temporaryStatus };
          }

          return { ...account, temporaryStatus };
        }

        return account;
      });

      setTimeout(() => setAccounts(newAccounts), CLOSE_DETAILS_MODAL_TIMER);
    },
    [accounts]
  );

  const editAccount = useCallback(
    (data: ReferrerAccountDetailsData, id: string) => {
      const newAccounts = accounts.map((account) => {
        if (account.id === id) {
          return { ...account, ...data };
        }

        return account;
      });

      setAccounts(newAccounts);
    },
    [accounts]
  );

  return (
    <ReferrerAccountsContext.Provider
      value={{
        accounts,
        deleteAccount,
        addNewAccount,
        editAccount,
        setNewStatusForAccount,
      }}
    >
      {children}
    </ReferrerAccountsContext.Provider>
  );
};

export default ReferrerAccountsContextProvider;
