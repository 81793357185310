/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { ErrorMessage } from '@hookform/error-message';
import DefaultButton from 'components/buttons/DefaultButton';
import WarningIcon from 'components/icons/WarningIcon';
import Loader from 'components/Loader';

import {
  ContinueButtonContainer,
  ErrorText,
  FormContainer,
  FormInput,
  FormLabel,
  InputContainer,
  LoaderContainer,
} from '../components/styledElements/formsStyledElements';

type EmailFormProps = {
  inputId: string;
  isLoading?: boolean;
  errors: FieldErrors;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  isValid: boolean;
  passEmailToValidate: (email: string) => void;
};

const EmailFormTemplate = ({
  inputId,
  isLoading = false,
  watch,
  errors,
  setValue,
  handleSubmit,
  isValid,
  register,
  passEmailToValidate,
}: EmailFormProps) => {
  const emailValue = watch()[inputId];
  const [active, setActive] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(inputId, value, { shouldValidate: true });
  };

  return (
    <>
      {!isLoading ? (
        <FormContainer
          onSubmit={handleSubmit(() => passEmailToValidate(emailValue))}
        >
          <Input $error={!!errors[inputId]}>
            <FormLabel
              htmlFor={inputId}
              $error={!!errors[inputId]}
              $active={active}
            >
              Enter your email address
            </FormLabel>
            <FormInput
              id={inputId}
              type="text"
              autoComplete="off"
              {...register(inputId, {
                required: true,
              })}
              onFocus={() => setActive(true)}
              onChange={changeInputHandler}
              $error={!!errors[inputId]}
            />
            <ErrorMessage
              name={inputId}
              errors={errors}
              render={({ message }) => (
                <ErrorText $error={!!errors[inputId]}>
                  <WarningIcon color={theme.colors.primary} />
                  {message}
                </ErrorText>
              )}
            />
          </Input>
          <ContinueButtonContainer>
            <DefaultButton
              isSubmit
              withShadow
              borderSize="lg"
              buttonSize="lg"
              text="continue"
              isActive={isValid}
              isUppercase
              textColor={theme.colors.white}
              buttonColor={
                isValid ? theme.colors.funtainBlue : theme.colors.quillGray
              }
            />
          </ContinueButtonContainer>
        </FormContainer>
      ) : (
        <LoaderContainer>
          <Loader
            circles={[
              {
                color: theme.colors.darkBlue,
                speed: 2,
                size: 140,
              },
            ]}
          />
        </LoaderContainer>
      )}
    </>
  );
};

const Input = styled(InputContainer)`
  height: 58px;

  input {
    padding: 0;
    margin-bottom: 6px;
  }
`;

export default EmailFormTemplate;
