import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';

type ConversionDataReturnType = Blob | null;

export const getAffiliateReferralsReport = async (
  affiliateOfferId: string
): Promise<ConversionDataReturnType> => {
  try {
    const response = await axiosClient.get(
      `/api/v${config.apiVersionFive}/AffiliateOffers/${affiliateOfferId}/csvreports/referrals`,
      {
        responseType: 'blob',
      }
    );

    return response.data || null;
  } catch {
    return null;
  }
};

export const getAffiliateUnconvertedReferralsReport = async (
  affiliateOfferId: string
): Promise<ConversionDataReturnType> => {
  try {
    const response = await axiosClient.get(
      `${config.apiUrl}/api/v${config.apiVersionFive}/AffiliateOffers/${affiliateOfferId}/csvreports/referrals/unconverted`,
      {
        responseType: 'blob',
      }
    );

    return response.data || null;
  } catch {
    return null;
  }
};

export const getAffiliateSubscribersReport = async (
  affiliateOfferId: string,
  currentMonthOnly = false
): Promise<ConversionDataReturnType> => {
  try {
    const response = await axiosClient.get(
      `${config.apiUrl}/api/v${config.apiVersionFive}/AffiliateOffers/${affiliateOfferId}/csvreports/subscribers`,
      {
        params: {
          currentMonthOnly,
        },
        responseType: 'blob',
      }
    );

    return response.data || null;
  } catch {
    return null;
  }
};

export const getAffiliateUnsubscribedReport = async (
  affiliateOfferId: string
): Promise<ConversionDataReturnType> => {
  try {
    const response = await axiosClient.get(
      `${config.apiUrl}/api/v${config.apiVersionFive}/AffiliateOffers/${affiliateOfferId}/csvreports/subscribers/unsubscribed`,
      {
        responseType: 'blob',
      }
    );

    return response.data || null;
  } catch {
    return null;
  }
};
