import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import DefaultButton from 'components/buttons/DefaultButton';
import {
  businessSectors,
  listLabels,
} from 'components/listForSelectingData/dataForLists';
import { CorporateAccountDetailsData } from 'modules/accounts/accountsServices/CorporateAccountsService';

import CountryDropdown from '../../forms/dropdowns/CountryDropdown';
import StandardDropdown from '../../forms/dropdowns/StandardDropdown';
import StandardInput from '../../forms/inputs/StandardInput';
import { InputId } from '../../models';
import { corporateAccountDetailsValidationSchema } from '../../models/validationSchemas';
import {
  FormContainer,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';

type CorporateAccountDetailsProps = {
  title: string;
  accountDetails: CorporateAccountDetailsData;
  goToNextStage: () => void;
  goToPreviousStage?: () => void;
  saveAccountDetailsData: (accountDetails: CorporateAccountDetailsData) => void;
  toggleNextStage?: (value?: boolean) => void;
};

const CorporateAccountDetails = ({
  title,
  accountDetails,
  goToNextStage,
  goToPreviousStage,
  saveAccountDetailsData,
  toggleNextStage,
}: CorporateAccountDetailsProps) => {
  const [isBusinessSectorValid, setIsBusinessSectorValid] = useState(false);
  const [isCountryValid, setIsCountryValid] = useState(false);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(corporateAccountDetailsValidationSchema),
  });

  const isFormValid = isValid && isBusinessSectorValid && isCountryValid;

  const saveCompany = useCallback(
    () =>
      saveAccountDetailsData({
        ...accountDetails,
        companyName: watch()[InputId.CompanyNameInputId],
      }),
    [accountDetails, saveAccountDetailsData, watch]
  );

  useEffect(() => {
    if (isFormValid && toggleNextStage) {
      toggleNextStage();
    } else if (!isFormValid && toggleNextStage) {
      toggleNextStage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  const saveWebsite = useCallback(
    () =>
      saveAccountDetailsData({
        ...accountDetails,
        website: watch()[InputId.WebsiteInputId],
      }),
    [accountDetails, saveAccountDetailsData, watch]
  );

  const saveBusinessSector = useCallback(
    (businessSector: string) =>
      saveAccountDetailsData({ ...accountDetails, businessSector }),
    [accountDetails, saveAccountDetailsData]
  );

  const checkBusinessSectorValidation = useCallback(
    (isValid: boolean) => setIsBusinessSectorValid(isValid),
    []
  );

  const saveCountry = useCallback(
    (country: string) => saveAccountDetailsData({ ...accountDetails, country }),
    [accountDetails, saveAccountDetailsData]
  );

  const checkCountryValidation = useCallback(
    (isValid: boolean) => setIsCountryValid(isValid),
    []
  );

  const formSubmit = () => {
    saveAccountDetailsData({
      ...accountDetails,
      companyName: watch()[InputId.CompanyNameInputId],
      website: watch()[InputId.WebsiteInputId],
    });
    goToNextStage();
  };

  return (
    <div>
      <Title>{title}</Title>
      <FormContainer onSubmit={handleSubmit(formSubmit)}>
        <StandardInput
          errors={errors}
          register={register}
          setValue={setValue}
          label="Company name"
          inputId={InputId.CompanyNameInputId}
          inputValue={accountDetails.companyName}
          saveData={saveCompany}
        />
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          inputValue={accountDetails.website}
          label="Website"
          inputId={InputId.WebsiteInputId}
          saveData={saveWebsite}
        />
        <StandardDropdown
          inputLabel="Business sector"
          listLabel={listLabels.businessSector}
          list={businessSectors}
          saveData={saveBusinessSector}
          inputValue={accountDetails.businessSector}
          checkValidation={checkBusinessSectorValidation}
        />
        <CountryDropdown
          isOutOfBounds
          saveData={saveCountry}
          inputValue={accountDetails.country}
          checkValidation={checkCountryValidation}
        />
        <SubmitButtonContainer>
          {goToPreviousStage && (
            <DefaultButton
              text="back"
              textColor={theme.colors.white}
              isActive
              withShadow
              buttonColor={theme.colors.funtainBlue}
              isUppercase
              buttonSize="md"
              borderSize="md"
              clickHandler={goToPreviousStage}
            />
          )}
          <DefaultButton
            text="next"
            textColor={theme.colors.white}
            isActive={isFormValid}
            isSubmit
            withShadow
            buttonColor={
              isFormValid ? theme.colors.funtainBlue : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
    </div>
  );
};

export default CorporateAccountDetails;
