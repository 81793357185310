import { useQuery } from 'react-query';

import { getAffiliateOffers } from 'modules/offers/offersServices/AffiliateOffersService';
import { getCorporateOffers } from 'modules/offers/offersServices/CorporateOffersService';
import { getPromotionOffers } from 'modules/offers/offersServices/PromotionOffersService';

const useOffersDashboard = () => {
  const { data, isLoading } = useQuery('GetDashboardOffers', async () => {
    const [affiliateResponse, corporateResponse, promotionResponse] =
      await Promise.all([
        getAffiliateOffers({
          data: {
            page: 1,
            pageSize: 1000,
            search: '',
            sortBy: 'Latest',
          },
        }),
        getCorporateOffers({
          data: {
            page: 1,
            pageSize: 1000,
            search: '',
            sortBy: 'Latest',
          },
        }),
        getPromotionOffers({
          data: {
            page: 1,
            pageSize: 1000,
            search: '',
            sortBy: 'Latest',
          },
        }),
      ]);

    return {
      affiliateOffers: {
        items: affiliateResponse.items,
        totalCount: affiliateResponse.totalCount,
      },
      corporateOffers: {
        items: corporateResponse.items,
        totalCount: corporateResponse.totalCount,
      },
      promotionOffers: {
        items: promotionResponse.items,
        totalCount: promotionResponse.totalCount,
      },
    };
  });

  return {
    affiliateOffersData: data?.affiliateOffers,
    corporateOffersData: data?.corporateOffers,
    promotionOffersData: data?.promotionOffers,
    isLoading,
  };
};

export default useOffersDashboard;
