import { useEffect } from 'react';

import { AffiliateOfferData, AffiliateOfferResponse } from '../models';

const useSaveDataForEditAffiliateOffer = (
  affiliateOffer: AffiliateOfferResponse,
  setSaved: (value: boolean) => void,
  saveAllOfferData: (data: {
    settings: AffiliateOfferData;
    affiliateOfferId: string;
    status: string;
  }) => void,
  setLogoImage: (url: string) => void,
  setLogoName: (name: string) => void,
  saveAccount: (id: string) => void,
  saved?: boolean
) => {
  useEffect(() => {
    if (!saved) {
      const {
        status,
        id,
        affiliateHandle,
        affiliateName,
        affiliateStatement,
        intro,
        bodyText,
        backgroundColour,
        conversionFunnel,
        imageUrl,
      } = affiliateOffer;

      saveAllOfferData({
        affiliateOfferId: id,
        status,
        settings: {
          offerStage: {
            affiliateHandle,
            affiliateName,
            affiliateStatement,
            intro,
            bodyText,
          },
          settingsStage: {
            conversionFunnel,
            backgroundColour,
          },
        },
      });
      setLogoImage(imageUrl as string);
      setLogoName('image');

      setSaved(true);
    }
  }, [
    saved,
    setSaved,
    setLogoImage,
    setLogoName,
    saveAccount,
    saveAllOfferData,
    affiliateOffer,
  ]);
};

export default useSaveDataForEditAffiliateOffer;
