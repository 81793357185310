const countries = [
  {
    id: '0',
    abbr: 'AF',
    displayName: 'Afghanistan',
    BEformat: 'Afghanistan',
    code: '+93',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AF.svg',
  },
  {
    id: '1',
    abbr: 'AL',
    displayName: 'Albania',
    BEformat: 'Albania',
    code: '+355',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AL.svg',
  },
  {
    id: '2',
    abbr: 'DZ',
    displayName: 'Algeria',
    BEformat: 'Algeria',
    code: '+213',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/DZ.svg',
  },
  {
    id: '3',
    abbr: 'AD',
    displayName: 'Andorra',
    BEformat: 'Andorra',
    code: '+376',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AD.svg',
  },
  {
    id: '4',
    abbr: 'AO',
    displayName: 'Angola',
    BEformat: 'Angola',
    code: '+244',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AO.svg',
  },
  {
    id: '5',
    abbr: 'AG',
    displayName: 'Antigua and Barbuda',
    BEformat: 'AntiguaAndBarbuda',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AG.svg',
  },
  {
    id: '6',
    abbr: 'AR',
    displayName: 'Argentina',
    BEformat: 'Argentina',
    code: '+54',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AR.svg',
  },
  {
    id: '7',
    abbr: 'AM',
    displayName: 'Armenia',
    BEformat: 'Armenia',
    code: '+374',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AM.svg',
  },
  {
    id: '8',
    abbr: 'AU',
    displayName: 'Australia',
    BEformat: 'Australia',
    code: '+61',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AU.svg',
  },
  {
    id: '9',
    abbr: 'AT',
    displayName: 'Austria',
    BEformat: 'Austria',
    code: '+43',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AT.svg',
  },
  {
    id: '10',
    abbr: 'AZ',
    displayName: 'Azerbaijan',
    BEformat: 'Azerbaijan',
    code: '+994',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AZ.svg',
  },
  {
    id: '11',
    abbr: 'BS',
    displayName: 'Bahamas',
    BEformat: 'Bahamas',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BS.svg',
  },
  {
    id: '12',
    abbr: 'BH',
    displayName: 'Bahrain',
    BEformat: 'Bahrain',
    code: '+973',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BH.svg',
  },
  {
    id: '13',
    abbr: 'BD',
    displayName: 'Bangladesh',
    BEformat: 'Bangladesh',
    code: '+880',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BD.svg',
  },
  {
    id: '14',
    abbr: 'BB',
    displayName: 'Barbados',
    BEformat: 'Barbados',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BB.svg',
  },
  {
    id: '15',
    abbr: 'BY',
    displayName: 'Belarus',
    BEformat: 'Belarus',
    code: '+375',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BY.svg',
  },
  {
    id: '16',
    abbr: 'BE',
    displayName: 'Belgium',
    BEformat: 'Belgium',
    code: '+32',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BE.svg',
  },
  {
    id: '17',
    abbr: 'BZ',
    displayName: 'Belize',
    BEformat: 'Belize',
    code: '+501',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BZ.svg',
  },
  {
    id: '18',
    abbr: 'BJ',
    displayName: 'Benin',
    BEformat: 'Benin',
    code: '+229',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BJ.svg',
  },
  {
    id: '19',
    abbr: 'BT',
    displayName: 'Bhutan',
    BEformat: 'Bhutan',
    code: '+975',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BT.svg',
  },
  {
    id: '20',
    abbr: 'BO',
    displayName: 'Bolivia',
    BEformat: 'Bolivia',
    code: '+591',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BO.svg',
  },
  {
    id: '21',
    abbr: 'BA',
    displayName: 'Bosnia and Herzegovina',
    BEformat: 'BosniaAndHerzegovina',
    code: '+387',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BA.svg',
  },
  {
    id: '22',
    abbr: 'BW',
    displayName: 'Botswana',
    BEformat: 'Botswana',
    code: '+267',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BW.svg',
  },
  {
    id: '23',
    abbr: 'BR',
    displayName: 'Brazil',
    BEformat: 'Brazil',
    code: '+55',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BR.svg',
  },
  {
    id: '24',
    abbr: 'BN',
    displayName: 'Brunei Darussalam',
    BEformat: 'Brunei',
    code: '+673',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BN.svg',
  },
  {
    id: '25',
    abbr: 'BG',
    displayName: 'Bulgaria',
    BEformat: 'Bulgaria',
    code: '+359',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BG.svg',
  },
  {
    id: '26',
    abbr: 'BF',
    displayName: 'Burkina Faso',
    BEformat: 'BurkinaFaso',
    code: '+226',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BF.svg',
  },
  {
    id: '27',
    abbr: 'MM',
    displayName: 'Burma',
    BEformat: 'MyanmarFormerlyBurma',
    code: '+95',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MM.svg',
  },
  {
    id: '28',
    abbr: 'BI',
    displayName: 'Burundi',
    BEformat: 'Burundi',
    code: '+257',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/BI.svg',
  },
  {
    id: '29',
    abbr: 'KH',
    displayName: 'Cambodia',
    BEformat: 'Cambodia',
    code: '+855',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KH.svg',
  },
  {
    id: '30',
    abbr: 'CM',
    displayName: 'Cameroon',
    BEformat: 'Cameroon',
    code: '+237',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CM.svg',
  },
  {
    id: '31',
    abbr: 'CA',
    displayName: 'Canada',
    BEformat: 'Canada',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CA.svg',
  },
  {
    id: '32',
    abbr: 'CV',
    displayName: 'Cape Verde',
    BEformat: 'CaboVerde',
    code: '+238',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CV.svg',
  },
  {
    id: '33',
    abbr: 'CF',
    displayName: 'Central African Republic',
    BEformat: 'CentralAfricanRepublic',
    code: '+236',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CF.svg',
  },
  {
    id: '34',
    abbr: 'TD',
    displayName: 'Chad',
    BEformat: 'Chad',
    code: '+235',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TD.svg',
  },
  {
    id: '35',
    abbr: 'CL',
    displayName: 'Chile',
    BEformat: 'Chile',
    code: '+56',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CL.svg',
  },
  {
    id: '36',
    abbr: 'CN',
    displayName: 'China',
    BEformat: 'China',
    code: '+86',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CN.svg',
  },
  {
    id: '37',
    abbr: 'CO',
    displayName: 'Colombia',
    BEformat: 'Colombia',
    code: '+57',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CO.svg',
  },
  {
    id: '38',
    abbr: 'KM',
    displayName: 'Comoros',
    BEformat: 'Comoros',
    code: '+269',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KM.svg',
  },
  {
    id: '39',
    abbr: 'CG',
    displayName: 'Congo',
    BEformat: 'CongoCongoBrazzaville',
    code: '+242',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CG.svg',
  },
  {
    id: '40',
    abbr: 'CR',
    displayName: 'Costa Rica',
    BEformat: 'CostaRica',
    code: '+506',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CR.svg',
  },
  {
    id: '41',
    abbr: 'CI',
    displayName: "Cote D'Ivoire",
    BEformat: 'CôteDIvoire',
    code: '+225',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CI.svg',
  },
  {
    id: '42',
    abbr: 'HR',
    displayName: 'Croatia',
    BEformat: 'Croatia',
    code: '+385',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/HR.svg',
  },
  {
    id: '43',
    abbr: 'CU',
    displayName: 'Cuba',
    BEformat: 'Cuba',
    code: '+53',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CU.svg',
  },
  {
    id: '44',
    abbr: 'CY',
    displayName: 'Cyprus',
    BEformat: 'Cyprus',
    code: '+357',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg',
  },
  {
    id: '45',
    abbr: 'CZ',
    displayName: 'Czech Republic',
    BEformat: 'CzechiaCzechRepublic',
    code: '+420',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CZ.svg',
  },
  {
    id: '46',
    abbr: 'CD',
    displayName: 'Democratic Republic of the Congo',
    BEformat: 'DemocraticRepublicOfTheCongo',
    code: '+243',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CD.svg',
  },
  {
    id: '47',
    abbr: 'DK',
    displayName: 'Denmark',
    BEformat: 'Denmark',
    code: '+45',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/DK.svg',
  },
  {
    id: '48',
    abbr: 'DJ',
    displayName: 'Djibouti',
    BEformat: 'Djibouti',
    code: '+253',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/DJ.svg',
  },
  {
    id: '49',
    abbr: 'DM',
    displayName: 'Dominica',
    BEformat: 'Dominica',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/DM.svg',
  },
  {
    id: '50',
    abbr: 'DO',
    displayName: 'Dominican Republic',
    BEformat: 'DominicanRepublic',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/DO.svg',
  },
  {
    id: '51',
    abbr: 'EC',
    displayName: 'Ecuador',
    BEformat: 'Ecuador',
    code: '+593',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/EC.svg',
  },
  {
    id: '52',
    abbr: 'EG',
    displayName: 'Egypt',
    BEformat: 'Egypt',
    code: '+20',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/EG.svg',
  },
  {
    id: '53',
    abbr: 'SV',
    displayName: 'El Salvador',
    BEformat: 'ElSalvador',
    code: '+503',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SV.svg',
  },
  {
    id: '54',
    abbr: 'GQ',
    displayName: 'Equatorial Guinea',
    BEformat: 'EquatorialGuinea',
    code: '+240',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GQ.svg',
  },
  {
    id: '55',
    abbr: 'ER',
    displayName: 'Eritrea',
    BEformat: 'Eritrea',
    code: '+291',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ER.svg',
  },
  {
    id: '56',
    abbr: 'EE',
    displayName: 'Estonia',
    BEformat: 'Estonia',
    code: '+372',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/EE.svg',
  },
  {
    id: '57',
    abbr: 'SZ',
    displayName: 'Eswatini',
    BEformat: 'EswatiniFmrSwaziland',
    code: '+268',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SZ.svg',
  },
  {
    id: '58',
    abbr: 'ET',
    displayName: 'Ethiopia',
    BEformat: 'Ethiopia',
    code: '+251',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ET.svg',
  },
  {
    id: '59',
    abbr: 'FM',
    displayName: 'Federated States of Micronesia',
    BEformat: 'Micronesia',
    code: '+691',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/FM.svg',
  },
  {
    id: '60',
    abbr: 'FJ',
    displayName: 'Fiji',
    BEformat: 'Fiji',
    code: '+679',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/FJ.svg',
  },
  {
    id: '61',
    abbr: 'FI',
    displayName: 'Finland',
    BEformat: 'Finland',
    code: '+358',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/FI.svg',
  },
  {
    id: '62',
    abbr: 'FR',
    displayName: 'France',
    BEformat: 'France',
    code: '+33',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/FR.svg',
  },
  {
    id: '63',
    abbr: 'GA',
    displayName: 'Gabon',
    BEformat: 'Gabon',
    code: '+241',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GA.svg',
  },
  {
    id: '64',
    abbr: 'GM',
    displayName: 'Gambia',
    BEformat: 'Gambia',
    code: '+220',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GM.svg',
  },
  {
    id: '65',
    abbr: 'GE',
    displayName: 'Georgia',
    BEformat: 'Georgia',
    code: '+995',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GE.svg',
  },
  {
    id: '66',
    abbr: 'DE',
    displayName: 'Germany',
    BEformat: 'Germany',
    code: '+49',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/DE.svg',
  },
  {
    id: '67',
    abbr: 'GH',
    displayName: 'Ghana',
    BEformat: 'Ghana',
    code: '+233',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GH.svg',
  },
  {
    id: '68',
    abbr: 'GR',
    displayName: 'Greece',
    BEformat: 'Greece',
    code: '+30',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GR.svg',
  },
  {
    id: '69',
    abbr: 'GD',
    displayName: 'Grenada',
    BEformat: 'Grenada',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GD.svg',
  },
  {
    id: '70',
    abbr: 'GT',
    displayName: 'Guatemala',
    BEformat: 'Guatemala',
    code: '+502',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GT.svg',
  },
  {
    id: '71',
    abbr: 'GN',
    displayName: 'Guinea',
    BEformat: 'Guinea',
    code: '+224',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GN.svg',
  },
  {
    id: '72',
    abbr: 'GW',
    displayName: 'Guinea-Bissau',
    BEformat: 'GuineaBissau',
    code: '+245',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GW.svg',
  },
  {
    id: '73',
    abbr: 'GY',
    displayName: 'Guyana',
    BEformat: 'Guyana',
    code: '+592',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GY.svg',
  },
  {
    id: '74',
    abbr: 'HT',
    displayName: 'Haiti',
    BEformat: 'Haiti',
    code: '+509',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/HT.svg',
  },
  {
    id: '75',
    abbr: 'VE',
    displayName: 'Holy See (Vatican City State)',
    BEformat: 'Holy',
    code: '+58',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/VE.svg',
  },
  {
    id: '76',
    abbr: 'HN',
    displayName: 'Honduras',
    BEformat: 'Honduras',
    code: '+504',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/HN.svg',
  },
  {
    id: '77',
    abbr: 'HU',
    displayName: 'Hungary',
    BEformat: 'Hungary',
    code: '+36',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/HU.svg',
  },
  {
    id: '78',
    abbr: 'IS',
    displayName: 'Iceland',
    BEformat: 'Iceland',
    code: '+354',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IS.svg',
  },
  {
    id: '79',
    abbr: 'IN',
    displayName: 'India',
    BEformat: 'India',
    code: '+91',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IN.svg',
  },
  {
    id: '80',
    abbr: 'ID',
    displayName: 'Indonesia',
    BEformat: 'Indonesia',
    code: '+62',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ID.svg',
  },
  {
    id: '81',
    abbr: 'IR',
    displayName: 'Iran',
    BEformat: 'Iran',
    code: '+98',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IR.svg',
  },
  {
    id: '82',
    abbr: 'IQ',
    displayName: 'Iraq',
    BEformat: 'Iraq',
    code: '+964',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IQ.svg',
  },
  {
    id: '83',
    abbr: 'IE',
    displayName: 'Ireland',
    BEformat: 'Ireland',
    code: '+353',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IE.svg',
  },
  {
    id: '84',
    abbr: 'IL',
    displayName: 'Israel',
    BEformat: 'Israel',
    code: '+972',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IL.svg',
  },
  {
    id: '85',
    abbr: 'IT',
    displayName: 'Italy',
    BEformat: 'Italy',
    code: '+39',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/IT.svg',
  },
  {
    id: '86',
    abbr: 'JM',
    displayName: 'Jamaica',
    BEformat: 'Jamaica',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/JM.svg',
  },
  {
    id: '87',
    abbr: 'JP',
    displayName: 'Japan',
    BEformat: 'Japan',
    code: '+81',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/JP.svg',
  },
  {
    id: '88',
    abbr: 'JO',
    displayName: 'Jordan',
    BEformat: 'Jordan',
    code: '+962',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/JO.svg',
  },
  {
    id: '89',
    abbr: 'KZ',
    displayName: 'Kazakhstan',
    BEformat: 'Kazakhstan',
    code: '+7',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KZ.svg',
  },
  {
    id: '90',
    abbr: 'KE',
    displayName: 'Kenya',
    BEformat: 'Kenya',
    code: '+254',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KE.svg',
  },
  {
    id: '91',
    abbr: 'KI',
    displayName: 'Kiribati',
    BEformat: 'Kiribati',
    code: '+686',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KI.svg',
  },
  {
    id: '92',
    abbr: 'KW',
    displayName: 'Kuwait',
    BEformat: 'Kuwait',
    code: '+965',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KW.svg',
  },
  {
    id: '93',
    abbr: 'KG',
    displayName: 'Kyrgyzstan',
    BEformat: 'Kyrgyzstan',
    code: '+996',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KG.svg',
  },
  {
    id: '94',
    abbr: 'LA',
    displayName: 'Laos',
    BEformat: 'Laos',
    code: '+856',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LA.svg',
  },
  {
    id: '95',
    abbr: 'LV',
    displayName: 'Latvia',
    BEformat: 'Latvia',
    code: '+371',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LV.svg',
  },
  {
    id: '96',
    abbr: 'LB',
    displayName: 'Lebanon',
    BEformat: 'Lebanon',
    code: '+961',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LB.svg',
  },
  {
    id: '97',
    abbr: 'LS',
    displayName: 'Lesotho',
    BEformat: 'Lesotho',
    code: '+266',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LS.svg',
  },
  {
    id: '98',
    abbr: 'LR',
    displayName: 'Liberia',
    BEformat: 'Liberia',
    code: '+231',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LR.svg',
  },
  {
    id: '99',
    abbr: 'LY',
    displayName: 'Libya',
    BEformat: 'Libya',
    code: '+218',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LY.svg',
  },
  {
    id: '100',
    abbr: 'LI',
    displayName: 'Liechtenstein',
    BEformat: 'Liechtenstein',
    code: '+423',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LI.svg',
  },
  {
    id: '101',
    abbr: 'LT',
    displayName: 'Lithuania',
    BEformat: 'Lithuania',
    code: '+370',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LT.svg',
  },
  {
    id: '102',
    abbr: 'LU',
    displayName: 'Luxembourg',
    BEformat: 'Luxembourg',
    code: '+352',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LU.svg',
  },
  {
    id: '103',
    abbr: 'MG',
    displayName: 'Madagascar',
    BEformat: 'Madagascar',
    code: '+261',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MG.svg',
  },
  {
    id: '104',
    abbr: 'MW',
    displayName: 'Malawi',
    BEformat: 'Malawi',
    code: '+265',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MW.svg',
  },
  {
    id: '105',
    abbr: 'MY',
    displayName: 'Malaysia',
    BEformat: 'Malaysia',
    code: '+60',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MY.svg',
  },
  {
    id: '106',
    abbr: 'MV',
    displayName: 'Maldives',
    BEformat: 'Maldives',
    code: '+960',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MV.svg',
  },
  {
    id: '107',
    abbr: 'ML',
    displayName: 'Mali',
    BEformat: 'Mali',
    code: '+223',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ML.svg',
  },
  {
    id: '108',
    abbr: 'MT',
    displayName: 'Malta',
    BEformat: 'Malta',
    code: '+356',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MT.svg',
  },
  {
    id: '109',
    abbr: 'MH',
    displayName: 'Marshall Islands',
    BEformat: 'MarshallIslands',
    code: '+692',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MH.svg',
  },
  {
    id: '110',
    abbr: 'MR',
    displayName: 'Mauritania',
    BEformat: 'Mauritania',
    code: '+222',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MR.svg',
  },
  {
    id: '111',
    abbr: 'MU',
    displayName: 'Mauritius',
    BEformat: 'Mauritius',
    code: '+230',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MU.svg',
  },
  {
    id: '112',
    abbr: 'MX',
    displayName: 'Mexico',
    BEformat: 'Mexico',
    code: '+52',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MX.svg',
  },
  {
    id: '113',
    abbr: 'MD',
    displayName: 'Moldova',
    BEformat: 'Moldova',
    code: '+373',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MD.svg',
  },
  {
    id: '114',
    abbr: 'MC',
    displayName: 'Monaco',
    BEformat: 'Monaco',
    code: '+377',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MC.svg',
  },
  {
    id: '115',
    abbr: 'MN',
    displayName: 'Mongolia',
    BEformat: 'Mongolia',
    code: '+976',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MN.svg',
  },
  {
    id: '116',
    abbr: 'ME',
    displayName: 'Montenegro',
    BEformat: 'Montenegro',
    code: '+382',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ME.svg',
  },
  {
    id: '117',
    abbr: 'MA',
    displayName: 'Morocco',
    BEformat: 'Morocco',
    code: '+212',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MA.svg',
  },
  {
    id: '118',
    abbr: 'MZ',
    displayName: 'Mozambique',
    BEformat: 'Mozambique',
    code: '+258',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MZ.svg',
  },
  {
    id: '119',
    abbr: 'NA',
    displayName: 'Namibia',
    BEformat: 'Namibia',
    code: '+264',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NA.svg',
  },
  {
    id: '120',
    abbr: 'NR',
    displayName: 'Nauru',
    BEformat: 'Nauru',
    code: '+674',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NR.svg',
  },
  {
    id: '121',
    abbr: 'NP',
    displayName: 'Nepal',
    BEformat: 'Nepal',
    code: '+977',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NP.svg',
  },
  {
    id: '122',
    abbr: 'NL',
    displayName: 'Netherlands',
    BEformat: 'Netherlands',
    code: '+31',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NL.svg',
  },
  {
    id: '123',
    abbr: 'NZ',
    displayName: 'New Zealand',
    BEformat: 'NewZealand',
    code: '+64',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NZ.svg',
  },
  {
    id: '124',
    abbr: 'NI',
    displayName: 'Nicaragua',
    BEformat: 'Nicaragua',
    code: '+505',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NI.svg',
  },
  {
    id: '125',
    abbr: 'NE',
    displayName: 'Niger',
    BEformat: 'Niger',
    code: '+227',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NE.svg',
  },
  {
    id: '126',
    abbr: 'NG',
    displayName: 'Nigeria',
    BEformat: 'Nigeria',
    code: '+234',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NG.svg',
  },
  {
    id: '127',
    abbr: 'KP',
    displayName: 'North Korea',
    BEformat: 'NorthKorea',
    code: '+850',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KP.svg',
  },
  {
    id: '128',
    abbr: 'MK',
    displayName: 'North Macedonia',
    BEformat: 'NorthMacedonia',
    code: '+389',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/MK.svg',
  },
  {
    id: '129',
    abbr: 'NO',
    displayName: 'Norway',
    BEformat: 'Norway',
    code: '+47',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/NO.svg',
  },
  {
    id: '130',
    abbr: 'OM',
    displayName: 'Oman',
    BEformat: 'Oman',
    code: '+968',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/OM.svg',
  },
  {
    id: '131',
    abbr: 'PK',
    displayName: 'Pakistan',
    BEformat: 'Pakistan',
    code: '+92',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PK.svg',
  },
  {
    id: '132',
    abbr: 'PW',
    displayName: 'Palau',
    BEformat: 'Palau',
    code: '+680',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PW.svg',
  },
  {
    id: '133',
    abbr: 'PS',
    displayName: 'Palestine',
    BEformat: 'PalestineState',
    code: '+970',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PS.svg',
  },
  {
    id: '134',
    abbr: 'PA',
    displayName: 'Panama',
    BEformat: 'Panama',
    code: '+507',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PA.svg',
  },
  {
    id: '135',
    abbr: 'PG',
    displayName: 'Papua New Guinea',
    BEformat: 'PapuaNewGuinea',
    code: '+675',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PG.svg',
  },
  {
    id: '136',
    abbr: 'PY',
    displayName: 'Paraguay',
    BEformat: 'Paraguay',
    code: '+595',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PY.svg',
  },
  {
    id: '137',
    abbr: 'PE',
    displayName: 'Peru',
    BEformat: 'Peru',
    code: '+51',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PE.svg',
  },
  {
    id: '138',
    abbr: 'PH',
    displayName: 'Philippines',
    BEformat: 'Philippines',
    code: '+63',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PH.svg',
  },
  {
    id: '139',
    abbr: 'PL',
    displayName: 'Poland',
    BEformat: 'Poland',
    code: '+48',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PL.svg',
  },
  {
    id: '140',
    abbr: 'PT',
    displayName: 'Portugal',
    BEformat: 'Portugal',
    code: '+351',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/PT.svg',
  },
  {
    id: '141',
    abbr: 'QA',
    displayName: 'Qatar',
    BEformat: 'Qatar',
    code: '+974',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/QA.svg',
  },
  {
    id: '142',
    abbr: 'RO',
    displayName: 'Romania',
    BEformat: 'Romania',
    code: '+40',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/RO.svg',
  },
  {
    id: '143',
    abbr: 'RU',
    displayName: 'Russia',
    BEformat: 'Russia',
    code: '+7',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/RU.svg',
  },
  {
    id: '144',
    abbr: 'RW',
    displayName: 'Rwanda',
    BEformat: 'Rwanda',
    code: '+250',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/RW.svg',
  },
  {
    id: '145',
    abbr: 'KN',
    displayName: 'Saint Kitts and Nevis',
    BEformat: 'SaintKittsAndNevis',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KN.svg',
  },
  {
    id: '146',
    abbr: 'LC',
    displayName: 'Saint Lucia',
    BEformat: 'SaintLucia',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LC.svg',
  },
  {
    id: '147',
    abbr: 'VC',
    displayName: 'Saint Vincent and the Grenadines',
    BEformat: 'SaintVincentAndTheGrenadines',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/VC.svg',
  },
  {
    id: '148',
    abbr: 'WS',
    displayName: 'Samoa',
    BEformat: 'Samoa',
    code: '+685',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/WS.svg',
  },
  {
    id: '149',
    abbr: 'SM',
    displayName: 'San Marino',
    BEformat: 'SanMarino',
    code: '+378',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SM.svg',
  },
  {
    id: '150',
    abbr: 'ST',
    displayName: 'Sao Tome and Principe',
    BEformat: 'SaoTomeAndPrincipe',
    code: '+239',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ST.svg',
  },
  {
    id: '151',
    abbr: 'SA',
    displayName: 'Saudi Arabia',
    BEformat: 'Saudi',
    code: '+966',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SA.svg',
  },
  {
    id: '152',
    abbr: 'SN',
    displayName: 'Senegal',
    BEformat: 'Senegal',
    code: '+221',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SN.svg',
  },
  {
    id: '153',
    abbr: 'RS',
    displayName: 'Serbia',
    BEformat: 'Serbia',
    code: '+381',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/RS.svg',
  },
  {
    id: '154',
    abbr: 'SC',
    displayName: 'Seychelles',
    BEformat: 'Seychelles',
    code: '+248',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SC.svg',
  },
  {
    id: '155',
    abbr: 'SL',
    displayName: 'Sierra Leone',
    BEformat: 'SierraLeone',
    code: '+232',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SL.svg',
  },
  {
    id: '156',
    abbr: 'SG',
    displayName: 'Singapore',
    BEformat: 'Singapore',
    code: '+65',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SG.svg',
  },
  {
    id: '157',
    abbr: 'SK',
    displayName: 'Slovakia',
    BEformat: 'Slovakia',
    code: '+421',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SK.svg',
  },
  {
    id: '158',
    abbr: 'SI',
    displayName: 'Slovenia',
    BEformat: 'Slovenia',
    code: '+386',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SI.svg',
  },
  {
    id: '159',
    abbr: 'SB',
    displayName: 'Solomon Islands',
    BEformat: 'SolomonIslands',
    code: '+677',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SB.svg',
  },
  {
    id: '160',
    abbr: 'SO',
    displayName: 'Somalia',
    BEformat: 'Somalia',
    code: '+252',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SO.svg',
  },
  {
    id: '161',
    abbr: 'ZA',
    displayName: 'South Africa',
    BEformat: 'SouthAfrica',
    code: '+27',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ZA.svg',
  },
  {
    id: '162',
    abbr: 'KR',
    displayName: 'South Korea',
    BEformat: 'SouthKorea',
    code: '+82',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/KR.svg',
  },
  {
    id: '163',
    abbr: 'SS',
    displayName: 'South Sudan',
    BEformat: 'SouthSudan',
    code: '+211',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SS.svg',
  },
  {
    id: '164',
    abbr: 'ES',
    displayName: 'Spain',
    BEformat: 'Spain',
    code: '+34',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ES.svg',
  },
  {
    id: '165',
    abbr: 'LK',
    displayName: 'Sri Lanka',
    BEformat: 'SriLanka',
    code: '+94',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/LK.svg',
  },
  {
    id: '166',
    abbr: 'SD',
    displayName: 'Sudan',
    BEformat: 'Sudan',
    code: '+249',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SD.svg',
  },
  {
    id: '167',
    abbr: 'SR',
    displayName: 'Suriname',
    BEformat: 'Suriname',
    code: '+597',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SR.svg',
  },
  {
    id: '168',
    abbr: 'SE',
    displayName: 'Sweden',
    BEformat: 'Sweden',
    code: '+46',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SE.svg',
  },
  {
    id: '169',
    abbr: 'CH',
    displayName: 'Switzerland',
    BEformat: 'Switzerland',
    code: '+41',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/CH.svg',
  },
  {
    id: '170',
    abbr: 'SY',
    displayName: 'Syria',
    BEformat: 'Syria',
    code: '+963',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/SY.svg',
  },
  {
    id: '171',
    abbr: 'TZ',
    displayName: 'Tanzania',
    BEformat: 'Tanzania',
    code: '+255',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TZ.svg',
  },
  {
    id: '172',
    abbr: 'TJ',
    displayName: 'Tajikistan',
    BEformat: 'Tajikistan',
    code: '+992',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TJ.svg',
  },
  {
    id: '173',
    abbr: 'TH',
    displayName: 'Thailand',
    BEformat: 'Thailand',
    code: '+66',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TH.svg',
  },
  {
    id: '174',
    abbr: 'TL',
    displayName: 'Timor-Leste',
    BEformat: 'TimorLeste',
    code: '+670',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TL.svg',
  },
  {
    id: '175',
    abbr: 'TG',
    displayName: 'Togo',
    BEformat: 'Togo',
    code: '+228',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TG.svg',
  },
  {
    id: '176',
    abbr: 'TO',
    displayName: 'Tonga',
    BEformat: 'Tonga',
    code: '+676',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TO.svg',
  },
  {
    id: '177',
    abbr: 'TT',
    displayName: 'Trinidad and Tobago',
    BEformat: 'TrinidadAndTobago',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TT.svg',
  },
  {
    id: '178',
    abbr: 'TN',
    displayName: 'Tunisia',
    BEformat: 'Tunisia',
    code: '+216',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TN.svg',
  },
  {
    id: '179',
    abbr: 'TR',
    displayName: 'Turkey',
    BEformat: 'Turkey',
    code: '+90',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TR.svg',
  },
  {
    id: '180',
    abbr: 'TM',
    displayName: 'Turkmenistan',
    BEformat: 'Turkmenistan',
    code: '+993',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TM.svg',
  },
  {
    id: '181',
    abbr: 'TV',
    displayName: 'Tuvalu',
    BEformat: 'Tuvalu',
    code: '+688',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/TV.svg',
  },
  {
    id: '182',
    abbr: 'UG',
    displayName: 'Uganda',
    BEformat: 'Uganda',
    code: '+256',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/UG.svg',
  },
  {
    id: '183',
    abbr: 'UA',
    displayName: 'Ukraine',
    BEformat: 'Ukraine',
    code: '+380',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg',
  },
  {
    id: '184',
    abbr: 'AE',
    displayName: 'United Arab Emirates',
    BEformat: 'UnitedArabEmirates',
    code: '+971',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/AE.svg',
  },
  {
    id: '185',
    abbr: 'GB',
    displayName: 'United Kingdom',
    BEformat: 'UnitedKingdom',
    code: '+44',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/GB.svg',
  },
  {
    id: '186',
    abbr: 'US',
    displayName: 'United States',
    BEformat: 'UnitedStatesOfAmerica',
    code: '+1',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/US.svg',
  },
  {
    id: '187',
    abbr: 'UY',
    displayName: 'Uruguay',
    BEformat: 'Uruguay',
    code: '+598',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/UY.svg',
  },
  {
    id: '188',
    abbr: 'UZ',
    displayName: 'Uzbekistan',
    BEformat: 'Uzbekistan',
    code: '+998',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/UZ.svg',
  },
  {
    id: '189',
    abbr: 'VE',
    displayName: 'Venezuela',
    BEformat: 'Venezuela',
    code: '+58',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/VE.svg',
  },
  {
    id: '190',
    abbr: 'VU',
    displayName: 'Vietnam',
    BEformat: 'Vietnam',
    code: '+678',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/VU.svg',
  },
  {
    id: '191',
    abbr: 'VN',
    displayName: 'Vietnam',
    BEformat: 'Vietnam',
    code: '+84',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/VN.svg',
  },
  {
    id: '192',
    abbr: 'YE',
    displayName: 'Yemen',
    BEformat: 'Yemen',
    code: '+967',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/YE.svg',
  },
  {
    id: '193',
    abbr: 'ZM',
    displayName: 'Zambia',
    BEformat: 'Zambia',
    code: '+260',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ZM.svg',
  },
  {
    id: '194',
    abbr: 'ZW',
    displayName: 'Zimbabwe',
    BEformat: 'Zimbabwe',
    code: '+263',
    icon: 'https://purecatamphetamine.github.io/country-flag-icons/1x1/ZW.svg',
  },
];

export default countries;
