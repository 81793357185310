import React from 'react';

const PersonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clip-path-person-1">
        <path
          data-name="Path 19008"
          d="M12-24A12 12 0 0 0 0-12 12 12 0 0 0 12 0a12 12 0 0 0 12-12 12.013 12.013 0 0 0-12-12Zm0 21.5A9.5 9.5 0 0 1 2.5-12a9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.5 9.5A9.511 9.511 0 0 1 12-2.5Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="clip-path-person-2">
        <path
          data-name="Path 19010"
          d="M12-14.5a3 3 0 0 0 3-3 3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="clip-path-person-3">
        <path
          data-name="Path 19012"
          d="M12-13.5a4.456 4.456 0 0 0-4.5 4.4v2.6A.5.5 0 0 0 8-6h1.323a.25.25 0 0 1 .249.225l.187 1.826a.5.5 0 0 0 .5.449h3.488a.5.5 0 0 0 .5-.449l.187-1.826A.25.25 0 0 1 14.677-6H16a.5.5 0 0 0 .5-.5v-2.6a4.456 4.456 0 0 0-4.5-4.4Z"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g data-name="Group 16715">
      <g
        data-name="Group 16709"
        clipPath="url(#clip-path-person-1)"
        transform="translate(0 24)"
      >
        <path data-name="Path 19007" d="M-5-29h34V5H-5Z" fill="#fff" />
      </g>
      <g
        data-name="Group 16710"
        clipPath="url(#clip-path-person-2)"
        transform="translate(0 24)"
      >
        <path data-name="Path 19009" d="M4-25.5h16v16H4Z" fill="#fff" />
      </g>
      <g
        data-name="Group 16711"
        clipPath="url(#clip-path-person-3)"
        transform="translate(0 24)"
      >
        <path data-name="Path 19011" d="M2.5-18.5h19v20h-19Z" fill="#fff" />
      </g>
      <g
        data-name="Group 16712"
        clipPath="url(#clip-path-person-1)"
        transform="translate(0 24)"
      >
        <path data-name="Path 19013" d="M-5-29h34V5H-5Z" fill="#fff" />
      </g>
      <g
        data-name="Group 16713"
        clipPath="url(#clip-path-person-2)"
        transform="translate(0 24)"
      >
        <path data-name="Path 19015" d="M4-25.5h16v16H4Z" fill="#fff" />
      </g>
      <g
        data-name="Group 16714"
        clipPath="url(#clip-path-person-3)"
        transform="translate(0 24)"
      >
        <path data-name="Path 19017" d="M2.5-18.5h19v20h-19Z" fill="#fff" />
      </g>
    </g>
  </svg>
);

export default PersonIcon;
