import React from 'react';

import { TableRowGrid } from 'components/table/models';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';

import { CourseType } from '../../models';

import CourseContainerRow from './CourseContainerRow';

type Props = {
  isShowButtons: boolean;
  data: CourseType[];
};

const CourseTable = ({ data, isShowButtons }: Props) => (
  <TableContainer>
    <TableHeader tableName={TableRowGrid.ContentCourses} />
    {!!data.length && (
      <div>
        {data.map((item) => (
          <CourseContainerRow
            key={item.id}
            isShowButtons={isShowButtons}
            item={item}
            openModalWindow={() => null}
          />
        ))}
      </div>
    )}
  </TableContainer>
);

export default CourseTable;
