import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import MessageIcon from 'components/icons/MessageIcon';

import { ContentType } from '../models';

type Props = {
  contentType: ContentType;
  toggleModal: () => void;
};

const GetStarted = ({ contentType, toggleModal }: Props) => {
  const { title, secondSubtitle } = useMemo(() => {
    switch (contentType) {
      case ContentType.Courses:
        return { title: 'Select courses for your team', secondSubtitle: '' };
      case ContentType.Modules:
      default:
        return {
          title: 'Select topics for your team',
          secondSubtitle:
            'Choose topics to display in the Work Life section of the app.',
        };
    }
  }, [contentType]);

  return (
    <Container>
      <MessageIconContainer>
        <MessageIcon />
      </MessageIconContainer>
      <Title>{title}</Title>
      <Subtitle>
        We have a selection of content available to help address common
        workplace health issues.
      </Subtitle>
      {!!secondSubtitle && <SecondSubtitle>{secondSubtitle}</SecondSubtitle>}
      <ButtonWrapper>
        <DefaultButton
          isActive
          withShadow
          borderSize="md"
          buttonSize="md"
          text="Get started"
          textColor={theme.colors.white}
          buttonColor={theme.colors.funtainBlue}
          clickHandler={toggleModal}
        />
      </ButtonWrapper>
    </Container>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 34px;
`;

const Subtitle = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  margin-top: 13px;
  font-weight: 300;
  position: relative;
  right: 2px;
`;

const SecondSubtitle = styled(Subtitle)`
  margin-top: 10px;
  right: 0;
`;

const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 30px;
  margin-top: 18px;
`;

const MessageIconContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
`;

const Container = styled.div`
  padding-top: 136px;
  max-width: 100%;
  height: 515px;
  box-sizing: border-box;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  background-color: rgb(0 0 0 / 0%);
  border-radius: 5px;
`;

export default GetStarted;
