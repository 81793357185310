import * as yup from 'yup';

import patterns from 'helpers/patterns';

import { InputId } from './index';

const FIRST_NAME_MAX_LENGTH = 50;
const LAST_NAME_MAX_LENGTH = 50;
const COMPANY_NAME_MAX_LENGTH = 50;
const EMAIL_MAX_LENGTH = 256;
const MOBILE_NUMBER_MAX_LENGTH = 20;
const WEBSITE_MAX_LENGTH = 80;
const ROLE_MAX_LENGTH = 50;
const TITLE_MAX_LENGTH = 100;
const BODY_MAX_LENGTH = 200;

const MAX_REDEMPTION_LIMIT_VALUE = 1000000.01;
const MIN_REDEMPTION_LIMIT_VALUE = 0.99;
const MAX_UNIT_PRICE_VALUE = 1000000.01;
const MIN_UNIT_PRICE_VALUE = 0.99;

export const referrerAccountDetailsValidationSchema = yup.object().shape({
  [InputId.FirstNameInputId]: yup
    .string()
    .max(
      FIRST_NAME_MAX_LENGTH,
      `The length of the first name must be ${FIRST_NAME_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.namePattern, 'Invalid first name format')
    .required('This field is required'),
  [InputId.LastNameInputId]: yup
    .string()
    .max(
      LAST_NAME_MAX_LENGTH,
      `The length of the last name must be ${LAST_NAME_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.namePattern, 'Invalid last name format')
    .required('This field is required'),
  [InputId.EmailAddressInputId]: yup
    .string()
    .max(
      EMAIL_MAX_LENGTH,
      `The length of the email must be ${EMAIL_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.emailPattern, 'Invalid email format')
    .required('This field is required'),
  [InputId.MobileNumberInputId]: yup
    .string()
    .max(
      MOBILE_NUMBER_MAX_LENGTH,
      `The length of the mobile number must be ${MOBILE_NUMBER_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
});

export const corporateAccountDetailsValidationSchema = yup.object().shape({
  [InputId.CompanyNameInputId]: yup
    .string()
    .max(
      COMPANY_NAME_MAX_LENGTH,
      `The length of the company name must be ${COMPANY_NAME_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.companyPattern, 'Invalid company name format')
    .required('This field is required'),
  [InputId.WebsiteInputId]: yup
    .string()
    .max(
      WEBSITE_MAX_LENGTH,
      `The length of the website must be ${WEBSITE_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.websitePattern, 'Invalid website format')
    .required('This field is required'),
});

export const corporateOfferSettingsValidationSchema = (value?: number) => {
  const minRedemptionLimitValue = value
    ? value - 1
    : MIN_REDEMPTION_LIMIT_VALUE;

  return yup.object().shape({
    [InputId.RedemptionLimitInputId]: yup
      .number()
      .nullable()
      .moreThan(
        minRedemptionLimitValue,
        `The value must be greater than or equal to 
          ${value || 1}`
      )
      .lessThan(
        MAX_REDEMPTION_LIMIT_VALUE,
        'The value must be less than or equal to 1000000'
      )
      // transform allows avoiding useless errors
      .transform((v, o) => (o === '' ? null : v))
      .required('This field is required'),
    [InputId.UnitPriceInputId]: yup
      .number()
      .nullable()
      .moreThan(
        MIN_UNIT_PRICE_VALUE,
        'The value must be greater than or equal to 1'
      )
      .lessThan(
        MAX_UNIT_PRICE_VALUE,
        'The value must be less than or equal to 1000000'
      )
      .transform((v, o) => (o === '' ? null : v))
      .required('This field is required'),
    [InputId.NoteInputId]: yup
      .string()
      .max(
        ROLE_MAX_LENGTH,
        `The length of the note must be ${ROLE_MAX_LENGTH} characters or fewer`
      ),
  });
};

export const promotionOfferSettingsValidationSchema = yup.object().shape({
  [InputId.NoteInputId]: yup
    .string()
    .max(
      ROLE_MAX_LENGTH,
      `The length of the note must be ${ROLE_MAX_LENGTH} characters or fewer`
    ),
});

export const promotionOffePageValidationSchema = yup.object().shape({
  [InputId.PromotionTitle]: yup
    .string()
    .max(
      TITLE_MAX_LENGTH,
      `The length of the title must be ${TITLE_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.PromotionSubtitle]: yup
    .string()
    .max(
      TITLE_MAX_LENGTH,
      `The length of the subtitle must be ${TITLE_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.PromotionBody]: yup
    .string()
    .max(
      BODY_MAX_LENGTH,
      `The length of the body must be ${BODY_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
});

export const PrimaryContactValidationSchema = yup.object().shape({
  [InputId.FirstNameInputId]: yup
    .string()
    .max(
      FIRST_NAME_MAX_LENGTH,
      `The length of the first name must be ${FIRST_NAME_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.LastNameInputId]: yup
    .string()
    .max(
      LAST_NAME_MAX_LENGTH,
      `The length of the last name must be ${LAST_NAME_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.RoleInputId]: yup
    .string()
    .max(
      ROLE_MAX_LENGTH,
      `The length of the role must be ${ROLE_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.EmailAddressInputId]: yup
    .string()
    .max(
      EMAIL_MAX_LENGTH,
      `The length of the email must be ${EMAIL_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.emailPattern, 'Invalid email format')
    .required('This field is required'),
  [InputId.MobileNumberInputId]: yup
    .string()
    .max(
      MOBILE_NUMBER_MAX_LENGTH,
      `The length of the mobile number must be ${MOBILE_NUMBER_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
});

export const userAccountDetailsSchema = yup.object().shape({
  [InputId.FirstNameInputId]: yup
    .string()
    .max(
      FIRST_NAME_MAX_LENGTH,
      `The length of the first name must be ${FIRST_NAME_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.EmailAddressInputId]: yup
    .string()
    .max(
      EMAIL_MAX_LENGTH,
      `The length of the email must be ${EMAIL_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.emailPattern, 'Invalid email format')
    .required('This field is required'),
  [InputId.MobileNumberInputId]: yup
    .string()
    .max(
      MOBILE_NUMBER_MAX_LENGTH,
      `The length of the mobile number must be ${MOBILE_NUMBER_MAX_LENGTH} characters or fewer`
    )
    .optional(),
});

export const AccessAccountDetailsSchema = yup.object().shape({
  [InputId.FirstNameInputId]: yup
    .string()
    .max(
      FIRST_NAME_MAX_LENGTH,
      `The length of the first name must be ${FIRST_NAME_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.EmailAddressInputId]: yup
    .string()
    .max(
      EMAIL_MAX_LENGTH,
      `The length of the email must be ${EMAIL_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.emailPattern, 'Invalid email format')
    .required('This field is required'),
  [InputId.MobileNumberInputId]: yup
    .string()
    .max(
      MOBILE_NUMBER_MAX_LENGTH,
      `The length of the mobile number must be ${MOBILE_NUMBER_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.JobTitleInputId]: yup
    .string()
    .max(
      LAST_NAME_MAX_LENGTH,
      `The length of the job title must be ${LAST_NAME_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.LastNameInputId]: yup
    .string()
    .max(
      LAST_NAME_MAX_LENGTH,
      `The length of the last name must be ${LAST_NAME_MAX_LENGTH} characters or fewer`
    )
    .matches(patterns.namePattern, 'Invalid last name format')
    .required('This field is required'),
});

export const affiliateOffePageValidationSchema = yup.object().shape({
  [InputId.AffiliateName]: yup
    .string()
    .max(
      TITLE_MAX_LENGTH,
      `The length of the name must be ${TITLE_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.AffiliateHandle]: yup
    .string()
    .max(
      TITLE_MAX_LENGTH,
      `The length of the handle must be ${TITLE_MAX_LENGTH} characters or fewer`
    ),
  [InputId.AffiliateStatement]: yup
    .string()
    .max(
      BODY_MAX_LENGTH,
      `The length of the statement must be ${BODY_MAX_LENGTH} characters or fewer`
    ),
  [InputId.AffiliateIntro]: yup
    .string()
    .max(
      BODY_MAX_LENGTH,
      `The length of the intro must be ${BODY_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
  [InputId.AffiliateBody]: yup
    .string()
    .max(
      BODY_MAX_LENGTH,
      `The length of the body must be ${BODY_MAX_LENGTH} characters or fewer`
    )
    .required('This field is required'),
});
