import React from 'react';
import styled from 'styled-components';

import ModalAction from 'components/detailsModal/ModalAction';
import { HorizontalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';

type RevokeProps = {
  deleteUser: () => void;
  cancelDeletion: () => void;
  toggleDeleteStatus: () => void;
  closeModal: () => void;
  isActionConfirmed: boolean;
  backgroundColor: string;
};

const RevokeComponent = ({
  closeModal,
  toggleDeleteStatus,
  cancelDeletion,
  deleteUser,
  isActionConfirmed,
  backgroundColor,
}: RevokeProps) => {
  return (
    <>
      <CloseIconWrapper onClick={closeModal}>
        <CloseIcon color="white" prefix="details_modal_deletion" />
      </CloseIconWrapper>
      <Subtitle>Revoke Dan McPeake’s offer code</Subtitle>
      <Title>Are you sure?</Title>
      <ConfirmationBlock>
        <HorizontalLine />
        <ModalActionWrapper>
          <ModalAction
            employerModal
            buttonText="delete"
            isChecked={isActionConfirmed}
            color={backgroundColor}
            submitAction={deleteUser}
            closeModal={cancelDeletion}
            toggleStatus={toggleDeleteStatus}
            actionTypeText="YES REVOKE OFFER CODE"
            note="The users subscription will end & they will no longer have access to employee content"
          />
        </ModalActionWrapper>
      </ConfirmationBlock>
    </>
  );
};

const ModalActionWrapper = styled.div`
  div:nth-child(2) {
    bottom: 16px;
    column-gap: 5px;
  }
`;

const CloseIconWrapper = styled.div`
  top: 36px;
  right: 21px;
  position: absolute;
`;

const ConfirmationBlock = styled.div`
  max-width: 640px;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: white;
  margin-top: 39px;
`;

const Title = styled.div`
  font-size: 30px;
  color: white;
  margin-top: 9px;
  margin-bottom: 32px;
`;

export default RevokeComponent;
