import React from 'react';
import styled, { css } from 'styled-components';

type DashboardMonthNavigationProps = {
  months: string[];
  activeMonth: string;
  setMonth: (month: string) => void;
};

const DashboardMonthNavigation = ({
  months,
  activeMonth,
  setMonth,
}: DashboardMonthNavigationProps) => (
  <Container>
    <Results>
      <p>2,338 </p> team members
    </Results>
    <MonthNavigationWrapper>
      {months.map((month) => (
        <MonthItem
          key={month}
          $active={activeMonth === month}
          onClick={() => setMonth(month)}
        >
          <MonthWrapperItem $active={activeMonth === month}>
            <p>{month}</p>
          </MonthWrapperItem>
        </MonthItem>
      ))}
    </MonthNavigationWrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 87px 19px 0;
  height: 30px;
  margin-bottom: 5px;
`;

const MonthWrapperItem = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  height: 16px;
  border-right: 1px solid rgb(134 119 119 / 20%);
  width: 100%;

  p {
    font-size: 12px;
    color: ${({ theme, $active }) =>
      $active ? theme.colors.darkBlue : theme.colors.funtainBlue};
    font-weight: 700;
    height: 14px;
    padding-top: 4px;
  }
`;

const Results = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};

  p {
    display: inline;
    font-weight: 700;
    font-size: 16px;
  }
`;

const MonthNavigationWrapper = styled.div`
  display: flex;
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
`;

const MonthItem = styled.div<{ $active?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  height: 30px;
  cursor: pointer;
  width: 60px;
  box-sizing: border-box;
  padding-top: 3px;

  ${({ $active }) =>
    $active &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 60px;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.funtainBlue};
        cursor: default;
      }
    `}
  &:last-child {
    div {
      border: none;
    }
  }
`;

export default React.memo(DashboardMonthNavigation);
