import React from 'react';
import styled, { css } from 'styled-components';

import AffiliateArrow from 'components/icons/AffiliateArror';

import custom from '../../previewPagePromotion/custom.png';

type DetailsSectionProps = {
  isDesktop?: boolean;
  intro: string;
  bodyText: string;
  goToNextSection: () => void;
};

const DetailsSection = (
  { isDesktop, intro, bodyText, goToNextSection }: DetailsSectionProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <Container $isDesktop={isDesktop} ref={ref}>
      <InfoContainer $isDesktop={isDesktop}>
        <ParagraphWrapper $isDesktop={isDesktop}>
          <FirstParagraph $isDesktop={isDesktop}>{intro}</FirstParagraph>
          <SecondParagraph $isDesktop={isDesktop}>{bodyText}</SecondParagraph>
        </ParagraphWrapper>
        <ImageWrapper $isDesktop={isDesktop}>
          <img src={custom} />
        </ImageWrapper>
      </InfoContainer>
      <ArrowWrapper $isDesktop={isDesktop} onClick={goToNextSection}>
        <AffiliateArrow />
      </ArrowWrapper>
    </Container>
  );
};

const ImageWrapper = styled.div<{ $isDesktop?: boolean }>`
  position: relative;
  width: 540px;
  height: 400px;

  img {
    position: absolute;
    height: 510px;
    object-fit: contain;
    top: -40px;
    right: -58px;
    width: 650px;
    ${({ $isDesktop }) =>
      $isDesktop &&
      css`
        width: 660px;
      `}
  }
`;

const ArrowWrapper = styled.div<{ $isDesktop?: boolean }>`
  text-align: center;
  cursor: pointer;
  margin: ${({ $isDesktop }) =>
    $isDesktop ? '19px auto 0 auto' : '80px auto 0 auto'};
  position: relative;
  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      margin: 41px auto 0;
    `}
`;

const ParagraphWrapper = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '535px' : '440px')};
  min-height: ${({ $isDesktop }) => ($isDesktop ? '400px' : '235px')};
  word-break: break-word;
`;

const FirstParagraph = styled.div<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '30px' : '24px')};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  line-height: 50px;
  top: 13px;
  position: relative;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      top: -9px;
      position: relative;
      left: 6px;
    `}
`;

const SecondParagraph = styled.div<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '30px' : '24px')};
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 45px;
  font-weight: 300;
  line-height: 50px;
  top: 20px;
  position: relative;
  padding-right: 22px;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      top: 0;
      padding-right: 12px;
      position: relative;
      left: 6px;
    `}
`;

const InfoContainer = styled.div<{ $isDesktop?: boolean }>`
  width: ${({ $isDesktop }) => ($isDesktop ? '92%' : '100%')};
  margin: auto;
  display: flex;
  flex-direction: ${({ $isDesktop }) => ($isDesktop ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '1400px' : '1200px')};
  margin: 30px auto 0;
  padding-bottom: 84px;
  position: relative;
  padding-top: ${({ $isDesktop }) => ($isDesktop ? '80px' : '161px')};
  border-top: 1px dashed rgb(216 218 224);
  border-bottom: 1px dashed rgb(216 218 224);
  z-index: 3;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin: 36px auto 0;
    `}
`;

export default React.forwardRef(DetailsSection);
