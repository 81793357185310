export enum EmployerRoutes {
  Insights = 'insights',
  Delegates = 'delegates',
  Events = 'events',
  Onboarding = 'onboarding',
  Content = 'content',
  Login = 'login',
  EmployersBase = '/employers',
}

export enum AdminRoutes {
  ReferrerRegister = 'referrer-register',
  Accounts = 'accounts',
  Dashboard = 'dashboard',
  Offers = 'offers',
  Users = 'users',
  AdminBase = '/admin',
}

export enum BaseRoutes {
  Base = '/',
  Login = '/login',
}

export enum NavbarLabel {
  Accounts = 'accounts',
  Dashboard = 'dashboard',
  Offers = 'offers',
  Users = 'users',
  Reports = 'reports',
  Delegates = 'delegates',
  Insights = 'insights',
  Events = 'events',
  Content = 'content',
  Onboarding = 'onboarding',
}
