import * as yup from 'yup';

import patterns from 'helpers/patterns';

const SECONDS_TO_MINUTES_CONVERTER = 60;
const SECOND = 1000;
const MINUTES = 25;

export const ACCESS_TOKEN_VALID_TIME =
  MINUTES * SECONDS_TO_MINUTES_CONVERTER * SECOND;

export enum AuthStage {
  SignIn = 'SIGN_IN',
  Verify = 'VERIFY',
  Password = 'PASSWORD',
  Success = 'SUCCESS',
  NewPassword = 'NEW_PASSWORD',
}

export enum CustomTrackEvent {
  InvalidEmail = 'INVALID_EMAIL',
  InvalidMobileSignInCode = 'INVALID_MOBILE_SIGN_IN_CODE',
}

export enum SuccessAuth {
  AdminLogin = 'ADMIN_LOGIN',
  ReferrerRegister = 'REFERRER_REGISTER',
  EmployerLogin = 'EMPLOYER_LOGIN',
  NewPasswordLogin = 'NEW_PASSWORD_EMPLOYER_LOGIN',
}

export enum UserRole {
  Admin = 'Admin',
}

export enum Token {
  AccessToken = 'ACCESS_TOKEN',
  RefreshToken = 'REFRESH_TOKEN',
}

export enum ContentInfo {
  TopicIds = 'topicIds',
  CourseIds = 'courseIds',
  DateOfSelectingTopics = 'dateOfSelectingTopics',
  DateOfSelectingCourses = 'dateOfSelectingCourses',
}

export const TOKEN_ASSIGNMENT_TIME = 'TOKEN_ASSIGNMENT_TIME';

export const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(patterns.emailPattern, 'Invalid email format')
    .required('This field is required'),
});
