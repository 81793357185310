import { CustomDate } from 'components/calendar/models';
import { StatusBE } from 'components/detailsModal/models';
import { Colors } from 'components/previewPages/previewPagePromotion/PreviewPagePromotion';

export enum OfferModalWindowStages {
  SelectType = 'SELECT_TYPE',
  SelectAccount = 'SELECT_ACCOUNT',
  Settings = 'SETTINGS',
  OfferPage = 'OFFER_PAGE',
  OfferCodes = 'OFFER_CODES',
  Result = 'RESULT',
}

export enum OfferCategory {
  Corporate = 'CORPORATE',
  Promotion = 'PROMOTION',
  Affiliate = 'AFFILIATE',
}

export enum ConversionFunnelBE {
  App = 'App',
  Web = 'Web',
}

export type CorporateSettings = {
  unitPrice: {
    currency: string;
    unitPrice: string;
  };
  redemptionLimit: string;
  validPeriod: {
    firstDate: CustomDate;
    secondDate: CustomDate;
  };
  offerTerm: string;
  offerCode: string;
};

export type OfferCodePage = {
  logo: {
    name: string;
    url: string;
  };
  background: {
    name: string;
    url: string;
  };
};

export type PromotionOfferPageAttributes = {
  title: string;
  subtitle: string;
  bodyText: string;
  promotionTermId: string;
};

export type PromotionSettingsPageAttributes = {
  backgroundColour: keyof Colors;
  notes: string;
};

export type PromotionOfferData = {
  settingsStage: PromotionSettingsPageAttributes;
  offerStage: PromotionOfferPageAttributes;
};

export type PromoTerm = {
  id: string;
  displayName: string;
  BEformat: string;
  promoDisplay: string;
  findBy: string;
};

export type PromotionOfferCodes = {
  ios: {
    name: string;
    file: string;
  };
  android: {
    name: string;
    file: string;
  };
};

interface PromotionOffer
  extends PromotionSettingsPageAttributes,
    PromotionOfferPageAttributes {
  id: string;
  status: StatusBE;
  category: OfferCategory.Promotion;
}

export interface PromotionOfferResponse extends PromotionOffer {
  publicCode: string;
  promotionTerm: {
    id: string;
    term: string;
    termDescription: string;
  };
  imageUrl: string | null;
  qrCodeUrl: string;
  redemptions: number;
  createdDateUtc: string;
}

export type CorporateOfferAccountId = {
  accountId: string;
};

export enum ImageVariant {
  Logo = 'LOGO',
  Background = 'BACKGROUND',
}

export enum QueryKey {
  GetCorporateOffers = 'GET_CORPORATE_OFFERS',
  GetPromotionOffers = 'GET_PROMOTION_OFFERS',
  GetAffiliateOffers = 'GET_AFFILIATE_OFFERS',
}

export const OFFERS_SORT_BY_BE = [
  'Alphabetical',
  'Expires soonest',
  'Highest limit',
  'Highest redemptions',
  'Latest',
];
export const OFFERS_SORT_BY_FE = [
  'Account A-Z',
  'Expires soonest',
  'Highest limit',
  'Highest redemptions',
  'Latest',
];

export type AffiliateOfferPageAttributes = {
  affiliateName: string;
  affiliateHandle: string;
  affiliateStatement: string;
  intro: string;
  bodyText: string;
};

export type AffiliateSettingsPageAttributes = {
  backgroundColour: keyof Colors;
  conversionFunnel: ConversionFunnelBE;
};

export type AffiliateOfferData = {
  settingsStage: AffiliateSettingsPageAttributes;
  offerStage: AffiliateOfferPageAttributes;
};

export interface AffiliateOfferResponse
  extends AffiliateSettingsPageAttributes,
    AffiliateOfferPageAttributes {
  id: string;
  status: StatusBE;
  category: OfferCategory.Affiliate;
  imageUrl: string | null;
  publicCode: string;
  convertedRedemptionsThisMonth: number;
  convertedRedemptions: number;
  qrCodeUrl: string;
  promotionTermId: string;
  redemptions: string;
  promotionTerm: {
    id: string;
    term: string;
    termDescription: string;
  };
  createdDateUtc: string;
}

export const AFFILIATE_SORT_BY_FE = ['Account A-Z', 'Latest', 'Name'];
export const AFFILIATE_SORT_BY_BE = ['Alphabetical', 'Latest', 'Name'];

export const IMAGE_EXTENSIONS = ['svg', 'jpeg', 'jpg', 'png'];
export const DROP_IMAGE_EXTENSIONS = [
  'image/svg+xml',
  'image/jpeg',
  'image/jpg',
  'image/png',
];
