import { StatusBE } from 'components/detailsModal/models';
import { TemporaryStatus } from 'components/table/models';

export enum AccountModalWindowStages {
  SelectType = 'SELECT_TYPE',
  AccountDetails = 'ACCOUNT_DETAILS',
  PrimaryContact = 'PRIMARY_CONTACT',
  Result = 'RESULT',
}

export enum AccountCategory {
  Corporate = 'CORPORATE',
  Referrer = 'REFERRER',
}

export type CorporateAccountDetailsData = {
  companyName: string;
  website: string;
  businessSector: string;
  country: {
    abbr: string;
    displayName: string;
  };
};

export type CorporatePrimaryContactData = {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  mobile: {
    code: string;
    number: string;
  };
};

export type ReferrerAccountDetailsData = {
  country: {
    abbr: string;
    displayName: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  mobile: {
    code: string;
    number: string;
    countryAbbreviation: string;
  };
};

export interface ReferrerAccount extends ReferrerAccountDetailsData {
  id: string;
  status: StatusBE;
  category: AccountCategory.Referrer;
  temporaryStatus?: TemporaryStatus;
}

export enum QueryKey {
  GetCorporateAccounts = 'GET_CORPORATE_ACCOUNTS',
}

export const ACCOUNTS_SORT_BY_BE = ['Alphabetical', 'Latest'];
export const ACCOUNTS_SORT_BY_FE = ['A-Z', 'Latest'];
