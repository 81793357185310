import styled from 'styled-components';

export const Label = styled.div`
  font-size: 12px;
  opacity: 0.7;
  color: white;
`;

export const Value = styled.div`
  font-size: 12px;
  color: white;
`;

export const InfoItemWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalContainer = styled.div<{ $background?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  background: ${({ theme, $background }) =>
    $background ? $background : theme.colors.funtainBlue};
  z-index: 3;
  top: 0;
  box-sizing: border-box;
  padding: 0 20px;
`;

export const DashedWrapper = styled.div`
  border: 1.5px dashed ${({ theme }) => theme.colors.lightGreyV3};
  display: flex;
  justify-content: space-between;
  height: 45px;
  align-items: center;
  box-sizing: border-box;
  border-left: none;
  border-right: none;
`;
