import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.colors.darkBlue};
  overflow: hidden;
`;

export const LogoWrapper = styled.div`
  position: absolute !important;
  top: 40px !important;
  left: 40px !important;

  @media (height <= 800px) {
    top: 20px !important;
    left: 10px !important;
    transform: scale(0.8);
  }
`;

export const SignInModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 37px 40px;
  box-sizing: border-box;
  z-index: 10;
`;

export const BackgroundImage = styled.img`
  width: 119%;
  height: auto;
  position: absolute;
  left: 45%;
  top: 76%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;
