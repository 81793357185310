export const goalsData = [
  {
    id: 1,
    imageSrc: '/images/goals/sleep.svg',
    title: 'Get enough sleep',
    results: [92, 71],
  },
  {
    id: 2,
    imageSrc: '/images/goals/walk.svg',
    title: 'Walk outdoors',
    results: [56, 51],
  },
  {
    id: 3,
    imageSrc: '/images/goals/drink_water.svg',
    title: 'Drink water',
    results: [81, 68],
  },
  {
    id: 4,
    imageSrc: '/images/goals/drive.svg',
    title: 'Regular exercise',
    results: [74, 22],
  },
  {
    id: 5,
    imageSrc: '/images/goals/limit_sugar.svg',
    title: 'Limit sugar',
    results: [92, 71],
  },
  {
    id: 6,
    imageSrc: '/images/goals/track_weight.svg',
    title: 'Track weight',
    results: [56, 51],
  },
  {
    id: 7,
    imageSrc: '/images/goals/yoga.svg',
    title: 'Drink water',
    results: [81, 68],
  },
  {
    id: 8,
    imageSrc: '/images/goals/fruits.svg',
    title: 'Regular exercise',
    results: [74, 22],
  },
];
