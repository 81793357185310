import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import CloseIcon from 'components/icons/CloseIcon';
import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

type SearchBarResultProps = {
  isEmployerPage?: boolean;
  title: string;
  nameToFilter: string;
  activeFilterParameter: string;
  setNewNameToFilter: (value: string) => void;
};

const SearchBarResult = ({
  isEmployerPage,
  title,
  nameToFilter,
  setNewNameToFilter,
  activeFilterParameter,
}: SearchBarResultProps) => (
  <Container>
    {isEmployerPage ? <img src="/images/insightsLogo.png" /> : <h4>{title}</h4>}
    <Result $isActive={!!nameToFilter}>
      {nameToFilter ? (
        <p>{nameToFilter}</p>
      ) : (
        <FilterParameter>{activeFilterParameter.toLowerCase()}</FilterParameter>
      )}
      {nameToFilter && (
        <CleanResult>
          <ActionButton
            icon={<CloseIcon color={theme.colors.funtainBlue} />}
            clickHandler={() => setNewNameToFilter('')}
          />
        </CleanResult>
      )}
    </Result>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;

  h4 {
    line-height: 25px;
    font-size: 20px;
    font-weight: 700;
    padding-right: 5px;
  }

  p {
    line-height: 25px;
    font-size: 20px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    p {
      max-width: 450px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  @media (width <= 880px) {
    p {
      max-width: 90px;
    }
  }
`;

const Result = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  ${({ $isActive }) =>
    $isActive &&
    css`
      p {
        color: ${({ theme }) => theme.colors.funtainBlue};
      }
    `}
`;

const BUTTON_VERTICAL_RANGE = 8;
const BUTTON_HORIZONTAL_RANGE = 8;

const CleanResult = styled.div`
  margin-left: 15px;
  transform: scale(0.7);

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 5px;
  }
`;

const FilterParameter = styled.p`
  text-transform: capitalize;
  padding: 0;
`;

export default SearchBarResult;
