import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Navbar from 'components/actionModal/Navbar';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import PromotionOfferPage from 'components/actionModal/stages/offerPage/PromotionOfferPage';
import Result from 'components/actionModal/stages/Result';
import SelectCategory from 'components/actionModal/stages/SelectCategory';
import PromotionOfferSettings from 'components/actionModal/stages/settings/PromotionOfferSettings';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import convertPromotionTerms from 'helpers/convertPromotionTerms';
import { PromotionOfferContext } from 'modules/offers/context/PromotionOfferContext';
import useGetOfferTerms, {
  OfferTerms,
} from 'modules/offers/hooks/useGetOfferTerms';
import useSaveImage from 'modules/offers/hooks/useSaveImage';
import useSetRightStageAfterRequest from 'modules/offers/hooks/useSetRightStageAfterRequest';
import {
  createPromotionfferDraft,
  getPromotionOfferTerms,
  publishPromotionOffer,
  uploadPromotionLogo,
} from 'modules/offers/offersServices/PromotionOffersService';

import {
  OfferCategory,
  OfferModalWindowStages,
  PromotionOfferPageAttributes,
  PromotionSettingsPageAttributes,
  QueryKey,
} from '../../models';
import { dataForSelectingCategory } from '../CreateOfferModal';

const NAVBAR_ITEMS = [
  {
    text: 'Select type',
    name: OfferModalWindowStages.SelectType,
  },
  {
    text: 'Promotion page',
    name: OfferModalWindowStages.OfferPage,
  },
  {
    text: 'Settings',
    name: OfferModalWindowStages.Settings,
  },
];

const DRAFT_CREATED = 'DRAFT_CREATED';
const PROMO_PUBLISHED = 'PROMO_PUBLISHED';

type CreatePromotionModalProps = {
  isWindowClosing: boolean;
  isNavbarTitleShown: boolean;
  activatedStages: OfferModalWindowStages[];
  showNavbarTitle: (value: boolean) => void;
  closeModalWindow: () => void;
  setActiveCategory: (category: OfferCategory) => void;
  setActivatedStages: (stage: OfferModalWindowStages) => void;
  freezeSettingsPageActivate: (disable?: boolean) => void;
};

const CreatePromotionModal = ({
  isWindowClosing,
  isNavbarTitleShown,
  activatedStages,
  closeModalWindow,
  setActiveCategory,
  showNavbarTitle,
  setActivatedStages,
  freezeSettingsPageActivate,
}: CreatePromotionModalProps) => {
  const queryClient = useQueryClient();

  const {
    offerDraft,
    imageName,
    image,
    promotionTerms,
    setQrCodeUrl,
    saveSettings,
    saveOfferSettings,
    setImageFile,
    setImage,
    setImageName,
    savePromotionTerm,
    saveAccount,
    setPublicCode,
  } = useContext(PromotionOfferContext);

  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.SelectType
  );
  const [isPending, setPending] = useState(false);
  const [result, setResult] = useState('');

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(NAVBAR_ITEMS[stage].name),
    []
  );

  const goToSettingsStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.Settings);
    setActivatedStages(OfferModalWindowStages.Settings);

    if (!isNavbarTitleShown) {
      showNavbarTitle(true);
    }
  }, [setActivatedStages, showNavbarTitle, isNavbarTitleShown]);

  const goToOfferPageStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.OfferPage);
    setActivatedStages(OfferModalWindowStages.OfferPage);
  }, [setActivatedStages]);

  const goToResultStage = useCallback(
    () => setActiveStage(OfferModalWindowStages.Result),
    []
  );

  const goToNextStage = useCallback(() => {
    if (result === DRAFT_CREATED) {
      goToSettingsStage();
    } else if (result === PROMO_PUBLISHED) {
      closeModalWindow();
    }

    setResult('');
  }, [closeModalWindow, goToSettingsStage, result]);

  useEffect(() => {
    if (isWindowClosing) {
      closeModalWindow();
    }
  }, [closeModalWindow, isWindowClosing]);

  const uploadLogo = useMutation(uploadPromotionLogo);

  const saveImage = useSaveImage({
    setPending,
    setImageFile,
    setImage,
    uploadLogo: uploadLogo.mutateAsync,
    offerId: offerDraft.promotionAccountId,
  });

  const savePromotionTerms = useCallback(
    (data: OfferTerms) => {
      savePromotionTerm(convertPromotionTerms(data));
    },
    [savePromotionTerm]
  );

  useGetOfferTerms(getPromotionOfferTerms, savePromotionTerms);

  const {
    isLoading: createPromotionOfferDraftLoading,
    mutate: createOfferDraft,
  } = useMutation(createPromotionfferDraft, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.GetPromotionOffers);
      setQrCodeUrl(data.qrCodeUrl);
      saveAccount(data.id);
      setPublicCode(data.publicCode);
      setResult(DRAFT_CREATED);
      goToResultStage();
    },
  });

  const {
    isLoading: publishPromotionOfferDraftLoading,
    mutate: publishOfferDraft,
  } = useMutation(publishPromotionOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.GetPromotionOffers);
      setResult(PROMO_PUBLISHED);
      goToResultStage();
    },
  });

  const sendCreatePromotionOfferDraftRequest = useCallback(
    (data: PromotionOfferPageAttributes) => {
      createOfferDraft({
        data: {
          ...data,
          promotionTermId: offerDraft.settings.offerStage.promotionTermId,
        },
      });
    },
    [createOfferDraft, offerDraft.settings.offerStage.promotionTermId]
  );

  const sendPublishPromotionOfferDraftRequest = useCallback(
    (data: PromotionSettingsPageAttributes) => {
      publishOfferDraft({
        data,
        id: offerDraft.promotionAccountId,
      });
    },
    [offerDraft.promotionAccountId, publishOfferDraft]
  );

  const isLoadingStarted =
    createPromotionOfferDraftLoading || publishPromotionOfferDraftLoading;

  useSetRightStageAfterRequest(
    !!result &&
      (isLoadingStarted || activeStage === OfferModalWindowStages.Result),
    goToNextStage
  );

  return (
    <>
      {isLoadingStarted || activeStage === OfferModalWindowStages.Result ? (
        <Result
          disableAutoClosing
          title={result === PROMO_PUBLISHED ? 'Promotion offer published!' : ''}
          closeModal={goToNextStage}
        />
      ) : (
        <>
          <Navbar
            title="Create offer"
            activeStage={activeStage}
            showTitle={isNavbarTitleShown}
            accountCategory={OfferCategory.Promotion}
            activatedStages={activatedStages}
            navbarItems={NAVBAR_ITEMS}
            changeActiveStage={changeActiveStage}
          />
          <ActionModalMainContent>
            {activeStage === OfferModalWindowStages.SelectType && (
              <SelectCategory
                accountCategory={OfferCategory.Promotion}
                categories={dataForSelectingCategory}
                saveData={setActiveCategory}
                goToNextStage={goToOfferPageStage}
              />
            )}
            <PromotionOfferPage
              isShowStage={activeStage === OfferModalWindowStages.OfferPage}
              promoArray={promotionTerms}
              title="Offer page"
              settings={offerDraft.settings.offerStage}
              saveSettings={saveOfferSettings}
              sendRequest={sendCreatePromotionOfferDraftRequest}
              freezeSettingsPageActivate={freezeSettingsPageActivate}
            />
            <PromotionOfferSettings
              isShowStage={activeStage === OfferModalWindowStages.Settings}
              title="Settings"
              submitButtonTitle="Publish"
              isPending={isPending}
              settings={offerDraft.settings.settingsStage}
              previewOffer={PreviewOffer.Promotion}
              imageName={imageName}
              image={image}
              saveSettings={saveSettings}
              saveImage={saveImage}
              saveImageName={setImageName}
              goToNextStage={sendPublishPromotionOfferDraftRequest}
            />
          </ActionModalMainContent>
        </>
      )}
    </>
  );
};

export default React.memo(CreatePromotionModal);
