import { User, UserStatusBE, UserSubscription, UserTableItem } from '../models';

export const ExpiresItems = ['1 month', '3 months', '12 months', 'Never'];

export const userExample: User = {
  id: '1',
  userName: 'Muhhamed',
  email: 'john@gmail.com',
  phoneNumber: '12345 67890',
  countryCode: '+93',
  countryAbbreviation: 'AF',
  createdOn: new Date(),
  term: '12 Months',
  type: 'App subscriber',
  subscription: UserSubscription.Subscribed,
  offerCode: 'BH2021',
  status: UserStatusBE.Active,
  joined: '10 March 2022',
  gender: 'Male',
  timezoneId: 'Netherlands',
  createdDateTime: new Date(),
  birthYear: 1920,
};

export const usersMockData: UserTableItem[] = [
  {
    id: '1',
    email: 'vfkdjn@gm.co',
    userName: 'kvfdjnj',
    phoneNumber: '743676773',
    countryCode: '+93',
    createdDateTime: new Date(),
  },
  {
    id: '2',
    email: 'bgjnkgfn@gm.co',
    userName: 'kmlgfbm',
    phoneNumber: '743674637',
    countryCode: '+93',
    createdDateTime: new Date(),
  },
  {
    id: '3',
    email: 'vkfdjfnvj@gm.co',
    userName: 'vfkfjvn',
    phoneNumber: '746373763',
    countryCode: '+93',
    createdDateTime: new Date(),
  },
  {
    id: '4',
    email: 'bjgfngb@gm.co',
    userName: 'jnfbg',
    phoneNumber: '753454344',
    countryCode: '+93',
    createdDateTime: new Date(),
  },
  {
    id: '5',
    email: 'vkfjdnvfj@gmc.co',
    userName: 'vjfnkjvn',
    phoneNumber: '735423343',
    countryCode: '+93',
    createdDateTime: new Date(),
  },
  {
    id: '6',
    email: 'progress@bar.test',
    userName: 'Progress@bar.test',
    phoneNumber: '',
    countryCode: '',
    createdDateTime: new Date(),
  },
  {
    id: '7',
    email: 'p@b.com',
    userName: 'Pablo',
    phoneNumber: '',
    countryCode: '',
    createdDateTime: new Date(),
  },
];
