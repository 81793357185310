import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DocumentIcon from 'components/icons/DocumentIcon';
import EditIcon from 'components/icons/EditIcon';
import PersonIcon from 'components/icons/PersonIcon';
import QRIcon from 'components/icons/QRIcon';
import { Device } from 'components/previewPages/models';
import {
  DashedWrapper,
  InfoItemWrapperColumn,
  Label,
  ModalContainer,
  Value,
} from 'modules/onboarding/components/styledElements/styledModalElements';

import { OnboardingOfferItem } from '../../models';

import DetailsModalHeader from './DetailsModalLandingHeader';

type LandingRowDetailsModalProps = {
  offer: OnboardingOfferItem;
  closeWindow: () => void;
  openEditingModalWindow: () => void;
  openPreview: (model: Device) => void;
};

const LandingRowDetailsModal = ({
  offer,
  closeWindow,
  openPreview,
  openEditingModalWindow,
}: LandingRowDetailsModalProps) => {
  return (
    <ModalContainer>
      <DetailsModalHeaderWrapper>
        <DetailsModalHeader
          openPreview={openPreview}
          closeModal={closeWindow}
        />
      </DetailsModalHeaderWrapper>
      <PageTitle>Landing page</PageTitle>
      <NameTitle>{offer.account}</NameTitle>
      <InfoContainer>
        <InfoItemsContainer>
          <InfoItemWrapperColumn>
            <InfoItemWrapper>
              <Label>Offer code</Label>
              <Value>{offer.offerCode}</Value>
            </InfoItemWrapper>
            <InfoItemWrapper>
              <Label>Term</Label>
              <MonthValueWrapper>6 months</MonthValueWrapper>
            </InfoItemWrapper>
            <InfoItemWrapper>
              <Label>Redemption limit</Label>
              <LimitWrapper>10,000</LimitWrapper>
            </InfoItemWrapper>
          </InfoItemWrapperColumn>
          <InfoItemWrapperColumn>
            <InfoItemWrapper>
              <Label>URL</Label>
              <Value>www.shido.me/arnoldclark-A03</Value>
            </InfoItemWrapper>
            <InfoItemWrapper>
              <Label>Valid</Label>
              <Value>20 Aug 2022 - 20 Dec 2022</Value>
            </InfoItemWrapper>
            <InfoItemWrapper>
              <Label>Users</Label>
              <Value>7.651</Value>
            </InfoItemWrapper>
          </InfoItemWrapperColumn>
        </InfoItemsContainer>
        <AdditionalInfoContainer>
          <AdditionalInfoBox>
            <BoxTitle>POSTER.PDF</BoxTitle>
            <HorizontalLine />
            <DocumentIcon />
          </AdditionalInfoBox>
          <AdditionalInfoBox>
            <BoxTitle>QR CODE.SVG</BoxTitle>
            <HorizontalLine />
            <QRIcon />
          </AdditionalInfoBox>
        </AdditionalInfoContainer>
      </InfoContainer>
      <ButtonsContainer>
        <ButtonContainer onClick={openEditingModalWindow}>
          <EditIcon width={24} height={24} color={theme.colors.white} />
          <ButtonText>EDIT PAGE</ButtonText>
        </ButtonContainer>
        <VerticalLine />
        <ButtonContainer>
          <PersonIcon />
          <ButtonText>USERS</ButtonText>
        </ButtonContainer>
      </ButtonsContainer>
    </ModalContainer>
  );
};

const LimitWrapper = styled(Value)`
  position: relative;
  right: 36px;
`;

const MonthValueWrapper = styled(Value)`
  position: relative;
  right: 22px;
`;

const InfoItemsContainer = styled.div`
  display: flex;
  column-gap: 19px;
`;

const InfoItemWrapper = styled(DashedWrapper)`
  width: 306px;

  &:nth-child(2) {
    height: 46px;
    border-bottom: none;
    border-top: none;
  }
`;

const NameTitle = styled.div`
  font-size: 30px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 10px;
  text-transform: capitalize;
`;

const PageTitle = styled.div`
  margin-top: 41px;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
`;

const DetailsModalHeaderWrapper = styled.div`
  position: absolute;
  top: 46px;
  right: 18px;
`;

const AdditionalInfoBox = styled.div`
  width: 91px;
  height: 91px;
  background-color: rgb(255 255 255 / 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 11px 0 10px;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  column-gap: 18px;
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 39px;
  justify-content: space-between;
`;

const BoxTitle = styled.div`
  font-size: 10px;
  margin-top: 19px;
  color: white;
`;

const HorizontalLine = styled.div`
  height: 1px;
  background-color: rgb(255 255 255 / 20%);
  width: 100%;
  margin-top: 17px;
  margin-bottom: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 40px;
`;

const VerticalLine = styled.div`
  width: 1px;
  background-color: rgb(255 255 255 / 20%);
  margin-left: 20px;
  margin-right: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
`;

const ButtonText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`;

export default LandingRowDetailsModal;
