import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Device } from 'components/previewPages/models';
import PreviewPageInsight from 'components/previewPages/previewPageCorporate/PreviewPageInsight';
import { TableRowGrid } from 'components/table/models';
import TableRow from 'components/table/tableRow/TableRow';
import { utcToCustomV2 } from 'helpers/utcFormatToCustom';

import { OnboardingOfferContext } from '../../context/OnboardingOfferContext';
import { OnboardingOfferItem } from '../../models';

import LandingRowDetailsModal from './LandingRowDetailsModal';

type LandingContainerRowProps = {
  item: OnboardingOfferItem;
  openEditModalWindow: (data: OnboardingOfferItem) => void;
};

const LandingContainerRow = ({
  item,
  openEditModalWindow,
}: LandingContainerRowProps) => {
  const [isRowModalOpen, setIsRowModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [activeDeviceForPreview, setActiveDeviceForPreview] = useState(
    Device.Mobile
  );

  const { saveOfferData } = useContext(OnboardingOfferContext);

  const openPreview = useCallback((device: Device) => {
    document.body.style.overflowY = 'hidden';
    setIsPreviewOpen(true);
    setActiveDeviceForPreview(device);
  }, []);

  const toggleRowModal = useCallback(() => {
    setIsRowModalOpen(!isRowModalOpen);
  }, [isRowModalOpen]);

  useEffect(() => {
    saveOfferData({
      account: item.account,
      offerTerm: item.term,
    });
    // we have to call this function only once to prevent from multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container $isRowModalOpen={isRowModalOpen}>
      <TableRow
        middleUpperCase
        key={item.id}
        tableName={TableRowGrid.LandingUsers}
        data={[
          `www.shido.me/${item.account}`,
          item.offerCode,
          item.users,
          utcToCustomV2(new Date(), true),
        ]}
        openModalWindow={toggleRowModal}
      />
      {isRowModalOpen && (
        <LandingRowDetailsModal
          openPreview={openPreview}
          offer={item}
          openEditingModalWindow={() => openEditModalWindow(item)}
          closeWindow={toggleRowModal}
        />
      )}
      {isPreviewOpen && (
        <PreviewPageInsight
          logo={item.logo.url}
          background={item.background.url}
          chosenDevice={activeDeviceForPreview}
          closePreview={() => {
            setIsPreviewOpen(false);
            document.body.style.overflowY = 'auto';
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ $isRowModalOpen?: boolean }>`
  position: relative;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  font-size: 12px;
  overflow: hidden;

  &:nth-child(even) {
    .row:first-child {
      background-color: ${({ theme }) => theme.colors.lightYellow};
    }
  }

  ${({ $isRowModalOpen }) =>
    $isRowModalOpen &&
    css`
      height: 370px;
    `}
`;

export default React.memo(LandingContainerRow);
