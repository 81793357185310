import { StatusBE } from 'components/detailsModal/models';
import countries from 'components/listForSelectingData/dataForList/countries';

export enum DelegatesWindowStages {
  DelegatesDetails = 'DELEGATES_DETAILS',
  Result = 'RESULT',
}

export type CreatableDelegateAttributes = {
  firstName: string;
  lastName: string;
  role: string;
  job: string;
  email: string;
  mobileNumber: string;
  mobileNumberCode: string;
  countryAbbreviation?: string;
};

export interface EditableDelegateAttributes
  extends CreatableDelegateAttributes {
  id: string;
  status: StatusBE;
}

export const DELEGATE_ITEM: CreatableDelegateAttributes = {
  firstName: '',
  lastName: '',
  role: '',
  job: '',
  email: '',
  mobileNumber: '',
  mobileNumberCode: countries[0].code,
  countryAbbreviation: 'AF',
};

export const USERS_SORT_BY_BE = ['Email A-Z', 'Latest'];
export const USERS_SORT_BY_FE = ['A-Z', 'Latest'];
