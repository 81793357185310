const EXTRA_MINUTES = 5;

const getExactTime = () => {
  const date = new Date();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  return [hours, minutes + EXTRA_MINUTES, seconds, milliseconds];
};

export default getExactTime;
