export enum ContentType {
  Modules = 'Modules',
  Courses = 'Courses',
}

export enum ContentQueryKey {
  GetTopics = 'GET_TOPICS',
  GetCourses = 'GET_COURSES',
}

export type TopicType = {
  id: string;
  title: string;
  numberOfItems?: number;
  imgUrl: string;
  appliedDate?: number;
};
export interface EditableTopicType extends TopicType {
  isChosen: boolean;
}

export type CourseType = {
  id: string;
  title: string;
  description: string;
  courseLength: string;
  lessonNr: number;
  appliedDate?: number;
};
export interface EditableCourseType extends CourseType {
  isChosen: boolean;
}

export const TWENTY_EIGHT_DAYS = 28;
export const ONE_DAY = 1000 * 3600 * 24;
