import { RatingResult } from '../models';

type Rating = {
  diffPercentage: number;
  result: RatingResult;
};

export const getRatingBetween = (firstValue = 0, lastValue = 0): Rating => {
  const diffPercentage = Math.round((lastValue * 100) / firstValue) - 100 || 0;

  if (firstValue > lastValue) {
    return {
      diffPercentage,
      result: 'falls',
    };
  } else if (firstValue === lastValue) {
    return {
      diffPercentage,
      result: 'stay',
    };
  }

  return {
    diffPercentage,
    result: 'rises',
  };
};
