import React, { createContext, ReactNode, useCallback, useState } from 'react';

type OnboardingOfferContextType = {
  logoFile?: File;
  backgroundFile?: File;
  logoName: string;
  logoImage: string;
  backgroundName: string;
  backgroundImage: string;
  setLogoName: (name: string) => void;
  setLogoImage: (image: string) => void;
  setBackgroundName: (name: string) => void;
  setBackgroundImage: (image: string) => void;
  setBackgroundFile: (value?: File) => void;
  setLogoFile: (value?: File) => void;
  offerCodeData: OfferCodeDetails;
  saveOfferData: (data: OfferCodeDetails) => void;
};

export type OfferCodeDetails = {
  account: string;
  offerTerm: string;
};

const initialValue: OnboardingOfferContextType = {
  logoName: '',
  logoImage: '',
  backgroundName: '',
  backgroundImage: '',
  setLogoName: () => null,
  setLogoImage: () => null,
  setBackgroundName: () => null,
  setBackgroundImage: () => null,
  setLogoFile: () => null,
  setBackgroundFile: () => null,
  saveOfferData: () => null,
  offerCodeData: {
    account: '',
    offerTerm: '',
  },
};

export const OnboardingOfferContext =
  createContext<OnboardingOfferContextType>(initialValue);

const OnboardingOfferContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [logoName, setLogoName] = useState('');
  const [logoImage, setLogoImage] = useState('');
  const [backgroundName, setBackgroundName] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [logoFile, setLogoFile] = useState<File>();
  const [backgroundFile, setBackgroundFile] = useState<File>();
  const [offerCodeData, setOfferCodeData] = useState(
    initialValue.offerCodeData
  );

  const saveOfferData = useCallback(
    (data: OfferCodeDetails) => {
      setOfferCodeData({ ...offerCodeData, ...data });
    },
    [offerCodeData]
  );

  return (
    <OnboardingOfferContext.Provider
      value={{
        saveOfferData,
        offerCodeData,
        logoFile,
        backgroundFile,
        logoName,
        logoImage,
        setLogoImage,
        setLogoName,
        setLogoFile,
        setBackgroundFile,
        backgroundName,
        backgroundImage,
        setBackgroundName,
        setBackgroundImage,
      }}
    >
      {children}
    </OnboardingOfferContext.Provider>
  );
};

export default OnboardingOfferContextProvider;
