import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { BadResultItemInfo, ItemInfo } from 'modules/insights/models';

import { demographicsData } from '../mockData/insightsData';

import DemographicsGraphs from './DemographicsGraphs';
import DemographicsModal from './modal/DemographicsModal';

const ONE_THIRD = 33;

const DemographicsInfo = ({
  isSectionVisible,
}: {
  isSectionVisible: boolean;
}) => {
  const [isModalOpen, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => {
    setOpenModal(!isModalOpen);
  }, [isModalOpen]);

  const { mutatedArray, arrayWithBadResults } = useMemo(() => {
    const mutatedArray: ItemInfo[] = [];
    const arrayWithBadResults: BadResultItemInfo[] = [];

    demographicsData.forEach((item) => {
      if (item.percent < ONE_THIRD) {
        const sliderId = arrayWithBadResults.length + 1;

        arrayWithBadResults.push({
          ...item,
          sliderId,
        });

        mutatedArray.push({
          ...item,
          sliderId,
        });

        return;
      }

      mutatedArray.push(item);
    });

    return { mutatedArray, arrayWithBadResults };
  }, []);

  return (
    <Container>
      <DemographicsGraphs
        isSectionVisible={isSectionVisible}
        openModal={toggleModal}
        resultArray={mutatedArray}
      />
      {isModalOpen && (
        <DemographicsModal
          resultArray={arrayWithBadResults}
          closeModal={toggleModal}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-height: 580px;
  width: 100%;
`;

export default DemographicsInfo;
