import styled from 'styled-components';

export const HorizontalLine = styled.hr<{ $color?: string }>`
  background-color: ${({ $color, theme }) =>
    $color ? $color : theme.colors.white};
  opacity: 0.5;
  border: none;
  height: 1px;
`;

export const VerticalLine = styled.div<{
  $color: string;
  $height?: number;
  $withOpacity?: boolean;
}>`
  height: ${({ $height }) => ($height ? $height : '30')}px;
  width: 1.5px;
  opacity: ${({ $withOpacity }) => ($withOpacity ? '0.3' : '1')};
  background-color: ${({ $color }) => $color};
`;

export const DottedLine = styled.hr`
  margin: 0;
  opacity: 0.2;
  border-top: 2px dotted ${({ theme }) => theme.colors.primary};
`;

export const DashedLine = styled.hr`
  margin: 0;
  border: none;
  opacity: 0.2;
  border-top: 2px dashed ${({ theme }) => theme.colors.primary};
`;
