import React from 'react';

const BookMarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="24.021"
    viewBox="0 0 17 24.021"
  >
    <g
      id="Group_15470"
      data-name="Group 15470"
      transform="translate(-0.5 23.907)"
    >
      <g
        id="Group_14945"
        data-name="Group 14945"
        transform="translate(1.5 -22.907)"
      >
        <path
          id="Path_12853"
          data-name="Path 12853"
          d="M13.82,21.863,7.5,17.4,1.18,21.863a.745.745,0,0,1-.776.051.757.757,0,0,1-.4-.671V1.513A1.507,1.507,0,0,1,1.5,0h12A1.507,1.507,0,0,1,15,1.513V21.244a.757.757,0,0,1-.4.671A.745.745,0,0,1,13.82,21.863Z"
          fill="#69b0c7"
          stroke="#69b0c7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export default BookMarkIcon;
