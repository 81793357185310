import React, { useRef } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

import convertCustomDateToDateFormat from 'helpers/convertCustomDateToDateFormat';
import useOutsideClick from 'hooks/useOutsideClick';

import { months } from './calendarData';
import { CustomDate, ValidPeriodVariant } from './models';

const MS_IN_DAY = 86400000;

const calendarTitles = {
  [ValidPeriodVariant.ValidPeriodFrom]: 'Valid period from',
  [ValidPeriodVariant.ValidPeriodUntil]: 'Valid period until',
};

type Props = {
  validPeriod: CustomDate[];
  validPeriodVariant: ValidPeriodVariant;
  closeCalendar: () => void;
  setValidPeriod: (date: CustomDate) => void;
};

const CustomCalendar = ({
  validPeriod,
  setValidPeriod,
  validPeriodVariant,
  closeCalendar,
}: Props) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const [validPeriodFrom, validPeriodUntil] = validPeriod;

  const activeValidPeriodFrom = validPeriodFrom.day
    ? convertCustomDateToDateFormat(validPeriodFrom)
    : null;

  const activeValidPeriodUntil = validPeriodUntil.day
    ? convertCustomDateToDateFormat(validPeriodUntil)
    : null;

  const maxDate =
    validPeriodVariant === ValidPeriodVariant.ValidPeriodFrom &&
    validPeriodUntil.day
      ? new Date(
          convertCustomDateToDateFormat(validPeriodUntil).getTime() - MS_IN_DAY
        )
      : undefined;

  const minDate =
    validPeriodVariant === ValidPeriodVariant.ValidPeriodUntil &&
    validPeriodFrom.day
      ? new Date(
          convertCustomDateToDateFormat(validPeriodFrom).getTime() + MS_IN_DAY
        )
      : new Date(new Date().getTime());

  useOutsideClick(calendarRef, closeCalendar);

  return (
    <Container ref={calendarRef} data-testid="calendar-wrapper">
      <CalendarTitle>{calendarTitles[validPeriodVariant]}</CalendarTitle>
      <CalendarWrapper
        onClickDay={(value: Date) => {
          setValidPeriod({
            day: String(value.getDate()),
            month: months[value.getMonth()],
            year: String(value.getFullYear()),
          });
          closeCalendar();
        }}
        minDate={minDate}
        maxDate={maxDate}
        value={
          validPeriodVariant === ValidPeriodVariant.ValidPeriodFrom
            ? activeValidPeriodFrom
            : activeValidPeriodUntil
        }
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 170px;
  z-index: 10;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgb(0 0 0 / 60%);
  background-color: ${({ theme }) => theme.colors.white};
`;

const CalendarWrapper = styled(Calendar)`
  button {
    background: none;
    border-radius: 2px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.funtainBlue};
    border: 0.5px solid ${({ theme }) => theme.colors.funtainBlue};
    height: 25px;

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.dustyGray};

      span {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  div.react-calendar__month-view__weekdays {
    margin: 5px 0;

    div {
      text-align: center;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  button.react-calendar__tile--active {
    cursor: default;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.funtainBlue};
  }

  button[disabled='disabled'],
  button:disabled {
    background-color: ${({ theme }) => theme.colors.quillGray};

    &:hover {
      cursor: default;
      color: ${({ theme }) => theme.colors.funtainBlue};
      border-color: ${({ theme }) => theme.colors.funtainBlue};
      background-color: ${({ theme }) => theme.colors.quillGray};
    }
  }
`;

const CalendarTitle = styled.div`
  margin-bottom: 5px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.funtainBlue};
`;

export default CustomCalendar;
