import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

type Error = {
  isOpen: boolean;
  errorText: string;
};

const Error = ({ isOpen, errorText }: Error) => {
  const actionWindow = document.getElementById('action_window');

  if (!actionWindow) return null;

  return ReactDOM.createPortal(
    <Container $isOpen={isOpen}>{errorText}</Container>,
    actionWindow
  );
};

const Container = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  height: 40px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.5s;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.hibiscus};
  color: ${({ theme }) => theme.colors.white};
  visibility: hidden;
  opacity: 0;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      top: -40px;
      visibility: visible;
      opacity: 1;

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        height: 5px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

export default Error;
