import React from 'react';
const KeyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16.003"
    height="16.004"
    viewBox="0 0 16.003 16.004"
  >
    <defs>
      <clipPath id="key-clip-path">
        <path
          id="Path_8761"
          data-name="Path 8761"
          d="M15.67-24h-2a.333.333,0,0,0-.236.1L7.079-17.547a5,5,0,0,0-6.185,1.7,5,5,0,0,0,.572,6.389,5,5,0,0,0,6.389.572,5,5,0,0,0,1.7-6.185l1.542-1.542,1.289.429a.667.667,0,0,0,.638-.125.667.667,0,0,0,.229-.609l-.241-1.611.484-.484.971.441a.667.667,0,0,0,.711-.1.667.667,0,0,0,.208-.687l-.337-1.206.862-.862a.334.334,0,0,0,.1-.236v-2A.333.333,0,0,0,15.67-24Zm-11,12.336a1,1,0,0,1-1,1,1,1,0,0,1-1-1,1,1,0,0,1,1-1,1,1,0,0,1,1,1Z"
          transform="translate(-0.001 24)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="Group_10931"
      data-name="Group 10931"
      transform="translate(-0.001 24)"
    >
      <g
        id="Group_10930"
        data-name="Group 10930"
        transform="translate(0.001 -24)"
        clipPath="url(#key-clip-path)"
      >
        <path
          id="Path_8760"
          data-name="Path 8760"
          d="M-5-29H17.672V-6.328H-5Z"
          transform="translate(1.665 25.666)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default KeyIcon;
