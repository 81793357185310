import React from 'react';

const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16.002"
    height="14.666"
    viewBox="0 0 16.002 14.666"
  >
    <defs>
      <clipPath id="email-clip-path">
        <path
          id="Path_6918"
          data-name="Path 6918"
          d="M15.88-21.923a.333.333,0,0,0-.341-.052L.205-15.641A.333.333,0,0,0,0-15.33a.333.333,0,0,0,.212.307l4.221,1.639a.333.333,0,0,0,.312-.037l5.6-3.983a.333.333,0,0,1,.452.057.333.333,0,0,1-.027.455L6.1-12.4a.333.333,0,0,0-.1.24v4.489a.333.333,0,0,0,.246.321A.333.333,0,0,0,6.622-7.5l2.111-3.617a.167.167,0,0,1,.225-.062l3.884,2.133a.333.333,0,0,0,.3.011.333.333,0,0,0,.188-.233L16-21.6A.339.339,0,0,0,15.88-21.923Z"
          transform="translate(0 22)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="Group_6239" data-name="Group 6239" transform="translate(0 22)">
      <g
        id="Group_6238"
        data-name="Group 6238"
        transform="translate(0 -22)"
        clipPath="url(#email-clip-path)"
      >
        <path
          id="Path_6917"
          data-name="Path 6917"
          d="M-5-27H17.669V-5.667H-5Z"
          transform="translate(1.666 23.667)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default EmailIcon;
