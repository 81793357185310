import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import PlayIcon from 'components/icons/PlayIcon';
import ShidoIcon from 'components/icons/ShidoIcon';
import ShidoTextIcon from 'components/icons/ShidoTextIcon';
import { ListItemData } from 'helpers/filterDataForDropdownList';

import FormContainer from './FormContainer';
import {
  BroughtToYou,
  EnteringOfferCode,
  Notification,
  MainContent,
  PlayButtonContainer,
  Container,
  Header,
  ShidoLink,
  RegisterSymbol,
  ShidoSlogan,
  ShidoExplanation,
  SuggestionToWatchVideo,
} from './StyledElements';

type TabletPreviewProps = {
  isShowDepartment?: boolean;
  data: ListItemData[];
  isInsightOffer?: boolean;
  companyName: string;
  offerTerm: string;
  background?: string;
};

const TabletPreview = ({
  isShowDepartment,
  data,
  companyName,
  offerTerm,
  background,
}: TabletPreviewProps) => (
  <Container>
    <TabletContent>
      <MainTabletContent $isTablet $backgroundImg={background}>
        <TabletHeader>
          <ShidoLink>
            <ShidoIcon width={63} height={38} color={theme.colors.white} />
            <ShidoTextIcon width={98} height={32} color={theme.colors.white} />
            <RegisterSymbol $isTablet $color={theme.colors.white}>
              ®
            </RegisterSymbol>
          </ShidoLink>
        </TabletHeader>
        <TabletOfferCode>
          <BroughtToYou $isTablet>Brought to you by {companyName}</BroughtToYou>
          <Notification $isTablet>Receive {offerTerm}!</Notification>
          <FormContainer isShowDepartment={isShowDepartment} data={data} />
        </TabletOfferCode>
      </MainTabletContent>
      <ShidoSlogan>
        Helping you be your best & achieve optimal health.
      </ShidoSlogan>
      <TabletShidoExplanation>
        Shido connects all aspects of your health in a single place, from
        nutrition to motivation & condition management, we have an entire team
        dedicated to providing you with advice & inspiration targeted to your
        specific needs, interests & goals.
      </TabletShidoExplanation>
      <SuggestionToWatchVideo>see how</SuggestionToWatchVideo>{' '}
      <PlayButtonContainer>
        <PlayIcon />
      </PlayButtonContainer>
    </TabletContent>
  </Container>
);

const MainTabletContent = styled(MainContent)`
  position: relative;
`;

const TabletHeader = styled(Header)`
  top: 43px;
`;

const TabletOfferCode = styled(EnteringOfferCode)`
  margin-top: 40px;
`;

const TabletShidoExplanation = styled(ShidoExplanation)`
  padding: 0 65px;
`;

const TabletContent = styled.div`
  width: 768px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 20px 40px rgb(0 0 0 / 40%);
  margin: 0 20px;
  padding-bottom: 68px;
  height: fit-content;
  border-radius: 8px;
`;

export default TabletPreview;
