import React, { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import ProgressBar from 'components/ProgressBar';
import colorChecker from 'helpers/colorChecker';

const progressBarSections = ['Active targets', 'Achieve 7 days streaks'];

type GoalCardProps = {
  id: number;
  title: string;
  imageSrc: string;
  results: number[];
};

const GoalCard = ({ id, title, imageSrc, results }: GoalCardProps) => {
  const [isProgressBarVisible, setProgressBarVisible] = useState(false);

  return (
    <Container>
      <NumberTitle>#{id}</NumberTitle>
      <CardTitle>{title}</CardTitle>

      <ImageWrapper>
        <img src={imageSrc} alt={title} />
      </ImageWrapper>
      <ProgressBarWrapper>
        {progressBarSections.map((name, i) => {
          const color = colorChecker(results[i]);

          return (
            <ReactVisibilitySensor
              key={name}
              onChange={(isVisibleBar: boolean) => {
                if (!isProgressBarVisible && isVisibleBar) {
                  setProgressBarVisible(true);
                }
              }}
            >
              <ProgressBarElementWrapper>
                <ProgressBarInfo>
                  <Name>{name}</Name>
                  <Percent>{results[i]}%</Percent>
                </ProgressBarInfo>
                <ProgressBar
                  isVisible={isProgressBarVisible}
                  color={color}
                  percent={results[i]}
                />
              </ProgressBarElementWrapper>
            </ReactVisibilitySensor>
          );
        })}
      </ProgressBarWrapper>
    </Container>
  );
};

const NumberTitle = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const Percent = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const ProgressBarInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -11px;
  row-gap: 7px;
`;

const ProgressBarElementWrapper = styled.div`
  margin-top: 33px;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
`;

const ImageWrapper = styled.div`
  margin: auto;
  height: 242px;
  width: 242px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media (width <= 1333px) {
    width: 160px;
  }
`;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;
  height: 440px;
  box-sizing: border-box;
  padding: 0 40px;
  border-right: 1px solid rgb(219 221 227);

  @media (width <= 1333px) {
    width: 285px;
  }
`;

export default React.memo(GoalCard);
