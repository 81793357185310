import config from 'config';
import React, { useCallback, useMemo, useState } from 'react';

import InstallDescription from 'components/detailsModal/InstallDescription/pages/InstallDescription';
import {
  OPENING_MODAL_ANIMATION_DELAY,
  pageSizes,
  TableRowGrid,
} from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import TableRow from 'components/table/tableRow/TableRow';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanOfferLocalStorage } from 'helpers/connectToLocalStorage';

import CorporateOfferContextProvider from '../../context/CorporateOfferContext';
import { OFFERS_SORT_BY_FE } from '../../models';
import { CorporateOffer } from '../../offersServices/CorporateOffersService';

import CorporateDetailsModal from './CorporateDetailsModal';
import EditCorporateModal from './EditCorporateModal';

type CorporateTableProps = {
  data?: CorporateOffer[];
  dataAmount: number;
  page: number;
  activePageSize: number;
  sortByListItemIndex: number;
  sortOffers: (index: number) => void;
  setActivePageSize: (pageSize: number) => void;
  setPage: (pageNumber: number) => void;
};

const CorporateTable = ({
  data,
  dataAmount,
  activePageSize,
  setActivePageSize,
  page,
  setPage,
  sortOffers,
  sortByListItemIndex,
}: CorporateTableProps) => {
  const [dataForModal, setDataForModal] = useState<CorporateOffer>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [offerForEditing, setOfferForEditing] = useState<
    CorporateOffer | undefined
  >();

  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, activePageSize),
    [activePageSize, dataAmount]
  );

  const closeModalWindow = () => setIsModalOpen(false);

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen((prev) => !prev);
    cleanOfferLocalStorage();
  }, []);

  const openModalWindow = useCallback(
    (data: CorporateOffer) =>
      setTimeout(() => {
        setIsModalOpen(true);
        setDataForModal(data);
      }, OPENING_MODAL_ANIMATION_DELAY),
    []
  );

  return (
    <>
      <TableInfo
        pageSizes={pageSizes}
        dataAmount={dataAmount}
        activePageSize={activePageSize}
        changePageSize={setActivePageSize}
      />
      {dataForModal && <InstallDescription offer={dataForModal} />}
      <TableContainer>
        <TableHeader
          tableName={TableRowGrid.CorporateOfferHeaderRow}
          sortData={sortOffers}
          sortByListItemIndex={sortByListItemIndex}
          parametersForSorting={OFFERS_SORT_BY_FE}
        />
        {!!data?.length && (
          <div>
            {data.map((item) => {
              return (
                <TableRow
                  href={`${config.shidoUrl}${encodeURIComponent(
                    item.publicCode
                  )}`}
                  key={item.id}
                  data={[
                    item.publicCode,
                    item.corporateAccount.companyName,
                    item.status,
                    `${item.redemptions} / ${item.redemptionLimit}`,
                    item.notes || '',
                  ]}
                  tableName={TableRowGrid.CorporateOfferRow}
                  temporaryStatus={undefined}
                  openModalWindow={() => openModalWindow(item)}
                />
              );
            })}
          </div>
        )}
        {isModalOpen && dataForModal && (
          <CorporateDetailsModal
            offer={dataForModal}
            setDataForEditing={setOfferForEditing}
            closeWindow={closeModalWindow}
            openEditingModalWindow={() => {
              toggleEditingModalWindow();
              closeModalWindow();
            }}
          />
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && offerForEditing && (
        <CorporateOfferContextProvider>
          <EditCorporateModal
            offer={offerForEditing}
            closeModalWindow={toggleEditingModalWindow}
          />
        </CorporateOfferContextProvider>
      )}
    </>
  );
};

export default CorporateTable;
