import React from 'react';
import theme from 'theme';

const DownRatingIcon = ({ color }: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_15433"
    data-name="Group 15433"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="17.012"
    height="9.512"
    viewBox="0 0 17.012 9.512"
  >
    <defs>
      <clipPath id="Down-rating-icon-clip-path">
        <path
          id="Path_14441"
          data-name="Path 14441"
          d="M10.177-6.4A.25.25,0,0,0,10-6.47a.25.25,0,0,0-.177.073L7.914-4.488a2,2,0,0,1-2.828,0L1.293-8.281a1,1,0,0,1-.273-.972,1,1,0,0,1,.714-.714,1,1,0,0,1,.972.273L6.323-6.079a.25.25,0,0,0,.177.073.25.25,0,0,0,.177-.073L8.586-7.988a2,2,0,0,1,2.828,0l4.013,4.013a.25.25,0,0,0,.353,0l1.366-1.367a.5.5,0,0,1,.354-.146.489.489,0,0,1,.191.038A.5.5,0,0,1,18-4.988v4a.5.5,0,0,1-.5.5h-4A.5.5,0,0,1,13.037-.8a.5.5,0,0,1,.109-.545l.867-.866a.25.25,0,0,0,0-.353Z"
          transform="translate(-0.988 10)"
          fill={color || theme.colors.cherry}
        />
      </clipPath>
    </defs>
    <g
      id="Group_15431"
      data-name="Group 15431"
      transform="translate(0 0)"
      clipPath="url(#Down-rating-icon-clip-path)"
    >
      <path
        id="Path_14440"
        data-name="Path 14440"
        d="M-4.012,4.512H23V-15H-4.012Z"
        transform="translate(-0.988 10)"
        fill={color || theme.colors.cherry}
      />
    </g>
  </svg>
);

export default DownRatingIcon;
