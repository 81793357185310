import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Navbar from 'components/navbar/Navbar';
import useAuth from 'modules/auth/hooks/useAuth';
import { NavbarLabel, AdminRoutes } from 'modules/router/models';

const NAVBAR_ITEMS = [
  {
    path: `../${AdminRoutes.Dashboard}`,
    label: NavbarLabel.Dashboard,
  },
  {
    path: `../${AdminRoutes.Accounts}`,
    label: NavbarLabel.Accounts,
  },
  {
    path: `../${AdminRoutes.Offers}`,
    label: NavbarLabel.Offers,
  },
  {
    path: `../${AdminRoutes.Users}`,
    label: NavbarLabel.Users,
  },
];

type Props = {
  children: ReactNode;
};

const AdminPrivateRoute = ({ children }: Props) => {
  const { tokenUpdateComplete } = useAuth();

  if (!tokenUpdateComplete) return null;

  return (
    <Container>
      <Navbar linksArray={NAVBAR_ITEMS} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.lightYellow};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export default AdminPrivateRoute;
