import React from 'react';

const DocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 8802"
          d="M15.5-18h3.9a.25.25 0 0 0 .231-.154.25.25 0 0 0-.054-.273l-5.15-5.148a.25.25 0 0 0-.272-.055.25.25 0 0 0-.155.23v3.9a1.5 1.5 0 0 0 1.5 1.5Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Path 8804"
          d="M9.951-10.178c-.105-.046-.326-.048-.326.164v3.977a.237.237 0 0 0 .109.19.237.237 0 0 0 .218.02A2.374 2.374 0 0 0 11.376-8a2.374 2.374 0 0 0-1.424-2.176Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Path 8806"
          d="M5.5-10.377h-.623a.252.252 0 0 0-.252.252v1.246a.252.252 0 0 0 .252.252H5.5a.875.875 0 0 0 .875-.873.875.875 0 0 0-.875-.875Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="d">
        <path
          data-name="Path 8808"
          d="M19.75-17H15.5a2.5 2.5 0 0 1-2.5-2.5v-4.252a.25.25 0 0 0-.25-.248H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-14.752a.25.25 0 0 0-.25-.248ZM5.5-7.377h-.623a.252.252 0 0 0-.252.252V-5A.625.625 0 0 1 4-4.375.625.625 0 0 1 3.375-5v-6a.625.625 0 0 1 .182-.443.625.625 0 0 1 .443-.184h1.5A2.125 2.125 0 0 1 7.625-9.5 2.125 2.125 0 0 1 5.5-7.377Zm3.5 3A.625.625 0 0 1 8.375-5v-6a.625.625 0 0 1 .182-.443.625.625 0 0 1 .443-.184A3.625 3.625 0 0 1 12.625-8 3.625 3.625 0 0 1 9-4.377Zm8-6h-2a.375.375 0 0 0-.375.375v1.123a.252.252 0 0 0 .252.252H16a.625.625 0 0 1 .625.627.625.625 0 0 1-.625.623h-1.123a.252.252 0 0 0-.252.252V-5a.625.625 0 0 1-.625.625.625.625 0 0 1-.625-.625v-5A1.627 1.627 0 0 1 15-11.627h2a.625.625 0 0 1 .625.625.625.625 0 0 1-.625.625Z"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g data-name="Group 10965">
      <g data-name="Group 10961" clipPath="url(#a)" transform="translate(0 24)">
        <path data-name="Path 8801" d="M9-28.648h15.65V-13H9Z" fill="#fff" />
      </g>
      <g data-name="Group 10962" clipPath="url(#b)" transform="translate(0 24)">
        <path
          data-name="Path 8803"
          d="M4.625-15.2h11.751V-.809H4.625Z"
          fill="#fff"
        />
      </g>
      <g data-name="Group 10963" clipPath="url(#c)" transform="translate(0 24)">
        <path
          data-name="Path 8805"
          d="M-.375-15.377h11.75v11.75H-.375Z"
          fill="#fff"
        />
      </g>
      <g data-name="Group 10964" clipPath="url(#d)" transform="translate(0 24)">
        <path data-name="Path 8807" d="M-5-29h30V5H-5Z" fill="#fff" />
      </g>
    </g>
  </svg>
);

export default DocumentIcon;
