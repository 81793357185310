import { compareAsc, isAfter } from 'date-fns';

import { CorporateAccount } from 'modules/accounts/accountsServices/CorporateAccountsService';

import {
  AccountsDashboardGroup,
  AccountsGroupBy,
  groupAccounts,
} from './groupAccounts';

const descSort = (
  aGroup: AccountsDashboardGroup,
  bGroup: AccountsDashboardGroup
) => bGroup.accounts.length - aGroup.accounts.length;

const accountDateSort = (
  aAccount: CorporateAccount,
  bAccount: CorporateAccount
) => compareAsc(new Date(aAccount.createdDate), new Date(bAccount.createdDate));

const getAccountsStats = (accounts: CorporateAccount[]) => {
  const now = new Date();
  const thisYear = new Date(now.getFullYear(), 0, 1);
  const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  const countryGroups = groupAccounts(AccountsGroupBy.Countries, accounts).sort(
    descSort
  );

  return {
    businessSectors: [
      {
        name: 'All Time',
        groups: groupAccounts(AccountsGroupBy.BusinessSector, accounts).sort(
          descSort
        ),
      },
      {
        name: 'This Year',
        groups: groupAccounts(
          AccountsGroupBy.BusinessSector,
          accounts.filter((account) =>
            isAfter(new Date(account.createdDate), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupAccounts(
          AccountsGroupBy.BusinessSector,
          accounts.filter((account) =>
            isAfter(new Date(account.createdDate), thisMonth)
          )
        ).sort(descSort),
      },
    ],
    countries: [
      {
        name: 'Top 5',
        groups: countryGroups.slice(0, 5),
      },
      {
        name: 'Top 10',
        groups: countryGroups.slice(0, 10),
      },
      {
        name: 'Top 20',
        groups: countryGroups.slice(0, 20),
      },
    ],
    createdDates: [
      {
        name: 'All Time',
        groups: groupAccounts(
          AccountsGroupBy.CreatedDateAllTime,
          [...accounts].sort(accountDateSort)
        ),
      },
      {
        name: 'This Year',
        groups: groupAccounts(
          AccountsGroupBy.CreatedDateYear,
          accounts
            .filter((account) =>
              isAfter(new Date(account.createdDate), thisYear)
            )
            .sort(accountDateSort)
        ),
      },
      {
        name: 'This Month',
        groups: groupAccounts(
          AccountsGroupBy.CreatedDateMonth,
          accounts
            .filter((account) =>
              isAfter(new Date(account.createdDate), thisMonth)
            )
            .sort(accountDateSort)
        ),
      },
    ],
  };
};

export type AccountsStatName = keyof ReturnType<typeof getAccountsStats>;

export default getAccountsStats;
