import React, { useState } from 'react';
import styled from 'styled-components';

import ParameterNavigation from 'components/ParameterNavigation';

import CorporateAccounts from '../components/corporateAccounts/CorporateAccounts';
import ReferrerAccounts from '../components/referrerAccounts/ReferrerAccounts';
import ReferrerAccountsContextProvider from '../context/ReferrerAccountsContext';
import { AccountCategory } from '../models';

const accountCategories = [AccountCategory.Corporate, AccountCategory.Referrer];

const Accounts = () => {
  const [activeAccountCategory, setActiveAccountCategory] =
    useState<AccountCategory>(AccountCategory.Corporate);

  const changeAccountCategory = (index: number) =>
    setActiveAccountCategory(accountCategories[index]);

  return (
    <ReferrerAccountsContextProvider>
      <Container>
        <ParameterNavigation
          withOffset
          parameters={accountCategories}
          setNewParameter={changeAccountCategory}
          activeParameter={activeAccountCategory}
        />
        {activeAccountCategory === AccountCategory.Corporate ? (
          <CorporateAccounts />
        ) : (
          <ReferrerAccounts />
        )}
      </Container>
    </ReferrerAccountsContextProvider>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Accounts;
