import React from 'react';

const DEFAULT_SIZE = 60;

type PlayIconProps = {
  size?: number;
};

const PlayIcon = ({ size }: PlayIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size || DEFAULT_SIZE}
    height={size || DEFAULT_SIZE}
    viewBox="0 0 60 60"
  >
    <defs>
      <clipPath id="play-clip-path">
        <path
          id="Path_4308"
          data-name="Path 4308"
          d="M30,36A30,30,0,1,0,0,6,30,30,0,0,0,30,36ZM42.03,7.25a2.347,2.347,0,0,1-1.467,2.06L25.35,16.915a2.963,2.963,0,0,1-1.327.333,2.3,2.3,0,0,1-1.22-.34,2.58,2.58,0,0,1-1.148-2.277V-.135A2.435,2.435,0,0,1,22.8-2.413,2.435,2.435,0,0,1,25.35-2.42L40.563,5.188A2.348,2.348,0,0,1,42.03,7.25Z"
          transform="translate(0 24)"
          fill="#62b0cb"
        />
      </clipPath>
    </defs>
    <g
      id="Group_10936"
      data-name="Group 10936"
      transform="translate(-653.132 -1055.418)"
    >
      <g
        id="Group_4518"
        data-name="Group 4518"
        transform="translate(653.132 1079.418)"
      >
        <g
          id="Group_4517"
          data-name="Group 4517"
          transform="translate(0 -24)"
          clipPath="url(#play-clip-path)"
        >
          <path
            id="Path_4307"
            data-name="Path 4307"
            d="M-5-29H80V56H-5Z"
            transform="translate(-7.5 16.5)"
            fill="#62b0cb"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PlayIcon;
