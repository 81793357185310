import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { TableRowGrid } from 'components/table/models';
import TableRow from 'components/table/tableRow/TableRow';

import { EditableDelegateAttributes } from '../models';

import DelegateRowDetailsModal from './DelegateRowDetailsModal';

type DelegateContainerRowProps = {
  item: EditableDelegateAttributes;
  openModalWindow: () => void;
};

const DelegateContainerRow = ({
  item,
  openModalWindow,
}: DelegateContainerRowProps) => {
  const [isRowModalOpen, setIsRowModalOpen] = useState(false);

  const toggleRowModal = useCallback(() => {
    setIsRowModalOpen(!isRowModalOpen);
  }, [isRowModalOpen]);

  return (
    <Container $isRowModalOpen={isRowModalOpen}>
      <TableRow
        special={item.role === 'Primary account'}
        key={item.id}
        tableName={TableRowGrid.DelegateAccounts}
        data={[`${item.firstName} ${item.lastName}`, item.job, item.role]}
        openModalWindow={toggleRowModal}
      />
      {isRowModalOpen && (
        <DelegateRowDetailsModal
          delegateAccountInfo={item}
          closeWindow={toggleRowModal}
          openEditingModalWindow={openModalWindow}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ $isRowModalOpen?: boolean }>`
  position: relative;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  font-size: 12px;
  overflow: hidden;

  &:nth-child(even) {
    .row:first-child {
      background-color: ${({ theme }) => theme.colors.lightYellow};
    }
  }

  ${({ $isRowModalOpen }) =>
    $isRowModalOpen &&
    css`
      height: 390px;
    `}
`;

export default React.memo(DelegateContainerRow);
