import { months } from 'components/calendar/calendarData';
import { CustomDate } from 'components/calendar/models';

const convertCustomDateToDateFormat = (date: CustomDate) => {
  const { year, day, month } = date;

  return new Date(Number(year), months.indexOf(month), Number(day));
};

export default convertCustomDateToDateFormat;
