import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';

import ActiveGraphDot from 'components/icons/ActiveGraphDot';
import GraphBackgroundIcon from 'components/icons/GraphBackgroundIcon';
import GraphDot from 'components/icons/GraphDot';
import { MonthAverage } from 'modules/insights/components/GraphCard';

type ChartLayoutProps = {
  months: MonthAverage[];
  color: string;
  dotColor: string;
};

const ChartLayout = ({ months, color, dotColor }: ChartLayoutProps) => {
  return (
    <ResponsiveContainer>
      <LineChart
        data={months}
        margin={{
          left: -61,
          bottom: -31,
          top: 2,
          right: -1,
        }}
      >
        <CartesianGrid />
        <ReferenceArea shape={<GraphBackgroundIcon />} />

        <XAxis
          axisLine={false}
          tick={false}
          tickLine={false}
          padding={{ left: 25, right: 25 }}
        />
        <YAxis axisLine={false} tickLine={false} tick={false} />
        <Tooltip />
        <Line
          dataKey="uniqueVisitors"
          stroke={color}
          dot={<GraphDot color={color} innerColor={dotColor} />}
          activeDot={<ActiveGraphDot color={color} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartLayout;
