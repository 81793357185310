import React, { RefObject } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import CloseIcon from 'components/icons/CloseIcon';
import checkFileExtension from 'helpers/checkFileExtension';

import { FileDropZoneContainer } from '../../styledElements/stagesStyledElements';

export type FileDropZoneProps = {
  text: string;
  isError?: boolean;
  isFirstChild?: boolean;
  validExtensions: string[];
  validExtensionsForDrop: string[];
  refObject: RefObject<HTMLInputElement>;
  setName: (name: string) => void;
  toggleDropZone: () => void;
  readFile: (file: File) => void;
};

const FileDropZone = React.forwardRef<HTMLInputElement, FileDropZoneProps>(
  (
    {
      isError,
      refObject,
      setName,
      isFirstChild,
      toggleDropZone,
      readFile,
      validExtensions,
      text,
      validExtensionsForDrop,
    },
    ref
  ) => {
    return (
      <FileDropZoneContainer
        data-testid="drop-zone"
        $isError={isError}
        $isFirstChild={isFirstChild}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();

          if (
            e.dataTransfer.files.length &&
            refObject.current &&
            checkFileExtension(
              e.dataTransfer.files[0].type,
              validExtensionsForDrop
            )
          ) {
            refObject.current.files = e.dataTransfer.files;
            setName(refObject.current.files[0].name);
            readFile(refObject.current.files[0]);
          }
        }}
      >
        <p>
          {text}&nbsp;
          <span onClick={() => refObject?.current?.click()}>browse</span>
          &nbsp;
        </p>
        <CloseButtonContainer>
          <ActionButton
            icon={
              <CloseIcon prefix="drop-zone" color={theme.colors.funtainBlue} />
            }
            clickHandler={toggleDropZone}
          />
        </CloseButtonContainer>
        <FileInput
          type="file"
          accept={validExtensions.map((file) => `.${file}`).join(',')}
          ref={ref}
          autoComplete="off"
          onChange={(e) => {
            if (refObject?.current?.files && refObject.current.files.length) {
              setName(refObject.current.files[0].name);
              readFile(refObject.current.files[0]);
            }
            e.target.value = '';
          }}
        />
      </FileDropZoneContainer>
    );
  }
);

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const FileInput = styled.input`
  display: none;
`;

// This displayName is needed here due to eslint error about forwardRef
FileDropZone.displayName = 'FileDropZone';

export default React.memo(FileDropZone);
