import React, { useEffect } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import styled from 'styled-components';

import { BadResultItemInfo } from 'modules/insights/models';
import SlideContent from 'modules/insights/modules/demographics/components/SlideContent';

import SlideSelector from './SlideSelector';

const AUTOPLAY_INTERVAL = 6000;

type Props = {
  slides: BadResultItemInfo[];
};

const DemographicsCarousel = ({ slides }: Props) => {
  const { scrollRef, activePageIndex, pages, snapPointIndexes, goTo } =
    useSnapCarousel();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activePageIndex === pages.length - 1) {
        goTo(0);
      } else {
        goTo(activePageIndex + 1);
      }
    }, AUTOPLAY_INTERVAL);

    return () => {
      clearTimeout(timeout);
    };
  }, [activePageIndex, pages, goTo]);

  return (
    <>
      <Scroll ref={scrollRef}>
        {slides.map((item, i) => (
          <SlideContent
            key={item.id}
            percent={item.percent}
            title={item.title}
            arrayDescription={item.arrayDescription}
            focusTitle={item.focusTitle}
            isSnapPoint={snapPointIndexes.has(i)}
          />
        ))}
      </Scroll>
      <SelectorContainer>
        <SlideSelector
          slides={slides}
          clickHandler={goTo}
          selectedIdx={activePageIndex}
          interval={AUTOPLAY_INTERVAL}
        />
      </SelectorContainer>
    </>
  );
};

const SelectorContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

const Scroll = styled.ul`
  position: relative;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  box-sizing: border-box;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default DemographicsCarousel;
