import styled, { css } from 'styled-components';

export const Title = styled.div<{ $isHidden?: boolean }>`
  font-size: 30px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4px;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  transition-duration: 0.4s;
`;

export const ListItem = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  margin-left: 0 !important;
  transform: scale(1) !important;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.dustyGray : theme.colors.primary};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightYellow};
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  * {
    margin-right: 10px;
  }
`;

export const DateText = styled.span<{
  $isStyled?: boolean;
  $disabled?: boolean;
}>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.funtainBlue};
  ${({ $isStyled }) =>
    $isStyled &&
    css`
      min-width: 70px;
      max-width: max-content;
      cursor: pointer;
      display: inline-block;
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.dustyGrayV2};
    `}
`;

export const FileName = styled.p`
  width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 30px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 250px;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 5px 8px rgb(0 0 0 / 20%);
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  transition: 0.2s;

  &:hover {
    box-shadow: 0 5px 8px rgb(0 0 0 / 30%);
    cursor: pointer;
  }
`;

export const FileDropZoneContainer = styled.div<{
  $isError?: boolean;
  $isFirstChild?: boolean;
}>`
  width: 103%;
  height: 133px;
  display: flex;
  align-items: center;
  position: relative;
  left: -10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px
    ${({ theme, $isError }) =>
      $isError
        ? `solid ${theme.colors.hibiscus}`
        : `dashed ${theme.colors.funtainBlue}`};
  ${({ $isFirstChild }) =>
    $isFirstChild &&
    css`
      margin-top: 26px;
    `}

  p {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 1px;
    text-align: center;
    width: 100%;

    span {
      cursor: pointer;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.funtainBlue};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (width <= 1250px) {
    width: 85%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
export const ActionModalMainContent = styled.div`
  width: 656px;
  margin: 85px auto 0;
  box-sizing: border-box;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 540px;
    padding-left: 40px;
  }
`;
