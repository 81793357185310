import { StatusBE } from 'components/detailsModal/models';
import countries from 'components/listForSelectingData/dataForList/countries';

import { AccountCategory, ReferrerAccount } from './models';

const referrerAccountsMockData: ReferrerAccount[] = [
  {
    firstName: 'Dan',
    lastName: 'McPeake',
    category: AccountCategory.Referrer,
    email: 'test@shido.com',
    country: {
      displayName: countries[0].displayName,
      abbr: countries[0].id,
    },
    mobile: {
      code: '+31',
      number: '7856542133',
      countryAbbreviation: 'NL',
    },
    status: StatusBE.Active,
    id: '1',
    temporaryStatus: undefined,
  },
  {
    firstName: 'Harry',
    lastName: 'Garfild',
    category: AccountCategory.Referrer,
    email: 'test@shido.com',
    country: {
      displayName: countries[0].displayName,
      abbr: countries[0].id,
    },
    mobile: {
      code: '+31',
      number: '7856542133',
      countryAbbreviation: 'NL',
    },
    status: StatusBE.Pending,
    id: '2',
    temporaryStatus: undefined,
  },
  {
    firstName: 'Anthony',
    lastName: 'Joshua',
    category: AccountCategory.Referrer,
    email: 'test@shido.com',
    country: {
      displayName: countries[1].displayName,
      abbr: countries[1].id,
    },
    mobile: {
      code: '+44',
      number: '7856542133',
      countryAbbreviation: 'UK',
    },
    status: StatusBE.Suspend,
    id: '4',
    temporaryStatus: undefined,
  },
  {
    firstName: 'Chris',
    lastName: 'Hemmingway',
    category: AccountCategory.Referrer,
    email: 'test@shido.com',
    country: {
      displayName: countries[0].displayName,
      abbr: countries[0].id,
    },
    mobile: {
      code: '+31',
      number: '7856542133',
      countryAbbreviation: 'NL',
    },
    status: StatusBE.Pending,
    id: '10',
    temporaryStatus: undefined,
  },
  {
    firstName: 'Caroline',
    lastName: 'Dampier',
    category: AccountCategory.Referrer,
    email: 'test@shido.com',
    country: {
      displayName: countries[0].displayName,
      abbr: countries[0].id,
    },
    mobile: {
      code: '+31',
      number: '7856542133',
      countryAbbreviation: 'NL',
    },
    status: StatusBE.Active,
    id: '11',
    temporaryStatus: undefined,
  },
  {
    firstName: 'Mary',
    lastName: 'Barra',
    category: AccountCategory.Referrer,
    email: 'test@shido.com',
    country: {
      displayName: countries[0].displayName,
      abbr: countries[0].id,
    },
    mobile: {
      code: '+31',
      number: '7856542133',
      countryAbbreviation: 'NL',
    },
    status: StatusBE.Active,
    id: '12',
    temporaryStatus: undefined,
  },
];

export default referrerAccountsMockData;
