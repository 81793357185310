import React from 'react';
import styled, { css } from 'styled-components';

type ButtonSize = 'md' | 'lg' | 'xl';
type BorderSize = 'md' | 'lg' | 'xl' | 'none';

const buttonWidth = {
  md: 160,
  lg: 158,
  xl: 300,
};

const buttonHeight = {
  md: 40,
  lg: 50,
  xl: 60,
};

const borderWidth = {
  md: 165,
  lg: 163,
  xl: 305,
};

const borderHeight = {
  md: 45.2,
  lg: 55.2,
  xl: 65,
};

export type DefaultButtonProps = {
  text: string;
  textColor: string;
  isActive: boolean;
  isSubmit?: boolean;
  withShadow: boolean;
  buttonColor: string;
  isUppercase?: boolean;
  buttonSize: ButtonSize;
  borderSize: BorderSize;
  clickHandler?: () => void;
};

const DefaultButton = ({
  text,
  isActive,
  isSubmit,
  textColor,
  withShadow,
  buttonSize,
  borderSize,
  buttonColor,
  clickHandler,
  isUppercase,
}: DefaultButtonProps) => (
  <Container
    $isActive={isActive}
    $withShadow={withShadow}
    $width={
      borderSize !== 'none' ? borderWidth[borderSize] : buttonWidth[buttonSize]
    }
    $height={
      borderSize !== 'none'
        ? borderHeight[borderSize]
        : buttonHeight[buttonSize]
    }
  >
    <Button
      $isActive={isActive}
      disabled={!isActive}
      $textColor={textColor}
      onClick={clickHandler}
      $withShadow={withShadow}
      $buttonColor={buttonColor}
      $isUppercase={isUppercase}
      $width={buttonWidth[buttonSize]}
      $height={buttonHeight[buttonSize]}
      type={isSubmit ? 'submit' : 'button'}
    >
      {text}
    </Button>
  </Container>
);

const Container = styled.div<{
  $width: number;
  $height: number;
  $isActive: boolean;
  $withShadow: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? 'rgba(255, 255, 255, 0.2)' : theme.colors.white};
  box-shadow: ${({ $isActive }) =>
    $isActive ? '0 5px 20px rgb(0 0 0 / 10%)' : 'none'};
  border-radius: 60px !important;
  text-decoration: none;
  ${({ $withShadow }) =>
    !$withShadow &&
    css`
      box-shadow: none;
    `}
  ${({ $isActive }) =>
    !$isActive &&
    css`
      opacity: 0.6;
    `}
`;

const Button = styled.button<{
  $width: number;
  $height: number;
  $isActive: boolean;
  $textColor: string;
  $buttonColor: string;
  $withShadow: boolean;
  $isUppercase?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width}px !important;
  height: ${({ $height }) => $height}px;
  border-radius: 60px;
  font-size: 14px;
  font-weight: 700;
  background-color: ${({ $buttonColor }) => $buttonColor};
  box-shadow: ${({ $isActive: isActive }) =>
    isActive ? '0 5px 5px rgb(0 0 0 / 10%)' : 'none'};
  text-decoration: none;
  color: ${({ $textColor, theme }) =>
    $textColor ? $textColor : theme.colors.funtainBlue};
  cursor: pointer;
  border: none;
  transition: all 0.5s;

  &:hover {
    box-shadow: ${({ $isActive }) =>
      $isActive ? '0 5px 10px rgb(0 0 0 / 20%)' : 'none'};
  }

  ${({ $withShadow }) =>
    !$withShadow &&
    css`
      box-shadow: none;
    `}
  ${({ $isUppercase }) =>
    $isUppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export default DefaultButton;
