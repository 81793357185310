import React, { useState, useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar from 'components/actionModal/Navbar';
import AccessCode, {
  accessCodeExpiresItems,
} from 'components/actionModal/stages/AccessCode';
import UserAccountDetails from 'components/actionModal/stages/accountDetails/UserAccountDetails';
import Result from 'components/actionModal/stages/Result';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import setInputError from 'helpers/setInputError';

import { UserAccountContext } from '../context/userAccountContext';
import useChangeAccessCode from '../hooks/useChangeAccessCode';
import useDeleteAccessCode from '../hooks/useDeleteAccessCode';
import useSaveDataForEditUser from '../hooks/useSaveDataForEditUser';
import {
  UserModalWindowStages,
  User,
  QueryKey,
  editUserErrorResponse,
  ASTERISKS,
} from '../models';
import {
  changeUserAccessCode,
  deleteUserAccessCode,
  editUserDetails,
  UserAccountAttributes,
} from '../usersServices/usersAccountsService';

const navbarItemsForCorporate = [
  {
    text: 'Account details',
    name: UserModalWindowStages.AccountDetails,
  },
  {
    text: 'Access code',
    name: UserModalWindowStages.AccessCode,
  },
];

const USER_UPDATED = 'User account updated!';
const ACCESS_CODE_UPDATED = 'Access code applied!';

type EditUserModalProps = {
  user: User;
  closeModalWindow: () => void;
};

const EditUserModal = ({ user, closeModalWindow }: EditUserModalProps) => {
  const queryClient = useQueryClient();
  const [result, setResult] = useState('');
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState({ input: '', message: '' });
  const [isCodeActive, setIsCodeActive] = useState(false);
  const [activatedStages, setActivatedStages] = useState([
    UserModalWindowStages.AccountDetails,
  ]);
  const [activeStage, setActiveStage] = useState<UserModalWindowStages>(
    UserModalWindowStages.AccountDetails
  );

  const [accessExpires, setAccessExpires] = useState('');
  const [activeMonth, setActiveMonth] = useState(accessCodeExpiresItems[0].id);
  const [isConfirm, setIsConfirm] = useState(false);

  const toggleConfirm = () => setIsConfirm((prev) => !prev);

  const { saveAccountDetailsData, user: userEditing } =
    useContext(UserAccountContext);

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(navbarItemsForCorporate[stage].name),
    []
  );

  const goToAccountAccessCodeStage = useCallback(
    () => setActiveStage(UserModalWindowStages.AccessCode),
    [setActiveStage]
  );

  const goToResultStage = useCallback(
    () => setActiveStage(UserModalWindowStages.Result),
    [setActiveStage]
  );

  const goToNextStep = useCallback(
    (skip?: boolean) => {
      if (skip) {
        goToAccountAccessCodeStage();
      } else {
        setResult(USER_UPDATED);
        goToResultStage();
      }
    },
    [goToAccountAccessCodeStage, goToResultStage]
  );

  const goToFinalStep = useCallback(
    (skip?: boolean) => {
      if (skip) {
        closeModalWindow();
      } else {
        setResult(ACCESS_CODE_UPDATED);
        goToResultStage();
      }
    },
    [closeModalWindow, goToResultStage]
  );

  const goToNextStage = useCallback(() => {
    if (result === USER_UPDATED) {
      goToAccountAccessCodeStage();
    } else if (result === ACCESS_CODE_UPDATED) {
      closeModalWindow();
    }
  }, [closeModalWindow, goToAccountAccessCodeStage, result]);

  useSaveDataForEditUser(
    user,
    setSaved,
    setIsCodeActive,
    saveAccountDetailsData,
    saved
  );

  const { isLoading: isEditUserDetailsLoading, mutate } = useMutation(
    editUserDetails,
    {
      onSuccess: (data) => {
        if (data.succeeded) {
          goToNextStep();
          disableAccessStage();
          queryClient.invalidateQueries(QueryKey.GetUsersAccounts);
        } else {
          setInputError({
            setError,
            errorMessage: data.message,
            errorResponse: editUserErrorResponse,
          });
        }
      },
    }
  );

  const clearCodeData = useCallback(() => {
    setIsCodeActive(false);
    saveAccountDetailsData({
      ...userEditing.accountDetails,
      term: undefined,
      createdOn: undefined,
    });
  }, [saveAccountDetailsData, userEditing.accountDetails]);

  const { isLoading: isEditAccessCodeLoading, mutate: editAccessCode } =
    useChangeAccessCode(goToFinalStep, changeUserAccessCode);

  const {
    isLoading: isDeleteUserAccessCodeLoading,
    mutate: removeUserAccessCode,
  } = useDeleteAccessCode(clearCodeData, deleteUserAccessCode);

  const sendEditUserAccessCodeRequest = useCallback(
    (term: string) =>
      editAccessCode({
        id: user.id,
        term,
      }),
    [editAccessCode, user.id]
  );

  const sendEditUserRequest = useCallback(
    (userAttr: UserAccountAttributes) => {
      setError({ input: '', message: '' });
      saveAccountDetailsData(userAttr);
      const { email, countryCode, firstName, phoneNumber, passcode } = userAttr;

      if (passcode === ASTERISKS) {
        mutate({
          data: { email, countryCode, firstName, phoneNumber },
          id: user.id,
        });
      } else {
        mutate({
          data: {
            email,
            countryCode,
            firstName,
            phoneNumber,
            passcode,
          },
          id: user.id,
        });
      }
    },
    [mutate, saveAccountDetailsData, user.id]
  );

  const sendRemoveUserAccessCodeRequest = useCallback(
    () =>
      removeUserAccessCode({
        id: user.id,
      }),
    [removeUserAccessCode, user.id]
  );

  const disableAccessStage = useCallback((value?: boolean) => {
    if (value) {
      setActivatedStages([UserModalWindowStages.AccountDetails]);
    } else {
      setActivatedStages([
        UserModalWindowStages.AccountDetails,
        UserModalWindowStages.AccessCode,
      ]);
    }
  }, []);

  const isLoadingStarted =
    isEditUserDetailsLoading ||
    isEditAccessCodeLoading ||
    isDeleteUserAccessCodeLoading;

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {isLoadingStarted || activeStage === UserModalWindowStages.Result ? (
        <Result
          title={isLoadingStarted ? '' : result}
          closeModal={goToNextStage}
        />
      ) : (
        <>
          <Navbar
            title="Manage user"
            showTitle={false}
            activeStage={activeStage}
            accountCategory=""
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
            navbarItems={navbarItemsForCorporate}
          />
          <ActionModalMainContent>
            {activeStage === UserModalWindowStages.AccountDetails && (
              <UserAccountDetails
                disableAccessStage={disableAccessStage}
                isEdit
                title="Account details"
                userDetails={userEditing.accountDetails}
                errorResponse={error}
                saveAccountDetailsData={saveAccountDetailsData}
                countryAbbreviation={userEditing.countryAbbreviation}
                goToNextStage={goToNextStep}
                sendRequest={sendEditUserRequest}
              />
            )}
            {activeStage === UserModalWindowStages.AccessCode && (
              <AccessCode
                isEdit
                setActiveMonth={setActiveMonth}
                setAccessExpires={setAccessExpires}
                activeMonth={activeMonth}
                accessExpires={accessExpires}
                isConfirm={isConfirm}
                accessPeriod={userEditing.accountDetails.term}
                startDatePeriod={userEditing.accountDetails.createdOn}
                isCodeActive={isCodeActive}
                title="Access code"
                goToNextStage={goToFinalStep}
                sendAccessCode={sendEditUserAccessCodeRequest}
                setIsCodeActive={setIsCodeActive}
                removeAccessCode={sendRemoveUserAccessCodeRequest}
                toggleConfirm={toggleConfirm}
              />
            )}
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default EditUserModal;
