import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import { User, UserTableItem } from 'modules/users/models';

export type UserAccountAttributes = {
  firstName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  passcode: string;
  createdOn?: Date;
  term?: string;
};

export type UsersResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  items: UserTableItem[];
};

export type PageInfo = {
  pageNumber: number;
  pageSize: number;
  searchQuery: string;
  orderByProperty: string;
  orderingOption: string;
};

type UsersPayload = {
  data: PageInfo;
};

export const getUsers = ({ data }: UsersPayload): Promise<UsersResponse> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/users/query`, data)
    .then((response) => response.data.payload.users);

export type UserDetailsPayload = {
  id: string;
};

export const getUserDetails = async ({
  id,
}: UserDetailsPayload): Promise<User> => {
  const userData = await axiosClient
    .get(`/api/v${config.apiVersion}/Users/${id}`)
    .then((response) => response.data.payload.user);

  const accessCode = await getUserAccessCode({ id });

  if (!accessCode) {
    return userData;
  }

  return {
    ...userData,
    term: accessCode.term,
    createdOn: accessCode.createdOn,
  };
};

type UserDetailsDataEditPayload = {
  email: string;
  countryCode: string;
  firstName: string;
  phoneNumber: string;
  passcode?: string;
};

type UserDetailsEditPayload = {
  data: UserDetailsDataEditPayload;
  id: string;
};

export type UserResponse = {
  succeeded: boolean;
  message: string;
};

export const editUserDetails = ({
  data,
  id,
}: UserDetailsEditPayload): Promise<UserResponse> =>
  axiosClient
    .put(`/api/v${config.apiVersion}/Users/${id}`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      return error.response.data.payload;
    });

export type deleteUserPayload = {
  userAccountId: string;
};

export const deleteUserRequest = ({ userAccountId }: deleteUserPayload) =>
  axiosClient.delete(`/api/v${config.apiVersion}/Users/${userAccountId}`);

export type UserDetailsEditAccessCodePayload = {
  term: string;
  id: string;
};

export const changeUserAccessCode = ({
  term,
  id,
}: UserDetailsEditAccessCodePayload): Promise<UserResponse> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/UserAccessCodes/${id}`, { term })
    .then((response) => response.data.payload)
    .catch((error) => {
      return error.response.data.payload;
    });

export const deleteUserAccessCode = ({
  id,
}: UserDetailsPayload): Promise<UserResponse> =>
  axiosClient
    .delete(`/api/v${config.apiVersion}/UserAccessCodes/${id}`)
    .then((response) => response.data.payload)
    .catch((error) => {
      return error.response.data.payload;
    });

type UserAccessCodeResponse = {
  term: string;
  createdOn: Date;
};

export const getUserAccessCode = ({
  id,
}: UserDetailsPayload): Promise<UserAccessCodeResponse> =>
  axiosClient
    .get(`/api/v${config.apiVersion}/UserAccessCodes/${id}`)
    .then((response) => response.data.payload.data)
    .catch(() => false);

type UserDetailsCreatePayload = {
  data: UserDetailsDataEditPayload;
};

export const createUserDetails = ({
  data,
}: UserDetailsCreatePayload): Promise<UserResponse & { userId: string }> =>
  axiosClient
    .post(`/api/v${config.apiVersion}/Users`, data)
    .then((response) => response.data.payload)
    .catch((error) => {
      return error.response.data.payload;
    });

export const disableUserRequest = ({ userAccountId }: deleteUserPayload) =>
  axiosClient.delete(
    `/api/v${config.apiVersion}/Users/${userAccountId}/disable`
  );
