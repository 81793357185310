import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';
import EditIcon from 'components/icons/EditIcon';

import { Action } from './styledElements/detailsModalStyledElements';

type HeaderProps = {
  name: string;
  closeModal: () => void;
  openEditingModalWindow: () => void;
};

const Header = ({ name, closeModal, openEditingModalWindow }: HeaderProps) => (
  <Container>
    <Name>{name}</Name>
    <Action data-testid="header-actions">
      <ActionButton
        icon={<EditIcon width={16} height={16} color={theme.colors.white} />}
        clickHandler={openEditingModalWindow}
      />
      <VerticalLine $color="rgba(255, 255, 255, 0.2)" />
      <ActionButton
        icon={<CloseIcon color={theme.colors.white} prefix="modal" />}
        clickHandler={closeModal}
      />
    </Action>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.h2`
  font-size: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  width: 80%;
  overflow-wrap: break-word;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export default React.memo(Header);
