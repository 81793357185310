import React, { createContext, ReactNode, useCallback, useState } from 'react';

import countries from 'components/listForSelectingData/dataForList/countries';

import {
  CorporateAccountDetailsData,
  CorporateAccountPrimaryContactData,
} from '../accountsServices/CorporateAccountsService';

type CorporateAccountContextType = {
  account: {
    accountDetails: CorporateAccountDetailsData;
    primaryContact: CorporateAccountPrimaryContactData;
    countryAbbreviation: string;
  };
  saveData: (
    accountDetails: CorporateAccountDetailsData,
    primaryContact: CorporateAccountPrimaryContactData,
    countryAbbreviation: string
  ) => void;
  saveAccountDetailsData: (accountDetails: CorporateAccountDetailsData) => void;
  savePrimaryContactData: (
    primaryContactData: CorporateAccountPrimaryContactData
  ) => void;
};

const initialValue: CorporateAccountContextType = {
  account: {
    accountDetails: {
      companyName: '',
      website: '',
      country: '',
      businessSector: '',
    },
    primaryContact: {
      email: '',
      lastName: '',
      mobileNumber: '',
      role: '',
      mobileNumberCode: countries[0].code,
      firstName: '',
    },
    countryAbbreviation: '',
  },
  saveData: () => null,
  saveAccountDetailsData: () => null,
  savePrimaryContactData: () => null,
};

export const CorporateAccountContext =
  createContext<CorporateAccountContextType>(initialValue);

const CorporateAccountContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [account, setAccount] = useState(initialValue.account);

  const saveAccountDetailsData = useCallback(
    (accountDetails: CorporateAccountDetailsData) => {
      setAccount({ ...account, accountDetails });
    },
    [account]
  );

  const savePrimaryContactData = useCallback(
    (
      primaryContact: CorporateAccountPrimaryContactData,
      countryAbbreviation?: string
    ) =>
      setAccount({
        ...account,
        primaryContact,
        countryAbbreviation: countryAbbreviation || account.countryAbbreviation,
      }),
    [account]
  );

  const saveData = useCallback(
    (
      accountDetails: CorporateAccountDetailsData,
      primaryContact: CorporateAccountPrimaryContactData,
      countryAbbreviation: string
    ) => setAccount({ accountDetails, primaryContact, countryAbbreviation }),
    []
  );

  return (
    <CorporateAccountContext.Provider
      value={{
        account,
        saveData,
        saveAccountDetailsData,
        savePrimaryContactData,
      }}
    >
      {children}
    </CorporateAccountContext.Provider>
  );
};

export default CorporateAccountContextProvider;
