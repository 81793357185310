import React, { createContext, ReactNode, useCallback, useState } from 'react';

import {
  PromoTerm,
  PromotionOfferData,
  PromotionOfferPageAttributes,
  PromotionSettingsPageAttributes,
} from '../models';

type PromotionOfferContextType = {
  imageFile?: File;
  imageName: string;
  image: string;
  offerDraft: {
    settings: PromotionOfferData;
    promotionAccountId: string;
    status: string;
  };
  promotionTerms: PromoTerm[];
  publicCode: string;
  prevData?: PromotionOfferPageAttributes;
  qrCodeUrl: string;
  setQrCodeUrl: (qrCode: string) => void;
  setImageFile: (value?: File) => void;
  setImageName: (name: string) => void;
  setImage: (image: string) => void;
  saveAccount: (id: string) => void;
  saveSettings: (settings: PromotionSettingsPageAttributes) => void;
  savePromotionTerm: (array: PromoTerm[]) => void;
  setPrevData: (value: PromotionOfferPageAttributes) => void;
  saveOfferSettings: (value: PromotionOfferPageAttributes) => void;
  setPublicCode: (code: string) => void;
  saveAllOfferData: (data: {
    settings: PromotionOfferData;
    promotionAccountId: string;
    status: string;
  }) => void;
};

const initialValue: PromotionOfferContextType = {
  offerDraft: {
    settings: {
      offerStage: {
        promotionTermId: '',
        title: '',
        subtitle: '',
        bodyText: '',
      },
      settingsStage: {
        backgroundColour: 'Sand',
        notes: '',
      },
    },
    status: '',
    promotionAccountId: '',
  },
  promotionTerms: [],
  publicCode: '',
  imageName: '',
  image: '',
  qrCodeUrl: '',
  setQrCodeUrl: () => null,
  setImageName: () => null,
  setImage: () => null,
  setImageFile: () => null,
  saveAccount: () => null,
  saveSettings: () => null,
  savePromotionTerm: () => null,
  setPrevData: () => null,
  saveOfferSettings: () => null,
  setPublicCode: () => null,
  saveAllOfferData: () => null,
};

export const PromotionOfferContext =
  createContext<PromotionOfferContextType>(initialValue);

const PromotionOfferContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [offerDraft, setOfferDraft] = useState(initialValue.offerDraft);
  const [imageName, setImageName] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState<File>();
  const [promotionTerms, savePromotionTerms] = useState<PromoTerm[]>([]);
  const [prevData, setPrevData] = useState<PromotionOfferPageAttributes>();
  const [publicCode, setPublicCode] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const saveAccount = useCallback(
    (id: string) => setOfferDraft({ ...offerDraft, promotionAccountId: id }),
    [offerDraft]
  );

  const saveAllOfferData = useCallback(
    (data: {
      settings: PromotionOfferData;
      promotionAccountId: string;
      status: string;
    }) => {
      setOfferDraft(data);
    },
    []
  );

  const saveOfferSettings = useCallback(
    (settings: PromotionOfferPageAttributes) => {
      setOfferDraft({
        ...offerDraft,
        settings: {
          ...offerDraft.settings,
          offerStage: {
            ...settings,
          },
        },
      });
    },
    [offerDraft]
  );

  const saveSettings = useCallback(
    (settings: PromotionSettingsPageAttributes) => {
      setOfferDraft({
        ...offerDraft,
        settings: {
          ...offerDraft.settings,
          settingsStage: {
            ...settings,
          },
        },
      });
    },
    [offerDraft]
  );

  const savePromotionTerm = useCallback(
    (array: PromoTerm[]) => {
      savePromotionTerms(array);
      setOfferDraft({
        ...offerDraft,
        settings: !offerDraft.settings.offerStage.promotionTermId
          ? {
              ...offerDraft.settings,
              offerStage: {
                ...offerDraft.settings.offerStage,
                promotionTermId: array[0].BEformat,
              },
            }
          : { ...offerDraft.settings },
      });
    },
    [offerDraft]
  );

  return (
    <PromotionOfferContext.Provider
      value={{
        imageFile,
        image,
        imageName,
        offerDraft,
        promotionTerms,
        prevData,
        publicCode,
        qrCodeUrl,
        setQrCodeUrl,
        saveAllOfferData,
        saveOfferSettings,
        setPrevData,
        savePromotionTerm,
        saveAccount,
        saveSettings,
        setImageName,
        setImage,
        setImageFile,
        setPublicCode,
      }}
    >
      {children}
    </PromotionOfferContext.Provider>
  );
};

export default PromotionOfferContextProvider;
