import React from 'react';
import styled, { css } from 'styled-components';

import GraphCircle from 'components/circles/GraphCircle';
import { ProblemDescription } from 'modules/insights/models';

type Props = {
  focusTitle?: string;
  percent: number;
  title: string;
  arrayDescription?: ProblemDescription[];
  isSnapPoint?: boolean;
};

const SlideContent = ({
  focusTitle,
  percent,
  title,
  arrayDescription,
  isSnapPoint,
}: Props) => (
  <Container $isSnapPoint={isSnapPoint}>
    <div>
      <GraphCircle noColor isSectionVisible title={title} percent={percent} />
    </div>
    <InfoContainer>
      <UpperTitle>FOCUS AREAS</UpperTitle>
      <MainTitle>{focusTitle}</MainTitle>
      <DashedLine />
      {arrayDescription &&
        arrayDescription.map(({ title, description }) => {
          return (
            <ItemWrapper key={description}>
              <ItemTitle>{title}</ItemTitle>
              <ItemDescription>{description}</ItemDescription>
            </ItemWrapper>
          );
        })}
    </InfoContainer>
  </Container>
);

const InfoContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 40px;
  max-width: 848px;
`;

const DashedLine = styled.div`
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 69%, white 71%);
  background-size: 7px 86%;
  opacity: 0.4;
  margin-bottom: 38px;
`;

const UpperTitle = styled.div`
  font-size: 16px;
  margin-top: 28px;
`;
const MainTitle = styled.div`
  line-height: 52px;
  font-size: 36px;
  margin: 5px 0 16px 20px;
`;
const ItemWrapper = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`;
const ItemDescription = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-top: 8px;
  line-height: 21px;
`;
const ItemTitle = styled.div`
  font-size: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(-30px, -50%);
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
  }
`;

const Container = styled.div<{ $isSnapPoint?: boolean }>`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  ${({ $isSnapPoint }) =>
    $isSnapPoint &&
    css`
      scroll-snap-align: start;
    `}
`;

export default SlideContent;
