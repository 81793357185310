import { useEffect } from 'react';

import { CLOSE_DETAILS_MODAL_TIMER } from 'components/detailsModal/models';

const useSetRightStageAfterRequest = (
  setRightStage: boolean,
  goToNextStage: () => void
) => {
  useEffect(() => {
    if (setRightStage) {
      const nextStage = setTimeout(() => {
        goToNextStage();
      }, CLOSE_DETAILS_MODAL_TIMER);

      return () => clearTimeout(nextStage);
    }
  }, [goToNextStage, setRightStage]);
};

export default useSetRightStageAfterRequest;
