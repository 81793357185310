import { useMutation } from 'react-query';

import { REMOVE_ERROR_TIMER } from 'components/actionModal/models';

import { OfferModalWindowStages } from '../models';
import { createCorporateOfferDraft } from '../offersServices/CorporateOffersService';

const useCreateDraft = (
  setError: (value: boolean) => void,
  setStage: (stage: OfferModalWindowStages) => void,
  setIsDraftCleaned: (value: boolean) => void
) =>
  useMutation(createCorporateOfferDraft, {
    onError: () => {
      setError(true);
      setTimeout(() => setError(false), REMOVE_ERROR_TIMER);
    },
    onSuccess: () => {
      setStage(OfferModalWindowStages.OfferPage);
      setIsDraftCleaned(false);
    },
  });

export default useCreateDraft;
