/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import theme from 'theme';

import { ErrorMessage } from '@hookform/error-message';
import WarningIcon from 'components/icons/WarningIcon';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import { FormLabel } from '../../styledElements/formsStyledElements';

type TextAreaProps = {
  maxRows: number;
  isRequired?: boolean;
  doNotTriggerValidation?: boolean;
  errorTextResponse?: string;
  label: string;
  inputId: string;
  errors: FieldErrors;
  inputValue?: string;
  cutFirstWord?: boolean;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  saveData?: () => void;
  submit: () => void;
};

const TextAreaField = ({
  isRequired = true,
  maxRows,
  doNotTriggerValidation,
  errors,
  label,
  inputId,
  inputValue,
  cutFirstWord,
  submit,
  register,
  setValue,
  saveData,
}: TextAreaProps) => {
  const [isInputActive, setIsInputActive] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    setValue(name, /^\s/.test(value) ? '' : value, { shouldValidate: true });
    saveData && saveData();
  };

  useLayoutEffect(() => {
    if (inputValue) {
      setIsInputActive(true);
      // in case if there is an error from the server, we should turn off shouldValidate
      // property to prevent from rewriting error
      setValue(
        inputId,
        inputValue,
        doNotTriggerValidation ? {} : { shouldValidate: true }
      );
    }
  }, [doNotTriggerValidation, inputId, inputValue, setValue]);

  const labelView = useMemo(() => {
    if (cutFirstWord && isInputActive) {
      return label.substring(label.indexOf(' ') + 1);
    }

    return label;
  }, [cutFirstWord, isInputActive, label]);

  const onSubmitAction = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();

        return;
      }
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        submit();
      }
    },
    [submit]
  );

  return (
    <Container>
      <StyledInput
        $isActive={!!(isInputActive || inputValue)}
        $error={!!errors[inputId]}
      >
        <StyledLabel
          $isActive={!!(isInputActive || inputValue)}
          htmlFor={inputId}
          $error={!!errors[inputId]}
          $active={!!(isInputActive || inputValue)}
        >
          {labelView}
        </StyledLabel>
        <TextareaAutosize
          {...register(inputId, {
            required: isRequired,
          })}
          id={inputId}
          maxRows={maxRows}
          onBlur={saveData}
          onKeyDown={onSubmitAction}
          onFocus={() => setIsInputActive(true)}
          onChange={changeInputHandler}
        />
      </StyledInput>
      <ErrorMessage
        name={inputId}
        errors={errors}
        render={({ message }) => (
          <StyledErrorText $error={!!errors[inputId]}>
            <WarningIcon color={theme.colors.primary} />
            {message}
          </StyledErrorText>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledLabel = styled(FormLabel)<{ $isActive?: boolean }>`
  top: ${({ $isActive }) => ($isActive ? '-1px' : '19px')};
`;

const StyledErrorText = styled(ErrorText)`
  position: absolute;
  bottom: -20px;
`;

const StyledInput = styled(InputContainer)<{
  $isActive?: boolean;
  $error?: boolean;
}>`
  height: fit-content;
  margin-top: 38px;

  textarea {
    box-sizing: border-box;
    max-height: ${({ $isActive }) => ($isActive ? 'auto' : '25px')};
    margin-top: 17px;
    margin-bottom: 3px;
    resize: none;
    min-height: 25px;
    width: 100%;
    font-size: 14px;
    outline: none;
    border: none;
    font-weight: 400;
    border-radius: 5px;
    background: none;
    line-height: 21px;
    position: relative;
    right: 3px;
    color: ${({ $error, theme }) =>
      $error ? theme.colors.lightRedV1 : theme.colors.funtainBlue};
    ${({ $disabled: disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.colors.darkBlue};
      `}

    &::-webkit-inner-spin-button {
      display: none;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default TextAreaField;
