import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { AffiliateOfferContext } from 'modules/offers/context/AffiliateOfferContext';

import { Device } from '../models';
import PreviewHeader from '../PreviewHeader';
import { colorPicker } from '../previewPagePromotion/PreviewPagePromotion';

import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';
import TabletPreview from './TabletPreview';

type PreviewPagePromotionProps = {
  chosenDevice: Device;
  closePreview: () => void;
};

const PreviewPageAffiliate = ({
  chosenDevice,
  closePreview,
}: PreviewPagePromotionProps) => {
  const [activeDevice, setActiveDevice] = useState(chosenDevice);
  const {
    offerDraft: {
      settings: {
        offerStage: {
          affiliateName,
          affiliateHandle,
          bodyText,
          affiliateStatement,
          intro,
        },
        settingsStage: { backgroundColour, conversionFunnel },
      },
    },
    image,
  } = useContext(AffiliateOfferContext);

  const changeActiveDeice = useCallback(
    (device: Device) => setActiveDevice(device),
    []
  );

  const promotionProps = {
    affiliateName,
    affiliateHandle,
    bodyText,
    chosenColor: colorPicker[backgroundColour],
    image,
    affiliateStatement,
    intro,
    conversionFunnel,
  };

  return (
    <Container>
      <PreviewHeader
        title="offer"
        activeDevice={activeDevice}
        closePreview={closePreview}
        setActiveDevice={changeActiveDeice}
      />
      {activeDevice === Device.Desktop && (
        <WrapperDesktop>
          <ContainerDesktop
            $color={promotionProps.chosenColor.strong}
            data-testid="desktop-preview-container"
          >
            <DesktopPreview {...promotionProps} />
          </ContainerDesktop>
        </WrapperDesktop>
      )}
      {activeDevice === Device.Tablet && (
        <WrapperDesktop>
          <ContainerTablet
            $color={promotionProps.chosenColor.strong}
            data-testid="tablet-preview-container"
          >
            <TabletPreview {...promotionProps} />
          </ContainerTablet>
        </WrapperDesktop>
      )}
      {activeDevice === Device.Mobile && <MobilePreview {...promotionProps} />}
    </Container>
  );
};

const WrapperDesktop = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const ContainerDesktop = styled.div<{ $color: string }>`
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  background-color: ${({ $color }) => $color};
`;

const ContainerTablet = styled.div<{ $color: string }>`
  max-width: 768px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  background-color: ${({ $color }) => $color};
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  min-height: 100vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
`;

export default PreviewPageAffiliate;
