import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import TableLoader from 'components/table/TableLoader';
import { ContentInfo } from 'modules/auth/models';
import useContentful from 'modules/content/hooks/useContentful';
import {
  ContentQueryKey,
  ContentType,
  EditableTopicType,
  TWENTY_EIGHT_DAYS,
  TopicType,
} from 'modules/content/models';

import GetStarted from '../GetStarted';

import ChooseModulesModal from './ChooseModulesModal';
import ModuleTable from './ModuleTable';

type Props = {
  isModalOpen: boolean;
  appliedDate: number | undefined;
  diffDays: number | undefined;
  toggleModal: () => void;
};

const Modules = ({
  isModalOpen,
  appliedDate,
  diffDays,
  toggleModal,
}: Props) => {
  const [topics, setTopics] = useState<EditableTopicType[]>([]);
  const [tableTopics, setTableTopics] = useState<TopicType[]>([]);

  const { getCategories } = useContentful();

  const chosenIdTopics = useMemo(() => {
    if (isModalOpen) return;

    return JSON.parse(
      localStorage.getItem(ContentInfo.TopicIds) as string
    ) as string[];
  }, [isModalOpen]);

  const [isFirstTime, setIsFirstTime] = useState(!chosenIdTopics);

  const { isLoading } = useQuery(ContentQueryKey.GetTopics, getCategories, {
    onSuccess: (data) => {
      const previousTopics = data.items.map((topic) => ({
        id: topic.sys.id.toString(),
        title: topic.fields.title,
        imgUrl: topic.fields.coverImage.fields.file.url,
        numberOfItems: topic.fields.numberOfContent,
        appliedDate,
        isChosen: false,
      }));

      if (chosenIdTopics) {
        setIsFirstTime(false);
        const chosenTopics = previousTopics.filter((topic) =>
          chosenIdTopics.includes(topic.id)
        );

        setTableTopics(chosenTopics);
      } else {
        setIsFirstTime(true);
      }

      setTopics(previousTopics);
    },
  });

  const renderContent = () => {
    if (isLoading) {
      return <TableLoader />;
    }

    if (isFirstTime) {
      return (
        <GetStarted
          toggleModal={toggleModal}
          contentType={ContentType.Modules}
        />
      );
    }

    return (
      <ModuleTable
        data={tableTopics}
        isShowButtons={!!diffDays && diffDays > TWENTY_EIGHT_DAYS}
      />
    );
  };

  return (
    <>
      <ContentInformation>
        {isLoading ? '' : `${tableTopics.length} modules`}
      </ContentInformation>
      {renderContent()}
      {isModalOpen && (
        <ChooseModulesModal topics={topics} closeModalWindow={toggleModal} />
      )}
    </>
  );
};

const ContentInformation = styled.div`
  padding: 0 19px 18px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export default Modules;
