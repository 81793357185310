import styled, { css } from 'styled-components';

import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

export const FormContainer = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;

  @media (height <= 769px) {
    margin-top: 0;
  }
`;

export const FormLabel = styled.label<{
  $active: boolean;
  $error?: boolean;
  $isCursorPointer?: boolean;
}>`
  &::first-letter {
    text-transform: uppercase;
  }

  color: ${({ theme, $active }) =>
    !$active ? theme.colors.funtainBlue : theme.colors.darkBlue};
  font-size: ${({ $active }) => ($active ? '11px' : '14px')};
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  transition:
    all 0.5s,
    color 0s;
  z-index: 5;
  top: ${({ $active }) => ($active ? '2px' : '22px')};
  cursor: ${({ $active }) => ($active ? 'default' : 'text')};
  ${({ $error: error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.lightRedV1};
    `}

  ${({ $isCursorPointer }) =>
    $isCursorPointer &&
    css`
      cursor: pointer;
    `}
`;

export const FormInput = styled.input<{
  $error?: boolean;
  $disabled?: boolean;
}>`
  width: 100%;
  height: 100%;
  font-size: 14px;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  background: none;
  padding: 6px 0 0;
  color: ${({ $error, theme }) =>
    $error ? theme.colors.lightRedV1 : theme.colors.funtainBlue};
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.darkBlue};
    `}

  &::-webkit-inner-spin-button {
    display: none;
  }
`;

export const MobileNumberInput = styled.input<{ $error?: boolean }>`
  width: 100%;
  height: 50%;
  font-size: 14px;
  outline: none;
  border: none;
  margin-left: 10px;
  font-weight: 400;
  border-radius: 5px;
  background: none;
  padding: 5px 0 7px;
  color: ${({ $error, theme }) =>
    $error ? theme.colors.lightRedV1 : theme.colors.funtainBlue};

  &::-webkit-inner-spin-button {
    display: none;
  }
`;

export const DropdownInput = styled.div<{ $isCursorDefault?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-top: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.funtainBlue};
  ${({ $isCursorDefault }) =>
    $isCursorDefault &&
    css`
      cursor: default;
    `}
`;

export const SubmitButtonContainer = styled.div`
  position: absolute;
  left: -1px;
  bottom: 20px;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  column-gap: 20px;
  margin-bottom: 19px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: 39px;
  }

  @media (height <= 769px) {
    bottom: 0;
  }
`;

const BUTTON_VERTICAL_RANGE = 7;
const BUTTON_HORIZONTAL_RANGE = 7;

export const DropdownAndInputContent = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }

  button {
    margin-left: 3px;
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }
`;

export const DropdownAndInputText = styled.p<{ $color?: string }>`
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
  min-width: max-content;
  color: ${({ theme, $color }) => ($color ? $color : theme.colors.primary)};
`;

export const DropdownAndInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 12px;
  cursor: text;
  margin-bottom: 8px;
  box-sizing: border-box;

  div:nth-child(2) {
    transform: scale(0.8);
    margin-left: 10px;
  }
`;

export const CountryItem = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;

export const ActiveListParameter = styled.p`
  padding-top: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.funtainBlue};

  span:first-child {
    position: relative;
    margin-right: 13px;
  }

  span:first-child::after {
    content: '-';
    position: absolute;
    right: -9.5px;
    top: 50%;
    cursor: default;
    pointer-events: none;
    transform: translate(0, -50%);
  }
`;

export const OfferCodeForm = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 16.3px;
  height: 46px;
  width: 100%;

  input {
    padding-top: 7px;
    height: 105%;
    text-align: center;
  }
`;

export const MultipleInputsContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;
`;

export const ModalStage = styled.div<{ $isShowStage: boolean }>`
  display: ${({ $isShowStage }) => ($isShowStage ? 'block' : 'none')};
`;
