import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';

import ShidoLogoWithTextIcon from 'components/icons/ShidoLogoWithTextIcon';

import AdminEmailStage from '../components/stages/adminLoginStages/AdminEmailStage';
import AdminVerifyIdentity from '../components/stages/adminLoginStages/AdminVerifyIdentity';
import Success from '../components/stages/adminLoginStages/Success';
import {
  BackgroundImage,
  Container,
  LogoWrapper,
  SignInModal,
} from '../components/styledElements/pagesStyledElements';
import { AuthStage, SuccessAuth } from '../models';

const AdminLogin = () => {
  const [adminLoginStage, setAdminLoginStage] = useState(AuthStage.SignIn);
  const [lastTwoDigitsOnMobile, setLastTwoDigitsOnMobile] = useState('');
  const [email, setEmail] = useState('');

  const goToVerifyIdentityStage = useCallback(
    (email: string, lastTwoDigitsOnMobile: string) => {
      setEmail(email);
      setAdminLoginStage(AuthStage.Verify);
      setLastTwoDigitsOnMobile(lastTwoDigitsOnMobile);
    },
    []
  );

  const goToSuccessStage = useCallback(
    () => setAdminLoginStage(AuthStage.Success),
    []
  );

  return (
    <Container>
      <Helmet>
        <meta
          name="description"
          content="Admin panel for managing offers, users and accounts"
        />
        <title>Admin portal</title>
      </Helmet>
      {adminLoginStage !== AuthStage.Success && (
        <>
          <LogoWrapper>
            <ShidoLogoWithTextIcon />
          </LogoWrapper>
          <SignInModal>
            {adminLoginStage === AuthStage.SignIn && (
              <AdminEmailStage goToNextStage={goToVerifyIdentityStage} />
            )}
            {adminLoginStage === AuthStage.Verify && (
              <AdminVerifyIdentity
                email={email}
                goToNextStage={goToSuccessStage}
                lastTwoDigitsOnMobile={lastTwoDigitsOnMobile}
              />
            )}
          </SignInModal>
        </>
      )}
      {adminLoginStage === AuthStage.Success && (
        <Success successType={SuccessAuth.AdminLogin} />
      )}
      <BackgroundImage src="/images/background.svg" />
    </Container>
  );
};

export default AdminLogin;
