/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import WarningIcon from 'components/icons/WarningIcon';
import codeFormKeyDownHandler from 'helpers/codeFormKeyDownHandler';
import patterns from 'helpers/patterns';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import {
  FormInput,
  FormLabel,
  OfferCodeForm,
} from '../../styledElements/formsStyledElements';

type OfferCodeInputProps = {
  error: boolean;
  fields: string[];
  inputValue: string;
  isOfferCodeUnique: boolean;
  disabled?: boolean;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  setFocus: UseFormSetFocus<any>;
  saveData: () => void;
};

const OfferCodeInput = ({
  error,
  register,
  setValue,
  fields,
  watch,
  setFocus,
  saveData,
  isOfferCodeUnique,
  inputValue,
  disabled,
}: OfferCodeInputProps) => {
  const [isOfferCodeActive, setIsOfferCodeNameActive] = useState(false);
  const [firstRenderDone, setFirstRenderDone] = useState(false);
  const [isOfferCodeSaved, setIsOfferCodeSaved] = useState(false);
  const [emptyInputs, setEmtpyInputs] = useState(0);
  const [isCursorMovementPrevented, setCursorMovementPrevented] =
    useState(false);

  const changeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { value, name } = e.target;

    setCursorMovementPrevented(true);
    // index can be 0
    // 'if' handles input with broken letters
    if (index !== undefined) {
      if (!value || value === ' ') {
        let replaceLetter: boolean;

        fields.forEach((field, index) => {
          // find name of the empty input and replace deleted letter with the next one letter of the code
          if (field === name) {
            setValue(field, inputValue[index + 1], { shouldValidate: true });
            replaceLetter = true;

            saveData();
            if (index !== 0) {
              setFocus(fields[index - 1]);
            }

            return;
          }

          // after replacing upstairs, we have to raplace the same way others letter
          if (replaceLetter) {
            setValue(field, inputValue[index + 1], { shouldValidate: true });
            saveData();

            return;
          }
          setValue(field, inputValue[index], { shouldValidate: true });
          saveData();
        });

        return;
      }

      let matchingValue = value.match(patterns.offerCodePattern);

      // if previous input is empty user can't type, but he can type if it's the first letter
      if (!watch()[(Number(name) - 1).toString()] && name !== '1') {
        matchingValue = null;
      }

      setValue(name, matchingValue ? value.toUpperCase() : '', {
        shouldValidate: true,
      });

      // if there is next input, after entering letter focus will be changed automatically to the text input
      if (matchingValue && index !== fields.length - 1) {
        setFocus(fields[index + 1]);
      }

      // if user entered the full code, we are calling blur on active element
      if (
        matchingValue &&
        index === fields.length - 1 &&
        document.activeElement
      ) {
        (document.activeElement as HTMLElement).blur();
      }

      saveData();
    }
  };

  const getCountOfEmptyInputs = useCallback(() => {
    let count = 0;

    fields.forEach((field, index) => {
      if (!watch()[index + 1]) {
        count += 1;
      }
    });

    setEmtpyInputs(count ? count : -1);
  }, [watch, fields, setEmtpyInputs]);

  useLayoutEffect(() => {
    if (inputValue && !isCursorMovementPrevented) {
      setFirstRenderDone(true);
      if (!firstRenderDone) {
        setIsOfferCodeNameActive(true);
        fields.forEach((field, index) => {
          setValue(field, inputValue[index], { shouldValidate: true });
        });
      }
    }
  }, [
    fields,
    firstRenderDone,
    inputValue,
    isCursorMovementPrevented,
    setValue,
  ]);

  useEffect(() => {
    if (isOfferCodeActive) {
      setFocus(fields[0]);
    }
  }, [fields, isOfferCodeActive, setFocus]);

  useEffect(() => {
    if (firstRenderDone && !isOfferCodeSaved) {
      setIsOfferCodeSaved(true);
      saveData();
    }
  }, [firstRenderDone, isOfferCodeSaved, saveData]);

  useEffect(() => {
    if (isOfferCodeSaved) {
      getCountOfEmptyInputs();
    }
  }, [isOfferCodeSaved, getCountOfEmptyInputs]);

  return (
    <OfferCodeContainer>
      <OfferCodeForm>
        <FormLabel
          $error={(error || !isOfferCodeUnique) && isOfferCodeActive}
          $active={!!(isOfferCodeActive || inputValue)}
          onClick={() => setIsOfferCodeNameActive(true)}
        >
          Name offer code (min 6 chars)
        </FormLabel>
        {isOfferCodeActive ? (
          <>
            {fields.map((field, i) => {
              return (
                <InputContainer
                  key={field}
                  $error={(error || !isOfferCodeUnique) && isOfferCodeActive}
                  $disabled={disabled}
                  $emptyInputs={emptyInputs}
                  $withDifferentBorderColor
                >
                  <FormInput
                    data-testid="mobile-offer-code"
                    key={field}
                    id={field}
                    $disabled={disabled}
                    type="text"
                    autoComplete="off"
                    {...register(field, {
                      required: false,
                    })}
                    onKeyDown={(e) =>
                      codeFormKeyDownHandler(
                        e,
                        i,
                        setFocus,
                        fields,
                        watch()[i + 1]
                      )
                    }
                    maxLength={1}
                    onSelect={(e) => e.currentTarget.select()}
                    onChange={(e) => changeInputHandler(e, i)}
                    $error={(error || !isOfferCodeUnique) && isOfferCodeActive}
                  />
                </InputContainer>
              );
            })}
          </>
        ) : (
          <InputContainerWithTextCursor
            onClick={() => setIsOfferCodeNameActive(true)}
          />
        )}
      </OfferCodeForm>
      {(error || !isOfferCodeUnique) && isOfferCodeActive && (
        <Error $error>
          <WarningIcon color={theme.colors.primary} />
          {!isOfferCodeUnique
            ? 'Offer code is not unique'
            : 'Min length 6 characters!'}
        </Error>
      )}
    </OfferCodeContainer>
  );
};

const OfferCodeContainer = styled.div`
  margin-bottom: 33px;
`;

const InputContainerWithTextCursor = styled(InputContainer)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: text;
`;

const Error = styled(ErrorText)`
  margin-top: 10px;
  position: absolute;
`;

OfferCodeInput.displayName = 'OfferCodeInput';

export default OfferCodeInput;
