import memoize from 'memoize-one';
import React, { useState, useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import SimpleBar from 'simplebar-react';
import theme from 'theme';

import 'simplebar-react/dist/simplebar.min.css';

import ActionModal from 'components/actionModal/ActionModal';
import DefaultButton from 'components/buttons/DefaultButton';
import { ContentInfo } from 'modules/auth/models';

import { ContentQueryKey, EditableCourseType } from '../../models';
import {
  BackgroundContainer,
  ButtonWrapper,
  ChosenList,
  ChosenListTitle,
  ChosenNumber,
  ChosenTopicTitle,
  Container,
  innerElementType,
  ListItem,
  ListsContainer,
  SelectTopicContainer,
  StyledList,
  Subtitle,
  Title,
  Wrapper,
} from '../modules/ChooseModulesModal';

import SelectCourseItem from './SelectCourseItem';

const createItemData = memoize(
  (items: EditableCourseType[], toggleItemActive: (id: string) => void) => ({
    items,
    toggleItemActive,
  })
);

const NR_COURSES = 5;
const NUMBER_OF_ITEMS_TO_SLICE = -2;

type Props = {
  courses: EditableCourseType[];
  closeModalWindow: () => void;
};

const ChooseCoursesModal = ({ courses, closeModalWindow }: Props) => {
  const queryClient = useQueryClient();
  const [editableCourses, setEditableCourses] =
    useState<EditableCourseType[]>(courses);
  const [selectedCourses, setSelectedCourses] = useState<EditableCourseType[]>(
    []
  );

  const toggleCourse = useCallback(
    (id: string) => {
      setEditableCourses(
        editableCourses.map((course) => {
          if (course.id === id) {
            if (!course.isChosen) {
              setSelectedCourses((prevSelected) => [...prevSelected, course]);
            } else {
              setSelectedCourses((prevSelected) =>
                prevSelected.filter(({ id }) => id !== course.id)
              );
            }

            return {
              ...course,
              isChosen: !course.isChosen,
            };
          }

          return course;
        })
      );
    },
    [editableCourses]
  );

  const isRightQuantityChosen = useMemo(
    () => selectedCourses.length === NR_COURSES,
    [selectedCourses.length]
  );

  const setCoursesInfoToLocalStorage = () => {
    const courseIds = selectedCourses.map(({ id }) => id);

    localStorage.setItem(
      ContentInfo.DateOfSelectingCourses,
      JSON.stringify(new Date().getTime())
    );
    localStorage.setItem(ContentInfo.CourseIds, JSON.stringify(courseIds));
    closeModalWindow();
  };

  const memoizedTopicsData = createItemData([...editableCourses], toggleCourse);

  return (
    <ActionModal isEmployerModal closeModalWindow={closeModalWindow}>
      <Wrapper>
        <Container>
          <BackgroundContainer>
            <Title>Select courses</Title>
            <Subtitle>Add courses for employees (Select {NR_COURSES})</Subtitle>
            <SelectTopicContainer>
              <SimpleBar
                autoHide={false}
                style={{ height: 376, width: '105%' }}
              >
                {({ scrollableNodeRef, contentNodeRef }) => (
                  <StyledList
                    height={376}
                    itemCount={editableCourses.length}
                    itemSize={95}
                    width={690}
                    itemData={memoizedTopicsData}
                    innerRef={contentNodeRef}
                    outerRef={scrollableNodeRef}
                    innerElementType={innerElementType}
                  >
                    {SelectCourseItem}
                  </StyledList>
                )}
              </SimpleBar>
            </SelectTopicContainer>
          </BackgroundContainer>
          <ButtonWrapper>
            <DefaultButton
              isActive={isRightQuantityChosen}
              withShadow
              borderSize="md"
              buttonSize="md"
              text="APPLY"
              textColor={theme.colors.white}
              buttonColor={
                isRightQuantityChosen
                  ? theme.colors.funtainBlue
                  : theme.colors.quillGray
              }
              clickHandler={() => {
                setCoursesInfoToLocalStorage();
                queryClient.invalidateQueries(ContentQueryKey.GetCourses);
              }}
            />
          </ButtonWrapper>
        </Container>

        {!!selectedCourses.length && (
          <ChosenList>
            <ChosenListTitle>Selected</ChosenListTitle>
            <ListsContainer>
              {selectedCourses.map(({ id, title }, i) => (
                <ListItem key={id}>
                  <ChosenNumber>
                    {`0${i + 1}`.slice(NUMBER_OF_ITEMS_TO_SLICE)}
                  </ChosenNumber>
                  <ChosenTopicTitle>{title}</ChosenTopicTitle>
                </ListItem>
              ))}
            </ListsContainer>
          </ChosenList>
        )}
      </Wrapper>
    </ActionModal>
  );
};

export default ChooseCoursesModal;
