import { QueryFunctionContext, useQuery } from 'react-query';

import { UsersResponse } from '../usersServices/usersAccountsService';

const useGetUsers = (
  queryKeys: (string | number)[],
  queryFn: (data: QueryFunctionContext) => Promise<UsersResponse>
) =>
  useQuery([...queryKeys], queryFn, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

export default useGetUsers;
