import React from 'react';

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="60"
    viewBox="0 0 40 60"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 6192"
          d="m22.615-4.855-3.75-.033a.625.625 0 0 1-.62-.625V-16.15a1.25 1.25 0 0 0-1.25-1.25h-6.218A1.283 1.283 0 0 0 9.5-16.123v10.6a.625.625 0 0 1-.625.625H5.14a.64.64 0 0 0-.592.385.618.618 0 0 0 .14.68l8.688 8.5a.65.65 0 0 0 .9 0L23.06-3.79a.62.62 0 0 0 .143-.682.637.637 0 0 0-.588-.383Z"
          transform="translate(-4.5 17.4)"
          fill="#d7a6b0"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Path 6194"
          d="M32.5-24h-25A7.5 7.5 0 0 0 0-16.5v45A7.5 7.5 0 0 0 7.5 36h25a7.5 7.5 0 0 0 7.5-7.5v-45a7.5 7.5 0 0 0-7.5-7.5ZM35 23.5a1.25 1.25 0 0 1-1.25 1.25H6.25A1.25 1.25 0 0 1 5 23.5v-38.75a1.25 1.25 0 0 1 1.25-1.25h27.5A1.25 1.25 0 0 1 35-15.25Z"
          transform="translate(0 24)"
          fill="#d7a6b0"
        />
      </clipPath>
    </defs>
    <g data-name="Group 5222">
      <g
        data-name="Group 5220"
        transform="translate(11.25 16.5)"
        clipPath="url(#a)"
      >
        <path
          data-name="Path 6191"
          d="M-12.5-12.5h43.749v47.246H-12.5Z"
          fill="#d7a6b0"
        />
      </g>
      <g data-name="Group 5221" clipPath="url(#b)">
        <path data-name="Path 6193" d="M-12.5-12.5h65v85h-65Z" fill="#d7a6b0" />
      </g>
    </g>
  </svg>
);

export default PhoneIcon;
