import React, { useCallback, useEffect, useState } from 'react';

import SearchBar from 'components/searchBar/SearchBar';
import { STANDARD_PAGE_SIZE } from 'components/table/models';
import TableLoader from 'components/table/TableLoader';
import { cleanOfferLocalStorage } from 'helpers/connectToLocalStorage';
import useGetAffiliateOffers from 'modules/offers/hooks/useGetAffiliateOffers';
import {
  OfferCategory,
  AFFILIATE_SORT_BY_BE,
  QueryKey,
} from 'modules/offers/models';
import { getAffiliateOffers } from 'modules/offers/offersServices/AffiliateOffersService';

import CreateOfferModal from '../CreateOfferModal';

import AffiliateTable from './AffiliateTable';

export const PUBLISH_LABEL_DELAY = 4000;

const AffiliateOffers = () => {
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);

  const [nameForSearching, setNameForSearching] = useState('');
  const [sortByListItemIndex, setSortByListItemIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(STANDARD_PAGE_SIZE);
  const [isOfferPublished, setIsOfferPublished] = useState(false);
  const [isShowTablePublish, setShowTablePublish] = useState(false);

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
    cleanOfferLocalStorage();
  }, [isCreatingModalOpen]);

  const chooseTypeOfSorting = useCallback((index: number) => {
    setPage(1);
    setSortByListItemIndex(index);
  }, []);

  const searchByOfferName = useCallback((name: string) => {
    setPage(1);
    setNameForSearching(name);
  }, []);

  const { data, isLoading } = useGetAffiliateOffers(
    [
      QueryKey.GetAffiliateOffers,
      nameForSearching,
      sortByListItemIndex,
      page,
      pageSize,
    ],
    () => {
      return getAffiliateOffers({
        data: {
          page: page || 1,
          pageSize,
          search: nameForSearching,
          sortBy: AFFILIATE_SORT_BY_BE[sortByListItemIndex],
        },
      });
    }
  );

  useEffect(() => {
    if (!isLoading && isOfferPublished) {
      setShowTablePublish(true);
      const clearOfferShowing = setTimeout(() => {
        setIsOfferPublished(false);
        setShowTablePublish(false);
      }, PUBLISH_LABEL_DELAY);

      return () => clearTimeout(clearOfferShowing);
    }
  }, [data, isLoading, isOfferPublished]);

  return (
    <>
      <SearchBar
        title="Offers:"
        buttonText="Create offer"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter="Affiliate"
        setNewNameToFilter={searchByOfferName}
      />
      {isLoading ? (
        <TableLoader />
      ) : (
        <AffiliateTable
          isOfferPublished={sortByListItemIndex === 1 && isShowTablePublish}
          data={data?.items}
          page={page}
          dataAmount={data?.totalCount || 0}
          setPage={setPage}
          activePageSize={pageSize}
          setActivePageSize={setPageSize}
          sortOffers={chooseTypeOfSorting}
          sortByListItemIndex={sortByListItemIndex}
        />
      )}
      {isCreatingModalOpen && (
        <CreateOfferModal
          setIfOfferPublished={setIsOfferPublished}
          activeOfferCategory={OfferCategory.Affiliate}
          closeModalWindow={toggleCreatingModalWindow}
        />
      )}
    </>
  );
};

export default AffiliateOffers;
