import { AxiosProgressEvent } from 'axios';
import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import { ERROR_IMAGE_MESSAGE } from 'components/actionModal/forms/uploadOfferPageField/UploadOfferPageField';
import { REMOVE_ERROR_TIMER } from 'components/actionModal/models';

import {
  PromotionOfferPageAttributes,
  PromotionOfferResponse,
  PromotionSettingsPageAttributes,
} from '../models';

export type CreatePromotionOfferDraftPayload = {
  data: PromotionOfferPageAttributes;
};

export type EditPromotionOfferData = {
  title: string;
  subtitle: string;
  bodyText: string;
  notes: string;
  backgroundColour: string;
};

export type EditPromotionOfferDraftPayload = {
  data: EditPromotionOfferData;
  offerId: string;
};

export type CreatePromotionSettingsPayload = {
  data: PromotionSettingsPageAttributes;
  id: string;
};

export const createPromotionfferDraft = ({
  data,
}: CreatePromotionOfferDraftPayload) => {
  return axiosClient
    .post(`/api/v${config.apiVersionFive}/PromotionOffers/CreateDraft`, data)
    .then((response) => response.data.payload.data);
};

export const getPromotionOfferTerms = (): Promise<
  { id: string; term: string }[]
> => {
  return axiosClient
    .get(`/api/v${config.apiVersionFive}/PromotionTerms`)
    .then((response) => response.data.payload.data);
};

export const publishPromotionOffer = ({
  id,
  data,
}: CreatePromotionSettingsPayload) => {
  return axiosClient
    .put(`/api/v${config.apiVersionFive}/PromotionOffers/Publish`, {
      id,
      ...data,
    })
    .then((response) => response.data.payload.data);
};

export type ImageDraftPayloadPromotion = {
  id: string;
  options: {
    onUploadProgress: (progress: AxiosProgressEvent) => void;
  };
  formData: FormData;
  openError: (value: boolean) => void;
  setErrorText: (value: string) => void;
  toggleFormView: () => void;
};

export const uploadPromotionLogo = async ({
  id,
  options,
  formData,
  openError,
  setErrorText,
  toggleFormView,
}: ImageDraftPayloadPromotion) => {
  try {
    const response = await axiosClient.put(
      `/api/v${config.apiVersionFive}/PromotionOffers/${id}/UploadImage`,
      formData,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    toggleFormView();

    return response.data.payload;
  } catch {
    openError(true);
    setErrorText(ERROR_IMAGE_MESSAGE);

    setTimeout(() => {
      openError(false);
    }, REMOVE_ERROR_TIMER);
  }
};

export type PromotionOffersResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  items: PromotionOfferResponse[];
};

export type PageInfo = {
  page: number;
  pageSize: number;
  sortBy: string;
  search: string;
};

type PromotionOffersPayload = {
  data: PageInfo;
};

export const getPromotionOffers = ({
  data,
}: PromotionOffersPayload): Promise<PromotionOffersResponse> => {
  return axiosClient
    .post(`/api/v${config.apiVersionFive}/PromotionOffers/GetPage`, data)
    .then((response) => response.data.payload.data);
};

export const editPromotionOffer = ({
  data,
  offerId,
}: EditPromotionOfferDraftPayload) => {
  return axiosClient
    .put(`/api/v${config.apiVersionFive}/PromotionOffers/${offerId}`, data)
    .then((response) => response.data.payload.data);
};

type DeletePromotionOfferPayload = {
  promotionOfferId: string;
};

export const deletePromotionOffer = ({
  promotionOfferId,
}: DeletePromotionOfferPayload) =>
  axiosClient
    .delete(
      `/api/v${config.apiVersionFive}/PromotionOffers/${promotionOfferId}`
    )
    .then((response) => response.data.payload);
