import React from 'react';
import styled from 'styled-components';

import { AuthStage } from '../models';

type HeaderProps = {
  headerType: AuthStage;
  lastTwoDigitsOnMobile?: string;
};

const Header = ({ headerType, lastTwoDigitsOnMobile }: HeaderProps) => {
  switch (headerType) {
    case AuthStage.SignIn:
      return <Container>Sign in</Container>;
    case AuthStage.Verify:
      return (
        <Container>
          Verify your identity
          <Description>
            Enter the code we sent to your phone number ending in
            <LastTwoDigitsOnMobile>
              &nbsp;-{lastTwoDigitsOnMobile}
            </LastTwoDigitsOnMobile>
          </Description>
        </Container>
      );
    case AuthStage.Password:
      return (
        <Container>
          Choose password
          <Description>You will use this the next time you sign in</Description>
        </Container>
      );
    case AuthStage.NewPassword:
      return (
        <Container>
          Choose a new password
          <Description>You will use this the next time you sign in</Description>
        </Container>
      );
    default:
      return null;
  }
};

const Container = styled.h2`
  position: relative;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 75px;
`;

const Description = styled.p`
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 400;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.darkBlue};
  width: 350px;
  text-align: center;
`;

const LastTwoDigitsOnMobile = styled.span`
  font-weight: 700;
`;

export default Header;
