import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import customizeListData from 'helpers/customizeListData';

export const getDepartmentList = () => {
  return axiosClient
    .get(`/api/v${config.apiVersion}/Departments`)
    .then((response) => response.data)
    .then((departments) => customizeListData(departments));
};
