import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ChevronIcon from 'components/icons/ChevronIcon';
import PreviewList from 'components/listForSelectingData/lists/PreviewList';
import { ListItemData } from 'helpers/filterDataForDropdownList';

import {
  FormInput,
  FormLabel,
  InputContainerWrapper,
} from './previewPageCorporate/StyledElements';

const PreviewDropDown = ({
  data,
  textLabel,
}: {
  data: ListItemData[];
  textLabel: string;
}) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [chosenText, setChosenText] = useState('');

  const pickText = useCallback(
    (id: string) => {
      setChosenText(data.find((item) => item.id === id)?.displayName || '');
    },
    [data]
  );

  return (
    <InputContainerWrapper>
      <FormLabel $isActive={!!chosenText} htmlFor={textLabel}>
        {textLabel}
      </FormLabel>
      <FormInput
        id={textLabel}
        type="text"
        readOnly
        autoComplete="off"
        onFocus={() => setIsListOpen(true)}
        value={chosenText}
      />
      <IconWrapper onClick={() => setIsListOpen(true)}>
        <ChevronIcon color={theme.colors.funtainBlue} />
      </IconWrapper>
      {isListOpen && (
        <PreviewList
          listWidth="lg"
          data={data}
          label="Select department"
          closeList={() => setIsListOpen(false)}
          chooseId={pickText}
        />
      )}
    </InputContainerWrapper>
  );
};

const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export default PreviewDropDown;
