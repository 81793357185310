import { useMutation } from 'react-query';

import {
  UserDetailsPayload,
  UserResponse,
} from '../usersServices/usersAccountsService';

const useDeleteAccessCode = (
  clearCodeData: () => void,
  mutationFn: ({ id }: UserDetailsPayload) => Promise<UserResponse>
) => {
  return useMutation(mutationFn, {
    onSuccess: () => {
      clearCodeData();
    },
  });
};

export default useDeleteAccessCode;
