import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';

import EmployerLogin from 'modules/auth/pages/EmployerLogin';
import Content from 'modules/content/pages/Content';
import Delegates from 'modules/delegates/pages/Delegates';
import Insights from 'modules/insights/pages/Insights';
import Onboarding from 'modules/onboarding/pages/Onboarding';

import EmployersPrivateRoute from './EmployersPrivateRoute';
import { EmployerRoutes } from './models';

const EmployersRoute = () => (
  <>
    <Helmet>
      <meta
        name="description"
        content="Employer portal for managing offers and users"
      />
      <title>Employer portal</title>
    </Helmet>
    <Routes>
      <Route path={EmployerRoutes.Login} element={<EmployerLogin />} />
      <Route
        path={EmployerRoutes.Insights}
        element={
          <EmployersPrivateRoute>
            <Insights />
          </EmployersPrivateRoute>
        }
      />
      <Route
        path={EmployerRoutes.Onboarding}
        element={
          <EmployersPrivateRoute>
            <Onboarding />
          </EmployersPrivateRoute>
        }
      />
      <Route
        path={EmployerRoutes.Delegates}
        element={
          <EmployersPrivateRoute>
            <Delegates />
          </EmployersPrivateRoute>
        }
      />
      <Route
        path={EmployerRoutes.Content}
        element={
          <EmployersPrivateRoute>
            <Content />
          </EmployersPrivateRoute>
        }
      />
      <Route
        path={EmployerRoutes.Events}
        element={<Navigate to={`../${EmployerRoutes.Insights}`} replace />}
      />
      <Route
        path="*"
        element={<Navigate to={EmployerRoutes.Insights} replace />}
      />
    </Routes>
  </>
);

export default EmployersRoute;
