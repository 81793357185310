import React from 'react';

import { ListItemData } from 'helpers/filterDataForDropdownList';

import PreviewDropDown from '../PreviewDropDown';
import StandardPreviewInput from '../StandardPreviewInput';

import { OfferCodeForm } from './StyledElements';

type FormContainerProps = {
  isShowDepartment?: boolean;
  data: ListItemData[];
};

const FormContainer = ({ data, isShowDepartment }: FormContainerProps) => {
  return (
    <OfferCodeForm>
      {isShowDepartment && (
        <PreviewDropDown data={data} textLabel="Select department" />
      )}

      <StandardPreviewInput textLabel="Enter offer code" />
    </OfferCodeForm>
  );
};

export default FormContainer;
