import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '../models';
import {
  UserDetailsEditAccessCodePayload,
  UserResponse,
} from '../usersServices/usersAccountsService';

const useChangeAccessCode = (
  goToFinalStep: () => void,
  mutationFn: ({
    term,
    id,
  }: UserDetailsEditAccessCodePayload) => Promise<UserResponse>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      if (data.succeeded) {
        goToFinalStep();
        queryClient.invalidateQueries(QueryKey.GetUsersAccounts);
      }
    },
  });
};

export default useChangeAccessCode;
