import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const PIE_SIZE = 16;
const CIRCLE_RADIUS = 8;

type SlideElement = {
  id: number;
  sliderId: number;
};

type Props = {
  slides: SlideElement[];
  selectedIdx: number;
  isMobile?: boolean;
  interval?: number;
  clickHandler: (idx: number) => void;
};

const SlideSelector = ({
  slides,
  selectedIdx,
  interval,
  clickHandler,
}: Props) => (
  <Container>
    {slides.map((slide, i) => {
      return (
        <PickedItem
          key={slide.id}
          onClick={() => {
            clickHandler(i);
          }}
        >
          <Pie width={PIE_SIZE} height={PIE_SIZE}>
            <Circle
              r={CIRCLE_RADIUS}
              cx={CIRCLE_RADIUS}
              cy={CIRCLE_RADIUS}
              $focused={selectedIdx === i}
              $interval={interval}
            />
          </Pie>
        </PickedItem>
      );
    })}
  </Container>
);

const Container = styled.div`
  display: flex;
`;

const PickedItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.darkBlueV2};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const animate = keyframes`
  from {
    stroke-dasharray: 0 52;

    @media (max-width: 450px) {
      stroke-dasharray: 0 314;
    }
  }

  to {
    stroke-dasharray: 52;

    @media (max-width: 450px) {
      stroke-dasharray: 314;
    }
  }
`;

const Circle = styled.circle<{
  $focused: boolean;
  $interval?: number;
}>`
  fill: none;
  ${({ $focused, $interval }) =>
    $focused &&
    $interval &&
    css`
      stroke-width: 16;
      stroke-dasharray: 0 52;
      animation: ${animate} ${$interval}ms linear;
      stroke: ${({ theme }) => theme.colors.darkBlueV2};
    `};
  ${({ theme, $focused, $interval }) =>
    $focused &&
    !$interval &&
    css`
      fill: ${theme.colors.darkBlueV2};
    `}
`;

const Pie = styled.svg`
  transform: rotate(-90deg);
  background: transparent;
  border-radius: 50%;
`;

export default SlideSelector;
