import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Success from '../components/stages/adminLoginStages/Success';
import EmployerAsteriskPasswordForm from '../components/stages/employerLoginStages/EmployerAsteriskPasswordForm';
import EmployerChooseNewPassword from '../components/stages/employerLoginStages/EmployerChooseNewPassword';
import EmployerEmailForm from '../components/stages/employerLoginStages/EmployerEmailStage';
import EmployerVerifyIdentity from '../components/stages/employerLoginStages/EmployerVerifyIdentity';
import {
  BackgroundImage,
  Container,
  LogoWrapper,
  SignInModal,
} from '../components/styledElements/pagesStyledElements';
import { AuthStage, SuccessAuth } from '../models';

const EmployerLogin = () => {
  const [employerLoginStage, setAdminLoginStage] = useState(AuthStage.SignIn);
  const [lastTwoDigitsOnMobile, setLastTwoDigitsOnMobile] = useState('');
  const [isSecondTimeLogin, setIsSecondTimeLogin] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [email, setEmail] = useState('');

  const goToPasswordFormOrVerifyIdentityStage = useCallback(
    (
      isSecondTimeLogin: boolean,
      email: string,
      lastTwoDigitsOnMobile: string
    ) => {
      if (isSecondTimeLogin) {
        setIsSecondTimeLogin(true);
        setAdminLoginStage(AuthStage.Password);
        setEmail(email);

        return;
      }
      setAdminLoginStage(AuthStage.Verify);
      setLastTwoDigitsOnMobile(lastTwoDigitsOnMobile);
    },
    []
  );

  const goVerifyStage = useCallback(() => {
    setIsForgetPassword(true);
    setAdminLoginStage(AuthStage.Verify);
  }, []);

  const goToSuccessStage = useCallback(
    () => setAdminLoginStage(AuthStage.Success),
    []
  );

  const goToChooseNewPasswordStage = useCallback(() => {
    if (isSecondTimeLogin && isForgetPassword) {
      setAdminLoginStage(AuthStage.NewPassword);
    }
  }, [isForgetPassword, isSecondTimeLogin]);

  const redirectToLoginFirstStage = useCallback(() => {
    setAdminLoginStage(AuthStage.SignIn);
    setEmail('');
    setIsSecondTimeLogin(false);
    setIsForgetPassword(false);
    setLastTwoDigitsOnMobile('');
  }, []);

  return (
    <EmployerContainer>
      {employerLoginStage !== AuthStage.Success && (
        <>
          <EmployerLogoWrapper>
            <img src="/images/employerLoginLabel.svg" alt="" />
          </EmployerLogoWrapper>
          <SignInModal>
            {employerLoginStage === AuthStage.SignIn && (
              <EmployerEmailForm
                goToNextStage={goToPasswordFormOrVerifyIdentityStage}
              />
            )}
            {employerLoginStage === AuthStage.Password && (
              <EmployerAsteriskPasswordForm
                forgotPassword={goVerifyStage}
                goToNextStage={() => setAdminLoginStage(AuthStage.Success)}
              />
            )}
            {employerLoginStage === AuthStage.Verify && (
              <EmployerVerifyIdentity
                email={email}
                goToNextStage={goToChooseNewPasswordStage}
                lastTwoDigitsOnMobile={lastTwoDigitsOnMobile}
              />
            )}
            {employerLoginStage === AuthStage.NewPassword && (
              <EmployerChooseNewPassword goToNextStage={goToSuccessStage} />
            )}
          </SignInModal>
        </>
      )}
      {employerLoginStage === AuthStage.Success && (
        <Success
          redirectToLoginFirstStage={redirectToLoginFirstStage}
          successType={
            isForgetPassword
              ? SuccessAuth.NewPasswordLogin
              : SuccessAuth.EmployerLogin
          }
        />
      )}
      <BackgroundImage src="/images/background.svg" />
    </EmployerContainer>
  );
};

const EmployerLogoWrapper = styled(LogoWrapper)`
  top: 30px !important;
`;

const EmployerContainer = styled(Container)`
  background: ${({ theme }) => theme.colors.primaryV2};
`;

export default EmployerLogin;
