import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'theme';

import DashedCircleIconV2 from 'components/icons/dashedCircles/DashedCircleIconV2';
import DashedCircleIconV3 from 'components/icons/dashedCircles/DashedCircleIconV3';

import {
  FileDropZoneContainer,
  FileName,
} from '../../styledElements/stagesStyledElements';

type UploadPendingProps = {
  fileName: string;
  isFirstChild?: boolean;
  downloadPercentages: string;
};

const UploadPending = React.forwardRef<
  HTMLParagraphElement,
  UploadPendingProps
>(({ fileName, isFirstChild, downloadPercentages }, ref) => (
  <Container $isFirstChild={isFirstChild}>
    <LoaderContainer>
      <AnimationCircle $speed={2}>
        <DashedCircleIconV3 size={62} color={theme.colors.funtainBlue} />
      </AnimationCircle>
      <AnimationCircle $speed={1}>
        <DashedCircleIconV2 size={82} color={theme.colors.funtainBlue} />
      </AnimationCircle>
      <p ref={ref}>{downloadPercentages}</p>
    </LoaderContainer>
    <FileName>{fileName}</FileName>
  </Container>
));

const Container = styled(FileDropZoneContainer)`
  margin-bottom: 30px;

  @media (width <= 1250px) {
    width: 85%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  p {
    text-align: left;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 110px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.lightYellow};
  margin: 0 18px 0 10px;
  background: none;

  p {
    text-align: center;
    font-family: monospace;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const AnimationCircle = styled.div<{ $speed: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} ${({ $speed }) => $speed}s linear infinite;
`;

// This displayName is needed here due to eslint error about forwardRef
UploadPending.displayName = 'UploadPending';

export default UploadPending;
