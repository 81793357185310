import { find } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { offerTerms } from 'components/listForSelectingData/dataForLists';
import { OnboardingOfferContext } from 'modules/onboarding/context/OnboardingOfferContext';

import { Device } from '../models';
import PreviewHeader from '../PreviewHeader';
import { getDepartmentList } from '../PreviewService';

import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';
import TabletPreview from './TabletPreview';

type PreviewPageInsightProps = {
  logo: string;
  background?: string;
  chosenDevice: Device;
  closePreview: () => void;
};

const PreviewPageInsight = ({
  logo,
  background,
  chosenDevice,
  closePreview,
}: PreviewPageInsightProps) => {
  const { offerCodeData } = useContext(OnboardingOfferContext);
  const [activeDevice, setActiveDevice] = useState(chosenDevice);
  const offerTerm =
    find(offerTerms, { BEformat: offerCodeData.offerTerm })?.displayName || '';

  const changeActiveDeice = useCallback(
    (device: Device) => setActiveDevice(device),
    []
  );

  const departmentList = useQuery([], getDepartmentList).data || [];

  return (
    <Container>
      <PreviewHeader
        title="onboarding"
        activeDevice={activeDevice}
        closePreview={closePreview}
        setActiveDevice={changeActiveDeice}
      />
      {activeDevice === Device.Desktop && (
        <DesktopPreview
          data={departmentList}
          companyName={offerCodeData.account}
          logo={logo}
          background={background}
          offerTerm={offerTerm}
        />
      )}
      {activeDevice === Device.Tablet && (
        <TabletPreview
          data={departmentList}
          companyName={offerCodeData.account}
          background={background}
          offerTerm={offerTerm}
        />
      )}
      {activeDevice === Device.Mobile && (
        <MobilePreview
          data={departmentList}
          companyName={offerCodeData.account}
          background={background}
          offerTerm={offerTerm}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  z-index: 7;
`;

export default PreviewPageInsight;
