/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty, isEqual, omit } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import { InputId } from 'components/actionModal/models';
import DefaultButton from 'components/buttons/DefaultButton';
import { Button } from 'components/detailsModal/styledElements/detailsModalStyledElements';
import { UserAccountAttributes } from 'modules/users/usersServices/usersAccountsService';

import MobileInput from '../../forms/inputs/MobileInput';
import PasscodeInput from '../../forms/inputs/PassCodeInput';
import StandardInput from '../../forms/inputs/StandardInput';
import { userAccountDetailsSchema } from '../../models/validationSchemas';
import {
  FormContainer,
  MultipleInputsContainer,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';

const PASSCODE_MIN_LENGTH = 6;
const FIELDS = ['1', '2', '3', '4', '5', '6'];

type UserAccountDetailsProps = {
  isEdit?: boolean;
  errorResponse: {
    input: string;
    message: string;
  };
  title: string;
  userDetails: UserAccountAttributes;
  countryAbbreviation: string;
  disableAccessStage: (value?: boolean) => void;
  goToNextStage: (skip?: boolean) => void;
  sendRequest?: (userAttr: UserAccountAttributes) => void;
  saveAccountDetailsData: (
    accountDetails: UserAccountAttributes,
    countryAbbr?: string
  ) => void;
};

const UserAccountDetails = ({
  isEdit,
  title,
  userDetails,
  countryAbbreviation,
  errorResponse,
  disableAccessStage,
  sendRequest,
  goToNextStage,
  saveAccountDetailsData,
}: UserAccountDetailsProps) => {
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isPasscodeValid, setIsPasscodeValid] = useState(!!isEdit);
  const [prevData, setPrevData] = useState<UserAccountAttributes>();
  const [disableButton, setDisableButton] = useState(false);
  const [disableSkip, setDisableSkip] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  // we use this value to prevent mobile input from rerendering
  const [mobileNumber, setMobileNumber] = useState('');

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    setFocus,
    setError,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(userAccountDetailsSchema),
  });
  const isFormValid =
    isValid && isMobileValid && isPasscodeValid && isEmpty(errors);

  useEffect(() => {
    const { input, message } = errorResponse;

    if (input) {
      setError(input as any, {
        message,
      });
      setFocus(input as any);
    }
  }, [errorResponse, setError, setFocus]);

  const saveFirstName = useCallback(() => {
    const firstName = watch()[InputId.FirstNameInputId];

    saveAccountDetailsData(
      {
        ...userDetails,
        firstName,
      },
      countryAbbreviation
    );
  }, [watch, saveAccountDetailsData, userDetails, countryAbbreviation]);

  const saveEmailAddress = useCallback(() => {
    const email = watch()[InputId.EmailAddressInputId];

    saveAccountDetailsData(
      {
        ...userDetails,
        email,
      },
      countryAbbreviation
    );
  }, [watch, saveAccountDetailsData, userDetails, countryAbbreviation]);

  const saveMobile = useCallback(
    (code?: string, countryAbbreviation?: string) => {
      const validNumber = watch()[InputId.MobileNumberInputId]?.slice(
        code?.length
      );

      saveAccountDetailsData(
        {
          ...userDetails,
          phoneNumber: validNumber || '',
          countryCode: code || '',
        },
        countryAbbreviation
      );
    },
    [watch, userDetails, saveAccountDetailsData]
  );

  const setMobileCopy = useCallback(() => {
    const validNumber = watch()[InputId.MobileNumberInputId]?.slice(
      userDetails.countryCode?.length
    );

    setMobileNumber(validNumber || '');
  }, [watch, userDetails]);

  const savePasscode = useCallback(() => {
    const passcode = FIELDS.map(
      (field) => (watch() as Record<string, any>)[field]
    ).join('');

    saveAccountDetailsData(
      {
        ...userDetails,
        passcode,
      },
      countryAbbreviation
    );

    setIsPasscodeValid(passcode.length === PASSCODE_MIN_LENGTH);
  }, [saveAccountDetailsData, userDetails, countryAbbreviation, watch]);

  const formSubmit = () => {
    const validNumber = watch()[InputId.MobileNumberInputId]?.slice(
      userDetails.countryCode?.length
    );

    if (sendRequest) {
      sendRequest({
        ...userDetails,
        email: watch()[InputId.EmailAddressInputId],
        phoneNumber: validNumber || '',
        firstName: watch()[InputId.FirstNameInputId],
      });
    }
  };

  // we have to run this only once, to set data for edit user flow for comparing with next values
  useEffect(() => {
    if (isEdit && !isSaved && userDetails.firstName) {
      setPrevData(userDetails);
      setIsSaved(true);
      setMobileNumber(userDetails.phoneNumber);
    }
  }, [isEdit, isSaved, userDetails]);

  // we have to run this only once, to set data for create user flow for comparing with next values
  useEffect(() => {
    if (!isEdit && Object.values(userDetails).every((x) => !!x)) {
      setPrevData(userDetails);
      setMobileNumber(userDetails.phoneNumber);
    }
    // we have to run this only once, to set data and then to know difference between previous and current userDetails
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevData) {
      if (
        isEqual(
          omit(userDetails, ['phoneNumber']),
          omit(prevData, ['phoneNumber'])
        ) &&
        mobileNumber === prevData.phoneNumber &&
        !errorResponse.input
      ) {
        disableAccessStage();
        if (isEdit) {
          setDisableSkip(false);
          setDisableButton(true);
        }
      } else {
        disableAccessStage(true);
        if (isEdit) {
          setDisableSkip(true);
          setDisableButton(false);
        }
      }
    }
  }, [
    isEdit,
    prevData,
    userDetails,
    errorResponse,
    disableAccessStage,
    mobileNumber,
  ]);

  return (
    <div>
      <Title>{title}</Title>
      <FormContainer onSubmit={handleSubmit(formSubmit)}>
        <StandardInput
          doNotTriggerValidation={
            errorResponse.input === InputId.FirstNameInputId
          }
          errors={errors}
          register={register}
          setValue={setValue}
          label="First name"
          inputId={InputId.FirstNameInputId}
          inputValue={userDetails.firstName}
          saveData={saveFirstName}
        />
        <StandardInput
          doNotTriggerValidation={
            errorResponse.input === InputId.EmailAddressInputId
          }
          register={register}
          setValue={setValue}
          errors={errors}
          label="Email"
          inputId={InputId.EmailAddressInputId}
          inputValue={userDetails.email}
          saveData={saveEmailAddress}
        />
        <MobileInput
          setMobileCopy={setMobileCopy}
          doNotTriggerValidation={
            errorResponse.input === InputId.MobileNumberInputId
          }
          control={control}
          isMobileValid={isMobileValid}
          watch={watch}
          saveData={saveMobile}
          inputValue={userDetails.phoneNumber}
          code={userDetails.countryCode}
          countryAbbr={countryAbbreviation}
          register={register}
          setValue={setValue}
          errors={errors}
          setIsMobileValid={setIsMobileValid}
          inputId={InputId.MobileNumberInputId}
        />
        <MultipleInputsContainer>
          <PasscodeInput
            isEdit={isEdit}
            fields={FIELDS}
            watch={watch}
            isPassCodeValid={isPasscodeValid}
            error={!isPasscodeValid}
            register={register}
            setValue={setValue}
            setFocus={setFocus}
            inputValue={userDetails.passcode}
            saveData={savePasscode}
          />
        </MultipleInputsContainer>
        <SubmitButtonContainer>
          <DefaultButton
            text={isEdit ? 'UPDATE' : 'CREATE USER'}
            textColor={theme.colors.white}
            isActive={isFormValid && !disableButton}
            isSubmit
            withShadow
            buttonColor={
              isFormValid && !disableButton
                ? theme.colors.swamp
                : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
          {isEdit && !disableSkip && (
            <StyledButton
              disabled={!isFormValid || disableSkip}
              onClick={() => {
                disableAccessStage();
                goToNextStage(true);
              }}
            >
              skip
            </StyledButton>
          )}
        </SubmitButtonContainer>
      </FormContainer>
    </div>
  );
};

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.funtainBlue};
`;

export default UserAccountDetails;
