import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BaseRoutes, EmployerRoutes } from 'modules/router/models';

import { ContentInfo, Token } from './models';

type AuthContextType = {
  tokenUpdateComplete?: boolean;
  logout: () => void;
  setTokens: (accessToken: string, refreshToken: string) => void;
};

const initialValue: AuthContextType = {
  setTokens: () => null,
  logout: () => null,
};

export const AuthContext = createContext<AuthContextType>(initialValue);

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [tokenUpdateComplete, setTokenUpdateComplete] = useState(false);

  const setTokens = useCallback((accessToken: string, refreshToken: string) => {
    localStorage.setItem(Token.AccessToken, accessToken);
    localStorage.setItem(Token.RefreshToken, refreshToken);
  }, []);

  const logout = useCallback(() => {
    const isEmployersPage = pathname.startsWith(EmployerRoutes.EmployersBase);

    if (isEmployersPage) {
      navigate(`${EmployerRoutes.EmployersBase}/${EmployerRoutes.Login}`, {
        replace: true,
      });
    } else {
      navigate(BaseRoutes.Login, { replace: true });
    }

    localStorage.removeItem(ContentInfo.DateOfSelectingTopics);
    localStorage.removeItem(ContentInfo.TopicIds);
    localStorage.removeItem(Token.AccessToken);
    localStorage.removeItem(Token.RefreshToken);
  }, [navigate, pathname]);

  useEffect(() => {
    const accessToken = localStorage.getItem(Token.AccessToken);
    const refreshToken = localStorage.getItem(Token.RefreshToken);

    if (!accessToken || !refreshToken) {
      setTokenUpdateComplete(true);

      const isEmployersPage = pathname.startsWith(EmployerRoutes.EmployersBase);

      if (!isEmployersPage) {
        navigate(BaseRoutes.Login, { replace: true });
      }

      return;
    }

    setTokenUpdateComplete(true);
  }, [navigate, pathname]);

  return (
    <AuthContext.Provider
      value={{
        tokenUpdateComplete,
        setTokens,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
