import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import ShidoIcon from 'components/icons/ShidoIcon';
import Loader from 'components/Loader';
import { AdminRoutes, BaseRoutes, EmployerRoutes } from 'modules/router/models';

import { checkUserRoles } from '../../../AuthService';
import { UserRole, SuccessAuth } from '../../../models';
import { USER_ROLES_QUERY } from '../../../models/queries';

type SuccessProps = {
  redirectToLoginFirstStage?: () => void;
  successType: SuccessAuth;
};

const Success = ({ successType, redirectToLoginFirstStage }: SuccessProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: userRoles } = useQuery(USER_ROLES_QUERY, checkUserRoles);

  useEffect(() => {
    if (successType === SuccessAuth.EmployerLogin) {
      setTimeout(() => {
        navigate(EmployerRoutes.Insights, { replace: true });
      }, 100);

      return;
    }

    if (
      successType === SuccessAuth.NewPasswordLogin &&
      redirectToLoginFirstStage
    ) {
      setTimeout(() => {
        redirectToLoginFirstStage();
      }, 1000);

      return;
    }

    if (successType === SuccessAuth.AdminLogin && userRoles) {
      if (userRoles.roles.includes(UserRole.Admin)) {
        navigate(`${AdminRoutes.AdminBase}/${AdminRoutes.Accounts}`, {
          replace: true,
        });
      } else {
        setTimeout(() => {
          navigate(BaseRoutes.Login, { replace: true });
        }, 1000);
      }
    }
  }, [pathname, redirectToLoginFirstStage, navigate, successType, userRoles]);

  return (
    <SuccessWrapper>
      <ShidoIcon color={theme.colors.white} />
      <Loader
        circles={[
          {
            color: theme.colors.careysPink,
            speed: 2,
            size: 180,
          },
          {
            color: theme.colors.careysPink,
            speed: 2.3,
            size: 140,
          },
        ]}
      />
    </SuccessWrapper>
  );
};

const SuccessWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Success;
