import React from 'react';

type DashedCircleIconV3Props = {
  size: number;
  color: string;
};

const DashedCircleIconV3 = ({ size, color }: DashedCircleIconV3Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 62 62"
  >
    <g
      id="Group_6290"
      data-name="Group 6290"
      transform="translate(61 1) rotate(90)"
    >
      <path
        id="Path_688"
        data-name="Path 688"
        d="M30,0A30,30,0,1,1,0,30,30,30,0,0,1,30,0Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeDasharray="5 25 80"
      />
    </g>
  </svg>
);

export default DashedCircleIconV3;
