import { compareAsc, isAfter, sub } from 'date-fns';

import { UserTableItem } from 'modules/users/models';

import { UsersGroupBy, groupUsers } from './groupUsers';

const ascCreatedDateSort = (aUser: UserTableItem, bUser: UserTableItem) =>
  compareAsc(new Date(aUser.createdDateTime), new Date(bUser.createdDateTime));

export type UsersStatName = 'createdUsers' | 'topCountries';

const getUsersStats = (users: UserTableItem[]) => {
  const thisDayUsers = users
    .filter((user) =>
      isAfter(new Date(user.createdDateTime), sub(new Date(), { days: 1 }))
    )
    .sort(ascCreatedDateSort);

  const verifiedUsers = groupUsers(UsersGroupBy.UserHasPhone, users);

  const countriesGroups = groupUsers(UsersGroupBy.UserCountryCode, users)
    .filter((group) => group.name !== 'none')
    .sort((aGroup, bGroup) => {
      return bGroup.users.length - aGroup.users.length;
    });

  return {
    thisDayUsers,
    verifiedUsers,
    createdUsers: [
      {
        name: 'Day',
        groups: groupUsers(UsersGroupBy.UserCreatedHours, thisDayUsers),
      },
      {
        name: 'Week',
        groups: groupUsers(
          UsersGroupBy.UserCreatedWeekDays,
          users
            .filter((user) =>
              isAfter(
                new Date(user.createdDateTime),
                sub(new Date(), { weeks: 1 })
              )
            )
            .sort(ascCreatedDateSort)
        ),
      },
      {
        name: 'Month',
        groups: groupUsers(
          UsersGroupBy.UserCreatedMonthDays,
          users
            .filter((user) =>
              isAfter(
                new Date(user.createdDateTime),
                sub(new Date(), { months: 1 })
              )
            )
            .sort(ascCreatedDateSort)
        ),
      },
    ],
    topCountries: [
      {
        name: 'Top 5',
        groups: countriesGroups.slice(0, 5),
      },
      {
        name: 'Top 10',
        groups: countriesGroups.slice(0, 10),
      },
      {
        name: 'Top 20',
        groups: countriesGroups.slice(0, 20),
      },
    ],
  };
};

export default getUsersStats;
