import React, { useCallback, useMemo, useState } from 'react';

import {
  OPENING_MODAL_ANIMATION_DELAY,
  pageSizes,
  STANDARD_PAGE_SIZE,
  TableRowGrid,
} from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import TableRow from 'components/table/tableRow/TableRow';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';

import { ACCOUNTS_SORT_BY_FE, ReferrerAccount } from '../../models';

import ReferrerDetailsModal from './ReferrerDetailsModal';

type ReferrerTableProps = {
  data: ReferrerAccount[];
  sortByListItemIndex: number;
  setDataForEditing: (data: ReferrerAccount) => void;
  sortAccounts: (index: number) => void;
  deleteItem: (id: string) => void;
  openEditingModalWindow: () => void;
};

const ReferrerTable = ({
  data,
  deleteItem,
  sortAccounts,
  setDataForEditing,
  sortByListItemIndex,
  openEditingModalWindow,
}: ReferrerTableProps) => {
  const [dataForModal, setDataForModal] = useState<ReferrerAccount>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePageSize, setActivePageSize] = useState(STANDARD_PAGE_SIZE);
  const [activePage, setActivePage] = useState(1);

  const dataAmount = useMemo(() => data.length, [data]);
  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, activePageSize),
    [activePageSize, dataAmount]
  );

  const closeModalWindow = useCallback(() => setIsModalOpen(false), []);

  const openModalWindow = useCallback(
    (data: ReferrerAccount) =>
      setTimeout(() => {
        setIsModalOpen(true);
        setDataForModal(data);
      }, OPENING_MODAL_ANIMATION_DELAY),
    []
  );

  return (
    <>
      <TableInfo
        pageSizes={pageSizes}
        dataAmount={dataAmount}
        activePageSize={activePageSize}
        changePageSize={setActivePageSize}
      />
      <TableContainer>
        <TableHeader
          sortData={sortAccounts}
          tableName={TableRowGrid.Account}
          sortByListItemIndex={sortByListItemIndex}
          parametersForSorting={ACCOUNTS_SORT_BY_FE}
        />
        <div>
          {data.map((item) => {
            const name = `${item.firstName} ${item.lastName}`;

            return (
              <TableRow
                key={item.id}
                tableName={TableRowGrid.Account}
                data={[name, item.category, item.status]}
                openModalWindow={() => openModalWindow(item)}
                temporaryStatus={item.temporaryStatus}
              />
            );
          })}
        </div>
        {isModalOpen && dataForModal && (
          <ReferrerDetailsModal
            dataLength={data ? data.length : 0}
            data={dataForModal}
            setDataForEditing={setDataForEditing}
            deleteItem={deleteItem}
            closeWindow={closeModalWindow}
            openEditingModalWindow={() => {
              openEditingModalWindow();
              closeModalWindow();
            }}
          />
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={activePage}
        maxPages={maxPages}
        changeActivePage={setActivePage}
      />
    </>
  );
};

export default ReferrerTable;
