import theme from 'theme';

export enum DashboardCategory {
  Users = 'USERS',
  Offers = 'OFFERS',
  Accounts = 'ACCOUNTS',
}

export type RatingResult = 'rises' | 'stay' | 'falls';

export const RATING_COLORS: Record<RatingResult, string> = {
  rises: theme.colors.greenApple,
  stay: theme.colors.lightRasberry,
  falls: theme.colors.cherry,
};
