import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';

import Accounts from 'modules/accounts/pages/Accounts';
import ReferrerRegister from 'modules/auth/pages/ReferrerRegister';
import Dashboard from 'modules/dashboard/pages/Dashboard';
import Offers from 'modules/offers/pages/Offers';
import Users from 'modules/users/pages/Users';

import AdminPrivateRoute from './AdminPrivateRoute';
import { AdminRoutes } from './models';

const AdminRoute = () => (
  <>
    <Helmet>
      <meta
        name="description"
        content="Admin panel for managing offers, users and accounts"
      />
      <title>Admin portal</title>
    </Helmet>
    <Routes>
      <Route
        path={AdminRoutes.ReferrerRegister}
        element={<ReferrerRegister />}
      />
      <Route
        path={AdminRoutes.Accounts}
        element={
          <AdminPrivateRoute>
            <Accounts />
          </AdminPrivateRoute>
        }
      />
      <Route
        path={AdminRoutes.Dashboard}
        element={
          <AdminPrivateRoute>
            <Dashboard />
          </AdminPrivateRoute>
        }
      />
      <Route
        path={AdminRoutes.Offers}
        element={
          <AdminPrivateRoute>
            <Offers />
          </AdminPrivateRoute>
        }
      />
      <Route
        path={AdminRoutes.Users}
        element={
          <AdminPrivateRoute>
            <Users />
          </AdminPrivateRoute>
        }
      />

      <Route
        path="*"
        element={<Navigate to={AdminRoutes.Accounts} replace />}
      />
    </Routes>
  </>
);

export default AdminRoute;
