import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import EmployersFooter from 'components/EmployersFooter';

import Landing from '../modules/landing/Landing';
import Manage from '../modules/manage/Manage';

export enum OnboardingNavigation {
  LandingPage = 'Landing page',
  ManageUsers = 'Manage users',
}

export const arrayOnNavigationItems = [
  OnboardingNavigation.LandingPage,
  OnboardingNavigation.ManageUsers,
];

const Onboarding = () => {
  const [activeNavigationItem, setActiveNavigationItem] = useState(
    OnboardingNavigation.LandingPage
  );

  const chooseActiveItem = useCallback((i: number) => {
    setActiveNavigationItem(arrayOnNavigationItems[i]);
  }, []);

  return (
    <Container>
      {activeNavigationItem === OnboardingNavigation.LandingPage ? (
        <Landing
          chooseActiveItem={chooseActiveItem}
          activeNavigationItem={activeNavigationItem}
        />
      ) : (
        <Manage
          chooseActiveItem={chooseActiveItem}
          activeNavigationItem={activeNavigationItem}
        />
      )}
      <FooterWrapper>
        <EmployersFooter />
      </FooterWrapper>
    </Container>
  );
};

const FooterWrapper = styled.div`
  margin-top: 74px;
`;

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Onboarding;
