import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import EmployersFooter from 'components/EmployersFooter';
import { ContentInfo } from 'modules/auth/models';
import NavigationItem from 'modules/onboarding/components/NavigationItem';

import ContentBar from '../components/ContentBar';
import Courses from '../components/courses/Courses';
import Modules from '../components/modules/Modules';
import { ContentType, ONE_DAY } from '../models';

const NAV_ITEMS = [ContentType.Modules, ContentType.Courses];

const Content = () => {
  const [activeTab, setActiveTab] = useState(ContentType.Modules);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const appliedDate = useMemo(() => {
    if (isModalOpen) return;

    return JSON.parse(
      localStorage.getItem(
        activeTab === ContentType.Courses
          ? ContentInfo.DateOfSelectingCourses
          : ContentInfo.DateOfSelectingTopics
      ) as string
    ) as number;
  }, [isModalOpen, activeTab]);

  const diffDays = useMemo(() => {
    if (!appliedDate) return;

    // count how many days have passed since user applied topics
    const diff = Math.abs(appliedDate - new Date().getTime());
    const diffDays = Math.ceil(diff / ONE_DAY);

    return diffDays;
  }, [appliedDate]);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Container>
      <ContentBar openModalWindow={toggleModal} diffDays={diffDays} />
      <Navigation>
        {NAV_ITEMS.map((item, i) => (
          <React.Fragment key={item}>
            {i !== 0 && <VerticalLine />}
            <NavigationItem
              text={item}
              active={activeTab === item}
              chooseActiveItem={() => setActiveTab(item)}
            />
          </React.Fragment>
        ))}
      </Navigation>
      {activeTab === ContentType.Modules && (
        <Modules
          appliedDate={appliedDate}
          diffDays={diffDays}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      )}
      {activeTab === ContentType.Courses && (
        <Courses
          appliedDate={appliedDate}
          diffDays={diffDays}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      )}
      <FooterWrapper>
        <EmployersFooter />
      </FooterWrapper>
    </Container>
  );
};

const FooterWrapper = styled.div`
  margin-top: 79px;
`;

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

const VerticalLine = styled.div`
  height: 20px;
  width: 0.5px;
  opacity: 0.4;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Navigation = styled.div`
  padding: 85px 19px 18px;
  display: flex;
  align-items: center;
  position: relative;
  top: 4px;
`;

export default Content;
