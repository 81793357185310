import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import RadioButton from 'components/buttons/RadioButton';
import { HorizontalLine } from 'components/Divider';
import { AccountCategory } from 'modules/accounts/models';
import { OfferCategory } from 'modules/offers/models';

import { SubmitButtonContainer } from '../styledElements/formsStyledElements';
import { Title } from '../styledElements/stagesStyledElements';

type Category<T> = {
  name: string;
  text: string;
  icon: JSX.Element;
  category: T;
};

type SelectCategoryProps<T> = {
  accountCategory: string;
  categories: Category<T>[];
  saveData: (accountCategory: T) => void;
  goToNextStage: () => void;
};

const SelectCategory = <T extends AccountCategory | OfferCategory>({
  saveData,
  accountCategory,
  categories,
  goToNextStage,
}: SelectCategoryProps<T>) => {
  return (
    <div>
      <Title>Select type</Title>
      {categories.map(({ name, text, category, icon }) => {
        const isActive = category === accountCategory;

        return (
          <SelectCategoryItem
            data-testid="category-item"
            onClick={() => saveData(category)}
            $isActive={isActive}
            key={name}
          >
            <SelectCategoryItemName>
              <RadioButton
                fontSize="md"
                textColor={theme.colors.funtainBlue}
                parameter={name}
                isActive={isActive}
              />
              {icon}
            </SelectCategoryItemName>
            <HorizontalLineWithGap $color={theme.colors.quillGray} />
            <Text>{text}</Text>
          </SelectCategoryItem>
        );
      })}
      <SubmitButtonContainer>
        <DefaultButton
          text="next"
          textColor={theme.colors.white}
          isActive
          withShadow
          buttonColor={theme.colors.funtainBlue}
          isUppercase
          buttonSize="md"
          borderSize="md"
          clickHandler={goToNextStage}
        />
      </SubmitButtonContainer>
    </div>
  );
};

const HorizontalLineWithGap = styled(HorizontalLine)`
  margin: 19px 0;
`;

const SelectCategoryItem = styled.div<{ $isActive?: boolean }>`
  height: 120px;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  border-radius: 4px;
  display: block;
  padding: 18px 20px 24px;
  margin-top: 20px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.lightYellow};
    `}

  p {
    padding-top: 1px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 10px;
    height: 110px;
  }
`;

const SelectCategoryItemName = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  justify-content: space-between;

  svg {
    padding-top: 2px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
`;

export default SelectCategory;
