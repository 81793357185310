import { format } from 'date-fns';

import countries from 'components/listForSelectingData/dataForList/countries';
import { UserTableItem } from 'modules/users/models';

type UserDashboardGroup = { name: string; users: UserTableItem[] };

export enum UsersGroupBy {
  UserCreatedHours = 'USER_CREATED_HOURS',
  UserCreatedWeekDays = 'USER_CREATED_WEEK_DAYS',
  UserCreatedMonthDays = 'USER_CREATED_MONTH_DAYS',
  UserHasPhone = 'USER_HAS_PHONE',
  UserCountryCode = 'USER_COUNTRY_CODE',
}

export const groupUsers = (groupBy: UsersGroupBy, users: UserTableItem[]) =>
  users.reduce<UserDashboardGroup[]>((groups, user) => {
    let groupName: string;

    switch (groupBy) {
      case UsersGroupBy.UserCountryCode:
        groupName =
          countries.find(({ code }) => code === user.countryCode)
            ?.displayName || 'none';
        break;
      case UsersGroupBy.UserHasPhone:
        groupName = user.phoneNumber ? 'Yes' : 'No';
        break;
      case UsersGroupBy.UserCreatedMonthDays:
        groupName = format(new Date(`${user.createdDateTime}+0000`), 'MMM do');
        break;
      case UsersGroupBy.UserCreatedWeekDays:
        groupName = format(new Date(`${user.createdDateTime}+0000`), 'EEEE do');
        break;
      case UsersGroupBy.UserCreatedHours:
      default:
        groupName = format(new Date(`${user.createdDateTime}+0000`), 'h:00 a');
        break;
    }

    const userGroup = groups.find((fGroup) => fGroup.name === groupName);

    if (!userGroup) {
      return [...groups, { name: groupName, users: [user] }];
    }

    userGroup.users.push(user);

    return groups;
  }, []);
