import { AxiosProgressEvent } from 'axios';
import { useCallback } from 'react';

import { ImageDraftPayloadPromotion } from '../offersServices/PromotionOffersService';

type useSaveImageProps = {
  offerId: string;
  uploadLogo: (data: ImageDraftPayloadPromotion) => Promise<void>;
  setPending: (value: boolean) => void;
  setImageFile: (file?: File) => void;
  setImage: (value: string) => void;
};

const useSaveImage = ({
  offerId,
  uploadLogo,
  setPending,
  setImageFile,
  setImage,
}: useSaveImageProps) => {
  const saveImage = useCallback(
    async (
      imageURL: string,
      toggleFormView: () => void,
      openError: (value: boolean) => void,
      setErrorText: (value: string) => void,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      if (file) {
        setPending(true);
        const options = {
          onUploadProgress: (progress: AxiosProgressEvent) => {
            const { loaded, total } = progress;

            if (textWithPercentages && total) {
              textWithPercentages.textContent = `${Math.round(
                (loaded / total) * 100
              )}%`;
            }
          },
        };
        const formData = new FormData();

        formData.append('payload', file);

        const uploadedData = {
          id: offerId,
          options,
          formData,
          openError,
          setErrorText,
          toggleFormView,
        };

        await uploadLogo(uploadedData);
        setImageFile(file);
        setPending(false);
      }
      setImage(imageURL);
      if (!imageURL) {
        setImageFile();
      }
    },
    [offerId, setImage, setImageFile, setPending, uploadLogo]
  );

  return saveImage;
};

export default useSaveImage;
