import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { checkValidAdminEmail } from 'modules/auth/AuthService';
import Header from 'modules/auth/components/Header';
import {
  AuthStage,
  CustomTrackEvent,
  emailValidationSchema,
} from 'modules/auth/models';

import EmailFormTemplate from '../../../forms/EmailFormTemplate';

const SIGN_IN_EMAIL_INPUT_ID = 'email';

type AdminEmailFormProps = {
  goToNextStage: (email: string, lastTwoDigitsOnMobile: string) => void;
};

const AdminEmailStage = ({ goToNextStage }: AdminEmailFormProps) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
  });
  const appInsights = useAppInsightsContext();
  const trackInvalidEmail = useTrackEvent(
    appInsights,
    CustomTrackEvent.InvalidEmail,
    {}
  );

  const { isLoading, mutate } = useMutation(checkValidAdminEmail, {
    onSuccess: ({ mobileNumberLastDigits }) =>
      goToNextStage(watch()[SIGN_IN_EMAIL_INPUT_ID], mobileNumberLastDigits),
    onError: () => {
      setError(SIGN_IN_EMAIL_INPUT_ID, {
        message: 'Email not recognised',
        type: 'invalid email',
      });
      trackInvalidEmail({ email: watch()[SIGN_IN_EMAIL_INPUT_ID] });
    },
  });

  return (
    <>
      <Header headerType={AuthStage.SignIn} />
      <EmailFormTemplate
        errors={errors}
        inputId={SIGN_IN_EMAIL_INPUT_ID}
        register={register}
        watch={watch}
        setValue={setValue}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        passEmailToValidate={mutate}
        isValid={isValid}
      />
    </>
  );
};

export default React.memo(AdminEmailStage);
