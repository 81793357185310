import React from 'react';

const DEFAULT_WIDTH = 80;
const DEFAULT_HEIGHT = 50;

type ShidoIconProps = {
  color: string;
  width?: number;
  height?: number;
};

const ShidoIcon = ({ color, width, height }: ShidoIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || DEFAULT_WIDTH}
    height={height || DEFAULT_HEIGHT}
    viewBox="0 0 80 50"
  >
    <path
      id="Path_1841"
      data-name="Path 1841"
      d="M721.541,484.533a24.543,24.543,0,0,0-34.958,0l-11.469,11.6a8.66,8.66,0,0,0-2.35,7.666,8.308,8.308,0,0,0,1.345,3.281,8.445,8.445,0,0,0,13.026,1.215l7.587-7.676a2.418,2.418,0,0,1,1.485-.738,2.288,2.288,0,0,1,1.846.676,2.364,2.364,0,0,1,0,3.31l-11.471,11.606a18.4,18.4,0,0,1-26.209,0,18.932,18.932,0,0,1,0-26.52,18.4,18.4,0,0,1,20.964-3.718.365.365,0,0,0,.418-.073l2.728-2.76c.437-.442.888-.864,1.35-1.271a.38.38,0,0,0-.054-.611A24.544,24.544,0,0,0,656,484.533a25.25,25.25,0,0,0,0,35.372,24.544,24.544,0,0,0,34.959,0L702.428,508.3a8.679,8.679,0,0,0-.838-12.908,8.322,8.322,0,0,0-4.089-1.706,8.414,8.414,0,0,0-7.094,2.452l-7.587,7.676a2.42,2.42,0,0,1-1.484.738,2.289,2.289,0,0,1-1.848-.676A2.347,2.347,0,0,1,678.82,502a2.474,2.474,0,0,1,.73-1.5l11.407-11.542a18.4,18.4,0,0,1,26.21,0,18.932,18.932,0,0,1,0,26.52A18.4,18.4,0,0,1,696.2,519.2a.366.366,0,0,0-.418.073l-2.727,2.76q-.653.661-1.349,1.273a.379.379,0,0,0,.054.611,24.539,24.539,0,0,0,29.778-4.008A25.25,25.25,0,0,0,721.541,484.533Z"
      transform="translate(-648.77 -477.219)"
      fill={color}
    />
  </svg>
);

export default ShidoIcon;
