import { AxiosError } from 'axios';

enum UserFriendlyErrorMessage {
  NoMessage = 'No message',
  CorporateAccountUniqueName = 'corporateAccount/corporate-account-name-is-not-unique',
  CorporateAccountInvalidMobile = 'corporateAccount/mobile-number-invalid',
}

const userFriendlyErrorMessages = {
  [UserFriendlyErrorMessage.NoMessage]: 'Something went wrong :(',
  [UserFriendlyErrorMessage.CorporateAccountUniqueName]:
    'Sorry, but corporate account name is not unique :(',
  [UserFriendlyErrorMessage.CorporateAccountInvalidMobile]:
    'Oops, the mobile phone number is invalid :(',
};

const createErrorMessage = (error: AxiosError) => {
  const { payload } = error.response?.data as {
    payload: {
      message: string;
    };
  };

  if (
    payload.message &&
    Object.values(UserFriendlyErrorMessage).includes(
      payload.message as UserFriendlyErrorMessage
    )
  ) {
    const errorMessage = payload.message as UserFriendlyErrorMessage;

    return userFriendlyErrorMessages[errorMessage];
  }

  return userFriendlyErrorMessages[UserFriendlyErrorMessage.NoMessage];
};

export default createErrorMessage;
