import React, { createContext, ReactNode, useCallback, useState } from 'react';

import {
  currencies,
  departmentVariant,
} from 'components/listForSelectingData/dataForLists';

import { CorporateSettings } from '../offersServices/CorporateOffersService';

type CorporateOfferContextType = {
  logoFile?: File;
  backgroundFile?: File;
  logoName: string;
  logoImage: string;
  backgroundName: string;
  backgroundImage: string;
  companyName: string;
  offerDraft: {
    settings: CorporateSettings;
    corporateAccountId: string;
  };
  setCompanyName: (companyName: string) => void;
  saveAccount: (id: string) => void;
  saveSettings: (settings: CorporateSettings) => void;
  setLogoName: (name: string) => void;
  setLogoImage: (image: string) => void;
  setBackgroundName: (name: string) => void;
  setBackgroundImage: (image: string) => void;
  setBackgroundFile: (value?: File) => void;
  setLogoFile: (value?: File) => void;
};

const initialValue: CorporateOfferContextType = {
  offerDraft: {
    settings: {
      includeDepartment: departmentVariant.Yes,
      publicCode: '',
      code: '',
      unitPriceCurrency: currencies[0].BEformat,
      unitPrice: 0,
      redemptionLimit: 0,
      startDateUTC: null,
      endDateUTC: null,
      offerTerm: '',
      notes: '',
    },
    corporateAccountId: '',
  },
  companyName: '',
  logoName: '',
  logoImage: '',
  backgroundName: '',
  backgroundImage: '',
  setCompanyName: () => null,
  setLogoName: () => null,
  setLogoImage: () => null,
  saveAccount: () => null,
  saveSettings: () => null,
  setBackgroundName: () => null,
  setBackgroundImage: () => null,
  setLogoFile: () => null,
  setBackgroundFile: () => null,
};

export const CorporateOfferContext =
  createContext<CorporateOfferContextType>(initialValue);

const CorporateOfferContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [offerDraft, setOfferDraft] = useState(initialValue.offerDraft);
  const [logoName, setLogoName] = useState('');
  const [logoImage, setLogoImage] = useState('');
  const [backgroundName, setBackgroundName] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [logoFile, setLogoFile] = useState<File>();
  const [backgroundFile, setBackgroundFile] = useState<File>();
  const [companyName, setCompanyName] = useState('');

  const saveAccount = useCallback(
    (id: string) => setOfferDraft({ ...offerDraft, corporateAccountId: id }),
    [offerDraft]
  );

  const saveSettings = useCallback(
    (settings: CorporateSettings) => {
      setOfferDraft({ ...offerDraft, settings });
    },
    [offerDraft]
  );

  return (
    <CorporateOfferContext.Provider
      value={{
        logoFile,
        backgroundFile,
        logoName,
        logoImage,
        setCompanyName,
        companyName,
        setLogoImage,
        setLogoName,
        setLogoFile,
        setBackgroundFile,
        backgroundName,
        backgroundImage,
        offerDraft,
        saveAccount,
        saveSettings,
        setBackgroundName,
        setBackgroundImage,
      }}
    >
      {children}
    </CorporateOfferContext.Provider>
  );
};

export default CorporateOfferContextProvider;
