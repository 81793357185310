import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import { Button } from 'components/detailsModal/styledElements/detailsModalStyledElements';
import {
  ButtonContainer,
  Header,
  IconWrapper,
  InformationContainer,
  InformationItem,
} from 'components/detailsModal/styledElements/informationStyledElements';
import { HorizontalLine } from 'components/Divider';
import EmailIcon from 'components/icons/EmailIcon';
import MobileIcon from 'components/icons/MobileIcon';
import { ModalContext } from 'contexts/ModalContext';
import { AdminRoutes } from 'modules/router/models';
import Input from 'react-phone-number-input/input';

import { MobileNumberInput } from '../../actionModal/styledElements/formsStyledElements';
import ActionAgreement from '../ActionAgreement';
import { StatusBE } from '../models';

type OffersInAccount = {
  activeOffersCount?: number;
  suspendedOffersCount?: number;
  expiredOffersCount?: number;
};

type PrimaryContact = {
  name: string;
  email: string;
  role: string;
  mobileNumber: string;
  mobileNumberCode: string;
};

type CorporateAccountInformationProps = {
  color: string;
  isChecked: boolean;
  offersInAccount: OffersInAccount;
  primaryContact: PrimaryContact;
  accountStatus: StatusBE;
  closeModal: () => void;
  activateAccount: () => void;
  toggleStatus: () => void;
};

const CorporateAccountInformation = ({
  color,
  isChecked,
  closeModal,
  toggleStatus,
  accountStatus,
  activateAccount,
  offersInAccount: {
    activeOffersCount,
    suspendedOffersCount,
    expiredOffersCount,
  },
  primaryContact: { name, email, role, mobileNumber, mobileNumberCode },
}: CorporateAccountInformationProps) => {
  const { setModalOpen } = useContext(ModalContext);
  const isAnyAccountOfferExist =
    !!activeOffersCount || !!suspendedOffersCount || !!expiredOffersCount;

  return (
    <>
      {accountStatus === StatusBE.Suspend ? (
        <div>
          <ActionAgreement
            note="All offers will be activated"
            isChecked={isChecked}
            actionTypeText="YES ACTIVATE THIS ACCOUNT"
            toggleStatus={toggleStatus}
          />
        </div>
      ) : (
        <>
          <InformationContainer>
            <Header>Primary contact</Header>
            <br />
            <StyledInformationItem>{name}</StyledInformationItem>
            <StyledInformationItem>
              <IconWrapper>
                <EmailIcon />
              </IconWrapper>
              {email}
            </StyledInformationItem>
            <StyledInformationItem>{role}</StyledInformationItem>
            <InformationItem>
              <IconWrapper>
                <MobileIcon color={theme.colors.white} width={10} height={16} />
              </IconWrapper>
              <Input
                inputComponent={MobileNumberInput}
                international
                disabled
                onChange={() => null}
                value={`${mobileNumberCode}${mobileNumber}`}
              />
            </InformationItem>
          </InformationContainer>
          <HorizontalLine />
          {isAnyAccountOfferExist && (
            <InformationContainer>
              <Header>Offers</Header>
              <br />
              {!!activeOffersCount && (
                <InformationItem>
                  <span>Active:</span> {activeOffersCount}
                </InformationItem>
              )}
              {!!suspendedOffersCount && (
                <InformationItem>
                  <span>Suspended:</span> {suspendedOffersCount}
                </InformationItem>
              )}
              {!!expiredOffersCount && (
                <InformationItem>
                  <span>Expired:</span> {expiredOffersCount}
                </InformationItem>
              )}
            </InformationContainer>
          )}
        </>
      )}
      <ButtonContainer $color={color}>
        {accountStatus === StatusBE.Suspend ? (
          <>
            <DefaultButton
              text="activate"
              textColor={color}
              isActive={isChecked}
              withShadow
              buttonColor={theme.colors.white}
              isUppercase
              buttonSize="md"
              borderSize="md"
              clickHandler={activateAccount}
            />
            <Button onClick={closeModal}>cancel</Button>
          </>
        ) : (
          <>
            <StyledNavLink to={`../${AdminRoutes.Offers}`}>
              <DefaultButton
                text="create offer"
                textColor={theme.colors.funtainBlue}
                isActive
                withShadow
                buttonColor={theme.colors.white}
                isUppercase
                buttonSize="md"
                borderSize="md"
                clickHandler={() => {
                  setModalOpen(true);
                }}
              />
            </StyledNavLink>

            <NavLink to={`../${AdminRoutes.Offers}`}>
              <Button>manage offers</Button>
            </NavLink>
          </>
        )}
      </ButtonContainer>
    </>
  );
};

const StyledInformationItem = styled(InformationItem)`
  word-break: break-word;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export default CorporateAccountInformation;
