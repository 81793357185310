import { find } from 'lodash';
import { useEffect } from 'react';

import countries from 'components/listForSelectingData/dataForList/countries';

import { User } from '../models';
import { UserAccountAttributes } from '../usersServices/usersAccountsService';

const useSaveDataForEditUser = (
  user: User,
  setSaved: (value: boolean) => void,
  setIsCodeActive: (value: boolean) => void,
  saveSettings: (data: UserAccountAttributes, countryAbbr?: string) => void,
  saved?: boolean
) => {
  useEffect(() => {
    if (!saved) {
      const {
        userName: firstName,
        email,
        phoneNumber,
        countryCode,
        createdOn,
        term,
      } = user;
      // temporary solution, in the future 'countryCode' will always has value

      const countryCodeNew = countryCode ? countryCode : '+93';

      if (createdOn) {
        setIsCodeActive(true);
      }

      saveSettings(
        {
          firstName,
          email,
          phoneNumber,
          countryCode: countryCodeNew,
          passcode: '******',
          createdOn: createdOn ? new Date(createdOn) : undefined,
          term,
        },
        find(countries, { code: countryCodeNew })?.abbr
      );
      setSaved(true);
    }
  }, [saveSettings, saved, setIsCodeActive, setSaved, user]);
};

export default useSaveDataForEditUser;
