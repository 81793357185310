import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export let reactPlugin: ReactPlugin | null = null;
export let appInsights: ApplicationInsights | null = null;

export const initializeAppInsights = (instrumentationKey: string) => {
  if (!instrumentationKey) {
    throw new Error(
      'Could not initialize App Insights: `instrumentationKey` not provided'
    );
  }

  reactPlugin = new ReactPlugin();

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();
};

export const handleRouteChange = (url: string) => {
  if (!reactPlugin) {
    return;
  }

  reactPlugin.trackPageView({
    uri: url,
  });
};
