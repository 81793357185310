/* eslint-disable react/no-array-index-key */
import React from 'react';

import AdvancedButton from 'components/buttons/AdvancedButton';
import PinkLockIcon from 'components/icons/PinkLockIcon';

import { TABLE_COLUMNS, TableRowGrid, TemporaryStatus } from '../models';
import {
  Container,
  IconWrapper,
  Name,
  PublishedLabel,
  SpecialContainer,
  StyledLink,
  Text,
} from '../styledElements/tableStyledElements';

import TemporaryNotification from './TemporaryNotification';

type Props = {
  isPublished?: boolean;
  href?: string;
  middleUpperCase?: boolean;
  special?: boolean;
  data: (string | number)[];
  tableName: TableRowGrid;
  temporaryStatus?: TemporaryStatus;
  editOnFirstColumn?: boolean;
  openModalWindow: () => void;
};

const TableRow = ({
  isPublished,
  href,
  middleUpperCase,
  special,
  data,
  tableName,
  temporaryStatus,
  editOnFirstColumn = false,
  openModalWindow,
}: Props) => {
  const displayFirstColumn = (item: string | number) => {
    if (href) {
      return (
        <StyledLink special={special}>
          <a target="_blank" href={href} rel="noreferrer">
            {item}
          </a>
          {special && <SpecialContainer>PRIMARY</SpecialContainer>}
          {isPublished && <PublishedLabel>PUBLISHED!</PublishedLabel>}
          {temporaryStatus && (
            <TemporaryNotification temporaryStatus={temporaryStatus} />
          )}
        </StyledLink>
      );
    }

    return (
      <Name $special={special}>
        <p
          onClick={() => {
            if (editOnFirstColumn) {
              openModalWindow();
            }
          }}
          style={{ cursor: editOnFirstColumn ? 'pointer' : 'auto' }}
        >
          {item}
        </p>
        {special && <SpecialContainer>PRIMARY</SpecialContainer>}
        {temporaryStatus && (
          <TemporaryNotification temporaryStatus={temporaryStatus} />
        )}
      </Name>
    );
  };

  return (
    <Container
      className="row"
      data-testid={`${data[0]}-table-row`}
      style={{ gridTemplateColumns: TABLE_COLUMNS[tableName] }}
    >
      {data.map((item, index) => (
        <React.Fragment key={`row-${item}-${index}`}>
          {index === 0 ? (
            displayFirstColumn(item)
          ) : (
            <Text
              $middleUpperCase={middleUpperCase}
              $special={special}
              $withMargin={index === 2}
            >
              {item}
            </Text>
          )}
        </React.Fragment>
      ))}
      {special ? (
        <IconWrapper>
          <PinkLockIcon width={19} height={24} />
        </IconWrapper>
      ) : (
        <AdvancedButton clickHandler={openModalWindow} />
      )}
    </Container>
  );
};

export default TableRow;
