import React from 'react';
import styled from 'styled-components';

import Logo from './Logo';

const DesktopFooter = () => {
  return (
    <Container>
      <Logo withMark width={68} height={42} />
      <Link href="https://www.shido.com/">SHIDO.COM</Link>
    </Container>
  );
};

const Container = styled.footer`
  margin-bottom: 80px;
  color: ${({ theme }) => theme.colors.primary};
  display: grid;
  justify-content: center;
  text-align: center;
`;

const Link = styled.a`
  margin-top: 15px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

export default DesktopFooter;
