import React from 'react';

type DashedCircleIconV2Props = {
  size: number;
  color: string;
};

const DashedCircleIconV2 = ({ size, color }: DashedCircleIconV2Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 82 82"
  >
    <g
      id="Group_6289"
      data-name="Group 6289"
      transform="translate(81 1) rotate(90)"
    >
      <path
        id="Path_688"
        data-name="Path 688"
        d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeDasharray="5 25 80"
      />
    </g>
  </svg>
);

export default DashedCircleIconV2;
