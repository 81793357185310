import config from 'config';
import {
  Asset,
  createClient,
  ContentTypeLink,
  Entry,
  EntryFields,
} from 'contentful';

type TopicTypeResponse = {
  title: string;
  coverImage: Asset;
  numberOfContent?: number;
  sys: {
    id: string;
  };
};

type LessonTypeResponse = Entry<{
  title: EntryFields.Text;
  category: {
    sys: ContentTypeLink;
  };
  contentResources: Asset[];
  coverImage: Asset;
  description: EntryFields.Text;
  expert: {
    sys: ContentTypeLink;
  };
  length: EntryFields.Text;
  video: Asset;
}>;

type CourseTypeResponse = {
  title: string;
  description: string;
  length: string;
  introVideo: Asset;
  lesson: (
    | {
        sys: ContentTypeLink;
      }
    | LessonTypeResponse
  )[];
};

const useContentful = () => {
  const client = createClient({
    space: config.contentfulSpaceId as string,
    accessToken: config.contentfulAccessToken as string,
  });

  const getCategories = () => {
    return client.getEntries<TopicTypeResponse>({
      // eslint-disable-next-line camelcase
      content_type: 'newCategorySeries',
      'fields.category.sys.id': '7G202j0jzau3XB2oxepfBA',
    });
  };

  const getCourses = () => {
    return client.getEntries<CourseTypeResponse>({
      // eslint-disable-next-line camelcase
      content_type: 'courses',
      'fields.lesson[exists]': true,
    });
  };

  return {
    getCategories,
    getCourses,
  };
};

export default useContentful;
