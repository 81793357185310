import { ManageUserOnboardingItem } from './models';

const manageUserMockData: ManageUserOnboardingItem[] = [
  {
    id: 1,
    name: 'Dan McPeake',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 2,
    name: 'Suzy Southam',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 3,
    name: 'Steve Jackson',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 4,
    name: 'Anthony Joshua',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 5,
    name: 'Phil Spencer',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 6,
    name: 'Lochiel Kay',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 7,
    name: 'Leila Rodgers',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 8,
    name: 'Edwin Ulak',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 9,
    name: 'Catherine Miller',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 10,
    name: 'Harris Trigger',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 11,
    name: 'Trish Collins',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 12,
    name: 'Suzy Shellerton',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 13,
    name: 'Camden Cosworth',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 14,
    name: 'Rachel Massey',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 15,
    name: 'Chris Hooper',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 16,
    name: 'Fiona Fox',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 17,
    name: 'Chad Cribbins',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 18,
    name: 'Jason Moorehouse',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 19,
    name: 'Nicky Gawith',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
  {
    id: 20,
    name: 'Catherine Miller',
    photo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Shaqi_jrvej.jpg/1200px-Shaqi_jrvej.jpg',
    email: 'name@gmail.com',
    department: 'Accounting',
    sex: 'Male',
    age: '21',
    offerCode: 'J4NSH63',
  },
];

export default manageUserMockData;
