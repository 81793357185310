import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ActiveGraphDot from 'components/icons/ActiveGraphDot';
import GraphBackgroundIcon from 'components/icons/GraphBackgroundIcon';
import GraphDot from 'components/icons/GraphDot';

type Props<DataKey extends string> = {
  lines: {
    name: DataKey;
    color: string;
    dotColor?: string;
    activeDotColor?: string;
  }[];
  data: (Record<'name', string> & Record<DataKey, number>)[];
};

const DashboardLineChart = <DataKey extends string>({
  data,
  lines,
}: Props<DataKey>) => (
  <ResponsiveContainer>
    <LineChart
      data={data}
      margin={{
        left: -61,
        bottom: -20,
        top: 20,
        right: -1,
      }}
    >
      <CartesianGrid />
      <ReferenceArea shape={<GraphBackgroundIcon />} />
      <XAxis
        dataKey="name"
        axisLine={false}
        tick={false}
        tickLine={false}
        padding={{ left: 25, right: 25 }}
      />
      <YAxis axisLine={false} tickLine={false} tick={false} />
      <Tooltip
        labelStyle={{ fontSize: '14px' }}
        allowEscapeViewBox={{ y: true }}
        position={{ y: -35 }}
      />
      {lines.map((line) => (
        <Line
          key={line.name}
          dataKey={line.name}
          stroke={line.color}
          dot={
            <GraphDot
              color={line.color}
              innerColor={line.dotColor || line.color}
            />
          }
          activeDot={
            <ActiveGraphDot color={line.activeDotColor || line.color} />
          }
        />
      ))}
    </LineChart>
  </ResponsiveContainer>
);

export default DashboardLineChart;
