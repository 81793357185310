import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import theme from 'theme';

import PlayIcon from 'components/icons/PlayIcon';
import ShidoIcon from 'components/icons/ShidoIcon';
import ShidoTextIcon from 'components/icons/ShidoTextIcon';
import { ListItemData } from 'helpers/filterDataForDropdownList';

import FormContainer from './FormContainer';
import {
  Footer,
  BroughtToYou,
  Container,
  Notification,
  EnteringOfferCode,
  MainContent,
  PlayButtonContainer,
  DashedLineContainer,
  Header,
  ShidoLink,
  RegisterSymbol,
  ShidoSlogan,
  ShidoExplanation,
  SuggestionToWatchVideo,
} from './StyledElements';

type DesktopPreviewProps = {
  isShowDepartment?: boolean;
  data: ListItemData[];
  companyName: string;
  logo: string;
  offerTerm: string;
  background?: string;
};

const DesktopPreview = ({
  isShowDepartment,
  data,
  companyName,
  logo,
  offerTerm,
  background,
}: DesktopPreviewProps) => (
  <Container>
    <DesktopHeader>
      <ShidoLink>
        <ShidoIcon width={88} height={52} color={theme.colors.primary} />
        <ShidoTextIcon color={theme.colors.primary} />
        <RegisterSymbol $color={theme.colors.primary}>®</RegisterSymbol>
      </ShidoLink>
    </DesktopHeader>
    <DesktopContent>
      <MainContent $backgroundImg={background}>
        <EnteringOfferCode>
          <BroughtToYou>Brought to you by {companyName}</BroughtToYou>
          <Notification>Receive {offerTerm}!</Notification>
          <FormContainer isShowDepartment={isShowDepartment} data={data} />
        </EnteringOfferCode>
      </MainContent>
      <ShidoSlogan $isDesktop>
        Helping you be your best & achieve optimal health.
      </ShidoSlogan>
      <ShidoExplanation $isDesktop>
        Shido connects all aspects of your health in a single place, from
        nutrition to motivation & condition management, we have an entire team
        dedicated to providing you with advice & inspiration targeted to your
        specific needs, interests & goals.
      </ShidoExplanation>
      <SuggestionToWatchVideo>see how</SuggestionToWatchVideo>
      <PlayButtonContainer>
        <PlayIcon />
      </PlayButtonContainer>
      <DashedLineContainer>
        <hr />
      </DashedLineContainer>
      <Footer $isDesktop>
        {logo && (
          <>
            <ReactSVG
              fallback={() => <img src={logo} alt="logo" />}
              src={logo}
            />
          </>
        )}
      </Footer>
    </DesktopContent>
  </Container>
);

const DesktopHeader = styled(Header)`
  left: 49%;

  div {
    padding-left: 7px;
    margin-top: 17px;

    svg:first-child {
      margin-right: 25px;
    }

    div:last-child {
      font-size: 17px;
      right: -16px;
      padding-left: 4px;
    }
  }
`;

const DesktopContent = styled.div`
  width: 900px;
  min-height: 1178px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 20px 40px rgb(0 0 0 / 40%);
  margin: 0 20px;
  border-radius: 5px;
`;

export default DesktopPreview;
