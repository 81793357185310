import { useEffect } from 'react';

import usePrevious from 'hooks/usePrevious';

export type UseReloadImageArguments = {
  saveImage: (
    urlImg: string,
    toggleFormView: () => void,
    openError: (value: boolean) => void,
    setErrorText: (value: string) => void,
    textWithPercentages?: HTMLParagraphElement | null,
    file?: File
  ) => void;
  image: string;
  setLogoError: (isError: boolean) => void;
  setErrorText: (error: string) => void;
  imageFile?: File;
  isEdit?: boolean;
  isShowStage: boolean;
  offerId: string;
};

const useReloadImage = ({
  saveImage,
  image,
  setLogoError,
  setErrorText,
  imageFile,
  isEdit,
  isShowStage,
  offerId,
}: UseReloadImageArguments) => {
  const previousOfferId = usePrevious(offerId);

  useEffect(() => {
    // we have to use this function once, when component is mounted to set image
    if (imageFile && !isEdit && isShowStage && previousOfferId !== offerId) {
      saveImage(image, () => null, setLogoError, setErrorText, null, imageFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowStage]);
};

export default useReloadImage;
