import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { HorizontalLine } from 'components/Divider';
import ParameterNavigation from 'components/ParameterNavigation';

type ModalNavigationProps = {
  parameters: string[];
  activeStatus: string;
  setActiveStatus: (value: number) => void;
};

const ModalNavigation = ({
  parameters,
  activeStatus,
  setActiveStatus,
}: ModalNavigationProps) => (
  <>
    <Container>
      <h4>Status</h4>
      <ParameterNavigation
        radioButtonColor={theme.colors.white}
        parameters={parameters}
        activeParameter={activeStatus}
        setNewParameter={(index: number) => setActiveStatus(index)}
      />
    </Container>
    <HorizontalLine />
  </>
);

const Container = styled.div`
  margin: 32px 0 41px;

  h4 {
    font-size: 16px;
    margin-bottom: 19px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  }

  div:first-child {
    margin-left: 1px;
  }
`;

export default React.memo(ModalNavigation);
