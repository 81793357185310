import React from 'react';

type GarbageIconProps = {
  color: string;
};

const GarbageIcon = ({ color }: GarbageIconProps) => (
  <svg
    data-testid="garbage-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="22"
    height="24"
    viewBox="0 0 22 24"
  >
    <defs>
      <clipPath id="garbage-clip-path">
        <path
          id="Path_6920"
          data-name="Path 6920"
          d="M18.5-16.5H3.5A.5.5,0,0,0,3-16V-2A2,2,0,0,0,5,0H17a2,2,0,0,0,2-2V-16A.5.5,0,0,0,18.5-16.5ZM9.25-3.5a.75.75,0,0,1-.75.75.75.75,0,0,1-.75-.75v-9a.75.75,0,0,1,.75-.75.75.75,0,0,1,.75.75Zm5,0a.75.75,0,0,1-.75.75.75.75,0,0,1-.75-.75v-9a.75.75,0,0,1,.75-.75.75.75,0,0,1,.75.75Z"
          transform="translate(-3 16.5)"
          fill={color}
        />
      </clipPath>
      <clipPath id="garbage-clip-path-2">
        <path
          id="Path_6922"
          data-name="Path 6922"
          d="M21-20H16.25a.25.25,0,0,1-.25-.25V-21.5A2.5,2.5,0,0,0,13.5-24h-5A2.5,2.5,0,0,0,6-21.5v1.25a.25.25,0,0,1-.073.177A.25.25,0,0,1,5.75-20H1a1,1,0,0,0-1,1,1,1,0,0,0,1,1H21a1,1,0,0,0,1-1A1,1,0,0,0,21-20ZM8-20.25V-21.5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v1.25a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8-20.25Z"
          transform="translate(0 24)"
          fill={color}
        />
      </clipPath>
    </defs>
    <g id="Group_10913" data-name="Group 10913" transform="translate(0 24)">
      <g
        id="Group_6240"
        data-name="Group 6240"
        transform="translate(3 -16.499)"
        clipPath="url(#garbage-clip-path)"
      >
        <path
          id="Path_6919"
          data-name="Path 6919"
          d="M-2-21.5H24V5H-2Z"
          transform="translate(-3 16.5)"
          fill={color}
        />
      </g>
      <g
        id="Group_6241"
        data-name="Group 6241"
        transform="translate(0 -24)"
        clipPath="url(#garbage-clip-path-2)"
      >
        <path
          id="Path_6921"
          data-name="Path 6921"
          d="M-5-29H27v16H-5Z"
          transform="translate(0 24)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default GarbageIcon;
