import React from 'react';

const SendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.002"
    height="21.999"
    viewBox="0 0 24.002 21.999"
  >
    <defs>
      <clipPath id="send">
        <path
          data-name="Path 19020"
          d="M23.82-21.884a.5.5 0 0 0-.512-.078l-23 9.5A.5.5 0 0 0 0-12a.5.5 0 0 0 .318.46L6.65-9.076a.5.5 0 0 0 .468-.056l8.4-5.974a.5.5 0 0 1 .678.086.5.5 0 0 1-.041.682l-7 6.745a.5.5 0 0 0-.155.36V-.5a.5.5 0 0 0 .369.481.5.5 0 0 0 .562-.229L13.1-5.673a.251.251 0 0 1 .337-.093l5.826 3.2a.5.5 0 0 0 .448.017.5.5 0 0 0 .282-.349l4-18.5a.508.508 0 0 0-.173-.486Z"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g data-name="Group 16717">
      <g
        data-name="Group 16716"
        clipPath="url(#send)"
        transform="translate(0 22)"
      >
        <path data-name="Path 19019" d="M-5-27h34V5H-5Z" fill="#fff" />
      </g>
    </g>
  </svg>
);

export default SendIcon;
