import React from 'react';
import styled from 'styled-components';

import {
  filterElements,
  monthsData,
  percentageData,
  percentageDataV2,
} from '../mockData/mockData';

import GraphCard, { ResultRating } from './GraphCard';

type TeamHealthProps = {
  activeMonth: string;
};

const TeamHealth = ({ activeMonth }: TeamHealthProps) => {
  return (
    <Wrapper>
      <TeamHealthTitle>Team health check</TeamHealthTitle>
      <FilterElementsWrapper>
        {filterElements.map(({ option, result }) => {
          return (
            <FilterElement key={option}>
              <OptionTitle>{option}</OptionTitle>:
              <FilterElementResult>{result}</FilterElementResult>
            </FilterElement>
          );
        })}
      </FilterElementsWrapper>
      <GraphCardsWrapper>
        <GraphCard
          activeMonth={activeMonth}
          title="Energy levels"
          months={monthsData}
          percentageData={percentageData}
          rating={ResultRating.Good}
          ratingNumber="+20"
        />
        <GraphCard
          activeMonth={activeMonth}
          title="Stress levels"
          months={monthsData}
          percentageData={percentageDataV2}
          rating={ResultRating.Bad}
          ratingNumber="-30"
        />
        <GraphCard
          activeMonth={activeMonth}
          title="Life balance"
          months={monthsData}
          percentageData={percentageData}
          rating={ResultRating.Normal}
          ratingNumber="+10"
        />
      </GraphCardsWrapper>
    </Wrapper>
  );
};

const OptionTitle = styled.p`
  font-size: 12px;
  font-weight: 400px;
  color: ${({ theme }) => theme.colors.white};
`;

const GraphCardsWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  flex-wrap: wrap;
  gap: 20px 40px;
`;

const TeamHealthTitle = styled.div`
  font-weight: 400;
  font-size: 40px;
  padding-top: 76px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const FilterElement = styled.div`
  height: 24px;
  background: rgb(226 216 216 / 18%);
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
`;

const FilterElementResult = styled.div`
  font-weight: 700;
`;
const FilterElementsWrapper = styled.div`
  padding-top: 15px;
  display: flex;
  margin: auto;
  column-gap: 10px;
  justify-content: center;
`;

const Wrapper = styled.div`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  min-height: 650px;
  background:
    bottom no-repeat url('/images/insights_background_additional.png'),
    ${({ theme }) => theme.colors.primary} 39% 31% / contain no-repeat
      url('/images/insights_background.png'); /* stylelint-disable */

  @media (max-width: 1283px) {
    background:
      bottom no-repeat url('/images/insights_background_additional.png'),
      ${({ theme }) => theme.colors.primary} 39% 61% / contain no-repeat
        url('/images/insights_background.png');
  }

  @media (max-width: 855px) {
    background:
      bottom no-repeat url('/images/insights_background_additional.png'),
      ${({ theme }) => theme.colors.primary} 39% 75% / contain no-repeat
        url('/images/insights_background.png');
  }
`;

export default TeamHealth;
