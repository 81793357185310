import { StatusBE } from 'components/detailsModal/models';

import { CorporateOffer } from './offersServices/CorporateOffersService';

const corporateOfferMockData: CorporateOffer[] = [
  {
    id: 'de0e7538-da01-43ed-25c6-08da2c458858',
    code: 'BNBNBNBN',
    publicCode: "{base}' a='#%$&%^$^!5#(@($)(&%_%-48561",
    unitPriceCurrency: 'EUR',
    unitPrice: 43,
    redemptionLimit: 1111,
    redemptions: 2,
    startDateUTC: new Date(),
    endDateUTC: new Date(),
    offerTerm: 'ThreeMonths',
    logoUrl: '',
    backgroundUrl: '',
    status: StatusBE.Active,
    includeDepartment: '',
    createdDate: '2022-05-06T07:36:48.2918442',
    corporateAccountId: 'fcd10655-4b1f-4982-a939-08da2ccf569c',
    notes: 'DSFGDFKJLKJLKJGKLJFGDlkfgn 234213091    DSFGDFffff',
    companyName: '',
    corporateAccount: {
      id: 'fcd10655-4b1f-4982-a939-08da2ccf569c',
      companyName: "{base}' a='#%$&%^$^!5#(@($)(&%_%",
      website: 'dasdasdn.ds',
      businessSector: 'AdministrationAndOfficeSupport',
      country: 'Afghanistan',
      primaryContact: {
        firstName: 'ewf$^&%$@(%$)@*$&_@(%&+@+%!@%',
        lastName: 'gdfs@^%*^%@$&@^$)&!@_$^@_%_!@',
        role: 'dfg&^%$&@!%(%$!)@%$%@)$&#@%^',
        email: 'sdf@gmail.com',
        mobileNumberCode: '+93',
        mobileNumber: '412421345',
      },
      status: StatusBE.Active,
      createdDate: '2022-05-05T09:17:18.2290159',
    },
  },
  {
    id: '1176bed2-2749-4125-4d0f-08da318895b7',
    code: 'BVMBVYTQ',
    publicCode: "{base}' a='#%$&%^$^!5#(@($)(&%_%-23810",
    unitPriceCurrency: 'EUR',
    unitPrice: 43,
    redemptionLimit: 66,
    redemptions: 0,
    companyName: '',
    startDateUTC: new Date(),
    includeDepartment: '',
    endDateUTC: null,
    offerTerm: 'SixMonths',
    logoUrl: '',
    backgroundUrl: '',
    status: StatusBE.Active,
    createdDate: '2022-05-10T07:46:27.9416844',
    corporateAccountId: 'fcd10655-4b1f-4982-a939-08da2ccf569c',
    notes: '',
    corporateAccount: {
      id: 'fcd10655-4b1f-4982-a939-08da2ccf569c',
      companyName: "{base}' a='#%$&%^$^!5#(@($)(&%_%",
      website: 'dasdasdn.ds',
      businessSector: 'AdministrationAndOfficeSupport',
      country: 'Afghanistan',
      primaryContact: {
        firstName: 'ewf$^&%$@(%$)@*$&_@(%&+@+%!@%',
        lastName: 'gdfs@^%*^%@$&@^$)&!@_$^@_%_!@',
        role: 'dfg&^%$&@!%(%$!)@%$%@)$&#@%^',
        email: 'sdf@gmail.com',
        mobileNumberCode: '+93',
        mobileNumber: '412421345',
      },
      status: StatusBE.Active,
      createdDate: '2022-05-05T09:17:18.2290159',
    },
  },
  {
    id: 'cd57777f-61d2-4efc-88a9-08da3e6c41f4',
    code: 'VFDBFDMG',
    publicCode: "{base}' a='#%$&%^$^!5#(@($)(&%_%-16914",
    unitPriceCurrency: 'EUR',
    unitPrice: 543,
    redemptionLimit: 534,
    redemptions: 0,
    startDateUTC: new Date(),
    endDateUTC: null,
    companyName: '',
    offerTerm: 'ThreeMonths',
    includeDepartment: '',
    logoUrl: '',
    backgroundUrl: '',
    status: StatusBE.Active,
    createdDate: '2022-05-25T16:33:37.4164445',
    corporateAccountId: 'fcd10655-4b1f-4982-a939-08da2ccf569c',
    notes: '',
    corporateAccount: {
      id: 'fcd10655-4b1f-4982-a939-08da2ccf569c',
      companyName: "{base}' a='#%$&%^$^!5#(@($)(&%_%",
      website: 'dasdasdn.ds',
      businessSector: 'AdministrationAndOfficeSupport',
      country: 'Afghanistan',
      primaryContact: {
        firstName: 'ewf$^&%$@(%$)@*$&_@(%&+@+%!@%',
        lastName: 'gdfs@^%*^%@$&@^$)&!@_$^@_%_!@',
        role: 'dfg&^%$&@!%(%$!)@%$%@)$&#@%^',
        email: 'sdf@gmail.com',
        mobileNumberCode: '+93',
        mobileNumber: '412421345',
      },
      status: StatusBE.Active,
      createdDate: '2022-05-05T09:17:18.2290159',
    },
  },
];

export default corporateOfferMockData;
