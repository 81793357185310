import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';

import SearchForm from './SearchForm';

type SearchBarActionProps = {
  buttonText: string;
  nameToFilter: string;
  isHiddenButton?: boolean;
  openModalWindow: () => void;
  setNewNameToFilter: (value: string) => void;
};

const SearchBarAction = ({
  buttonText,
  nameToFilter,
  isHiddenButton,
  openModalWindow,
  setNewNameToFilter,
}: SearchBarActionProps) => (
  <Container>
    <SearchForm
      isHiddenButton={isHiddenButton}
      nameToFilter={nameToFilter}
      setNewNameToFilter={setNewNameToFilter}
    />
    {!isHiddenButton && (
      <ButtonWrapper>
        <DefaultButton
          isActive
          withShadow
          borderSize="md"
          buttonSize="md"
          text={buttonText}
          textColor={theme.colors.white}
          buttonColor={theme.colors.funtainBlue}
          clickHandler={openModalWindow}
        />
      </ButtonWrapper>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  position: relative;
  top: -1px;
  left: 1px;
  margin-left: 38px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 15px;
  }
`;

export default SearchBarAction;
