import React, { useState, useCallback } from 'react';

import ActionModal from 'components/actionModal/ActionModal';
import Result from 'components/actionModal/stages/Result';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';

import { DelegatesWindowStages, CreatableDelegateAttributes } from '../models';

import DelegateAccountDetails from './DelegateAccountDetails';

type EditDelegateAcountModalProps = {
  closeModalWindow: () => void;
  accessAccount: CreatableDelegateAttributes;
};

const EditAccessAccountModal = ({
  closeModalWindow,
  accessAccount,
}: EditDelegateAcountModalProps) => {
  const [result, setResult] = useState('');
  const [delegateItem, setDelegateAccountItem] = useState(accessAccount);
  const [activeStage, setActiveStage] = useState(
    DelegatesWindowStages.DelegatesDetails
  );

  const goToResultStage = useCallback(() => {
    setActiveStage(DelegatesWindowStages.Result);
  }, [setActiveStage]);

  const goToNextStep = useCallback(() => {
    setResult('Delegate account updated!');
    goToResultStage();
  }, [goToResultStage]);

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {activeStage === DelegatesWindowStages.Result ? (
        <Result title={result} closeModal={closeModalWindow} />
      ) : (
        <ActionModalMainContent>
          <DelegateAccountDetails
            title="Edit delegate account"
            goToNextStage={goToNextStep}
            delegateDetails={delegateItem}
            setDelegateAccountItem={setDelegateAccountItem}
          />
        </ActionModalMainContent>
      )}
    </ActionModal>
  );
};

export default EditAccessAccountModal;
