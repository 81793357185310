import React from 'react';

const UpRatingIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.012"
    height="9.512"
    viewBox="0 0 17.012 9.512"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 14441"
          d="M10.177-4.091a.25.25 0 0 1-.177.073.25.25 0 0 1-.177-.073L7.914-6a2 2 0 0 0-2.828 0L1.293-2.207a1 1 0 0 0-.273.973 1 1 0 0 0 .714.714 1 1 0 0 0 .973-.273l3.616-3.616a.25.25 0 0 1 .177-.073.25.25 0 0 1 .177.073L8.586-2.5a2 2 0 0 0 2.828 0l4.013-4.013a.25.25 0 0 1 .353 0l1.366 1.367A.5.5 0 0 0 17.5-5a.489.489 0 0 0 .191-.038A.5.5 0 0 0 18-5.5v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.463.309.5.5 0 0 0 .109.545l.867.866a.25.25 0 0 1 0 .353Z"
          fill="#8AB49C"
        />
      </clipPath>
    </defs>
    <g data-name="Group 15432">
      <g
        data-name="Group 15431"
        clipPath="url(#a)"
        transform="translate(-.988 10)"
      >
        <path
          data-name="Path 14440"
          d="M-4.012-15H23V4.512H-4.012Z"
          fill={color || '#8AB49C'}
        />
      </g>
    </g>
  </svg>
);

export default UpRatingIcon;
