import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $width: string;
  $isOutOfBounds?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ $width }) => $width};
  z-index: 10;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgb(0 0 0 / 60%);
  background-color: ${({ theme }) => theme.colors.white};

  @media (height <= 700px) {
    top: ${({ $isOutOfBounds }) => ($isOutOfBounds ? '-20px' : 0)};
  }
`;

export const ActionButtonContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 20px;
  transform: rotate(180deg);
`;

export const ListWrapper = styled.div<{
  $height: string;
  $isOutOfBounds?: boolean;
}>`
  height: ${({ $height }) => $height};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
    background: ${({ theme }) => theme.colors.gray};
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 40px;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.funtainBlue};
  }

  @media (height <= 730px) {
    ${({ $isOutOfBounds }) =>
      $isOutOfBounds &&
      css`
        height: 120px;
      `}
  }
`;
