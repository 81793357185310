import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { CorporateAccount } from 'modules/accounts/accountsServices/CorporateAccountsService';
import DashboardBarChart from 'modules/dashboard/components/DashboardBarChart';
import DashboardChip from 'modules/dashboard/components/DashboardChip';
import DashboardGraphCard from 'modules/dashboard/components/DashboardGraphCard';
import DashboardLineChart from 'modules/dashboard/components/DashboardLineChart';
import NoDataMessage from 'modules/dashboard/components/NoDataMessage';
import getAccountsStats, {
  AccountsStatName,
} from 'modules/dashboard/helpers/getAccountsStats';
import { getRatingBetween } from 'modules/dashboard/helpers/getRatingBetween';

type Props = {
  accounts?: CorporateAccount[];
};

const AccountsContent = ({ accounts }: Props) => {
  const [activeParams, setActiveParams] = useState<
    Record<AccountsStatName, number>
  >({
    businessSectors: 0,
    countries: 0,
    createdDates: 0,
  });

  const changeActiveParam = (param: AccountsStatName) => (value: number) =>
    setActiveParams({ ...activeParams, [param]: value });

  const { businessSectors, countries, createdDates } = useMemo(
    () => getAccountsStats(accounts || []),
    [accounts]
  );

  const activeBusinessSector =
    businessSectors[activeParams.businessSectors].groups;
  const activeCountries = countries[activeParams.countries].groups;
  const activeCreatedDate = createdDates[activeParams.createdDates].groups;

  const createdDatesRating = getRatingBetween(
    activeCreatedDate[0]?.accounts.length,
    activeCreatedDate[activeCreatedDate.length - 1]?.accounts.length
  );

  if (!accounts?.length) return <NoDataMessage />;

  return (
    <Wrapper>
      <HeaderSection>
        <Content>
          <HeaderTitle>Corporate Accounts</HeaderTitle>
          <SubTitleInfo>
            <DashboardChip>
              <ChipText>
                Last account created at:{' '}
                <span>
                  {format(
                    new Date(accounts[0].createdDate),
                    'yyyy-LL-dd hh:mm a'
                  )}
                </span>
              </ChipText>
            </DashboardChip>
          </SubTitleInfo>
          <GraphsContainer>
            <DashboardGraphCard
              title="Business Sectors"
              chart={
                <DashboardBarChart
                  data={activeBusinessSector.map(({ name, accounts }) => ({
                    name,
                    Count: accounts.length,
                  }))}
                  bars={[
                    {
                      name: 'Count',
                      color: theme.colors.lightBlue,
                    },
                  ]}
                />
              }
              params={{
                values: businessSectors.map(({ name }) => name),
                activeParam: activeParams.businessSectors,
                setActiveParam: changeActiveParam('businessSectors'),
              }}
            />
            <DashboardGraphCard
              title="Countries"
              chart={
                <DashboardBarChart
                  data={activeCountries.map(({ name, accounts }) => ({
                    name,
                    Count: accounts.length,
                  }))}
                  bars={[
                    {
                      name: 'Count',
                      color: theme.colors.lightBlue,
                    },
                  ]}
                />
              }
              params={{
                values: countries.map(({ name }) => name),
                activeParam: activeParams.countries,
                setActiveParam: changeActiveParam('countries'),
              }}
            />
            <DashboardGraphCard
              title="Accounts created"
              rating={{
                percentage: createdDatesRating.diffPercentage,
                result: createdDatesRating.result,
              }}
              chart={
                <DashboardLineChart
                  data={activeCreatedDate.map(({ name, accounts }) => ({
                    name,
                    Count: accounts.length,
                  }))}
                  lines={[
                    {
                      name: 'Count',
                      color: theme.colors.lightBlue,
                    },
                  ]}
                />
              }
              params={{
                values: createdDates.map(({ name }) => name),
                activeParam: activeParams.createdDates,
                setActiveParam: changeActiveParam('createdDates'),
              }}
            />
          </GraphsContainer>
        </Content>
      </HeaderSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GraphsContainer = styled.div`
  margin: 80px 0;
  display: flex;
  width: 100%;
  gap: 30px;
`;

const ChipText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};

  span {
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const SubTitleInfo = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 10px;
`;

const HeaderTitle = styled.h1`
  font-weight: 400;
  font-size: 40px;
  padding-top: 76px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const Content = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderSection = styled.section`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  min-height: 600px;
  background:
    url('/images/insights_background_additional.png') center bottom no-repeat,
    url('/images/insights_background.png') 39% 31% / contain no-repeat
      ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default AccountsContent;
