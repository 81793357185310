import React, { useEffect, useState } from 'react';
import { Animate } from 'react-move';

const TIME_DURATION_MULTIPLIER = 1000;

// Ease function for defines a transition between start and end values
// Resource: d3-ease package
const quadInOut = (t: number) =>
  // eslint-disable-next-line no-plusplus,no-mixed-operators
  ((t *= 2) <= 1 ? t * t : --t * (2 - t) + 1) / 2;

type Props = {
  valueEnd: number;
  duration: number;
  valueStart: number;
  startAnimation: boolean;
  children: (value: number) => React.ReactElement;
};

// The provider controls animation for circle bars
// Resource: react-circular-progressbar package
const AnimatedProgressProvider = ({
  duration,
  valueEnd,
  children,
  valueStart,
  startAnimation,
}: Props) => {
  const [durationMultiplier, setDurationMultiplier] = useState(
    TIME_DURATION_MULTIPLIER
  );
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (startAnimation) {
      setIsAnimated(true);
      setDurationMultiplier(TIME_DURATION_MULTIPLIER);
    }

    return () => {
      setIsAnimated(false);
      setDurationMultiplier(0);
    };
  }, [startAnimation]);

  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * durationMultiplier,
          ease: quadInOut,
        },
      })}
    >
      {({ value }) => children(value)}
    </Animate>
  );
};

export default AnimatedProgressProvider;
