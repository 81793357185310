import React from 'react';

const DotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="4"
    viewBox="0 0 4 4"
  >
    <circle
      id="Ellipse_304"
      data-name="Ellipse 304"
      cx="2"
      cy="2"
      r="2"
      fill="#69b0c7"
    />
  </svg>
);

export default DotIcon;
