import { isEqual } from 'lodash';
import { useEffect } from 'react';

const useCheckDataChanging = <T>(
  freezeSettingsPageActivate: (value?: boolean) => void,
  setDisableButton: (value: boolean) => void,
  isFormValid: boolean,
  prevData: T,
  settings: T,
  isEdit?: boolean
) => {
  useEffect(() => {
    if (isEdit) {
      if (isFormValid) {
        if (!prevData) {
          freezeSettingsPageActivate(true);
        } else {
          freezeSettingsPageActivate();
        }
        setDisableButton(false);
      } else {
        freezeSettingsPageActivate(true);
        setDisableButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isFormValid]);

  useEffect(() => {
    if (prevData && !isEdit) {
      if (isEqual(settings, prevData)) {
        freezeSettingsPageActivate();
        setDisableButton(true);
      } else {
        freezeSettingsPageActivate(true);
        setDisableButton(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, prevData, settings]);
};

export default useCheckDataChanging;
