import { useContext } from 'react';

import { AuthContext } from 'modules/auth/AuthContext';

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('AuthContext must be within AuthProvider');
  }

  return context;
};

export default useAuth;
