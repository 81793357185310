import React from 'react';
import styled from 'styled-components';

import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

import DotIcon from '../icons/DotIcon';

type Props = {
  clickHandler: () => void;
};

const AdvancedButton = ({ clickHandler }: Props) => (
  <Container data-testid="dotted-button" onClick={clickHandler}>
    <DotIcon />
    <DotIcon />
    <DotIcon />
  </Container>
);

const BUTTON_VERTICAL_RANGE = 5;
const BUTTON_HORIZONTAL_RANGE = 5;

const Container = styled.button`
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  width: 20px;
  justify-content: space-between;
  margin-left: auto;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  height: 15px;
  ${increaseClickableAreaForHtmlElement(
    BUTTON_VERTICAL_RANGE,
    BUTTON_HORIZONTAL_RANGE
  )}

  svg {
    transition: all 0.4s;
  }

  &:hover {
    svg {
      transform: scale(1.5);
    }
  }
`;

export default AdvancedButton;
