export const demographicsData = [
  {
    id: 1,
    percent: 63,
    title: 'Overweight',
  },
  {
    id: 2,
    percent: 28,
    title: 'Active',
    focusTitle: 'Encourage a healthy diet in the workplace',
    arrayDescription: [
      {
        title: 'Increased productivity.',
        description:
          'You can expect more productivity from your healthy-eating employees. Many essential nutrients boost brain  power to help employees feel productive and energised all day long.',
      },
      {
        title: 'Better mood.',
        description:
          'Many healthy foods provide mood-boosting nourishment. Healthy food staples such as salmon, spinach, and almonds are full of mood-boosting properties',
      },

      {
        title: 'Better overall performance.',
        description:
          'Employees who eat nutritious foods benefit from improved cognitive functions. This includes better focus, concentration, memory, and creativeness.',
      },
    ],
  },
  {
    id: 3,
    percent: 20,
    title: 'Healthy diet',
    focusTitle: 'Encourage a healthy diet in the workplace',
    arrayDescription: [
      {
        title: 'Increased productivity.',
        description:
          'You can expect more productivity from your healthy-eating employees. Many essential nutrients boost brain  power to help employees feel productive and energised all day long.',
      },
      {
        title: 'Better mood.',
        description:
          'Many healthy foods provide mood-boosting nourishment. Healthy food staples such as salmon, spinach, and almonds are full of mood-boosting properties',
      },

      {
        title: 'Better overall performance.',
        description:
          'Employees who eat nutritious foods benefit from improved cognitive functions. This includes better focus, concentration, memory, and creativeness.',
      },
    ],
  },
  {
    id: 4,
    percent: 55,
    title: 'Strong immunity',
  },
  {
    id: 5,
    percent: 57,
    title: 'Anxiety',
  },
  {
    id: 6,
    percent: 30,
    title: 'Fatigue',
    focusTitle: 'Encourage a healthy diet in the workplace',
    arrayDescription: [
      {
        title: 'Increased productivity.',
        description:
          'You can expect more productivity from your healthy-eating employees. Many essential nutrients boost brain  power to help employees feel productive and energised all day long.',
      },
      {
        title: 'Better mood.',
        description:
          'Many healthy foods provide mood-boosting nourishment. Healthy food staples such as salmon, spinach, and almonds are full of mood-boosting properties',
      },

      {
        title: 'Better overall performance.',
        description:
          'Employees who eat nutritious foods benefit from improved cognitive functions. This includes better focus, concentration, memory, and creativeness.',
      },
    ],
  },
  {
    id: 7,
    percent: 43,
    title: 'Happiness',
  },
  {
    id: 8,
    percent: 67,
    title: 'Confidence',
  },
];
