import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import { LogoIcon } from 'components/icons/LogoIcon';
import patterns from 'helpers/patterns';

type ResponseSectionProps = {
  isDesktop?: boolean;
};

const EmailSection = (
  { isDesktop }: ResponseSectionProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [activeInput, setActiveInput] = useState(false);
  const [email, setEmail] = useState('');

  const isCorrectEmail = !!email.match(patterns.emailPattern);

  return (
    <Container $isDesktop={isDesktop} ref={ref}>
      <TitleName>Start your free 1 month trial!</TitleName>
      <Subtitle>Enter your email to activate your unique offer code</Subtitle>
      <InputContainer>
        <Label $active={activeInput} htmlFor="offer-input">
          Enter email
        </Label>
        <StyledInput
          id="offer-input"
          onFocus={() => setActiveInput(true)}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputContainer>
      <WrapperButton>
        <DefaultButton
          text="get offer code"
          textColor={theme.colors.white}
          isActive={isCorrectEmail}
          withShadow
          buttonColor={
            isCorrectEmail ? theme.colors.funtainBlue : theme.colors.darkGrey
          }
          isUppercase
          buttonSize="xl"
          borderSize="xl"
        />
      </WrapperButton>
      <ShidoIconWrapper>
        <LogoIcon width={48} height={29} />
        <ShidoTitle>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
    </Container>
  );
};

const ShidoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 20px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: 4px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const WrapperButton = styled.div`
  width: 305px;
  margin: auto;
  margin-bottom: 84px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  outline: none;
  border: none;
  padding-top: 11px;
  box-sizing: border-box;
`;

const Label = styled.label<{ $active: boolean }>`
  font-size: ${({ $active }) => ($active ? '12px' : '16px')};
  color: ${({ theme }) => theme.colors.funtainBlue};
  position: absolute;
  transform: translateY(-50%);
  transition:
    all 0.5s,
    color 0s;
  top: ${({ $active }) => ($active ? '12px' : '50%')};
`;

const InputContainer = styled.div`
  position: relative;
  margin: 73px auto 40px;
  width: 600px;
  padding-left: 20px;
  padding-right: 6px;
  box-sizing: border-box;
  height: 60px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.funtainBlue};
  padding-bottom: 5px;
  background: white;
`;

const TitleName = styled.div`
  text-align: center;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
`;

const Subtitle = styled(TitleName)`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 300;
`;

const Container = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '1400px' : '1200px')};
  margin: 30px auto 0;
  padding-bottom: 211px;
  padding-top: 80px;
  border-bottom: 1px dashed rgb(216 218 224);
  position: relative;
  z-index: 3;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin: 36px auto 0;
    `}
`;

export default React.forwardRef(EmailSection);
