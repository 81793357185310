import React, { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import theme from 'theme';

import ChartLayout from 'components/charts/ChartLayout';
import UpRatingIcon from 'components/icons/UpRatingIcon';
import ProgressBar from 'components/ProgressBar';
import colorChecker from 'helpers/colorChecker';

const progressBarSection = ['Your team', 'Your sector', 'Global average'];

export enum ResultRating {
  Bad = 'BAD_RESULT',
  Normal = 'NORMAL_RESULT',
  Good = 'GOOD_RESULT',
}

type Picker = Record<
  ResultRating,
  {
    color: string;
    icon: JSX.Element;
    dotColor: string;
  }
>;

const picker: Picker = {
  [ResultRating.Bad]: {
    color: theme.colors.peach,
    icon: <img src="/images/downRatingResult.svg" />,
    dotColor: theme.colors.lightMangoYellow,
  },
  [ResultRating.Normal]: {
    color: theme.colors.lightRasberry,
    icon: <UpRatingIcon color={theme.colors.lightRasberry} />,
    dotColor: theme.colors.white,
  },
  [ResultRating.Good]: {
    color: theme.colors.greenApple,
    icon: <UpRatingIcon />,
    dotColor: theme.colors.lightGreenV2,
  },
};

export type MonthAverage = {
  name: string;
  uniqueVisitors: number;
};

type GraphCardProps = {
  title: string;
  percentageData: number[];
  months: MonthAverage[];
  rating: keyof Picker;
  ratingNumber: string;
  activeMonth: string;
};

const GraphCard = ({
  title,
  months,
  percentageData,
  rating,
  ratingNumber,
  activeMonth,
}: GraphCardProps) => {
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const { color, dotColor, icon } = picker[rating];

  return (
    <Container>
      <CardInfoWrapper>
        <CardTitle>{title}</CardTitle>
        <CardInfoRatingWrapper>
          {icon}
          <PercentageResult $color={color}>{ratingNumber}%</PercentageResult>
        </CardInfoRatingWrapper>
      </CardInfoWrapper>
      <GraphContainer>
        <ChartLayout color={color} dotColor={dotColor} months={months} />
      </GraphContainer>
      <MonthsViewWrapper>
        {months.map(({ name }) => {
          return (
            <MonthName key={name} $active={name === activeMonth}>
              {name}
            </MonthName>
          );
        })}
      </MonthsViewWrapper>
      <ProgressBarWrapper>
        {progressBarSection.map((name, i) => {
          const color = colorChecker(percentageData[i]);

          return (
            <ReactVisibilitySensor
              key={name}
              onChange={(isVisible: boolean) => {
                if (!isSectionVisible && isVisible) {
                  setIsSectionVisible(true);
                }
              }}
            >
              <ProgressBarElementWrapper key={name}>
                <ProgressBarInfo>
                  <Name>{name}</Name>
                  <Percent>{percentageData[i]}%</Percent>
                </ProgressBarInfo>
                <ProgressBar
                  isVisible={isSectionVisible}
                  color={color}
                  percent={percentageData[i]}
                />
              </ProgressBarElementWrapper>
            </ReactVisibilitySensor>
          );
        })}
      </ProgressBarWrapper>
    </Container>
  );
};

const Name = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const Percent = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const ProgressBarInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgressBarElementWrapper = styled.div`
  margin-top: 19px;
`;

const MEDIUM_POSITION = -9;
const SMALL_POSITION = -17;

const GraphContainer = styled.div`
  width: 100%;
  height: 90px;
  position: relative;
  margin-top: 15px;
  ${() => {
    // change vertical lines position of svg for better view on smaller devices
    let templateLiteral = ``;

    Array.from({ length: 5 }, (_, i) => i + 1).forEach((i) => {
      templateLiteral += `
        @media (max-width: 1340px) {
          #vertical-line-${i} {
            transform: translateX(${i * MEDIUM_POSITION}px);
          }
        }

        @media (max-width: 1160px) {
          #vertical-line-${i} {
            transform: translateX(${i * SMALL_POSITION}px);
        }
      }
    `;
    });

    return templateLiteral;
  }}

  line[stroke='#ccc'] {
    display: none;
  }
`;

const CardInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MonthsViewWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 9px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 10px;
  font-weight: 300;
`;

const MonthName = styled.div<{ $active: boolean }>`
  font-weight: ${({ $active }) => ($active ? '700' : '300')};
  width: 58px;
  display: flex;
  color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 19px;
`;

const CardInfoRatingWrapper = styled.div`
  align-items: center;
  display: flex;
  column-gap: 10px;
`;

const PercentageResult = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-weight: 700;
`;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 388px;
  height: 340px;
  border-radius: 5px;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  padding: 16px 19px 25px 20px;

  @media (width <= 1340px) {
    width: 330px;
  }

  @media (width <= 1160px) {
    width: 280px;
  }
`;

export default GraphCard;
