import { useMutation, useQueryClient } from 'react-query';

import { RequestStatus } from 'components/detailsModal/models';

import { QueryKey } from '../models';

type ChangeUserStatus = {
  userAccountId: string;
};

const useChangeUserStatus = (
  setTitle: (title: string) => void,
  setStatus: (status: RequestStatus) => void,
  mutationFn: ({ userAccountId }: ChangeUserStatus) => Promise<unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      setStatus(RequestStatus.Success);
      queryClient.invalidateQueries(QueryKey.GetUsersAccounts);
    },
    onError: (error: Error) => {
      setStatus(RequestStatus.Fail);
      setTitle('Something went wrong :(');
      console.error(error.message);
    },
  });
};

export default useChangeUserStatus;
