import { useEffect } from 'react';

import { PromotionOfferData, PromotionOfferResponse } from '../models';

const useSaveDataForEditPromotionOffer = (
  promotionOffer: PromotionOfferResponse,
  setSaved: (value: boolean) => void,
  saveAllOfferData: (data: {
    settings: PromotionOfferData;
    promotionAccountId: string;
    status: string;
  }) => void,
  setLogoImage: (url: string) => void,
  setLogoName: (name: string) => void,
  saveAccount: (id: string) => void,
  setQrCodeUrl: (qrCodeUrl: string) => void,
  saved?: boolean
) => {
  useEffect(() => {
    if (!saved) {
      const {
        qrCodeUrl,
        status,
        id,
        title,
        subtitle,
        bodyText,
        promotionTermId,
        backgroundColour,
        notes,
        imageUrl,
      } = promotionOffer;

      saveAllOfferData({
        promotionAccountId: id,
        status,
        settings: {
          offerStage: {
            promotionTermId,
            title,
            subtitle,
            bodyText,
          },
          settingsStage: {
            backgroundColour,
            notes,
          },
        },
      });
      setQrCodeUrl(qrCodeUrl);
      setLogoImage(imageUrl as string);
      setLogoName('image');

      setSaved(true);
    }
  }, [
    saved,
    setSaved,
    setLogoImage,
    setLogoName,
    promotionOffer,
    saveAccount,
    saveAllOfferData,
    setQrCodeUrl,
  ]);
};

export default useSaveDataForEditPromotionOffer;
