import React, { useCallback, useState } from 'react';

import ActionModal from 'components/actionModal/ActionModal';
import AffiliateIcon from 'components/icons/AffiliateIcon';
import CorporateIcon from 'components/icons/CorporateIcon';
import ReferrerIcon from 'components/icons/ReferrerIcon';

import AffiliateOfferContextProvider from '../context/AffiliateOfferContext';
import CorporateOfferContextProvider from '../context/CorporateOfferContext';
import PromotionOfferContextProvider from '../context/PromotionOfferContext';
import { OfferCategory, OfferModalWindowStages } from '../models';

import CreateAffiliateModal from './affiliateOffers/CreateAffiliateModal';
import CreateCorporateModal from './corporateOffers/CreateCorporateModal';
import CreatePromotionModal from './promotionOffers/CreatePromotionModal';

export const dataForSelectingCategory = [
  {
    name: 'Corporate offer',
    text: 'Create an offer page the enables corporation members to join Shido for free.',
    category: OfferCategory.Corporate,
    icon: <CorporateIcon />,
  },
  {
    name: 'Promotion',
    text: 'Create an offer page that enables users to redeem an extended trial period.',
    category: OfferCategory.Promotion,
    icon: <ReferrerIcon />,
  },
  {
    name: 'Affiliate',
    text: 'Create a page that enables affiliates to referrer users for commission.',
    category: OfferCategory.Affiliate,
    icon: <AffiliateIcon />,
  },
];

type Props = {
  activeOfferCategory: OfferCategory;
  setIfOfferPublished?: (value: boolean) => void;
  closeModalWindow: (id?: string) => void;
};

const CreateOfferModal = ({
  activeOfferCategory,
  setIfOfferPublished,
  closeModalWindow,
}: Props) => {
  const [isWindowClosing, setIsWindowClosing] = useState(false);
  const [activeCategory, setActiveCategory] = useState(activeOfferCategory);
  const [isNavbarTitleShown, setIsNavbarTitleShown] = useState(false);
  const [activatedCorporateStages, setActivatedCorporateStages] = useState([
    OfferModalWindowStages.SelectType,
  ]);
  const [activatedPromotionStages, setActivatedPromotionStages] = useState([
    OfferModalWindowStages.SelectType,
  ]);

  const [activatedAffiliateStages, setActivatedAffiliateStages] = useState([
    OfferModalWindowStages.SelectType,
  ]);

  const changeActiveCategory = useCallback(
    (category: OfferCategory) => setActiveCategory(category),
    []
  );

  const addNewStageToActivatedCorporate = useCallback(
    (stage: OfferModalWindowStages) => {
      if (!activatedCorporateStages.includes(stage)) {
        setActivatedCorporateStages([...activatedCorporateStages, stage]);
      }
    },
    [activatedCorporateStages]
  );

  const addNewStageToActivatedPromotion = useCallback(
    (stage: OfferModalWindowStages) => {
      if (!activatedPromotionStages.includes(stage)) {
        setActivatedPromotionStages([...activatedPromotionStages, stage]);
      }
    },
    [activatedPromotionStages]
  );

  const addNewStageToActivatedAffiliate = useCallback(
    (stage: OfferModalWindowStages) => {
      if (!activatedAffiliateStages.includes(stage)) {
        setActivatedAffiliateStages([...activatedAffiliateStages, stage]);
      }
    },
    [activatedAffiliateStages]
  );

  const freezeOfferPageActivate = useCallback(() => {
    const withoutOfferArray = activatedCorporateStages.filter(
      (stage) => stage !== OfferModalWindowStages.OfferPage
    );

    setActivatedCorporateStages(withoutOfferArray);
  }, [activatedCorporateStages]);

  const showNavbarTitle = useCallback(
    (value: boolean) => setIsNavbarTitleShown(value),
    []
  );

  const freezeSettingsPageActivate = useCallback(
    (disable?: boolean) => {
      if (disable) {
        const withoutOfferArray = activatedPromotionStages.filter(
          (stage) => stage !== OfferModalWindowStages.Settings
        );

        setActivatedPromotionStages(withoutOfferArray);
      } else {
        setActivatedPromotionStages([
          OfferModalWindowStages.SelectType,
          OfferModalWindowStages.OfferPage,
          OfferModalWindowStages.Settings,
        ]);
      }
    },
    [activatedPromotionStages]
  );

  const freezeSettingsAffiliatePageActivate = useCallback(
    (disable?: boolean) => {
      if (disable) {
        const withoutOfferArray = activatedAffiliateStages.filter(
          (stage) => stage !== OfferModalWindowStages.Settings
        );

        setActivatedAffiliateStages(withoutOfferArray);
      } else {
        setActivatedAffiliateStages([
          OfferModalWindowStages.SelectType,
          OfferModalWindowStages.OfferPage,
          OfferModalWindowStages.Settings,
        ]);
      }
    },
    [activatedAffiliateStages]
  );

  return (
    <CorporateOfferContextProvider>
      <PromotionOfferContextProvider>
        <AffiliateOfferContextProvider>
          <ActionModal closeModalWindow={() => setIsWindowClosing(true)}>
            {activeCategory === OfferCategory.Corporate && (
              <CreateCorporateModal
                isWindowClosing={isWindowClosing}
                isNavbarTitleShown={isNavbarTitleShown}
                closeModalWindow={closeModalWindow}
                setActiveCategory={changeActiveCategory}
                activatedStages={activatedCorporateStages}
                showNavbarTitle={showNavbarTitle}
                setActivatedStages={addNewStageToActivatedCorporate}
                freezeOfferPageActivate={freezeOfferPageActivate}
              />
            )}

            {activeCategory === OfferCategory.Promotion && (
              <CreatePromotionModal
                isWindowClosing={isWindowClosing}
                isNavbarTitleShown={isNavbarTitleShown}
                closeModalWindow={closeModalWindow}
                setActiveCategory={changeActiveCategory}
                activatedStages={activatedPromotionStages}
                showNavbarTitle={showNavbarTitle}
                setActivatedStages={addNewStageToActivatedPromotion}
                freezeSettingsPageActivate={freezeSettingsPageActivate}
              />
            )}

            {activeCategory === OfferCategory.Affiliate && (
              <CreateAffiliateModal
                isWindowClosing={isWindowClosing}
                isNavbarTitleShown={isNavbarTitleShown}
                closeModalWindow={closeModalWindow}
                setActiveCategory={changeActiveCategory}
                activatedStages={activatedAffiliateStages}
                showNavbarTitle={showNavbarTitle}
                setActivatedStages={addNewStageToActivatedAffiliate}
                freezeSettingsPageActivate={freezeSettingsAffiliatePageActivate}
                setIfOfferPublished={setIfOfferPublished}
              />
            )}
          </ActionModal>
        </AffiliateOfferContextProvider>
      </PromotionOfferContextProvider>
    </CorporateOfferContextProvider>
  );
};

export default CreateOfferModal;
