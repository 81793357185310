import axios from 'axios';
import Bowser from 'bowser';
import config from 'config';

import { refreshRequest } from 'modules/auth/AuthService';
import { Token } from 'modules/auth/models';
import { BaseRoutes, EmployerRoutes } from 'modules/router/models';

const browser = Bowser.getParser(window.navigator.userAgent);

export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
  headers: {
    'x-agent': browser.getBrowserName(),
    'x-agent-version': browser.getBrowserVersion(),
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Token.AccessToken);

    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (err.response && err.response.status === 401 && !err.config._retry) {
      err.config._retry = true;

      try {
        const accessToken = localStorage.getItem(Token.AccessToken);
        const refreshToken = localStorage.getItem(Token.RefreshToken);

        if (!accessToken || !refreshToken) throw Promise.reject(err);

        const { token, refreshToken: newRefreshToken } = await refreshRequest({
          accessToken,
          refreshToken,
        });

        localStorage.setItem(Token.AccessToken, token);
        localStorage.setItem(Token.RefreshToken, newRefreshToken);

        return axiosClient(originalConfig);
      } catch (_error) {
        const isEmployersPage = window.location.pathname.startsWith(
          EmployerRoutes.EmployersBase
        );

        if (isEmployersPage) {
          return Promise.reject(_error);
        }

        localStorage.removeItem(Token.AccessToken);
        localStorage.removeItem(Token.RefreshToken);

        window.location.href = BaseRoutes.Login;

        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  }
);
