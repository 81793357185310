import { useMutation, useQueryClient } from 'react-query';

import { RequestStatus } from 'components/detailsModal/models';

import { QueryKey } from '../models';

type ChangeCorporateAccountStatus = {
  corporateAccountId: string;
};

const useChangeAccountStatus = (
  setTitle: (title: string) => void,
  setStatus: (status: RequestStatus) => void,
  mutationFn: ({
    corporateAccountId,
  }: ChangeCorporateAccountStatus) => Promise<unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      setStatus(RequestStatus.Success);
      queryClient.invalidateQueries(QueryKey.GetCorporateAccounts);
    },
    onError: (error: Error) => {
      setStatus(RequestStatus.Fail);
      setTitle('Something went wrong :(');
      console.error(error.message);
    },
  });
};

export default useChangeAccountStatus;
