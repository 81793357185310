import React from 'react';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';

import ActionAgreement from './ActionAgreement';
import { Button } from './styledElements/detailsModalStyledElements';
import { ButtonContainer } from './styledElements/informationStyledElements';

type ModalActionProps = {
  employerModal?: boolean;
  note: string;
  color: string;
  buttonText: string;
  isChecked: boolean;
  actionTypeText: string;
  closeModal: () => void;
  toggleStatus: () => void;
  submitAction: () => void;
};

const ModalAction = ({
  employerModal,
  note,
  color,
  isChecked,
  buttonText,
  closeModal,
  toggleStatus,
  submitAction,
  actionTypeText,
}: ModalActionProps) => (
  <>
    <ActionAgreement
      employerModal={employerModal}
      note={note}
      isChecked={isChecked}
      actionTypeText={actionTypeText}
      toggleStatus={toggleStatus}
    />
    <ButtonContainer $color={color}>
      <DefaultButton
        text={buttonText}
        textColor={color}
        isActive={isChecked}
        withShadow
        buttonColor={theme.colors.white}
        isUppercase
        buttonSize="md"
        borderSize="md"
        clickHandler={submitAction}
      />
      <Button onClick={closeModal}>cancel</Button>
    </ButtonContainer>
  </>
);

export default ModalAction;
