import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { pageSizes, TableRowGrid } from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import TableRow from 'components/table/tableRow/TableRow';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanAccountLocalStorage } from 'helpers/connectToLocalStorage';
import { utcToCustomV2 } from 'helpers/utcFormatToCustom';

import UserAccountContextProvider from '../context/userAccountContext';
import { parametersForSortingUsersFE, User, UserTableItem } from '../models';
import { getUserDetails } from '../usersServices/usersAccountsService';

import EditUserModal from './EditUserModal';
import UsersDetailsModal from './UsersDetailsModal';
import { userExample } from './usersMockData';

type SubscribedTableProps = {
  data?: UserTableItem[];
  page: number;
  pageSize: number;
  dataAmount: number;
  sortByListItemIndex: number;
  sortUsers: (index: number) => void;
  changePageSize: (pageSize: number) => void;
  setPage: (pageNumber: number) => void;
};

const SubscribedTable = ({
  data,
  pageSize,
  sortUsers,
  dataAmount,
  changePageSize,
  setPage,
  page,
  sortByListItemIndex,
}: SubscribedTableProps) => {
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [dataForModal, setDataForModal] = useState<User>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, pageSize),
    [dataAmount, pageSize]
  );

  const closeDetailsModalWindow = useCallback(() => setIsModalOpen(false), []);

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen(!isEditingModalOpen);
    cleanAccountLocalStorage();
  }, [isEditingModalOpen]);

  const { isLoading, mutate } = useMutation(getUserDetails, {
    onSuccess: (data) => {
      setDataForModal(data);
    },
    onError: () => {
      setIsModalOpen(false);
    },
  });

  const openModalWindow = useCallback(
    (data: UserTableItem) => {
      setIsModalOpen(true);
      mutate({ id: data.id });
    },
    [mutate]
  );

  const switchFromDetailsToEditModal = useCallback(() => {
    toggleEditingModalWindow();
    closeDetailsModalWindow();
  }, [closeDetailsModalWindow, toggleEditingModalWindow]);

  return (
    <>
      <TableInfo
        pageSizes={pageSizes}
        dataAmount={dataAmount}
        activePageSize={pageSize}
        changePageSize={changePageSize}
      />
      <TableContainer>
        <TableHeader
          sortByListItemIndex={sortByListItemIndex}
          sortData={sortUsers}
          tableName={TableRowGrid.Users}
          parametersForSorting={parametersForSortingUsersFE}
        />
        {data && (
          <div>
            {data.map((item) => {
              const phoneNumber = item.countryCode
                ? `${item.countryCode} ${item.phoneNumber}`
                : '—';

              return (
                <TableRow
                  middleUpperCase
                  key={item.id}
                  tableName={TableRowGrid.UsersItems}
                  data={[
                    item.email,
                    phoneNumber,
                    utcToCustomV2(item.createdDateTime),
                  ]}
                  openModalWindow={() => openModalWindow(item)}
                  editOnFirstColumn
                />
              );
            })}
          </div>
        )}
        {isModalOpen && (
          <UsersDetailsModal
            isDataLoading={isLoading}
            user={dataForModal || userExample}
            closeWindow={closeDetailsModalWindow}
            openEditingModalWindow={switchFromDetailsToEditModal}
          />
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && dataForModal && (
        <UserAccountContextProvider>
          <EditUserModal
            user={dataForModal}
            closeModalWindow={toggleEditingModalWindow}
          />
        </UserAccountContextProvider>
      )}
    </>
  );
};

export default React.memo(SubscribedTable);
