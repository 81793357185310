import { find } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { PromotionOfferContext } from 'modules/offers/context/PromotionOfferContext';

import { Device } from '../models';
import PreviewHeader from '../PreviewHeader';

import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';
import TabletPreview from './TabletPreview';

export type Colors = Record<
  'Sand' | 'BurntSienna' | 'Sea' | 'Stone' | 'Blush' | 'Pistachio' | 'Sunlight',
  {
    light: string;
    strong: string;
  }
>;

export const colorPicker: Colors = {
  Sand: {
    light: 'white',
    strong: '#F7F7F2',
  },
  BurntSienna: {
    light: '#F0C9C4',
    strong: '#ECB8B1',
  },
  Sea: {
    light: '#E1EDF1',
    strong: '#D7E7ED',
  },
  Stone: {
    light: '#F2F3F3',
    strong: '#E8E9EB',
  },
  Blush: {
    light: '#F1DCE0',
    strong: '#ECD1D7',
  },
  Pistachio: {
    light: '#D0DCD2',
    strong: '#C0D1C5',
  },
  Sunlight: {
    light: '#FDF5DE',
    strong: '#FDEDC1',
  },
};

type PreviewPagePromotionProps = {
  chosenDevice: Device;
  closePreview: () => void;
};

const PreviewPagePromotion = ({
  chosenDevice,
  closePreview,
}: PreviewPagePromotionProps) => {
  const [activeDevice, setActiveDevice] = useState(chosenDevice);
  const {
    qrCodeUrl,
    offerDraft: {
      settings: {
        offerStage: { title, subtitle, bodyText, promotionTermId },
        settingsStage: { backgroundColour },
      },
    },
    promotionTerms,
    image,
  } = useContext(PromotionOfferContext);

  const changeActiveDeice = useCallback(
    (device: Device) => setActiveDevice(device),
    []
  );

  const chosenColor = useMemo(
    () => colorPicker[backgroundColour],
    [backgroundColour]
  );

  const term = useMemo(
    () => find(promotionTerms, { BEformat: promotionTermId })?.promoDisplay,
    [promotionTermId, promotionTerms]
  );

  const promotionProps = {
    title,
    subtitle,
    bodyText,
    chosenColor,
    image,
    term,
    qrCodeUrl,
  };

  return (
    <Container>
      <PreviewHeader
        title="offer"
        activeDevice={activeDevice}
        closePreview={closePreview}
        setActiveDevice={changeActiveDeice}
      />
      {activeDevice === Device.Desktop && (
        <WrapperDesktop>
          <ContainerDesktop $color={promotionProps.chosenColor.light}>
            <DesktopPreview {...promotionProps} />
          </ContainerDesktop>
        </WrapperDesktop>
      )}
      {activeDevice === Device.Tablet && <TabletPreview {...promotionProps} />}
      {activeDevice === Device.Mobile && (
        <MobilePreview
          {...{ title, subtitle, bodyText, chosenColor, image, term }}
        />
      )}
    </Container>
  );
};

const WrapperDesktop = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const ContainerDesktop = styled.div<{ $color: string }>`
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  background: white;
  position: relative;
  background-color: ${({ $color }) => $color};

  .background-graph {
    z-index: 2;
    position: absolute;
    margin-top: 911px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  min-height: 100vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
`;

export default PreviewPagePromotion;
