import React, { ReactNode } from 'react';
import styled from 'styled-components';

type StepProps = {
  title: string;
  stepNumber: number;
  description: string;
  Icon: () => JSX.Element;
  children: ReactNode;
};

const Step = ({
  Icon,
  title,
  children,
  stepNumber,
  description,
}: StepProps) => {
  return (
    <Container>
      <StepNumber>Step {stepNumber}</StepNumber>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Title>{title}</Title>
      <Description>
        <WrapText>{description}</WrapText>
      </Description>
      {children}
    </Container>
  );
};

const WrapText = styled.div`
  line-height: 25px;
`;

const IconWrapper = styled.div`
  margin-top: 15px;
`;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  max-width: 376px;
  height: auto;
  padding: 0 54px 30px 20px;
  box-sizing: border-box;

  @media (width <= 867px) {
    margin-top: 10px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 17px;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: light;
  margin: 12px 0 36px;
`;
const StepNumber = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 40px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.careysPink};
`;

export default Step;
