/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'config';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import QRCode from 'react-qr-code';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import theme from 'theme';

import { CorporateOfferDownloadDetails } from 'components/detailsModal/models';
import PhoneIcon from 'components/icons/PhoneIcon';
import PinkLockIcon from 'components/icons/PinkLockIcon';
import { offerTerms } from 'components/listForSelectingData/dataForLists';

import AppleIcon from '../temporarySvg/appleSvg.svg';
import GooglePlayIcon from '../temporarySvg/googlePlay.svg';

import Step from './Steps';

const InstallContent = ({ offer }: CorporateOfferDownloadDetails) => {
  const convertedMonthTerm = useMemo(
    () =>
      find(offerTerms, {
        BEformat: offer.offerTerm,
      })?.displayDownloadPageName,
    [offer.offerTerm]
  );

  return (
    <>
      <Content>
        <MainContent $background={offer.backgroundUrl}>
          <TitleContainer>
            <Title>
              Brought to you by {offer.corporateAccount.companyName}
            </Title>
            <SubTitle>
              Scan to activate {convertedMonthTerm} free subscription!
            </SubTitle>
          </TitleContainer>
          <QRContainer>
            <QRCode
              value={`${config.shidoUrl}${encodeURIComponent(
                offer.publicCode
              )}`}
              size={200}
              bgColor={theme.colors.primary}
              fgColor={theme.colors.white}
            />
          </QRContainer>
        </MainContent>
      </Content>
      <MiddleTitle>How it works</MiddleTitle>
      <StepContainer>
        <Step
          stepNumber={1}
          Icon={() => <PinkLockIcon width={46.5} height={60} />}
          title="Activate your shido offer"
          description="Scan the QR code with your mobile device or enter the offer code at
  the link below"
        >
          <StepWebSiteAndGoogle>Visit the website</StepWebSiteAndGoogle>

          <StepPath>shido.me/{encodeURIComponent(offer.publicCode)}</StepPath>

          <StepText>Offer code</StepText>
          <StepCode>{offer.code}</StepCode>
        </Step>
        <Step
          stepNumber={2}
          Icon={PhoneIcon}
          title="Install the shido app"
          description="Download the Shido mobile app and sign in with your activated Shido
  account."
        >
          <StepWebSiteAndGoogle>Get on google play</StepWebSiteAndGoogle>

          <GoogleAppleIconWrapper>
            <img src={GooglePlayIcon} />
          </GoogleAppleIconWrapper>

          <StepText>Download on app store</StepText>
          <GoogleAppleIconWrapper>
            <img src={AppleIcon} />
          </GoogleAppleIconWrapper>
        </Step>
      </StepContainer>
      {offer.logoUrl && (
        <>
          <BroughtTitle>Brought to you by</BroughtTitle>
          <IconWrapper>
            <ReactSVG
              fallback={() => <img src={offer.logoUrl} alt="logo" />}
              src={offer.logoUrl}
            />
          </IconWrapper>
        </>
      )}
    </>
  );
};

const Content = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    z-index: 10;
    content: '';
    position: absolute;
    bottom: -100px;
    border-radius: 47.7%;
    width: 143%;
    height: 128%;
    border: 146px solid white;
    left: -335px;
  }
`;

const StepContainer = styled.div`
  display: flex;
  max-width: 792px;
  margin: 31px auto 0;
  margin-bottom: 0;
  justify-content: space-between;
`;

const GoogleAppleIconWrapper = styled.div`
  margin-top: 10px;
`;

const StepWebSiteAndGoogle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.careysPink};
  font-weight: bold;
  text-transform: uppercase;
`;

const StepText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.careysPink};
  font-weight: bold;
  margin-top: 26px;
  line-height: 1.8;
  text-transform: uppercase;
`;

const StepPath = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 16px;
  overflow-wrap: break-word;
  margin-bottom: 35px;
`;

const IconWrapper = styled.div`
  display: flex;
  margin: 10px auto;
  margin-bottom: 0;
  justify-content: center;

  svg,
  img {
    height: 100px;
    margin: 0 auto;
  }
`;

const StepCode = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 9px;
`;

const BroughtTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-top: 52px;
  text-transform: uppercase;
`;
/* stylelint-disable */
const MainContent = styled.div<{ $background: string }>`
  position: relative;
  height: 730px;
  background: ${({ theme }) => theme.colors.primary} 67% 82% / contain no-repeat
    ${({ $background }) =>
      $background
        ? `url(${$background})`
        : 'url(/images/desktop_background_v2.png)'};
`;

const TitleContainer = styled.div`
  display: grid;
  justify-content: center;
`;

const Title = styled.span`
  max-width: 670px;
  text-align: center;
  color: white;
  margin-top: 80px;
  font-size: 20px;
  font-family: ${({ theme }) => (theme.fonts as any).light};
  font-weight: normal;
  font-display: swap;
  line-height: 31px;
  margin-left: 10px;
`;

const SubTitle = styled.span`
  max-width: 670px;
  text-align: center;
  color: white;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
`;

const MiddleTitle = styled.span`
  position: relative;
  width: 100%;
  background: white;
  font-size: 30px;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  text-align: center;
  top: -2px;
`;

const QRContainer = styled.div`
  margin-top: 42px;
  display: grid;
  justify-content: center;
  font-weight: bold;
`;

export default InstallContent;
