import React from 'react';

type EditIconProps = {
  color: string;
  width: number;
  height: number;
};

const EditIcon = ({ color, width, height }: EditIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 16.002 16.003"
  >
    <defs>
      <clipPath id="edit-clip-path">
        <path
          id="Path_6888"
          data-name="Path 6888"
          d="M11.189-20.063a.335.335,0,0,0-.237-.1h0a.335.335,0,0,0-.237.1L3.389-12.736a.335.335,0,0,0,0,.474L6.707-8.943a.335.335,0,0,0,.474,0L14.5-16.265a.335.335,0,0,0,0-.474Z"
          transform="translate(-3.291 20.162)"
          fill={color}
        />
      </clipPath>
      <clipPath id="edit-clip-path-2">
        <path
          id="Path_6890"
          data-name="Path 6890"
          d="M1.653-7.248a.335.335,0,0,0-.328-.085.335.335,0,0,0-.235.244L.079-2.879a.335.335,0,0,0,.089.315.34.34,0,0,0,.315.089L4.691-3.481a.335.335,0,0,0,.245-.235.335.335,0,0,0-.086-.328Z"
          transform="translate(-0.07 7.346)"
          fill={color}
        />
      </clipPath>
      <clipPath id="edit-clip-path-3">
        <path
          id="Path_6892"
          data-name="Path 6892"
          d="M20.823-22.016,19.4-23.441a1.677,1.677,0,0,0-2.369,0l-.951.95a.335.335,0,0,0,0,.474L19.4-18.7a.335.335,0,0,0,.474,0l.95-.952A1.677,1.677,0,0,0,20.823-22.016Z"
          transform="translate(-15.981 23.93)"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_6208"
      data-name="Group 6208"
      transform="translate(-0.07 23.93)"
    >
      <g
        id="Group_6204"
        data-name="Group 6204"
        transform="translate(2.23 -21.403)"
        clipPath="url(#edit-clip-path)"
      >
        <path
          id="Path_6887"
          data-name="Path 6887"
          d="M-1.709-25.162H16.309V-7.138H-1.709Z"
          transform="translate(-1.644 21.808)"
          fill={color}
        />
      </g>
      <g
        id="Group_6205"
        data-name="Group 6205"
        transform="translate(0.07 -12.807)"
        clipPath="url(#edit-clip-path-2)"
      >
        <path
          id="Path_6889"
          data-name="Path 6889"
          d="M-4.93-12.346H6.655V-.759H-4.93Z"
          transform="translate(1.576 8.992)"
          fill={color}
        />
      </g>
      <g
        id="Group_6206"
        data-name="Group 6206"
        transform="translate(10.741 -23.93)"
        clipPath="url(#edit-clip-path-3)"
      >
        <path
          id="Path_6891"
          data-name="Path 6891"
          d="M10.981-28.93H23.019v12.039H10.981Z"
          transform="translate(-14.334 25.577)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default EditIcon;
