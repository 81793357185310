import React from 'react';
import styled from 'styled-components';

import theme from '../../theme';
import Loader from '../Loader';

const circles = [
  {
    color: theme.colors.funtainBlue,
    speed: 2,
    size: 184,
  },
  {
    color: theme.colors.funtainBlue,
    speed: 2.3,
    size: 140,
  },
];

const TableLoader = () => (
  <Container data-testid="table-loader">
    <Loader circles={circles} />
  </Container>
);

const Container = styled.div`
  margin: 200px auto;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
`;

export default TableLoader;
