import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ShidoWithSignIcon from 'components/icons/ShidoWithSignIcon';

import Navigation, { LinksArray } from './Navigation';
import SignOutButton from './SignOutButton';

export type NavBarProps = {
  employerHeader?: boolean;
  linksArray: LinksArray[];
};

const Navbar = ({ linksArray, employerHeader }: NavBarProps) => (
  <Container $employerHeader={employerHeader}>
    <NavigationContainer>
      <Content>
        <ShidoWithSignIcon width={64} height={40} color={theme.colors.white} />
        <Navigation linksArray={linksArray} />
        <SignOutButton employerHeader={employerHeader} />
      </Content>
    </NavigationContainer>
  </Container>
);

const Container = styled.nav<{ $employerHeader?: boolean }>`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  min-height: ${({ $employerHeader }) => ($employerHeader ? '121' : '160')}px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  border-bottom: 1px solid rgb(255 255 255 / 20%);
`;

const NavigationContainer = styled.div`
  height: 80px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  padding: 0 40px;
  top: 0;
  position: fixed;
  z-index: 6;
  width: 100%;
`;

export default Navbar;
