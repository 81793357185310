import React from 'react';
import styled from 'styled-components';

import SendIcon from 'components/icons/SendIcon';
import {
  DashedWrapper,
  InfoItemWrapperColumn,
  Label,
  Value,
} from 'modules/onboarding/components/styledElements/styledModalElements';

type DelegateAccountInformationProps = {
  email: string;
  mobile: string;
  jobTitle: string;
  role: string;
};

const DelegateModalInformation = ({
  email,
  mobile,
  jobTitle,
  role,
}: DelegateAccountInformationProps) => {
  return (
    <Container>
      <InfoContainer>
        <InfoItemWrapperColumn>
          <InfoItemWrapper>
            <Label>Job title</Label>
            <Value>{jobTitle}</Value>
          </InfoItemWrapper>
          <InfoItemWrapper>
            <Label>Email</Label>
            <Value>{email}</Value>
          </InfoItemWrapper>
        </InfoItemWrapperColumn>
        <InfoItemWrapperColumn>
          <InfoItemWrapper>
            <Label>Role</Label>
            <Value>{role}</Value>
          </InfoItemWrapper>
          <InfoItemWrapper>
            <Label>Mobile</Label>
            <Value>{mobile}</Value>
          </InfoItemWrapper>
        </InfoItemWrapperColumn>
      </InfoContainer>
      <ResendContainer>
        <InfoItemText>
          Leila hasn’t signed in yet, would you like to resend the access link?
        </InfoItemText>
        <ButtonContainer>
          <SendIcon />
          <ButtonText>RESEND</ButtonText>
        </ButtonContainer>
      </ResendContainer>
    </Container>
  );
};

const InfoItemWrapper = styled(DashedWrapper)`
  width: 306px;

  &:nth-child(1) {
    border-bottom: none;
    border-top: none;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 17px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const ButtonText = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const ResendContainer = styled.div`
  position: absolute;
  bottom: 39px;
  width: 94%;
`;

const Container = styled.div`
  margin-top: -7px;
  color: ${({ theme }) => theme.colors.white};
  width: 630px;
`;

const InfoContainer = styled.div`
  display: flex;
  column-gap: 20px;
  color: ${({ theme }) => theme.colors.white};
`;

const InfoItemText = styled.div`
  font-size: 12px;
  font-weight: 200;
  margin-top: 14px;
`;

export default React.memo(DelegateModalInformation);
