import React, { useCallback, useState } from 'react';

import SearchBar from 'components/searchBar/SearchBar';
import { STANDARD_PAGE_SIZE } from 'components/table/models';
import TableLoader from 'components/table/TableLoader';
import { cleanAccountLocalStorage } from 'helpers/connectToLocalStorage';

import { getCorporateAccounts } from '../../accountsServices/CorporateAccountsService';
import useGetCorporateAccounts from '../../hooks/useGetCorporateAccounts';
import { ACCOUNTS_SORT_BY_BE } from '../../models';
import CreateAccountModal from '../CreateAccountModal';

import CorporateTable from './CorporateTable';

const CorporateAccounts = () => {
  const [nameForSearching, setNameForSearching] = useState('');
  const [sortByListItemIndex, setSortByListItemIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(STANDARD_PAGE_SIZE);
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);

  const { data, isLoading } = useGetCorporateAccounts(
    [nameForSearching, sortByListItemIndex, page, pageSize],
    () =>
      getCorporateAccounts({
        data: {
          page: page || 1,
          pageSize,
          search: nameForSearching,
          sortBy: ACCOUNTS_SORT_BY_BE[sortByListItemIndex],
        },
      })
  );

  const chooseTypeOfSorting = useCallback((index: number) => {
    setPage(1);
    setSortByListItemIndex(index);
  }, []);

  const searchByOfferName = useCallback((name: string) => {
    setPage(1);
    setNameForSearching(name);
  }, []);

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
    cleanAccountLocalStorage();
  }, [isCreatingModalOpen]);

  return (
    <>
      <SearchBar
        title="Accounts:"
        buttonText="Create account"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter="Corporate"
        setNewNameToFilter={searchByOfferName}
      />
      {isCreatingModalOpen && (
        <CreateAccountModal closeModalWindow={toggleCreatingModalWindow} />
      )}
      {isLoading ? (
        <TableLoader />
      ) : (
        <CorporateTable
          sortByListItemIndex={sortByListItemIndex}
          sortAccounts={chooseTypeOfSorting}
          data={data?.items}
          page={page}
          dataAmount={data?.totalCount || 0}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default CorporateAccounts;
