import React from 'react';
import styled from 'styled-components';

type Props = {
  categoryName: string;
  statisticsName:
    | string
    | {
        title: string;
        value: string;
      };
};

const DashboardHeader = ({ categoryName, statisticsName }: Props) => (
  <Container>
    <Title>
      Dashboard: <StatisticsName>{categoryName}</StatisticsName>
    </Title>
    {typeof statisticsName === 'string' ? (
      <StatisticsName>{statisticsName}</StatisticsName>
    ) : (
      <Title>
        {`${statisticsName.title}: `}
        <StatisticsName>{statisticsName.value}</StatisticsName>
      </Title>
    )}
  </Container>
);

const StatisticsName = styled.span`
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
`;

const Container = styled.div`
  position: absolute;
  top: -40px;
  width: 100%;
  height: 80px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 19px;
  box-sizing: border-box;
  line-height: 25px;
  font-size: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 10px;
  }
`;

export default DashboardHeader;
