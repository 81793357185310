import React from 'react';

const CheckedCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="ab">
        <path
          data-name="Path 18998"
          d="M12-24A12 12 0 0 0 0-12 12 12 0 0 0 12 0a12 12 0 0 0 12-12 12.014 12.014 0 0 0-12-12Zm6.927 8.2-6.845 9.289a1.011 1.011 0 0 1-.676.394 1.011 1.011 0 0 1-.754-.206l-4.888-3.908a1 1 0 0 1-.156-1.406 1 1 0 0 1 1.406-.156l4.076 3.261 6.227-8.451a1 1 0 0 1 .927-.441 1 1 0 0 1 .827.608 1 1 0 0 1-.144 1.016Z"
          fill="#69b0c7"
        />
      </clipPath>
    </defs>
    <g data-name="Group 16667">
      <g
        data-name="Group 16663"
        clipPath="url(#ab)"
        transform="translate(0 24)"
      >
        <path data-name="Path 18997" d="M-5-29h34V5H-5Z" fill="#69b0c7" />
      </g>
    </g>
  </svg>
);

export default CheckedCircleIcon;
