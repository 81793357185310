import { useRef } from 'react';

const useCreateRefsForClick = () => {
  const detailsRef = useRef<HTMLDivElement>(null);
  const responseRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLDivElement>(null);

  return {
    refs: [detailsRef, responseRef, emailRef],
  };
};

export const handlerRefClick =
  (element: React.RefObject<HTMLDivElement>) => () => {
    if (element.current) {
      element.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

export default useCreateRefsForClick;
