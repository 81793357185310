import React from 'react';

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.523"
    height="11.995"
    viewBox="0 0 14.523 11.995"
  >
    <defs>
      <clipPath id="checkIconClip">
        <path
          data-name="Path 15273"
          d="M18.741-17.757a1.25 1.25 0 0 0-.929-.23 1.25 1.25 0 0 0-.819.495l-6.228 8.453-3.985-3.188a1.25 1.25 0 0 0-1.248-.209 1.25 1.25 0 0 0-.79.989 1.25 1.25 0 0 0 .478 1.172l5 4a1.235 1.235 0 0 0 .78.269 1.254 1.254 0 0 0 1.006-.5l7-9.5a1.249 1.249 0 0 0 .231-.931 1.249 1.249 0 0 0-.496-.82Z"
          fill="#69b0c7"
        />
      </clipPath>
    </defs>
    <g data-name="Group 15719">
      <g
        data-name="Group 15717"
        clipPath="url(#checkIconClip)"
        transform="translate(-4.728 18.001)"
      >
        <path
          data-name="Path 15272"
          d="M-.272-23h24.524v21.994H-.272Z"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default CheckIcon;
