import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import GraphBackgroundIcon from 'components/icons/GraphBackgroundIcon';

type Props<DataKey extends string> = {
  bars: {
    name: DataKey;
    color: string;
  }[];
  data: (Record<'name', string> & Record<DataKey, number>)[];
};

const DashboardBarChart = <DataKey extends string>({
  data,
  bars,
}: Props<DataKey>) => (
  <ResponsiveContainer>
    <BarChart
      data={data}
      barCategoryGap={2}
      margin={{
        left: -61,
        bottom: -20,
        top: 20,
        right: -1,
      }}
    >
      <CartesianGrid />
      <ReferenceArea shape={<GraphBackgroundIcon />} />
      <XAxis
        dataKey="name"
        axisLine={false}
        tick={false}
        tickLine={false}
        padding={{ left: 25, right: 25 }}
      />
      <YAxis axisLine={false} tickLine={false} tick={false} />
      {!!data.length && (
        <Tooltip
          labelStyle={{ fontSize: '14px' }}
          allowEscapeViewBox={{ y: true }}
          position={{ y: -35 }}
        />
      )}

      {bars.map((bar) => (
        <Bar
          key={bar.name}
          dataKey={bar.name}
          fill={bar.color}
          minPointSize={2}
        />
      ))}
    </BarChart>
  </ResponsiveContainer>
);

export default DashboardBarChart;
