import React, { createContext, ReactNode, useCallback, useState } from 'react';

import countries from 'components/listForSelectingData/dataForList/countries';

import { UserAccountAttributes } from '../usersServices/usersAccountsService';

type userAccountContextType = {
  user: {
    accountDetails: UserAccountAttributes;
    countryAbbreviation: string;
  };
  saveData: (accountDetails: UserAccountAttributes) => void;
  saveAccountDetailsData: (
    accountDetails: UserAccountAttributes,
    countryAbbreviation?: string
  ) => void;
};

const initialValue: userAccountContextType = {
  user: {
    accountDetails: {
      firstName: '',
      email: '',
      phoneNumber: '',
      countryCode: countries[0].code,
      passcode: '',
    },
    countryAbbreviation: '',
  },
  saveData: () => null,
  saveAccountDetailsData: () => null,
};

export const UserAccountContext =
  createContext<userAccountContextType>(initialValue);

const UserAccountContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState(initialValue.user);

  const saveAccountDetailsData = useCallback(
    (accountDetails: UserAccountAttributes, countryAbbr?: string) =>
      setUser({
        ...user,
        accountDetails,
        countryAbbreviation: countryAbbr || user.countryAbbreviation,
      }),
    [user]
  );

  const saveData = useCallback(
    (accountDetails: UserAccountAttributes) =>
      setUser({ ...user, accountDetails }),
    [user]
  );

  return (
    <UserAccountContext.Provider
      value={{
        user,
        saveData,
        saveAccountDetailsData,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  );
};

export default UserAccountContextProvider;
