import {
  ErrorResponse,
  ErrorResponseFE,
  InputId,
} from 'components/actionModal/models';

type SetErrorProps = {
  input: string;
  message: string;
};

type InputErrorProps = {
  setError: ({ message, input }: SetErrorProps) => void;
  errorMessage: string;
  errorResponse: ErrorResponse;
};

const setInputError = ({
  setError,
  errorMessage,
  errorResponse,
}: InputErrorProps) => {
  if (errorMessage === errorResponse.MOBILE_TAKEN) {
    setError({
      input: InputId.MobileNumberInputId,
      message: ErrorResponseFE.MOBILE_TAKEN,
    });
  } else if (errorMessage === errorResponse.EMAIL_TAKEN) {
    setError({
      input: InputId.EmailAddressInputId,
      message: ErrorResponseFE.EMAIL_TAKEN,
    });
  } else if (errorMessage === errorResponse.MOBILE_INVALID) {
    setError({
      input: InputId.MobileNumberInputId,
      message: ErrorResponseFE.MOBILE_INVALID,
    });
  }
};

export default setInputError;
