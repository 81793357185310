import { find } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar, { NavbarItem } from 'components/actionModal/Navbar';
import AffiliateOfferPage from 'components/actionModal/stages/offerPage/AffiliateOfferPage';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import Result from 'components/actionModal/stages/Result';
import AffiliateOfferSettings from 'components/actionModal/stages/settings/AffiliateOfferSettings';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
} from 'components/detailsModal/models';
import { AffiliateOfferContext } from 'modules/offers/context/AffiliateOfferContext';
import useGetOfferTerms, {
  OfferTerms,
} from 'modules/offers/hooks/useGetOfferTerms';
import useSaveDataForEditAffiliateOffer from 'modules/offers/hooks/useSaveDataForEditAffiliateOffer';
import useSaveImage from 'modules/offers/hooks/useSaveImage';
import {
  editAffiliateOffer,
  publishAffiliateOffer,
  uploadAffiliateImage,
} from 'modules/offers/offersServices/AffiliateOffersService';
import { getPromotionOfferTerms } from 'modules/offers/offersServices/PromotionOffersService';

import {
  AffiliateOfferResponse,
  AffiliateSettingsPageAttributes,
  OfferCategory,
  OfferModalWindowStages,
  QueryKey,
} from '../../models';

const AFFILIATE_UPDATED = 'AFFILIATE_UPDATED';

const NAVBAR_ITEMS: NavbarItem<OfferModalWindowStages>[] = [
  {
    text: 'Affiliate page',
    name: OfferModalWindowStages.OfferPage,
  },
  {
    text: 'Settings',
    name: OfferModalWindowStages.Settings,
  },
];

type EditAffiliateModalProps = {
  offer: AffiliateOfferResponse;
  isEditing?: boolean;
  closeModalWindow: () => void;
};

const EditAffiliateModal = ({
  offer,
  closeModalWindow,
}: EditAffiliateModalProps) => {
  const queryClient = useQueryClient();

  const {
    offerDraft,
    imageName,
    image,
    setIsValidFormOffer,
    setPrevData,
    saveSettings,
    saveAllOfferData,
    saveOfferSettings,
    setImageFile,
    setImage,
    setImageName,
    saveOfferId,
    setOfferTermId,
  } = useContext(AffiliateOfferContext);

  const [saved, setSaved] = useState(false);
  const [result, setResult] = useState('');
  const [activatedStages, setActivatedStages] = useState([
    OfferModalWindowStages.OfferPage,
  ]);
  const [isPending, setPending] = useState(false);
  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.OfferPage
  );

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(NAVBAR_ITEMS[stage].name),
    []
  );

  const goToSettingsStage = useCallback(() => {
    setIsValidFormOffer(true);
    setPrevData(offerDraft.settings.offerStage);
    setActiveStage(OfferModalWindowStages.Settings);
    setActivatedStages([
      OfferModalWindowStages.OfferPage,
      OfferModalWindowStages.Settings,
    ]);
  }, [offerDraft.settings.offerStage, setIsValidFormOffer, setPrevData]);

  const goToResultStage = useCallback(
    () => setActiveStage(OfferModalWindowStages.Result),
    []
  );

  const uploadLogo = useMutation(uploadAffiliateImage);

  const saveImage = useSaveImage({
    setPending,
    setImageFile,
    setImage,
    uploadLogo: uploadLogo.mutateAsync,
    offerId: offerDraft.affiliateOfferId,
  });

  const saveOfferTermId = useCallback(
    (data: OfferTerms) => {
      const termId = find(data, { term: 'OneMonth' })?.id || '';

      setOfferTermId(termId);
    },
    [setOfferTermId]
  );

  const freezeSettingsPageActivate = useCallback(
    (disable?: boolean) => {
      if (disable) {
        const withoutOfferArray = activatedStages.filter(
          (stage) => stage !== OfferModalWindowStages.Settings
        );

        setActivatedStages(withoutOfferArray);
      } else {
        setActivatedStages([
          OfferModalWindowStages.OfferPage,
          OfferModalWindowStages.Settings,
        ]);
      }
    },
    [activatedStages]
  );

  const closeAffiliateModal = useCallback(() => {
    setResult(AFFILIATE_UPDATED);
    queryClient.invalidateQueries(QueryKey.GetAffiliateOffers);
    goToResultStage();
  }, [goToResultStage, queryClient]);

  const { isLoading: editAffiliateOfferDraftLoading, mutate: editOffer } =
    useMutation(editAffiliateOffer, {
      onSuccess: () => {
        if (offer.status === StatusBE.Draft) {
          publishOfferDraft({
            id: offerDraft.affiliateOfferId,
            data: {
              ...offerDraft.settings.settingsStage,
            },
          });
        } else if (offer.status === StatusBE.Active) {
          closeAffiliateModal();
        }
      },
    });

  const {
    isLoading: publishAffiliateOfferDraftLoading,
    mutate: publishOfferDraft,
  } = useMutation(publishAffiliateOffer, {
    onSuccess: () => {
      closeAffiliateModal();
    },
  });

  const sendEditAffiliateOfferRequest = useCallback(
    (data: AffiliateSettingsPageAttributes) => {
      editOffer({
        offerId: offerDraft.affiliateOfferId,
        data: {
          ...offerDraft.settings.offerStage,
          ...data,
        },
      });
    },
    [editOffer, offerDraft.affiliateOfferId, offerDraft.settings.offerStage]
  );

  useSaveDataForEditAffiliateOffer(
    offer,
    setSaved,
    saveAllOfferData,
    setImage,
    setImageName,
    saveOfferId,
    saved
  );

  useGetOfferTerms(getPromotionOfferTerms, saveOfferTermId);

  useEffect(() => {
    if (
      result &&
      (publishAffiliateOfferDraftLoading ||
        activeStage === OfferModalWindowStages.Result)
    ) {
      setTimeout(() => {
        closeModalWindow();
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [
    activeStage,
    closeModalWindow,
    publishAffiliateOfferDraftLoading,
    result,
  ]);

  const isLoadingStarted =
    editAffiliateOfferDraftLoading || publishAffiliateOfferDraftLoading;

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {isLoadingStarted || activeStage === OfferModalWindowStages.Result ? (
        <Result
          title={result === AFFILIATE_UPDATED ? 'Affiliate offer updated!' : ''}
          disableAutoClosing
          closeModal={closeModalWindow}
        />
      ) : (
        <>
          <Navbar
            title="Edit offer"
            activeStage={activeStage}
            showTitle={false}
            accountCategory={OfferCategory.Promotion}
            navbarItems={NAVBAR_ITEMS}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
          />
          <ActionModalMainContent>
            <AffiliateOfferPage
              isEdit
              isShowStage={activeStage === OfferModalWindowStages.OfferPage}
              title="Affiliate page"
              settings={offerDraft.settings.offerStage}
              saveSettings={saveOfferSettings}
              sendRequest={goToSettingsStage}
              freezeSettingsPageActivate={freezeSettingsPageActivate}
            />
            <AffiliateOfferSettings
              isEdit
              isShowStage={activeStage === OfferModalWindowStages.Settings}
              title="Settings"
              submitButtonTitle="Publish"
              isPending={isPending}
              settings={offerDraft.settings.settingsStage}
              previewOffer={PreviewOffer.Affiliate}
              imageName={imageName}
              image={image}
              saveSettings={saveSettings}
              saveImage={saveImage}
              saveImageName={setImageName}
              goToNextStage={sendEditAffiliateOfferRequest}
            />
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default EditAffiliateModal;
