import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import CustomCalendar from 'components/calendar/Calendar';
import { months } from 'components/calendar/calendarData';
import {
  CustomDate,
  CustomDateBE,
  ValidPeriodVariant,
} from 'components/calendar/models';
import CalendarIcon from 'components/icons/CalendarIcon';
import WarningIcon from 'components/icons/WarningIcon';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import {
  ActiveListParameter,
  CountryItem,
  DropdownInput,
  FormLabel,
} from '../../styledElements/formsStyledElements';
import { DateText } from '../../styledElements/stagesStyledElements';
import { initialDate } from '../initialValues';

type ValidPeriodInputProps = {
  isStartDateDisabled?: boolean;
  offerDateEnd?: string | Date | null;
  startDateUTC: string | Date | null;
  endDateUTC: string | Date | null;
  checkValidation: (isValid: boolean) => void;
  saveStartDate: (date: CustomDateBE) => void;
  saveEndDate: (date: CustomDateBE) => void;
};

const ValidPeriodDropdown = ({
  isStartDateDisabled,
  offerDateEnd,
  endDateUTC,
  startDateUTC,
  saveEndDate,
  saveStartDate,
  checkValidation,
}: ValidPeriodInputProps) => {
  const [offerEndDate, setOfferEndDate] = useState<CustomDate>(initialDate);
  const [startDate, setStartDate] = useState<CustomDate>(initialDate);
  const [endDate, setEndDate] = useState<CustomDate>(initialDate);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [validPeriodVariant, setValidPeriodVariant] =
    useState<ValidPeriodVariant>(ValidPeriodVariant.ValidPeriodFrom);
  const isOfferExpired = useMemo(
    () => endDateUTC && new Date().getTime() >= new Date(endDateUTC).getTime(),
    [endDateUTC]
  );

  const openCalendar = useCallback((index: number, disabled?: boolean) => {
    if (!disabled) {
      setIsCalendarOpen(true);
      setValidPeriodVariant(
        index === 0
          ? ValidPeriodVariant.ValidPeriodFrom
          : ValidPeriodVariant.ValidPeriodUntil
      );
    }
  }, []);

  const closeCalendar = useCallback(() => setIsCalendarOpen(false), []);

  const setDateToValidPeriod = useCallback(
    (date: CustomDate) => {
      const { day, month, year } = date;
      const dateForSaving = {
        day: Number(day),
        month: months.indexOf(month),
        year: Number(year),
      };

      if (validPeriodVariant === ValidPeriodVariant.ValidPeriodFrom) {
        saveStartDate(dateForSaving);
      } else {
        saveEndDate(dateForSaving);
      }
    },
    [saveEndDate, saveStartDate, validPeriodVariant]
  );

  useEffect(() => {
    if (
      (offerDateEnd && typeof offerDateEnd === 'string') ||
      offerDateEnd === null
    ) {
      const calculateEndDate = () => {
        const endDate = offerDateEnd ? new Date(offerDateEnd) : new Date();

        if (endDate.getTime() < new Date().getTime()) {
          return new Date();
        }

        if (
          startDateUTC &&
          endDate.getTime() < new Date(startDateUTC).getTime()
        ) {
          return new Date(new Date(startDateUTC).getTime());
        }

        return endDate;
      };

      const date = calculateEndDate();

      setOfferEndDate({
        day: String(date.getDate()),
        month: months[date.getMonth()],
        year: String(date.getFullYear()),
      });
    }
  }, [offerDateEnd, setOfferEndDate, startDateUTC]);

  useEffect(() => {
    if (startDate.day) {
      checkValidation(true);
    }
  }, [checkValidation, endDate.day, startDate.day]);

  useEffect(() => {
    if (startDateUTC && typeof startDateUTC !== 'string') {
      setStartDate({
        day: String(startDateUTC.getDate()),
        month: months[startDateUTC.getMonth()],
        year: String(startDateUTC.getFullYear()),
      });
    }

    if (endDateUTC && typeof endDateUTC !== 'string') {
      setEndDate({
        day: String(endDateUTC.getDate()),
        month: months[endDateUTC.getMonth()],
        year: String(endDateUTC.getFullYear()),
      });
    }
  }, [endDateUTC, startDateUTC]);

  return (
    <InputContainer>
      <FormLabel
        $active={
          !!(!!startDate.day || !!endDate.day) || !!(startDateUTC || endDateUTC)
        }
      >
        Valid period
      </FormLabel>
      <CalendarDropdown data-testid="calendar-dropdown" $isCursorDefault>
        <CountryItem>
          {(startDate.day || endDate.day) && (
            <ActiveListParameter>
              {[startDate, endDate].map(({ day, month, year }, i) => {
                const date = `${day} ${month} ${year}`;
                const disabled = isStartDateDisabled && i === 0;

                return (
                  <DateText
                    key={date}
                    $isStyled={!disabled}
                    $disabled={disabled}
                    onClick={() => openCalendar(i, disabled)}
                  >
                    {day ? date : 'xx xxx xxxx'}
                  </DateText>
                );
              })}
            </ActiveListParameter>
          )}
        </CountryItem>
        <ActionButton
          icon={<CalendarIcon color={theme.colors.funtainBlue} />}
          clickHandler={() => {
            setIsCalendarOpen(true);
            setValidPeriodVariant(
              startDate.day
                ? ValidPeriodVariant.ValidPeriodUntil
                : ValidPeriodVariant.ValidPeriodFrom
            );
          }}
        />
      </CalendarDropdown>
      {isCalendarOpen && (
        <CustomCalendar
          validPeriodVariant={validPeriodVariant}
          validPeriod={
            isStartDateDisabled ? [offerEndDate, endDate] : [startDate, endDate]
          }
          setValidPeriod={setDateToValidPeriod}
          closeCalendar={closeCalendar}
        />
      )}
      {isStartDateDisabled && isOfferExpired && (
        <ErrorText $error>
          <WarningIcon color={theme.colors.primary} />
          The time of the offer has expired
        </ErrorText>
      )}
    </InputContainer>
  );
};

const CalendarDropdown = styled(DropdownInput)`
  svg {
    padding-bottom: 7px;
  }
`;

export default ValidPeriodDropdown;
