import { AxiosProgressEvent } from 'axios';
import React, { useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-query';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar, { NavbarItem } from 'components/actionModal/Navbar';
import OfferPage from 'components/actionModal/stages/offerPage/OfferPage';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import Result from 'components/actionModal/stages/Result';
import CorporateOfferSettings from 'components/actionModal/stages/settings/CorporateOfferSettings';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import { StatusBE } from 'components/detailsModal/models';

import { CorporateOfferContext } from '../../context/CorporateOfferContext';
import useEditCorporateOffer from '../../hooks/useEditCorporateOffer';
import useSaveDataForEditCorporateOffer from '../../hooks/useSaveDataForEditCorporateOffer';
import {
  ImageVariant,
  OfferCategory,
  OfferModalWindowStages,
} from '../../models';
import {
  CorporateOffer,
  editCorporateOffer,
  publishCorporateOffer,
  uploadBackgroundDraft,
  uploadLogoDraft,
} from '../../offersServices/CorporateOffersService';

const NAVBAR_ITEMS: NavbarItem<OfferModalWindowStages>[] = [
  {
    text: 'Settings',
    name: OfferModalWindowStages.Settings,
  },
  {
    text: 'Offer page',
    name: OfferModalWindowStages.OfferPage,
  },
];

const ONE_HOUR = 60;

type EditCorporateModalProps = {
  offer: CorporateOffer;
  closeModalWindow: () => void;
};

const EditCorporateModal = ({
  offer,
  closeModalWindow,
}: EditCorporateModalProps) => {
  const [saved, setSaved] = useState(false);
  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.Settings
  );
  const [activatedStages, setActivatedStages] = useState<
    OfferModalWindowStages[]
  >([OfferModalWindowStages.Settings]);
  const [title, setTitle] = useState('');
  const [logoIsUpdated, setLogoIsUpdated] = useState(false);
  const [backgroundIsUpdated, setBackgroundIsUpdated] = useState(false);
  const [isEndDateExists, setisEndDateExists] = useState(false);
  const [isEndDateChanged, setisEndDateChanged] = useState(false);

  const {
    logoImage,
    logoName,
    offerDraft,
    saveSettings,
    setLogoName,
    setLogoImage,
    backgroundName,
    backgroundImage,
    setBackgroundName,
    setBackgroundImage,
    setCompanyName,
  } = useContext(CorporateOfferContext);

  const uploadLogo = useMutation(uploadLogoDraft);
  const uploadBackground = useMutation(uploadBackgroundDraft);
  const updateOffer = useEditCorporateOffer(setTitle, editCorporateOffer);

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(NAVBAR_ITEMS[stage].name),
    []
  );

  const toggleNextStage = useCallback((disable?: boolean) => {
    if (disable) {
      setActivatedStages([OfferModalWindowStages.Settings]);
    } else {
      setActivatedStages(NAVBAR_ITEMS.map(({ name }) => name));
    }
  }, []);

  const goToSettingsStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.Settings);
  }, []);

  const goToOfferPageStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.OfferPage);
  }, []);

  const goToResultStage = useCallback(async () => {
    setActiveStage(OfferModalWindowStages.Result);

    const {
      unitPrice,
      unitPriceCurrency,
      redemptionLimit,
      endDateUTC,
      offerTerm,
      notes,
    } = offerDraft.settings;

    const isStatusDraft = offer.status === StatusBE.Draft;

    if (isStatusDraft) {
      await publishCorporateOffer({ id: offer.id });
    }

    let endDateCopy = null;

    if (endDateUTC && isEndDateExists && !isEndDateChanged) {
      endDateCopy = endDateUTC;
      const timeOffset = endDateUTC.getTimezoneOffset() / ONE_HOUR;

      if (timeOffset < 0) {
        endDateCopy.setHours(endDateCopy.getHours() + Math.abs(timeOffset));
      } else {
        endDateCopy.setHours(endDateCopy.getHours() - timeOffset);
      }
    }

    if (endDateUTC) {
      endDateCopy = endDateUTC;
    }

    const offerForEdit = {
      notes: notes,
      unitPrice,
      unitPriceCurrency,
      redemptionLimit,
      endDateUTC: endDateCopy,
      offerTerm,
      isLogoUpdated: isStatusDraft ? false : logoIsUpdated,
      isBackgroundUpdated: isStatusDraft ? false : backgroundIsUpdated,
    };

    updateOffer.mutate({
      corporateOfferId: offer.id,
      data: {
        ...offerForEdit,
      },
    });
  }, [
    offerDraft.settings,
    offer.status,
    offer.id,
    isEndDateExists,
    isEndDateChanged,
    logoIsUpdated,
    backgroundIsUpdated,
    updateOffer,
  ]);

  const saveImageName = useCallback(
    (name: string, imageVariant: ImageVariant) => {
      if (imageVariant === ImageVariant.Logo) {
        setLogoName(name);
      } else {
        setBackgroundName(name);
      }
    },
    [setLogoName, setBackgroundName]
  );

  const saveImage = useCallback(
    async (
      imageURL: string,
      imageVariant: ImageVariant,
      openError: (value: boolean) => void,
      setErrorText: (value: string) => void,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      const { id } = offer;

      if (id && file) {
        const options = {
          onUploadProgress: (progress: AxiosProgressEvent) => {
            const { loaded, total } = progress;

            if (textWithPercentages && total) {
              textWithPercentages.textContent = `${Math.round(
                (loaded / total) * 100
              )}%`;
            }
          },
        };
        const formData = new FormData();

        formData.append('payload', file);

        const uploadedData = {
          id,
          options,
          formData,
          openError,
          setErrorText,
        };

        if (imageVariant === ImageVariant.Logo) {
          await uploadLogo.mutateAsync(uploadedData);
          setLogoImage(imageURL);
          setLogoIsUpdated(true);
        } else {
          await uploadBackground.mutateAsync(uploadedData);
          setBackgroundImage(imageURL);
          setBackgroundIsUpdated(true);
        }
      } else if (imageVariant === ImageVariant.Logo) {
        setLogoImage(imageURL);
        setLogoIsUpdated(false);
      } else {
        setBackgroundImage(imageURL);
        setBackgroundIsUpdated(false);
      }
    },
    [
      setBackgroundImage,
      setLogoImage,
      setLogoIsUpdated,
      setBackgroundIsUpdated,
      offer,
      uploadLogo,
      uploadBackground,
    ]
  );

  useSaveDataForEditCorporateOffer(
    offer,
    setSaved,
    saveSettings,
    setLogoImage,
    setBackgroundImage,
    setLogoName,
    setBackgroundName,
    setCompanyName,
    setisEndDateExists,
    saved
  );

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {activeStage === OfferModalWindowStages.Result ? (
        <Result title={title} closeModal={closeModalWindow} />
      ) : (
        <>
          <Navbar
            title="Update offer"
            activeStage={activeStage}
            showTitle
            accountCategory={OfferCategory.Corporate}
            navbarItems={NAVBAR_ITEMS}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
          />
          <ActionModalMainContent>
            <CorporateOfferSettings
              isShowStage={activeStage === OfferModalWindowStages.Settings}
              isEdit
              offer={offer}
              title="Settings"
              pendingStatus={false}
              isOfferCodeError={false}
              saveSettings={saveSettings}
              settings={offerDraft.settings}
              setisEndDateChanged={setisEndDateChanged}
              goToNextStage={goToOfferPageStage}
              toggleNextStage={toggleNextStage}
            />
            <OfferPage
              isShowStage={activeStage === OfferModalWindowStages.OfferPage}
              isEdit
              previewOffer={PreviewOffer.Corporate}
              title="Offer page"
              logoImage={logoImage}
              logoName={logoName}
              backgroundName={backgroundName}
              backgroundImage={backgroundImage}
              saveImage={saveImage}
              saveImageName={saveImageName}
              submitButtonTitle="Update"
              goToNextStage={goToResultStage}
              goToPreviousStage={goToSettingsStage}
            />
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default EditCorporateModal;
