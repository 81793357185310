import React from 'react';

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.555"
    height="23.999"
    viewBox="0 0 11.555 23.999"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 13164"
          d="M11.7-6.162a1 1 0 0 0-.924-.617H7.75a.249.249 0 0 1-.177-.073.249.249 0 0 1-.073-.177V-22.5A1.5 1.5 0 0 0 6-24a1.5 1.5 0 0 0-1.5 1.5v15.471a.25.25 0 0 1-.25.25H1.222a1 1 0 0 0-.922.617 1 1 0 0 0 .217 1.09L5.292-.294A1 1 0 0 0 6 0a1 1 0 0 0 .707-.293l4.778-4.778a1 1 0 0 0 .215-1.091Z"
          fill="#414e71"
        />
      </clipPath>
    </defs>
    <g
      data-name="Group 15365"
      transform="translate(-.222 24)"
      clipPath="url(#a)"
    >
      <path
        data-name="Path 13163"
        d="M-4.778-29h21.555V5H-4.778Z"
        fill="#414e71"
      />
    </g>
  </svg>
);

export default Arrow;
