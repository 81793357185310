import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

import useEscapeClick from 'hooks/useEscapeClick';
import useOutsideClick from 'hooks/useOutsideClick';

import CloseBlock from './CloseBlock';

type ActionModalProps = {
  isEmployerModal?: boolean;
  children: ReactNode;
  closeModalWindow: () => void;
};

const ActionModal = ({
  children,
  isEmployerModal,
  closeModalWindow,
}: ActionModalProps) => {
  const modalWindowRef = useRef<HTMLDivElement>(null);

  useEscapeClick(modalWindowRef, closeModalWindow);
  useOutsideClick(modalWindowRef, closeModalWindow);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <Container>
      <Content
        $isEmployerModal={isEmployerModal}
        id="action_window"
        data-testid="action_window"
        ref={modalWindowRef}
      >
        <CloseBlock closeModalWindow={closeModalWindow} />
        {children}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 50%);
  z-index: 20;
`;

const Content = styled.div<{ $isEmployerModal?: boolean }>`
  position: relative;
  top: ${({ $isEmployerModal }) => ($isEmployerModal ? '0px' : '-30px')};
  width: 97%;
  height: 85%;
  max-width: 1326px;
  max-height: 700px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;

  @media (height <= 850px) {
    top: 0;
    height: 95%;
  }
`;

export default ActionModal;
