import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import GarbageIcon from 'components/icons/GarbageIcon';

import {
  ActionButtonContainer,
  FileName,
} from '../../styledElements/stagesStyledElements';

type UploadResultProps = {
  isPromotionUpload?: boolean;
  url: string;
  fileName: string;
  isFirstChild?: boolean;
  uploadSuggestionText: string;
  clickHandler: () => void;
};

const UploadResult = ({
  isPromotionUpload,
  url,
  isFirstChild,
  fileName,
  uploadSuggestionText,
  clickHandler,
}: UploadResultProps) => {
  const isSvg = useMemo(() => {
    const urlEnding = url.split('.');

    if (
      urlEnding[urlEnding.length - 1] === 'svg' ||
      url.substring(0, 14) === 'data:image/svg'
    )
      return true;

    return false;
  }, [url]);

  const renderImage = () => {
    if (isSvg) {
      return (
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute('class', 'loaded-image');
          }}
          src={url}
        />
      );
    }

    return (
      <img
        src={url}
        className={isPromotionUpload ? 'promotion-image' : 'loaded-image'}
      />
    );
  };

  return (
    <Container $isFirstChild={isFirstChild}>
      <ActionButtonContainerWithAbsolutePositioning
        onClick={clickHandler}
        data-testid="upload-result-button-container"
      >
        <ActionButton icon={<GarbageIcon color={theme.colors.funtainBlue} />} />
      </ActionButtonContainerWithAbsolutePositioning>

      {renderImage()}

      <NamePhotoWrapper>
        <FileName>{fileName}</FileName>

        {!isPromotionUpload && (
          <FileName>
            {uploadSuggestionText.split(' ')[1].toUpperCase()}
          </FileName>
        )}
      </NamePhotoWrapper>
    </Container>
  );
};

const ActionButtonContainerWithAbsolutePositioning = styled(
  ActionButtonContainer
)`
  position: absolute;
  top: 50%;
  left: -24px;
  transform: translate(0, -50%);
`;

const Container = styled.div<{ $isFirstChild?: boolean }>`
  position: relative;
  left: 2px;
  display: flex;
  align-items: center;
  margin-bottom: 41px;
  ${({ $isFirstChild }) =>
    $isFirstChild &&
    css`
      margin-top: 38px;
    `}

  @media (width <= 1250px) {
    width: 85%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 20px;
  }

  img.loaded-image,
  svg.loaded-image {
    max-width: 199px;
    min-width: 199px;
    max-height: 109px;
    min-height: 109px;
    background-size: 100% 100%;
    margin-right: 19px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-right: 10px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-width: 150px;
      min-width: 150px;
      max-height: 75px;
      min-height: 75px;
    }
  }

  img.promotion-image {
    width: 110px;
    height: 110px;
    object-fit: contain;
    margin-right: 17px;
  }
`;

const NamePhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default React.memo(UploadResult);
