/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { ErrorMessage } from '@hookform/error-message';
import WarningIcon from 'components/icons/WarningIcon';
import patterns from 'helpers/patterns';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import { FormInput, FormLabel } from '../../styledElements/formsStyledElements';

type Props = {
  inputId: string;
  inputValue: string;
  errors: FieldErrors;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  saveData: () => void;
};

const RedemptionLimitInput = ({
  watch,
  errors,
  register,
  setValue,
  inputId,
  saveData,
  inputValue,
}: Props) => {
  const [isRedemptionLimitActive, setIsRedemptionLimitActive] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (e.target.value.match(patterns.redemptionLimitPattern)) {
      const dots = value.match(/[.]+/g);

      if ((dots?.length === 1 || !dots) && value[0] !== '0') {
        setValue(name, value, { shouldValidate: true });
      }
    }
  };

  useLayoutEffect(() => {
    if (inputValue) {
      setIsRedemptionLimitActive(true);
      setValue(inputId, inputValue, { shouldValidate: true });
    }
  }, [inputId, inputValue, setValue]);

  return (
    <InputContainer $error={!!errors[inputId]}>
      <FormLabel
        htmlFor={inputId}
        $error={!!errors[inputId]}
        $active={!!(isRedemptionLimitActive || inputValue)}
      >
        Redemption limit
      </FormLabel>
      <FormInputWithLetterSpacing
        id={inputId}
        {...register(inputId, {
          required: true,
        })}
        onBlur={saveData}
        onChange={(e) => changeInputHandler(e)}
        $error={!!errors[inputId]}
        onFocus={() => setIsRedemptionLimitActive(true)}
        value={watch()[inputId] || ''}
      />
      <ErrorMessage
        name={inputId}
        errors={errors}
        render={({ message }) => (
          <ErrorText $error={!!errors[inputId]}>
            <WarningIcon color={theme.colors.primary} />
            {message}
          </ErrorText>
        )}
      />
    </InputContainer>
  );
};

const FormInputWithLetterSpacing = styled(FormInput)`
  letter-spacing: 4px;
`;

export default RedemptionLimitInput;
