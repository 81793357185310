import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import ListForSelectingDataFE from 'components/listForSelectingData/lists/ListForSelectingDataFE';
import { InputContainer } from 'modules/auth/components/styledElements/formsStyledElements';

import {
  ActiveListParameter,
  CountryItem,
  DropdownInput,
  FormLabel,
} from '../../styledElements/formsStyledElements';

type StandardListInputProps = {
  isDisabledDropdown?: boolean;
  listLabel: string;
  inputValue: string;
  inputLabel: string;
  list: {
    id: string;
    displayName: string;
    BEformat: string;
    disabled?: boolean;
  }[];
  saveData: (value: string) => void;
  checkValidation?: (isValid: boolean) => void;
};

const StandardDropdown = ({
  list,
  saveData,
  listLabel,
  inputValue,
  inputLabel,
  isDisabledDropdown,
  checkValidation,
}: StandardListInputProps) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const closeList = useCallback(() => setIsListOpen(false), []);

  const saveChosenItem = useCallback(
    (index: string) => {
      const listItemIndex = Number(index);

      saveData(list[listItemIndex].BEformat);
    },
    [list, saveData]
  );

  const openList = useCallback(
    () => !isDisabledDropdown && setIsListOpen(true),
    [isDisabledDropdown]
  );

  useEffect(() => {
    if (inputValue) {
      checkValidation && checkValidation(true);
    }
  }, [checkValidation, inputValue]);

  return (
    <InputContainer $emptyInputs={-1} $disabled={isDisabledDropdown}>
      <FormLabel $isCursorPointer $active={!!inputValue} onClick={openList}>
        {inputLabel}
      </FormLabel>
      <DropdownInput onClick={openList}>
        <CountryItem>
          <ActiveListParameter>
            {find(list, { BEformat: inputValue })?.displayName}
          </ActiveListParameter>
        </CountryItem>
        <ActionButton icon={<ChevronIcon color={theme.colors.funtainBlue} />} />
      </DropdownInput>
      {isListOpen && (
        <ListForSelectingDataFE
          listWidth="lg"
          listHeight="md"
          data={list}
          label={listLabel}
          closeList={closeList}
          setNewActiveListItem={saveChosenItem}
        />
      )}
    </InputContainer>
  );
};

export default StandardDropdown;
