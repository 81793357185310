import React, { useState } from 'react';

import AdminVerifyCodeForm from 'modules/auth/forms/adminForms/AdminVerifyCodeForm';

import { AuthStage } from '../../../models';
import Header from '../../Header';
import ResendCodePanel from '../../ResendCodePanel';

type Props = {
  email: string;
  lastTwoDigitsOnMobile: string;
  goToNextStage: () => void;
};

const AdminVerifyIdentity = ({
  email,
  goToNextStage,
  lastTwoDigitsOnMobile,
}: Props) => {
  const [isResendCodePanelOpen, setIsResendCodePanelOpen] = useState(false);

  return (
    <>
      <ResendCodePanel
        email={email}
        isOpen={isResendCodePanelOpen}
        closePanel={() => setIsResendCodePanelOpen(false)}
      />
      <Header
        headerType={AuthStage.Verify}
        lastTwoDigitsOnMobile={lastTwoDigitsOnMobile}
      />
      <div>
        <AdminVerifyCodeForm
          email={email}
          goToNextStage={goToNextStage}
          openResendPanel={() => setIsResendCodePanelOpen(true)}
          closeResendPanel={() => setIsResendCodePanelOpen(false)}
        />
      </div>
    </>
  );
};

export default AdminVerifyIdentity;
