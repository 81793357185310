import React from 'react';
import styled, { css } from 'styled-components';

export type NavbarItem<T = string> = {
  text: string;
  name: T;
};

type NavbarProps = {
  title: string;
  activeStage: string;
  showTitle?: boolean;
  accountCategory: string;
  navbarItems: NavbarItem[];
  activatedStages: string[];
  changeActiveStage: (stage: number) => void;
};

const Navbar = ({
  title,
  activeStage,
  navbarItems,
  showTitle,
  activatedStages,
  accountCategory,
  changeActiveStage,
}: NavbarProps) => (
  <Container>
    <Title>
      {title}
      {showTitle && (
        <>
          :&nbsp;
          <ChosenCategory>{accountCategory.toLowerCase()}</ChosenCategory>
        </>
      )}
    </Title>
    <Navigation>
      {navbarItems.map(({ text, name }, i) => {
        const isAvailableItem = activatedStages.includes(name);

        return (
          <Item
            key={name}
            onClick={() => changeActiveStage(i)}
            $disabled={!isAvailableItem}
          >
            <StageNumber $isActive={activeStage === name}>{i + 1}</StageNumber>
            <Text>{text}</Text>
          </Item>
        );
      })}
    </Navigation>
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  align-content: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 37px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ChosenCategory = styled.p`
  font-size: 20px;
  font-weight: 300;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.button<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  width: fit-content;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;

const StageNumber = styled.div<{ $isActive: boolean }>`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  margin-right: 9px;
  border: 3px solid ${({ theme }) => theme.colors.funtainBlue};
  background: none;
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 14px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.typeface};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.funtainBlue};
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 25px;
    height: 25px;
    font-size: 13px;
    margin-right: 5px;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.typeface};
  color: ${({ theme }) => theme.colors.funtainBlue};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 13px;
  }
`;

export default Navbar;
