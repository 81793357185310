import BrainHealthIcon from 'components/icons/BrainHealthIcon';
import EnergyIcon from 'components/icons/EnergyIcon';
import ImmuneIcon from 'components/icons/ImmuneIcon';
import SleepIcon from 'components/icons/SleepIcon';
import StressIcon from 'components/icons/StressIcon';
import WeightIcon from 'components/icons/WeightIcon';

export enum TopicType {
  BRAIN = 'brain',
  ENERGY = 'energy',
  WEIGHT = 'weight',
  IMMUNE = 'immune',
  STRESS = 'stress',
  SLEEP = 'sleep',
}

export type HealthImprovementCategory = {
  id: number;
  text: string;
  topicValue: TopicType;
};

export const TopicIconMap: Record<TopicType, () => JSX.Element> = {
  [TopicType.BRAIN]: BrainHealthIcon,
  [TopicType.ENERGY]: EnergyIcon,
  [TopicType.WEIGHT]: WeightIcon,
  [TopicType.IMMUNE]: ImmuneIcon,
  [TopicType.STRESS]: StressIcon,
  [TopicType.SLEEP]: SleepIcon,
};

export const HealthImprovementCategoriesData: HealthImprovementCategory[] = [
  {
    id: 1,
    text: 'Weight loss',
    topicValue: TopicType.WEIGHT,
  },
  {
    id: 2,
    text: 'Stress, anxiety & low mood',
    topicValue: TopicType.STRESS,
  },
  {
    id: 3,
    text: 'Sleep & relaxation',
    topicValue: TopicType.SLEEP,
  },
  {
    id: 4,
    text: 'Energy & vitality',
    topicValue: TopicType.ENERGY,
  },
  {
    id: 5,
    text: 'Immune & gut health',
    topicValue: TopicType.IMMUNE,
  },
  {
    id: 6,
    text: 'Brain health, focus & memory',
    topicValue: TopicType.BRAIN,
  },
];
