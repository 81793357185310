import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DashboardBarChart from 'modules/dashboard/components/DashboardBarChart';
import DashboardChip from 'modules/dashboard/components/DashboardChip';
import DashboardGraphCard from 'modules/dashboard/components/DashboardGraphCard';
import DashboardLineChart from 'modules/dashboard/components/DashboardLineChart';
import NoDataMessage from 'modules/dashboard/components/NoDataMessage';
import getAffiliateOffersStats, {
  AffiliateOffersStatName,
  getActiveAffiliateOffersStats,
} from 'modules/dashboard/helpers/getAffiliateOffersStats';
import getCorporateOffersStats, {
  CorporateOffersStatName,
  getActiveCorporateOffersStats,
} from 'modules/dashboard/helpers/getCorporateOffersStats';
import getPromotionOffersStats, {
  PromotionOffersStatName,
  getActivePromotionOffersStats,
} from 'modules/dashboard/helpers/getPromotionOffersStats';
import { getRatingBetween } from 'modules/dashboard/helpers/getRatingBetween';
import {
  AffiliateOfferResponse,
  PromotionOfferResponse,
} from 'modules/offers/models';
import { CorporateOffer } from 'modules/offers/offersServices/CorporateOffersService';

import { OffersParam } from './OffersCategory';

type SubtitleChip = { title: string; value: string };

const getSubtitleChips = ({
  nowOfferParam,
  affiliateOffers,
  corporateOffers,
  promotionOffers,
}: Pick<
  Props,
  'nowOfferParam' | 'affiliateOffers' | 'corporateOffers' | 'promotionOffers'
>): SubtitleChip[] => {
  switch (nowOfferParam) {
    case OffersParam.Affiliate:
      return affiliateOffers?.length
        ? [
            {
              title: 'Last offer created at',
              value: format(
                new Date(affiliateOffers[0].createdDateUtc),
                'yyyy-LL-dd hh:mm a'
              ),
            },
          ]
        : [];

    case OffersParam.Corporate:
      return corporateOffers?.length
        ? [
            {
              title: 'Last offer created at',
              value: format(
                new Date(corporateOffers[0].createdDate),
                'yyyy-LL-dd hh:mm a'
              ),
            },
          ]
        : [];
      break;
    case OffersParam.Promotion:
      return promotionOffers?.length
        ? [
            {
              title: 'Last offer created at',
              value: format(
                new Date(promotionOffers[0].createdDateUtc),
                'yyyy-LL-dd hh:mm a'
              ),
            },
          ]
        : [];
      break;
  }
};

const getShowNoDataMessage = ({
  nowOfferParam,
  affiliateOffers,
  corporateOffers,
  promotionOffers,
}: Pick<
  Props,
  'nowOfferParam' | 'affiliateOffers' | 'corporateOffers' | 'promotionOffers'
>) => {
  switch (nowOfferParam) {
    case OffersParam.Affiliate:
      return !affiliateOffers?.length;
    case OffersParam.Corporate:
      return !corporateOffers?.length;
    case OffersParam.Promotion:
      return !promotionOffers?.length;
    default:
      return false;
  }
};

type Props = {
  nowOfferParam: OffersParam;
  affiliateOffers?: AffiliateOfferResponse[];
  corporateOffers?: CorporateOffer[];
  promotionOffers?: PromotionOfferResponse[];
};

const OffersContent = ({
  affiliateOffers,
  corporateOffers,
  promotionOffers,
  nowOfferParam,
}: Props) => {
  const [activeAffiliateParams, setActiveAffiliateParams] = useState<
    Record<AffiliateOffersStatName, number>
  >({
    conversionFunnels: 0,
    bgColors: 0,
    promotionTerms: 0,
  });
  const [activeCorporateParams, setActiveCorporateParams] = useState<
    Record<CorporateOffersStatName, number>
  >({
    countries: 0,
    createdDates: 0,
    offerTerms: 0,
  });
  const [activePromotionParams, setActivePromotionParams] = useState<
    Record<PromotionOffersStatName, number>
  >({
    bgColors: 0,
    createdDates: 0,
    promotionTerms: 0,
  });

  const changeActiveAffiliateParam =
    (param: AffiliateOffersStatName) => (value: number) =>
      setActiveAffiliateParams({ ...activeAffiliateParams, [param]: value });
  const changeActiveCorporateParam =
    (param: CorporateOffersStatName) => (value: number) =>
      setActiveCorporateParams({ ...activeCorporateParams, [param]: value });
  const changeActivePromotionParam =
    (param: PromotionOffersStatName) => (value: number) =>
      setActivePromotionParams({ ...activePromotionParams, [param]: value });

  const affiliateOffersStats = useMemo(
    () => getAffiliateOffersStats(affiliateOffers || []),
    [affiliateOffers]
  );
  const corporateOffersStats = useMemo(
    () => getCorporateOffersStats(corporateOffers || []),
    [corporateOffers]
  );
  const promotionOffersStats = useMemo(
    () => getPromotionOffersStats(promotionOffers || []),
    [promotionOffers]
  );

  const activeAffiliateOffersStats = getActiveAffiliateOffersStats(
    affiliateOffersStats,
    activeAffiliateParams
  );

  const activeCorporateOffersStats = getActiveCorporateOffersStats(
    corporateOffersStats,
    activeCorporateParams
  );

  const corporateCreatedDatesRating = getRatingBetween(
    activeCorporateOffersStats.createdDates[0]?.offers.length,
    activeCorporateOffersStats.createdDates[
      activeCorporateOffersStats.createdDates.length - 1
    ]?.offers.length
  );

  const activePromotionOffersStats = getActivePromotionOffersStats(
    promotionOffersStats,
    activePromotionParams
  );

  const promotionCreatedDateRating = getRatingBetween(
    activePromotionOffersStats.createdDates[0]?.offers.length,
    activePromotionOffersStats.createdDates[
      activePromotionOffersStats.createdDates.length - 1
    ]?.offers.length
  );

  const subtitleChips = getSubtitleChips({
    nowOfferParam,
    affiliateOffers,
    corporateOffers,
    promotionOffers,
  });

  const showNoDataMessage = getShowNoDataMessage({
    nowOfferParam,
    affiliateOffers,
    corporateOffers,
    promotionOffers,
  });

  return (
    <HeaderSection>
      <Content>
        <HeaderTitle>{nowOfferParam} Offers</HeaderTitle>
        <SubTitleInfo>
          {subtitleChips.map(({ title, value }) => (
            <DashboardChip key={title}>
              <ChipText>
                {title}: <span>{value}</span>
              </ChipText>
            </DashboardChip>
          ))}
        </SubTitleInfo>
        <GraphsContainer $isNoData={showNoDataMessage}>
          {showNoDataMessage ? (
            <NoDataMessage withoutBg />
          ) : (
            <>
              {nowOfferParam === OffersParam.Affiliate && (
                <>
                  <DashboardGraphCard
                    title="Conversion funnels"
                    params={{
                      values: affiliateOffersStats.conversionFunnels.map(
                        ({ name }) => name
                      ),
                      activeParam: activeAffiliateParams.conversionFunnels,
                      setActiveParam:
                        changeActiveAffiliateParam('conversionFunnels'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activeAffiliateOffersStats.conversionFunnels.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                  <DashboardGraphCard
                    title="Background Colors"
                    params={{
                      values: affiliateOffersStats.bgColors.map(
                        ({ name }) => name
                      ),
                      activeParam: activeAffiliateParams.bgColors,
                      setActiveParam: changeActiveAffiliateParam('bgColors'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activeAffiliateOffersStats.bgColors.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                  <DashboardGraphCard
                    title="Promotion Terms"
                    params={{
                      values: affiliateOffersStats.promotionTerms.map(
                        ({ name }) => name
                      ),
                      activeParam: activeAffiliateParams.promotionTerms,
                      setActiveParam:
                        changeActiveAffiliateParam('promotionTerms'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activeAffiliateOffersStats.promotionTerms.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                </>
              )}
              {nowOfferParam === OffersParam.Corporate && (
                <>
                  <DashboardGraphCard
                    title="Countries"
                    params={{
                      values: corporateOffersStats.countries.map(
                        ({ name }) => name
                      ),
                      activeParam: activeCorporateParams.countries,
                      setActiveParam: changeActiveCorporateParam('countries'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activeCorporateOffersStats.countries.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                  <DashboardGraphCard
                    title="Offer Terms"
                    params={{
                      values: corporateOffersStats.offerTerms.map(
                        ({ name }) => name
                      ),
                      activeParam: activeCorporateParams.offerTerms,
                      setActiveParam: changeActiveCorporateParam('offerTerms'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activeCorporateOffersStats.offerTerms.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                  <DashboardGraphCard
                    title="Offers Created"
                    params={{
                      values: corporateOffersStats.createdDates.map(
                        ({ name }) => name
                      ),
                      activeParam: activeCorporateParams.createdDates,
                      setActiveParam:
                        changeActiveCorporateParam('createdDates'),
                    }}
                    rating={{
                      percentage: corporateCreatedDatesRating.diffPercentage,
                      result: corporateCreatedDatesRating.result,
                    }}
                    chart={
                      <DashboardLineChart
                        data={activeCorporateOffersStats.createdDates.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        lines={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                </>
              )}
              {nowOfferParam === OffersParam.Promotion && (
                <>
                  <DashboardGraphCard
                    title="Background Colors"
                    params={{
                      values: promotionOffersStats.bgColors.map(
                        ({ name }) => name
                      ),
                      activeParam: activePromotionParams.bgColors,
                      setActiveParam: changeActivePromotionParam('bgColors'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activePromotionOffersStats.bgColors.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                  <DashboardGraphCard
                    title="Offers Created"
                    params={{
                      values: promotionOffersStats.createdDates.map(
                        ({ name }) => name
                      ),
                      activeParam: activePromotionParams.createdDates,
                      setActiveParam:
                        changeActivePromotionParam('createdDates'),
                    }}
                    rating={{
                      percentage: promotionCreatedDateRating.diffPercentage,
                      result: promotionCreatedDateRating.result,
                    }}
                    chart={
                      <DashboardLineChart
                        data={activePromotionOffersStats.createdDates.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        lines={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                  <DashboardGraphCard
                    title="Promotion Terms"
                    params={{
                      values: promotionOffersStats.promotionTerms.map(
                        ({ name }) => name
                      ),
                      activeParam: activePromotionParams.promotionTerms,
                      setActiveParam:
                        changeActivePromotionParam('promotionTerms'),
                    }}
                    chart={
                      <DashboardBarChart
                        data={activePromotionOffersStats.promotionTerms.map(
                          ({ name, offers }) => ({
                            name,
                            Count: offers.length,
                          })
                        )}
                        bars={[
                          {
                            name: 'Count',
                            color: theme.colors.lightBlue,
                          },
                        ]}
                      />
                    }
                  />
                </>
              )}
            </>
          )}
        </GraphsContainer>
      </Content>
    </HeaderSection>
  );
};

const GraphsContainer = styled.div<{ $isNoData?: boolean }>`
  margin: ${({ $isNoData }) => ($isNoData ? '0' : '80px 0')};
  display: flex;
  width: 100%;
  gap: 30px;
`;

const ChipText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};

  span {
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const SubTitleInfo = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 10px;
`;

const HeaderTitle = styled.h1`
  font-weight: 400;
  font-size: 40px;
  padding-top: 76px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const Content = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderSection = styled.section`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  min-height: 600px;
  background:
    url('/images/insights_background_additional.png') center bottom no-repeat,
    url('/images/insights_background.png') 39% 31% / contain no-repeat
      ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default OffersContent;
