import React from 'react';
import styled, { css } from 'styled-components';

import AffiliateArrow from 'components/icons/AffiliateArror';
import { LogoIcon } from 'components/icons/LogoIcon';

type ResponseSectionProps = {
  isDesktop?: boolean;
  name: string;
  statement: string;
  handle: string;
  image: string | null;
  goToNextSection: () => void;
};

const ResponseSection = (
  {
    isDesktop,
    name,
    statement,
    handle,
    image,
    goToNextSection,
  }: ResponseSectionProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <Container ref={ref} $isDesktop={isDesktop}>
      <TitleName>{name}</TitleName>
      <Subtitle>{handle}</Subtitle>
      <ParagraphStatement>{statement}</ParagraphStatement>
      <Paragraph>Join Shido today to start a 1 month free trial.</Paragraph>
      <Paragraph>No commitments, cancel anytime.</Paragraph>

      <ImageContainer>
        {image ? <img src={image} /> : <LogoIcon width={40} height={20} />}
      </ImageContainer>
      <ArrowWrapper onClick={goToNextSection} $isDesktop={isDesktop}>
        <AffiliateArrow />
      </ArrowWrapper>
    </Container>
  );
};

const TitleName = styled.div`
  text-align: center;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-word;
`;

const Subtitle = styled(TitleName)`
  margin-top: 20px;
  font-size: 20px;
`;

const Paragraph = styled(TitleName)`
  font-weight: 300;
  font-size: 20px;
`;

const ParagraphStatement = styled(Paragraph)`
  margin-bottom: 30px;
  margin-top: 20px;
  line-height: 30px;
  word-break: break-word;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  box-shadow: -1px 1px 10px -4px grey;
  background: white;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;

const ArrowWrapper = styled.div<{ $isDesktop?: boolean }>`
  text-align: center;
  cursor: pointer;
  margin: 19px auto 0;
  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      margin: 41px auto 0;
    `}
`;

const Container = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '1400px' : '1200px')};
  margin: 30px auto 0;
  padding-bottom: 84px;
  padding-top: 80px;
  border-bottom: 1px dashed rgb(216 218 224);
  position: relative;
  z-index: 3;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin: 36px auto 0;
    `}
`;

export default React.forwardRef(ResponseSection);
