import React, { useCallback, useState } from 'react';

import ShidoLogoWithTextIcon from 'components/icons/ShidoLogoWithTextIcon';
import Success from 'modules/auth/components/stages/adminLoginStages/Success';
import { AuthStage, SuccessAuth } from 'modules/auth/models';

import AdminEmailStage from '../components/stages/adminLoginStages/AdminEmailStage';
import AdminVerifyIdentity from '../components/stages/adminLoginStages/AdminVerifyIdentity';
import {
  BackgroundImage,
  Container,
  LogoWrapper,
  SignInModal,
} from '../components/styledElements/pagesStyledElements';
import ReferrerPasswordForm from '../forms/referrerForms/ReferrerPasswordForm';

const ReferrerRegister: React.FC = () => {
  const [referrerRegisterStage, setReferrerRegisterStage] = useState<AuthStage>(
    AuthStage.SignIn
  );

  const goToVerifyStage = useCallback(() => {
    setReferrerRegisterStage(AuthStage.Verify);
  }, []);

  const goToSuccessStage = useCallback(() => {
    setReferrerRegisterStage(AuthStage.Success);
  }, []);

  return (
    <Container>
      {referrerRegisterStage !== AuthStage.Success && (
        <>
          <LogoWrapper>
            <ShidoLogoWithTextIcon />
          </LogoWrapper>
          <SignInModal>
            {referrerRegisterStage === AuthStage.SignIn && (
              <AdminEmailStage goToNextStage={goToVerifyStage} />
            )}
            {referrerRegisterStage === AuthStage.Verify && (
              <AdminVerifyIdentity
                email="test@shido.com"
                lastTwoDigitsOnMobile="33"
                goToNextStage={() =>
                  setReferrerRegisterStage(AuthStage.Password)
                }
              />
            )}
            {referrerRegisterStage === AuthStage.Password && (
              <ReferrerPasswordForm goToNextStage={goToSuccessStage} />
            )}
          </SignInModal>
        </>
      )}
      {referrerRegisterStage === AuthStage.Success && (
        <Success successType={SuccessAuth.ReferrerRegister} />
      )}
      <BackgroundImage src="/images/background.svg" />
    </Container>
  );
};

export default ReferrerRegister;
