import React from 'react';

type AppstoreIconProps = {
  color: string;
};

const AppstoreIcon = ({ color }: AppstoreIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="17.643"
    height="22.941"
    viewBox="0 0 17.643 22.941"
  >
    <defs>
      <clipPath id="appstore-clip-path">
        <path
          id="Path_8768"
          data-name="Path 8768"
          d="M15.58-10.71a4.82,4.82,0,0,1,1.94-3.87.5.5,0,0,0,.189-.345.5.5,0,0,0-.119-.375A5.16,5.16,0,0,0,14-17h0c-1.69-.16-3.3,1-4.16,1s-2.26-1-3.66-1a5.34,5.34,0,0,0-4.51,2.76C-.25-10.9,1.18-6,3-3.26,3.91-1.94,5-.45,6.44-.5s1.9-.89,3.57-.89,2.14.89,3.6.86S16.08-1.88,17-3.2a12.23,12.23,0,0,0,1.37-2.67.5.5,0,0,0-.23-.61A4.76,4.76,0,0,1,15.58-10.71Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="appstore-clip-path-2">
        <path
          id="Path_8770"
          data-name="Path 8770"
          d="M13-19.83a5.24,5.24,0,0,0,1.22-3.09.5.5,0,0,0-.176-.4.5.5,0,0,0-.424-.109,5.35,5.35,0,0,0-2.81,1.67,5.07,5.07,0,0,0-1.25,3,.5.5,0,0,0,.16.379.5.5,0,0,0,.39.131A4.48,4.48,0,0,0,13-19.83Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_10939"
      data-name="Group 10939"
      transform="translate(-0.757 23.44)"
    >
      <g
        id="Group_10937"
        data-name="Group 10937"
        clipPath="url(#appstore-clip-path)"
      >
        <path
          id="Path_8767"
          data-name="Path 8767"
          d="M-4.243-22.015H23.4V4.5H-4.243Z"
          fill={color}
        />
      </g>
      <g
        id="Group_10938"
        data-name="Group 10938"
        clipPath="url(#appstore-clip-path-2)"
      >
        <path
          id="Path_8769"
          data-name="Path 8769"
          d="M4.56-28.44H19.22v15.193H4.56Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default AppstoreIcon;
