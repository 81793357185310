import { compareAsc, isAfter } from 'date-fns';

import { PromotionOfferResponse } from 'modules/offers/models';

import {
  PromotionOffersDashboardGroup,
  PromotionOffersGroupBy,
  groupPromotionOffers,
} from '../helpers/groupOffers';

const descSort = (
  aGroup: PromotionOffersDashboardGroup,
  bGroup: PromotionOffersDashboardGroup
) => bGroup.offers.length - aGroup.offers.length;

const offerDateSort = (
  aOffer: PromotionOfferResponse,
  bOffer: PromotionOfferResponse
) =>
  compareAsc(new Date(aOffer.createdDateUtc), new Date(bOffer.createdDateUtc));

const getPromotionOffersStats = (offers: PromotionOfferResponse[]) => {
  const now = new Date();
  const thisYear = new Date(now.getFullYear(), 0, 1);
  const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  return {
    bgColors: [
      {
        name: 'All Time',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.BackgroundColor,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.BackgroundColor,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.BackgroundColor,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisMonth)
          )
        ).sort(descSort),
      },
    ],
    createdDates: [
      {
        name: 'All Time',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.CreatedDateAllTime,
          [...offers].sort(offerDateSort)
        ),
      },
      {
        name: 'This Year',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.CreatedDateYear,
          offers
            .filter((offer) =>
              isAfter(new Date(offer.createdDateUtc), thisYear)
            )
            .sort(offerDateSort)
        ),
      },
      {
        name: 'This Month',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.CreatedDateMonth,
          offers
            .filter((offer) =>
              isAfter(new Date(offer.createdDateUtc), thisYear)
            )
            .sort(offerDateSort)
        ),
      },
    ],
    promotionTerms: [
      {
        name: 'All Time',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.PromotionTerm,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.PromotionTerm,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupPromotionOffers(
          PromotionOffersGroupBy.PromotionTerm,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisMonth)
          )
        ).sort(descSort),
      },
    ],
  };
};

export type PromotionOffersStatName = keyof ReturnType<
  typeof getPromotionOffersStats
>;

export const getActivePromotionOffersStats = (
  stats: ReturnType<typeof getPromotionOffersStats>,
  activeParams: Record<PromotionOffersStatName, number>
): Record<PromotionOffersStatName, PromotionOffersDashboardGroup[]> => ({
  bgColors: stats.bgColors[activeParams.bgColors].groups,
  promotionTerms: stats.promotionTerms[activeParams.promotionTerms].groups,
  createdDates: stats.createdDates[activeParams.createdDates].groups,
});

export default getPromotionOffersStats;
