import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  BYTES_IN_ONE_MB,
  IMG_FILE_NUMBER_OF_MB,
  REMOVE_ERROR_TIMER,
} from '../../models';

import FileDropZone from './FileDropZone';
import UploadPending from './UploadPending';
import UploadResult from './UploadResult';
import UploadSuggestion from './UploadSuggestion';

const MAX_IMAGE_SIZE = BYTES_IN_ONE_MB * IMG_FILE_NUMBER_OF_MB;

export const ERROR_IMAGE_MESSAGE =
  'This file type is unsupported. Please upload a supported file type';

export type UploadOfferPageFieldProps = {
  isShowStage?: boolean;
  isPromotionUpload?: boolean;
  pictureDescription: string;
  fileDropZoneText: string;
  file: string;
  fileName: string;
  fileError: boolean;
  validExtensions: string[];
  uploadSuggestionText: string;
  uploadSuggestionFormat: string;
  validExtensionsForDrop: string[];
  toggleForm?: () => void;
  setFileError: (fileError: boolean) => void;
  setErrorText: (errorValue: string) => void;
  setFileName: (fileName: string) => void;
  setFile: (
    urlImg: string,
    textWithPercentages?: HTMLParagraphElement | null,
    file?: File
  ) => void;
};

const UploadOfferPageField = ({
  isShowStage,
  isPromotionUpload,
  pictureDescription,
  fileDropZoneText,
  fileError,
  fileName,
  file,
  toggleForm,
  setFileName,
  setErrorText,
  setFile,
  setFileError,
  validExtensions,
  uploadSuggestionText,
  uploadSuggestionFormat,
  validExtensionsForDrop,
}: UploadOfferPageFieldProps) => {
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
  const [isPendingStatus, setIsPendingStatus] = useState(false);
  const fileLoadingPercentage = useRef<HTMLParagraphElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleDropzone = useCallback(
    () => setIsDropzoneOpen(!isDropzoneOpen),
    [isDropzoneOpen]
  );

  useEffect(() => {
    if (fileError) {
      setFile('');
      setFileName('');
      setIsPendingStatus(false);
    }
  }, [fileError, setFile, setFileName]);

  const readFile = useCallback(
    async (
      file: File,
      ref: RefObject<HTMLParagraphElement>,
      togglePendingStatus: (status: boolean) => void,
      setUrlForImg: (
        urlImg: string,
        textWithPercentages?: HTMLParagraphElement | null,
        file?: File
      ) => void,
      setError: (value: boolean) => void
    ) => {
      const createImageError = () => {
        setError(true);

        setUrlForImg('');

        setTimeout(() => {
          setError(false);
          setErrorText('');
        }, REMOVE_ERROR_TIMER);
      };

      if (file.size > MAX_IMAGE_SIZE) {
        createImageError();
        setErrorText(
          `${file.name} exceeds the ${IMG_FILE_NUMBER_OF_MB}MB file size limit.`
        );

        return;
      }

      const [imgType] = file.type.split(' ');

      if (!validExtensionsForDrop.includes(imgType)) {
        createImageError();
        setErrorText(ERROR_IMAGE_MESSAGE);

        return;
      }

      togglePendingStatus(true);

      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        if (typeof fileReader.result === 'string') {
          await setUrlForImg(fileReader.result, ref.current, file);
        }
      };
    },
    [setErrorText, validExtensionsForDrop]
  );

  useEffect(() => {
    if (toggleForm && file && isShowStage) {
      toggleDropzone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowStage]);

  const togglePendingStatus = (status: boolean) => setIsPendingStatus(status);

  const toggleDropZoneHandler = useCallback(() => {
    toggleDropzone();
    toggleForm && toggleForm();
  }, [toggleDropzone, toggleForm]);

  const removePicture = useCallback(() => {
    setFile('');
    setFileName('');
    setIsPendingStatus(false);
    !isDropzoneOpen && toggleDropzone();
    toggleForm && toggleForm();
  }, [isDropzoneOpen, setFile, setFileName, toggleDropzone, toggleForm]);

  return (
    <>
      {file && fileName ? (
        <UploadResult
          isPromotionUpload={isPromotionUpload}
          isFirstChild
          url={file}
          fileName={fileName}
          uploadSuggestionText={uploadSuggestionText}
          clickHandler={removePicture}
        />
      ) : (
        <>
          {isDropzoneOpen ? (
            <>
              {isPendingStatus ? (
                <UploadPending
                  isFirstChild
                  ref={fileLoadingPercentage}
                  fileName={fileName}
                  downloadPercentages={
                    fileLoadingPercentage.current
                      ? String(fileLoadingPercentage.current.textContent)
                      : '0%'
                  }
                />
              ) : (
                <DropZoneContainer>
                  <FileDropZone
                    isError={fileError}
                    isFirstChild
                    validExtensions={validExtensions}
                    validExtensionsForDrop={validExtensionsForDrop}
                    ref={inputRef}
                    refObject={inputRef}
                    setName={setFileName}
                    toggleDropZone={toggleDropZoneHandler}
                    text={fileDropZoneText}
                    readFile={(file: File) =>
                      readFile(
                        file,
                        fileLoadingPercentage,
                        togglePendingStatus,
                        setFile,
                        setFileError
                      )
                    }
                  />
                </DropZoneContainer>
              )}
            </>
          ) : (
            <UploadSuggestion
              pictureDescription={pictureDescription}
              isFirstChild
              maxFileSize={IMG_FILE_NUMBER_OF_MB}
              text={uploadSuggestionText}
              fileFormat={uploadSuggestionFormat}
              clickHandler={toggleDropZoneHandler}
            />
          )}
        </>
      )}
    </>
  );
};

const DropZoneContainer = styled.div`
  margin-bottom: 30px;
`;

export default React.memo(UploadOfferPageField);
