import React from 'react';

type usePreviousType = (
  value: string | number | boolean | null | undefined
) => string | number | boolean | null | undefined;

const usePrevious = <T = usePreviousType>(value: T): T | undefined => {
  const ref = React.useRef<typeof value>();

  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
