import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { getPercentage } from 'helpers/getPercentage';
import DashboardBarChart from 'modules/dashboard/components/DashboardBarChart';
import DashboardChip from 'modules/dashboard/components/DashboardChip';
import DashboardGraphCard from 'modules/dashboard/components/DashboardGraphCard';
import DashboardLineChart from 'modules/dashboard/components/DashboardLineChart';
import NoDataMessage from 'modules/dashboard/components/NoDataMessage';
import { getRatingBetween } from 'modules/dashboard/helpers/getRatingBetween';
import getUsersStats, {
  UsersStatName,
} from 'modules/dashboard/helpers/getUsersStats';
import { UsersDashboardAdditional } from 'modules/dashboard/hooks/useUsersDashboard';
import { UserTableItem } from 'modules/users/models';

type Props = {
  users?: UserTableItem[];
  usersAdditional: UsersDashboardAdditional;
};

const UsersContent = ({ users, usersAdditional }: Props) => {
  const [activeParams, setActiveParams] = useState<
    Record<UsersStatName, number>
  >({
    createdUsers: 0,
    topCountries: 0,
  });

  const changeActiveParam = (param: UsersStatName) => (value: number) =>
    setActiveParams({ ...activeParams, [param]: value });

  const { createdUsers, topCountries, thisDayUsers, verifiedUsers } = useMemo(
    () => getUsersStats(users || []),
    [users]
  );

  const activeCreatedUsers = createdUsers[activeParams.createdUsers].groups;
  const activeTopCountries = topCountries[activeParams.topCountries].groups;

  const createdUsersRating = getRatingBetween(
    activeCreatedUsers[0]?.users.length,
    activeCreatedUsers[activeCreatedUsers.length - 1 || 0]?.users.length
  );

  const verifiedPercent = getPercentage(
    verifiedUsers[0]?.users.length + verifiedUsers[1]?.users.length,
    verifiedUsers.find(({ name }) => name === 'Yes')?.users.length
  );

  const topCountriesMargin = getRatingBetween(
    activeTopCountries[1]?.users.length,
    activeTopCountries[0]?.users.length
  );

  const usersSubtitles = [
    {
      title: 'Users created for the last 24 hours',
      value: thisDayUsers.length,
    },
    {
      title: 'Last user from',
      value: usersAdditional.lastUserInfo?.timezoneId || 'Unknown',
    },
  ];

  if (!users?.length) return <NoDataMessage />;

  return (
    <Wrapper>
      <HeaderSection>
        <Content>
          <HeaderTitle>Users</HeaderTitle>
          <SubTitleInfo>
            {usersSubtitles.map(({ title, value }) => (
              <DashboardChip key={title}>
                <ChipText>
                  {`${title}: `}
                  <span>{value}</span>
                </ChipText>
              </DashboardChip>
            ))}
          </SubTitleInfo>
          <GraphsContainer>
            <DashboardGraphCard
              title="Users created"
              rating={{
                result: createdUsersRating.result,
                percentage: createdUsersRating.diffPercentage,
              }}
              chart={
                <DashboardLineChart
                  data={activeCreatedUsers.map(({ name, users }) => ({
                    name,
                    Count: users.length,
                  }))}
                  lines={[
                    {
                      name: 'Count',
                      color: theme.colors.lightBlue,
                      dotColor: theme.colors.darkGray,
                      activeDotColor: theme.colors.cararra,
                    },
                  ]}
                />
              }
              params={{
                values: createdUsers.map(({ name }) => name),
                activeParam: activeParams.createdUsers,
                setActiveParam: changeActiveParam('createdUsers'),
              }}
              progressBars={[
                { title: 'Verified Users', percent: verifiedPercent },
              ]}
            />
            <DashboardGraphCard
              title="Countries"
              chart={
                <DashboardBarChart
                  data={activeTopCountries.map(({ name, users }) => ({
                    name,
                    Count: users.length,
                  }))}
                  bars={[
                    {
                      name: 'Count',
                      color: theme.colors.lightBlue,
                    },
                  ]}
                />
              }
              params={{
                values: topCountries.map(({ name }) => name),
                activeParam: activeParams.topCountries,
                setActiveParam: changeActiveParam('topCountries'),
              }}
              progressBars={[
                {
                  title: 'Top 1 margin',
                  percent: topCountriesMargin.diffPercentage,
                },
              ]}
            />
          </GraphsContainer>
        </Content>
      </HeaderSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GraphsContainer = styled.div`
  margin: 80px 0;
  display: flex;
  width: 100%;
  gap: 30px;
`;

const ChipText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};

  span {
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const SubTitleInfo = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 10px;
`;

const HeaderTitle = styled.h1`
  font-weight: 400;
  font-size: 40px;
  padding-top: 76px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const Content = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderSection = styled.section`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  min-height: 600px;
  background:
    url('/images/insights_background_additional.png') center bottom no-repeat,
    url('/images/insights_background.png') 39% 31% / contain no-repeat
      ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default UsersContent;
