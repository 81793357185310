import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import useOutsideClick from 'hooks/useOutsideClick';

import increaseClickableAreaForHtmlElement from '../helpers/increaseClickableAreaForHtmlElement';

type SortingPopupProps = {
  activeParameter: string;
  closePopup: () => void;
  parametersForSorting: string[];
  setActiveParameter: (index: number) => void;
};

const SortingPopup = ({
  closePopup,
  activeParameter,
  setActiveParameter,
  parametersForSorting,
}: SortingPopupProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideClick(popupRef, closePopup);

  return (
    <Container ref={popupRef}>
      <ActiveParameter>
        <span>Sort by:</span>&nbsp;
        <span>{activeParameter}</span>
        <CloseButtonContainer>
          <ActionButton
            icon={<ChevronIcon color={theme.colors.funtainBlue} />}
            clickHandler={closePopup}
          />
        </CloseButtonContainer>
      </ActiveParameter>
      {parametersForSorting.map(
        (parameter, index) =>
          parameter !== activeParameter && (
            <Parameter
              key={parameter}
              onClick={() => {
                setActiveParameter(index);
                closePopup();
              }}
            >
              {parameter}
            </Parameter>
          )
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: 5;
  top: -10px;
  right: -2px;
  width: 200px;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgb(0 0 0 / 40%);
  background-color: ${({ theme }) => theme.colors.white};
`;

const ActiveParameter = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding-left: 20px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.funtainBlue};
  }

  span:first-child {
    font-weight: 700;
  }
`;

const Parameter = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  font-weight: 400;
  box-sizing: border-box;
  padding-left: 20px;
  cursor: pointer;
  border-top: 1px solid rgb(0 0 0 / 10%);
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightYellow};
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const BUTTON_VERTICAL_RANGE = 10;
const BUTTON_HORIZONTAL_RANGE = 10;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }

  svg {
    transform: rotate(180deg);
  }
`;

export default SortingPopup;
