import React from 'react';
import styled from 'styled-components';

type Props = {
  withoutBg?: boolean;
};

const NoDataMessage = ({ withoutBg }: Props) =>
  withoutBg ? (
    <Text $withoutBg>Sorry, no data was found :(</Text>
  ) : (
    <TextWrapper>
      <Text>Sorry, no data was found :(</Text>
    </TextWrapper>
  );

const TextWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  min-height: 800px;
  background:
    url('/images/insights_background_additional.png') center bottom no-repeat,
    url('/images/insights_background.png') 39% 31% / contain no-repeat
      ${({ theme }) => theme.colors.primary};
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.h4<{
  $withoutBg?: boolean;
}>`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ $withoutBg }) => ($withoutBg ? '30px' : '40px')};
  font-weight: 400;
  margin: ${({ $withoutBg }) => ($withoutBg ? '5px auto' : '126px auto')};
  text-align: center;
`;

export default NoDataMessage;
