import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import StandardDropdown from 'components/actionModal/forms/dropdowns/StandardDropdown';
import MobileInput from 'components/actionModal/forms/inputs/MobileInput';
import StandardInput from 'components/actionModal/forms/inputs/StandardInput';
import { InputId } from 'components/actionModal/models';
import { AccessAccountDetailsSchema } from 'components/actionModal/models/validationSchemas';
import {
  FormContainer,
  MultipleInputsContainer,
  SubmitButtonContainer,
} from 'components/actionModal/styledElements/formsStyledElements';
import { Title } from 'components/actionModal/styledElements/stagesStyledElements';
import DefaultButton from 'components/buttons/DefaultButton';

import { accountsRoles } from '../delegatesMockData/delegatesItemMockData';
import { CreatableDelegateAttributes } from '../models';

type AccessAccountDetailsProps = {
  title: string;
  delegateDetails: CreatableDelegateAttributes;
  goToNextStage: (skip?: boolean) => void;
  setDelegateAccountItem: (item: CreatableDelegateAttributes) => void;
};

const DelegateAccountDetails = ({
  title,
  delegateDetails,
  goToNextStage,
  setDelegateAccountItem,
}: AccessAccountDetailsProps) => {
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [role, setRole] = useState(delegateDetails.role);
  const [isRoleValid, setIsRoleValid] = useState(false);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(AccessAccountDetailsSchema),
  });

  const isFormValid = isValid && isMobileValid && isRoleValid;

  const checkRoleValidation = useCallback(
    (isValid: boolean) => setIsRoleValid(isValid),
    []
  );

  const saveMobile = useCallback(
    (code?: string, countryAbbreviation?: string) => {
      const validNumber = watch()[InputId.MobileNumberInputId].slice(
        code?.length
      );

      setDelegateAccountItem({
        ...delegateDetails,
        mobileNumber: validNumber,
        mobileNumberCode: code || '',
        countryAbbreviation:
          countryAbbreviation || delegateDetails.countryAbbreviation,
      });
    },
    [setDelegateAccountItem, delegateDetails, watch]
  );

  const formSubmit = () => goToNextStage();

  return (
    <>
      <DelegateTitle>{title}</DelegateTitle>
      <FormContainer onSubmit={handleSubmit(formSubmit)}>
        <MultipleInputsContainer>
          <StandardInput
            errors={errors}
            register={register}
            setValue={setValue}
            label="First name"
            inputId={InputId.FirstNameInputId}
            inputValue={delegateDetails.firstName}
          />
          <StandardInput
            errors={errors}
            register={register}
            setValue={setValue}
            label="Last name"
            inputId={InputId.LastNameInputId}
            inputValue={delegateDetails.lastName}
          />
        </MultipleInputsContainer>

        <MultipleInputsContainer>
          <StandardInput
            register={register}
            setValue={setValue}
            errors={errors}
            label="Email address"
            inputId={InputId.EmailAddressInputId}
            inputValue={delegateDetails.email}
          />
          <MobileInput
            control={control}
            isMobileValid={isMobileValid}
            watch={watch}
            saveData={saveMobile}
            inputValue={delegateDetails.mobileNumber}
            code={delegateDetails.mobileNumberCode}
            countryAbbr={delegateDetails.countryAbbreviation}
            register={register}
            setValue={setValue}
            errors={errors}
            setIsMobileValid={setIsMobileValid}
            inputId={InputId.MobileNumberInputId}
          />
        </MultipleInputsContainer>

        <MultipleInputsContainer>
          <StandardInput
            errors={errors}
            register={register}
            setValue={setValue}
            label="Job title"
            inputId={InputId.JobTitleInputId}
            inputValue={delegateDetails.job}
          />
          <StandardDropdown
            inputLabel="Select role"
            listLabel="Select role"
            list={accountsRoles}
            saveData={setRole}
            inputValue={role}
            checkValidation={checkRoleValidation}
          />
        </MultipleInputsContainer>
        <SubmitButtonContainer>
          <DefaultButton
            text="ADD"
            textColor={theme.colors.white}
            isActive={isFormValid}
            isSubmit
            withShadow
            buttonColor={
              isFormValid ? theme.colors.swamp : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
    </>
  );
};

const DelegateTitle = styled(Title)`
  margin-bottom: 0;
`;

export default React.memo(DelegateAccountDetails);
