import { find } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Navbar from 'components/actionModal/Navbar';
import AffiliateOfferPage from 'components/actionModal/stages/offerPage/AffiliateOfferPage';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import Result from 'components/actionModal/stages/Result';
import SelectCategory from 'components/actionModal/stages/SelectCategory';
import AffiliateOfferSettings from 'components/actionModal/stages/settings/AffiliateOfferSettings';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import { AffiliateOfferContext } from 'modules/offers/context/AffiliateOfferContext';
import useGetOfferTerms, {
  OfferTerms,
} from 'modules/offers/hooks/useGetOfferTerms';
import useSaveImage from 'modules/offers/hooks/useSaveImage';
import useSetRightStageAfterRequest from 'modules/offers/hooks/useSetRightStageAfterRequest';
import {
  createAffiliateOfferDraft,
  publishAffiliateOffer,
  uploadAffiliateImage,
} from 'modules/offers/offersServices/AffiliateOffersService';
import { getPromotionOfferTerms } from 'modules/offers/offersServices/PromotionOffersService';

import {
  AffiliateOfferPageAttributes,
  AffiliateSettingsPageAttributes,
  OfferCategory,
  OfferModalWindowStages,
  QueryKey,
} from '../../models';
import { dataForSelectingCategory } from '../CreateOfferModal';

const NAVBAR_ITEMS = [
  {
    text: 'Select type',
    name: OfferModalWindowStages.SelectType,
  },
  {
    text: 'Affiliate page',
    name: OfferModalWindowStages.OfferPage,
  },
  {
    text: 'Settings',
    name: OfferModalWindowStages.Settings,
  },
];

enum FinishedStage {
  DraftCreated = 'DRAFT_CREATED',
  AffiliatePublished = 'AFFILIATE_PUBLISHED',
}

type CreatePromotionModalProps = {
  isWindowClosing: boolean;
  isNavbarTitleShown: boolean;
  activatedStages: OfferModalWindowStages[];
  showNavbarTitle: (value: boolean) => void;
  closeModalWindow: () => void;
  setActiveCategory: (category: OfferCategory) => void;
  setActivatedStages: (stage: OfferModalWindowStages) => void;
  freezeSettingsPageActivate: (disable?: boolean) => void;
  setIfOfferPublished?: (value: boolean) => void;
};

const CreateAffiliateModal = ({
  isWindowClosing,
  isNavbarTitleShown,
  activatedStages,
  setIfOfferPublished,
  closeModalWindow,
  setActiveCategory,
  showNavbarTitle,
  setActivatedStages,
  freezeSettingsPageActivate,
}: CreatePromotionModalProps) => {
  const queryClient = useQueryClient();
  const {
    offerDraft,
    imageName,
    image,
    offerTermId,
    setIsValidFormOffer,
    setPrevData,
    saveSettings,
    saveOfferSettings,
    setImageFile,
    setImage,
    setImageName,
    saveOfferId,
    setOfferTermId,
  } = useContext(AffiliateOfferContext);

  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.SelectType
  );
  const [isPending, setPending] = useState(false);
  const [result, setResult] = useState('');

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(NAVBAR_ITEMS[stage].name),
    []
  );

  const goToSettingsStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.Settings);
    setActivatedStages(OfferModalWindowStages.Settings);

    if (!isNavbarTitleShown) {
      showNavbarTitle(true);
    }
  }, [setActivatedStages, showNavbarTitle, isNavbarTitleShown]);

  const goToOfferPageStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.OfferPage);
    setActivatedStages(OfferModalWindowStages.OfferPage);
  }, [setActivatedStages]);

  const goToResultStage = useCallback(
    () => setActiveStage(OfferModalWindowStages.Result),
    []
  );

  const goToNextStage = useCallback(() => {
    if (result === FinishedStage.DraftCreated) {
      goToSettingsStage();
    } else if (result === FinishedStage.AffiliatePublished) {
      closeModalWindow();
    }

    setResult('');
  }, [closeModalWindow, goToSettingsStage, result]);

  useEffect(() => {
    if (isWindowClosing) {
      closeModalWindow();
    }
  }, [closeModalWindow, isWindowClosing]);

  const uploadLogo = useMutation(uploadAffiliateImage);

  const saveImage = useSaveImage({
    setPending,
    setImageFile,
    setImage,
    uploadLogo: uploadLogo.mutateAsync,
    offerId: offerDraft.affiliateOfferId,
  });

  const {
    isLoading: createAffiliateOfferDraftLoading,
    mutate: createOfferDraft,
  } = useMutation(createAffiliateOfferDraft, {
    onSuccess: (data) => {
      setPrevData(offerDraft.settings.offerStage);
      queryClient.invalidateQueries(QueryKey.GetAffiliateOffers);
      saveOfferId(data.id);
      setResult(FinishedStage.DraftCreated);
      goToResultStage();
    },
    onError: () => {
      setIsValidFormOffer(false);
    },
  });

  const {
    isLoading: publishAffiliateOfferDraftLoading,
    mutate: publishOfferDraft,
  } = useMutation(publishAffiliateOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.GetAffiliateOffers);
      setIfOfferPublished && setIfOfferPublished(true);
      setResult(FinishedStage.AffiliatePublished);
      goToResultStage();
    },
  });

  const sendCreateAffiliateOfferDraftRequest = useCallback(
    (data: AffiliateOfferPageAttributes) => {
      createOfferDraft({
        data: {
          ...data,
          promotionTermId: offerTermId,
        },
      });
    },
    [createOfferDraft, offerTermId]
  );

  const sendPublishAffiliateOfferDraftRequest = useCallback(
    (data: AffiliateSettingsPageAttributes) => {
      publishOfferDraft({
        data,
        id: offerDraft.affiliateOfferId,
      });
    },
    [offerDraft.affiliateOfferId, publishOfferDraft]
  );

  const saveOfferTermId = useCallback(
    (data: OfferTerms) => {
      const termId = find(data, { term: 'OneMonth' })?.id || '';

      setOfferTermId(termId);
    },
    [setOfferTermId]
  );

  useGetOfferTerms(getPromotionOfferTerms, saveOfferTermId);

  const isLoadingStarted =
    createAffiliateOfferDraftLoading || publishAffiliateOfferDraftLoading;

  useSetRightStageAfterRequest(
    !!result &&
      (isLoadingStarted || activeStage === OfferModalWindowStages.Result),
    goToNextStage
  );

  return (
    <>
      {activeStage === OfferModalWindowStages.Result || isLoadingStarted ? (
        <Result
          disableAutoClosing
          title={
            result === FinishedStage.AffiliatePublished
              ? 'Affiliate offer published!'
              : ''
          }
          closeModal={goToNextStage}
        />
      ) : (
        <>
          <Navbar
            title="Create offer"
            activeStage={activeStage}
            accountCategory=""
            activatedStages={activatedStages}
            navbarItems={NAVBAR_ITEMS}
            changeActiveStage={changeActiveStage}
          />
          <ActionModalMainContent>
            {activeStage === OfferModalWindowStages.SelectType && (
              <SelectCategory
                accountCategory={OfferCategory.Affiliate}
                categories={dataForSelectingCategory}
                saveData={setActiveCategory}
                goToNextStage={goToOfferPageStage}
              />
            )}
            <AffiliateOfferPage
              isShowStage={activeStage === OfferModalWindowStages.OfferPage}
              title="Offer page"
              settings={offerDraft.settings.offerStage}
              saveSettings={saveOfferSettings}
              sendRequest={sendCreateAffiliateOfferDraftRequest}
              freezeSettingsPageActivate={freezeSettingsPageActivate}
            />
            <AffiliateOfferSettings
              isShowStage={activeStage === OfferModalWindowStages.Settings}
              title="Settings"
              submitButtonTitle="Publish"
              isPending={isPending}
              settings={offerDraft.settings.settingsStage}
              previewOffer={PreviewOffer.Affiliate}
              imageName={imageName}
              image={image}
              saveSettings={saveSettings}
              saveImage={saveImage}
              saveImageName={setImageName}
              goToNextStage={sendPublishAffiliateOfferDraftRequest}
            />
          </ActionModalMainContent>
        </>
      )}
    </>
  );
};

export default React.memo(CreateAffiliateModal);
