export type ArrayListDataItem = {
  id: string;
  displayName: string;
  displayNameBE?: string;
};

const customizeListData = (obj: Record<string, string>) => {
  const arrayListData: ArrayListDataItem[] = [];

  for (const item in obj) {
    arrayListData.push({
      id: String(arrayListData.length),
      displayName: obj[item],
      displayNameBE: item,
    });
  }

  return arrayListData;
};

export default customizeListData;
