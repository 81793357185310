import { useEffect, useState } from 'react';

const useShowSvgImg = () => {
  const [isShowSvg, setIsShowSvg] = useState(false);

  useEffect(() => {
    setIsShowSvg(true);
  }, [isShowSvg]);

  return isShowSvg;
};

export default useShowSvgImg;
