import styled, { keyframes } from 'styled-components';

import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

export const closeModalAnimation = keyframes`
  0% {
    right: 10px;
  }

  100% {
    right: -750px;
  }
`;

export const openModalAnimation = keyframes`
  0% {
    right: -750px;
  }

  100% {
    right: 10px;
  }
`;

export const DetailsModalContent = styled.div<{
  $backgroundColor?: string;
}>`
  z-index: 4;
  width: 100%;
  padding: 17px 19px;
  padding: 19px 17px 85px 19px;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.2s;
  height: 820px;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.colors.funtainBlue};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (height <= 915px) {
    height: 685px;
  }

  @media (height <= 812px) {
    height: 615px;
  }

  @media (height <= 770px) {
    height: 460px;
  }

  @media (height <= 720px) {
    height: 440px;
  }
`;

export const DetailsModalContainer = styled.div<{
  $shouldRender: boolean;
}>`
  z-index: 3;
  background: ${({ theme }) => theme.colors.funtainBlue};
  top: 10px;
  width: 653px;
  border-radius: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  animation-name: ${({ $shouldRender }) =>
    $shouldRender ? openModalAnimation : closeModalAnimation};
  animation-duration: ${({ $shouldRender }) =>
    $shouldRender ? '0.5s' : '0.25s'};

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  background-color: transparent;
  right: 10px;
`;

const BUTTON_VERTICAL_RANGE = 7;
const BUTTON_HORIZONTAL_RANGE = 7;

export const Button = styled.button`
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background: none;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  text-transform: uppercase;
  ${increaseClickableAreaForHtmlElement(
    BUTTON_VERTICAL_RANGE,
    BUTTON_HORIZONTAL_RANGE
  )}
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: -4px;

  div {
    margin: 0 20px;
  }

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }
`;
