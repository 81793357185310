import React from 'react';

type SearchIconProps = {
  color: string;
};

const SearchIcon = ({ color }: SearchIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="search-clip-path">
        <path
          id="Path_6873"
          data-name="Path 6873"
          d="M23.809-1.982l-6.649-6.65a9.458,9.458,0,0,0-1.306-12.848,9.452,9.452,0,0,0-12.9.436A9.458,9.458,0,0,0,2.515-8.138,9.453,9.453,0,0,0,15.36-6.832l6.649,6.65a1.289,1.289,0,0,0,1.8,0A1.274,1.274,0,0,0,23.809-1.982ZM2.8-14.327A6.875,6.875,0,0,1,9.671-21.2a6.875,6.875,0,0,1,6.874,6.876A6.875,6.875,0,0,1,9.671-7.451,6.883,6.883,0,0,1,2.8-14.327Z"
          transform="translate(-0.182 23.815)"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_6058"
      data-name="Group 6058"
      transform="translate(-0.182 23.815)"
    >
      <g
        id="Group_6057"
        data-name="Group 6057"
        transform="translate(0.182 -23.815)"
        clipPath="url(#search-clip-path)"
      >
        <path
          id="Path_6872"
          data-name="Path 6872"
          d="M-4.818-28.815H29.365V5.371H-4.818Z"
          transform="translate(-0.274 23.722)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default SearchIcon;
