import { CustomDate } from 'components/calendar/models';
import countries from 'components/listForSelectingData/dataForList/countries';
import { CorporateAccountPrimaryContactData } from 'modules/accounts/accountsServices/CorporateAccountsService';

export const primaryContactInitialValue: CorporateAccountPrimaryContactData = {
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  mobileNumberCode: '',
  mobileNumber: '',
};

export const initialDate: CustomDate = {
  day: '',
  month: '',
  year: '',
};

export const initialValidPeriod = [initialDate, initialDate];

export const promotionOfferSettingsInitialValue = {
  url: '',
  offerTerm: '',
  validPeriod: initialValidPeriod,
};

export const corporateOfferSettingsInitialValue = {
  unitPrice: {
    price: '',
    currency: '',
  },
  redemptionLimit: '',
  offerTerm: '',
  offerCode: '',
  validPeriod: initialValidPeriod,
};

export const referrerAccountAccountDetailsInitialValue = {
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  phone: {
    code: countries[0].code,
    number: '',
    countryAbbreviation: '',
  },
};

export const corporateAccountDetailsInitialValue = {
  company: '',
  website: '',
  businessSector: '',
  country: '',
};
