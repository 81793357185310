/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';

import Logo from './Logo';

const Header = () => {
  return (
    <Container>
      <Logo width={115} height={70} />
      <Wrap>
        <Title>shido</Title>
        <RegisterSymbolContainer>
          <RegisterSymbol>®</RegisterSymbol>
        </RegisterSymbolContainer>
      </Wrap>
    </Container>
  );
};

const Container = styled.header`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
`;

const Title = styled.h1`
  font-size: 62px;
  font-family: ${({ theme }) => (theme.fonts as any).regular};
`;

const Wrap = styled.div`
  display: flex;
  margin-left: 32px;
`;

const RegisterSymbolContainer = styled.span`
  position: relative;
`;

const RegisterSymbol = styled.span`
  left: 4px;
  top: 3px;
  font-weight: 600;
  font-size: 20px;
  position: absolute;
`;

export default Header;
