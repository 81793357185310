import { find } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { CorporateOfferContext } from 'modules/offers/context/CorporateOfferContext';

import {
  departmentVariant,
  offerTerms,
} from '../../listForSelectingData/dataForLists';
import { Device } from '../models';
import PreviewHeader from '../PreviewHeader';
import { getDepartmentList } from '../PreviewService';

import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';
import TabletPreview from './TabletPreview';

type PreviewPageCorporateProps = {
  logo: string;
  background?: string;
  chosenDevice: Device;
  closePreview: () => void;
};

const PreviewPageCorporate = ({
  logo,
  background,
  chosenDevice,
  closePreview,
}: PreviewPageCorporateProps) => {
  const {
    offerDraft: { settings },
    companyName,
  } = useContext(CorporateOfferContext);
  const [activeDevice, setActiveDevice] = useState(chosenDevice);
  const offerTerm =
    find(offerTerms, { BEformat: settings.offerTerm })?.displayName || '';

  const changeActiveDeice = useCallback(
    (device: Device) => setActiveDevice(device),
    []
  );

  const departmentList = useQuery([], getDepartmentList).data || [];
  const isShowDepartment = settings.includeDepartment === departmentVariant.Yes;

  return (
    <Container>
      <PreviewHeader
        title="offer"
        activeDevice={activeDevice}
        closePreview={closePreview}
        setActiveDevice={changeActiveDeice}
      />
      {activeDevice === Device.Desktop && (
        <DesktopPreview
          data={departmentList}
          companyName={companyName}
          logo={logo}
          background={background}
          offerTerm={offerTerm}
          isShowDepartment={isShowDepartment}
        />
      )}
      {activeDevice === Device.Tablet && (
        <TabletPreview
          data={departmentList}
          background={background}
          companyName={companyName}
          offerTerm={offerTerm}
          isShowDepartment={isShowDepartment}
        />
      )}
      {activeDevice === Device.Mobile && (
        <MobilePreview
          data={departmentList}
          background={background}
          companyName={companyName}
          offerTerm={offerTerm}
          isShowDepartment={isShowDepartment}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  min-height: 100vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

export default PreviewPageCorporate;
