import { QueryFunctionContext, useQuery } from 'react-query';

export type OfferTerms = { id: string; term: string }[];

const useGetOfferTerms = (
  queryFn: (data: QueryFunctionContext) => Promise<OfferTerms>,
  setTerms: (termsArray: OfferTerms) => void
) =>
  useQuery([], queryFn, {
    onSuccess: (data) => {
      setTerms(data);
    },
    refetchOnWindowFocus: false,
  });

export default useGetOfferTerms;
