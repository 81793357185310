import React from 'react';

const BrowserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16.01"
    height="15.566"
    viewBox="0 0 16.01 15.566"
  >
    <defs>
      <clipPath id="browser-clip-path">
        <path
          id="Path_8737"
          data-name="Path 8737"
          d="M13.135-20.185a.167.167,0,0,0,.139-.074.167.167,0,0,0,.016-.157,6.553,6.553,0,0,0-2.462-3.17.168.168,0,0,0-.178,0,6.553,6.553,0,0,0-2.462,3.17.167.167,0,0,0,.016.157.167.167,0,0,0,.139.074Z"
          transform="translate(-8.175 23.611)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-2">
        <path
          id="Path_8739"
          data-name="Path 8739"
          d="M18.32-14.313a.168.168,0,0,0,.168.162h3.337a.166.166,0,0,0,.123-.054.169.169,0,0,0,.044-.127,7.968,7.968,0,0,0-.657-2.567.168.168,0,0,0-.153-.1H18.191a.168.168,0,0,0-.129.062.168.168,0,0,0-.034.139,14.62,14.62,0,0,1,.292,2.486Z"
          transform="translate(-18.024 17)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-3">
        <path
          id="Path_8741"
          data-name="Path 8741"
          d="M13.523-14.151a.17.17,0,0,0,.121-.052.168.168,0,0,0,.046-.123,13.027,13.027,0,0,0-.342-2.547A.166.166,0,0,0,13.187-17H7.539a.167.167,0,0,0-.162.128,13.028,13.028,0,0,0-.342,2.547.168.168,0,0,0,.047.123.168.168,0,0,0,.121.052Z"
          transform="translate(-7.035 17)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-4">
        <path
          id="Path_8743"
          data-name="Path 8743"
          d="M8.343-5.5a.167.167,0,0,0-.139.074.167.167,0,0,0-.016.157A6.553,6.553,0,0,0,10.65-2.1a.167.167,0,0,0,.178,0,6.551,6.551,0,0,0,2.463-3.17.168.168,0,0,0-.016-.157.168.168,0,0,0-.139-.074Z"
          transform="translate(-8.175 5.5)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-5">
        <path
          id="Path_8745"
          data-name="Path 8745"
          d="M7.2-11.251a.168.168,0,0,0-.121.052.168.168,0,0,0-.047.123,13.028,13.028,0,0,0,.342,2.547.167.167,0,0,0,.162.127h5.648a.168.168,0,0,0,.163-.128,13.083,13.083,0,0,0,.341-2.547.168.168,0,0,0-.047-.123.168.168,0,0,0-.121-.052Z"
          transform="translate(-7.035 11.252)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-6">
        <path
          id="Path_8747"
          data-name="Path 8747"
          d="M.058-14.333a.168.168,0,0,0,.044.127.168.168,0,0,0,.123.054H3.561a.167.167,0,0,0,.168-.161A14.62,14.62,0,0,1,4.021-16.8a.165.165,0,0,0-.034-.14A.166.166,0,0,0,3.858-17H.868a.168.168,0,0,0-.153.1,7.968,7.968,0,0,0-.657,2.567Z"
          transform="translate(-0.057 17)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-7">
        <path
          id="Path_8749"
          data-name="Path 8749"
          d="M3.729-11.088a.168.168,0,0,0-.168-.162H.225A.166.166,0,0,0,.1-11.2a.169.169,0,0,0-.044.127A7.976,7.976,0,0,0,.715-8.5a.168.168,0,0,0,.153.1H3.858a.168.168,0,0,0,.129-.062A.168.168,0,0,0,4.021-8.6a14.62,14.62,0,0,1-.292-2.486Z"
          transform="translate(-0.057 11.25)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-8">
        <path
          id="Path_8751"
          data-name="Path 8751"
          d="M5.081-5.387A.168.168,0,0,0,4.922-5.5h-2.6a.168.168,0,0,0-.147.092.168.168,0,0,0,.012.172A8.061,8.061,0,0,0,6.655-2.1a.168.168,0,0,0,.187-.074.168.168,0,0,0-.019-.2A8.638,8.638,0,0,1,5.081-5.387Z"
          transform="translate(-2.154 5.5)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-9">
        <path
          id="Path_8753"
          data-name="Path 8753"
          d="M16.762-5.5a.168.168,0,0,0-.158.113,8.638,8.638,0,0,1-1.739,3.016.168.168,0,0,0-.019.2.168.168,0,0,0,.187.074,8.061,8.061,0,0,0,4.47-3.14.168.168,0,0,0,.012-.172.168.168,0,0,0-.147-.092Z"
          transform="translate(-14.821 5.501)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-10">
        <path
          id="Path_8755"
          data-name="Path 8755"
          d="M4.92-20.177a.168.168,0,0,0,.158-.113,8.638,8.638,0,0,1,1.739-3.017.168.168,0,0,0,.019-.2.168.168,0,0,0-.187-.074,8.061,8.061,0,0,0-4.47,3.14.168.168,0,0,0-.012.172.168.168,0,0,0,.147.092Z"
          transform="translate(-2.148 23.587)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-11">
        <path
          id="Path_8757"
          data-name="Path 8757"
          d="M21.991-11.068a.168.168,0,0,0-.044-.127.167.167,0,0,0-.123-.054H18.487a.168.168,0,0,0-.168.162A14.621,14.621,0,0,1,18.027-8.6a.168.168,0,0,0,.034.139.168.168,0,0,0,.129.062H21.18a.168.168,0,0,0,.153-.1A7.976,7.976,0,0,0,21.991-11.068Z"
          transform="translate(-18.024 11.25)"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="browser-clip-path-12">
        <path
          id="Path_8759"
          data-name="Path 8759"
          d="M16.6-20.29a.168.168,0,0,0,.158.113h2.6a.168.168,0,0,0,.147-.092.168.168,0,0,0-.012-.172,8.061,8.061,0,0,0-4.466-3.14.168.168,0,0,0-.187.074.168.168,0,0,0,.019.2A8.638,8.638,0,0,1,16.6-20.29Z"
          transform="translate(-14.821 23.587)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="Group_10929"
      data-name="Group 10929"
      transform="translate(-0.057 23.611)"
    >
      <g
        id="Group_10917"
        data-name="Group 10917"
        transform="translate(5.499 -23.611)"
        clipPath="url(#browser-clip-path)"
      >
        <path
          id="Path_8736"
          data-name="Path 8736"
          d="M3.175-28.611h11.83v10.129H3.175Z"
          transform="translate(-6.527 25.26)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10918"
        data-name="Group 10918"
        transform="translate(12.1 -19.18)"
        clipPath="url(#browser-clip-path-2)"
      >
        <path
          id="Path_8738"
          data-name="Path 8738"
          d="M13.024-22h10.67v9.552H13.024Z"
          transform="translate(-16.375 18.649)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10919"
        data-name="Group 10919"
        transform="translate(4.734 -19.18)"
        clipPath="url(#browser-clip-path-3)"
      >
        <path
          id="Path_8740"
          data-name="Path 8740"
          d="M2.035-22H15.394v9.553H2.035Z"
          transform="translate(-5.386 18.649)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10920"
        data-name="Group 10920"
        transform="translate(5.499 -11.471)"
        clipPath="url(#browser-clip-path-4)"
      >
        <path
          id="Path_8742"
          data-name="Path 8742"
          d="M3.175-10.5H15.006V-.371H3.175Z"
          transform="translate(-6.527 7.149)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10921"
        data-name="Group 10921"
        transform="translate(4.734 -15.327)"
        clipPath="url(#browser-clip-path-5)"
      >
        <path
          id="Path_8744"
          data-name="Path 8744"
          d="M2.035-16.252H15.394V-6.7H2.035Z"
          transform="translate(-5.386 12.901)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10922"
        data-name="Group 10922"
        transform="translate(0.057 -19.18)"
        clipPath="url(#browser-clip-path-6)"
      >
        <path
          id="Path_8746"
          data-name="Path 8746"
          d="M-4.943-22H5.728v9.552H-4.943Z"
          transform="translate(1.591 18.649)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10923"
        data-name="Group 10923"
        transform="translate(0.057 -15.326)"
        clipPath="url(#browser-clip-path-7)"
      >
        <path
          id="Path_8748"
          data-name="Path 8748"
          d="M-4.943-16.25H5.727V-6.7H-4.943Z"
          transform="translate(1.591 12.899)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10924"
        data-name="Group 10924"
        transform="translate(1.463 -11.471)"
        clipPath="url(#browser-clip-path-8)"
      >
        <path
          id="Path_8750"
          data-name="Path 8750"
          d="M-2.846-10.5H8.57V-.387H-2.846Z"
          transform="translate(-0.506 7.149)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10925"
        data-name="Group 10925"
        transform="translate(9.953 -11.472)"
        clipPath="url(#browser-clip-path-9)"
      >
        <path
          id="Path_8752"
          data-name="Path 8752"
          d="M9.821-10.5H21.237V-.388H9.821Z"
          transform="translate(-13.173 7.15)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10926"
        data-name="Group 10926"
        transform="translate(1.459 -23.595)"
        clipPath="url(#browser-clip-path-10)"
      >
        <path
          id="Path_8754"
          data-name="Path 8754"
          d="M-2.852-28.587H8.564v10.113H-2.852Z"
          transform="translate(-0.5 25.235)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10927"
        data-name="Group 10927"
        transform="translate(12.1 -15.326)"
        clipPath="url(#browser-clip-path-11)"
      >
        <path
          id="Path_8756"
          data-name="Path 8756"
          d="M13.024-16.25h10.67V-6.7H13.024Z"
          transform="translate(-16.375 12.899)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10928"
        data-name="Group 10928"
        transform="translate(9.953 -23.595)"
        clipPath="url(#browser-clip-path-12)"
      >
        <path
          id="Path_8758"
          data-name="Path 8758"
          d="M9.821-28.587H21.233v10.113H9.821Z"
          transform="translate(-13.173 25.235)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default BrowserIcon;
