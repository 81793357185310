import React from 'react';
import styled from 'styled-components';

type UserDetailsInformationProps = {
  email: string;
  name: string;
  gender: string;
  location: string;
  age: number;
  joined: string;
};

const UserDetailsInformation = ({
  email,
  name,
  gender,
  location,
  age,
  joined,
}: UserDetailsInformationProps) => {
  return (
    <Container>
      <Title>Account details</Title>
      <InfoContainer>
        <div>
          <InfoItemText>Email: {email}</InfoItemText>
          <InfoItemText>Name: {name}</InfoItemText>
          <InfoItemText>Gender: {gender}</InfoItemText>
          <InfoItemText>Location: {location}</InfoItemText>
        </div>
        <div>
          <InfoItemText>Age: {age}</InfoItemText>
          <InfoItemText>Joined: {joined}</InfoItemText>
        </div>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 35px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;

const InfoContainer = styled.div`
  display: flex;
  column-gap: 185px;
  color: ${({ theme }) => theme.colors.white};
`;

const InfoItemText = styled.div`
  font-size: 12px;
  margin-top: 14px;
`;

export default React.memo(UserDetailsInformation);
