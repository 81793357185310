import { AxiosProgressEvent } from 'axios';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'react-query';

import Navbar from 'components/actionModal/Navbar';
import OfferPage from 'components/actionModal/stages/offerPage/OfferPage';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import Result from 'components/actionModal/stages/Result';
import SelectAccount from 'components/actionModal/stages/SelectAccount';
import SelectCategory from 'components/actionModal/stages/SelectCategory';
import CorporateOfferSettings from 'components/actionModal/stages/settings/CorporateOfferSettings';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import { departmentVariant } from 'components/listForSelectingData/dataForLists';
import { CorporateAccount } from 'modules/accounts/accountsServices/CorporateAccountsService';

import { CorporateOfferContext } from '../../context/CorporateOfferContext';
import useCreateDraft from '../../hooks/useCreateDraft';
import usePublishOffer from '../../hooks/usePublishOffer';
import {
  ImageVariant,
  OfferCategory,
  OfferModalWindowStages,
} from '../../models';
import {
  deleteCorporateOffer,
  publishCorporateOffer,
  uploadBackgroundDraft,
  uploadLogoDraft,
} from '../../offersServices/CorporateOffersService';
import { dataForSelectingCategory } from '../CreateOfferModal';

const navbarItemsForCorporate = [
  {
    text: 'Select type',
    name: OfferModalWindowStages.SelectType,
  },
  {
    text: 'Select account',
    name: OfferModalWindowStages.SelectAccount,
  },
  {
    text: 'Settings',
    name: OfferModalWindowStages.Settings,
  },
  {
    text: 'Offer page',
    name: OfferModalWindowStages.OfferPage,
  },
];

type CreateCorporateModalProps = {
  isWindowClosing: boolean;
  isNavbarTitleShown: boolean;
  activatedStages: OfferModalWindowStages[];
  showNavbarTitle: (value: boolean) => void;
  closeModalWindow: (id?: string) => void;
  setActiveCategory: (category: OfferCategory) => void;
  setActivatedStages: (stage: OfferModalWindowStages) => void;
  freezeOfferPageActivate: () => void;
};

const CreateCorporateModal = ({
  isWindowClosing,
  isNavbarTitleShown,
  activatedStages,
  closeModalWindow,
  setActiveCategory,
  showNavbarTitle,
  freezeOfferPageActivate,
  setActivatedStages,
}: CreateCorporateModalProps) => {
  const createDraftController = useMemo(() => new AbortController(), []);
  const deleteOfferController = useMemo(() => new AbortController(), []);
  const [offerAccounts, setOfferAccounts] = useState<CorporateAccount[]>([]);

  const {
    setLogoFile,
    setBackgroundFile,
    logoImage,
    logoName,
    offerDraft,
    saveAccount,
    saveSettings,
    setLogoName,
    setLogoImage,
    backgroundName,
    backgroundImage,
    setBackgroundName,
    setBackgroundImage,
  } = useContext(CorporateOfferContext);
  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.SelectType
  );
  const [completionTitle, setCompletionTitle] = useState('');
  const [isOfferCodeError, setIsOfferCodeError] = useState(false);
  const [isDraftCleaned, setIsDraftCleaned] = useState(true);
  const createDraft = useCreateDraft(
    setIsOfferCodeError,
    setActiveStage,
    setIsDraftCleaned
  );

  const uploadLogo = useMutation(uploadLogoDraft);
  const uploadBackground = useMutation(uploadBackgroundDraft);
  const publishOffer = usePublishOffer(
    setCompletionTitle,
    publishCorporateOffer
  );
  const [userId, setUserId] = useState('');
  const [isPending, setPending] = useState(false);

  const { data } = createDraft;
  const deleteOffer = useMutation(deleteCorporateOffer);

  const cleanDraftRequest = useCallback(() => {
    if (data && !isDraftCleaned) {
      setIsDraftCleaned(true);
      deleteOffer.mutate({
        corporateOfferId: data.id,
        controller: deleteOfferController,
      });
    }
  }, [data, deleteOffer, deleteOfferController, isDraftCleaned]);

  const goToSelectCategoryStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.SelectType);
  }, []);

  const goToSelectAccountStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.SelectAccount);
    setActivatedStages(OfferModalWindowStages.SelectAccount);

    if (!isNavbarTitleShown) {
      showNavbarTitle(true);
    }
  }, [setActivatedStages, showNavbarTitle, isNavbarTitleShown]);

  const goToOfferPageStage = useCallback(() => {
    const { settings, corporateAccountId } = offerDraft;
    const { mutate } = createDraft;
    const includeDepartmentValue =
      settings.includeDepartment === departmentVariant.Yes;

    mutate({
      controller: createDraftController,
      data: {
        ...settings,
        includeDepartment: includeDepartmentValue as unknown as string,
        corporateAccountId,
      },
    });
    setActivatedStages(OfferModalWindowStages.OfferPage);
  }, [createDraftController, createDraft, offerDraft, setActivatedStages]);

  const goToResultStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.Result);
    if (createDraft.data) {
      const { id } = createDraft.data;

      deleteOfferController.abort();
      publishOffer.mutate({ id });
    }
  }, [deleteOfferController, createDraft.data, publishOffer]);

  const saveImageName = useCallback(
    (name: string, imageVariant: ImageVariant) => {
      if (imageVariant === ImageVariant.Logo) {
        setLogoName(name);
      } else {
        setBackgroundName(name);
      }
    },
    [setBackgroundName, setLogoName]
  );

  const saveImage = useCallback(
    async (
      imageURL: string,
      imageVariant: ImageVariant,
      openError: (value: boolean) => void,
      setErrorText: (value: string) => void,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      const { data } = createDraft;

      if (data && file) {
        setPending(true);
        const options = {
          onUploadProgress: (progress: AxiosProgressEvent) => {
            const { loaded, total } = progress;

            if (textWithPercentages && total) {
              textWithPercentages.textContent = `${Math.round(
                (loaded / total) * 100
              )}%`;
            }
          },
        };
        const formData = new FormData();
        const { id } = data;

        formData.append('payload', file);

        const uploadedData = {
          id,
          options,
          formData,
          openError,
          setErrorText,
        };

        if (imageVariant === ImageVariant.Logo) {
          await uploadLogo.mutateAsync(uploadedData);
          setLogoFile(file);
        } else {
          await uploadBackground.mutateAsync(uploadedData);
          setBackgroundFile(file);
        }
        setPending(false);
      }
      if (imageVariant === ImageVariant.Logo) {
        if (logoImage !== imageURL) {
          setLogoImage(imageURL);
        }
        if (!imageURL) {
          setLogoFile();
        }
      } else {
        setBackgroundImage(imageURL);
        if (!imageURL) {
          setBackgroundFile();
        }
      }
    },
    [
      createDraft,
      logoImage,
      setBackgroundFile,
      setBackgroundImage,
      setLogoFile,
      setLogoImage,
      uploadBackground,
      uploadLogo,
    ]
  );

  const goToSettingsStage = useCallback(() => {
    cleanDraftRequest();

    setActiveStage(OfferModalWindowStages.Settings);
    setActivatedStages(OfferModalWindowStages.Settings);
  }, [cleanDraftRequest, setActivatedStages]);

  const changeActiveStage = useCallback(
    (stage: number) => {
      if (stage <= 2) {
        cleanDraftRequest();
      }

      setActiveStage(navbarItemsForCorporate[stage].name);
    },
    [cleanDraftRequest]
  );

  useEffect(() => {
    if (isWindowClosing) {
      closeModalWindow();
    }
  }, [closeModalWindow, isWindowClosing]);

  useEffect(() => {
    if (data) {
      setUserId(data.id);
    }
  }, [data]);

  return (
    <>
      {activeStage === OfferModalWindowStages.Result && publishOffer.data ? (
        <Result title={completionTitle} closeModal={closeModalWindow} />
      ) : (
        <>
          <Navbar
            title="Create offer"
            activeStage={activeStage}
            showTitle={isNavbarTitleShown}
            accountCategory={OfferCategory.Corporate}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
            navbarItems={navbarItemsForCorporate}
          />
          <ActionModalMainContent>
            {activeStage === OfferModalWindowStages.SelectType && (
              <SelectCategory
                saveData={setActiveCategory}
                accountCategory={OfferCategory.Corporate}
                categories={dataForSelectingCategory}
                goToNextStage={goToSelectAccountStage}
              />
            )}
            {activeStage === OfferModalWindowStages.SelectAccount && (
              <SelectAccount
                setOfferAccounts={setOfferAccounts}
                offerAccounts={offerAccounts}
                title="Select account"
                accountId={offerDraft.corporateAccountId}
                goToNextStage={goToSettingsStage}
                saveAccount={saveAccount}
                goToPreviousStage={goToSelectCategoryStage}
              />
            )}
            <CorporateOfferSettings
              isShowStage={activeStage === OfferModalWindowStages.Settings}
              title="Settings"
              pendingStatus={createDraft.isLoading}
              settings={offerDraft.settings}
              saveSettings={saveSettings}
              goToNextStage={goToOfferPageStage}
              isOfferCodeError={isOfferCodeError}
              goToPreviousStage={goToSelectAccountStage}
            />
            <OfferPage
              isShowStage={activeStage === OfferModalWindowStages.OfferPage}
              isPending={isPending}
              logoImage={logoImage}
              logoName={logoName}
              freezeOfferPageActivate={freezeOfferPageActivate}
              id={userId}
              setUserId={setUserId}
              backgroundName={backgroundName}
              backgroundImage={backgroundImage}
              saveImage={saveImage}
              saveImageName={saveImageName}
              previewOffer={PreviewOffer.Corporate}
              title="Offer page"
              submitButtonTitle="Publish"
              goToNextStage={goToResultStage}
              goToPreviousStage={goToSettingsStage}
            />
          </ActionModalMainContent>
        </>
      )}
    </>
  );
};

export default CreateCorporateModal;
