import React from 'react';
import styled from 'styled-components';

import SignOutIcon from 'components/icons/SignOutIcon';
import useAuth from 'modules/auth/hooks/useAuth';

type Props = {
  employerHeader?: boolean;
};

const SignOutButton = ({ employerHeader }: Props) => {
  const { logout } = useAuth();

  return (
    <Container $employerHeader={employerHeader} onClick={logout}>
      <Text>sign out</Text>
      <SignOutIcon />
    </Container>
  );
};

const Container = styled.button<{ $employerHeader?: boolean }>`
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  right: ${({ $employerHeader }) => $employerHeader && '-12px'};

  svg {
    margin-left: 10px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-left: 5px;
    }
  }
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.funtainBlue};
  text-transform: uppercase;
  padding-top: 1px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 10px;
  }
`;

export default SignOutButton;
