import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import {
  CLOSE_DETAILS_MODAL_TIMER,
  RequestStatus,
} from 'components/detailsModal/models';
import SuccessStage from 'components/detailsModal/SuccessStage';
import GarbageIcon from 'components/icons/GarbageIcon';
import { TableRowGrid } from 'components/table/models';
import {
  DashedWrapper,
  Label,
  ModalContainer,
  Value,
} from 'modules/onboarding/components/styledElements/styledModalElements';

import ManageRow from './ManageRow';
import { ManageUserOnboardingItem } from './models';
import RevokeComponent from './RevokeComponent';

type LandingRowDetailsModalProps = {
  offer: ManageUserOnboardingItem;
  closeWindow: () => void;
};

const ManageRowDetailsModal = ({
  offer,
  closeWindow,
}: LandingRowDetailsModalProps) => {
  const [isDeletionBlockOpen, setIsDeletionBlockOpen] = useState(false);
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();

  const onDelete = useCallback(() => {
    setSuccessTitle('Dan McPeake’s');
    setSuccessText('Offer code revoked!');
    setRequestStatus(RequestStatus.Success);
  }, []);

  const toggleBlockOpen = useCallback(() => {
    setIsDeletionBlockOpen(!isDeletionBlockOpen);
    setBackgroundColor('');
    setIsActionConfirmed(false);
  }, [isDeletionBlockOpen]);

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else {
      setBackgroundColor('');
    }
  }, [isActionConfirmed]);

  useEffect(() => {
    if (requestStatus) {
      setTimeout(() => {
        closeWindow();
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [closeWindow, requestStatus, successTitle]);

  return (
    <ModalContainer $background={backgroundColor}>
      {requestStatus ? (
        <SuccessStageWrapper>
          <SuccessStage
            requestStatus={requestStatus}
            title={successTitle}
            name=""
            text={successText}
          />
        </SuccessStageWrapper>
      ) : (
        <>
          {isDeletionBlockOpen ? (
            <RevokeComponent
              closeModal={closeWindow}
              deleteUser={onDelete}
              cancelDeletion={toggleBlockOpen}
              toggleDeleteStatus={toggleDeleteStatus}
              isActionConfirmed={isActionConfirmed}
              backgroundColor={backgroundColor}
            />
          ) : (
            <div>
              <ManageRow
                details
                detailsInfo={{
                  photo: offer.photo,
                  name: offer.name,
                  email: offer.email,
                }}
                key={offer.department}
                tableName={TableRowGrid.ManageUsers}
                data={[offer.department, offer.sex, offer.age]}
                openModalWindow={closeWindow}
              />
              <HorizontalLine />
              <InfoItemsContainer>
                <InfoItemWrapper>
                  <Label>Date signed up</Label>
                  <Value>22 Aug 2022</Value>
                </InfoItemWrapper>
                <InfoItemWrapper>
                  <Label>Offer code assigned</Label>
                  <Value>OFFERCODE-1A</Value>
                </InfoItemWrapper>
              </InfoItemsContainer>
              <ButtonWrapper onClick={toggleBlockOpen}>
                <GarbageIcon color={theme.colors.white} />
                <ButtonText>REVOKE OFFER CODE</ButtonText>
              </ButtonWrapper>
            </div>
          )}
        </>
      )}
    </ModalContainer>
  );
};

const SuccessStageWrapper = styled.div`
  h4 {
    margin-top: 42px;
    font-size: 16px;
    font-weight: 300;
  }

  p {
    font-size: 30px;
    margin-top: 8px;
  }

  div {
    transform: translate(-50%, 45%);
  }
`;

const ButtonText = styled.div`
  font-weight: 700;
  position: relative;
  top: 2px;
  color: ${({ theme }) => theme.colors.white};
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  column-gap: 11px;
  margin-top: 98px;
  align-items: center;
  width: 165px;
`;

const HorizontalLine = styled.div`
  height: 1px;
  border-bottom: 1.5px dashed ${({ theme }) => theme.colors.lightGreyV3};
  width: 100%;
  margin-top: -4px;
`;

const InfoItemWrapper = styled(DashedWrapper)`
  width: 302px;

  &:nth-child(1) {
    border-bottom: none;
    border-top: none;
  }
`;

const InfoItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ManageRowDetailsModal;
