import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

import Background from '../icons/Background';

import Header from './Header';

type IntroSection = {
  qrCodeUrl: string;
  title: string;
  isDesktop?: boolean;
  term?: string;
  chosenColor: {
    light: string;
    strong: string;
  };
};

const IntroSection = ({
  qrCodeUrl,
  isDesktop,
  title,
  chosenColor,
  term,
}: IntroSection) => {
  return (
    <Container>
      <Header isDesktop={isDesktop} />
      <Title $isDesktop={isDesktop}>
        {title ? title : `Reduce your stress and anxiety naturally.`}
      </Title>
      <Subtitle $isDesktop={isDesktop}>
        Scan the code with your mobile device to start a free {term} trial
      </Subtitle>
      <QrCodeContainer $isDesktop={isDesktop}>
        <QrCodeContainerInfo $isDesktop={isDesktop}>
          <ReactSVG src={qrCodeUrl} />
        </QrCodeContainerInfo>
      </QrCodeContainer>
      <GraphWrapper>
        <Background color={chosenColor.strong} />
      </GraphWrapper>
    </Container>
  );
};

const GraphWrapper = styled.div<{ $isDesktop?: boolean }>`
  position: absolute;
  margin-top: ${({ $isDesktop }) => ($isDesktop ? '-384px' : '-375px')};
  left: 50%;
  transform: translateX(-50%);
`;

export const QrCodeContainerInfo = styled.div<{ $isDesktop?: boolean }>`
  position: relative;
  z-index: 10;
  width: 300px;
  height: 300px;

  svg {
    width: 200px;
    height: 200px;

    rect {
      fill: ${({ theme }) => theme.colors.primary};
    }

    path {
      fill: white;
    }
  }

  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      width: 240px;
      height: 240px;
      position: relative;

      svg {
        height: 150px;
        width: 150px;

        rect {
          fill: ${({ theme }) => theme.colors.primary};
        }

        path {
          fill: white;
        }
      }
    `}
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QrCodeContainer = styled.div<{ $isDesktop?: boolean }>`
  margin: 42px auto 0;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin: 76px auto 0;
    `}
`;

const Subtitle = styled.div<{ $isDesktop?: boolean }>`
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 300;
  margin-top: 40px;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin-top: 27px;
    `}
`;

const Title = styled.div<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '60px' : '50px')};
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 72px;
  word-break: break-word;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin-top: 74px;
    `}
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
`;

export default IntroSection;
