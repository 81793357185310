import React from 'react';

const CorporateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="19.5"
    viewBox="0 0 24 19.5"
  >
    <defs>
      <clipPath id="corporate-clip-path">
        <path
          id="Path_8733"
          data-name="Path 8733"
          d="M24-19a.75.75,0,0,0-.75-.75H.75A.75.75,0,0,0,0-19a.75.75,0,0,0,.75.75H1.5a.5.5,0,0,1,.5.5v17a.5.5,0,0,0,.5.5H9a.5.5,0,0,0,.5-.5v-2.5A2.5,2.5,0,0,1,12-5.75a2.5,2.5,0,0,1,2.5,2.5v2.5a.5.5,0,0,0,.5.5h6.5a.5.5,0,0,0,.5-.5v-17a.5.5,0,0,1,.5-.5h.75A.75.75,0,0,0,24-19ZM9.25-7.75h-4A.75.75,0,0,1,4.5-8.5a.75.75,0,0,1,.75-.75h4A.75.75,0,0,1,10-8.5.75.75,0,0,1,9.25-7.75Zm0-4h-4a.75.75,0,0,1-.75-.75.75.75,0,0,1,.75-.75h4a.75.75,0,0,1,.75.75A.75.75,0,0,1,9.25-11.75Zm0-4h-4a.75.75,0,0,1-.75-.75.75.75,0,0,1,.75-.75h4a.75.75,0,0,1,.75.75.75.75,0,0,1-.75.75Zm9.5,8h-4A.75.75,0,0,1,14-8.5a.75.75,0,0,1,.75-.75h4a.75.75,0,0,1,.75.75A.75.75,0,0,1,18.75-7.75Zm0-4h-4A.75.75,0,0,1,14-12.5a.75.75,0,0,1,.75-.75h4a.75.75,0,0,1,.75.75A.75.75,0,0,1,18.75-11.75Zm.75-4.75a.75.75,0,0,1-.75.75h-4A.75.75,0,0,1,14-16.5a.75.75,0,0,1,.75-.75h4a.75.75,0,0,1,.53.22A.75.75,0,0,1,19.5-16.5Z"
          fill="#66abc1"
        />
      </clipPath>
    </defs>
    <g id="Group_10900" data-name="Group 10900" transform="translate(0 19.75)">
      <g
        id="Group_10899"
        data-name="Group 10899"
        clipPath="url(#corporate-clip-path)"
      >
        <path
          id="Path_8732"
          data-name="Path 8732"
          d="M-5-24.75H29V4.75H-5Z"
          fill="#66abc1"
        />
      </g>
    </g>
  </svg>
);

export default CorporateIcon;
