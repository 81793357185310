import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

type Props = {
  dataAmount: number;
  currentPage: number;
  maxPages: number;
  changeActivePage: (value: number) => void;
};

const Pagination = ({
  dataAmount,
  currentPage,
  maxPages,
  changeActivePage,
}: Props) => {
  const [currentPageField, setCurrentPageField] = useState(currentPage);

  useEffect(() => {
    setCurrentPageField(currentPage);
  }, [currentPage]);

  const changePage = useCallback(
    (page: number, isIncrement = true) => {
      if (isIncrement) {
        setCurrentPageField((prev) => prev + page);
        changeActivePage(currentPage + page);
      } else {
        setCurrentPageField(page);
        changeActivePage(page);
      }
    },
    [currentPage, changeActivePage]
  );

  const incrementPageNumber = useCallback(() => {
    if (currentPage < maxPages) {
      changePage(1);
    }
  }, [currentPage, maxPages, changePage]);

  const reducePageNumber = useCallback(() => {
    if (currentPage > 1) {
      changePage(-1);
    }
  }, [currentPage, changePage]);

  const onChangeCurrentPage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const page = Number(value);

      // check if the user enters anything other than numbers
      if (!isNaN(page)) {
        setCurrentPageField(page);
      }
    },
    []
  );

  const onSubmitChangePage: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        if (maxPages < currentPageField) {
          changePage(maxPages, false);

          return;
        }

        if (maxPages && currentPageField === 0) {
          changePage(1, false);

          return;
        }

        changePage(currentPageField, false);
      },
      [currentPageField, maxPages, changePage]
    );

  if (!dataAmount) {
    return (
      <Container>
        <WarningMessage>Sorry, no data was found :(</WarningMessage>
      </Container>
    );
  }

  return (
    <Container>
      <ActionButtonContainer $isLeft>
        <ActionButton
          icon={<ChevronIcon color={theme.colors.funtainBlue} />}
          clickHandler={reducePageNumber}
        />
      </ActionButtonContainer>
      <PagesControl>
        <form onSubmit={onSubmitChangePage}>
          <CurrentPage
            value={currentPageField}
            onChange={onChangeCurrentPage}
          />
        </form>
        <AllPages>of {maxPages} pages</AllPages>
      </PagesControl>
      <ActionButtonContainer>
        <ActionButton
          icon={<ChevronIcon color={theme.colors.funtainBlue} />}
          clickHandler={incrementPageNumber}
        />
      </ActionButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
`;

const BUTTON_VERTICAL_RANGE = 10;
const BUTTON_HORIZONTAL_RANGE = 10;

const ActionButtonContainer = styled.div<{ $isLeft?: boolean }>`
  margin-right: 35px;
  transform: rotate(90deg);
  ${({ $isLeft }) =>
    !$isLeft &&
    css`
      margin-left: 35px;
      margin-right: 0;
      transform: rotate(-90deg);
    `}

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }
`;

const CurrentPage = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 30px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.funtainBlue};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
`;

const AllPages = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 400;
`;

const PagesControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WarningMessage = styled.div`
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 20px;
  font-weight: 400;
`;

export default Pagination;
