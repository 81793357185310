import { find } from 'lodash';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import DefaultButton from 'components/buttons/DefaultButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import { listLabels } from 'components/listForSelectingData/dataForLists';
import ListForSelectingDataBE from 'components/listForSelectingData/lists/ListForSelectingDataBE';
import usePrevious from 'hooks/usePrevious';
import {
  CorporateAccount,
  getCorporateAccounts,
} from 'modules/accounts/accountsServices/CorporateAccountsService';
import useGetCorporateAccounts from 'modules/accounts/hooks/useGetCorporateAccounts';
import { ACCOUNTS_SORT_BY_BE } from 'modules/accounts/models';
import { InputContainer } from 'modules/auth/components/styledElements/formsStyledElements';

import { VerticalLine } from '../../Divider';
import {
  ActiveListParameter,
  DropdownInput,
  FormContainer,
  FormLabel,
  SubmitButtonContainer,
} from '../styledElements/formsStyledElements';
import { Title } from '../styledElements/stagesStyledElements';

const ACCOUNT_INPUT_ID = 'ACCOUNT_INPUT_ID';
const PAGE_SIZE = 500;

type Props = {
  offerAccounts: CorporateAccount[];
  title: string;
  accountId: string;
  setOfferAccounts: (value: CorporateAccount[]) => void;
  goToNextStage: () => void;
  saveAccount: (id: string) => void;
  goToPreviousStage?: () => void;
};

const SelectAccount = ({
  offerAccounts,
  title,
  accountId,
  saveAccount,
  setOfferAccounts,
  goToNextStage,
  goToPreviousStage,
}: Props) => {
  const { handleSubmit } = useForm();
  const [account, setAccount] = useState<CorporateAccount>();
  const [isCompaniesListOpen, setIsCompaniesListOpen] = useState(false);
  const [searchingValue, setSearchingValue] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const previousAccountId = usePrevious(accountId);

  const { data, isLoading } = useGetCorporateAccounts([searchingValue], () =>
    getCorporateAccounts({
      data: {
        page: 1,
        pageSize: PAGE_SIZE,
        search: searchingValue,
        sortBy: ACCOUNTS_SORT_BY_BE[0],
      },
    })
  );

  const dataForList = useMemo(
    () =>
      data?.items.map((item) => ({
        id: item.id,
        displayName: item.companyName,
      })),
    [data?.items]
  );

  const formSubmit = () => {
    goToNextStage();
  };

  const closeCompaniesList = useCallback(() => {
    setIsCompaniesListOpen(false);
  }, []);

  useLayoutEffect(() => {
    if (data && accountId && accountId !== previousAccountId) {
      const account =
        find(data.items, { id: accountId }) ||
        find(offerAccounts, { id: accountId });

      setAccount(account);
    }
  }, [accountId, data, offerAccounts, previousAccountId, setOfferAccounts]);

  return (
    <div ref={containerRef}>
      <Title>{title}</Title>
      <FormContainer onSubmit={handleSubmit(formSubmit)}>
        <InputContainer>
          <FormLabel
            id="account-label"
            $isCursorPointer
            htmlFor={ACCOUNT_INPUT_ID}
            $active={!!(account || accountId)}
            onClick={() => setIsCompaniesListOpen(true)}
          >
            Select account
          </FormLabel>
          <DropdownInput onClick={() => setIsCompaniesListOpen(true)}>
            <ActiveListParameter>
              {account ? account.companyName : ''}
            </ActiveListParameter>
            <ActionButton
              icon={<ChevronIcon color={theme.colors.funtainBlue} />}
            />
          </DropdownInput>
          {isCompaniesListOpen && (
            <ListForSelectingDataBE
              searchingValue={searchingValue}
              listWidth="lg"
              listHeight="md"
              isLoading={isLoading}
              setSearchingValue={setSearchingValue}
              data={dataForList}
              label={listLabels.corporateAccount}
              closeList={closeCompaniesList}
              setNewActiveListItem={(id: string) => {
                saveAccount(id);
                if (data) {
                  setOfferAccounts(data.items);
                }
              }}
            />
          )}
        </InputContainer>
        <SubmitButtonContainer>
          {goToPreviousStage && (
            <DefaultButton
              text="back"
              textColor={theme.colors.white}
              isActive
              withShadow
              buttonColor={theme.colors.funtainBlue}
              isUppercase
              buttonSize="md"
              borderSize="md"
              clickHandler={goToPreviousStage}
            />
          )}
          <DefaultButton
            text="next"
            textColor={theme.colors.white}
            isActive={!!account}
            isSubmit
            withShadow
            buttonColor={
              account ? theme.colors.funtainBlue : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
      {account && (
        <AboutAccountContainer>
          <Notification>Confirm account details</Notification>
          <AccountDetails>
            <AboutAccount>
              <h4>{account.companyName}</h4>
              <Details>
                <p>{account.country}</p>
                <ScaledVerticalLine
                  $withOpacity
                  $color={theme.colors.primary}
                />
                <p>{account.website}</p>
                <ScaledVerticalLine
                  $withOpacity
                  $color={theme.colors.primary}
                />
                <p>{account.businessSector}</p>
              </Details>
            </AboutAccount>
            <AboutAccount>
              <h4>Primary contact</h4>
              <Details>
                <p>
                  {account.primaryContact.firstName}{' '}
                  {account.primaryContact.lastName}
                </p>
              </Details>
            </AboutAccount>
          </AccountDetails>
        </AboutAccountContainer>
      )}
    </div>
  );
};

const AccountDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 18px 8px 12px;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  border: 2px solid ${({ theme }) => theme.colors.cararra};

  div:first-child {
    margin-bottom: 30px;
  }
`;

const Notification = styled.div`
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.primary};
`;

const AboutAccount = styled.div`
  h4 {
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  height: 24px;

  p {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const AboutAccountContainer = styled.div`
  margin-top: 7px;
`;

const ScaledVerticalLine = styled(VerticalLine)`
  transform: scale(0.6);
  margin: 0 19px;
`;

export default SelectAccount;
