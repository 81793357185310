import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import ParameterNavigation from 'components/ParameterNavigation';
import SearchBar from 'components/searchBar/SearchBar';
import { STANDARD_PAGE_SIZE } from 'components/table/models';
import TableLoader from 'components/table/TableLoader';

import CreateUserModal from '../components/CreateUserModal';
import UsersTable from '../components/UsersTable';
import UserAccountContextProvider from '../context/userAccountContext';
import useGetUsers from '../hooks/useGetUsers';
import {
  parametersForSortingUsersBE,
  parametersPropertyForSortingBE,
  QueryKey,
  UserCategory,
} from '../models';
import { getUsers } from '../usersServices/usersAccountsService';

const userCategories = [UserCategory.All];

const Users = () => {
  const [nameForSearching, setNameForSearching] = useState('');
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);
  const [sortByListItemIndex, setSortByListItemIndex] = useState(2);
  const [pageSize, setPageSize] = useState(STANDARD_PAGE_SIZE);
  const [page, setPage] = useState(1);

  const { data, isLoading } = useGetUsers(
    [
      QueryKey.GetUsersAccounts,
      nameForSearching,
      sortByListItemIndex,
      page,
      pageSize,
    ],
    () =>
      getUsers({
        data: {
          pageNumber: page || 1,
          pageSize,
          searchQuery: nameForSearching,
          orderByProperty: parametersPropertyForSortingBE[sortByListItemIndex],
          orderingOption: parametersForSortingUsersBE[sortByListItemIndex],
        },
      })
  );

  const [activeUserCategory, setActiveUserCategory] = useState<UserCategory>(
    UserCategory.All
  );

  const searchByEmail = useCallback((email: string) => {
    setPage(1);
    setNameForSearching(email);
  }, []);

  const changeUserCategory = useCallback(
    (index: number) => setActiveUserCategory(userCategories[index]),
    []
  );

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
  }, [isCreatingModalOpen]);

  return (
    <Container>
      <ParameterNavigation
        withOffset
        parameters={userCategories}
        setNewParameter={changeUserCategory}
        activeParameter={activeUserCategory}
      />
      <SearchBar
        title="Users:"
        buttonText="Create user"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter={activeUserCategory}
        setNewNameToFilter={searchByEmail}
      />
      {isLoading ? (
        <TableLoader />
      ) : (
        <UsersTable
          sortByListItemIndex={sortByListItemIndex}
          setPage={setPage}
          sortUsers={setSortByListItemIndex}
          data={data?.items}
          page={page}
          pageSize={pageSize}
          changePageSize={setPageSize}
          dataAmount={data?.totalCount || 0}
        />
      )}

      {isCreatingModalOpen && (
        <UserAccountContextProvider>
          <CreateUserModal closeModalWindow={toggleCreatingModalWindow} />
        </UserAccountContextProvider>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Users;
