import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { LogoIcon } from 'components/icons/LogoIcon';

import DetailsSection from './components/DetailsSection';
import IntroSection, { QrCodeContainerInfo } from './components/IntroSection';
import ShidoIsTheBestAssistantSection from './components/ShidoIsTheBestAssistantSection';
import BackgroundGraph from './icons/BackgroundGraph';

type TabletPreviewProps = {
  image: string;
  title: string;
  subtitle: string;
  bodyText: string;
  qrCodeUrl: string;
  term?: string;
  chosenColor: {
    light: string;
    strong: string;
  };
};

const TabletPreview = ({
  title,
  subtitle,
  bodyText,
  term,
  chosenColor,
  qrCodeUrl,
  image,
}: TabletPreviewProps) => {
  return (
    <Wrapper>
      <Container $color={chosenColor.light}>
        <IntroSection
          qrCodeUrl={qrCodeUrl}
          term={term}
          chosenColor={chosenColor}
          title={title}
        />
        <DetailsSection subtitle={subtitle} body={bodyText} logoImage={image} />
        <ShidoIsTheBestAssistantSection />
        <Footer>
          <FreeTrial>Start a free {term} trial!</FreeTrial>
          <Subtitle>
            Scan the code with your mobile device to get the app!
          </Subtitle>
          <StyledQr>
            <QrCodeContainerInfo>
              <ReactSVG src={qrCodeUrl} />
            </QrCodeContainerInfo>
          </StyledQr>
          <ShidoIconWrapper>
            <ShidoTitle>
              <LogoIcon width={70} height={44} />
              <ShidoMark>®</ShidoMark>
            </ShidoTitle>
          </ShidoIconWrapper>
        </Footer>
        <BackgroundGraph color={chosenColor.light} />
      </Container>
    </Wrapper>
  );
};

const Footer = styled.div`
  position: relative;
  z-index: 3;
`;

const ShidoMark = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  top: -2px;
  right: -21px;
`;

const ShidoTitle = styled.div`
  position: relative;
`;

const ShidoIconWrapper = styled.div`
  margin: 40px auto 162px;
  width: 70px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledQr = styled.div`
  margin-top: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FreeTrial = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 73px;
`;

const Subtitle = styled.div`
  margin-top: 22px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 20px;
  font-weight: 300;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Container = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  max-width: 1366px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;

  .background-graph {
    z-index: 2;
    position: absolute;
    margin-top: 888px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default TabletPreview;
