import { useEffect } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

import {
  CorporateAccount,
  CorporateAccountDetailsData,
  CorporateAccountPrimaryContactData,
} from '../accountsServices/CorporateAccountsService';

const useSaveDataForEditCorporateAccount = (
  accountForEditing: CorporateAccount,
  setSaved: (value: boolean) => void,
  saveData: (
    accountDetails: CorporateAccountDetailsData,
    primaryContact: CorporateAccountPrimaryContactData,
    countryAbbreviation: string
  ) => void,
  saved?: boolean
) => {
  useEffect(() => {
    const { mobileNumberCode, mobileNumber } = accountForEditing.primaryContact;
    const countryAbbr = parsePhoneNumber(`${mobileNumberCode}${mobileNumber}`)
      ?.country;

    if (!saved) {
      const { website, country, companyName, businessSector } =
        accountForEditing;

      saveData(
        {
          website,
          companyName,
          country: country,
          businessSector: businessSector,
        },
        accountForEditing.primaryContact,
        countryAbbr || ''
      );
      setSaved(true);
    }
  }, [accountForEditing, saveData, saved, setSaved]);
};

export default useSaveDataForEditCorporateAccount;
