import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
};

const DashboardChip = ({ children }: Props) => (
  <Container>{children}</Container>
);

const Container = styled.div`
  padding: 5px 15px;
  border-radius: 15px;
  background-color: rgb(255 255 255 / 18%);
`;

export default DashboardChip;
