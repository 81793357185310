export const STANDARD_PAGE_SIZE = 20;
export const MEDIUM_PAGE_SIZE = 50;
export const LARGE_PAGE_SIZE = 100;
export const OPENING_MODAL_ANIMATION_DELAY = 250;
export const pageSizes = [
  STANDARD_PAGE_SIZE,
  MEDIUM_PAGE_SIZE,
  LARGE_PAGE_SIZE,
];

const userItemsTypes = ['Email', 'Mobile', 'Joined'];
const OfferColumns = ['Shido.me/', 'Account', 'Status', 'Redemptions', 'Notes'];

export enum TableRowGrid {
  Account = 'ACCOUNT',
  CorporateOfferHeaderRow = 'CORPORATE_OFFER',
  CorporateOfferRow = 'CORPORATE_OFFER_ROW',
  AffiliateOfferRow = 'AFFILIATE_OFFER',
  PromotionOffer = 'PROMOTION_OFFER',
  OnboardingOffers = 'ONBOARDING_OFFER',
  Users = 'USERS',
  UsersItems = 'USERS_ITEMS',
  DelegateAccounts = 'DELEGATE_ACCOUNTS',
  ManageUsers = 'MANAGE_USERS',
  LandingUsers = 'LANDING_USERS',
  ContentModules = 'CONTENT_MODULES',
  ContentCourses = 'CONTENT_COURSES',
}

export const TABLE_COLUMNS: Record<TableRowGrid, string> = {
  [TableRowGrid.Account]: '2fr 140px 112px 251px',
  [TableRowGrid.CorporateOfferHeaderRow]: '24% 26% 10.9% 10.9% 9.7% 18.3%',
  [TableRowGrid.CorporateOfferRow]: '24% 26% 10.9% 10.9% 14.5% 13.5%',
  [TableRowGrid.PromotionOffer]: '50% 10.8% 9.7% 11% 18.5%',
  [TableRowGrid.AffiliateOfferRow]: '50% 11% 10.9% 12.6% 15.5%',
  [TableRowGrid.OnboardingOffers]: '6fr 140px 140px 123px 240px',
  [TableRowGrid.Users]: '6fr 333px 62px 251px',
  [TableRowGrid.UsersItems]: '6fr 333px 147px 170px',
  [TableRowGrid.DelegateAccounts]: '60.3fr 30.9fr 10fr 19fr',
  [TableRowGrid.ManageUsers]: '50% 13% 13% 6% 18% 10%',
  [TableRowGrid.LandingUsers]: '50% 13% 12.8% 5.5% 18.7%',
  [TableRowGrid.ContentModules]: '67fr 29fr 35fr 2fr',
  [TableRowGrid.ContentCourses]: '15fr 15fr 10fr 5fr',
};

export const TABLE_HEADER: Record<TableRowGrid, string[]> = {
  [TableRowGrid.Account]: ['Name', 'Type', 'Status'],
  [TableRowGrid.CorporateOfferHeaderRow]: OfferColumns,
  [TableRowGrid.CorporateOfferRow]: OfferColumns,
  [TableRowGrid.PromotionOffer]: [
    'Shido.me/',
    'Status',
    'Redemptions',
    'Notes',
  ],
  [TableRowGrid.AffiliateOfferRow]: [
    'Affiliate name',
    'Type',
    'Referrals',
    'Paid subscribers this month',
  ],
  [TableRowGrid.OnboardingOffers]: ['Offer', 'Code', 'Status', 'Users'],
  [TableRowGrid.Users]: userItemsTypes,
  [TableRowGrid.UsersItems]: userItemsTypes,
  [TableRowGrid.DelegateAccounts]: ['Name', 'Title', 'Role'],
  [TableRowGrid.ManageUsers]: ['Name', 'Department', 'Sex', 'Age'],
  [TableRowGrid.LandingUsers]: ['URL', 'Offer code', 'Users', 'Expires'],
  [TableRowGrid.ContentModules]: ['Topics', 'Items', 'Date added'],
  [TableRowGrid.ContentCourses]: [
    'Course',
    'Description',
    'No. of lessons',
    'Date added',
  ],
};

export enum TemporaryStatus {
  Activated = 'ACTIVATED',
  Suspended = 'SUSPENDED',
  Published = 'PUBLISHED',
  Updated = 'UPDATED',
}
