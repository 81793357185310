import React from 'react';
import styled from 'styled-components';

import { ConversionFunnelBE } from 'modules/offers/models';

import CheckoutSection from './components/CheckoutSection';
import DetailsSection from './components/DetailsSection';
import EmailSection from './components/EmailSection';
import IntroSection from './components/IntroSection';
import ResponseSection from './components/ResponseSection';
import useCreateRefsForClick, {
  handlerRefClick,
} from './useCreateRefsForClick';

export type DesktopPreviewProps = {
  intro: string;
  image: string | null;
  affiliateStatement: string;
  affiliateName: string;
  bodyText: string;
  affiliateHandle: string;
  chosenColor: {
    light: string;
    strong: string;
  };
  conversionFunnel: ConversionFunnelBE;
};

const TabletPreview = ({
  intro,
  affiliateStatement,
  image,
  affiliateName,
  affiliateHandle,
  bodyText,
  chosenColor,
  conversionFunnel,
}: DesktopPreviewProps) => {
  const { refs } = useCreateRefsForClick();

  return (
    <>
      <IntroSection
        name={affiliateName}
        image={image}
        handle={affiliateHandle}
        chosenColor={chosenColor}
        goToNextSection={handlerRefClick(refs[0])}
      />
      <DetailsSection
        ref={refs[0]}
        goToNextSection={handlerRefClick(refs[1])}
        bodyText={bodyText}
        intro={intro}
      />
      <ResponseSection
        ref={refs[1]}
        name={affiliateName}
        image={image}
        handle={affiliateHandle}
        statement={affiliateStatement}
        goToNextSection={handlerRefClick(refs[2])}
      />
      {conversionFunnel === ConversionFunnelBE.App ? (
        <EmailSection ref={refs[2]} isDesktop />
      ) : (
        <CheckoutSection ref={refs[2]} isDesktop />
      )}

      <GraphBackground $color={chosenColor.light} />
    </>
  );
};

const GraphBackground = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/layout.svg') no-repeat 50% 50%;
  mask-position: bottom -1670px right 50%;
`;

export default TabletPreview;
