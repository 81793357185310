import React from 'react';
import styled, { css } from 'styled-components';

import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

import NavigationItem from './NavigationItem';

type TableInfoProps = {
  pageSizes: number[];
  activePageSize: number;
  employersPage?: boolean;
  navigationList: string[];
  activeNavigationItem: string;
  changePageSize: (value: number) => void;
  chooseActiveItem: (index: number) => void;
};

const OnboardingTableInfo = ({
  navigationList,
  pageSizes,
  employersPage,
  changePageSize,
  chooseActiveItem,
  activePageSize,
  activeNavigationItem,
}: TableInfoProps) => (
  <Container $employersPage={employersPage}>
    <Navigation>
      {navigationList.map((navItemText, i) => {
        return (
          <React.Fragment key={navItemText}>
            {i !== 0 && <VerticalLine />}
            <NavigationItem
              text={navItemText}
              active={activeNavigationItem === navItemText}
              chooseActiveItem={() => chooseActiveItem(i)}
            />
          </React.Fragment>
        );
      })}
    </Navigation>
    <RowsPerPage>
      <span>Per page:</span>
      {pageSizes.map((pages) => (
        <RowsNumber
          key={pages}
          $active={activePageSize === pages}
          onClick={() => changePageSize(pages)}
        >
          {pages}
        </RowsNumber>
      ))}
    </RowsPerPage>
  </Container>
);

const VerticalLine = styled.div`
  height: 20px;
  width: 0.5px;
  opacity: 0.4;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Container = styled.div<{ $employersPage?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 19px 18px;
  ${({ $employersPage }) =>
    $employersPage &&
    css`
      padding: 77px 22px 18px 19px;
    `}
`;

const RowsPerPage = styled.div`
  display: flex;
  padding-top: 9px;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 400;
  right: -3px;
  position: relative;
`;

const BUTTON_VERTICAL_RANGE = 5;
const BUTTON_HORIZONTAL_RANGE = 7;

const RowsNumber = styled.span<{ $active?: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-weight: 700;
  cursor: pointer;
  margin-left: 19px;
  ${increaseClickableAreaForHtmlElement(
    BUTTON_VERTICAL_RANGE,
    BUTTON_HORIZONTAL_RANGE
  )}
  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
      font-weight: 400;
    `}
  &:hover {
    text-decoration: underline;
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 4px;
`;

export default OnboardingTableInfo;
