import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import DefaultButton from 'components/buttons/DefaultButton';
import { CorporateAccountPrimaryContactData } from 'modules/accounts/accountsServices/CorporateAccountsService';

import MobileInput from '../forms/inputs/MobileInput';
import StandardInput from '../forms/inputs/StandardInput';
import { InputId } from '../models';
import { PrimaryContactValidationSchema } from '../models/validationSchemas';
import {
  FormContainer,
  SubmitButtonContainer,
} from '../styledElements/formsStyledElements';
import { Title } from '../styledElements/stagesStyledElements';

type Props = {
  title: string;
  countryAbbr?: string;
  primaryContact: CorporateAccountPrimaryContactData;
  submitButtonTitle: string;
  goToNextStage: (primaryContact: CorporateAccountPrimaryContactData) => void;
  goToPreviousStage: () => void;
  savePrimaryContactData: (
    primaryContactData: CorporateAccountPrimaryContactData,
    countryAbbreviation?: string
  ) => void;
};

const PrimaryContact = ({
  title,
  countryAbbr,
  primaryContact,
  goToNextStage,
  submitButtonTitle,
  goToPreviousStage,
  savePrimaryContactData,
}: Props) => {
  const [isMobileValid, setIsMobileValid] = useState(true);
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(PrimaryContactValidationSchema),
  });
  const isFormValid = isValid && isMobileValid;

  const saveFirstName = useCallback(
    () =>
      savePrimaryContactData({
        ...primaryContact,
        firstName: watch()[InputId.FirstNameInputId],
      }),
    [primaryContact, savePrimaryContactData, watch]
  );

  const saveLastName = useCallback(
    () =>
      savePrimaryContactData({
        ...primaryContact,
        lastName: watch()[InputId.LastNameInputId],
      }),
    [primaryContact, savePrimaryContactData, watch]
  );

  const saveEmail = useCallback(
    () =>
      savePrimaryContactData({
        ...primaryContact,
        email: watch()[InputId.EmailAddressInputId],
      }),
    [primaryContact, savePrimaryContactData, watch]
  );

  const saveRole = useCallback(
    () =>
      savePrimaryContactData({
        ...primaryContact,
        role: watch()[InputId.RoleInputId],
      }),
    [primaryContact, savePrimaryContactData, watch]
  );

  const saveMobile = useCallback(
    (code?: string, countryAbbreviation?: string) => {
      const validNumber = watch()[InputId.MobileNumberInputId].slice(
        code?.length
      );

      savePrimaryContactData(
        {
          ...primaryContact,
          mobileNumber: validNumber,
          mobileNumberCode: code || '',
        },
        countryAbbreviation
      );
    },
    [primaryContact, savePrimaryContactData, watch]
  );

  const formSubmit = () => {
    const validNumber = watch()[InputId.MobileNumberInputId].slice(
      primaryContact.mobileNumberCode?.length
    );

    goToNextStage({
      ...primaryContact,
      firstName: watch()[InputId.FirstNameInputId],
      lastName: watch()[InputId.LastNameInputId],
      email: watch()[InputId.EmailAddressInputId],
      role: watch()[InputId.RoleInputId],
      mobileNumber: validNumber,
    });
  };

  return (
    <div>
      <Title>{title}</Title>
      <FormContainer onSubmit={handleSubmit(formSubmit)} role="form">
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="First name"
          inputId={InputId.FirstNameInputId}
          saveData={saveFirstName}
          inputValue={primaryContact.firstName}
        />
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="Last name"
          inputId={InputId.LastNameInputId}
          saveData={saveLastName}
          inputValue={primaryContact.lastName}
        />
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="Role"
          inputId={InputId.RoleInputId}
          saveData={saveRole}
          inputValue={primaryContact.role}
        />
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="Email address"
          inputId={InputId.EmailAddressInputId}
          saveData={saveEmail}
          inputValue={primaryContact.email}
        />
        <MobileInput
          control={control}
          isMobileValid={isMobileValid}
          watch={watch}
          saveData={saveMobile}
          countryAbbr={countryAbbr}
          inputValue={primaryContact.mobileNumber}
          code={primaryContact.mobileNumberCode}
          register={register}
          setValue={setValue}
          errors={errors}
          setIsMobileValid={setIsMobileValid}
          inputId={InputId.MobileNumberInputId}
        />
        <SubmitButtonContainer>
          <DefaultButton
            text="back"
            textColor={theme.colors.white}
            isActive
            withShadow
            buttonColor={theme.colors.funtainBlue}
            isUppercase
            buttonSize="md"
            borderSize="md"
            clickHandler={goToPreviousStage}
          />
          <DefaultButton
            text={submitButtonTitle}
            textColor={theme.colors.white}
            isActive={isFormValid}
            isSubmit
            withShadow
            buttonColor={
              isFormValid ? theme.colors.swamp : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
    </div>
  );
};

export default React.memo(PrimaryContact);
