import React, { useState } from 'react';
import styled from 'styled-components';

import ParameterNavigation from 'components/ParameterNavigation';

import AccountsCategory from '../categories/accounts/AccountsCategory';
import OffersCategory from '../categories/offers/OffersCategory';
import UsersCategory from '../categories/users/UsersCategory';
import { DashboardCategory } from '../models';

const DASHBOARD_CATEGORIES: DashboardCategory[] = [
  DashboardCategory.Users,
  DashboardCategory.Offers,
  DashboardCategory.Accounts,
];

const Dashboard = () => {
  const [activeDashboardCategory, setActiveDashboardCategory] =
    useState<DashboardCategory>(DashboardCategory.Users);

  const changeDashboardCategory = (index: number) =>
    setActiveDashboardCategory(DASHBOARD_CATEGORIES[index]);

  return (
    <Container>
      <ParameterNavigation
        withOffset
        parameters={DASHBOARD_CATEGORIES}
        activeParameter={activeDashboardCategory}
        setNewParameter={changeDashboardCategory}
      />
      {activeDashboardCategory === DashboardCategory.Users && <UsersCategory />}
      {activeDashboardCategory === DashboardCategory.Offers && (
        <OffersCategory />
      )}
      {activeDashboardCategory === DashboardCategory.Accounts && (
        <AccountsCategory />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Dashboard;
