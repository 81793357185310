import React, { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import Error from 'components/actionModal/Error';
import StandardInput from 'components/actionModal/forms/inputs/StandardInput';
import UploadOfferPageField from 'components/actionModal/forms/uploadOfferPageField/UploadOfferPageField';
import DefaultButton from 'components/buttons/DefaultButton';
import { promotionColorList } from 'components/listForSelectingData/dataForLists';
import { Colors } from 'components/previewPages/previewPagePromotion/PreviewPagePromotion';
import { PromotionOfferContext } from 'modules/offers/context/PromotionOfferContext';
import useReloadImage from 'modules/offers/hooks/useReloadImage';
import useSetFileHandler from 'modules/offers/hooks/useSetFileHandler';
import {
  DROP_IMAGE_EXTENSIONS,
  IMAGE_EXTENSIONS,
  PromotionSettingsPageAttributes,
} from 'modules/offers/models';

import StandardDropdown from '../../forms/dropdowns/StandardDropdown';
import { InputId } from '../../models';
import { promotionOfferSettingsValidationSchema } from '../../models/validationSchemas';
import {
  FormContainer,
  ModalStage,
  MultipleInputsContainer,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';
import PreviewBlock, { PreviewOffer } from '../offerPage/PreviewBlock';

type PromotionOfferSettingsProps = {
  isShowStage: boolean;
  settings: PromotionSettingsPageAttributes;
  isEdit?: boolean;
  isUpdatedImage?: boolean;
  isPending?: boolean;
  title: string;
  imageName: string;
  id?: string;
  image: string;
  previewOffer: PreviewOffer;
  submitButtonTitle: string;
  saveSettings: (settings: PromotionSettingsPageAttributes) => void;
  goToNextStage: (data: PromotionSettingsPageAttributes) => void;
  saveImageName: (name: string) => void;
  saveImage: (
    urlImg: string,
    toggleFormView: () => void,
    openError: (value: boolean) => void,
    setErrorText: (value: string) => void,
    textWithPercentages?: HTMLParagraphElement | null,
    file?: File
  ) => void;
};

const PromotionOfferSettings = ({
  isShowStage,
  isEdit,
  settings,
  isUpdatedImage = true,
  isPending,
  title,
  image,
  imageName,
  previewOffer,
  submitButtonTitle,
  saveSettings,
  saveImage,
  goToNextStage,
  saveImageName,
}: PromotionOfferSettingsProps) => {
  const { imageFile, offerDraft } = useContext(PromotionOfferContext);

  const [isFormHidden, setIsFormHidden] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [logoError, setLogoError] = useState(false);

  const {
    clearErrors,
    watch,
    register,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(promotionOfferSettingsValidationSchema),
  });

  const toggleFormView = useCallback(() => {
    if (!isFormHidden) {
      clearErrors();
    }
    setIsFormHidden(!isFormHidden);
  }, [clearErrors, isFormHidden]);

  const isFormValid = isValid;

  const saveBackgroundColor = useCallback(
    (backgroundColour: string) =>
      saveSettings({
        ...settings,
        backgroundColour: backgroundColour as keyof Colors,
      }),
    [saveSettings, settings]
  );

  const saveNote = useCallback(
    () =>
      saveSettings({
        ...settings,
        notes: watch()[InputId.NoteInputId] || '',
      }),
    [saveSettings, settings, watch]
  );

  const setFileHandler = useSetFileHandler({
    toggleFormView,
    setLogoError,
    saveImage,
    setErrorText,
  });

  useReloadImage({
    imageFile,
    image,
    saveImage,
    setLogoError,
    setErrorText,
    isEdit,
    isShowStage,
    offerId: offerDraft.promotionAccountId,
  });

  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid) {
      goToNextStage({
        backgroundColour: settings.backgroundColour,
        notes: watch()[InputId.NoteInputId] || '',
      });
    }
  };

  return (
    <ModalStage $isShowStage={isShowStage}>
      <Title>{title}</Title>
      {!isFormHidden && (
        <FormContainer onSubmit={formSubmit}>
          <MultipleInputsContainer>
            <StandardDropDownWrapper>
              <StandardDropdown
                inputLabel="Background color"
                listLabel="Background color"
                list={promotionColorList}
                saveData={saveBackgroundColor}
                inputValue={settings.backgroundColour}
              />
            </StandardDropDownWrapper>
          </MultipleInputsContainer>
          <StandardInput
            isRequired={false}
            errors={errors}
            register={register}
            setValue={setValue}
            label="Notes (optional)"
            inputId={InputId.NoteInputId}
            inputValue={settings.notes}
            saveData={saveNote}
          />

          {!isFormHidden && (
            <SubmitButtonContainer>
              <DefaultButton
                text={submitButtonTitle}
                textColor={theme.colors.white}
                isActive={!isPending && isUpdatedImage && isFormValid}
                isSubmit
                withShadow={isFormValid}
                buttonColor={
                  !isPending && isFormValid
                    ? theme.colors.swamp
                    : theme.colors.quillGray
                }
                isUppercase
                buttonSize="md"
                borderSize="md"
              />
            </SubmitButtonContainer>
          )}
        </FormContainer>
      )}

      <UploadContainer>
        <UploadOfferPageField
          isShowStage={isShowStage}
          isPromotionUpload
          pictureDescription="Square, transparent background, Max file size 5MB"
          fileDropZoneText="Drag & drop the replacement promotion image here, or"
          fileName={imageName}
          file={image}
          fileError={logoError}
          validExtensions={IMAGE_EXTENSIONS}
          validExtensionsForDrop={DROP_IMAGE_EXTENSIONS}
          uploadSuggestionText="Overwrite promotion image"
          uploadSuggestionFormat="(.SVG, .JPEG, .JPG, .PNG)"
          setFileError={setLogoError}
          toggleForm={toggleFormView}
          setErrorText={setErrorText}
          setFileName={saveImageName}
          setFile={setFileHandler}
        />
      </UploadContainer>
      <PreviewBlock
        isShowStage={isShowStage}
        previewOffer={previewOffer}
        logo={image}
      />
      <Error isOpen={logoError} errorText={errorText} />
    </ModalStage>
  );
};

const UploadContainer = styled.div`
  .upload-suggestion {
    top: -19px;
  }
`;

const StandardDropDownWrapper = styled.div`
  max-width: 320px;
`;

export default PromotionOfferSettings;
