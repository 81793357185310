import config from 'config';
import React, { useCallback, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import BrowserIcon from 'components/icons/BrowserIcon';
import ChevronIcon from 'components/icons/ChevronIcon';
import { saveExternalSvg } from 'helpers/saveExternalSvg';

import { HorizontalLine } from '../../Divider';
import { printPdf } from '../helpers/printPdf';
import useShowSvgImg from '../helpers/useShowSvgImg';
import {
  ButtonContainer,
  ChooseCountry,
  Header,
  IconQrWrapper,
  IconWrapper,
  InformationContainer,
  InformationContainerFlex,
  InformationItem,
  ScaledVerticalLine,
} from '../styledElements/informationStyledElements';

type OfferPageInfo = {
  url: string;
  qrCodeUrl: string;
  publicCode: string;
};

type Settings = {
  terms: string;
  valid: string;
  redemptions: string;
};

type PromotionInformationProps = {
  color: string;
  settings: Settings;
  offerPageInfo: OfferPageInfo;
};

const PromotionInformation = ({
  color,
  settings: { terms, valid, redemptions },
  offerPageInfo: { url, qrCodeUrl, publicCode },
}: PromotionInformationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const isShowSvg = useShowSvgImg();

  const downloadPdf = useCallback(async () => {
    setIsLoading(true);
    await printPdf(url);
    setIsLoading(false);
  }, [url]);

  return (
    <>
      <>
        <InformationContainerFlex>
          <Header>Settings</Header>
          <br />
          <SettingsContainer>
            <InformationItem>
              <span>Terms:</span> <p>{terms} trial</p>
            </InformationItem>
            <ScaledVerticalLine $color={theme.colors.white} />
            <InformationItem>
              <span>Valid:</span> <p>{valid}</p>
            </InformationItem>
            {terms !== '7 day' && (
              <>
                <ScaledVerticalLine $color={theme.colors.white} />

                <InformationItem>
                  <span>Redemptions:</span> <p>{redemptions}</p>
                </InformationItem>
              </>
            )}
          </SettingsContainer>
        </InformationContainerFlex>
        <HorizontalLine />
        <ShidoUrlContainer>
          <Header>Offer page</Header>
          <br />
          <InformationItem $isLowercaseText>
            <IconWrapper>
              <BrowserIcon />
            </IconWrapper>
            <ShidoUrl target="_blank" href={`${config.shidoUrl}${url}`}>
              www.shido.me/{url}
            </ShidoUrl>
          </InformationItem>
        </ShidoUrlContainer>
        <HorizontalLine />
        <InformationContainer>
          <Header>Offer assets</Header>
          <br />
          <InformationItem $isSmallFontSize>
            <ChooseCountry>
              <img src="./images/countries/netherlands.svg" alt="Netherlands" />
              NL
              <ChevronIcon color={theme.colors.white} />
            </ChooseCountry>
            Printable offer page (.PDF)
          </InformationItem>
          <div>
            <QrInformationItem
              onClick={() => saveExternalSvg(qrCodeUrl, publicCode)}
              $isSmallFontSize
            >
              <IconQrWrapper>
                {isShowSvg && <ReactSVG src={qrCodeUrl} />}
              </IconQrWrapper>
              QR code (.SVG)
            </QrInformationItem>
          </div>
        </InformationContainer>
      </>
      <ButtonContainer $color={color}>
        <DefaultButton
          text="Download"
          textColor={theme.colors.funtainBlue}
          isActive={!isLoading}
          withShadow
          buttonColor={theme.colors.white}
          isUppercase
          buttonSize="md"
          borderSize="md"
          clickHandler={downloadPdf}
        />
      </ButtonContainer>
    </>
  );
};

const QrInformationItem = styled(InformationItem)`
  cursor: pointer;
  width: 150px;
`;

const ShidoUrlContainer = styled(InformationContainer)`
  grid-template-columns: repeat(2, auto);
`;

const ShidoUrl = styled.a`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
`;

const SettingsContainer = styled.div`
  display: flex;
`;

export default PromotionInformation;
