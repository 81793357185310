import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import { DottedLine } from 'components/Divider';

import { goalsData } from '../mockData/goalsData';

import GoalCard from './GoalCard';

const TeamGoals = () => {
  // Temporary solution, in the future it will be replaced with real data
  const middleIndex = Math.ceil(goalsData.length / 2);

  const firstHalf = goalsData.slice(0, middleIndex);
  const secondHalf = goalsData.slice(middleIndex);

  const [isCardsOpen, setOpenCards] = useState(false);

  const toggleOpenCards = useCallback(() => {
    setOpenCards(!isCardsOpen);
  }, [isCardsOpen]);

  return (
    <Wrapper>
      <TopLine />
      <TeamGoalsTitle>Popular team goals</TeamGoalsTitle>
      <GoalsCardWrapper>
        {firstHalf.map((item) => {
          return <GoalCard key={item.id} {...item} />;
        })}
      </GoalsCardWrapper>
      {isCardsOpen && (
        <>
          <AdditionalDottedLineContainer>
            <DottedLine />
          </AdditionalDottedLineContainer>
          <GoalsCardWrapper>
            {secondHalf.map((item) => {
              return <GoalCard key={item.id} {...item} />;
            })}
          </GoalsCardWrapper>
          <Gap />
        </>
      )}
      <ButtonWrapper>
        <DefaultButton
          clickHandler={toggleOpenCards}
          text={isCardsOpen ? 'Show less' : 'Show more'}
          isActive
          textColor={theme.colors.white}
          buttonColor={theme.colors.funtainBlue}
          buttonSize="md"
          withShadow
          borderSize="md"
        />
      </ButtonWrapper>
      <DottedLineWrapper>
        <DottedLine />
      </DottedLineWrapper>
    </Wrapper>
  );
};

const TopLine = styled.hr`
  opacity: 0.2;
  border-top: 2px dotted ${({ theme }) => theme.colors.primary};
  margin-top: 30px;
`;

const Gap = styled.div`
  margin-bottom: 45px;
`;

const DottedLineWrapper = styled.div`
  visibility: hidden;
  margin-top: 80px;
`;

const AdditionalDottedLineContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 77px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const GoalsCardWrapper = styled.div`
  margin: auto;
  row-gap: 20px;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;

  div:last-child {
    border-right: none;
  }

  @media (width <= 1190px) {
    grid-auto-flow: unset !important;
    grid-template-columns: repeat(2, 282px);
  }

  @media (width <= 660px) {
    grid-template-columns: repeat(1, 282px);
  }
`;

const TeamGoalsTitle = styled.div`
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 74px;
  padding-top: 67px;
`;

const Wrapper = styled.div`
  padding-left: 40px;
  width: 100%;
  padding-right: 40px;
  box-sizing: border-box;
`;

export default TeamGoals;
