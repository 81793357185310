import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import DemographicsCarousel from 'components/carousel/DemographicsCarousel';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';
import { BadResultItemInfo } from 'modules/insights/models';

type Props = {
  resultArray: BadResultItemInfo[];
  closeModal: () => void;
};

const DemographicsModal = ({ resultArray, closeModal }: Props) => (
  <Container>
    <CloseButtonWrapper>
      <VerticalLine $withOpacity $color={theme.colors.white} />
      <ActionButton
        clickHandler={closeModal}
        icon={
          <CloseIcon
            size={20}
            color={theme.colors.funtainBlue}
            prefix="modal"
          />
        }
      />
    </CloseButtonWrapper>
    <DemographicsCarousel slides={resultArray} />
  </Container>
);

const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightPrimary};
  height: 580px;
  width: 100%;
  position: absolute;
  top: -30px;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 40px;
  border-radius: 5px;
  box-sizing: border-box;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 24px;
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export default DemographicsModal;
