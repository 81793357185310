import React from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';

import { checkValidAdminEmail } from '../AuthService';

type ResendCodePanelProps = {
  email: string;
  isOpen: boolean;
  closePanel: () => void;
};

const ResendCodePanel = ({
  email,
  isOpen,
  closePanel,
}: ResendCodePanelProps) => {
  const { mutate } = useMutation(checkValidAdminEmail);

  return (
    <Container $isOpen={isOpen}>
      <ResendCodeWarning>
        <span>Didn’t receive your code?</span> Please allow 60 seconds for the
        message to arrive before requesting another code.
      </ResendCodeWarning>
      <ActionPart>
        <DefaultButton
          isActive
          borderSize="lg"
          buttonSize="lg"
          withShadow={false}
          text="resend code"
          isUppercase
          clickHandler={() => {
            mutate(email);
            closePanel();
          }}
          textColor={theme.colors.white}
          buttonColor={theme.colors.funtainBlue}
        />
        <VerticalLine $color={theme.colors.white} />
        <CloseButton className="close-button" onClick={closePanel}>
          <CloseIcon color={theme.colors.white} />
        </CloseButton>
      </ActionPart>
    </Container>
  );
};

const Container = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  width: 600px;
  height: 80px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.funtainBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      top: -79px;
      z-index: 10;
      visibility: visible;
      opacity: 1;

      &::before {
        content: '';
        transition-delay: 0.4s;
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

const ResendCodeWarning = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  line-height: 19px;
  margin-right: 21px;

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const CloseButton = styled.button`
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0;
`;

const ActionPart = styled.div`
  display: flex;
  visibility: visible;
  align-items: center;

  div {
    margin-right: 20px;
  }
`;

export default ResendCodePanel;
