import React from 'react';

const ReferrerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24.013"
    height="24"
    viewBox="0 0 24.013 24"
  >
    <defs>
      <clipPath id="referrer-clip-path">
        <path
          id="Path_8717"
          data-name="Path 8717"
          d="M22.49-24a2.5,2.5,0,0,0-2.5,2.5,2.476,2.476,0,0,0,.131.8.252.252,0,0,1-.061.258l-2.27,2.249a1,1,0,0,0-.262.966,1,1,0,0,0,.7.71,1,1,0,0,0,.967-.256l2.3-2.279a.251.251,0,0,1,.251-.061,2.5,2.5,0,0,0,2.616-.731,2.5,2.5,0,0,0,.406-2.685A2.5,2.5,0,0,0,22.49-24Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-2">
        <path
          id="Path_8719"
          data-name="Path 8719"
          d="M22.5-5a2.5,2.5,0,0,0-.8.131.248.248,0,0,1-.258-.061l-1.784-1.8a1,1,0,0,0-.972-.275,1,1,0,0,0-.716.712,1,1,0,0,0,.27.973L20.049-3.5a.251.251,0,0,1,.062.251A2.474,2.474,0,0,0,20-2.5,2.5,2.5,0,0,0,22.5,0,2.5,2.5,0,0,0,25-2.5,2.5,2.5,0,0,0,22.5-5Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-3">
        <path
          id="Path_8721"
          data-name="Path 8721"
          d="M8.211-18.193l-2.27-2.252A.252.252,0,0,1,5.88-20.7a2.476,2.476,0,0,0,.131-.8A2.5,2.5,0,0,0,4.29-23.875a2.5,2.5,0,0,0-2.793.894,2.5,2.5,0,0,0-.022,2.933,2.5,2.5,0,0,0,2.78.935.25.25,0,0,1,.251.061L6.8-16.773a1,1,0,0,0,.973.268,1,1,0,0,0,.711-.717,1,1,0,0,0-.276-.971Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-4">
        <path
          id="Path_8723"
          data-name="Path 8723"
          d="M3.5,0A2.5,2.5,0,0,0,5.268-.732,2.5,2.5,0,0,0,6-2.5a2.462,2.462,0,0,0-.114-.743.25.25,0,0,1,.062-.251l1.81-1.821a1,1,0,0,0,0-1.414,1,1,0,0,0-1.414,0l-1.786,1.8a.246.246,0,0,1-.257.061,2.5,2.5,0,0,0-2.653.676,2.5,2.5,0,0,0-.443,2.7A2.5,2.5,0,0,0,3.5,0Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-5">
        <path
          id="Path_8725"
          data-name="Path 8725"
          d="M22.49-15a2.5,2.5,0,0,0-2.226,1.364.251.251,0,0,1-.222.136H18.99a1,1,0,0,0-1,1,1,1,0,0,0,1,1h1.052a.25.25,0,0,1,.222.137,2.5,2.5,0,0,0,3,1.24,2.5,2.5,0,0,0,1.695-2.769A2.5,2.5,0,0,0,22.49-15Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-6">
        <path
          id="Path_8727"
          data-name="Path 8727"
          d="M5.959-13.5a.251.251,0,0,1-.222-.136A2.5,2.5,0,0,0,2.926-14.93,2.5,2.5,0,0,0,1.01-12.5a2.5,2.5,0,0,0,1.916,2.431,2.5,2.5,0,0,0,2.811-1.294.25.25,0,0,1,.222-.137H7.011a1,1,0,0,0,1-1,1,1,0,0,0-1-1Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-7">
        <path
          id="Path_8729"
          data-name="Path 8729"
          d="M10.143-16.88A2.857,2.857,0,0,0,13-14.023a2.857,2.857,0,0,0,2.857-2.857A2.857,2.857,0,0,0,13-19.737,2.857,2.857,0,0,0,10.143-16.88Z"
          fill="#66abc1"
        />
      </clipPath>
      <clipPath id="referrer-clip-path-8">
        <path
          id="Path_8731"
          data-name="Path 8731"
          d="M9.166-7.737h7.668a1.143,1.143,0,0,0,.919-.458,1.143,1.143,0,0,0,.181-1.011A5.145,5.145,0,0,0,13-12.879a5.145,5.145,0,0,0-4.93,3.673A1.144,1.144,0,0,0,8.255-8.2a1.144,1.144,0,0,0,.919.458Z"
          fill="#66abc1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_10898"
      data-name="Group 10898"
      transform="translate(-0.987 24)"
    >
      <g
        id="Group_10890"
        data-name="Group 10890"
        clipPath="url(#referrer-clip-path)"
      >
        <path
          id="Path_8716"
          data-name="Path 8716"
          d="M12.494-29h17.5v17.518h-17.5Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10891"
        data-name="Group 10891"
        clipPath="url(#referrer-clip-path-2)"
      >
        <path
          id="Path_8718"
          data-name="Path 8718"
          d="M12.937-12.037H30V5H12.937Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10892"
        data-name="Group 10892"
        clipPath="url(#referrer-clip-path-3)"
      >
        <path
          id="Path_8720"
          data-name="Path 8720"
          d="M-3.989-29h17.5v17.529h-17.5Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10893"
        data-name="Group 10893"
        clipPath="url(#referrer-clip-path-4)"
      >
        <path
          id="Path_8722"
          data-name="Path 8722"
          d="M-4.013-12.021H13.049V5H-4.013Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10894"
        data-name="Group 10894"
        clipPath="url(#referrer-clip-path-5)"
      >
        <path
          id="Path_8724"
          data-name="Path 8724"
          d="M12.99-20h17V-5h-17Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10895"
        data-name="Group 10895"
        clipPath="url(#referrer-clip-path-6)"
      >
        <path
          id="Path_8726"
          data-name="Path 8726"
          d="M-3.99-20h17V-5h-17Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10896"
        data-name="Group 10896"
        clipPath="url(#referrer-clip-path-7)"
      >
        <path
          id="Path_8728"
          data-name="Path 8728"
          d="M5.143-24.737H20.857V-9.023H5.143Z"
          fill="#66abc1"
        />
      </g>
      <g
        id="Group_10897"
        data-name="Group 10897"
        clipPath="url(#referrer-clip-path-8)"
      >
        <path
          id="Path_8730"
          data-name="Path 8730"
          d="M3.027-17.879H22.981V-2.737H3.027Z"
          fill="#66abc1"
        />
      </g>
    </g>
  </svg>
);

export default ReferrerIcon;
