import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '../models';
import { EditCorporateOfferPayload } from '../offersServices/CorporateOffersService';

const useEditCorporateOffer = (
  setTitle: (title: string) => void,
  mutationFn: ({
    corporateOfferId,
    data,
  }: EditCorporateOfferPayload) => Promise<unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      setTitle('Corporate offer updated!');
      queryClient.invalidateQueries(QueryKey.GetCorporateOffers);
    },
    onError: (error: Error) => {
      setTitle('Something went wrong :(');
      console.error(error.message);
    },
  });
};

export default useEditCorporateOffer;
