import React from 'react';

const QRIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="qr-clip-path">
        <path
          id="Path_6956"
          data-name="Path 6956"
          d="M1-16H7a1,1,0,0,0,1-1v-6a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v6A1,1,0,0,0,1-16Zm1-5.75A.25.25,0,0,1,2.25-22h3.5a.25.25,0,0,1,.25.25v3.5a.25.25,0,0,1-.25.25H2.25A.25.25,0,0,1,2-18.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-2">
        <path
          id="Path_6958"
          data-name="Path 6958"
          d="M7-8H1A1,1,0,0,0,0-7v6A1,1,0,0,0,1,0H7A1,1,0,0,0,8-1V-7A1,1,0,0,0,7-8ZM6-2.25A.25.25,0,0,1,5.75-2H2.25A.25.25,0,0,1,2-2.25v-3.5A.25.25,0,0,1,2.25-6h3.5A.25.25,0,0,1,6-5.75Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-3">
        <path
          id="Path_6960"
          data-name="Path 6960"
          d="M23-24H17a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1v-6A1,1,0,0,0,23-24Zm-1,5.75a.25.25,0,0,1-.25.25h-3.5a.25.25,0,0,1-.25-.25v-3.5a.25.25,0,0,1,.25-.25h3.5a.25.25,0,0,1,.25.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-4">
        <path
          id="Path_6962"
          data-name="Path 6962"
          d="M10-19.75h1a.75.75,0,0,0,.75-.75.75.75,0,0,0-.75-.75.25.25,0,0,1-.25-.25V-23a.75.75,0,0,0-.75-.75.75.75,0,0,0-.75.75v2.5A.75.75,0,0,0,10-19.75Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-5">
        <path
          id="Path_6964"
          data-name="Path 6964"
          d="M13-22.25a.25.25,0,0,1,.25.25v5.5a.75.75,0,0,0,.75.75.75.75,0,0,0,.75-.75V-23a.75.75,0,0,0-.75-.75H13a.75.75,0,0,0-.75.75A.75.75,0,0,0,13-22.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-6">
        <path
          id="Path_6966"
          data-name="Path 6966"
          d="M5.75-13.5a.75.75,0,0,0,.75.75H10a.75.75,0,0,0,.75-.75v-4a.75.75,0,0,0-.75-.75.75.75,0,0,0-.75.75v3a.25.25,0,0,1-.25.25H6.5A.75.75,0,0,0,5.75-13.5Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-7">
        <path
          id="Path_6968"
          data-name="Path 6968"
          d="M3-14.25a.75.75,0,0,0-.75.75V-11a.25.25,0,0,1-.25.25H1A.75.75,0,0,0,.25-10,.75.75,0,0,0,1-9.25H14a.75.75,0,0,0,.75-.75v-3a.75.75,0,0,0-.75-.75.75.75,0,0,0-.75.75v2a.25.25,0,0,1-.25.25H4A.25.25,0,0,1,3.75-11v-2.5A.75.75,0,0,0,3-14.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-8">
        <path
          id="Path_6970"
          data-name="Path 6970"
          d="M13.75-7A.75.75,0,0,0,13-7.75H10A.75.75,0,0,0,9.25-7v4a.75.75,0,0,0,.75.75A.75.75,0,0,0,10.75-3V-6A.25.25,0,0,1,11-6.25h2A.75.75,0,0,0,13.75-7Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-9">
        <path
          id="Path_6972"
          data-name="Path 6972"
          d="M23-1.75H14.5A.25.25,0,0,1,14.25-2V-4a.75.75,0,0,0-.75-.75.75.75,0,0,0-.75.75v3a.75.75,0,0,0,.75.75H23A.75.75,0,0,0,23.75-1,.75.75,0,0,0,23-1.75Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-10">
        <path
          id="Path_6974"
          data-name="Path 6974"
          d="M16.5-3.75h3a.75.75,0,0,0,.75-.75v-3a.75.75,0,0,0-.75-.75h-3a.75.75,0,0,0-.75.75v3A.75.75,0,0,0,16.5-3.75Zm.75-2.75a.25.25,0,0,1,.25-.25h1a.25.25,0,0,1,.25.25v1a.25.25,0,0,1-.25.25h-1a.25.25,0,0,1-.25-.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-11">
        <path
          id="Path_6976"
          data-name="Path 6976"
          d="M22.5-11.25a.75.75,0,0,0-.75.75V-4a.75.75,0,0,0,.75.75A.75.75,0,0,0,23.25-4v-6.5A.75.75,0,0,0,22.5-11.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="qr-clip-path-12">
        <path
          id="Path_6978"
          data-name="Path 6978"
          d="M23.25-14a.75.75,0,0,0-.75-.75H17a.75.75,0,0,0-.75.75v3a.75.75,0,0,0,.75.75.75.75,0,0,0,.75-.75v-2a.25.25,0,0,1,.25-.25h4.5A.75.75,0,0,0,23.25-14Z"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="Group_6275" data-name="Group 6275" transform="translate(0 24)">
      <g id="Group_6263" data-name="Group 6263" clipPath="url(#qr-clip-path)">
        <path
          id="Path_6955"
          data-name="Path 6955"
          d="M-5-29H13v18H-5Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6264" data-name="Group 6264" clipPath="url(#qr-clip-path-2)">
        <path
          id="Path_6957"
          data-name="Path 6957"
          d="M-5-13H13V5H-5Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6265" data-name="Group 6265" clipPath="url(#qr-clip-path-3)">
        <path
          id="Path_6959"
          data-name="Path 6959"
          d="M11-29H29v18H11Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6266" data-name="Group 6266" clipPath="url(#qr-clip-path-4)">
        <path
          id="Path_6961"
          data-name="Path 6961"
          d="M4.25-28.75h12.5v14H4.25Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6267" data-name="Group 6267" clipPath="url(#qr-clip-path-5)">
        <path
          id="Path_6963"
          data-name="Path 6963"
          d="M7.25-28.75h12.5v18H7.25Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6268" data-name="Group 6268" clipPath="url(#qr-clip-path-6)">
        <path
          id="Path_6965"
          data-name="Path 6965"
          d="M.75-23.25h15v15.5H.75Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6269" data-name="Group 6269" clipPath="url(#qr-clip-path-7)">
        <path
          id="Path_6967"
          data-name="Path 6967"
          d="M-4.75-19.25h24.5v15H-4.75Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6270" data-name="Group 6270" clipPath="url(#qr-clip-path-8)">
        <path
          id="Path_6969"
          data-name="Path 6969"
          d="M4.25-12.75h14.5V2.75H4.25Z"
          fill="#fff"
        />
      </g>
      <g id="Group_6271" data-name="Group 6271" clipPath="url(#qr-clip-path-9)">
        <path
          id="Path_6971"
          data-name="Path 6971"
          d="M7.75-9.75h21V4.75h-21Z"
          fill="#fff"
        />
      </g>
      <g
        id="Group_6272"
        data-name="Group 6272"
        clipPath="url(#qr-clip-path-10)"
      >
        <path
          id="Path_6973"
          data-name="Path 6973"
          d="M10.75-13.25h14.5V1.25H10.75Z"
          fill="#fff"
        />
      </g>
      <g
        id="Group_6273"
        data-name="Group 6273"
        clipPath="url(#qr-clip-path-11)"
      >
        <path
          id="Path_6975"
          data-name="Path 6975"
          d="M16.75-16.25h11.5v18H16.75Z"
          fill="#fff"
        />
      </g>
      <g
        id="Group_6274"
        data-name="Group 6274"
        clipPath="url(#qr-clip-path-12)"
      >
        <path
          id="Path_6977"
          data-name="Path 6977"
          d="M11.25-19.75h17v14.5h-17Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default QRIcon;
