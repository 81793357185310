import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar, { NavbarItem } from 'components/actionModal/Navbar';
import { PreviewOffer } from 'components/actionModal/stages/offerPage/PreviewBlock';
import PromotionOfferPage from 'components/actionModal/stages/offerPage/PromotionOfferPage';
import Result from 'components/actionModal/stages/Result';
import PromotionOfferSettings from 'components/actionModal/stages/settings/PromotionOfferSettings';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
} from 'components/detailsModal/models';
import convertPromotionTerms from 'helpers/convertPromotionTerms';
import { PromotionOfferContext } from 'modules/offers/context/PromotionOfferContext';
import useGetOfferTerms, {
  OfferTerms,
} from 'modules/offers/hooks/useGetOfferTerms';
import useSaveDataForEditPromotionOffer from 'modules/offers/hooks/useSaveDataForEditPromotionOffer';
import useSaveImage from 'modules/offers/hooks/useSaveImage';
import {
  editPromotionOffer,
  getPromotionOfferTerms,
  publishPromotionOffer,
  uploadPromotionLogo,
} from 'modules/offers/offersServices/PromotionOffersService';

import {
  OfferCategory,
  OfferModalWindowStages,
  PromotionOfferResponse,
  PromotionSettingsPageAttributes,
  QueryKey,
} from '../../models';

const PROMO_UPDATED = 'PROMO_UPDATED';

const NAVBAR_ITEMS: NavbarItem<OfferModalWindowStages>[] = [
  {
    text: 'Offer page',
    name: OfferModalWindowStages.OfferPage,
  },
  {
    text: 'Settings',
    name: OfferModalWindowStages.Settings,
  },
];

type EditPromotionModalProps = {
  offer: PromotionOfferResponse;
  isEditing?: boolean;
  closeModalWindow: () => void;
};

const EditPromotionModal = ({
  offer,
  closeModalWindow,
}: EditPromotionModalProps) => {
  const queryClient = useQueryClient();

  const {
    offerDraft,
    imageName,
    image,
    promotionTerms,
    setQrCodeUrl,
    saveSettings,
    saveOfferSettings,
    savePromotionTerm,
    saveAllOfferData,
    setImageName,
    setImageFile,
    saveAccount,
    setImage,
  } = useContext(PromotionOfferContext);

  const [saved, setSaved] = useState(false);
  const [isPending, setPending] = useState(false);
  const [result, setResult] = useState('');
  const [activeStage, setActiveStage] = useState<OfferModalWindowStages>(
    OfferModalWindowStages.OfferPage
  );
  const [activatedStages, setActivatedStages] = useState([
    OfferModalWindowStages.OfferPage,
    OfferModalWindowStages.Settings,
  ]);

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(NAVBAR_ITEMS[stage].name),
    []
  );

  const goToSettingsStage = useCallback(() => {
    setActiveStage(OfferModalWindowStages.Settings);
    setActivatedStages([
      OfferModalWindowStages.OfferPage,
      OfferModalWindowStages.Settings,
    ]);
  }, [setActivatedStages]);

  const goToResultStage = useCallback(
    () => setActiveStage(OfferModalWindowStages.Result),
    []
  );

  const uploadLogo = useMutation(uploadPromotionLogo);

  const saveImage = useSaveImage({
    setPending,
    setImageFile,
    setImage,
    uploadLogo: uploadLogo.mutateAsync,
    offerId: offerDraft.promotionAccountId,
  });

  const savePromotionTerms = useCallback(
    (data: OfferTerms) => {
      savePromotionTerm(convertPromotionTerms(data));
    },
    [savePromotionTerm]
  );

  useGetOfferTerms(getPromotionOfferTerms, savePromotionTerms);

  const freezeSettingsPageActivate = useCallback(
    (disable?: boolean) => {
      if (disable) {
        const withoutOfferArray = activatedStages.filter(
          (stage) => stage !== OfferModalWindowStages.Settings
        );

        setActivatedStages(withoutOfferArray);
      } else {
        setActivatedStages([
          OfferModalWindowStages.OfferPage,
          OfferModalWindowStages.Settings,
        ]);
      }
    },
    [activatedStages]
  );

  useSaveDataForEditPromotionOffer(
    offer,
    setSaved,
    saveAllOfferData,
    setImage,
    setImageName,
    saveAccount,
    setQrCodeUrl,
    saved
  );

  const closePromotionModal = useCallback(() => {
    setResult(PROMO_UPDATED);
    queryClient.invalidateQueries(QueryKey.GetPromotionOffers);
    goToResultStage();
  }, [goToResultStage, queryClient]);

  const {
    isLoading: publishPromotionOfferDraftLoading,
    mutate: publishOfferDraft,
  } = useMutation(publishPromotionOffer, {
    onSuccess: () => {
      closePromotionModal();
    },
  });

  const { isLoading: editPromotionOfferDraftLoading, mutate: editOffer } =
    useMutation(editPromotionOffer, {
      onSuccess: () => {
        if (offer.status === StatusBE.Draft) {
          publishOfferDraft({
            id: offerDraft.promotionAccountId,
            data: {
              ...offerDraft.settings.settingsStage,
            },
          });
        } else if (offer.status === StatusBE.Active) {
          closePromotionModal();
        }
      },
    });

  useEffect(() => {
    if (
      result &&
      (publishPromotionOfferDraftLoading ||
        activeStage === OfferModalWindowStages.Result)
    ) {
      setTimeout(() => {
        closeModalWindow();
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [
    activeStage,
    closeModalWindow,
    publishPromotionOfferDraftLoading,
    result,
  ]);

  const sendEditPromotionOfferRequest = useCallback(
    (data: PromotionSettingsPageAttributes) => {
      const { bodyText, title, subtitle } = offerDraft.settings.offerStage;

      editOffer({
        offerId: offerDraft.promotionAccountId,
        data: {
          bodyText,
          title,
          subtitle,
          ...data,
        },
      });
    },
    [editOffer, offerDraft.promotionAccountId, offerDraft.settings.offerStage]
  );

  const isLoadingStarted =
    editPromotionOfferDraftLoading || publishPromotionOfferDraftLoading;

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {isLoadingStarted || activeStage === OfferModalWindowStages.Result ? (
        <Result
          title={result === PROMO_UPDATED ? 'Promotion updated!' : ''}
          disableAutoClosing
          closeModal={closeModalWindow}
        />
      ) : (
        <>
          <Navbar
            title="Update promotion"
            activeStage={activeStage}
            showTitle={false}
            accountCategory={OfferCategory.Promotion}
            navbarItems={NAVBAR_ITEMS}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
          />
          <ActionModalMainContent>
            <PromotionOfferPage
              isEdit
              isShowStage={activeStage === OfferModalWindowStages.OfferPage}
              isDisabledDropdown={offerDraft.status === StatusBE.Active}
              promoArray={promotionTerms}
              title="Offer page"
              settings={offerDraft.settings.offerStage}
              saveSettings={saveOfferSettings}
              sendRequest={goToSettingsStage}
              freezeSettingsPageActivate={freezeSettingsPageActivate}
            />
            <PromotionOfferSettings
              isEdit
              isShowStage={activeStage === OfferModalWindowStages.Settings}
              title="Settings"
              submitButtonTitle="Publish"
              isPending={isPending}
              settings={offerDraft.settings.settingsStage}
              previewOffer={PreviewOffer.Promotion}
              imageName={imageName}
              image={image}
              saveSettings={saveSettings}
              saveImage={saveImage}
              saveImageName={setImageName}
              goToNextStage={sendEditPromotionOfferRequest}
            />
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default EditPromotionModal;
