import config from 'config';
import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import AppstoreIcon from 'components/icons/AppstoreIcon';
import GooglePlayIcon from 'components/icons/GooglePlayIcon';
import { LogoIcon } from 'components/icons/LogoIcon';

type Header = {
  isDesktop?: boolean;
};

const Header = ({ isDesktop }: Header) => {
  return (
    <Container $isDesktop={isDesktop}>
      <ShidoIconWrapper>
        <LogoIcon width={61} height={37} />
        <ShidoTitle>
          <ShidoText>shido</ShidoText>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
      <StoreButtonsWrapper>
        <StoreLink target="_blank" href={config.appStoreLink}>
          <StoreButton>
            <AppstoreIcon color={theme.colors.white} />
            <div>
              <p>DOWNLOAD ON</p>
              <p>App Store</p>
            </div>
          </StoreButton>
        </StoreLink>
        <StoreLink target="_blank" href={config.googlePlayLink}>
          <StoreButton $isGoogleIcon>
            <GooglePlayIcon color={theme.colors.white} />
            <div>
              <p>GET IT ON</p>
              <p>Google Play</p>
            </div>
          </StoreButton>
        </StoreLink>
      </StoreButtonsWrapper>
    </Container>
  );
};

const StoreLink = styled.a`
  text-decoration: none;
  height: 42px;
`;

const StoreButton = styled.div<{ $isGoogleIcon?: boolean }>`
  width: 140px;
  height: 42px;
  display: flex;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  padding-left: 10px;
  padding-top: 9px;
  column-gap: ${({ $isGoogleIcon }) => ($isGoogleIcon ? '8px' : '14px')};
  box-sizing: border-box;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -2px;

    p {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.white};
    }

    p:first-child {
      font-size: 10px;
      margin-bottom: 3px;
    }

    p:last-child {
      font-size: 14px;
    }
  }
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: -14px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 38px;
  font-weight: 700;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 20px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Container = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop: isDesktop }) =>
    isDesktop ? '1360px' : '1210px'};
  width: 100%;
  height: 123px;
  margin: 0 auto;
  padding: 40px 5px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(216 218 224);
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      padding: 42px 0 0 2px;
    `}
`;

const StoreButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-template-columns: auto auto;
  column-gap: 20px;
  position: relative;
  top: -3px;
`;

export default Header;
