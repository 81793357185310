import { find } from 'lodash';

import { PromoTerm } from 'modules/offers/models';

export const promotionTerms = [
  {
    id: '0',
    displayName: 'Default 7 days',
    findBy: 'SevenDays',
    promoDisplay: '7 day',
    shidoMeUrl: '7day',
  },
  {
    id: '1',
    displayName: '1 month free',
    findBy: 'OneMonth',
    promoDisplay: '1 month',
    shidoMeUrl: '1month',
  },
  {
    id: '2',
    displayName: '3 months free',
    findBy: 'ThreeMonths',
    promoDisplay: '3 months',
    shidoMeUrl: '3month',
  },
];

const convertPromotionTerms = (
  termsArray: { id: string; term: string }[]
): PromoTerm[] => {
  return promotionTerms.map((item) => ({
    ...item,
    BEformat: find(termsArray, { term: item.findBy })?.id as string,
  }));
};

export default convertPromotionTerms;
