import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import VerifyCodeFormTemplate from '../VerifyCodeFormTemplate';

enum CodeStatus {
  Pending = 'PENDING',
  Valid = 'VALID',
  Invalid = 'INVALID',
}

type EmployerVerifyCodeFormProps = {
  email: string;
  goToNextStage: () => void;
  openResendPanel: () => void;
  closeResendPanel: () => void;
};

const EmployerVerifyCodeForm = ({
  goToNextStage,
  openResendPanel,
  closeResendPanel,
}: EmployerVerifyCodeFormProps) => {
  const { register, watch, setValue, setFocus } = useForm();
  const [verifyCodeStatus, setVerifyCodeStatus] = useState(CodeStatus.Pending);

  const checkCode = () => {
    goToNextStage();
  };

  return (
    <>
      <VerifyCodeFormTemplate
        isLoading={false}
        openResendPanel={openResendPanel}
        closeResendPanel={closeResendPanel}
        setVerifyCodeStatus={setVerifyCodeStatus}
        verifyCodeStatus={verifyCodeStatus}
        isLoaderShown={false}
        checkCode={checkCode}
        setValue={setValue}
        setFocus={setFocus}
        watch={watch}
        register={register}
      />
    </>
  );
};

export default EmployerVerifyCodeForm;
