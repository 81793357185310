import React, { useCallback, useMemo, useState } from 'react';

import {
  OPENING_MODAL_ANIMATION_DELAY,
  pageSizes,
  TableRowGrid,
} from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import TableRow from 'components/table/tableRow/TableRow';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanAccountLocalStorage } from 'helpers/connectToLocalStorage';

import { CorporateAccount } from '../../accountsServices/CorporateAccountsService';
import CorporateAccountContextProvider from '../../context/CorporateAccountContext';
import { AccountCategory, ACCOUNTS_SORT_BY_FE } from '../../models';

import CorporateDetailsModal from './CorporateDetailsModal';
import EditCorporateModal from './EditCorporateModal';

type CorporateTableProps = {
  data?: CorporateAccount[];
  dataAmount: number;
  pageSize: number;
  page: number;
  sortByListItemIndex: number;
  sortAccounts: (index: number) => void;
  setPage: (index: number) => void;
  setPageSize: (pageSize: number) => void;
};

const CorporateTable = ({
  data,
  pageSize,
  sortAccounts,
  setPage,
  page,
  setPageSize,
  dataAmount,
  sortByListItemIndex,
}: CorporateTableProps) => {
  const [dataForModal, setDataForModal] = useState<CorporateAccount>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [accountForEditing, setAccountForEditing] =
    useState<CorporateAccount>();

  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, pageSize),
    [dataAmount, pageSize]
  );

  const closeModalWindow = useCallback(() => setIsModalOpen(false), []);

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen(!isEditingModalOpen);
    cleanAccountLocalStorage();
  }, [isEditingModalOpen]);

  const openModalWindow = useCallback(
    (data: CorporateAccount) =>
      setTimeout(() => {
        setIsModalOpen(true);
        setDataForModal(data);
      }, OPENING_MODAL_ANIMATION_DELAY),
    []
  );

  return (
    <>
      <TableInfo
        pageSizes={pageSizes}
        dataAmount={dataAmount}
        activePageSize={pageSize}
        changePageSize={setPageSize}
      />
      <TableContainer>
        <TableHeader
          parametersForSorting={ACCOUNTS_SORT_BY_FE}
          sortByListItemIndex={sortByListItemIndex}
          sortData={sortAccounts}
          tableName={TableRowGrid.Account}
        />
        {data && (
          <div>
            {data.map((item) => (
              <TableRow
                key={item.id}
                tableName={TableRowGrid.Account}
                data={[
                  item.companyName,
                  AccountCategory.Corporate,
                  item.status,
                ]}
                openModalWindow={() => openModalWindow(item)}
              />
            ))}
          </div>
        )}
        {isModalOpen && dataForModal && (
          <CorporateDetailsModal
            account={dataForModal}
            setDataForEditing={setAccountForEditing}
            closeWindow={closeModalWindow}
            openEditingModalWindow={() => {
              toggleEditingModalWindow();
              closeModalWindow();
            }}
          />
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && accountForEditing && (
        <CorporateAccountContextProvider>
          <EditCorporateModal
            accountForEditing={accountForEditing}
            closeModalWindow={toggleEditingModalWindow}
          />
        </CorporateAccountContextProvider>
      )}
    </>
  );
};

export default CorporateTable;
