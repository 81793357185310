import { format } from 'date-fns';

import { CorporateAccount } from 'modules/accounts/accountsServices/CorporateAccountsService';

export type AccountsDashboardGroup = {
  name: string;
  accounts: CorporateAccount[];
};

export enum AccountsGroupBy {
  'BusinessSector' = 'BUSINESS_SECTOR',
  'Countries' = 'COUNTRIES',
  'CreatedDateMonth' = 'CREATED_DATE_MONTH',
  'CreatedDateYear' = 'CREATED_DATE_YEAR',
  'CreatedDateAllTime' = 'CREATED_DATE_ALL_TIME',
}

export const groupAccounts = (
  groupBy: AccountsGroupBy,
  accounts: CorporateAccount[]
) =>
  accounts.reduce<AccountsDashboardGroup[]>((groups, account) => {
    let groupName: string;

    switch (groupBy) {
      case AccountsGroupBy.CreatedDateMonth:
        groupName = format(new Date(account.createdDate), 'EEEE do');
        break;
      case AccountsGroupBy.CreatedDateYear:
        groupName = format(new Date(account.createdDate), 'MMMM');
        break;
      case AccountsGroupBy.CreatedDateAllTime:
        groupName = format(new Date(account.createdDate), 'yyyy');
        break;
      case AccountsGroupBy.Countries:
        groupName = account.country;
        break;
      case AccountsGroupBy.BusinessSector:
      default:
        groupName = account.businessSector.replace(/([A-Z])/g, ' $1');
        break;
    }

    const accountGroup = groups.find((fGroup) => fGroup.name === groupName);

    if (!accountGroup) {
      return [...groups, { name: groupName, accounts: [account] }];
    }

    accountGroup.accounts.push(account);

    return groups;
  }, []);
