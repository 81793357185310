import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ShidoIcon from 'components/icons/ShidoIcon';
import Loader from 'components/Loader';

import { RequestStatus } from './models';

type SuccessStageProps = {
  title?: string;
  name?: string;
  text: string;
  requestStatus?: RequestStatus;
};

const SuccessStage = ({
  name,
  text,
  title,
  requestStatus,
}: SuccessStageProps) => (
  <>
    <Title $isHidden={!requestStatus}>
      {requestStatus === RequestStatus.Success ? `${name} ${title}!` : title}
    </Title>
    <Description $isVisible={!!requestStatus}>
      {requestStatus === RequestStatus.Success && text}
    </Description>
    <LoaderContainer data-testid="loader">
      <ShidoIcon color={theme.colors.white} />
      <Loader
        circles={[
          {
            color: 'rgba(255, 255, 255, 0.3)',
            speed: 2,
            size: 183,
          },
          {
            color: 'rgba(255, 255, 255, 0.3)',
            speed: 2.3,
            size: 140,
          },
        ]}
      />
    </LoaderContainer>
  </>
);

const Title = styled.h4<{ $isHidden?: boolean }>`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  text-align: center;
  margin-top: 92px;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  transition-duration: 0.4s;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (height <= 770px) {
    margin-top: 20px;
  }
`;

const Description = styled.p<{ $isVisible?: boolean }>`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition-duration: 0.4s;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SuccessStage;
