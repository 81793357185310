import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import usePrevious from 'hooks/usePrevious';
import { CorporateOfferContext } from 'modules/offers/context/CorporateOfferContext';
import { ImageVariant } from 'modules/offers/models';

import Error from '../../Error';
import UploadOfferPageField from '../../forms/uploadOfferPageField/UploadOfferPageField';
import {
  ModalStage,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';

import PreviewBlock, { PreviewOffer } from './PreviewBlock';

const logoExtensions = ['svg', 'png'];
const logoFileExtensionsForDrop = ['image/svg+xml', 'image/png'];
const backgroundExtensions = ['png', 'jpeg', 'jpg'];
const backgroundExtensionsForDrop = ['image/png', 'image/jpeg', 'image/jpg'];
const fileDropText = 'Drag & drop the logo file here, or';
const maxFileSizeText = 'Max file size 5MB';

type OfferPageProps = {
  isShowStage: boolean;
  isEdit?: boolean;
  isUpdatedImage?: boolean;
  isPending?: boolean;
  title: string;
  logoName: string;
  id?: string;
  logoImage: string;
  backgroundName: string;
  backgroundImage: string;
  previewOffer: PreviewOffer;
  submitButtonTitle: string;
  freezeOfferPageActivate?: () => void;
  setUserId?: (value: string) => void;
  goToNextStage: () => void;
  goToPreviousStage?: () => void;
  saveImageName: (name: string, imageVariant: ImageVariant) => void;
  saveImage: (
    urlImg: string,
    imageVariant: ImageVariant,
    openError: (value: boolean) => void,
    setErrorText: (value: string) => void,
    textWithPercentages?: HTMLParagraphElement | null,
    file?: File
  ) => void;
};

const OfferPage = ({
  isShowStage,
  isUpdatedImage = true,
  isPending,
  title,
  logoImage,
  logoName,
  id,
  previewOffer,
  submitButtonTitle,
  backgroundName,
  backgroundImage,
  setUserId,
  saveImage,
  goToNextStage,
  goToPreviousStage,
  saveImageName,
  freezeOfferPageActivate,
}: OfferPageProps) => {
  const { logoFile, backgroundFile } = useContext(CorporateOfferContext);

  const [logoError, setLogoError] = useState(false);
  const [backgroundError, setBackgroundError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  const prevOfferId = usePrevious(id);

  const formSubmit = () => {
    goToNextStage();
  };

  useEffect(() => {
    const reloadPictures = async () => {
      if (id && prevOfferId !== id && isShowStage) {
        if (logoFile) {
          await saveImage(
            logoImage,
            ImageVariant.Logo,
            setLogoError,
            setErrorText,
            null,
            logoFile
          );
        }

        if (backgroundFile) {
          await saveImage(
            backgroundImage,
            ImageVariant.Background,
            setBackgroundError,
            setErrorText,
            null,
            backgroundFile
          );
        }
      }
    };

    reloadPictures();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowStage, id]);

  useEffect(() => {
    if (freezeOfferPageActivate && id && setUserId && !isShowStage) {
      freezeOfferPageActivate();
      setUserId('');
    }
  }, [freezeOfferPageActivate, id, isShowStage, setUserId]);

  const setFileLogoHandler = useCallback(
    (
      url: string,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      saveImage(
        url,
        ImageVariant.Logo,
        setLogoError,
        setErrorText,
        textWithPercentages,
        file
      );
    },
    [saveImage]
  );

  const setFileBackgroundHandler = useCallback(
    (
      url: string,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      saveImage(
        url,
        ImageVariant.Background,
        setLogoError,
        setErrorText,
        textWithPercentages,
        file
      );
    },
    [saveImage]
  );

  const setLogoNameHandler = useCallback(
    (name: string) => saveImageName(name, ImageVariant.Logo),
    [saveImageName]
  );
  const setBackgroundNameHandler = useCallback(
    (name: string) => saveImageName(name, ImageVariant.Background),
    [saveImageName]
  );

  return (
    <ModalStage $isShowStage={isShowStage} ref={containerRef}>
      <Title>{title}</Title>
      <UploadOfferPageField
        pictureDescription={maxFileSizeText}
        fileDropZoneText={fileDropText}
        fileName={logoName}
        file={logoImage}
        fileError={logoError}
        validExtensions={logoExtensions}
        validExtensionsForDrop={logoFileExtensionsForDrop}
        setFileError={setLogoError}
        uploadSuggestionText="Upload logo"
        uploadSuggestionFormat="(.SVG .PNG)"
        setErrorText={setErrorText}
        setFileName={setLogoNameHandler}
        setFile={setFileLogoHandler}
      />
      <UploadOfferPageField
        pictureDescription="Max file size 5MB"
        fileDropZoneText={fileDropText}
        fileName={backgroundName}
        file={backgroundImage}
        fileError={backgroundError}
        validExtensions={backgroundExtensions}
        validExtensionsForDrop={backgroundExtensionsForDrop}
        setFileError={setBackgroundError}
        uploadSuggestionText="Upload background"
        uploadSuggestionFormat="(.PNG .JPG)"
        setErrorText={setErrorText}
        setFileName={setBackgroundNameHandler}
        setFile={setFileBackgroundHandler}
      />
      <PreviewBlock
        isShowStage={isShowStage}
        previewOffer={previewOffer}
        logo={logoImage}
        background={backgroundImage}
      />
      <SubmitButtonContainer>
        {goToPreviousStage && (
          <DefaultButton
            text="back"
            textColor={theme.colors.white}
            isActive
            withShadow
            buttonColor={theme.colors.funtainBlue}
            isUppercase
            buttonSize="md"
            borderSize="md"
            clickHandler={goToPreviousStage}
          />
        )}
        <DefaultButton
          text={submitButtonTitle}
          textColor={theme.colors.white}
          isActive={!isPending && isUpdatedImage}
          isSubmit
          withShadow
          buttonColor={
            !isPending && isUpdatedImage
              ? theme.colors.swamp
              : theme.colors.quillGray
          }
          isUppercase
          buttonSize="md"
          borderSize="md"
          clickHandler={formSubmit}
        />
      </SubmitButtonContainer>
      <Error isOpen={logoError || backgroundError} errorText={errorText} />
    </ModalStage>
  );
};

export default React.memo(OfferPage);
