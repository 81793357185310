import {
  getAffiliateReferralsReport,
  getAffiliateSubscribersReport,
  getAffiliateUnconvertedReferralsReport,
  getAffiliateUnsubscribedReport,
} from '../offersServices/ConversionDataService';

export enum AffiliateReport {
  REFERRALS = 'Referrals',
  UNCONVERTED_REFERRALS = 'UnconvertedReferrals',
  SUBSCRIBERS = 'Subscribers',
  CURRENT_MONTH_SUBSCRIBERS = 'CurrentMonthSubscribers',
  UNSUBSCRIBED = 'UnsubscribedSubscribers',
}

export const getAffiliateReport =
  (reportType: AffiliateReport) => async (affiliateOfferId: string) => {
    let blobFile: Blob | null = null;

    switch (reportType) {
      case AffiliateReport.REFERRALS:
        blobFile = await getAffiliateReferralsReport(affiliateOfferId);
        break;
      case AffiliateReport.UNCONVERTED_REFERRALS:
        blobFile =
          await getAffiliateUnconvertedReferralsReport(affiliateOfferId);
        break;
      case AffiliateReport.SUBSCRIBERS:
        blobFile = await getAffiliateSubscribersReport(affiliateOfferId, false);
        break;
      case AffiliateReport.CURRENT_MONTH_SUBSCRIBERS:
        blobFile = await getAffiliateSubscribersReport(affiliateOfferId, true);
        break;
      case AffiliateReport.UNSUBSCRIBED:
        blobFile = await getAffiliateUnsubscribedReport(affiliateOfferId);
        break;
    }

    if (!blobFile) return;

    const url = window.URL.createObjectURL(blobFile);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${reportType}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
