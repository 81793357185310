import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { checkValidMobileCode } from 'modules/auth/AuthService';
import useAuth from 'modules/auth/hooks/useAuth';
import { CustomTrackEvent } from 'modules/auth/models';

import VerifyCodeFormTemplate from '../VerifyCodeFormTemplate';

enum CodeStatus {
  Pending = 'PENDING',
  Valid = 'VALID',
  Invalid = 'INVALID',
}

const SUCCESS_TIMER = 2000;

type AdminVerifyCodeFormProps = {
  email: string;
  goToNextStage: () => void;
  openResendPanel: () => void;
  closeResendPanel: () => void;
};

const AdminVerifyCodeForm = ({
  email,
  goToNextStage,
  openResendPanel,
  closeResendPanel,
}: AdminVerifyCodeFormProps) => {
  const { setTokens } = useAuth();
  const { register, watch, setValue, setFocus, reset } = useForm();
  const [verifyCodeStatus, setVerifyCodeStatus] = useState(CodeStatus.Pending);
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackInvalidMobileCode = useTrackEvent(
    appInsights,
    CustomTrackEvent.InvalidMobileSignInCode,
    {}
  );

  const { mutate, isLoading } = useMutation(checkValidMobileCode, {
    onSuccess: ({ token, refreshToken }) => {
      setTokens(token, refreshToken);
      setVerifyCodeStatus(CodeStatus.Valid);
      setTimeout(goToNextStage, SUCCESS_TIMER);
    },
    onError: (err, { email, mobileSignInCode }) => {
      setVerifyCodeStatus(CodeStatus.Invalid);
      trackInvalidMobileCode({
        email,
        mobileSignInCode,
      });

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      reset();
    },
    onSettled: () => setIsLoaderShown(false),
  });

  const checkCode = useCallback(
    (mobileSignInCode: string) => {
      mutate({ email, mobileSignInCode });
      setIsLoaderShown(true);
    },
    [email, mutate]
  );

  return (
    <VerifyCodeFormTemplate
      isLoading={isLoading}
      openResendPanel={openResendPanel}
      closeResendPanel={closeResendPanel}
      setVerifyCodeStatus={setVerifyCodeStatus}
      verifyCodeStatus={verifyCodeStatus}
      isLoaderShown={isLoaderShown}
      checkCode={checkCode}
      setValue={setValue}
      setFocus={setFocus}
      watch={watch}
      register={register}
    />
  );
};

export default AdminVerifyCodeForm;
