import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from 'components/actionModal/forms/inputs/StandardInput';
import DefaultButton from 'components/buttons/DefaultButton';
import { CustomDateBE } from 'components/calendar/models';
import {
  departmentListVariant,
  listLabels,
  offerTerms,
} from 'components/listForSelectingData/dataForLists';
import Loader from 'components/Loader';
import getExactTime from 'helpers/getExactTime';
import {
  CorporateOffer,
  CorporateSettings,
} from 'modules/offers/offersServices/CorporateOffersService';

import Error from '../../Error';
import StandardDropdown from '../../forms/dropdowns/StandardDropdown';
import ValidPeriodDropdown from '../../forms/dropdowns/ValidPeriodDropdown';
import OfferCodeInput from '../../forms/inputs/OfferCodeInput';
import RedemptionLimitInput from '../../forms/inputs/RedemptionLimitInput';
import UnitPriceInput from '../../forms/inputs/UnitPriceInput';
import { InputId } from '../../models';
import { corporateOfferSettingsValidationSchema } from '../../models/validationSchemas';
import {
  FormContainer,
  ModalStage,
  MultipleInputsContainer,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';

const OFFER_CODE_NAME_MIN_LENGTH = 6;
const FIELDS = ['1', '2', '3', '4', '5', '6', '7', '8'];

type CorporateOfferSettingsProps = {
  isShowStage: boolean;
  isEdit?: boolean;
  offer?: CorporateOffer;
  title: string;
  pendingStatus: boolean;
  isOfferCodeError: boolean;
  settings: CorporateSettings;
  goToNextStage: () => void;
  setisEndDateChanged?: (value: boolean) => void;
  saveSettings: (settings: CorporateSettings) => void;
  goToPreviousStage?: () => void;
  toggleNextStage?: (value?: boolean) => void;
};

const CorporateOfferSettings = ({
  isShowStage,
  isEdit,
  offer,
  title,
  settings,
  setisEndDateChanged,
  saveSettings,
  goToNextStage,
  pendingStatus,
  isOfferCodeError,
  goToPreviousStage,
  toggleNextStage,
}: CorporateOfferSettingsProps) => {
  const {
    watch,
    register,
    setValue,
    setFocus,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(
      corporateOfferSettingsValidationSchema(offer?.redemptionLimit)
    ),
  });
  const [currentOfferTerms, setCurrentOfferTerms] = useState(offerTerms);
  const [isOfferTermValid, setIsOfferTermValid] = useState(false);
  const [isOfferCodeValid, setIsOfferCodeValid] = useState(true);
  const [isValidPeriodValid, setIsValidPeriodValid] = useState(false);
  const [isCodeLengthValid, setIsCodeLengthValid] = useState(false);
  const [isOfferCodeUnique, setIsOfferCodeUnique] = useState(true);
  const [isDepartmentValid, setIsDepartmentValid] = useState(false);
  const isNotValidateAdditionalInputs = isEdit || isDepartmentValid;

  const isValidUntillEndDate =
    settings.endDateUTC === null ||
    new Date(settings.endDateUTC).getTime() > new Date().getTime();

  const isFormValid =
    isValid &&
    isOfferTermValid &&
    isOfferCodeValid &&
    isValidPeriodValid &&
    isCodeLengthValid &&
    !pendingStatus &&
    isOfferCodeUnique &&
    isValidUntillEndDate &&
    isNotValidateAdditionalInputs;

  const saveUnitPrice = useCallback(
    () =>
      saveSettings({
        ...settings,
        unitPrice: Number(watch()[InputId.UnitPriceInputId]),
      }),
    [saveSettings, settings, watch]
  );

  const saveCurrency = useCallback(
    (currency: string) => {
      saveSettings({
        ...settings,
        unitPriceCurrency: currency,
      });
    },
    [saveSettings, settings]
  );

  const saveRedemptionLimit = useCallback(() => {
    const redemptionLimit = Number(watch()[InputId.RedemptionLimitInputId]);

    saveSettings({
      ...settings,
      redemptionLimit,
    });
  }, [saveSettings, settings, watch]);

  const saveOfferTerm = useCallback(
    (offerTerm: string) =>
      saveSettings({
        ...settings,
        offerTerm,
      }),
    [saveSettings, settings]
  );

  const checkOfferTermValidation = useCallback(
    (isValid: boolean) => setIsOfferTermValid(isValid),
    []
  );

  const saveOfferCode = useCallback(() => {
    const code = FIELDS.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (field) => (watch() as Record<string, any>)[field]
    ).join('');

    saveSettings({
      ...settings,
      code,
    });

    setIsOfferCodeValid(code.length >= OFFER_CODE_NAME_MIN_LENGTH);
    setIsCodeLengthValid(code.length >= OFFER_CODE_NAME_MIN_LENGTH);
  }, [saveSettings, settings, watch]);

  const saveStartDate = useCallback(
    (date: CustomDateBE) => {
      const { year, day, month } = date;

      saveSettings({
        ...settings,
        startDateUTC: new Date(year, month, day, ...getExactTime()),
      });
    },
    [saveSettings, settings]
  );

  const saveEndDate = useCallback(
    (date: CustomDateBE) => {
      const { year, day, month } = date;

      setisEndDateChanged && setisEndDateChanged(true);
      saveSettings({
        ...settings,
        endDateUTC: new Date(year, month, day, ...getExactTime()),
      });
    },
    [saveSettings, setisEndDateChanged, settings]
  );

  const saveIsShowDepartment = useCallback(
    (isShowDepartment: string) => {
      saveSettings({
        ...settings,
        includeDepartment: isShowDepartment,
      });
    },
    [saveSettings, settings]
  );

  const saveNote = useCallback(
    () =>
      saveSettings({
        ...settings,
        notes: watch()[InputId.NoteInputId],
      }),
    [saveSettings, settings, watch]
  );

  const checkValidPeriodValidation = useCallback(
    (isValid: boolean) => setIsValidPeriodValid(isValid),
    []
  );

  const checkDepartmentValidation = useCallback(
    (isValid: boolean) => setIsDepartmentValid(isValid),
    []
  );

  const formSubmit = () => {
    goToNextStage();
  };

  useEffect(() => {
    if (isEdit) {
      const termId = offerTerms.find((currOffer) => {
        return currOffer.BEformat === offer?.offerTerm;
      })?.id;

      const changeDisabledTerms = offerTerms.map((currentTerm) => {
        if (Number(currentTerm.id) < Number(termId)) {
          return {
            ...currentTerm,
            disabled: true,
          };
        }

        return currentTerm;
      });

      setCurrentOfferTerms(changeDisabledTerms);
    }
  }, [offer, isEdit]);

  useEffect(() => {
    if (isOfferCodeError) {
      setIsOfferCodeUnique(false);
    }
  }, [isOfferCodeError]);

  useEffect(() => {
    if (isFormValid && toggleNextStage) {
      toggleNextStage();
    } else if (!isFormValid && toggleNextStage) {
      toggleNextStage(true);
    }
  }, [isFormValid, toggleNextStage]);

  return (
    <ModalStage $isShowStage={isShowStage}>
      <Title>{title}</Title>
      <FormContainer onSubmit={handleSubmit(formSubmit)}>
        <MultipleInputsContainer>
          <OfferCodeInput
            disabled={isEdit}
            fields={FIELDS}
            watch={watch}
            isOfferCodeUnique={isOfferCodeUnique}
            error={!isOfferCodeValid}
            register={register}
            setValue={setValue}
            setFocus={setFocus}
            inputValue={settings.code}
            saveData={() => {
              saveOfferCode();
              setIsOfferCodeUnique(true);
            }}
          />
        </MultipleInputsContainer>
        <MultipleInputsContainer>
          <UnitPriceInput
            saveCurrency={saveCurrency}
            saveUnitPrice={saveUnitPrice}
            inputValue={String(settings.unitPrice || '')}
            currency={settings.unitPriceCurrency}
            watch={watch}
            register={register}
            setValue={setValue}
            errors={errors}
            inputId={InputId.UnitPriceInputId}
          />
          <RedemptionLimitInput
            watch={watch}
            saveData={saveRedemptionLimit}
            inputValue={String(settings.redemptionLimit || '')}
            register={register}
            setValue={setValue}
            errors={errors}
            inputId={InputId.RedemptionLimitInputId}
          />
        </MultipleInputsContainer>
        <MultipleInputsContainer>
          <ValidPeriodDropdown
            isStartDateDisabled={isEdit}
            offerDateEnd={offer?.endDateUTC}
            startDateUTC={settings.startDateUTC}
            endDateUTC={settings.endDateUTC}
            checkValidation={checkValidPeriodValidation}
            saveStartDate={saveStartDate}
            saveEndDate={saveEndDate}
          />
          <StandardDropdown
            inputLabel="Offer term"
            listLabel={listLabels.offerTerm}
            list={currentOfferTerms}
            saveData={saveOfferTerm}
            inputValue={settings.offerTerm}
            checkValidation={checkOfferTermValidation}
          />
        </MultipleInputsContainer>

        <MultipleInputsContainer>
          {!isEdit && (
            <DepartmentWrapper>
              <StandardDropdown
                inputLabel="Use department selection"
                listLabel="Use department selection"
                list={departmentListVariant}
                saveData={saveIsShowDepartment}
                checkValidation={checkDepartmentValidation}
                inputValue={settings.includeDepartment}
              />
            </DepartmentWrapper>
          )}

          <StandardInput
            isRequired={false}
            errors={errors}
            register={register}
            setValue={setValue}
            label="Add note"
            inputId={InputId.NoteInputId}
            inputValue={settings.notes}
            saveData={saveNote}
          />
        </MultipleInputsContainer>

        <SubmitButtonContainer>
          {goToPreviousStage && (
            <DefaultButton
              text="back"
              textColor={theme.colors.white}
              isActive={!pendingStatus}
              withShadow
              buttonColor={
                !pendingStatus
                  ? theme.colors.funtainBlue
                  : theme.colors.quillGray
              }
              isUppercase
              buttonSize="md"
              borderSize="md"
              clickHandler={goToPreviousStage}
            />
          )}
          <DefaultButton
            text="next"
            textColor={theme.colors.white}
            isActive={isFormValid}
            isSubmit
            withShadow
            buttonColor={
              isFormValid ? theme.colors.funtainBlue : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
          {pendingStatus && (
            <LoaderContainer>
              <Loader
                circles={[
                  {
                    color: theme.colors.primary,
                    speed: 2,
                    size: 30,
                  },
                ]}
              />
            </LoaderContainer>
          )}
        </SubmitButtonContainer>
      </FormContainer>
      <Error isOpen={isOfferCodeError} errorText="Offer code is not unique." />
    </ModalStage>
  );
};

const DepartmentWrapper = styled.div`
  .list-wrapper {
    height: 125px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export default CorporateOfferSettings;
