import React from 'react';

type DesktopIconProps = {
  color: string;
};

const DesktopIcon = ({ color }: DesktopIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="desktop-clip-path">
        <path
          id="Path_6912"
          data-name="Path 6912"
          d="M22.5-24H1.5A1.5,1.5,0,0,0,0-22.5v16A1.5,1.5,0,0,0,1.5-5h21A1.5,1.5,0,0,0,24-6.5v-16A1.5,1.5,0,0,0,22.5-24ZM2-9.5v-12a.5.5,0,0,1,.5-.5h19a.5.5,0,0,1,.5.5v12a.5.5,0,0,1-.5.5H2.5A.5.5,0,0,1,2-9.5Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="desktop-clip-path-2">
        <path
          id="Path_6914"
          data-name="Path 6914"
          d="M16.449-.787l-2.1-2.532a.5.5,0,0,0-.385-.181h-3.93a.5.5,0,0,0-.385.181L7.549-.787a.5.5,0,0,0-.034.518A.5.5,0,0,0,7.96,0h8.079a.5.5,0,0,0,.444-.269A.5.5,0,0,0,16.449-.787Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g id="Group_6234" data-name="Group 6234" transform="translate(0 24)">
      <g
        id="Group_6232"
        data-name="Group 6232"
        clipPath="url(#desktop-clip-path)"
      >
        <path
          id="Path_6911"
          data-name="Path 6911"
          d="M-5-29H29V0H-5Z"
          fill={color}
        />
      </g>
      <g
        id="Group_6233"
        data-name="Group 6233"
        clipPath="url(#desktop-clip-path-2)"
      >
        <path
          id="Path_6913"
          data-name="Path 6913"
          d="M2.458-8.5H21.54V5H2.458Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default DesktopIcon;
