import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import Loader from 'components/Loader';

const CIRCLES = [
  {
    color: theme.colors.funtainBlue,
    speed: 2,
    size: 184,
  },
  {
    color: theme.colors.funtainBlue,
    speed: 2.3,
    size: 140,
  },
];

const DashboardLoader = () => (
  <LoaderWrapper>
    <LoaderContainer>
      <Loader circles={CIRCLES} />
    </LoaderContainer>
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  min-height: 800px;
  background:
    url('/images/insights_background_additional.png') center bottom no-repeat,
    url('/images/insights_background.png') 39% 31% / contain no-repeat
      ${({ theme }) => theme.colors.primary};
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoaderContainer = styled.div`
  margin: 200px auto;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
`;

export default DashboardLoader;
