import { debounce } from 'lodash';
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { ListItem } from 'components/actionModal/styledElements/stagesStyledElements';
import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import Loader from 'components/Loader';
import { ListItemData } from 'helpers/filterDataForDropdownList';
import useOutsideClick from 'hooks/useOutsideClick';
import { LoaderContainer } from 'modules/auth/components/styledElements/formsStyledElements';

import { ListHeight, listsHeight, listsWidth, ListWidth } from '../models';
import SearchingBlock from '../SearchingBlock';
import {
  ActionButtonContainer,
  Container,
  ListWrapper,
} from '../styledElements/listStyledElements';

const DEBOUNCE_WAIT = 700;

type ListForSelectingDataBEProps = {
  label: string;
  isLoading: boolean;
  data?: ListItemData[];
  listWidth: ListWidth;
  searchingValue: string;
  listHeight: ListHeight;
  closeList: () => void;
  setNewActiveListItem: (index: string) => void;
  setSearchingValue: (value: string) => void;
};

// this component works with BE data
// FE and BE lists have some differences in logic
const ListForSelectingDataBE = ({
  label,
  data,
  closeList,
  listWidth,
  listHeight,
  isLoading,
  searchingValue,
  setSearchingValue,
  setNewActiveListItem,
}: ListForSelectingDataBEProps) => {
  const listRef = useRef<HTMLDivElement>(null);

  const setNewSearchingValue = useCallback(
    (value: string) => setSearchingValue(value),
    [setSearchingValue]
  );

  useOutsideClick(listRef, closeList);

  return (
    <Container $width={listsWidth[listWidth]} ref={listRef}>
      <SearchingBlock
        label={label}
        searchingValue={searchingValue}
        setSearchingValue={debounce(setNewSearchingValue, DEBOUNCE_WAIT)}
      />
      <ActionButtonContainer>
        <ActionButton
          icon={<ChevronIcon color={theme.colors.funtainBlue} />}
          clickHandler={closeList}
        />
      </ActionButtonContainer>
      <ListWrapper
        className="list-wrapper"
        data-testid="data-list-wrapper"
        $height={listsHeight[listHeight]}
      >
        {isLoading && (
          <LoaderContainer>
            <Loader
              circles={[
                {
                  color: theme.colors.darkBlue,
                  speed: 2,
                  size: 80,
                },
              ]}
            />
          </LoaderContainer>
        )}
        {data && (
          <>
            {data.map(({ displayName, id, icon }) => (
              <ListItem
                key={id}
                data-testid="data-list-wrapper-item"
                onClick={() => {
                  setNewActiveListItem(id);
                  closeList();
                }}
              >
                {icon && <img src={icon} alt={displayName} />}
                {displayName}
              </ListItem>
            ))}
          </>
        )}
        {!data?.length && !isLoading && (
          <WarningMessage>Sorry, no data was found :(</WarningMessage>
        )}
      </ListWrapper>
    </Container>
  );
};

const WarningMessage = styled.div`
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 20px;
  font-weight: 400;
  margin: 30px auto;
  text-align: center;
`;

export default ListForSelectingDataBE;
