import React from 'react';

const CheckedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10.147"
    viewBox="0 0 12 10.147"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 18976"
          d="m12.632-17.981-1.484-1.488a.266.266 0 0 0-.188-.078.266.266 0 0 0-.188.078L4.505-13.2a.266.266 0 0 1-.188.078.266.266 0 0 1-.188-.078l-1.484-1.486a.266.266 0 0 0-.188-.078.266.266 0 0 0-.188.078L.785-13.2a.266.266 0 0 0 0 .376l3.344 3.346a.266.266 0 0 0 .188.078.266.266 0 0 0 .188-.078l8.126-8.131a.266.266 0 0 0 .001-.372Z"
          transform="translate(-.708 19.547)"
          fill="#1bad31"
        />
      </clipPath>
    </defs>
    <g data-name="Group 15082" clipPath="url(#a)">
      <path
        data-name="Path 18975"
        d="M-2.657-2.657h17.314v15.461H-2.657Z"
        fill="#1bad31"
      />
    </g>
  </svg>
);

export default CheckedIcon;
