import React from 'react';
import styled, { css } from 'styled-components';

import AdvancedButton from 'components/buttons/AdvancedButton';
import CloseIcon from 'components/icons/CloseIcon';
import {
  TABLE_COLUMNS,
  TableRowGrid,
  TemporaryStatus,
} from 'components/table/models';
import {
  Container,
  Name,
  Text,
} from 'components/table/styledElements/tableStyledElements';

import { DetailsInfo } from './ManageContainerRow';

type ManageRowProps = {
  details?: boolean;
  detailsInfo: DetailsInfo;
  data: string[];
  tableName: TableRowGrid;
  temporaryStatus?: TemporaryStatus;
  openModalWindow: () => void;
};

const ManageRow = ({
  details,
  detailsInfo,
  data,
  tableName,
  openModalWindow,
}: ManageRowProps) => (
  <ManageRowContainer
    $details={details}
    className="row"
    style={{ gridTemplateColumns: TABLE_COLUMNS[tableName] }}
  >
    <InfoUserWrapper>
      <PhotoContainer>
        <Photo src={detailsInfo.photo} />
      </PhotoContainer>
      <InfoUserDescrWrapper>
        <UserName>{detailsInfo.name}</UserName>
        <Email>{detailsInfo.email}</Email>
      </InfoUserDescrWrapper>
    </InfoUserWrapper>
    {data.map((item, index) => (
      <React.Fragment key={item}>
        <Text $withMargin={index === 2}>{item}</Text>
      </React.Fragment>
    ))}
    {details ? (
      <CloseIconWrapper onClick={openModalWindow}>
        <CloseIcon color="white" prefix="details_modal" />
      </CloseIconWrapper>
    ) : (
      <AdvancedButton clickHandler={openModalWindow} />
    )}
  </ManageRowContainer>
);

const CloseIconWrapper = styled.div`
  width: 20px;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  outline: none;
  border: none;
  background: none;
  padding: 0;
`;

const ManageRowContainer = styled(Container)<{
  $details?: boolean;
}>`
  height: 95px;
  ${({ $details }) =>
    $details &&
    css`
      transition: 0.2s;
      padding: 0;
      background-color: inherit !important;

      div,
      p {
        color: white;
      }
    `}
`;

const InfoUserWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Photo = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
`;

const PhotoContainer = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: -1px 1px 9px 1px grey;
  width: 55px;
  height: 55px;
`;

const UserName = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.funtainBlue};
  text-transform: capitalize;
`;
const Email = styled(Name)`
  font-weight: 300;
  color: ${({ theme }) => theme.colors.funtainBlue};
`;

const InfoUserDescrWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ManageRow;
