export const listLabels = {
  businessSector: 'Business sector',
  country: 'Country',
  countryCode: 'Country code',
  corporateAccount: 'Select corporate account',
  currency: 'Currency',
  offerTerm: 'Offer term',
};

export const businessSectors = [
  { id: '0', displayName: 'Accounting', BEformat: 'Accounting' },
  {
    id: '1',
    displayName: 'Administration and office support',
    BEformat: 'AdministrationAndOfficeSupport',
  },
  {
    id: '2',
    displayName: 'Advertising arts and media',
    BEformat: 'AdvertisingArtsAndMedia',
  },
  {
    id: '3',
    displayName: 'Banking and financial services',
    BEformat: 'BankingAndFinancialServices',
  },
  {
    id: '4',
    displayName: 'Call centre and customer service',
    BEformat: 'CallCentreAndCustomerService',
  },
  {
    id: '5',
    displayName: 'Community services and development',
    BEformat: 'CommunityServicesAndDevelopment',
  },
  { id: '6', displayName: 'Construction', BEformat: 'Construction' },
  {
    id: '7',
    displayName: 'Consulting and strategy',
    BEformat: 'ConsultingAndStrategy',
  },
  {
    id: '8',
    displayName: 'Design and architecture',
    BEformat: 'DesignAndArchitecture',
  },
  {
    id: '9',
    displayName: 'Education and training',
    BEformat: 'EducationAndTraining',
  },
  { id: '10', displayName: 'Engineering', BEformat: 'Engineering' },
  {
    id: '11',
    displayName: 'Farming animals and conservation',
    BEformat: 'FarmingAnimalsAndConservation',
  },
  {
    id: '12',
    displayName: 'Government and defence',
    BEformat: 'GovernmentAndDefence',
  },
  {
    id: '13',
    displayName: 'Healthcare and medical',
    BEformat: 'HealthcareAndMedical',
  },
  {
    id: '14',
    displayName: 'Hospitality and tourism',
    BEformat: 'HospitalityAndTourism',
  },
  {
    id: '15',
    displayName: 'Human resources and recruitment',
    BEformat: 'HumanResourcesAndRecruitment',
  },
  {
    id: '16',
    displayName: 'Information and communication technology',
    BEformat: 'InformationAndCommunicationTechnology',
  },
  {
    id: '17',
    displayName: 'Insurance and superannuation',
    BEformat: 'InsuranceAndSuperannuation',
  },
  { id: '18', displayName: 'Legal', BEformat: 'Legal' },
  {
    id: '19',
    displayName: 'Manufacturing transport and logistics',
    BEformat: 'ManufacturingTransportAndLogistics',
  },
  {
    id: '20',
    displayName: 'Marketing and communications',
    BEformat: 'MarketingAndCommunications',
  },
  {
    id: '21',
    displayName: 'Mining resources and energy',
    BEformat: 'MiningResourcesAndEnergy',
  },
  {
    id: '22',
    displayName: 'Real estate and property',
    BEformat: 'RealEstateAndProperty',
  },
  {
    id: '23',
    displayName: 'Retail and consumer products',
    BEformat: 'RetailAndConsumerProducts',
  },
  { id: '24', displayName: 'Sales', BEformat: 'Sales' },
  {
    id: '25',
    displayName: 'Science and technology',
    BEformat: 'ScienceAndTechnology',
  },
  {
    id: '26',
    displayName: 'Sport and recreation',
    BEformat: 'SportAndRecreation',
  },
  {
    id: '27',
    displayName: 'Trades and services',
    BEformat: 'TradesAndServices',
  },
];

export const countriesCode = [
  {
    id: '0',
    displayName: '+31',
    icon: './images/countries/netherlands.svg',
    format: '(#) ##### ####################',
    mobileLength: 10,
  },
  {
    id: '1',
    displayName: '+44',
    icon: './images/countries/uk.svg',
    format: '(#) ##### ####################',
    mobileLength: 11,
  },
];

export const currencies = [
  {
    id: '0',
    displayName: '€ EUR',
    BEformat: 'EUR',
    displaySign: '€',
  },
  {
    id: '1',
    displayName: '$ USD',
    BEformat: 'USD',
    displaySign: '$',
  },
  {
    id: '2',
    displayName: '£ GBP',
    BEformat: 'GBP',
    displaySign: '£',
  },
];

export const offerTerms = [
  {
    id: '0',
    displayName: '1 month trial',
    BEformat: 'OneMonth',
    disabled: false,
    displayOfferPageName: '1M',
    displayDownloadPageName: '1 month',
  },
  {
    id: '1',
    displayName: '3 months full access',
    BEformat: 'ThreeMonths',
    disabled: false,
    displayOfferPageName: '3M',
    displayDownloadPageName: '3 months',
  },
  {
    id: '2',
    displayName: '6 months full access',
    BEformat: 'SixMonths',
    disabled: false,
    displayOfferPageName: '6M',
    displayDownloadPageName: '6 months',
  },
  {
    id: '3',
    displayName: '12 months full access',
    BEformat: 'TwelveMonths',
    disabled: false,
    displayOfferPageName: '12M',
    displayDownloadPageName: '12 months',
  },
];

export enum departmentVariant {
  Yes = 'true',
  No = 'false',
}

export const departmentListVariant = [
  { id: '0', displayName: 'Yes', BEformat: departmentVariant.Yes },
  {
    id: '1',
    displayName: 'No',
    BEformat: departmentVariant.No,
  },
];

export const promotionColorList = [
  {
    id: '0',
    displayName: 'Sand (default)',
    BEformat: 'Sand',
    disabled: false,
  },
  {
    id: '1',
    displayName: 'Burnt Sienna',
    BEformat: 'BurntSienna',
    disabled: false,
  },
  {
    id: '2',
    displayName: 'Sea',
    BEformat: 'Sea',
    disabled: false,
  },
  {
    id: '3',
    displayName: 'Stone',
    BEformat: 'Stone',
    disabled: false,
  },
  {
    id: '4',
    displayName: 'Blush',
    BEformat: 'Blush',
    disabled: false,
  },
  {
    id: '5',
    displayName: 'Pistachio',
    BEformat: 'Pistachio',
    disabled: false,
  },
  {
    id: '6',
    displayName: 'Sunlight',
    BEformat: 'Sunlight',
    disabled: false,
  },
];
