import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { TemporaryStatus } from '../models';

const inscriptions = {
  [TemporaryStatus.Suspended]: 'Suspended!',
  [TemporaryStatus.Activated]: 'Activated!',
  [TemporaryStatus.Published]: 'Published!',
  [TemporaryStatus.Updated]: 'Updated!',
};

const backgrounds = {
  [TemporaryStatus.Suspended]: theme.colors.secondary,
  [TemporaryStatus.Published]: theme.colors.swamp,
  [TemporaryStatus.Activated]: theme.colors.swamp,
  [TemporaryStatus.Updated]: theme.colors.swamp,
};

type TableRowNotificationProps = {
  temporaryStatus: TemporaryStatus;
};

const TemporaryNotification = ({
  temporaryStatus,
}: TableRowNotificationProps) => {
  return (
    <Container $background={backgrounds[temporaryStatus]}>
      {inscriptions[temporaryStatus]}
    </Container>
  );
};

const Container = styled.span<{ $background: string }>`
  padding: 3px 10px;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 700;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ $background }) => $background};
  text-transform: uppercase;
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: 9px;
`;

export default TemporaryNotification;
