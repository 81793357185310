/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import theme from 'theme';

import { ErrorMessage } from '@hookform/error-message';
import WarningIcon from 'components/icons/WarningIcon';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';

import { FormInput, FormLabel } from '../../styledElements/formsStyledElements';

type StandardInputProps = {
  cutLastWord?: boolean;
  cutFirstWord?: boolean;
  isRequired?: boolean;
  doNotTriggerValidation?: boolean;
  label: string;
  inputId: string;
  errors: FieldErrors;
  inputValue?: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  saveData?: () => void;
};

const StandardInput = ({
  cutLastWord,
  cutFirstWord,
  isRequired = true,
  doNotTriggerValidation,
  errors,
  register,
  setValue,
  label,
  inputId,
  saveData,
  inputValue,
}: StandardInputProps) => {
  const [isInputActive, setIsInputActive] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setValue(name, value, { shouldValidate: true });
    saveData?.();
  };

  useLayoutEffect(() => {
    if (inputValue) {
      setIsInputActive(true);
      // in case if there is an error from the server, we should turn off shouldValidate
      // property to prevent from rewriting error
      setValue(
        inputId,
        inputValue,
        doNotTriggerValidation ? {} : { shouldValidate: true }
      );
    }
  }, [doNotTriggerValidation, inputId, inputValue, setValue]);

  const labelView = useMemo(() => {
    if (cutFirstWord && isInputActive) {
      return label.substring(label.indexOf(' ') + 1);
    }

    if (cutLastWord && isInputActive) {
      const labelWords = label.split(' ');

      labelWords.pop();

      return labelWords.join(' ');
    }

    return label;
  }, [cutFirstWord, cutLastWord, isInputActive, label]);

  return (
    <InputContainer $error={!!errors[inputId]}>
      <FormLabel
        htmlFor={inputId}
        $error={!!errors[inputId]}
        $active={!!(isInputActive || inputValue)}
      >
        {labelView}
      </FormLabel>
      <FormInput
        id={inputId}
        {...register(inputId, {
          required: isRequired,
          onBlur: (e) => {
            setValue(inputId, e.target.value.trim(), { shouldValidate: true });
            saveData?.();
          },
        })}
        onChange={changeInputHandler}
        $error={!!errors[inputId]}
        onFocus={() => setIsInputActive(true)}
      />
      <ErrorMessage
        name={inputId}
        errors={errors}
        render={({ message }) => (
          <ErrorText $error={!!errors[inputId]}>
            <WarningIcon color={theme.colors.primary} />
            {message}
          </ErrorText>
        )}
      />
    </InputContainer>
  );
};

export default StandardInput;
