import React, { useCallback, useContext, useState } from 'react';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar, { NavbarItem } from 'components/actionModal/Navbar';
import ReferrerAccountDetails from 'components/actionModal/stages/accountDetails/ReferrerAccountDetails';
import Result from 'components/actionModal/stages/Result';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import { TemporaryStatus } from 'components/table/models';

import { ReferrerAccountsContext } from '../../context/ReferrerAccountsContext';
import {
  AccountCategory,
  AccountModalWindowStages,
  ReferrerAccount,
  ReferrerAccountDetailsData,
} from '../../models';

const NAVBAR_ITEMS: NavbarItem<AccountModalWindowStages>[] = [
  {
    text: 'Account details',
    name: AccountModalWindowStages.AccountDetails,
  },
];

type EditReferrerModalProps = {
  account: ReferrerAccount;
  closeModalWindow: () => void;
};

const EditReferrerModal = ({
  account,
  closeModalWindow,
}: EditReferrerModalProps) => {
  const [activeStage, setActiveStage] = useState<AccountModalWindowStages>(
    AccountModalWindowStages.AccountDetails
  );
  const { editAccount, setNewStatusForAccount } = useContext(
    ReferrerAccountsContext
  );

  const goToResultStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.Result);
    setNewStatusForAccount(TemporaryStatus.Updated, account.id);
  }, [account.id, setNewStatusForAccount]);

  const changeActiveStage = useCallback((stage: number) => {
    const newActiveStage = NAVBAR_ITEMS[stage].name;

    setActiveStage(newActiveStage);
  }, []);

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {activeStage === AccountModalWindowStages.Result ? (
        <Result
          closeModal={closeModalWindow}
          title={`${AccountCategory.Referrer} account updated!`}
        />
      ) : (
        <>
          <Navbar
            showTitle
            title="Update account"
            activeStage={activeStage}
            accountCategory={AccountCategory.Referrer}
            activatedStages={[AccountModalWindowStages.AccountDetails]}
            changeActiveStage={changeActiveStage}
            navbarItems={NAVBAR_ITEMS}
          />
          <ActionModalMainContent>
            <ReferrerAccountDetails
              account={{
                mobile: account.mobile,
                country: account.country,
                email: account.email,
                lastName: account.lastName,
                firstName: account.firstName,
              }}
              title="Account details"
              submitButtonTitle="update"
              goToNextStage={goToResultStage}
              saveData={(data: ReferrerAccountDetailsData) => {
                editAccount(data, account.id);
              }}
            />
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default EditReferrerModal;
