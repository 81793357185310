import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import SortingPopup from 'components/SortingPopup';
import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';

import { TableRowGrid, TABLE_COLUMNS, TABLE_HEADER } from './models';

type TableHeaderProps = {
  tableName: TableRowGrid;
  sortByListItemIndex?: number;
  parametersForSorting?: string[];
  sortData?: (index: number) => void;
};

const TableHeader = ({
  tableName,
  sortData,
  sortByListItemIndex,
  parametersForSorting,
}: TableHeaderProps) => {
  const [isSortingPopupOpen, setIsSortingPopupOpen] = useState(false);

  const closeSortingPopup = useCallback(() => {
    setIsSortingPopupOpen(false);
  }, []);

  return (
    <Container style={{ gridTemplateColumns: TABLE_COLUMNS[tableName] }}>
      {TABLE_HEADER[tableName].map((item) => (
        <div key={item}>{item}</div>
      ))}
      {(sortByListItemIndex || sortByListItemIndex === 0) &&
        parametersForSorting &&
        sortData && (
          <>
            <Sort>
              <StaticText>Sort by:&nbsp;</StaticText>
              <Option>{parametersForSorting[sortByListItemIndex || 0]}</Option>
              <ActionButtonContainer data-testid="sort-icon">
                <ActionButton
                  icon={<ChevronIcon color={theme.colors.white} />}
                  clickHandler={() => {
                    setIsSortingPopupOpen(true);
                  }}
                />
              </ActionButtonContainer>
            </Sort>

            {isSortingPopupOpen && (
              <SortingPopup
                closePopup={closeSortingPopup}
                parametersForSorting={parametersForSorting}
                activeParameter={parametersForSorting[sortByListItemIndex || 0]}
                setActiveParameter={sortData}
              />
            )}
          </>
        )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: grid;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 40px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  color: rgb(255 255 255 / 70%);
  font-size: 12px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 700;
`;

const Sort = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BUTTON_VERTICAL_RANGE = 10;
const BUTTON_HORIZONTAL_RANGE = 10;

const ActionButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }
`;

const Option = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
`;

const StaticText = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;

export default React.memo(TableHeader);
