import React, { useCallback, useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import StandardDropdown from 'components/actionModal/forms/dropdowns/StandardDropdown';
import StandardInput from 'components/actionModal/forms/inputs/StandardInput';
import TextAreaField from 'components/actionModal/forms/inputs/TextAreaField';
import { InputId } from 'components/actionModal/models';
import { promotionOffePageValidationSchema } from 'components/actionModal/models/validationSchemas';
import {
  ModalStage,
  MultipleInputsContainer,
  SubmitButtonContainer,
} from 'components/actionModal/styledElements/formsStyledElements';
import DefaultButton from 'components/buttons/DefaultButton';
import { listLabels } from 'components/listForSelectingData/dataForLists';
import { PromotionOfferContext } from 'modules/offers/context/PromotionOfferContext';
import useCheckDataChanging from 'modules/offers/hooks/useCheckDataChanging';
import {
  PromotionOfferPageAttributes,
  PromotionOfferData,
  PromoTerm,
} from 'modules/offers/models';

import { Title } from '../../styledElements/stagesStyledElements';

type OfferPageProps = {
  isShowStage: boolean;
  isDisabledDropdown?: boolean;
  isEdit?: boolean;
  promoArray: PromoTerm[];
  settings: PromotionOfferPageAttributes;
  title: string;
  offer?: PromotionOfferData;
  saveSettings: (value: PromotionOfferPageAttributes) => void;
  sendRequest: (offerPageAttr: PromotionOfferPageAttributes) => void;
  freezeSettingsPageActivate: (value?: boolean) => void;
};

const OfferPage = ({
  isShowStage,
  isDisabledDropdown,
  isEdit,
  promoArray,
  settings,
  title,
  saveSettings,
  sendRequest,
  freezeSettingsPageActivate,
}: OfferPageProps) => {
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(promotionOffePageValidationSchema),
  });
  const { setPrevData, prevData } = useContext(PromotionOfferContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOfferTermValid, setIsOfferTermValid] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const isFormValid = isValid && isOfferTermValid;

  const saveTitle = useCallback(() => {
    saveSettings({
      ...settings,
      title: watch()[InputId.PromotionTitle],
    });
  }, [saveSettings, settings, watch]);

  const saveSubtitle = useCallback(
    () =>
      saveSettings({
        ...settings,
        subtitle: watch()[InputId.PromotionSubtitle],
      }),
    [saveSettings, settings, watch]
  );

  const saveBody = useCallback(
    () =>
      saveSettings({
        ...settings,
        bodyText: watch()[InputId.PromotionBody],
      }),
    [saveSettings, settings, watch]
  );

  const saveOfferTerm = useCallback(
    (promotionTermId: string) =>
      saveSettings({
        ...settings,
        promotionTermId,
      }),
    [saveSettings, settings]
  );

  const checkOfferTermValidation = useCallback(
    (isValid: boolean) => setIsOfferTermValid(isValid),
    []
  );

  const formSubmit = () => {
    if (isValid) {
      sendRequest({
        subtitle: watch()[InputId.PromotionSubtitle],
        bodyText: watch()[InputId.PromotionBody],
        title: watch()[InputId.PromotionTitle],
        promotionTermId: settings.promotionTermId,
      });
      setPrevData(settings);
    }
  };

  const submitForm = handleSubmit(formSubmit);

  useCheckDataChanging(
    freezeSettingsPageActivate,
    setDisableButton,
    isFormValid,
    prevData,
    settings,
    isEdit
  );

  return (
    <ModalStage $isShowStage={isShowStage} ref={containerRef}>
      <StyledTitle>{title}</StyledTitle>
      <FormContainer onSubmit={submitForm}>
        <MultipleInputsContainer>
          <StandardDropdown
            isDisabledDropdown={isDisabledDropdown}
            inputLabel="Offer term"
            listLabel={listLabels.offerTerm}
            list={promoArray}
            saveData={saveOfferTerm}
            inputValue={settings.promotionTermId}
            checkValidation={checkOfferTermValidation}
          />
        </MultipleInputsContainer>
        <StandardInput
          cutFirstWord
          errors={errors}
          register={register}
          setValue={setValue}
          label="Enter title"
          inputId={InputId.PromotionTitle}
          inputValue={settings.title}
          saveData={saveTitle}
        />
        <StandardInput
          cutFirstWord
          errors={errors}
          register={register}
          setValue={setValue}
          label="Enter subtitle"
          inputId={InputId.PromotionSubtitle}
          inputValue={settings.subtitle}
          saveData={saveSubtitle}
        />
        <TextAreaField
          submit={() => {
            if (isFormValid && !disableButton) {
              submitForm();
            }
          }}
          cutFirstWord
          maxRows={2}
          errors={errors}
          register={register}
          setValue={setValue}
          label="Enter body text"
          inputId={InputId.PromotionBody}
          inputValue={settings.bodyText}
          saveData={saveBody}
        />
        <SubmitButtonContainer>
          <DefaultButton
            text="next"
            textColor={theme.colors.white}
            isSubmit
            withShadow
            isUppercase
            isActive={isFormValid && !disableButton}
            buttonColor={
              isFormValid && !disableButton
                ? theme.colors.funtainBlue
                : theme.colors.quillGray
            }
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
    </ModalStage>
  );
};

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  max-width: 656px;
  margin-top: 19px;
`;

export default React.memo(OfferPage);
