export type ShidoIsTheBestAssistantCarouselDataDesktop = {
  id: number;
  name: string;
  text: string;
  imageSrc: string;
};

export const shidoIsTheBestAssistantCarouselDataDesktop: ShidoIsTheBestAssistantCarouselDataDesktop[] =
  [
    {
      id: 1,
      name: 'John Skelly',
      text: `I love Shido and have been using it every day to get gradually improve my health. I’ve lost 10 kg, \n feel calmer and more confident. It covers a lot of topics and is fun and easy to use.`,
      imageSrc: '/images/shidoIsTheBestAssistant/john-skelly.png',
    },
    {
      id: 2,
      name: 'Kailia Balewa',
      text: 'There are so many apps out there that promise you the world. I have tested a lot and with Shido that search has finally \n stopped. I especially like the fact that I can get professional advice, recipes, workouts, articles and more in one place.',
      imageSrc: '/images/shidoIsTheBestAssistant/kailia-balewa.png',
    },
    {
      id: 3,
      name: 'Millie Gallo',
      text: 'Great app! I use it mainly for the recipes and the articles about Intermittent Fasting. I tried to establish it into my morning \n routine and it definitely helps for my mindset and positive thinking.',
      imageSrc: '/images/shidoIsTheBestAssistant/millie-gallo.png',
    },
  ];

export const shidoIsTheBestAssistantCarouselDataMobile: ShidoIsTheBestAssistantCarouselDataDesktop[] =
  [
    {
      id: 1,
      name: 'John Skelly',
      text: `I love Shido and have been using it every day to \n gradually improve my health. \n\n I’ve lost 10 kg, feel calmer and more confident. \n It covers a lot of topics and is fun and easy to use.`,
      imageSrc: '/images/shidoIsTheBestAssistant/john-skelly.png',
    },
    {
      id: 2,
      name: 'Kailia Balewa',
      text: 'There are so many apps out there that promise you \n the world. I have tested a lot and with Shido \n that search has finally stopped. \n\n I especially like the fact that I can get professional \n advice, recipes, workouts, articles \n and more in one place.',
      imageSrc: '/images/shidoIsTheBestAssistant/kailia-balewa.png',
    },
    {
      id: 3,
      name: 'Millie Gallo',
      text: 'Great app! I use it mainly for the recipes and the \n articles about Intermittent Fasting. \n\n I tried to establish it into my morning routine and \n it definitely helps for my mindset and positive thinking.',
      imageSrc: '/images/shidoIsTheBestAssistant/millie-gallo.png',
    },
  ];
