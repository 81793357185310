import React, { memo } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import InfoIcon from 'components/icons/InfoIcon';

import { TWENTY_EIGHT_DAYS } from '../models';

type ContentBarProps = {
  openModalWindow: () => void;
  diffDays?: number;
};

const ContentBar = ({ diffDays, openModalWindow }: ContentBarProps) => {
  return (
    <Container>
      <img src="/images/insightsLogo.png" />

      {diffDays && diffDays > TWENTY_EIGHT_DAYS ? (
        <ButtonWrapper>
          <DefaultButton
            isActive
            withShadow
            borderSize="md"
            buttonSize="md"
            text="Update modules"
            textColor={theme.colors.white}
            buttonColor={theme.colors.funtainBlue}
            clickHandler={openModalWindow}
          />
        </ButtonWrapper>
      ) : (
        diffDays && (
          <InfoModule>
            <p>{`You can update this selection again in ${
              TWENTY_EIGHT_DAYS - diffDays + 1
            } days`}</p>
            <InfoIcon />
          </InfoModule>
        )
      )}
    </Container>
  );
};

const InfoModule = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
  top: -2px;
  right: 2px;

  p {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: -40px;
  width: 100%;
  height: 80px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 19px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 10px;
  }
`;

export default memo(ContentBar);
