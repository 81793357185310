import { findIndex } from 'lodash';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import countries from 'components/listForSelectingData/dataForList/countries';
import { listLabels } from 'components/listForSelectingData/dataForLists';
import ListForSelectingDataFE from 'components/listForSelectingData/lists/ListForSelectingDataFE';
import { InputContainer } from 'modules/auth/components/styledElements/formsStyledElements';

import {
  ActiveListParameter,
  CountryItem,
  DropdownInput,
  FormLabel,
} from '../../styledElements/formsStyledElements';

type Country = {
  id: string;
  icon: string;
  displayName: string;
};

type CountryDropdownProps = {
  inputValue: string;
  isOutOfBounds?: boolean;
  saveData: (value: string) => void;
  checkValidation: (isValid: boolean) => void;
};

const CountryDropdown = ({
  saveData,
  inputValue,
  isOutOfBounds,
  checkValidation,
}: CountryDropdownProps) => {
  const [isCountryListOpen, setIsCountryListOpen] = useState(false);
  const [country, setCountry] = useState<Country>();

  const closeCountriesList = useCallback(() => setIsCountryListOpen(false), []);

  const setNewCountry = useCallback(
    (index: string) => {
      const countryIndex = Number(index);

      checkValidation(true);
      setCountry(countries[countryIndex]);
      saveData(countries[countryIndex].BEformat);
    },
    [checkValidation, saveData]
  );

  useLayoutEffect(() => {
    if (inputValue) {
      const countryIndex = findIndex(countries, { BEformat: inputValue });

      checkValidation(true);
      setCountry(countries[countryIndex]);
    }
  }, [checkValidation, inputValue]);

  return (
    <InputContainer>
      <FormLabel
        $isCursorPointer
        $active={!!(country || inputValue)}
        onClick={() => setIsCountryListOpen(true)}
      >
        Country
      </FormLabel>
      <DropdownInput onClick={() => setIsCountryListOpen(true)}>
        <CountryItem>
          {country && (
            <>
              <img src={country.icon} alt={country.displayName} />
              <ActiveListParameter>{country.displayName}</ActiveListParameter>
            </>
          )}
        </CountryItem>
        <ActionButton icon={<ChevronIcon color={theme.colors.funtainBlue} />} />
      </DropdownInput>
      {isCountryListOpen && (
        <ListForSelectingDataFE
          listWidth="lg"
          listHeight="md"
          data={countries}
          isOutOfBounds={isOutOfBounds}
          label={listLabels.country}
          closeList={closeCountriesList}
          setNewActiveListItem={setNewCountry}
        />
      )}
    </InputContainer>
  );
};

export default CountryDropdown;
