import React from 'react';
import styled, { css } from 'styled-components';

import ActionButton from 'components/buttons/ActionButton';
import DownloadIcon from 'components/icons/DownloadIcon';

import { ActionButtonContainer } from '../../styledElements/stagesStyledElements';

type Props = {
  pictureDescription: string;
  text: string;
  isFirstChild?: boolean;
  fileFormat: string;
  maxFileSize: number;
  clickHandler: () => void;
};

const UploadSuggestion = ({
  pictureDescription,
  text,
  isFirstChild,
  fileFormat,
  clickHandler,
}: Props) => (
  <Container
    className="upload-suggestion"
    data-testid="upload-suggestion"
    onClick={clickHandler}
    $isFirstChild={isFirstChild}
  >
    <ActionButtonContainer>
      <ActionButton icon={<DownloadIcon />} />
    </ActionButtonContainer>
    <Notification>
      <span>{text}</span> <span>{fileFormat}</span>
      <p>{pictureDescription}</p>
    </Notification>
  </Container>
);

const Container = styled.div<{ $isFirstChild?: boolean }>`
  position: relative;
  left: -2px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 40px;
  cursor: pointer;
  ${({ $isFirstChild }) =>
    $isFirstChild &&
    css`
      margin-top: 26px;
    `}

  div:first-child {
    margin-right: 10px;
  }
`;

const Notification = styled.div`
  position: relative;
  top: -2px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.funtainBlue};
  }

  p {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
  }

  span:first-child {
    font-weight: 700;
  }
`;

export default UploadSuggestion;
