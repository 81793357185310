import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import AffiliateArrow from 'components/icons/AffiliateArror';
import { LogoIcon } from 'components/icons/LogoIcon';

import Header from './Header';

type IntroSection = {
  name: string;
  handle: string;
  image: string | null;
  isDesktop?: boolean;
  chosenColor: {
    light: string;
    strong: string;
  };
  goToNextSection: () => void;
};

const IntroSection = ({
  image,
  handle,
  name,
  isDesktop,
  chosenColor,
  goToNextSection,
}: IntroSection) => {
  return (
    <Container $color={chosenColor.light}>
      <Header isDesktop={isDesktop} />
      <Title $isDesktop={isDesktop}>Exclusive offer from {name}.</Title>
      {handle && (
        <TeamSubtitleContainer>
          <TeamSubtitle $isDesktop={isDesktop}>{handle}</TeamSubtitle>
        </TeamSubtitleContainer>
      )}
      <ImageGraphContainer $isDesktop={isDesktop}>
        <ImageInnerContainer>
          <AdditionalImageInner>
            {image ? <img src={image} /> : <LogoIcon width={153} height={93} />}
          </AdditionalImageInner>
        </ImageInnerContainer>
      </ImageGraphContainer>
      <FreeTrialText>Get your 1 month free trial today!</FreeTrialText>
      <ButtonLink $isDesktop={!!isDesktop}>
        <DefaultButton
          text="continue"
          textColor={theme.colors.white}
          isActive
          withShadow
          buttonColor={theme.colors.funtainBlue}
          isUppercase
          buttonSize="xl"
          borderSize="xl"
        />
      </ButtonLink>
      <ArrowIconWrapper onClick={goToNextSection}>
        <AffiliateArrow />
      </ArrowIconWrapper>
      <Template $color={chosenColor.strong} />
    </Container>
  );
};

const ButtonLink = styled.div<{ $isDesktop: boolean }>`
  position: relative;
  z-index: 1;
  margin: ${({ $isDesktop }) =>
    $isDesktop ? '32px auto 38px' : '32px auto 4px'};
  transition: 0.5s all;
  width: 300px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  pointer-events: unset;
`;

const Template = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/promoTemplateDesktop.svg') no-repeat 50% 50%;
  mask-position: bottom -2150px right 50%;
`;

const AdditionalImageInner = styled.div`
  width: 268px;
  height: 268px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  img {
    width: 268px;
    height: 268px;
    object-fit: cover;
  }
`;

const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin: 74px auto 84px;
`;

const FreeTrialText = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 72px;
  position: relative;
  z-index: 3;
`;

export const ImageGraphContainer = styled.div<{ $isDesktop?: boolean }>`
  margin: 42px auto 0;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  border: 2px dashed ${({ theme }) => theme.colors.pinkLight};
  border-radius: 50%;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin: 76px auto 0;
    `}
`;

const ImageInnerContainer = styled.div`
  width: 270px;
  height: 270px;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 15px -4px grey;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const TeamSubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TeamSubtitle = styled.p<{ $isDesktop?: boolean }>`
  font-size: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pinkLight};
  box-sizing: border-box;
  font-weight: 700;
  position: relative;
  z-index: 3;
  margin: 40px auto 0;
  padding: 10px 20px;
  text-transform: uppercase;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin-top: 27px;
    `}
`;

const Title = styled.div<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '60px' : '50px')};
  text-align: center;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 72px;
  word-break: break-word;
  position: relative;
  z-index: 3;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin-top: 74px;
    `}
`;

const Container = styled.div<{ $color: string }>`
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: ${({ $color }) => $color};
`;

export default IntroSection;
