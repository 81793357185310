import React, { useMemo } from 'react';
import styled from 'styled-components';

import CheckedIcon from 'components/icons/CheckedIcon';
import EllipseIcon from 'components/icons/EllipseIcon';
import patterns from 'helpers/patterns';

type PasswordSuitabilityProps = {
  passwordValue: string;
};

const PasswordSuitability = ({ passwordValue }: PasswordSuitabilityProps) => {
  const isUpperCase = useMemo(
    () => patterns.passwordUpperCase.test(passwordValue),
    [passwordValue]
  );

  const isNumberExist = useMemo(
    () => patterns.passwordNumberExist.test(passwordValue),
    [passwordValue]
  );

  const isEnoughCharacters = useMemo(
    () => passwordValue.length >= 9,
    [passwordValue]
  );

  return (
    <Container>
      <SuitabilityItem>
        {isUpperCase ? <CheckedIcon /> : <EllipseIcon />}
        <TextItem>1 uppercase</TextItem>
      </SuitabilityItem>
      <SuitabilityItem>
        {isNumberExist ? <CheckedIcon /> : <EllipseIcon />}
        <TextItem>1 number</TextItem>
      </SuitabilityItem>
      <SuitabilityItem>
        {isEnoughCharacters ? <CheckedIcon /> : <EllipseIcon />}
        <TextItem>9 characters</TextItem>
      </SuitabilityItem>
    </Container>
  );
};

const TextItem = styled.div`
  margin-left: 5px;
`;

const SuitabilityItem = styled.div`
  font-weight: 300;
  font-size: 11px;
  color: #404d73;
  opacity: 0.7;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  position: absolute;
  top: 77px;
  left: 0;
  display: flex;
  align-items: center;
`;

export default PasswordSuitability;
