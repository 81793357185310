import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import { DashedLine, VerticalLine } from 'components/Divider';
import { Device } from 'components/previewPages/models';
import { devices } from 'components/previewPages/PreviewHeader';
import PreviewPageAffiliate from 'components/previewPages/previewPageAffiliate/PreviewPageAffiliate';
import PreviewPageCorporate from 'components/previewPages/previewPageCorporate/PreviewPageCorporate';
import PreviewPageInsight from 'components/previewPages/previewPageCorporate/PreviewPageInsight';
import PreviewPagePromotion from 'components/previewPages/previewPagePromotion/PreviewPagePromotion';

export enum PreviewOffer {
  Corporate = 'Corporate',
  Promotion = 'Promotion',
  Insight = 'Insight',
  Affiliate = 'Affiliate',
}

type PreviewBlockProps = {
  isShowStage?: boolean;
  logo: string;
  background?: string;
  previewOffer: PreviewOffer;
};

const PreviewBlock = ({
  isShowStage = true,
  logo,
  previewOffer,
  background,
}: PreviewBlockProps) => {
  const [activeDeviceForPreview, setActiveDeviceForPreview] = useState(
    Device.Mobile
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const actionWindow = document.getElementById('action_window');

  const openPreview = useCallback((device: Device) => {
    setIsPreviewOpen(true);
    setActiveDeviceForPreview(device);
  }, []);

  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
  }, []);

  if (!actionWindow) return null;

  // the portal is used for better positioning
  // this previewPages block inside the modal window
  return ReactDOM.createPortal(
    <Container $isShowStage={isShowStage}>
      <Title>Preview</Title>
      <DashedLine />
      <Devices>
        {devices.map(({ icon, device }) => (
          <React.Fragment key={device}>
            <DeviceItem>
              <p>{device}</p>
              <ActionButton
                icon={icon}
                clickHandler={() => openPreview(device)}
              />
            </DeviceItem>
            <Line $withOpacity $color={theme.colors.primary} />
          </React.Fragment>
        ))}
      </Devices>
      {isPreviewOpen && (
        <>
          {previewOffer === PreviewOffer.Corporate && (
            <PreviewPageCorporate
              logo={logo}
              background={background}
              chosenDevice={activeDeviceForPreview}
              closePreview={closePreview}
            />
          )}
          {previewOffer === PreviewOffer.Promotion && (
            <PreviewPagePromotion
              chosenDevice={activeDeviceForPreview}
              closePreview={closePreview}
            />
          )}

          {previewOffer === PreviewOffer.Insight && (
            <PreviewPageInsight
              logo={logo}
              background={background}
              chosenDevice={activeDeviceForPreview}
              closePreview={closePreview}
            />
          )}

          {previewOffer === PreviewOffer.Affiliate && (
            <PreviewPageAffiliate
              chosenDevice={activeDeviceForPreview}
              closePreview={closePreview}
            />
          )}
        </>
      )}
    </Container>,
    actionWindow
  );
};

const Container = styled.div<{ $isShowStage?: boolean }>`
  position: absolute;
  top: 85px;
  right: 20px;
  display: ${({ $isShowStage }) => ($isShowStage ? 'block' : 'none')};
`;

const Devices = styled.div`
  display: flex;
  align-items: center;
  margin-top: 17px;

  div:last-child {
    display: none;
  }

  div:nth-child(1) button {
    padding-left: 0;
  }

  div:nth-child(3) button,
  div:nth-child(5) button {
    padding-left: 9px;
  }

  div:nth-child(4) {
    position: relative;
    left: 8px;
  }

  path {
    fill: ${({ theme }) => theme.colors.funtainBlue};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 80px;
    text-align: center;
    flex-direction: column;

    div,
    button {
      left: 0 !important;
    }

    div:nth-child(5) button {
      padding-left: 0;
    }

    div:nth-child(3) button {
      padding-left: 4px;
    }
  }
`;

const DeviceItem = styled.div`
  width: 87px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 12px;
    margin-bottom: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 60px;

    p {
      font-size: 10px;
    }
  }
`;

const Title = styled.h5`
  font-size: 16px;
  font-weight: 400;
  opacity: 0.5;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    text-align: center;
    line-height: 18px;
  }
`;

const Line = styled(VerticalLine)`
  margin-top: 25px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 5px 0;
    transform: rotate(90deg);
  }
`;

export default PreviewBlock;
