import React from 'react';
import styled from 'styled-components';

import { CorporateOfferDownloadDetails } from 'components/detailsModal/models';

import DesktopFooter from '../components/Footer';
import Header from '../components/Header';
import InstallContent from '../components/InstallContent';

const InstallDescription = ({ offer }: CorporateOfferDownloadDetails) => {
  return (
    <Wrapper className="download">
      <Header />
      <ContentContainer>
        <InstallContent offer={offer} />
      </ContentContainer>
      <DesktopFooter />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 1300px;
  background: ${({ theme }) => theme.colors.lightWheat};
  display: grid;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  height: 1850px;
`;

const ContentContainer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  width: 875px;
  border-radius: 5px;
  margin: 35px auto 80px;
  padding-bottom: 55px;
  background: white;
`;

export default InstallDescription;
