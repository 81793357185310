import { QueryFunctionContext, useQuery } from 'react-query';

import { CorporateAccountsResponse } from '../accountsServices/CorporateAccountsService';
import { QueryKey } from '../models';

const useGetCorporateAccounts = (
  queryKeys: (string | number)[],
  queryFn: (data: QueryFunctionContext) => Promise<CorporateAccountsResponse>
) =>
  useQuery([QueryKey.GetCorporateAccounts, ...queryKeys], queryFn, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

export default useGetCorporateAccounts;
