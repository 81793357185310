import React from 'react';
import styled, { css } from 'styled-components';

import AdvancedButton from 'components/buttons/AdvancedButton';
import {
  TABLE_COLUMNS,
  TableRowGrid,
  TemporaryStatus,
} from 'components/table/models';
import {
  Container,
  Text,
} from 'components/table/styledElements/tableStyledElements';

type Props = {
  isShowButtons: boolean;
  details?: boolean;
  detailsInfo: {
    imgUrl?: string;
    title: string;
  };
  data: string[];
  tableName: TableRowGrid;
  temporaryStatus?: TemporaryStatus;
  openModalWindow: () => void;
};

const ContentRow = ({
  isShowButtons,
  details,
  detailsInfo,
  data,
  tableName,
}: Props) => (
  <ContentRowContainer
    $details={details}
    className="row"
    style={{ gridTemplateColumns: TABLE_COLUMNS[tableName] }}
  >
    <InfoUserWrapper>
      {!!detailsInfo.imgUrl && (
        <img
          style={{ borderRadius: '2px' }}
          height={55}
          width={78}
          src={detailsInfo.imgUrl}
        />
      )}
      <InfoUserDescrWrapper>
        <Title>{detailsInfo.title}</Title>
      </InfoUserDescrWrapper>
    </InfoUserWrapper>
    {data.map((item, index) => (
      <Text key={item} $middleUpperCase $withMargin={index === 2}>
        {item}
      </Text>
    ))}
    {isShowButtons && <AdvancedButton clickHandler={() => null} />}
  </ContentRowContainer>
);

const ContentRowContainer = styled(Container)<{
  $details?: boolean;
}>`
  height: 95px;
  ${({ $details }) =>
    $details &&
    css`
      transition: 0.2s;
      padding: 0;
      background-color: inherit !important;

      div,
      p {
        color: white;
      }
    `}
`;

const InfoUserWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
`;

const InfoUserDescrWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ContentRow;
