import { filter } from 'lodash';
import React, { createContext, ReactNode, useCallback, useState } from 'react';

import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
} from 'components/detailsModal/models';

import corporateOfferMockData from '../corporateOfferMockData';
import { CorporateOffer } from '../offersServices/CorporateOffersService';

type CorporateOffersContextType = {
  offers: CorporateOffer[];
  deleteOffer: (id: string) => void;
  setNewStatusForOffer: (status: StatusBE, id: string) => void;
};

const initialValue: CorporateOffersContextType = {
  offers: corporateOfferMockData,
  deleteOffer: () => null,
  setNewStatusForOffer: () => null,
};

export const CorporateOffersContext =
  createContext<CorporateOffersContextType>(initialValue);

const CorporateOffersContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [offers, setOffers] = useState(corporateOfferMockData);

  const deleteOffer = useCallback(
    (id: string) => {
      const newOffers = filter(offers, (offer) => offer.id !== id);

      setTimeout(() => setOffers(newOffers), CLOSE_DETAILS_MODAL_TIMER);
    },
    [offers]
  );

  const setNewStatusForOffer = useCallback(
    (status: StatusBE, id: string) => {
      const newOffers: CorporateOffer[] = offers.map((offer) => {
        if (offer.id === id) {
          return { ...offer, status, changedStatusRecently: status };
        }

        return offer;
      });

      setTimeout(() => setOffers(newOffers), CLOSE_DETAILS_MODAL_TIMER);
    },
    [offers]
  );

  return (
    <CorporateOffersContext.Provider
      value={{
        offers,
        deleteOffer,
        setNewStatusForOffer,
      }}
    >
      {children}
    </CorporateOffersContext.Provider>
  );
};

export default CorporateOffersContextProvider;
