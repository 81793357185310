import { range } from 'lodash';
import React, { useEffect } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import styled, { css } from 'styled-components';

import { ShidoIsTheBestAssistantCarouselDataDesktop } from '../models/data';

import SlideSelector from './SlideSelector';

const AUTOPLAY_INTERVAL = 6000;
const AMOUNT_OF_STARS = 5;

type Props = {
  disableInterval?: boolean;
  isMobile: boolean;
  isDesktop?: boolean;
  slides: ShidoIsTheBestAssistantCarouselDataDesktop[];
};

const ShidoIsTheBestAssistantCarousel = ({
  slides,
  disableInterval,
  isMobile,
  isDesktop,
}: Props) => {
  const { scrollRef, activePageIndex, pages, snapPointIndexes, goTo } =
    useSnapCarousel();

  useEffect(() => {
    if (disableInterval) return;

    const timeout = setTimeout(() => {
      if (activePageIndex === pages.length - 1) {
        goTo(0);
      } else {
        goTo(activePageIndex + 1);
      }
    }, AUTOPLAY_INTERVAL);

    return () => {
      clearTimeout(timeout);
    };
  }, [disableInterval, activePageIndex, pages, goTo]);

  return (
    <Container $isMobile={isMobile} $isDesktop={isDesktop}>
      <Scroll ref={scrollRef}>
        {slides.map((item, i) => (
          <Content key={item.id} $isSnapPoint={snapPointIndexes.has(i)}>
            <StarRatingContainer $isMobile={isMobile}>
              {range(AMOUNT_OF_STARS).map((number: number) => (
                <Star key={number} src="/star.svg" alt="" />
              ))}
            </StarRatingContainer>
            <Text $isMobile={isMobile}>{item.text}</Text>
            <Image
              $isSpecial={i === 1}
              $isMobile={isMobile}
              src={item.imageSrc}
              alt={item.name}
            />
            <Name $isMobile={isMobile}>{item.name}</Name>
          </Content>
        ))}
      </Scroll>
      <SelectorContainer aria-hidden $isMobile={isMobile}>
        <SlideSelector
          slides={slides}
          selectedIdx={activePageIndex}
          interval={AUTOPLAY_INTERVAL}
          clickHandler={goTo}
          disableInterval={disableInterval}
          isMobile={isMobile}
        />
      </SelectorContainer>
    </Container>
  );
};

const Container = styled.div<{
  $isDesktop?: boolean;
  $isMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin-top: 43px;
    `};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 38px;
    `};
`;

const SelectorContainer = styled.div<{ $isMobile?: boolean }>`
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 0;
    `};
`;

const Scroll = styled.ul`
  position: relative;
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  box-sizing: border-box;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div<{ $isSnapPoint: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  ${({ $isSnapPoint }) =>
    $isSnapPoint &&
    css`
      scroll-snap-align: start;
    `}

  p {
    text-align: center;
  }
`;

const StarRatingContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 33px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding-bottom: 13px;
    `}
`;

const Star = styled.img`
  width: 24px;
  height: 24px;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const Image = styled.img<{ $isMobile?: boolean; $isSpecial?: boolean }>`
  width: 61px;
  height: 61px;
  border-radius: 50%;
  margin-bottom: 20px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 41px;
      height: 41px;
      margin-top: 23px;
      margin-bottom: 14px;
    `}

  ${({ $isMobile, $isSpecial }) =>
    $isMobile &&
    $isSpecial &&
    css`
      margin-top: -2px;
    `}
`;

const Name = styled.p<{ $isMobile?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 12px;
      width: 150px;
      margin: 0 auto;
    `}
`;

const Text = styled.p<{ $isMobile?: boolean }>`
  font-size: 20px;
  font-weight: 300;
  white-space: pre-line;
  line-height: 30px;
  font-style: italic;
  padding-right: 1px;
  margin-bottom: 24px;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 14px;
      padding: 0 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      line-height: 21px;
    `}
`;

export default ShidoIsTheBestAssistantCarousel;
