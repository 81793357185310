import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import theme from 'theme';
import 'simplebar-react/dist/simplebar.min.css';

import { ListItem } from 'components/actionModal/styledElements/stagesStyledElements';
import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import filterDataForDropdownList, {
  ListItemData,
} from 'helpers/filterDataForDropdownList';
import useOutsideClick from 'hooks/useOutsideClick';

import { listsWidth, ListWidth } from '../models';
import SearchingBlock from '../SearchingBlock';
import { Container } from '../styledElements/listStyledElements';

type Props = {
  label: string;
  data: ListItemData[];
  listWidth: ListWidth;
  lineWidth?: string;
  height?: string;
  isOutOfBounds?: boolean;
  closeList: () => void;
  chooseId: (id: string) => void;
};

const PreviewList = ({
  label,
  data,
  height = '240px',
  lineWidth = '4px',
  closeList,
  chooseId,
  listWidth,
  isOutOfBounds,
}: Props) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [searchingValue, setSearchingValue] = useState('');
  const [opacity, setOpacity] = useState(1);

  const ref = useRef<HTMLElement>();

  const setNewSearchingValue = useCallback(
    (value: string) => setSearchingValue(value),
    []
  );

  const filteredData = useMemo(
    () => filterDataForDropdownList(data, searchingValue),
    [data, searchingValue]
  );

  useOutsideClick(listRef, closeList);

  useEffect(() => {
    if (!ref.current) return;

    const refValue = ref.current;
    const onScroll = (e: Event) => {
      const scrollableElement = e.target as HTMLElement;

      // check if user scroll is bigger than the hole scroll height - 10px,
      // if does, we will start continuously hide shadow
      if (
        scrollableElement.scrollTop + scrollableElement.clientHeight >=
        scrollableElement.scrollHeight - 10
      ) {
        setOpacity(
          (scrollableElement.scrollHeight -
            (scrollableElement.scrollTop + scrollableElement.clientHeight)) /
            10
        );
      } else {
        setOpacity(1);
      }
    };

    refValue.addEventListener('scroll', onScroll);

    return () => {
      refValue.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <PreviewContainer
      $width={listsWidth[listWidth]}
      $opacity={opacity}
      $lineWidth={lineWidth}
      ref={listRef}
      $isOutOfBounds={isOutOfBounds}
    >
      <SearchingBlockContainer>
        <SearchingBlock
          setSearchingValue={setNewSearchingValue}
          label={label}
        />
        <ActionButtonContainer>
          <ActionButton
            icon={<ChevronIcon color={theme.colors.funtainBlue} />}
            clickHandler={closeList}
          />
        </ActionButtonContainer>
      </SearchingBlockContainer>
      <SimpleBar
        scrollableNodeProps={{ ref: ref }}
        autoHide={false}
        style={{ height }}
      >
        {filteredData.map(({ displayName, id, disabled }) => (
          <PreviewListItem
            key={id}
            $disabled={disabled}
            onClick={() => {
              if (!disabled) {
                closeList();
                chooseId(id);
              }
            }}
          >
            {displayName}
          </PreviewListItem>
        ))}
      </SimpleBar>
    </PreviewContainer>
  );
};

const PreviewContainer = styled(Container)<{
  $lineWidth: string;
  $opacity: number;
}>`
  padding: 0;
  z-index: 12;

  .simplebar-track.simplebar-vertical {
    width: ${({ $lineWidth }) => $lineWidth};
    top: 19px;
    bottom: 19px;
    background: rgb(235 235 235 / 80%);
    border-radius: 5px;
    right: 10px;
  }

  .simplebar-scrollbar.simplebar-visible {
    width: ${({ $lineWidth }) => $lineWidth};
    background: ${({ theme }) => theme.colors.funtainBlue};
    border-radius: 5px;
  }

  .simplebar-content-wrapper {
    border-radius: 5px;
  }

  .simplebar-scrollbar::before {
    background: unset;
  }

  .simplebar-wrapper::after {
    content: '';
    position: absolute;
    opacity: ${({ $opacity }) => $opacity};
    z-index: 1;
    bottom: -1px;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgb(255 255 255 / 0%),
      rgb(255 255 255 / 100%) 90%
    );
    width: 100%;
    height: 5em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const PreviewListItem = styled(ListItem)`
  margin: 0 !important;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 12px;

  &:hover {
    background-color: rgb(251 251 252);
  }
`;

const SearchingBlockContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  font-size: 14px;

  div {
    padding-left: 10px;
  }
`;

const ActionButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

export default PreviewList;
