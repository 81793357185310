import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';
import SearchIcon from 'components/icons/SearchIcon';
import increaseClickableAreaForHtmlElement from 'helpers/increaseClickableAreaForHtmlElement';
import { FormInput } from 'modules/auth/components/styledElements/formsStyledElements';

const SEARCH_INPUT_ID = 'SEARCH_INPUT_ID';

type SearchFormProps = {
  isHiddenButton?: boolean;
  nameToFilter: string;
  setNewNameToFilter: (value: string) => void;
};

const SearchForm = ({
  nameToFilter,
  isHiddenButton,
  setNewNameToFilter,
}: SearchFormProps) => {
  const [isActiveForm, setIsActiveForm] = useState(false);
  const { register, handleSubmit, setValue, watch } = useForm();
  const searchInputValue = watch()[SEARCH_INPUT_ID];

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(SEARCH_INPUT_ID, value, { shouldValidate: false });
  };

  const formSubmit = () => {
    setIsActiveForm(!isActiveForm);
    if (isActiveForm) {
      setNewNameToFilter(searchInputValue);
    }
  };

  useEffect(() => {
    setValue(SEARCH_INPUT_ID, nameToFilter, { shouldValidate: false });
  }, [nameToFilter, setValue]);

  return (
    <Container>
      <div>
        <FormContainer
          onSubmit={handleSubmit(formSubmit)}
          $isVisible={isActiveForm}
          role="form"
        >
          <FormLabel htmlFor={SEARCH_INPUT_ID}>Search for</FormLabel>
          <StyledFormInput
            id={SEARCH_INPUT_ID}
            type="text"
            {...register(SEARCH_INPUT_ID, {
              required: false,
            })}
            value={searchInputValue ? searchInputValue : ''}
            onChange={changeInputHandler}
          />
        </FormContainer>
        <ActionButtonContainer $isVisible={isActiveForm} type="close">
          <ActionButton
            icon={<CloseIcon color={theme.colors.funtainBlue} />}
            clickHandler={() =>
              setValue(SEARCH_INPUT_ID, '', { shouldValidate: false })
            }
          />
        </ActionButtonContainer>
      </div>
      {!isHiddenButton && (
        <VerticalContainer $isLeft $isVisible={!isActiveForm}>
          <VerticalLine $withOpacity $color={theme.colors.primary} />
        </VerticalContainer>
      )}
      <ActionButtonContainer
        $isVisible
        type="search"
        data-testid="search-button-container"
      >
        <ActionButton
          icon={<SearchIcon color={theme.colors.funtainBlue} />}
          clickHandler={formSubmit}
        />
      </ActionButtonContainer>
      <VerticalContainer $isLeft={false} $isVisible={isActiveForm}>
        <VerticalLine $withOpacity $color={theme.colors.primary} />
      </VerticalContainer>
    </Container>
  );
};

const StyledFormInput = styled(FormInput)`
  width: 84%;
`;

const FormContainer = styled.form<{ $isVisible: boolean }>`
  position: relative;
  display: flex;
  height: 50px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  box-sizing: border-box;
  padding-right: 20px;
  width: 420px;
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: 500px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 330px;
  }
`;

const FormLabel = styled.label`
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  top: -5px;
  cursor: default;
`;

const BUTTON_VERTICAL_RANGE = 8;
const BUTTON_HORIZONTAL_RANGE = 8;

const ActionButtonContainer = styled.div<{
  $isVisible: boolean;
  type: 'close' | 'search';
}>`
  position: absolute;
  top: 50%;
  right: ${({ type }) => (type === 'close' ? '60' : '0')}px;
  transform: translate(0, -50%);
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: ${({ type }) => (type === 'close' ? '45' : '0')}px;
  }

  button {
    ${increaseClickableAreaForHtmlElement(
      BUTTON_VERTICAL_RANGE,
      BUTTON_HORIZONTAL_RANGE
    )}
  }
`;

const Container = styled.div`
  position: relative;
`;

const VerticalContainer = styled.div<{
  $isVisible: boolean;
  $isLeft: boolean;
}>`
  position: absolute;
  top: 50%;
  right: ${({ $isLeft }) => ($isLeft ? '-21px' : '40px')};
  transform: translate(0, -50%);
  transition: all 0.5s;
  visibility: visible;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

  div {
    transform: scale(1.2);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: ${({ $isLeft }) => ($isLeft ? '-10px' : '33px')};
  }
`;

export default SearchForm;
