import { AxiosProgressEvent } from 'axios';
import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import { ERROR_IMAGE_MESSAGE } from 'components/actionModal/forms/uploadOfferPageField/UploadOfferPageField';
import { REMOVE_ERROR_TIMER } from 'components/actionModal/models';
import { StatusBE } from 'components/detailsModal/models';
import getExactTime from 'helpers/getExactTime';
import { CorporateAccount } from 'modules/accounts/accountsServices/CorporateAccountsService';

export type CorporateSettings = {
  includeDepartment: string;
  publicCode: string;
  code: string;
  unitPriceCurrency: string;
  unitPrice: number;
  redemptionLimit: number;
  startDateUTC: Date | null;
  endDateUTC: Date | null;
  offerTerm: string;
  notes?: string;
};

export interface CorporateOffer extends CorporateSettings {
  id: string;
  redemptions: number;
  logoUrl: string;
  backgroundUrl: string;
  status: StatusBE;
  createdDate: string;
  corporateAccountId: string;
  companyName: string;
  corporateAccount: CorporateAccount;
}

interface CorporateOfferDraft extends CorporateSettings {
  corporateAccountId: string;
}

type CreateCorporateOfferDraftPayload = {
  controller: AbortController;
  data: CorporateOfferDraft;
};

export const createCorporateOfferDraft = ({
  data,
  controller,
}: CreateCorporateOfferDraftPayload): Promise<CorporateOffer> => {
  const [hours, minutes, seconds, milliseconds] = getExactTime();

  return axiosClient
    .post(
      `/api/v${config.apiVersionFive}/CorporateOffers/CreateDraft`,
      {
        ...data,
        // set new time to pass validation on BE
        startDateUTC: new Date(
          (data.startDateUTC as Date).setHours(
            hours,
            minutes,
            seconds,
            milliseconds
          )
        ),
      },
      {
        signal: controller.signal,
      }
    )
    .then((response) => response.data.payload.data);
};

type ImageDraftPayload = {
  id: string;
  options: {
    onUploadProgress: (progress: AxiosProgressEvent) => void;
  };
  formData: FormData;
  openError: (value: boolean) => void;
  setErrorText: (value: string) => void;
};

export const uploadLogoDraft = ({
  id,
  options,
  formData,
  openError,
  setErrorText,
}: ImageDraftPayload) =>
  axiosClient
    .put(
      `/api/v${config.apiVersion}/CorporateOffers/${id}/UploadLogoDraft`,
      formData,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => response.data.payload)
    .catch(() => {
      openError(true);
      setErrorText(ERROR_IMAGE_MESSAGE);
      setTimeout(() => {
        openError(false);
      }, REMOVE_ERROR_TIMER);
    });

export const uploadBackgroundDraft = ({
  id,
  options,
  formData,
  openError,
  setErrorText,
}: ImageDraftPayload) =>
  axiosClient
    .put(
      `/api/v${config.apiVersion}/CorporateOffers/${id}/UploadBackgroundDraft`,
      formData,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => response.data.payload)
    .catch(() => {
      openError(true);
      setErrorText(ERROR_IMAGE_MESSAGE);

      setTimeout(() => {
        openError(false);
      }, REMOVE_ERROR_TIMER);
    });

export type PageInfo = {
  page: number;
  pageSize: number;
  sortBy: string;
  search: string;
};

type CorporateOffersPayload = {
  data: PageInfo;
};

export type CorporateOffersResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  items: CorporateOffer[];
};

export const getCorporateOffers = ({
  data,
}: CorporateOffersPayload): Promise<CorporateOffersResponse> => {
  return axiosClient
    .post(`/api/v${config.apiVersion}/CorporateOffers/GetPage`, data)
    .then((response) => response.data.payload.corporateOffers);
};

export type PublishCorporateOfferPayload = {
  id: string;
};

export const publishCorporateOffer = ({ id }: PublishCorporateOfferPayload) =>
  axiosClient
    .put(`/api/v${config.apiVersion}/CorporateOffers/${id}/Publish`, null)
    .then((response) => response.data.payload);

type DeleteCorporateOfferPayload = {
  corporateOfferId: string;
  controller?: AbortController;
};

export const deleteCorporateOffer = ({
  corporateOfferId,
  controller,
}: DeleteCorporateOfferPayload) =>
  axiosClient
    .delete(`/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}`, {
      signal: controller?.signal,
    })
    .then((response) => response.data.payload);

type SuspendCorporateOfferPayload = {
  corporateOfferId: string;
};

export const suspendCorporateOffer = ({
  corporateOfferId,
}: SuspendCorporateOfferPayload) =>
  axiosClient.put(
    `/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}/Suspend`,
    null
  );

type ActivateCorporateOfferPayload = {
  corporateOfferId: string;
};

export const activateCorporateOffer = ({
  corporateOfferId,
}: ActivateCorporateOfferPayload) =>
  axiosClient
    .put(
      `/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}/Activate`,
      null
    )
    .then((response) => response.data.payload);

type CorporateEditSettings = {
  unitPrice: number;
  unitPriceCurrency: string;
  redemptionLimit: number;
  endDateUTC: string | Date | null;
  offerTerm: string;
  isLogoUpdated: boolean;
  isBackgroundUpdated: boolean;
};

export type EditCorporateOfferPayload = {
  corporateOfferId: string;
  data: CorporateEditSettings;
};

export const editCorporateOffer = ({
  corporateOfferId,
  data,
}: EditCorporateOfferPayload) =>
  axiosClient
    .put(`/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}`, data)
    .then((response) => response.data.payload);
