export const parametersForSortingUsersFE = ['A-Z', 'Latest'];

export type ImageArguments = {
  url: string;
  name: string;
};

export type OnboardingOfferItem = {
  id: string;
  background: ImageArguments;
  logo: ImageArguments;
  offerCode: string;
  term: string;
  account: string;
  users: string;
  status: string;
  expiry: string;
};
