import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children?: ReactNode;
  filters?: {
    parameters: string[];
    activeParameter: number;
    setActiveParameter: React.Dispatch<number>;
  };
};

const CategoryContainer = ({ children, filters }: Props) => (
  <>
    {!!filters && (
      <Filters>
        {filters.parameters.map((param, index) => (
          <React.Fragment key={param}>
            {!!index && <FilterDivider />}
            <FilterParam
              $isActive={filters.activeParameter === index}
              onClick={() => filters.setActiveParameter(index)}
            >
              {param}
            </FilterParam>
          </React.Fragment>
        ))}
      </Filters>
    )}
    <Container $hasParams={!!filters}>{children}</Container>
  </>
);

const FilterDivider = styled.div`
  width: 1px;
  opacity: 0.4;
  margin: 8px 0;
  background-color: ${({ theme }) => theme.colors.darkBlue};
`;

const FilterParam = styled.span<{ $isActive?: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  ${({ $isActive }) =>
    $isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.funtainBlue};
      }
    `}
`;

const Filters = styled.div`
  width: min(95%, 1280px);
  margin: 70px auto 0;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
`;

const Container = styled.main<{ $hasParams?: boolean }>`
  width: min(100%, 1326px);
  display: flex;
  margin: ${({ $hasParams }) => ($hasParams ? '0 auto 0' : '40px auto 0')};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: rgb(0 0 0 / 20%) 0 5px 40px;
  border-radius: 5px;
  box-sizing: border-box;
`;

export default CategoryContainer;
