import { passwordStrength } from 'check-password-strength';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import patterns from 'helpers/patterns';

export enum PasswordStatus {
  Weak = 'WEAK',
  Okay = 'OKAY',
  Good = 'GOOD',
  Strong = 'STRONG',
}

enum BarPosition {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT',
}

type PasswordBarsProps = {
  active: boolean;
  passwordValue: string;
};

const PasswordBars = ({ active, passwordValue }: PasswordBarsProps) => {
  const passwordStrengthStatus = passwordStrength<PasswordStatus>(
    passwordValue,
    [
      {
        id: 0,
        value: PasswordStatus.Weak,
        minDiversity: 0,
        minLength: 0,
      },
      {
        id: 1,
        value: PasswordStatus.Okay,
        minDiversity: 1,
        minLength: 2,
      },
      {
        id: 2,
        value: PasswordStatus.Good,
        minDiversity: 2,
        minLength: 9,
      },
      {
        id: 3,
        value: PasswordStatus.Strong,
        minDiversity: 4,
        minLength: 10,
      },
    ]
  ).value;

  const additionalCheckStatus = useMemo(() => {
    const isUpperCaseExist = patterns.passwordUpperCase.test(passwordValue);
    const isNumberExist = patterns.passwordNumberExist.test(passwordValue);

    if (
      (!isUpperCaseExist || !isNumberExist) &&
      (passwordStrengthStatus === PasswordStatus.Good ||
        passwordStrengthStatus === PasswordStatus.Strong)
    ) {
      return PasswordStatus.Okay;
    }

    return passwordStrengthStatus;
  }, [passwordStrengthStatus, passwordValue]);

  return (
    <Container>
      <PasswordBar
        $position={BarPosition.Left}
        $strength={additionalCheckStatus}
        $active={active}
      />
      <PasswordBar
        $position={BarPosition.Center}
        $strength={additionalCheckStatus}
        $active={active}
      />
      <PasswordBar
        $position={BarPosition.Right}
        $strength={additionalCheckStatus}
        $active={active}
      />
      {active && <Strength>Strength: {additionalCheckStatus}</Strength>}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  align-items: center;

  div {
    margin-right: 1px;
  }
`;

const PasswordBar = styled.div<{
  $position: BarPosition;
  $strength: PasswordStatus;
  $active: boolean;
}>`
  width: 40px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.gray};
  ${({ $position, $strength }) =>
    $position === BarPosition.Left &&
    css`
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      background-color: ${({ theme }) =>
        $strength === PasswordStatus.Weak
          ? theme.colors.red
          : theme.colors.secondary};
    `}
  ${({ $position, $strength }) =>
    $position === BarPosition.Right
      ? css`
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        `
      : css`
          background-color: ${({ theme }) =>
            $strength === PasswordStatus.Good && theme.colors.darkYellow};
        `}
  ${({ $strength }) =>
    $strength === PasswordStatus.Strong &&
    css`
      background-color: ${({ theme }) => theme.colors.swamp};
    `}
  ${({ $active }) =>
    !$active &&
    css`
      background-color: ${({ theme }) => theme.colors.gray};
    `}
`;

const Strength = styled.div`
  position: absolute;
  top: 20px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: 400;
  font-size: 11px;
  text-transform: lowercase;
`;

export default PasswordBars;
