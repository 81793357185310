import { StatusBE } from 'components/detailsModal/models';

import { OfferCategory, PromotionOfferResponse } from './models';

const promotionOffersMockData: PromotionOfferResponse[] = [
  {
    id: '0',
    status: StatusBE.Active,
    category: OfferCategory.Promotion,
    bodyText: '',
    title: '',
    subtitle: '',
    promotionTermId: '',
    backgroundColour: 'Sand',
    notes: '',
    publicCode: 'ABABABA',
    promotionTerm: {
      id: '',
      term: '',
      termDescription: '',
    },
    imageUrl: null,
    qrCodeUrl: '',
    redemptions: 0,
    createdDateUtc: '2022-11-17T10:10:28.0219369',
  },
  {
    id: '1',
    status: StatusBE.Active,
    category: OfferCategory.Promotion,
    bodyText: '',
    title: '',
    subtitle: '',
    promotionTermId: '',
    backgroundColour: 'Sand',
    notes: '',
    publicCode: 'UYUYUYUY',
    promotionTerm: {
      id: '',
      term: '',
      termDescription: '',
    },
    imageUrl: null,
    qrCodeUrl: '',
    redemptions: 0,
    createdDateUtc: '2022-11-17T10:10:28.0219369',
  },
  {
    id: '2',
    status: StatusBE.Active,
    category: OfferCategory.Promotion,
    bodyText: '',
    title: '',
    subtitle: '',
    promotionTermId: '',
    backgroundColour: 'Sand',
    notes: '',
    publicCode: 'NFHRRG',
    promotionTerm: {
      id: '',
      term: '',
      termDescription: '',
    },
    imageUrl: null,
    qrCodeUrl: '',
    redemptions: 0,
    createdDateUtc: '2022-11-17T10:10:28.0219369',
  },
  {
    id: '3',
    status: StatusBE.Active,
    category: OfferCategory.Promotion,
    bodyText: '',
    title: '',
    subtitle: '',
    promotionTermId: '',
    backgroundColour: 'Sand',
    notes: '',
    publicCode: 'PEFCDFG',
    promotionTerm: {
      id: '',
      term: '',
      termDescription: '',
    },
    imageUrl: null,
    qrCodeUrl: '',
    redemptions: 0,
    createdDateUtc: '2022-11-17T10:10:28.0219369',
  },
  {
    id: '4',
    status: StatusBE.Active,
    category: OfferCategory.Promotion,
    bodyText: '',
    title: '',
    subtitle: '',
    promotionTermId: '',
    backgroundColour: 'Sand',
    notes: '',
    publicCode: 'MEQAGD',
    promotionTerm: {
      id: '',
      term: '',
      termDescription: '',
    },
    imageUrl: null,
    qrCodeUrl: '',
    redemptions: 0,
    createdDateUtc: '2022-11-17T10:10:28.0219369',
  },
];

export default promotionOffersMockData;
