import React, { useCallback, useContext, useState } from 'react';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar, { NavbarItem } from 'components/actionModal/Navbar';
import CorporateAccountDetails from 'components/actionModal/stages/accountDetails/CorporateAccountDetails';
import PrimaryContact from 'components/actionModal/stages/PrimaryContact';
import Result from 'components/actionModal/stages/Result';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import {
  CorporateAccount,
  CorporateAccountPrimaryContactData,
  editCorporateAccount,
} from 'modules/accounts/accountsServices/CorporateAccountsService';

import { CorporateAccountContext } from '../../context/CorporateAccountContext';
import useEditCorporateAccount from '../../hooks/useEditCorporateAccount';
import useSaveDataForEditCorporateAccount from '../../hooks/useSaveDataForEditCorporateAccount';
import { AccountCategory, AccountModalWindowStages } from '../../models';

const NAVBAR_ITEMS: NavbarItem<AccountModalWindowStages>[] = [
  {
    text: 'Business details',
    name: AccountModalWindowStages.AccountDetails,
  },
  {
    text: 'Primary contact',
    name: AccountModalWindowStages.PrimaryContact,
  },
];

type EditCorporateModalProps = {
  accountForEditing: CorporateAccount;
  closeModalWindow: () => void;
};

const EditCorporateModal = ({
  accountForEditing,
  closeModalWindow,
}: EditCorporateModalProps) => {
  const [saved, setSaved] = useState(false);
  const [completionTitle, setCompletionTitle] = useState('');
  const [activatedStages, setActivatedStages] = useState<
    AccountModalWindowStages[]
  >([AccountModalWindowStages.AccountDetails]);
  const { account, saveAccountDetailsData, savePrimaryContactData, saveData } =
    useContext(CorporateAccountContext);
  const [activeStage, setActiveStage] = useState<AccountModalWindowStages>(
    AccountModalWindowStages.AccountDetails
  );
  const { mutate } = useEditCorporateAccount(
    setCompletionTitle,
    editCorporateAccount
  );

  const toggleNextStage = useCallback((disable?: boolean) => {
    if (disable) {
      setActivatedStages([AccountModalWindowStages.AccountDetails]);
    } else {
      setActivatedStages(NAVBAR_ITEMS.map(({ name }) => name));
    }
  }, []);

  const goToAccountDetailsStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.AccountDetails);
  }, []);

  const goToPrimaryContactStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.PrimaryContact);
    setActivatedStages([
      ...activatedStages,
      AccountModalWindowStages.PrimaryContact,
    ]);
  }, [activatedStages]);

  const goToResultStage = useCallback(
    (primaryContact: CorporateAccountPrimaryContactData) => {
      setActiveStage(AccountModalWindowStages.Result);
      mutate({
        data: {
          ...accountForEditing,
          ...account.accountDetails,
          primaryContact,
        },
      });
    },
    [account.accountDetails, accountForEditing, mutate]
  );

  const changeActiveStage = useCallback((stage: number) => {
    const newActiveStage = NAVBAR_ITEMS[stage].name;

    setActiveStage(newActiveStage);
  }, []);

  useSaveDataForEditCorporateAccount(
    accountForEditing,
    setSaved,
    saveData,
    saved
  );

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {activeStage === AccountModalWindowStages.Result ? (
        <Result closeModal={closeModalWindow} title={completionTitle} />
      ) : (
        <>
          <Navbar
            showTitle
            title="Update account"
            activeStage={activeStage}
            accountCategory={AccountCategory.Corporate}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
            navbarItems={NAVBAR_ITEMS}
          />
          <ActionModalMainContent>
            {activeStage === AccountModalWindowStages.AccountDetails && (
              <CorporateAccountDetails
                accountDetails={account.accountDetails}
                saveAccountDetailsData={saveAccountDetailsData}
                title="Edit business details"
                goToNextStage={goToPrimaryContactStage}
                toggleNextStage={toggleNextStage}
              />
            )}
            {activeStage === AccountModalWindowStages.PrimaryContact && (
              <PrimaryContact
                title="Edit primary contact"
                primaryContact={account.primaryContact}
                goToNextStage={goToResultStage}
                submitButtonTitle="update"
                countryAbbr={account.countryAbbreviation}
                goToPreviousStage={goToAccountDetailsStage}
                savePrimaryContactData={savePrimaryContactData}
              />
            )}
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default EditCorporateModal;
