import React from 'react';
import styled, { css } from 'styled-components';

import Arrow from 'components/icons/Arrow';

import {
  shidoIsTheBestAssistantCarouselDataDesktop,
  shidoIsTheBestAssistantCarouselDataMobile,
} from '../models/data';

import ShidoIsTheBestAssistantCarousel from './ShidoIsTheBestAssistantCarousel';

type Props = {
  disableInterval?: boolean;
  isMobile?: boolean;
  isDesktop?: boolean;
};

const ShidoIsTheBestAssistantSection = ({
  disableInterval,
  isMobile = false,
  isDesktop,
}: Props) => (
  <Container $isMobile={isMobile} $isDesktop={!!isDesktop}>
    <Title $isDesktop={isDesktop} $isMobile={isMobile}>
      What do people say about us?
    </Title>
    <ShidoIsTheBestAssistantCarousel
      disableInterval={disableInterval}
      isMobile={isMobile}
      isDesktop={isDesktop}
      slides={
        isMobile
          ? shidoIsTheBestAssistantCarouselDataMobile
          : shidoIsTheBestAssistantCarouselDataDesktop
      }
    />
    <ArrowWrapper>
      <Arrow />
    </ArrowWrapper>
  </Container>
);

const ArrowWrapper = styled.div`
  margin: auto;
  margin-top: 40px;
`;

const Title = styled.div<{ $isDesktop?: boolean; $isMobile?: boolean }>`
  font-size: 24px;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      font-size: 30px;
    `};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 20px;
    `};
  color: ${({ theme }) => theme.colors.primary};
`;

const Container = styled.div<{ $isDesktop: boolean; $isMobile?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  padding: 77px 0 78px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px dashed rgb(216 218 224);
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      padding: 75px 0 78px;
    `};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 35px 0 39px;
    `};
`;

export default ShidoIsTheBestAssistantSection;
