import React from 'react';

import useAccountsDashboard from 'modules/dashboard/hooks/useAccountsDashboard';

import CategoryContainer from '../../components/CategoryContainer';
import DashboardHeader from '../../components/DashboardHeader';
import DashboardLoader from '../../components/DashboardLoader';

import AccountsContent from './AccountsContent';

const AccountsCategory = () => {
  const { accounts, isLoading, totalCount } = useAccountsDashboard();

  return (
    <>
      <DashboardHeader
        categoryName="Accounts"
        statisticsName={
          totalCount ? `${totalCount} corporate accounts` : 'Loading...'
        }
      />
      <CategoryContainer>
        {isLoading ? (
          <DashboardLoader />
        ) : (
          <AccountsContent accounts={accounts} />
        )}
      </CategoryContainer>
    </>
  );
};

export default AccountsCategory;
