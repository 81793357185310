import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import AffiliateArrow from 'components/icons/AffiliateArror';
import { LogoIcon } from 'components/icons/LogoIcon';
import patterns from 'helpers/patterns';
import { ConversionFunnelBE } from 'modules/offers/models';

import custom from '../previewPagePromotion/custom.png';

import CheckoutSection from './components/CheckoutSection';
import useCreateRefsForClick, {
  handlerRefClick,
} from './useCreateRefsForClick';

type MobilePreviewProps = {
  intro: string;
  image: string | null;
  affiliateStatement: string;
  affiliateName: string;
  bodyText: string;
  affiliateHandle: string;
  chosenColor: {
    light: string;
    strong: string;
  };
  conversionFunnel: ConversionFunnelBE;
};

const MobilePreview = ({
  image,
  intro,
  affiliateHandle,
  affiliateName,
  bodyText,
  affiliateStatement,
  chosenColor,
  conversionFunnel,
}: MobilePreviewProps) => {
  const [activeInput, setActiveInput] = useState(false);
  const [email, setEmail] = useState('');
  const { refs } = useCreateRefsForClick();

  const isCorrectEmail = !!email.match(patterns.emailPattern);

  return (
    <Container
      $color={chosenColor.light}
      data-testid="mobile-preview-container"
    >
      <IntroWrapper>
        <Header>
          <ShidoIconWrapper>
            <LogoIcon width={61} height={37} />
            <HeaderTitle>
              <ShidoText>shido</ShidoText>
              <HeaderMark>®</HeaderMark>
            </HeaderTitle>
          </ShidoIconWrapper>
        </Header>
        <Title>Exclusive offer from {affiliateName}.</Title>
        {affiliateHandle && (
          <TeamSubtitleContainer>
            <TeamSubtitle>{affiliateHandle}</TeamSubtitle>
          </TeamSubtitleContainer>
        )}
        <Template $color={chosenColor.strong} />
        <ImageGraphContainer>
          <ImageInnerContainer>
            <AdditionalImageInner>
              {image ? (
                <img src={image} />
              ) : (
                <LogoIcon width={40} height={20} />
              )}
            </AdditionalImageInner>
          </ImageInnerContainer>
        </ImageGraphContainer>
      </IntroWrapper>

      <TrialWrapper $color={chosenColor.strong}>
        <FreeTrialText>Get your 1 month free trial today!</FreeTrialText>
        <ButtonLink>
          <DefaultButton
            text="continue"
            textColor={theme.colors.white}
            isActive
            withShadow
            buttonColor={theme.colors.funtainBlue}
            isUppercase
            buttonSize="lg"
            borderSize="lg"
          />
        </ButtonLink>
        <ArrowIconWrapper onClick={handlerRefClick(refs[0])}>
          <AffiliateArrow />
        </ArrowIconWrapper>
        <DashedLine />
      </TrialWrapper>
      <Description ref={refs[0]} $color={chosenColor.strong}>
        <div>
          <FirstParagraph>{intro}</FirstParagraph>
          <SecondParagraph>{bodyText}</SecondParagraph>
        </div>
        <CustomImage src={custom} />
        <ArrowIconWrapperDetails onClick={handlerRefClick(refs[1])}>
          <AffiliateArrow />
        </ArrowIconWrapperDetails>
        <DetailsDashedLine />
      </Description>
      <Footer $color={chosenColor.strong}>
        <TitleName ref={refs[1]}>{affiliateName}</TitleName>
        <Subtitle>{affiliateHandle}</Subtitle>
        <ParagraphStatement>{affiliateStatement}</ParagraphStatement>
        <Paragraph>Join Shido today to start a 1 month free trial.</Paragraph>
        <Paragraph>No commitments, cancel anytime.</Paragraph>
        <ImageContainer>
          {image ? <img src={image} /> : <LogoIcon width={40} height={20} />}
        </ImageContainer>
        <ResponseArrowIconWrapper onClick={handlerRefClick(refs[2])}>
          <AffiliateArrow />
        </ResponseArrowIconWrapper>
        {conversionFunnel === ConversionFunnelBE.App ? (
          <>
            <EmailContainer ref={refs[2]}>
              <DashedLine />
              <TitleName>Start your free 1 month trial!</TitleName>
              <SubtitleEmail>
                Enter your email to activate your unique offer code
              </SubtitleEmail>
              <InputContainer>
                <Label $active={activeInput} htmlFor="offer-input">
                  Enter email
                </Label>
                <StyledInput
                  id="offer-input"
                  onFocus={() => setActiveInput(true)}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputContainer>
              <WrapperButton>
                <DefaultButton
                  text="activate code"
                  textColor={theme.colors.white}
                  isActive={isCorrectEmail}
                  withShadow
                  buttonColor={
                    isCorrectEmail
                      ? theme.colors.funtainBlue
                      : theme.colors.darkGrey
                  }
                  isUppercase
                  buttonSize="lg"
                  borderSize="lg"
                />
              </WrapperButton>
            </EmailContainer>
            <FooterShidoIconWrapper>
              <LogoIcon width={48} height={29} />
              <ShidoTitle>
                <ShidoMark>®</ShidoMark>
              </ShidoTitle>
            </FooterShidoIconWrapper>
          </>
        ) : (
          <CheckoutSection ref={refs[2]} isDesktop={false} />
        )}
      </Footer>

      <GraphBackground $color={chosenColor.light} />
    </Container>
  );
};

const ButtonLink = styled.div`
  position: relative;
  z-index: 1;
  margin: 14px auto 4px;
  transition: 0.5s all;
  width: 300px;
  text-decoration: none;
  display: flex;
  justify-content: center;
`;

const IntroWrapper = styled.div`
  position: relative;
`;

const GraphBackground = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/mobileLayout.svg') no-repeat 50% 50%;
  mask-position: bottom -76px right 50%;
`;

const Template = styled.div<{ $color: string }>`
  position: absolute;
  overflow: hidden;
  inset: 0;
  background-color: ${({ $color }) => $color};
  mask: url('/images/promoTemplateMobile.svg') no-repeat 50% 50%;
  mask-position: bottom -73px right 50%;
  mask-size: 375px;
`;

const AdditionalImageInner = styled.div`
  width: 118px;
  height: 118px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  img {
    width: 118px;
    height: 118px;
    object-fit: cover;
  }
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: 0;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -1px;
`;

const EmailContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const Footer = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
`;

const TrialWrapper = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
`;

const TeamSubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TeamSubtitle = styled.p`
  font-size: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pinkLight};
  padding: 5px 20px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: 700;
  margin: 13px 5px 0;
  word-break: break-word;
`;

const SubtitleEmail = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  margin-top: 35px;
  margin-bottom: 38px;
  color: ${({ theme }) => theme.colors.primary};
`;

const WrapperButton = styled.div`
  width: 158px;
  margin: auto;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  outline: none;
  border: none;
  padding-top: 21px;
  box-sizing: border-box;
`;

const Label = styled.label<{ $active: boolean }>`
  font-size: ${({ $active }) => ($active ? '12px' : '16px')};
  color: ${({ theme }) => theme.colors.funtainBlue};
  position: absolute;
  transform: translateY(-50%);
  transition:
    all 0.5s,
    color 0s;
  top: ${({ $active }) => ($active ? '18px' : '50%')};
  left: 19px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 38px auto;
  width: 335px;
  padding-left: 17px;
  padding-right: 6px;
  box-sizing: border-box;
  background: white;
  height: 60px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.funtainBlue};
  padding-bottom: 5px;
`;

const TitleName = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  padding-top: 37px;
  word-break: break-word;
  margin: 0 5px 17px;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-word;
  margin: 0 5px;
`;

const Paragraph = styled.div`
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
`;

const ParagraphStatement = styled(Paragraph)`
  margin-bottom: 22px;
  margin-top: 40px;
  line-height: 21px;
  word-break: break-word;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  overflow: hidden;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 35px auto;
  box-shadow: -1px 1px 10px -4px grey;
  background: white;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const FreeTrialText = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  padding-top: 37px;
  position: relative;
  z-index: 3;
`;

const ImageGraphContainer = styled.div`
  margin: 43px auto 0;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  border: 2px dashed ${({ theme }) => theme.colors.pinkLight};
  border-radius: 50%;
`;

const ImageInnerContainer = styled.div`
  width: 120px;
  height: 120px;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 1px 15px -4px grey;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const HeaderMark = styled.div`
  right: -14px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 0;
`;

const HeaderTitle = styled.div`
  position: relative;
  top: -3px;
  left: 2px;
`;

const Description = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
`;

const CustomImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 36px;
`;

const SecondParagraph = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 300;
  margin-top: 23px;
  line-height: 25px;
  padding-left: 25px;
  padding-right: 21px;
  position: relative;
  right: 2px;
  word-break: break-word;
`;

const FirstParagraph = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  line-height: 30px;
  padding-top: 36px;
  padding-left: 34px;
  padding-right: 35px;
  word-break: break-word;
`;

const DashedLine = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px dashed rgb(216 218 224);
  margin-top: 41px;
`;

const DetailsDashedLine = styled(DashedLine)`
  margin-top: 30px;
`;

const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin: 30px auto 0;
`;

const ResponseArrowIconWrapper = styled(ArrowIconWrapper)`
  margin: 41px auto 0;
`;

const ArrowIconWrapperDetails = styled(ArrowIconWrapper)`
  margin: 0 auto;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-top: 37px;
  line-height: 37px;
  word-break: break-word;
  font-weight: 300;
  padding: 0 10px;
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 39px;
  font-weight: 700;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 17px;
  position: relative;
  left: -8px;
  z-index: 3;
  justify-content: center;
  margin-top: 43px;

  svg {
    left: 2px;
    position: relative;
    top: 2px;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const FooterShidoIconWrapper = styled(ShidoIconWrapper)`
  padding-bottom: 156px;
  left: 7px;
  top: -4px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const Container = styled.div<{ $color: string }>`
  width: 375px;
  margin: 80px auto 78px;
  box-shadow: 0 3px 58px -20px grey;
  padding-top: 135px;
  position: relative;
  overflow: hidden;
  background-color: ${({ $color }) => $color};

  svg.mobile-background {
    position: absolute;
    margin-top: 72px;
  }

  svg.background-graph {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
    width: 749px;
    height: 465px;
  }
`;

export default MobilePreview;
