/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import {
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled, { css } from 'styled-components';
import theme from 'theme';

import Loader from 'components/Loader';
import codeFormKeyDownHandler from 'helpers/codeFormKeyDownHandler';

const FIELDS = ['1', '2', '3', '4', '5', '6'];

enum CodeStatus {
  Pending = 'PENDING',
  Valid = 'VALID',
  Invalid = 'INVALID',
}

const INVALID_CODE_STATUS_DURATION = 1000;
const OPEN_RESEND_CODE_PANEL_TIMER = 60000;

type VerifyCodeFormProps = {
  openResendPanel: () => void;
  closeResendPanel: () => void;
  checkCode: (mobileCode: string) => void;
  setVerifyCodeStatus: (value: CodeStatus) => void;
  setFocus: UseFormSetFocus<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  verifyCodeStatus: CodeStatus;
  isLoaderShown: boolean;
  isLoading: boolean;
};

const VerifyCodeFormTemplate = ({
  isLoading,
  openResendPanel,
  closeResendPanel,
  setVerifyCodeStatus,
  verifyCodeStatus,
  isLoaderShown,
  checkCode,
  setValue,
  setFocus,
  watch,
  register,
}: VerifyCodeFormProps) => {
  const [isAbleToCheckCode, setIsAbleToCheckCode] = useState(false);

  const allFieldsValues = watch();

  const changeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const isNumber = !isNaN(Number(value));

    if ((isNumber || !value) && value !== ' ') {
      setValue(name, value);
    }

    if (index !== FIELDS.length - 1 && value && isNumber && value !== ' ') {
      setFocus(FIELDS[index + 1]);
    }

    if (verifyCodeStatus === CodeStatus.Invalid) {
      setVerifyCodeStatus(CodeStatus.Pending);
    }

    if (!isAbleToCheckCode && verifyCodeStatus !== CodeStatus.Invalid) {
      setIsAbleToCheckCode(true);
    }
  };

  const requestCondition = useMemo(() => {
    return FIELDS.every(
      (field) =>
        allFieldsValues[field] &&
        allFieldsValues[field] !== '' &&
        isAbleToCheckCode
    );
  }, [allFieldsValues, isAbleToCheckCode]);

  useEffect(() => {
    if (!requestCondition) return;

    const values = watch();

    const mobileSignInCode = FIELDS.map((field) => values[field]).join('');

    closeResendPanel();
    setIsAbleToCheckCode(false);
    checkCode(mobileSignInCode);
  }, [watch, checkCode, closeResendPanel, requestCondition]);

  useEffect(() => {
    const removeInvalidCodeStatus = setTimeout(() => {
      if (verifyCodeStatus === CodeStatus.Invalid) {
        setVerifyCodeStatus(CodeStatus.Pending);
      }
    }, INVALID_CODE_STATUS_DURATION);

    return () => {
      clearTimeout(removeInvalidCodeStatus);
    };
  }, [setVerifyCodeStatus, verifyCodeStatus]);

  useEffect(() => {
    const openResendCodePanel = setTimeout(() => {
      openResendPanel();
    }, OPEN_RESEND_CODE_PANEL_TIMER);

    return () => {
      clearTimeout(openResendCodePanel);
    };
  }, [allFieldsValues, openResendPanel]);

  return (
    <>
      <FormContainer>
        {FIELDS.map((field, i) => (
          <FormInput
            data-testid="mobile-code"
            key={field}
            id={field}
            disabled={verifyCodeStatus === CodeStatus.Valid || isLoading}
            type="text"
            autoComplete="off"
            {...register(field, {
              required: false,
            })}
            onKeyDown={(e) =>
              codeFormKeyDownHandler(
                e,
                i,
                setFocus,
                FIELDS,
                allFieldsValues[i + 1]
              )
            }
            $verificationCodeState={verifyCodeStatus}
            maxLength={1}
            onSelect={(e) => e.currentTarget.select()}
            value={!isNaN(allFieldsValues[field]) ? allFieldsValues[field] : ''}
            onChange={(e) => changeInputHandler(e, i)}
          />
        ))}
      </FormContainer>
      {isLoaderShown && (
        <LoaderContainer>
          <Loader
            circles={[
              {
                color: theme.colors.funtainBlue,
                speed: 2,
                size: 50,
              },
            ]}
          />
        </LoaderContainer>
      )}
      {verifyCodeStatus === CodeStatus.Pending && (
        <Notification
          $color={theme.colors.funtainBlue}
          onClick={() => openResendPanel()}
        >
          Didn’t receive your code?
        </Notification>
      )}
      {verifyCodeStatus === CodeStatus.Invalid && (
        <Notification $color={theme.colors.lightRed}>
          Verification unsuccessful
        </Notification>
      )}
      {verifyCodeStatus === CodeStatus.Valid && (
        <Notification $color={theme.colors.swamp}>
          Verification successful
        </Notification>
      )}
    </>
  );
};

const FormContainer = styled.form`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 40px);
  grid-template-rows: 60px;
  column-gap: 20px;
  top: -3px;
`;

const FormInput = styled.input<{ $verificationCodeState: CodeStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 40px;
  text-align: center;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid
    ${({ theme, $verificationCodeState }) =>
      $verificationCodeState === CodeStatus.Invalid
        ? theme.colors.red
        : theme.colors.funtainBlue};
  font-weight: 400;
  border-radius: 4px;
  background: none;
  padding: 0;
  color: ${({ theme, $verificationCodeState }) =>
    $verificationCodeState === CodeStatus.Invalid
      ? theme.colors.red
      : theme.colors.funtainBlue};
  ${({ $verificationCodeState }) =>
    $verificationCodeState === CodeStatus.Valid &&
    css`
      color: ${({ theme }) => theme.colors.swamp};
      border-color: ${({ theme }) => theme.colors.swamp};
    `}
`;

const Notification = styled.div<{ $color: string }>`
  margin: 45px auto 0;
  color: ${({ $color }) => $color};
  font-size: 12px;
  text-align: center;
  width: fit-content;
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 0);
`;

export default VerifyCodeFormTemplate;
