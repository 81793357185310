import React from 'react';

type MobileBackgroundProps = {
  color: string;
};

const MobileBackground = ({ color }: MobileBackgroundProps) => (
  <svg
    className="mobile-background"
    xmlns="http://www.w3.org/2000/svg"
    width="375"
    height="198"
    viewBox="0 0 375 198"
  >
    <path
      data-name="Path 6338"
      d="M-.002 0s73.74 51 188.139 51S374.998 0 374.998 0v198h-375Z"
      fill={color}
    />
  </svg>
);

export default MobileBackground;
