import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ParameterNavigation from 'components/ParameterNavigation';

type ModalNavigationProps = {
  parameters: string[];
  activeStatus: string;
  setActiveStatus: (value: number) => void;
};

const ModalNavigation = ({
  parameters,
  activeStatus,
  setActiveStatus,
}: ModalNavigationProps) => (
  <Container>
    <ParameterNavigation
      radioButtonColor={theme.colors.white}
      parameters={parameters}
      activeParameter={activeStatus}
      setNewParameter={setActiveStatus}
    />
  </Container>
);

const Container = styled.div`
  h4 {
    font-size: 16px;
    margin-bottom: 19px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  }

  div {
    div {
      div {
        margin-right: 8px;
      }
    }
  }
`;

export default React.memo(ModalNavigation);
