import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  background-color: rgb(0 0 0 / 0%);
  border-radius: 5px;
`;

export const SpecialContainer = styled.div`
  width: 66px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.careysPink};
  color: white;
  font-size: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PublishedLabel = styled.div`
  width: 77px;
  height: 20px;
  transition: all 0.5s;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  margin-left: 53px;
  color: white;
  font-size: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  margin-left: auto;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  z-index: 3;

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.lightYellow};
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }
`;

export const Name = styled.div<{ $special?: boolean }>`
  display: flex;
  align-items: center;
  word-break: break-all;
  padding-right: 10px;

  p {
    color: ${({ theme, $special }) =>
      $special ? theme.colors.careysPink : theme.colors.funtainBlue};
    font-weight: 700;
    ${({ $special: special }) =>
      special &&
      css`
        text-transform: uppercase;
      `}
  }
`;

export const Text = styled.p<{
  $withMargin: boolean;
  $special?: boolean;
  $middleUpperCase?: boolean;
}>`
  margin-right: ${({ $withMargin }) => ($withMargin ? '5px' : '0')};
  text-transform: lowercase;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme, $special }) =>
    $special ? theme.colors.careysPink : theme.colors.primary};

  &::first-letter {
    text-transform: uppercase;
  }

  ${({ $middleUpperCase }) =>
    $middleUpperCase &&
    css`
      text-transform: unset;
    `}
`;

export const StyledLink = styled.p<{ special?: boolean }>`
  display: flex;
  align-items: center;
  word-break: break-all;

  a {
    padding-right: 10px;
    text-decoration: none;
    color: ${({ theme, special }) =>
      special ? theme.colors.careysPink : theme.colors.funtainBlue};
    font-weight: 700;
    ${({ special }) =>
      special &&
      css`
        text-transform: uppercase;
      `}
  }
`;
