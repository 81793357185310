import React, { useCallback, useContext, useState } from 'react';

import Navbar from 'components/actionModal/Navbar';
import CorporateAccountDetails from 'components/actionModal/stages/accountDetails/CorporateAccountDetails';
import PrimaryContact from 'components/actionModal/stages/PrimaryContact';
import Result from 'components/actionModal/stages/Result';
import SelectCategory from 'components/actionModal/stages/SelectCategory';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';

import {
  CorporateAccountPrimaryContactData,
  createCorporateAccount,
} from '../../accountsServices/CorporateAccountsService';
import { CorporateAccountContext } from '../../context/CorporateAccountContext';
import useCreateCorporateAccount from '../../hooks/useCreateCorporateAccount';
import { AccountCategory, AccountModalWindowStages } from '../../models';
import { dataForSelectingCategory } from '../CreateAccountModal';

const navbarItemsForCorporate = [
  {
    text: 'Select type',
    name: AccountModalWindowStages.SelectType,
  },
  {
    text: 'Account details',
    name: AccountModalWindowStages.AccountDetails,
  },
  {
    text: 'Primary contact',
    name: AccountModalWindowStages.PrimaryContact,
  },
];

type CreateCorporateModalProps = {
  isNavbarTitleShown: boolean;
  activatedStages: AccountModalWindowStages[];
  closeModalWindow: () => void;
  showNavbarTitle: (value: boolean) => void;
  setActiveCategory: (category: AccountCategory) => void;
  setActivatedStages: (
    stage: AccountModalWindowStages,
    value?: boolean
  ) => void;
};

const CreateCorporateModal = ({
  activatedStages,
  showNavbarTitle,
  closeModalWindow,
  setActiveCategory,
  isNavbarTitleShown,
  setActivatedStages,
}: CreateCorporateModalProps) => {
  const [completionTitle, setCompletionTitle] = useState('');
  const { account, saveAccountDetailsData, savePrimaryContactData } =
    useContext(CorporateAccountContext);
  const [activeStage, setActiveStage] = useState<AccountModalWindowStages>(
    AccountModalWindowStages.SelectType
  );

  const { mutate } = useCreateCorporateAccount(
    setCompletionTitle,
    createCorporateAccount
  );

  const toggleNextStage = useCallback(
    (disable?: boolean) => {
      if (disable) {
        setActivatedStages(AccountModalWindowStages.PrimaryContact, true);
      } else {
        setActivatedStages(AccountModalWindowStages.PrimaryContact);
      }
    },
    [setActivatedStages]
  );

  const changeActiveStage = useCallback(
    (stage: number) => setActiveStage(navbarItemsForCorporate[stage].name),
    []
  );

  const goToSelectCategoryStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.SelectType);
  }, []);

  const goToAccountDetailsStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.AccountDetails);
    setActivatedStages(AccountModalWindowStages.AccountDetails);

    if (!isNavbarTitleShown) {
      showNavbarTitle(true);
    }
  }, [isNavbarTitleShown, setActivatedStages, showNavbarTitle]);

  const goToPrimaryContactStage = useCallback(() => {
    setActiveStage(AccountModalWindowStages.PrimaryContact);
    setActivatedStages(AccountModalWindowStages.PrimaryContact);
  }, [setActivatedStages]);

  const goToResultStage = useCallback(
    (primaryContact: CorporateAccountPrimaryContactData) => {
      setActiveStage(AccountModalWindowStages.Result);
      mutate({
        data: {
          ...account.accountDetails,
          primaryContact,
        },
      });
    },
    [account.accountDetails, mutate]
  );

  return (
    <>
      {activeStage === AccountModalWindowStages.Result ? (
        <Result title={completionTitle} closeModal={closeModalWindow} />
      ) : (
        <>
          <Navbar
            title="Create account"
            activeStage={activeStage}
            showTitle={isNavbarTitleShown}
            accountCategory={AccountCategory.Corporate}
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
            navbarItems={navbarItemsForCorporate}
          />
          <ActionModalMainContent>
            {activeStage === AccountModalWindowStages.SelectType && (
              <SelectCategory
                saveData={setActiveCategory}
                accountCategory={AccountCategory.Corporate}
                categories={dataForSelectingCategory}
                goToNextStage={goToAccountDetailsStage}
              />
            )}
            {activeStage === AccountModalWindowStages.AccountDetails && (
              <CorporateAccountDetails
                title="Account details"
                accountDetails={account.accountDetails}
                goToNextStage={goToPrimaryContactStage}
                goToPreviousStage={goToSelectCategoryStage}
                saveAccountDetailsData={saveAccountDetailsData}
                toggleNextStage={toggleNextStage}
              />
            )}
            {activeStage === AccountModalWindowStages.PrimaryContact && (
              <PrimaryContact
                primaryContact={account.primaryContact}
                title="Primary contact"
                countryAbbr={account.countryAbbreviation}
                goToNextStage={goToResultStage}
                submitButtonTitle="create account"
                goToPreviousStage={goToAccountDetailsStage}
                savePrimaryContactData={savePrimaryContactData}
              />
            )}
          </ActionModalMainContent>
        </>
      )}
    </>
  );
};

export default CreateCorporateModal;
