import React, { useCallback, useState } from 'react';

import SearchBar from 'components/searchBar/SearchBar';
import { STANDARD_PAGE_SIZE } from 'components/table/models';
import TableLoader from 'components/table/TableLoader';
import { cleanOfferLocalStorage } from 'helpers/connectToLocalStorage';
import useGetPromotionOffers from 'modules/offers/hooks/useGetPromotionOffers';
import { getPromotionOffers } from 'modules/offers/offersServices/PromotionOffersService';

import { OfferCategory, OFFERS_SORT_BY_BE } from '../../models';
import CreateOfferModal from '../CreateOfferModal';

import PromotionTable from './PromotionTable';

const PromotionOffers = () => {
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);

  const [nameForSearching, setNameForSearching] = useState('');
  const [sortByListItemIndex, setSortByListItemIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(STANDARD_PAGE_SIZE);

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
    cleanOfferLocalStorage();
  }, [isCreatingModalOpen]);

  const chooseTypeOfSorting = useCallback((index: number) => {
    setPage(1);
    setSortByListItemIndex(index);
  }, []);

  const searchByOfferName = useCallback((name: string) => {
    setPage(1);
    setNameForSearching(name);
  }, []);

  const { data, isLoading } = useGetPromotionOffers(
    [nameForSearching, sortByListItemIndex, page, pageSize],
    () => {
      return getPromotionOffers({
        data: {
          page: page || 1,
          pageSize,
          search: nameForSearching,
          sortBy: OFFERS_SORT_BY_BE[sortByListItemIndex],
        },
      });
    }
  );

  return (
    <>
      <SearchBar
        title="Offers:"
        buttonText="Create offer"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter="Promotion"
        setNewNameToFilter={searchByOfferName}
      />
      {isLoading ? (
        <TableLoader />
      ) : (
        <PromotionTable
          data={data?.items}
          dataAmount={data?.totalCount || 0}
          page={page}
          setPage={setPage}
          activePageSize={pageSize}
          setActivePageSize={setPageSize}
          sortOffers={chooseTypeOfSorting}
          sortByListItemIndex={sortByListItemIndex}
        />
      )}

      {isCreatingModalOpen && (
        <CreateOfferModal
          activeOfferCategory={OfferCategory.Promotion}
          closeModalWindow={toggleCreatingModalWindow}
        />
      )}
    </>
  );
};

export default PromotionOffers;
