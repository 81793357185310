import React from 'react';
import styled, { css } from 'styled-components';

import Arrow from 'components/icons/Arrow';

import custom from '../custom.png';

type DetailsSectionProps = {
  isDesktop?: boolean;
  logoImage: string | null;
  body: string;
  subtitle: string;
};

const DetailsSection = ({
  isDesktop,
  subtitle,
  logoImage,
  body,
}: DetailsSectionProps) => {
  return (
    <Container $isDesktop={isDesktop}>
      <InfoContainer $isDesktop={isDesktop}>
        <ParagraphWrapper $isDesktop={isDesktop}>
          <FirstParagraph $isDesktop={isDesktop}>
            {subtitle
              ? subtitle
              : `Shido is a new app that will change how you approach your life - for
            good.`}
          </FirstParagraph>
          <SecondParagraph $isDesktop={isDesktop}>
            {body
              ? body
              : `Based on the teachings of expert nutritionists, coaches & fitness
            experts, Shido will allow you to transform & live a life free from
            stress & anxiety.`}
          </SecondParagraph>
        </ParagraphWrapper>
        <ImageWrapper $isDesktop={isDesktop}>
          <img src={logoImage ? logoImage : custom} />
        </ImageWrapper>
      </InfoContainer>
      <ArrowWrapper $isDesktop={isDesktop}>
        <Arrow />
      </ArrowWrapper>
    </Container>
  );
};

const ImageWrapper = styled.div<{ $isDesktop?: boolean }>`
  position: relative;
  width: 540px;
  height: 400px;

  img {
    position: absolute;
    height: 510px;
    object-fit: contain;
    top: -40px;
    right: -58px;
    width: 650px;
    ${({ $isDesktop }) =>
      $isDesktop &&
      css`
        width: 660px;
      `}
  }
`;

const ArrowWrapper = styled.div<{ $isDesktop?: boolean }>`
  margin: 19px auto 0;
  text-align: center;
  ${({ $isDesktop }) =>
    !$isDesktop &&
    css`
      margin: 41px auto 0;
    `}
`;

const ParagraphWrapper = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '535px' : '440px')};
  min-height: 400px;
  word-break: break-word;
`;

const FirstParagraph = styled.div<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '30px' : '24px')};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  line-height: 50px;
  top: 13px;
  position: relative;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      top: -9px;
      position: relative;
      left: 6px;
    `}
`;

const SecondParagraph = styled.div<{ $isDesktop?: boolean }>`
  font-size: ${({ $isDesktop }) => ($isDesktop ? '30px' : '24px')};
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 45px;
  font-weight: 300;
  line-height: 50px;
  top: 20px;
  position: relative;
  padding-right: 22px;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      top: 0;
      padding-right: 12px;
      position: relative;
      left: 6px;
    `}
`;

const InfoContainer = styled.div<{ $isDesktop?: boolean }>`
  width: ${({ $isDesktop }) => ($isDesktop ? '92%' : '100%')};
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '1400px' : '1200px')};
  margin: 30px auto 0;
  padding-bottom: 84px;
  border-bottom: 1px dashed rgb(216 218 224);
  position: relative;
  z-index: 3;
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      margin: 36px auto 0;
    `}
`;

export default DetailsSection;
