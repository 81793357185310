import styled from 'styled-components';

import { VerticalLine } from '../../Divider';

export const InformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 303px);
  align-items: center;
  gap: 11px 5px;
  margin: 37px 0 35px;
  color: ${({ theme }) => theme.colors.white};

  @media (height <= 850px) {
    margin: 30px 0;
  }
`;

export const InformationContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  color: ${({ theme }) => theme.colors.white};
`;

export const LinkAnchor = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
`;

export const Header = styled.h4`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const InformationItemAdd = styled.div`
  display: flex;
  align-items: center;

  a {
    top: 1px;
    position: relative;
    left: 7px;
  }
`;

export const TextWrapperBlock = styled.div`
  width: 280px;
  overflow-wrap: break-word;
`;

export const IconWrapperInline = styled.div`
  display: inline;
  margin-right: 5px;
`;

export const InformationItem = styled.div<{
  $withLetterSpacing?: boolean;
  $isSmallFontSize?: boolean;
  $isLowercaseText?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: ${({ $isSmallFontSize }) => ($isSmallFontSize ? '12px' : '14px')};
  font-weight: 300;

  input {
    font-weight: 300;
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
  }

  *:first-child {
    margin-right: 3px;
  }

  p {
    font-size: ${({ $isSmallFontSize }) =>
      $isSmallFontSize ? '12px' : '14px'};
    display: flex;
    align-items: center;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    word-break: break-word;
    letter-spacing: ${({ $withLetterSpacing }) =>
      $withLetterSpacing ? '10px' : '0'};

    &::first-letter {
      text-transform: ${({ $isLowercaseText }) =>
        $isLowercaseText ? 'lowercase' : 'uppercase'};
    }
  }

  span {
    font-size: ${({ $isSmallFontSize }) =>
      $isSmallFontSize ? '12px' : '14px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};

    &::first-letter {
      text-transform: ${({ $isLowercaseText }) =>
        $isLowercaseText ? 'lowercase' : 'uppercase'};
    }
  }
`;

export const ButtonContainer = styled.div<{ $color?: string }>`
  position: absolute;
  transition: all 0.2s !important;
  left: 16px;
  bottom: 0;
  width: 97%;
  padding-bottom: 20px;
  z-index: 3;
  display: flex;
  align-items: center;
  background-color: ${({ $color, theme }) =>
    $color ? $color : theme.colors.funtainBlue};

  div {
    margin-right: 16px;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  justify-content: left;
`;

export const IconQrWrapper = styled(IconWrapper)`
  svg {
    width: 40px;
    height: 40px;

    rect {
      fill: ${({ theme }) => theme.colors.funtainBlue};
    }

    path {
      fill: white;
    }
  }
`;

export const ScaledVerticalLine = styled(VerticalLine)`
  margin: 0 20px;
  height: 21px;
`;

export const ChooseCountry = styled.div`
  align-items: center;
  font-size: 14px;
  display: none;

  img {
    margin-right: 8px !important;
  }

  svg {
    width: 10px;
    height: 6px;
    margin-left: 15px;
  }
`;
