import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '../models';
import { PublishCorporateOfferPayload } from '../offersServices/CorporateOffersService';

const usePublishOffer = (
  setTitle: (title: string) => void,
  mutationFn: ({ id }: PublishCorporateOfferPayload) => Promise<unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      setTitle('Corporate offer published!');
      queryClient.invalidateQueries(QueryKey.GetCorporateOffers);
    },
    onError: (error: Error) => {
      setTitle('Something went wrong :(');
      console.error(error.message);
    },
  });
};

export default usePublishOffer;
