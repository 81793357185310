import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { TableRowGrid } from 'components/table/models';
import { utcToCustomV2 } from 'helpers/utcFormatToCustom';

import { CourseType } from '../../models';
import ContentRow from '../ContentRow';

type Props = {
  isShowButtons: boolean;
  item: CourseType;
  openModalWindow: () => void;
};

const CourseContainerRow = ({
  isShowButtons,
  item,
  openModalWindow,
}: Props) => (
  <Container $isRowModalOpen={false}>
    <ContentRow
      isShowButtons={isShowButtons}
      key={item.id}
      detailsInfo={{
        title: item.title,
      }}
      tableName={TableRowGrid.ContentCourses}
      data={[
        item.description,
        item.lessonNr.toString(),
        utcToCustomV2(new Date(item.appliedDate as number), true),
      ]}
      openModalWindow={openModalWindow}
    />
  </Container>
);

const Container = styled.div<{ $isRowModalOpen?: boolean }>`
  position: relative;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  font-size: 12px;
  overflow: hidden;

  &:nth-child(even) {
    .row:first-child {
      background-color: ${({ theme }) => theme.colors.lightYellow};
    }
  }

  ${({ $isRowModalOpen }) =>
    $isRowModalOpen &&
    css`
      height: 390px;
    `}
`;

export default memo(CourseContainerRow);
