import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import PlayIcon from 'components/icons/PlayIcon';
import ShidoIcon from 'components/icons/ShidoIcon';
import ShidoTextIcon from 'components/icons/ShidoTextIcon';
import { ListItemData } from 'helpers/filterDataForDropdownList';

import FormContainer from './FormContainer';
import {
  BroughtToYou,
  Container,
  EnteringOfferCode,
  Header,
  MainContent,
  Notification,
  PlayButtonContainer,
  RegisterSymbol,
  ShidoLink,
  ShidoSlogan,
} from './StyledElements';

type MobilePreviewProps = {
  isShowDepartment?: boolean;
  data: ListItemData[];
  companyName: string;
  offerTerm: string;
  background?: string;
};

const MobilePreview = ({
  isShowDepartment,
  data,
  companyName,
  offerTerm,
  background,
}: MobilePreviewProps) => (
  <Container $isMobile>
    <MobileContent>
      <MainMobileContent $isMobile $backgroundImg={background}>
        <MobileHeader>
          <ShidoLink>
            <ShidoIcon width={65} height={40} color={theme.colors.white} />
            <ShidoTextIcon width={98} height={32} color={theme.colors.white} />
            <RegisterSymbol $color={theme.colors.white}>®</RegisterSymbol>
          </ShidoLink>
        </MobileHeader>
        <MobileOfferCode>
          <BroughtToYou $isMobile>Brought to you by {companyName}</BroughtToYou>
          <Notification $isMobile>Receive {offerTerm}!</Notification>
          <FormContainer isShowDepartment={isShowDepartment} data={data} />
        </MobileOfferCode>
      </MainMobileContent>
      <ShidoSlogan $isMobile>
        Helping you be your best & achieve optimal health.
      </ShidoSlogan>
      <PlayButtonContainer $size={58}>
        <PlayIcon size={50} />
      </PlayButtonContainer>
    </MobileContent>
  </Container>
);

const MobileHeader = styled(Header)`
  top: 77px;
`;

const MobileOfferCode = styled(EnteringOfferCode)`
  margin-top: 54px;
`;

const MainMobileContent = styled(MainContent)`
  position: relative;
`;

export const MobileContent = styled.div`
  position: relative;
  width: 375px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 20px 40px rgb(0 0 0 / 40%);
  margin: 0 20px;
  padding-bottom: 57px;
  height: fit-content;
  border-radius: 8px;
`;

export default MobilePreview;
