import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorage } from '@rehooks/local-storage';
import DefaultButton from 'components/buttons/DefaultButton';
import { ReferrerAccountDetailsData } from 'modules/accounts/models';

import CountryDropdown from '../../forms/dropdowns/CountryDropdown';
import { referrerAccountAccountDetailsInitialValue } from '../../forms/initialValues';
import MobileInput from '../../forms/inputs/MobileInput';
import StandardInput from '../../forms/inputs/StandardInput';
import { LocalStorageName, InputId } from '../../models';
import { referrerAccountDetailsValidationSchema } from '../../models/validationSchemas';
import {
  FormContainer,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';

type ReferrerAccountDetailsProps = {
  title: string;
  submitButtonTitle: string;
  account?: ReferrerAccountDetailsData;
  goToNextStage: () => void;
  goToPreviousStage?: () => void;
  saveData: (data: ReferrerAccountDetailsData) => void;
};

const ReferrerAccountDetails = ({
  title,
  account,
  goToNextStage,
  goToPreviousStage,
  submitButtonTitle,
}: ReferrerAccountDetailsProps) => {
  const [isMobileValid, setIsMobileValid] = useState(true);
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(referrerAccountDetailsValidationSchema),
  });
  const [isCountryValid, setIsCountryValid] = useState(false);
  const isFormValid = isValid && isCountryValid && isMobileValid;
  const [accountDetails, setAccountDetails] = useLocalStorage(
    LocalStorageName.ReferrerAccountDetails,
    referrerAccountAccountDetailsInitialValue
  );

  const formSubmit = () => {
    goToNextStage();
  };

  const saveFirstName = useCallback(
    () =>
      setAccountDetails({
        ...accountDetails,
        firstName: watch()[InputId.FirstNameInputId],
      }),
    [accountDetails, setAccountDetails, watch]
  );

  const saveLastName = useCallback(
    () =>
      setAccountDetails({
        ...accountDetails,
        lastName: watch()[InputId.LastNameInputId],
      }),
    [accountDetails, setAccountDetails, watch]
  );

  const saveEmail = useCallback(
    () =>
      setAccountDetails({
        ...accountDetails,
        email: watch()[InputId.EmailAddressInputId],
      }),
    [accountDetails, setAccountDetails, watch]
  );

  const saveCountry = useCallback(
    (country: string) => setAccountDetails({ ...accountDetails, country }),
    [accountDetails, setAccountDetails]
  );

  const checkCountryValidation = useCallback(
    (isValid: boolean) => setIsCountryValid(isValid),
    []
  );

  const saveMobile = useCallback(
    (code?: string, countryAbbreviation?: string) => {
      const validNumber = watch()[InputId.MobileNumberInputId].slice(
        code?.length
      );

      setAccountDetails({
        ...accountDetails,
        phone: {
          code: code || '',
          number: validNumber,
          countryAbbreviation:
            countryAbbreviation ||
            accountDetails.phone.countryAbbreviation ||
            '',
        },
      });
    },
    [accountDetails, setAccountDetails, watch]
  );

  useEffect(() => {
    if (account) {
      const { firstName, lastName, email, country, mobile } = account;

      setAccountDetails({
        firstName,
        lastName,
        email,
        country: country.displayName,
        phone: { ...mobile },
      });
    }
  }, [account, setAccountDetails]);

  return (
    <div>
      <Title>{title}</Title>
      <FormContainer onSubmit={handleSubmit(formSubmit)}>
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="First name"
          inputId={InputId.FirstNameInputId}
          saveData={saveFirstName}
          inputValue={accountDetails.firstName}
        />
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="Last name"
          inputId={InputId.LastNameInputId}
          saveData={saveLastName}
          inputValue={accountDetails.lastName}
        />
        <StandardInput
          register={register}
          setValue={setValue}
          errors={errors}
          label="Email address"
          inputId={InputId.EmailAddressInputId}
          saveData={saveEmail}
          inputValue={accountDetails.email}
        />
        <MobileInput
          control={control}
          isMobileValid={isMobileValid}
          watch={watch}
          saveData={saveMobile}
          inputValue={accountDetails.phone.number}
          code={accountDetails.phone.code}
          countryAbbr={accountDetails.phone.countryAbbreviation}
          register={register}
          setValue={setValue}
          errors={errors}
          setIsMobileValid={setIsMobileValid}
          inputId={InputId.MobileNumberInputId}
        />
        <CountryDropdown
          isOutOfBounds
          saveData={saveCountry}
          inputValue={accountDetails.country}
          checkValidation={checkCountryValidation}
        />
        <SubmitButtonContainer>
          {goToPreviousStage && (
            <DefaultButton
              text="back"
              textColor={theme.colors.white}
              isActive
              withShadow
              buttonColor={theme.colors.funtainBlue}
              isUppercase
              buttonSize="md"
              borderSize="md"
              clickHandler={goToPreviousStage}
            />
          )}
          <DefaultButton
            text={submitButtonTitle}
            textColor={theme.colors.white}
            isActive={isFormValid}
            isSubmit
            withShadow
            buttonColor={
              isFormValid ? theme.colors.swamp : theme.colors.quillGray
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
    </div>
  );
};

export default ReferrerAccountDetails;
