import React from 'react';

const AffiliateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.216"
    height="24"
    viewBox="0 0 24.216 24"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 19088"
          d="M13.75-10a4.177 4.177 0 0 1 1.25.193.247.247 0 0 0 .2-.024l1.752-1.051a.25.25 0 0 0 .117-.166 4.23 4.23 0 0 1 4.172-3.436.249.249 0 0 0 .178-.072.252.252 0 0 0 .074-.178V-22.5A1.5 1.5 0 0 0 20-24H1.5A1.5 1.5 0 0 0 0-22.5v12.053a1.459 1.459 0 0 0 .446 1.039A1.459 1.459 0 0 0 1.5-9H3v3.5a.5.5 0 0 0 .3.456.5.5 0 0 0 .537-.082L8.19-9h2.766a.247.247 0 0 0 .156-.055A4.2 4.2 0 0 1 13.75-10Z"
          fill="#69b0c7"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Path 19090"
          d="M21.25-5.5a2.734 2.734 0 0 0-2.166 1.076l-2.612-1.045c.009-.094.028-.185.028-.281a2.741 2.741 0 0 0-.1-.712l2.974-1.788a2.858 2.858 0 0 0 3.76.181 2.858 2.858 0 0 0 .677-3.7 2.858 2.858 0 0 0-3.581-1.161 2.858 2.858 0 0 0-1.625 3.4l-2.979 1.78a2.752 2.752 0 0 0-3.079-.485 2.752 2.752 0 0 0-1.557 2.7 2.752 2.752 0 0 0 1.962 2.422 2.752 2.752 0 0 0 2.964-.962l2.612 1.045c-.009.094-.028.185-.028.281A2.75 2.75 0 0 0 21.25 0 2.75 2.75 0 0 0 24-2.75a2.75 2.75 0 0 0-2.75-2.75Z"
          fill="#69b0c7"
        />
      </clipPath>
    </defs>
    <g data-name="Group 56569">
      <g data-name="Group 56570" clipPath="url(#a)" transform="translate(0 24)">
        <path data-name="Path 19087" d="M-5-29h31.493V0H-5Z" fill="#69b0c7" />
      </g>
      <g data-name="Group 56571" clipPath="url(#b)" transform="translate(0 24)">
        <path
          data-name="Path 19089"
          d="M5.981-18.166h23.235V5H5.981Z"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default AffiliateIcon;
