import { range } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DashedCircle from 'components/circles/DashedCircle';
import GraphCircle from 'components/circles/GraphCircle';
import { ItemInfo } from 'modules/insights/models';

const AMOUNT_OF_DASHED_CIRCLES = 3;

type DemographicsGraphsProps = {
  isSectionVisible: boolean;
  resultArray: ItemInfo[];
  openModal: () => void;
};

const DemographicsGraphs = ({
  isSectionVisible,
  resultArray,
  openModal,
}: DemographicsGraphsProps) => {
  const middleIndex = Math.ceil(resultArray.length / 2);

  const firstHalf = resultArray.slice(0, middleIndex);
  const secondHalf = resultArray.slice(middleIndex);

  return (
    <Container>
      <Row>
        {firstHalf.map((item) => (
          <GraphCircle
            key={item.title}
            isSectionVisible={isSectionVisible}
            percent={item.percent}
            title={item.title}
            openModal={openModal}
          />
        ))}
      </Row>
      <Row>
        {secondHalf.map((item) => (
          <GraphCircle
            key={item.title}
            isSectionVisible={isSectionVisible}
            percent={item.percent}
            title={item.title}
            openModal={openModal}
          />
        ))}
      </Row>
      <Circles>
        {range(0, AMOUNT_OF_DASHED_CIRCLES).map((circle: number) => (
          <DashedCircle
            size={40}
            key={circle}
            borderColor={theme.colors.secondary}
          />
        ))}
      </Circles>
    </Container>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column-gap: 100px;
  align-items: center;
`;

const Circles = styled.div`
  position: absolute;
  transform: translate(-50%, 23px);
  display: flex;
  left: 50%;
  column-gap: 282px;
  top: 34%;
  max-width: 680px;
`;

const Container = styled.div`
  max-width: 1146px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
`;

export default DemographicsGraphs;
