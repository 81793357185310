import { filter } from 'lodash';
import React, { createContext, ReactNode, useCallback, useState } from 'react';

import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
} from 'components/detailsModal/models';

import { PromotionOfferResponse } from '../models';
import promotionOffersMockData from '../promotionOfferMockData';

type PromotionOffersContextType = {
  offers: PromotionOfferResponse[];
  deleteOffer: (id: string) => void;
  setNewStatusForOffer: (status: StatusBE, id: string) => void;
};

const initialValue: PromotionOffersContextType = {
  offers: promotionOffersMockData,
  deleteOffer: () => null,
  setNewStatusForOffer: () => null,
};

export const PromotionOffersContext =
  createContext<PromotionOffersContextType>(initialValue);

const PromotionOffersContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [offers, setOffers] = useState(promotionOffersMockData);

  const deleteOffer = useCallback(
    (id: string) => {
      const newOffers = filter(offers, (offer) => offer.id !== id);

      setTimeout(() => setOffers(newOffers), CLOSE_DETAILS_MODAL_TIMER);
    },
    [offers]
  );

  const setNewStatusForOffer = useCallback(
    (status: StatusBE, id: string) => {
      const newOffers: PromotionOfferResponse[] = offers.map((offer) => {
        if (offer.id === id) {
          return { ...offer, status, changedStatusRecently: status };
        }

        return offer;
      });

      setTimeout(() => setOffers(newOffers), CLOSE_DETAILS_MODAL_TIMER);
    },
    [offers]
  );

  return (
    <PromotionOffersContext.Provider
      value={{
        offers,
        deleteOffer,
        setNewStatusForOffer,
      }}
    >
      {children}
    </PromotionOffersContext.Provider>
  );
};

export default PromotionOffersContextProvider;
