import React, { useState } from 'react';

import useOffersDashboard from 'modules/dashboard/hooks/useOffersDashboard';

import CategoryContainer from '../../components/CategoryContainer';
import DashboardHeader from '../../components/DashboardHeader';
import DashboardLoader from '../../components/DashboardLoader';

import OffersContent from './OffersContent';

export enum OffersParam {
  Affiliate = 'Affiliate',
  Corporate = 'Corporate',
  Promotion = 'Promotion',
}

const OFFERS_PARAMS = [
  OffersParam.Corporate,
  OffersParam.Promotion,
  OffersParam.Affiliate,
];

const OffersCategory = () => {
  const {
    affiliateOffersData,
    corporateOffersData,
    promotionOffersData,
    isLoading,
  } = useOffersDashboard();

  const [activeOfferParam, setActiveOfferParam] = useState(0);

  return (
    <>
      <DashboardHeader
        categoryName="Offers"
        statisticsName={
          isLoading
            ? 'Loading...'
            : {
                title: 'Total offers',
                value: `${corporateOffersData?.totalCount || 0} corporate | ${
                  promotionOffersData?.totalCount || 0
                } promotion | ${
                  affiliateOffersData?.totalCount || 0
                } affiliate`,
              }
        }
      />
      <CategoryContainer
        filters={{
          parameters: OFFERS_PARAMS,
          activeParameter: activeOfferParam,
          setActiveParameter: setActiveOfferParam,
        }}
      >
        {isLoading ? (
          <DashboardLoader />
        ) : (
          <OffersContent
            nowOfferParam={OFFERS_PARAMS[activeOfferParam]}
            affiliateOffers={affiliateOffersData?.items}
            corporateOffers={corporateOffersData?.items}
            promotionOffers={promotionOffersData?.items}
          />
        )}
      </CategoryContainer>
    </>
  );
};

export default OffersCategory;
