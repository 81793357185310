import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import { Action } from 'components/detailsModal/styledElements/detailsModalStyledElements';
import { VerticalLine } from 'components/Divider';
import CloseIcon from 'components/icons/CloseIcon';
import { Device } from 'components/previewPages/models';
import { devices } from 'components/previewPages/PreviewHeader';

type HeaderProps = {
  openPreview: (device: Device) => void;
  closeModal: () => void;
};

const DetailsModalLandingHeader = ({
  openPreview,
  closeModal,
}: HeaderProps) => (
  <Container>
    <Action>
      {devices.map(({ icon, device }) => (
        <React.Fragment key={device}>
          <ActionButton clickHandler={() => openPreview(device)} icon={icon} />
          <Line $withOpacity $color={theme.colors.primary} />
        </React.Fragment>
      ))}
      <VerticalLine $color="rgba(255, 255, 255, 0.2)" />
      <ActionButton
        icon={<CloseIcon color={theme.colors.white} prefix="modal" />}
        clickHandler={closeModal}
      />
    </Action>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled(VerticalLine)`
  margin-top: 25px;
  background-color: white;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 5px 0;
  }

  &:nth-child(6) {
    display: none;
  }
`;

export default React.memo(DetailsModalLandingHeader);
