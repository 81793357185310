import React, { useState } from 'react';
import styled from 'styled-components';

import {
  FormInput,
  FormLabel,
  InputContainerWrapper,
} from './previewPageCorporate/StyledElements';

const StandardPreviewInput = ({ textLabel }: { textLabel: string }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <InputContainerWrapper>
      <FormLabel htmlFor={textLabel} $isActive={isActive}>
        {textLabel}
      </FormLabel>
      <FormInput
        id={textLabel}
        onFocus={() => setIsActive(true)}
        type="text"
        autoComplete="off"
      />
      <OfferCodeHint>* Case sensitive</OfferCodeHint>
    </InputContainerWrapper>
  );
};

const OfferCodeHint = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  bottom: -22px;
  left: 11px;
`;

export default StandardPreviewInput;
