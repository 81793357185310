import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import createErrorMessage from 'helpers/createErrorMessages';

import { EditCorporateAccountPayload } from '../accountsServices/CorporateAccountsService';
import { AccountCategory, QueryKey } from '../models';

const useEditCorporateAccount = (
  setTitle: (title: string) => void,
  mutationFn: ({ data }: EditCorporateAccountPayload) => Promise<unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      setTitle(`${AccountCategory.Corporate} account updated!`);
      queryClient.invalidateQueries(QueryKey.GetCorporateAccounts);
    },
    onError: (error: AxiosError) => {
      const message = createErrorMessage(error);

      setTitle(message);
      console.error(error.message);
    },
  });
};

export default useEditCorporateAccount;
