import { ErrorResponse } from 'components/actionModal/models';

export enum UserCategory {
  All = 'ALL',
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
  Trial = 'TRIAL',
}

export enum UserSubscription {
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
  Trial = 'TRIAL',
}

export enum UserModalWindowStages {
  AccountDetails = 'ACCOUNT_DETAILS',
  GoalsInterests = 'GOALS_INTERESTS',
  AccessCode = 'ACCESS_CODE',
  Result = 'RESULT',
}

export type User = {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  countryAbbreviation: string;
  term?: string;
  createdOn?: Date;
  type: string;
  status: UserStatusBE;
  subscription: UserSubscription;
  joined: string;
  offerCode?: string;
  gender: string;
  timezoneId: string;
  birthYear: number;
  createdDateTime: Date;
};

export type UserTableItem = {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  createdDateTime: Date;
  countryCode?: string;
};

export const parametersPropertyForSortingBE = [
  'email',
  'email',
  'CreatedDateTime',
];

export const parametersForSortingUsersBE = [
  'Ascending',
  'Descending',
  'Descending',
];

export const parametersForSortingUsersFE = ['Email A-Z', 'Email Z-A', 'Latest'];

export enum QueryKey {
  GetUsersAccounts = 'GET_USERS_ACCOUNTS',
}

export const ASTERISKS = '******';

export const editUserErrorResponse: ErrorResponse = {
  MOBILE_TAKEN: 'user/mobile-number-is-already-taken',
  MOBILE_INVALID: 'user/mobile-number-is-invalid',
  EMAIL_TAKEN: 'user/email-is-already-taken',
};

export const registryUserErrorResponse: ErrorResponse = {
  MOBILE_TAKEN: 'registration/mobile-number-is-already-taken',
  MOBILE_INVALID: 'registration/mobile-number-is-invalid',
  EMAIL_TAKEN: 'registration/email-already-taken',
};

export enum UserStatusBE {
  Active = 'Active',
  Disabled = 'Disabled',
}

export enum UserStatusFE {
  Active = 'ACTIVE',
  Disable = 'DISABLE',
  Delete = 'DELETE',
}

export const userStatusesFE = [
  UserStatusFE.Active,
  UserStatusFE.Disable,
  UserStatusFE.Delete,
];
