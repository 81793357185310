import { StatusBE } from 'components/detailsModal/models';

const accountsUsersMockData = [
  {
    id: '1',
    firstName: 'Dan',
    lastName: 'McPeake',
    role: 'Primary account',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '2',
    firstName: 'Guy',
    lastName: 'Southam',
    role: 'viewer',
    job: 'HR executive',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '3',
    firstName: 'Steve',
    lastName: 'Jackson',
    role: 'viewer',
    job: 'Head of Sales',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '4',
    firstName: 'Anthony',
    lastName: 'Joshua',
    role: 'viewer',
    job: 'HR executive',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '5',
    firstName: 'Phil',
    lastName: 'Spencer',
    role: 'viewer',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '6',
    firstName: 'Lochiel',
    lastName: 'Kay',
    role: 'viewer',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '7',
    firstName: 'Leila',
    lastName: 'Rodgers',
    role: 'viewer',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '8',
    firstName: 'Edwin',
    lastName: 'Ulak',
    role: 'viewer',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '9',
    firstName: 'Catherine',
    lastName: 'Miller',
    role: 'viewer',
    job: 'Director of Happiness',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '10',
    firstName: 'Harris',
    lastName: 'Trigger',
    role: 'viewer',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '11',
    firstName: 'Tony',
    lastName: 'Collins',
    role: 'viewer',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '12',
    firstName: 'Suzy',
    lastName: 'Shellerton',
    role: 'editor',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '13',
    firstName: 'Camden',
    lastName: 'Cosworth',
    role: 'editor',
    job: 'HR director',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '14',
    firstName: 'Rachel',
    lastName: 'Massey',
    role: 'editor',
    job: 'CEO',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '15',
    firstName: 'Chris',
    lastName: 'Hooper',
    role: 'editor',
    job: 'CFO',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '16',
    firstName: 'Fiona',
    lastName: 'Fox',
    role: 'editor',
    job: 'HR executive',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '17',
    firstName: 'Chad',
    lastName: 'Cribbins',
    role: 'editor',
    job: 'HR executive',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '18',
    firstName: 'Jason',
    lastName: 'Moorehouse',
    role: 'editor',
    job: 'HR executive',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '19',
    firstName: 'Nick',
    lastName: 'Gawith',
    role: 'editor',
    job: 'Head of Design',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
  {
    id: '20',
    firstName: 'Catherine',
    lastName: 'Miller',
    role: 'editor',
    job: 'HR executive',
    email: 'john@gmail.com',
    mobileNumber: '12345 67890',
    mobileNumberCode: '+93',
    countryAbbreviation: 'AF',
    status: StatusBE.Active,
  },
];

export const accountsRoles = [
  {
    id: '0',
    displayName: 'Primary',
    BEformat: 'primary',
  },
  {
    id: '1',
    displayName: 'Viewer',
    BEformat: 'viewer',
  },
  {
    id: '2',
    displayName: 'Editor',
    BEformat: 'editor',
  },
];

export default accountsUsersMockData;
