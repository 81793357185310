import React, { createContext, useCallback, useState } from 'react';
import styled from 'styled-components';

import EmployersFooter from 'components/EmployersFooter';
import SearchBar from 'components/searchBar/SearchBar';
import { STANDARD_PAGE_SIZE } from 'components/table/models';

import CreateDelegateAccountModal from '../components/CreateDelegateAccountModal';
import DelegatesTable from '../components/DelegatesTable';
import delegatesItemMockData from '../delegatesMockData/delegatesItemMockData';

// temporary implementation, when endpoints will be ready it we be deleted
export const DelegateAccountsContext = createContext<{
  deleteUser: (id: string) => void;
}>({
  deleteUser: () => null,
});

const Delegates = () => {
  const [delegateAccountUsers, setDelegateAccountUsers] = useState(
    delegatesItemMockData
  );
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);
  const [sortByListItemIndex, setSortByListItemIndex] = useState(1);
  const [pageSize, setPageSize] = useState(STANDARD_PAGE_SIZE);
  const [nameForSearching, setNameForSearching] = useState('');

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
  }, [isCreatingModalOpen]);

  const deleteUser = useCallback(
    (id: string) => {
      setDelegateAccountUsers(
        delegateAccountUsers.filter((account) => {
          return account.id !== id;
        })
      );
    },
    [delegateAccountUsers]
  );

  return (
    <Container>
      <SearchBar
        isEmployerPage
        title="ARNOLD CLARK"
        buttonText="Add delegate"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter={nameForSearching}
        setNewNameToFilter={setNameForSearching}
      />
      <DelegateAccountsContext.Provider value={{ deleteUser }}>
        <DelegatesTable
          sortByListItemIndex={sortByListItemIndex}
          sortUsers={setSortByListItemIndex}
          data={delegateAccountUsers}
          pageSize={pageSize}
          changePageSize={setPageSize}
          dataAmount={delegateAccountUsers.length}
        />
      </DelegateAccountsContext.Provider>

      {isCreatingModalOpen && (
        <CreateDelegateAccountModal
          closeModalWindow={toggleCreatingModalWindow}
        />
      )}
      <FooterWrapper>
        <EmployersFooter />
      </FooterWrapper>
    </Container>
  );
};

const FooterWrapper = styled.div`
  margin-top: 74px;
`;

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 20px 60px;
  box-sizing: border-box;
`;

export default Delegates;
