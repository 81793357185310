import config from 'config';
import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import AffiliateDropdown from 'components/actionModal/forms/dropdowns/AffiliateDropdown';
import DefaultButton from 'components/buttons/DefaultButton';
import BrowserIcon from 'components/icons/BrowserIcon';
import { saveExternalSvg } from 'helpers/saveExternalSvg';
import {
  AffiliateReport,
  getAffiliateReport,
} from 'modules/offers/helpers/getAffiliateReport';

import { HorizontalLine } from '../../Divider';
import {
  ButtonContainer,
  Header,
  IconWrapper,
  InformationContainer,
  InformationContainerFlex,
  InformationItem,
  ScaledVerticalLine,
} from '../styledElements/informationStyledElements';

export const AFFILIATE_DOWNLOAD_OPTIONS: DownLoadOption[] = [
  {
    id: '0',
    displayName: 'Affiliate page QR code',
    activeDisplayName: 'QR code',
    saveSVGHandler: saveExternalSvg,
  },
  {
    id: '1',
    displayName: 'Referrals - All (.CSV)',
    activeDisplayName: 'Referrals - All (.CSV)',
    handler: getAffiliateReport(AffiliateReport.REFERRALS),
  },
  {
    id: '2',
    displayName: 'Referrals - Unconverted (.CSV)',
    activeDisplayName: 'Referrals - Unconverted (.CSV)',
    handler: getAffiliateReport(AffiliateReport.UNCONVERTED_REFERRALS),
  },
  {
    id: '3',
    displayName: 'Paid subscribers - This month (.CSV)',
    activeDisplayName: 'Paid subscribers - This month (.CSV)',
    handler: getAffiliateReport(AffiliateReport.CURRENT_MONTH_SUBSCRIBERS),
  },
  {
    id: '4',
    displayName: 'Paid subscribers - All time (.CSV)',
    activeDisplayName: 'Paid subscribers - All time (.CSV)',
    handler: getAffiliateReport(AffiliateReport.SUBSCRIBERS),
  },
  {
    id: '5',
    displayName: 'Paid subscribers - Unsubscribed (.CSV)',
    activeDisplayName: 'Paid subscribers - Unsubscribed (.CSV)',
    handler: getAffiliateReport(AffiliateReport.UNSUBSCRIBED),
  },
];

type Settings = {
  id: string;
  referrals: string;
  subscribers: number;
  publicCode: string;
  qrCodeUrl: string;
  funnel: string;
};

type AffiliateInformationProps = {
  color: string;
  settings: Settings;
};

type DownLoadOption = {
  id: string;
  displayName: string;
  activeDisplayName: string;
  handler?: (affiliateOfferId: string) => Promise<void>;
  saveSVGHandler?: (qrCodeSvgUrl: string, publicCode: string) => Promise<void>;
};

const AffiliateInformation = ({
  color,
  settings: { id, referrals, subscribers, publicCode, qrCodeUrl, funnel },
}: AffiliateInformationProps) => {
  const offerUrl = `${config.shidoUrl}affiliate/${encodeURIComponent(
    publicCode
  )}`;

  const [activeDownloadOption, setActiveDownloadOption] =
    useState<DownLoadOption>(AFFILIATE_DOWNLOAD_OPTIONS[0]);

  const setDownloadOption = (id: string) => {
    setActiveDownloadOption(AFFILIATE_DOWNLOAD_OPTIONS[Number(id)]);
  };

  const handleDownload = () => {
    if (activeDownloadOption.id === '0') {
      activeDownloadOption.saveSVGHandler?.(qrCodeUrl, publicCode);

      return;
    }

    activeDownloadOption.handler?.(id);
  };

  return (
    <>
      <div>
        <InformationContainerFlexAffiliate>
          <AffiliateHeader>Conversion</AffiliateHeader>
          <br />
          <SettingsContainer>
            <AffiliateInformationItem>
              <span>Type:</span> <p>{funnel}</p>
            </AffiliateInformationItem>
            <ScaledVerticalLine $color={theme.colors.white} />
            <AffiliateInformationItem>
              <span>Referrals:</span> <p>{referrals}</p>
            </AffiliateInformationItem>
            <ScaledVerticalLine $color={theme.colors.white} />
            <InformationItem>
              <span>Paid subscribers this month:</span> <p>{subscribers}</p>
            </InformationItem>
          </SettingsContainer>
        </InformationContainerFlexAffiliate>
        <HorizontalLine />
        <ShidoUrlContainer>
          <Header>Offer page</Header>
          <br />
          <InformationItem $isLowercaseText>
            <IconWrapper>
              <BrowserIcon />
            </IconWrapper>
            <ShidoUrl target="_blank" href={offerUrl}>
              www.shido.me/affiliate/{decodeURIComponent(publicCode)}
            </ShidoUrl>
          </InformationItem>
        </ShidoUrlContainer>
        <HorizontalLine />
        <InformationContainer>
          <Header>Download</Header>
          <br />
          <DropdownInputWrapper>
            <AffiliateDropdown
              label="Select"
              options={AFFILIATE_DOWNLOAD_OPTIONS}
              inputValue={activeDownloadOption.activeDisplayName}
              onChange={setDownloadOption}
            />
          </DropdownInputWrapper>
        </InformationContainer>
      </div>
      <ButtonContainer $color={color}>
        <DefaultButton
          text="Download"
          textColor={theme.colors.funtainBlue}
          isActive
          withShadow
          buttonColor={theme.colors.white}
          isUppercase
          buttonSize="md"
          borderSize="md"
          clickHandler={handleDownload}
        />
      </ButtonContainer>
    </>
  );
};

const AffiliateInformationItem = styled(InformationItem)`
  p {
    font-weight: 300;
  }
`;

const DropdownInputWrapper = styled.div`
  margin-top: 27px;
`;

const AffiliateHeader = styled(Header)`
  margin-bottom: -2px;
`;

const InformationContainerFlexAffiliate = styled(InformationContainerFlex)`
  margin: 35px 0;
`;

const ShidoUrlContainer = styled(InformationContainer)`
  grid-template-columns: repeat(2, auto);
  margin: 38px 0 41px;
`;

const ShidoUrl = styled.a`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
`;

const SettingsContainer = styled.div`
  display: flex;
`;

export default AffiliateInformation;
