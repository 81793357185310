import React from 'react';

type ShowPasswordIconProps = {
  color: string;
};

const ShowPasswordIcon = ({ color }: ShowPasswordIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.991"
    height="14.801"
    viewBox="0 0 23.991 14.801"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_8741"
          data-name="Path 8741"
          d="M23.432-9.476C20.787-12.386,16.4-15.462,12-15.4,7.6-15.463,3.213-12.385.568-9.476a2.211,2.211,0,0,0,0,2.948C3.182-3.649,7.507-.6,11.839-.6h.308c4.347,0,8.671-3.049,11.288-5.929A2.21,2.21,0,0,0,23.432-9.476ZM7.4-8A4.6,4.6,0,0,1,12-12.6,4.6,4.6,0,0,1,16.6-8,4.6,4.6,0,0,1,12-3.4,4.6,4.6,0,0,1,7.4-8Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_8743"
          data-name="Path 8743"
          d="M12-6a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0,12-6Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_10941"
      data-name="Group 10941"
      transform="translate(-0.005 15.401)"
    >
      <g id="Group_10939" data-name="Group 10939" clipPath="url(#clip-path)">
        <path
          id="Path_8740"
          data-name="Path 8740"
          d="M-5-20.4H29V4.4H-5Z"
          fill={color}
        />
      </g>
      <g id="Group_10940" data-name="Group 10940" clipPath="url(#clip-path-2)">
        <path
          id="Path_8742"
          data-name="Path 8742"
          d="M5-15H19V-1H5Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default ShowPasswordIcon;
