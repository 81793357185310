import React from 'react';

const GraphBackgroundIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <g data-name="Rectangle 2505" fill="#fbfbfc" stroke="#dfdfda">
      <rect width="100%" height="90px" rx="4" stroke="none" />
      <rect x=".5" y=".5" width="99.7%" height="89px" rx="3.5" fill="none" />
    </g>
    <path
      id="vertical-line-1"
      data-name="Line 554"
      fill="none"
      stroke="#dfdfda"
      strokeDasharray="4 4"
      d="M58 3v87"
    />
    <path
      id="vertical-line-2"
      data-name="Line 555"
      fill="none"
      stroke="#dfdfda"
      strokeDasharray="4 4"
      d="M116 3v87"
    />
    <path
      id="vertical-line-3"
      data-name="Line 556"
      fill="none"
      stroke="#dfdfda"
      strokeDasharray="4 4"
      d="M174 3v87"
    />
    <path
      id="vertical-line-4"
      data-name="Line 557"
      fill="none"
      stroke="#dfdfda"
      strokeDasharray="4 4"
      d="M232 3v87"
    />
    <path
      id="vertical-line-5"
      data-name="Line 558"
      fill="none"
      stroke="#dfdfda"
      strokeDasharray="4 4"
      d="M290 3v87"
    />
  </svg>
);

export default GraphBackgroundIcon;
