import React, { useCallback, useMemo, useRef, useState } from 'react';
import theme from 'theme';

import { ListItem } from 'components/actionModal/styledElements/stagesStyledElements';
import ActionButton from 'components/buttons/ActionButton';
import ChevronIcon from 'components/icons/ChevronIcon';
import filterDataForDropdownList, {
  ListItemData,
} from 'helpers/filterDataForDropdownList';
import useOutsideClick from 'hooks/useOutsideClick';

import { ListHeight, listsHeight, listsWidth, ListWidth } from '../models';
import SearchingBlock from '../SearchingBlock';
import {
  ActionButtonContainer,
  Container,
  ListWrapper,
} from '../styledElements/listStyledElements';

type ListForSelectingDataFEProps = {
  label: string;
  data: ListItemData[];
  listWidth: ListWidth;
  listHeight: ListHeight;
  isOutOfBounds?: boolean;
  closeList: () => void;
  setNewActiveListItem: (index: string) => void;
};

// this component works with FE data
// FE and BE lists have some differences in logic
const ListForSelectingDataFE = ({
  label,
  data,
  closeList,
  listWidth,
  listHeight,
  isOutOfBounds,
  setNewActiveListItem,
}: ListForSelectingDataFEProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [searchingValue, setSearchingValue] = useState('');

  const setNewSearchingValue = useCallback(
    (value: string) => setSearchingValue(value),
    []
  );

  const filteredData = useMemo(
    () => filterDataForDropdownList(data, searchingValue),
    [data, searchingValue]
  );

  useOutsideClick(listRef, closeList);

  return (
    <Container
      $width={listsWidth[listWidth]}
      ref={listRef}
      $isOutOfBounds={isOutOfBounds}
    >
      <SearchingBlock setSearchingValue={setNewSearchingValue} label={label} />
      <ActionButtonContainer>
        <ActionButton
          icon={<ChevronIcon color={theme.colors.funtainBlue} />}
          clickHandler={closeList}
        />
      </ActionButtonContainer>
      <ListWrapper
        className="list-wrapper"
        data-testid="data-list-wrapper"
        $isOutOfBounds={isOutOfBounds}
        $height={listsHeight[listHeight]}
      >
        {filteredData.map(({ displayName, id, icon, disabled }) => (
          <ListItem
            key={id}
            data-testid="data-list-wrapper-item"
            $disabled={disabled}
            onClick={() => {
              if (!disabled) {
                setNewActiveListItem(id);
                closeList();
              }
            }}
          >
            {icon && <img src={icon} alt={displayName} />}
            {displayName}
          </ListItem>
        ))}
      </ListWrapper>
    </Container>
  );
};

export default ListForSelectingDataFE;
