import React, { useState, useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from 'components/actionModal/ActionModal';
import Navbar from 'components/actionModal/Navbar';
import AccessCode, {
  accessCodeExpiresItems,
} from 'components/actionModal/stages/AccessCode';
import UserAccountDetails from 'components/actionModal/stages/accountDetails/UserAccountDetails';
import Result from 'components/actionModal/stages/Result';
import { ActionModalMainContent } from 'components/actionModal/styledElements/stagesStyledElements';
import setInputError from 'helpers/setInputError';

import { UserAccountContext } from '../context/userAccountContext';
import useChangeAccessCode from '../hooks/useChangeAccessCode';
import {
  registryUserErrorResponse,
  QueryKey,
  UserModalWindowStages,
  ASTERISKS,
  editUserErrorResponse,
} from '../models';
import {
  changeUserAccessCode,
  createUserDetails,
  editUserDetails,
  UserAccountAttributes,
} from '../usersServices/usersAccountsService';

const USER_CREATED = 'User account created!';
const ACCESS_CODE_APPLIED = 'Access code applied!';

const navbarItemsForCorporate = [
  {
    text: 'Account details',
    name: UserModalWindowStages.AccountDetails,
  },
  {
    text: 'Access code',
    name: UserModalWindowStages.AccessCode,
  },
];

type CreateUserModalProps = {
  closeModalWindow: () => void;
};

const CreateUserModal = ({ closeModalWindow }: CreateUserModalProps) => {
  const queryClient = useQueryClient();
  const [userId, setUserId] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState({ input: '', message: '' });
  const [isUserCreated, setIsUserCreated] = useState(false);

  const [accessExpires, setAccessExpires] = useState('');
  const [activeMonth, setActiveMonth] = useState(accessCodeExpiresItems[0].id);
  const [isConfirm, setIsConfirm] = useState(false);

  const toggleConfirm = () => setIsConfirm((prev) => !prev);

  const [activatedStages, setActivatedStages] = useState([
    UserModalWindowStages.AccountDetails,
  ]);

  const { saveAccountDetailsData, user: userEditing } =
    useContext(UserAccountContext);

  const [activeStage, setActiveStage] = useState<UserModalWindowStages>(
    UserModalWindowStages.AccountDetails
  );

  const changeActiveStage = useCallback((stage: number) => {
    setActiveStage(navbarItemsForCorporate[stage].name);
  }, []);

  const addNewStageToUser = useCallback(
    (stage: UserModalWindowStages) => {
      if (!activatedStages.includes(stage)) {
        setActivatedStages([...activatedStages, stage]);
      }
    },
    [activatedStages]
  );

  const goToAccountAccessCodeStage = useCallback(() => {
    addNewStageToUser(UserModalWindowStages.AccessCode);
    setActiveStage(UserModalWindowStages.AccessCode);
  }, [setActiveStage, addNewStageToUser]);

  const goToResultStage = useCallback(() => {
    setActiveStage(UserModalWindowStages.Result);
  }, [setActiveStage]);

  const goToNextStep = useCallback(() => {
    setResult(USER_CREATED);
    goToResultStage();
  }, [goToResultStage]);

  const goToFinalStep = useCallback(
    (skip?: boolean) => {
      if (skip) {
        closeModalWindow();
      } else {
        setResult(ACCESS_CODE_APPLIED);
        goToResultStage();
      }
    },
    [closeModalWindow, goToResultStage]
  );

  const goToNextStage = useCallback(() => {
    if (result === USER_CREATED) {
      goToAccountAccessCodeStage();
    } else if (result === ACCESS_CODE_APPLIED) {
      closeModalWindow();
    }
  }, [closeModalWindow, goToAccountAccessCodeStage, result]);

  const { isLoading: createUserDetailsLoading, mutate } = useMutation(
    createUserDetails,
    {
      onSuccess: (data) => {
        if (data.succeeded) {
          goToNextStep();
          disableAccessStage();
          setUserId(data.userId);
          queryClient.invalidateQueries(QueryKey.GetUsersAccounts);
          setIsUserCreated(true);
        } else {
          setInputError({
            setError,
            errorMessage: data.message,
            errorResponse: registryUserErrorResponse,
          });
        }
      },
    }
  );

  const { isLoading: isEditUserDetailsLoading, mutate: editMutate } =
    useMutation(editUserDetails, {
      onSuccess: (data) => {
        if (data.succeeded) {
          goToNextStep();
          disableAccessStage();
          setIsConfirm(false);
          setActiveMonth(accessCodeExpiresItems[0].id);
          queryClient.invalidateQueries(QueryKey.GetUsersAccounts);
        } else {
          setInputError({
            setError,
            errorMessage: data.message,
            errorResponse: editUserErrorResponse,
          });
        }
      },
    });

  const { isLoading: CreateAccessCodeLoading, mutate: createAccessCode } =
    useChangeAccessCode(goToFinalStep, changeUserAccessCode);

  const sendCreateUserRequest = useCallback(
    (userAttr: UserAccountAttributes) => {
      const { email, countryCode, firstName, phoneNumber, passcode } = userAttr;

      setError({ input: '', message: '' });
      saveAccountDetailsData(userAttr);
      setResult('');

      if (isUserCreated) {
        if (passcode === ASTERISKS) {
          editMutate({
            data: { email, countryCode, firstName, phoneNumber },
            id: userId,
          });
        } else {
          editMutate({
            data: {
              email,
              countryCode,
              firstName,
              phoneNumber,
              passcode,
            },
            id: userId,
          });
        }

        return;
      }

      mutate({
        data: {
          email,
          countryCode,
          firstName,
          phoneNumber,
          passcode,
        },
      });
    },
    [editMutate, isUserCreated, mutate, saveAccountDetailsData, userId]
  );

  const sendCreateUserAccessCodeRequest = useCallback(
    (term: string) =>
      createAccessCode({
        id: userId,
        term,
      }),
    [createAccessCode, userId]
  );

  const disableAccessStage = useCallback((value?: boolean) => {
    if (value) {
      setActivatedStages([UserModalWindowStages.AccountDetails]);
    } else {
      setActivatedStages([
        UserModalWindowStages.AccountDetails,
        UserModalWindowStages.AccessCode,
      ]);
    }
  }, []);

  const isLoadingStarted =
    createUserDetailsLoading ||
    CreateAccessCodeLoading ||
    isEditUserDetailsLoading;

  return (
    <ActionModal closeModalWindow={closeModalWindow}>
      {isLoadingStarted || activeStage === UserModalWindowStages.Result ? (
        <Result
          title={isLoadingStarted ? '' : result}
          closeModal={goToNextStage}
        />
      ) : (
        <>
          <Navbar
            title="Create user account"
            showTitle={false}
            activeStage={activeStage}
            accountCategory=""
            activatedStages={activatedStages}
            changeActiveStage={changeActiveStage}
            navbarItems={navbarItemsForCorporate}
          />
          <ActionModalMainContent>
            {activeStage === UserModalWindowStages.AccountDetails && (
              <UserAccountDetails
                errorResponse={error}
                title="Account details"
                goToNextStage={goToNextStep}
                userDetails={userEditing.accountDetails}
                saveAccountDetailsData={saveAccountDetailsData}
                countryAbbreviation={userEditing.countryAbbreviation}
                sendRequest={sendCreateUserRequest}
                disableAccessStage={disableAccessStage}
              />
            )}
            {activeStage === UserModalWindowStages.AccessCode && (
              <AccessCode
                setActiveMonth={setActiveMonth}
                setAccessExpires={setAccessExpires}
                activeMonth={activeMonth}
                accessExpires={accessExpires}
                isConfirm={isConfirm}
                title="Access code"
                isCodeActive={false}
                toggleConfirm={toggleConfirm}
                sendAccessCode={sendCreateUserAccessCodeRequest}
                goToNextStage={goToFinalStep}
              />
            )}
          </ActionModalMainContent>
        </>
      )}
    </ActionModal>
  );
};

export default CreateUserModal;
