/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import HidePasswordIcon from 'components/icons/HidePasswordIcon';
import ShowPasswordIcon from 'components/icons/ShowPasswordIcon';

import PasswordBars from '../components/PasswordBars';
import PasswordSuitability from '../components/PasswordSuitability';
import {
  ContinueButtonContainer,
  FormContainer,
  FormInput,
  FormLabel,
  InputContainer,
} from '../components/styledElements/formsStyledElements';

type AsteriskPasswordFormTemplateProps = {
  isSecondTimeLogin?: boolean;
  inputId: string;
  isValid: boolean;
  isShowPasswordStrength?: boolean;
  goToNextStage: (password: string) => void;
  register: UseFormRegister<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  watch: UseFormWatch<any>;
  forgotPassword?: () => void;
};

const AsteriskPasswordFormTemplate = ({
  isSecondTimeLogin,
  inputId,
  isValid,
  isShowPasswordStrength,
  goToNextStage,
  register,
  handleSubmit,
  watch,
  forgotPassword,
}: AsteriskPasswordFormTemplateProps) => {
  const passwordValue = watch()[inputId] || '';
  const [active, setActive] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  const togglePasswordDisplay = () => {
    setHidePassword(!hidePassword);
    setActive(true);
  };

  return (
    <FormContainer onSubmit={handleSubmit(() => goToNextStage(passwordValue))}>
      <Input>
        <FormLabel
          onFocus={() => setActive(true)}
          htmlFor={inputId}
          $active={active || passwordValue}
          $colored
        >
          Enter {isShowPasswordStrength && (active || passwordValue) && 'new '}
          password
        </FormLabel>

        <AsteriskInputForm
          id={inputId}
          hidden={hidePassword}
          autoComplete="off"
          {...register(inputId)}
          type={hidePassword ? 'password' : 'text'}
          onFocus={() => setActive(true)}
        />

        {isShowPasswordStrength &&
          (hidePassword ? (
            <PasswordSuitability passwordValue={passwordValue} />
          ) : (
            <PasswordBars passwordValue={passwordValue} active={active} />
          ))}

        <EyeButton $hidePassword={hidePassword} onClick={togglePasswordDisplay}>
          {hidePassword ? (
            <HidePasswordIcon color={theme.colors.funtainBlue} />
          ) : (
            <ShowPasswordIcon color={theme.colors.funtainBlue} />
          )}
        </EyeButton>
      </Input>
      <ContinueButtonContainer>
        {forgotPassword && (
          <ForgotText
            onClick={() => {
              forgotPassword && forgotPassword();
            }}
          >
            Forgot your password?
          </ForgotText>
        )}
        <DefaultButton
          isSubmit
          withShadow
          borderSize="lg"
          buttonSize="lg"
          text={isSecondTimeLogin ? 'sign in' : 'continue'}
          isActive={isValid}
          isUppercase
          textColor={theme.colors.white}
          buttonColor={
            isValid ? theme.colors.funtainBlue : theme.colors.quillGray
          }
        />
      </ContinueButtonContainer>
    </FormContainer>
  );
};

const ForgotText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.funtainBlue};
  cursor: pointer;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
`;

export const EyeButton = styled.div<{ $hidePassword?: boolean }>`
  position: absolute;
  right: 0;
  display: block;
  cursor: pointer;
  top: ${({ $hidePassword }) => ($hidePassword ? '42%' : '48%')};
`;

const Input = styled(InputContainer)`
  height: 58px;

  input {
    padding: 0;
    margin-bottom: 6px;
  }

  input[type='password'] {
    font-family: asterisk;
    font-style: normal;
    font-weight: normal;
    speak: none;
    letter-spacing: 4.3px;
    box-sizing: border-box;
    padding-top: 7px;
  }
`;

const AsteriskInputForm = styled(FormInput)`
  width: 94%;
`;

export default AsteriskPasswordFormTemplate;
