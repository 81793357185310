import config from 'config';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { DashedLine } from 'components/Divider';
import ShidoWithSignIcon from 'components/icons/ShidoWithSignIcon';

const EmployersFooter = () => {
  return (
    <Container>
      <ShidoWithSignIcon width={48} height={29} color={theme.colors.primary} />
      <DashedLineContainer>
        <DashedLine />
      </DashedLineContainer>
      <LinkContainer>
        <Link target="_blank" href={config.shidoWebsite}>
          © 2022 Shido Group Ltd
        </Link>
        <Link target="_blank" href={config.supportLink}>
          Support
        </Link>
        <Link target="_blank" href={`${config.shidoWebsite}privacy-policy`}>
          Privacy policy
        </Link>
        <Link
          target="_blank"
          href={`${config.shidoWebsite}terms-and-conditions`}
        >
          Terms and conditions
        </Link>
      </LinkContainer>
    </Container>
  );
};

const DashedLineContainer = styled.div`
  margin-top: 43px;
  width: 660px;
`;

const Link = styled.a`
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

const LinkContainer = styled.div`
  display: flex;
  margin-top: 13px;
  padding-top: 26px;
  column-gap: 50px;
`;

const Container = styled.footer`
  margin-bottom: 80px;
  color: ${({ theme }) => theme.colors.primary};
  display: grid;
  justify-content: center;
  text-align: center;

  div {
    margin-left: auto;
    margin-right: auto;

    span {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.primary};
      top: -1.5px;
      right: -21.5px;
    }
  }

  div:nth-child(1) {
    position: relative;
    left: 4px;
  }
`;

export default EmployersFooter;
