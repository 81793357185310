import React from 'react';

type TabletIconProps = {
  color: string;
};

const TabletIcon = ({ color }: TabletIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.999"
    height="23.999"
    viewBox="0 0 23.999 23.999"
  >
    <defs>
      <clipPath id="tablet-clip-path">
        <path
          id="Path_6924"
          data-name="Path 6924"
          d="M12.5-11.749h-7a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75h7a.75.75,0,0,0,.75-.75.75.75,0,0,0-.75-.75Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="tablet-clip-path-2">
        <path
          id="Path_6926"
          data-name="Path 6926"
          d="M12.5-15.249h-7a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75h7a.75.75,0,0,0,.75-.75.75.75,0,0,0-.75-.75Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="tablet-clip-path-3">
        <path
          id="Path_6928"
          data-name="Path 6928"
          d="M5.5-18.749a.75.75,0,0,0-.75.75.75.75,0,0,0,.75.75H11a.75.75,0,0,0,.75-.75.75.75,0,0,0-.75-.75Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="tablet-clip-path-4">
        <path
          id="Path_6930"
          data-name="Path 6930"
          d="M23.911-1.109,21.78-5.077a.244.244,0,0,1-.03-.118V-9.009c0-1.821-1.323-2.815-2.856-3.966-.254-.191-.521-.392-.8-.607a.251.251,0,0,1-.1-.2V-22.5A1.5,1.5,0,0,0,16.5-24H1.5A1.5,1.5,0,0,0,0-22.5V-4A1.5,1.5,0,0,0,1.5-2.5H15.13a.25.25,0,0,1,.215.122A15.242,15.242,0,0,0,16.939-.252a.751.751,0,0,0,.713.235.751.751,0,0,0,.561-.5.751.751,0,0,0-.153-.735A8.389,8.389,0,0,1,16.25-4V-5a.75.75,0,0,0-.178-.485L14.319-7.556a.731.731,0,0,1,.056-1.017.731.731,0,0,1,1.019.027L17.97-5.97a.75.75,0,0,0,.729.2.75.75,0,0,0,.535-.536.75.75,0,0,0-.2-.729l-.957-.957A.251.251,0,0,1,18-8.164v-3.105a.25.25,0,0,1,.138-.224.25.25,0,0,1,.262.024c1.246.951,1.848,1.528,1.848,2.458v4.065a.745.745,0,0,0,.089.355L22.587-.4A.757.757,0,0,0,23.6-.1.749.749,0,0,0,23.911-1.109Zm-10.4-8.68a2.249,2.249,0,0,0-.536,2.909.25.25,0,0,1,.005.253.25.25,0,0,1-.219.128H3a1,1,0,0,1-1-1V-21a1,1,0,0,1,1-1H15a1,1,0,0,1,1,1v10.65a.251.251,0,0,1-.108.2.251.251,0,0,1-.23.029,2.23,2.23,0,0,0-2.154.327Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g id="Group_6247" data-name="Group 6247" transform="translate(0 24)">
      <g
        id="Group_6243"
        data-name="Group 6243"
        clipPath="url(#tablet-clip-path)"
      >
        <path
          id="Path_6923"
          data-name="Path 6923"
          d="M-.25-16.749h18.5v11.5H-.25Z"
          fill={color}
        />
      </g>
      <g
        id="Group_6244"
        data-name="Group 6244"
        clipPath="url(#tablet-clip-path-2)"
      >
        <path
          id="Path_6925"
          data-name="Path 6925"
          d="M-.25-20.249h18.5v11.5H-.25Z"
          fill={color}
        />
      </g>
      <g
        id="Group_6245"
        data-name="Group 6245"
        clipPath="url(#tablet-clip-path-3)"
      >
        <path
          id="Path_6927"
          data-name="Path 6927"
          d="M-.25-23.749h17v11.5h-17Z"
          fill={color}
        />
      </g>
      <g
        id="Group_6246"
        data-name="Group 6246"
        clipPath="url(#tablet-clip-path-4)"
      >
        <path
          id="Path_6929"
          data-name="Path 6929"
          d="M-5-29H29V5H-5Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default TabletIcon;
