import { find } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sticky from 'react-sticky-el';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import CorporateOfferInformation from 'components/detailsModal/information/CorporateOfferInformation';
import ModalAction from 'components/detailsModal/ModalAction';
import ModalNavigation from 'components/detailsModal/ModalNavigation';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  StatusBE,
  StatusFE,
  statusesFE,
  RequestStatus,
} from 'components/detailsModal/models';
import {
  DetailsModalContainer,
  DetailsModalContent,
} from 'components/detailsModal/styledElements/detailsModalStyledElements';
import SuccessStage from 'components/detailsModal/SuccessStage';
import {
  currencies,
  offerTerms,
} from 'components/listForSelectingData/dataForLists';
import { utcToCustom } from 'helpers/utcFormatToCustom';
import useOutsideClick from 'hooks/useOutsideClick';
import usePrevious from 'hooks/usePrevious';
import useChangeOfferStatus from 'modules/offers/hooks/useChangeOfferStatus';

import {
  deleteCorporateOffer,
  CorporateOffer,
  suspendCorporateOffer,
  activateCorporateOffer,
} from '../../offersServices/CorporateOffersService';

type CorporateDetailsModalProps = {
  offer: CorporateOffer;
  closeWindow: () => void;
  setDataForEditing: (data: CorporateOffer) => void;
  openEditingModalWindow: () => void;
};

const CorporateDetailsModal = ({
  offer,
  closeWindow,
  setDataForEditing,
  openEditingModalWindow,
}: CorporateDetailsModalProps) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();
  const [activeStatus, setActiveStatus] = useState(StatusFE.Active);
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [shouldRender, setRender] = useState(true);
  const previousActiveStatus = usePrevious(activeStatus);
  const modalRef = useRef<HTMLDivElement>(null);

  const deleteOffer = useChangeOfferStatus(
    setSuccessTitle,
    setRequestStatus,
    deleteCorporateOffer
  );
  const suspendOffer = useChangeOfferStatus(
    setSuccessTitle,
    setRequestStatus,
    suspendCorporateOffer
  );
  const activateOffer = useChangeOfferStatus(
    setSuccessTitle,
    setRequestStatus,
    activateCorporateOffer
  );
  const isSuccessStageOpen =
    deleteOffer.isLoading ||
    suspendOffer.isLoading ||
    activateOffer.isLoading ||
    requestStatus;

  const onDelete = useCallback(() => {
    setSuccessTitle('deleted');
    setSuccessText('All pages and assets have been removed');
    deleteOffer.mutate({
      corporateOfferId: offer.id,
    });
  }, [offer.id, deleteOffer]);

  const onSuspend = useCallback(() => {
    setSuccessTitle('suspended');
    setSuccessText('This offer is now expired until reactivated');
    suspendOffer.mutate({
      corporateOfferId: offer.id,
    });
  }, [offer.id, suspendOffer]);

  const onActivate = useCallback(() => {
    setSuccessTitle('activated');
    setSuccessText('This offer has been activated');
    setIsActionConfirmed(false);
    setBackgroundColor('');
    activateOffer.mutate({
      corporateOfferId: offer.id,
    });
  }, [activateOffer, offer.id]);

  const toggleActivateStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);
    setBackgroundColor(
      !isActionConfirmed ? theme.colors.funtainBlue : theme.colors.secondary
    );
  }, [isActionConfirmed]);

  const toggleSuspendStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);
    setBackgroundColor(!isActionConfirmed ? theme.colors.secondary : '');
  }, [isActionConfirmed]);

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else if (offer.status === StatusBE.Suspend) {
      setBackgroundColor(theme.colors.secondary);
    } else {
      setBackgroundColor('');
    }
  }, [isActionConfirmed, offer.status]);

  useOutsideClick(modalRef, () => setRender(false));

  useEffect(() => {
    const { status } = offer;

    if (previousActiveStatus !== activeStatus) {
      setIsActionConfirmed(false);

      if (status !== StatusBE.Suspend) {
        setBackgroundColor('');
      } else if (status === StatusBE.Suspend) {
        setBackgroundColor(theme.colors.secondary);
      }
    }
  }, [activeStatus, offer, previousActiveStatus]);

  useEffect(() => {
    if (requestStatus) {
      setTimeout(() => {
        setRender(false);
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [requestStatus, successTitle]);

  useEffect(() => {
    const { status } = offer;

    if (status === StatusBE.Pending) {
      // create logic for pending status
      setActiveStatus(StatusFE.Active);
    } else if (status === StatusBE.Active) {
      setActiveStatus(StatusFE.Active);
    } else if (status === StatusBE.Draft) {
      setActiveStatus(StatusFE.Active);
    } else {
      setActiveStatus(StatusFE.Suspend);
      setBackgroundColor(theme.colors.secondary);
    }
  }, [offer]);

  const {
    corporateAccount: { country, companyName, website },
    offerTerm,
    status,
    redemptions,
    redemptionLimit,
    endDateUTC,
    code,
    unitPrice,
    unitPriceCurrency,
    publicCode,
    notes,
  } = offer;

  const terms = find(offerTerms, { BEformat: offerTerm })?.displayOfferPageName;
  const unit = `${find(currencies, { BEformat: unitPriceCurrency })
    ?.displaySign}${unitPrice}`;

  return (
    <DetailsModalContainer
      ref={modalRef}
      $shouldRender={shouldRender}
      onAnimationEnd={() => {
        if (!shouldRender) closeWindow();
      }}
    >
      <Sticky
        topOffset={-80}
        stickyStyle={{
          top: 85,
        }}
      >
        <DetailsModalContent $backgroundColor={backgroundColor}>
          {isSuccessStageOpen ? (
            <SuccessStage
              requestStatus={requestStatus}
              name={publicCode}
              text={successText}
              title={successTitle}
            />
          ) : (
            <>
              <Header
                name={publicCode}
                closeModal={() => setRender(false)}
                openEditingModalWindow={() => {
                  openEditingModalWindow();
                  setDataForEditing(offer);
                }}
              />
              <ModalNavigation
                parameters={statusesFE}
                activeStatus={activeStatus}
                setActiveStatus={(index: number) =>
                  setActiveStatus(statusesFE[index])
                }
              />
              {activeStatus === StatusFE.Active && (
                <CorporateOfferInformation
                  color={backgroundColor}
                  closeModal={() => setRender(false)}
                  isChecked={isActionConfirmed}
                  toggleStatus={toggleActivateStatus}
                  offerStatus={status}
                  activateAccount={onActivate}
                  corporateAccountInfo={{
                    country,
                    companyName,
                  }}
                  settings={{
                    terms: terms,
                    redemptions: `${redemptions}  /  ${redemptionLimit}`,
                    unit: unit,
                    expiry: endDateUTC ? utcToCustom(endDateUTC) : 'None',
                    notes,
                  }}
                  offerPageInfo={{
                    url: website,
                    code,
                    publicCode,
                  }}
                />
              )}
              {activeStatus === StatusFE.Suspend &&
                offer.status !== StatusBE.Suspend && (
                  <ModalAction
                    buttonText="suspend"
                    isChecked={isActionConfirmed}
                    color={backgroundColor}
                    submitAction={onSuspend}
                    closeModal={() => setRender(false)}
                    toggleStatus={toggleSuspendStatus}
                    actionTypeText="YES SUSPEND THIS OFFER"
                    note="This offer will expire until reactivated"
                  />
                )}
              {activeStatus === StatusFE.Delete && (
                <ModalAction
                  buttonText="delete"
                  isChecked={isActionConfirmed}
                  color={backgroundColor}
                  submitAction={onDelete}
                  closeModal={() => setRender(false)}
                  toggleStatus={toggleDeleteStatus}
                  actionTypeText="YES DELETE THIS offer"
                  note="Remove all pages and assets"
                />
              )}
            </>
          )}
        </DetailsModalContent>
      </Sticky>
    </DetailsModalContainer>
  );
};

export default CorporateDetailsModal;
