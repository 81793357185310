import { useQuery } from 'react-query';

import { User } from 'modules/users/models';
import {
  getUsers,
  getUserDetails,
} from 'modules/users/usersServices/usersAccountsService';

export type UsersDashboardAdditional = {
  lastUserInfo: User | null;
};

const useUsersDashboard = () => {
  const { data, isLoading } = useQuery('GetDashboardUsers', async () => {
    const response = await getUsers({
      data: {
        pageSize: 1000,
        pageNumber: 1,
        searchQuery: '',
        orderByProperty: 'createdDateTime',
        orderingOption: 'Descending',
      },
    });

    const users = response.items;

    const lastUserDetails = users?.length
      ? await getUserDetails({ id: users[0].id })
      : null;

    return { users, lastUserDetails, totalCount: response.totalCount };
  });

  return {
    users: data?.users,
    additional: {
      lastUserInfo: data?.lastUserDetails || null,
    } as UsersDashboardAdditional,
    totalCount: data?.totalCount,
    isLoading,
  };
};

export default useUsersDashboard;
