const {
  REACT_APP_API_URL,
  REACT_APP_INSIGHTS_KEY,
  REACT_APP_INSIGHTS_ENDPOINT,
  REACT_APP_ENVIRONMENT,
} = process.env;

export default {
  shidoUrl:
    REACT_APP_ENVIRONMENT === 'production'
      ? 'https://shido.me/'
      : 'https://shido-me-dev.azurewebsites.net/',
  shidoWebsite: 'https://shido.com/',
  apiVersion: 4,
  apiVersionFive: 5,
  apiUrl: REACT_APP_API_URL,
  appInsightsKey: REACT_APP_INSIGHTS_KEY,
  appInsightsEndpoint: REACT_APP_INSIGHTS_ENDPOINT,
  contentfulSpaceId: 'm61lhx9gr8sv',
  contentfulAccessToken: 'KY3uhMz5sMlu0JFvOHRWUE63YH-fo5i21_WZwJkBogk',
  appStoreLink: 'https://apps.apple.com/gb/app/shido/id1513137174',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.shido',
  supportLink: 'https://help.shido.com/hc/en-gb/requests/new',
};
