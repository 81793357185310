export type ListHeight = 'sm' | 'md' | 'lg';
export type ListWidth = 'xs' | 'sm' | 'lg';

export const listsHeight = {
  sm: '120px',
  md: '200px',
  lg: '240px',
};

export const listsWidth = {
  xs: '30%',
  sm: '40%',
  lg: '100%',
};
