import React from 'react';

type BackgroundColor = {
  color: string;
};

const BackgroundGraph = ({ color }: BackgroundColor) => (
  <svg
    className="background-graph"
    xmlns="http://www.w3.org/2000/svg"
    width="4431.549"
    height="2749.032"
    viewBox="0 0 4431.549 2749.032"
  >
    <path
      id="Path_15815"
      data-name="Path 15815"
      d="M1839.076,323.422,1208.131-307.522c-116.152-116.153-304.474-116.153-420.629,0s-116.152,304.476,0,420.63l420.722,420.721c116.153,116.153,116.153,304.474,0,420.628s-304.474,116.153-420.629,0L156.561,323.421v0l0,0c-464.614-464.612-1217.9-464.612-1682.515,0L-2156.9,954.365c-116.155,116.153-116.155,304.474,0,420.628s304.473,116.153,420.63,0l420.722-420.721c116.151-116.154,304.475-116.154,420.628,0s116.152,304.474,0,420.628l-631.04,631.035h0c464.616,464.614,1217.9,464.614,1682.515,0h0c464.612,464.614,1217.9,464.614,1682.511,0h0c464.611-464.613,464.611-1217.9,0-1682.512"
      transform="translate(2244.014 394.636)"
      fill={color}
    />
  </svg>
);

export default BackgroundGraph;
