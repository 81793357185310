import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const printPdf = async (publicCode: string) => {
  const canvas = await html2canvas(
    document.getElementsByClassName('download')[0] as HTMLDivElement,
    {
      useCORS: true,
      scale: 1,
      onclone: function (clonedDoc) {
        const items = clonedDoc.getElementsByClassName(
          'download'
        ) as HTMLCollectionOf<HTMLElement>;

        items[0].style.visibility = 'visible';
      },
    }
  );

  const image = canvas.toDataURL('image/jpeg', 1.0);
  const doc = new jsPDF('p', 'px', 'a4');

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const widthRatio = pageWidth / canvas.width;
  const heightRatio = pageHeight / canvas.height;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  const canvasWidth = canvas.width * ratio;
  const canvasHeight = canvas.height * ratio;

  const marginX = (pageWidth - canvasWidth) / 2;
  const marginY = (pageHeight - canvasHeight) / 2;

  doc.addImage(
    image,
    'JPEG',
    marginX,
    marginY,
    canvasWidth,
    canvasHeight,
    '',
    'FAST'
  );
  doc.save(`${publicCode}.pdf`);
};
