import React from 'react';

import useUsersDashboard from 'modules/dashboard/hooks/useUsersDashboard';

import CategoryContainer from '../../components/CategoryContainer';
import DashboardHeader from '../../components/DashboardHeader';
import DashboardLoader from '../../components/DashboardLoader';

import UsersContent from './UsersContent';

const UsersCategory = () => {
  const { users, additional, isLoading, totalCount } = useUsersDashboard();

  return (
    <>
      <DashboardHeader
        categoryName="Users"
        statisticsName={
          totalCount ? `${totalCount} users for all time` : 'Loading...'
        }
      />
      <CategoryContainer>
        {isLoading ? (
          <DashboardLoader />
        ) : (
          <UsersContent users={users} usersAdditional={additional} />
        )}
      </CategoryContainer>
    </>
  );
};

export default UsersCategory;
