import { QueryFunctionContext, useQuery } from 'react-query';

import { QueryKey } from '../models';
import { PromotionOffersResponse } from '../offersServices/PromotionOffersService';

const useGetPromotionOffers = (
  queryKeys: (string | number)[],
  queryFn: (data: QueryFunctionContext) => Promise<PromotionOffersResponse>
) =>
  useQuery([QueryKey.GetPromotionOffers, ...queryKeys], queryFn, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

export default useGetPromotionOffers;
