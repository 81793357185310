export type CustomDate = {
  day: string;
  month: string;
  year: string;
};

export type CustomDateBE = {
  day: number;
  month: number;
  year: number;
};

export enum ValidPeriodVariant {
  ValidPeriodFrom = 'VALID_PERIOD_FROM',
  ValidPeriodUntil = 'VALID_PERIOD_UNTIl',
}
