import React from 'react';
import styled, { css } from 'styled-components';

import { LogoIcon } from 'components/icons/LogoIcon';

type Header = {
  isDesktop?: boolean;
};

const Header = ({ isDesktop }: Header) => {
  return (
    <Container $isDesktop={isDesktop}>
      <ShidoIconWrapper>
        <LogoIcon width={61} height={37} />
        <ShidoTitle>
          <ShidoText>shido</ShidoText>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
    </Container>
  );
};

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: -14px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const ShidoText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 38px;
  font-weight: 700;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  column-gap: 20px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Container = styled.div<{ $isDesktop?: boolean }>`
  max-width: ${({ $isDesktop }) => ($isDesktop ? '1360px' : '1210px')};
  width: ${({ $isDesktop }) => ($isDesktop ? '100%' : '98%')};
  height: 123px;
  margin: 0 auto;
  padding: 40px 5px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(216 218 224);
  ${({ $isDesktop }) =>
    $isDesktop &&
    css`
      padding: 42px 0 0 2px;
    `}
`;

export default Header;
