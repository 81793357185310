import config from 'config';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import {
  appInsights,
  handleRouteChange,
  initializeAppInsights,
  reactPlugin,
} from 'services/AppInsights';

const AppInsightsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    const instrumentationKey = config.appInsightsKey;

    if (!instrumentationKey || !!appInsights) return;

    initializeAppInsights(instrumentationKey);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    handleRouteChange(location.pathname);
  }, [isInitialized, location.pathname]);

  return (
    <AppInsightsErrorBoundary
      appInsights={reactPlugin as ReactPlugin}
      onError={() => <></>}
    >
      <AppInsightsContext.Provider value={reactPlugin as ReactPlugin}>
        {children}
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  );
};

export default AppInsightsContextProvider;
