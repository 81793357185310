import React from 'react';

const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="23.424"
    viewBox="0 0 24 23.424"
  >
    <defs>
      <clipPath id="download-clip-path">
        <path
          id="Path_6902"
          data-name="Path 6902"
          d="M8.616-6.727A1.455,1.455,0,0,0,10.07-5.273h3.392a1.455,1.455,0,0,0,1.453-1.454v-8.228a.25.25,0,0,1,.25-.25h2.9a1.138,1.138,0,0,0,1.14-.7,1.138,1.138,0,0,0-.313-1.3l-6.1-6.095a1.489,1.489,0,0,0-2.056,0l-6.1,6.1a1.137,1.137,0,0,0-.315,1.3,1.137,1.137,0,0,0,1.142.7h2.9a.249.249,0,0,1,.177.073.249.249,0,0,1,.073.177Z"
          fill="#69b0c7"
        />
      </clipPath>
      <clipPath id="download-clip-path-2">
        <path
          id="Path_6904"
          data-name="Path 6904"
          d="M0-4.323A4.039,4.039,0,0,0,4.035-.288h15.93A4.039,4.039,0,0,0,24-4.323V-6.2a1.225,1.225,0,0,0-1.225-1.19A1.225,1.225,0,0,0,21.551-6.2v1.874A1.588,1.588,0,0,1,19.965-2.74H4.035A1.588,1.588,0,0,1,2.449-4.326V-6.2A1.225,1.225,0,0,0,1.224-7.39,1.225,1.225,0,0,0,0-6.2Z"
          fill="#69b0c7"
        />
      </clipPath>
    </defs>
    <g id="Group_6226" data-name="Group 6226" transform="translate(0 23.712)">
      <g
        id="Group_6223"
        data-name="Group 6223"
        clipPath="url(#download-clip-path)"
      >
        <path
          id="Path_6901"
          data-name="Path 6901"
          d="M-.761-28.712H24.291V-.273H-.761Z"
          fill="#69b0c7"
        />
      </g>
      <g
        id="Group_6224"
        data-name="Group 6224"
        clipPath="url(#download-clip-path-2)"
      >
        <path
          id="Path_6903"
          data-name="Path 6903"
          d="M-5-12.39H29v17.1H-5Z"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default DownloadIcon;
