import { find } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import theme from 'theme';

import ActionButton from 'components/buttons/ActionButton';
import DefaultButton from 'components/buttons/DefaultButton';
import RadioButton from 'components/buttons/RadioButton';
import { months } from 'components/calendar/calendarData';
import { Button } from 'components/detailsModal/styledElements/detailsModalStyledElements';
import { DashedLine } from 'components/Divider';
import GarbageIcon from 'components/icons/GarbageIcon';

import {
  FormContainer,
  SubmitButtonContainer,
} from '../styledElements/formsStyledElements';
import { Title } from '../styledElements/stagesStyledElements';

export const accessCodeExpiresItems = [
  {
    id: '1',
    displayName: '1 month',
    BEformat: 'OneMonth',
    months: 1,
  },
  {
    id: '2',
    displayName: '3 months',
    BEformat: 'ThreeMonths',
    months: 3,
  },
  {
    id: '3',
    displayName: '12 months',
    BEformat: 'TwelveMonths',
    months: 12,
  },
  {
    id: '4',
    displayName: 'Never',
    BEformat: 'Never',
  },
];

export type AccessCodeProps = {
  activeMonth: string;
  accessExpires: string;
  isConfirm: boolean;
  isCodeActive?: boolean;
  isEdit?: boolean;
  accessPeriod?: string;
  startDatePeriod?: Date;
  title: string;
  setActiveMonth: (value: string) => void;
  setAccessExpires: (value: string) => void;
  toggleConfirm: () => void;
  sendAccessCode?: (term: string) => void;
  goToNextStage: (skip?: boolean) => void;
  setIsCodeActive?: (isActive: boolean) => void;
  removeAccessCode?: () => void;
};

const AccessCode = ({
  accessExpires,
  activeMonth,
  isConfirm,
  accessPeriod,
  startDatePeriod,
  title,
  isCodeActive,
  setAccessExpires,
  setActiveMonth,
  toggleConfirm,
  sendAccessCode,
  goToNextStage,
  setIsCodeActive,
  removeAccessCode,
}: AccessCodeProps) => {
  const choiceExpires = useCallback(
    (id: string) => setActiveMonth(id),
    [setActiveMonth]
  );

  const deleteExpires = () => {
    if (removeAccessCode) {
      removeAccessCode();
    }
  };

  const formSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (sendAccessCode) {
      sendAccessCode(accessCodeExpiresItems[Number(activeMonth) - 1].BEformat);
    }
  };

  useEffect(() => {
    if (accessPeriod && !accessExpires && startDatePeriod) {
      const monthExpires = accessCodeExpiresItems.find((item) => {
        return item.BEformat === accessPeriod;
      })?.months;

      setIsCodeActive && setIsCodeActive(true);
      if (!monthExpires) {
        setAccessExpires(accessPeriod);

        return;
      }

      const newStartDatePeriod = new Date(startDatePeriod.valueOf());

      newStartDatePeriod.setMonth(startDatePeriod.getMonth() + monthExpires);

      const day = newStartDatePeriod.getDate();
      const month = months[newStartDatePeriod.getMonth()];
      const year = newStartDatePeriod.getFullYear();

      setAccessExpires(`${day} ${month} ${year}`);
    }
  }, [
    accessExpires,
    accessPeriod,
    setAccessExpires,
    setIsCodeActive,
    startDatePeriod,
  ]);

  return (
    <div>
      <Title>{title}</Title>
      <ModifiedFormContainer onSubmit={formSubmit} role="form">
        <ApproveDecisions
          $isConfirm={isConfirm}
          data-testid="approve-decisions"
        >
          <Text>
            <p>
              {isCodeActive
                ? 'ACTIVE CODE'
                : 'ENABLE ACCESS WITHOUT A SUBSCRIPTION'}
            </p>
            <p>
              {isCodeActive
                ? `${find(accessCodeExpiresItems, { BEformat: accessPeriod })
                    ?.displayName}: Expires ${accessExpires}`
                : 'Choose an access period'}
            </p>
          </Text>
          {isCodeActive ? (
            <ActionButton
              clickHandler={deleteExpires}
              icon={<GarbageIcon color={theme.colors.funtainBlue} />}
            />
          ) : (
            <Switch
              onChange={toggleConfirm}
              checked={isConfirm}
              height={30}
              width={52}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
              activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.5)"
            />
          )}
        </ApproveDecisions>
        {isCodeActive && (
          <AccessPeriod>
            <DashedLine />
          </AccessPeriod>
        )}
        {isConfirm && (
          <AccessPeriod data-testid="access-period">
            <DashedLine />
            <Text>
              <p>expires</p>
            </Text>
            {accessCodeExpiresItems.map(({ id, displayName }) => (
              <Wrapper key={id}>
                <RadioButton
                  isActive={activeMonth === id}
                  parameter={displayName}
                  fontSize="sm"
                  textColor={theme.colors.darkBlue}
                  clickHandler={() => choiceExpires(id)}
                />
              </Wrapper>
            ))}
          </AccessPeriod>
        )}
        <SubmitButtonContainer>
          <DefaultButton
            text="APPLY CODE"
            textColor={theme.colors.white}
            isActive={isConfirm}
            isSubmit
            withShadow
            buttonColor={
              isConfirm ? theme.colors.swamp : theme.colors.lightGreyV2
            }
            isUppercase
            buttonSize="md"
            borderSize="md"
          />
          {!isConfirm && (
            <StyledButton
              onClick={() => {
                goToNextStage(true);
              }}
            >
              skip
            </StyledButton>
          )}
        </SubmitButtonContainer>
      </ModifiedFormContainer>
    </div>
  );
};

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.funtainBlue};
`;

const ModifiedFormContainer = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  margin-top: 19px;
`;

const AccessPeriod = styled.div`
  width: 335px;
  margin-top: 15px;

  p:first-child {
    text-transform: uppercase;
    font-size: 10px;
    margin-top: 20px;
  }
`;

const ApproveDecisions = styled.div<{ $isConfirm: boolean }>`
  width: 335px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;

  p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  p:first-child {
    text-transform: uppercase;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }

  p:last-child {
    color: ${({ theme }) => theme.colors.funtainBlue};
  }

  .react-switch-bg {
    background: ${({ theme, $isConfirm }) =>
      $isConfirm
        ? theme.colors.funtainBlue
        : theme.colors.lightGreyV2} !important;
  }
`;

const Text = styled.div`
  width: 280px;
  margin-right: 5px;
  margin-top: 8px;
`;

export default AccessCode;
