import React from 'react';
import styled, { css } from 'styled-components';

import RadioButton from 'components/buttons/RadioButton';

type ParameterNavigationProps = {
  withOffset?: boolean;
  parameters: string[];
  radioButtonColor?: string;
  activeParameter: string;
  setNewParameter: (value: number) => void;
};

const ParameterNavigation = ({
  parameters,
  withOffset,
  radioButtonColor,
  activeParameter,
  setNewParameter,
}: ParameterNavigationProps) => (
  <Container $withOffset={withOffset} data-testid="offer-navigation-container">
    {parameters.map((parameter, index) => {
      const isActive = parameter === activeParameter;

      return (
        <RadioButton
          fontSize="sm"
          key={parameter}
          buttonColor={radioButtonColor}
          parameter={parameter}
          isActive={isActive}
          clickHandler={() => setNewParameter(index)}
        />
      );
    })}
  </Container>
);

const Container = styled.div<{ $withOffset?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $withOffset }) =>
    $withOffset &&
    css`
      position: relative;
      top: -68px;
      left: 20px;
    `}
`;

export default React.memo(ParameterNavigation);
