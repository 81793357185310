import { css } from 'styled-components';

const increaseClickableAreaForHtmlElement = (
  vertical: number,
  horizontal: number
) => css`
  &::before {
    position: absolute;
    content: '';
    inset: -${vertical}px -${horizontal}px -${vertical}px -${horizontal}px;
  }
`;

export default increaseClickableAreaForHtmlElement;
