/* eslint-disable @typescript-eslint/no-explicit-any */
import { findIndex } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  Controller,
  Control,
} from 'react-hook-form';
import { Country, isValidPhoneNumber } from 'react-phone-number-input';
import theme from 'theme';

import { ErrorMessage } from '@hookform/error-message';
import ActionButton from 'components/buttons/ActionButton';
import { VerticalLine } from 'components/Divider';
import ChevronIcon from 'components/icons/ChevronIcon';
import WarningIcon from 'components/icons/WarningIcon';
import countries from 'components/listForSelectingData/dataForList/countries';
import { listLabels } from 'components/listForSelectingData/dataForLists';
import ListForSelectingDataFE from 'components/listForSelectingData/lists/ListForSelectingDataFE';
import {
  ErrorText,
  InputContainer,
} from 'modules/auth/components/styledElements/formsStyledElements';
import Input from 'react-phone-number-input/input';

import {
  DropdownAndInput,
  DropdownAndInputContent,
  DropdownAndInputText,
  FormLabel,
  MobileNumberInput,
} from '../../styledElements/formsStyledElements';

type CountryCode = {
  id: string;
  code: string;
  icon: string;
  abbr: string;
};

type MobileInputProps = {
  control: Control<any>;
  code: string;
  countryAbbr?: string;
  inputId: string;
  inputValue: string;
  errors: FieldErrors;
  isMobileValid: boolean;
  doNotTriggerValidation?: boolean;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  saveData: (countryCode?: string, countryAbbreviation?: string) => void;
  setIsMobileValid: (isValid: boolean) => void;
  setMobileCopy?: () => void;
};

const MobileInput = ({
  setMobileCopy,
  watch,
  doNotTriggerValidation,
  code,
  errors,
  register,
  setValue,
  isMobileValid,
  inputId,
  saveData,
  inputValue,
  countryAbbr,
  setIsMobileValid,
  control,
}: MobileInputProps) => {
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isMobileCodesOpen, setIsMobileCodesOpen] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode>();

  const closeMobileCodesList = useCallback(
    () => setIsMobileCodesOpen(false),
    []
  );

  const setNewCountryCode = useCallback(
    (index: string) => {
      const mobileIndex = Number(index);
      const { id, code, icon, abbr } = countries[mobileIndex];
      const validNumber = watch()[inputId].slice(countryCode?.code?.length);
      const phoneNumber = `${code}${validNumber}`;

      setIsMobileValid(isValidPhoneNumber(phoneNumber));

      setValue(inputId, phoneNumber, {
        shouldValidate: true,
      });

      setCountryCode({
        id,
        code,
        icon,
        abbr,
      });

      saveData(code, abbr);
    },
    [
      watch,
      inputId,
      countryCode?.code?.length,
      setIsMobileValid,
      setValue,
      saveData,
    ]
  );

  useLayoutEffect(() => {
    if (inputValue) {
      const mobileNumber = `${code}${inputValue}`;

      setIsMobileActive(true);
      setIsMobileValid(isValidPhoneNumber(mobileNumber));
      setValue(
        inputId,
        mobileNumber,
        doNotTriggerValidation ? {} : { shouldValidate: true }
      );
    }
  }, [
    code,
    doNotTriggerValidation,
    inputId,
    inputValue,
    setIsMobileValid,
    setValue,
  ]);

  useEffect(() => {
    if (countryAbbr) {
      const countryCodeIndex = findIndex(countries, {
        code: code,
        abbr: countryAbbr,
      });
      const { id, code: countryCode, icon, abbr } = countries[countryCodeIndex];

      setCountryCode({ id, code: countryCode, icon, abbr });
    } else {
      const [{ id, code, icon, abbr }] = countries;

      setCountryCode({ id, code, icon, abbr });
    }
  }, [code, countryAbbr, inputId, inputValue, saveData]);

  return (
    <InputContainer
      $error={!!errors[inputId] || !isMobileValid}
      onClick={() => setIsMobileActive(true)}
    >
      <FormLabel
        htmlFor={inputId}
        $error={!!errors[inputId] || !isMobileValid}
        $active={!!(isMobileActive || inputValue)}
      >
        Mobile number
      </FormLabel>
      <DropdownAndInput>
        {isMobileActive && countryCode && (
          <>
            <DropdownAndInputContent>
              <img src={countryCode.icon} alt={countryCode.abbr} />
              <DropdownAndInputText>{countryCode.code}</DropdownAndInputText>
              <ActionButton
                icon={
                  <ChevronIcon
                    width={10}
                    height={6}
                    color={theme.colors.funtainBlue}
                  />
                }
                clickHandler={() => setIsMobileCodesOpen(true)}
              />
              {isMobileCodesOpen && (
                <ListForSelectingDataFE
                  listWidth="lg"
                  listHeight="sm"
                  data={countries.map((country) => ({
                    id: country.id,
                    icon: country.icon,
                    displayName: `${country.displayName} ${country.code}`,
                  }))}
                  label={listLabels.countryCode}
                  closeList={closeMobileCodesList}
                  setNewActiveListItem={setNewCountryCode}
                />
              )}
            </DropdownAndInputContent>
            <VerticalLine $color={theme.colors.primary} $withOpacity />
          </>
        )}
        <Controller
          name={inputId}
          control={control}
          rules={{
            required: true,
            validate: (value) => isValidPhoneNumber(value),
          }}
          render={({ field: { onChange } }) => (
            <Input
              id={inputId}
              {...register(inputId)}
              inputComponent={MobileNumberInput}
              value={watch()[inputId]}
              country={(countryCode?.abbr || countries[0].abbr) as Country}
              international
              onBlur={() => saveData(code)}
              onFocus={() => setIsMobileActive(true)}
              onChange={(value: string) => {
                if (value) {
                  setIsMobileValid(isValidPhoneNumber(value));
                  onChange(value);
                  setMobileCopy && setMobileCopy();
                }
              }}
            />
          )}
        />
      </DropdownAndInput>
      <ErrorMessage
        name={inputId}
        errors={errors}
        render={({ message }) => (
          <ErrorText $error={!!errors[inputId]}>
            <WarningIcon color={theme.colors.primary} />
            {message}
          </ErrorText>
        )}
      />
      {!isMobileValid && !errors[inputId] && (
        <ErrorText $error>
          <WarningIcon color={theme.colors.primary} />
          Mobile number is invalid
        </ErrorText>
      )}
    </InputContainer>
  );
};

export default React.memo(MobileInput);
