import { useCallback } from 'react';

type useSetFileHandler = {
  saveImage: (
    urlImg: string,
    toggleFormView: () => void,
    openError: (value: boolean) => void,
    setErrorText: (value: string) => void,
    textWithPercentages?: HTMLParagraphElement | null,
    file?: File
  ) => void;
  setErrorText: (error: string) => void;
  toggleFormView: () => void;
  setLogoError: (value: boolean) => void;
};

const useSetFileHandler = ({
  setLogoError,
  saveImage,
  setErrorText,
  toggleFormView,
}: useSetFileHandler) => {
  return useCallback(
    (
      url: string,
      textWithPercentages?: HTMLParagraphElement | null,
      file?: File
    ) => {
      saveImage(
        url,
        toggleFormView,
        setLogoError,
        setErrorText,
        textWithPercentages,
        file
      );
    },
    [saveImage, setErrorText, setLogoError, toggleFormView]
  );
};

export default useSetFileHandler;
