import config from 'config';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';
import BrowserIcon from 'components/icons/BrowserIcon';
import ChevronIcon from 'components/icons/ChevronIcon';
import KeyIcon from 'components/icons/KeyIcon';
import QRIcon from 'components/icons/QRIcon';

import { HorizontalLine } from '../../Divider';
import ActionAgreement from '../ActionAgreement';
import { printPdf } from '../helpers/printPdf';
import { StatusBE } from '../models';
import { Button } from '../styledElements/detailsModalStyledElements';
import {
  ButtonContainer,
  ChooseCountry,
  Header,
  IconWrapper,
  IconWrapperInline,
  InformationContainer,
  InformationContainerFlex,
  InformationItem,
  InformationItemAdd,
  LinkAnchor,
  ScaledVerticalLine,
} from '../styledElements/informationStyledElements';

type CorporateAccountInfo = {
  companyName: string;
  country: string;
};

type Settings = {
  notes?: string;
  terms?: string;
  expiry: string;
  unit?: string;
  redemptions: string;
};

type OfferPageInfo = {
  url: string;
  code: string;
  publicCode: string;
};

type CorporateOfferInformationProps = {
  color: string;
  isChecked: boolean;
  offerStatus: StatusBE;
  corporateAccountInfo?: CorporateAccountInfo;
  settings: Settings;
  offerPageInfo: OfferPageInfo;
  closeModal: () => void;
  activateAccount: () => void;
  toggleStatus: () => void;
};

const CorporateOfferInformation = ({
  color,
  isChecked,
  closeModal,
  offerStatus,
  toggleStatus,
  activateAccount,
  corporateAccountInfo,
  settings: { terms, expiry, unit, redemptions, notes },
  offerPageInfo: { code, publicCode },
}: CorporateOfferInformationProps) => {
  const urlHref = encodeURIComponent(publicCode);
  const [isLoading, setIsLoading] = useState(false);

  const downloadPdf = useCallback(async () => {
    setIsLoading(true);
    await printPdf(publicCode);
    setIsLoading(false);
  }, [publicCode]);

  return (
    <>
      {offerStatus === StatusBE.Suspend ? (
        <div>
          <ActionAgreement
            note="This offer will be activated"
            isChecked={isChecked}
            actionTypeText="YES ACTIVATE THIS OFFER"
            toggleStatus={toggleStatus}
          />
        </div>
      ) : (
        <>
          <InformationContainerFlexOffer>
            <HeaderInfo>Info</HeaderInfo>
            <InfoContainer>
              <AccountInfoItem>
                <span>Account: </span>
                <p>{corporateAccountInfo?.companyName}</p>
              </AccountInfoItem>
              {notes && (
                <>
                  <StyledVerticalLine $color={theme.colors.white} />
                  <NoteInfoItem>
                    <span>Note: </span>
                    <div>
                      <p>{notes}</p>
                    </div>
                  </NoteInfoItem>
                </>
              )}
            </InfoContainer>
          </InformationContainerFlexOffer>
          <HorizontalLine />
          <InformationContainerFlexOffer>
            <HeaderSettings>Settings</HeaderSettings>
            <SettingsContainer>
              <InformationItem>
                <span>Term: </span> <p>{terms}</p>
              </InformationItem>
              <ScaledVerticalLine $color={theme.colors.white} />
              <InformationItem>
                <span>Expiry: </span> <p>{expiry}</p>
              </InformationItem>
              <ScaledVerticalLine $color={theme.colors.white} />
              <InformationItem>
                <span>Unit: </span> <p>{unit}</p>
              </InformationItem>
              <ScaledVerticalLine $color={theme.colors.white} />
              <InformationItem>
                <span>Redemptions: {redemptions}</span>
              </InformationItem>
            </SettingsContainer>
          </InformationContainerFlexOffer>
          <HorizontalLine />
          <InformationContainer>
            <Header>Offer page</Header>
            <br />
            <InformationItemAdd>
              <IconWrapperInline>
                <BrowserIcon />
              </IconWrapperInline>
              <LinkWrapper>
                <LinkAnchor
                  target="_blank"
                  href={`${config.shidoUrl}${urlHref}`}
                >
                  shido.me/{publicCode}
                </LinkAnchor>
              </LinkWrapper>
            </InformationItemAdd>
            <InformationItem $withLetterSpacing>
              <IconWrapper>
                <KeyIcon />
              </IconWrapper>
              <p>{code}</p>
            </InformationItem>
          </InformationContainer>
          <HorizontalLine />
          <AssetsInformationContainer>
            <HeaderAssets>Offer assets</HeaderAssets>
            <br />
            <InformationItem $isSmallFontSize>
              <ChooseCountry>
                <img
                  src="./images/countries/netherlands.svg"
                  alt="Netherlands"
                />
                NL
                <ChevronIcon color={theme.colors.white} />
              </ChooseCountry>
              <p>Printable offer page (.PDF)</p>
            </InformationItem>
            <QrInformationItem $isSmallFontSize>
              <IconWrapper>
                <QRIcon />
              </IconWrapper>
              <p>QR code (.SVG)</p>
            </QrInformationItem>
          </AssetsInformationContainer>
        </>
      )}
      <ButtonContainer $color={color}>
        {offerStatus === StatusBE.Suspend ? (
          <>
            <DefaultButton
              text="activate"
              textColor={color}
              isActive={isChecked}
              withShadow
              buttonColor={theme.colors.white}
              isUppercase
              buttonSize="md"
              borderSize="md"
              clickHandler={activateAccount}
            />
            <Button onClick={closeModal}>cancel</Button>
          </>
        ) : (
          <DefaultButton
            text="Download"
            textColor={theme.colors.funtainBlue}
            isActive={!isLoading}
            withShadow
            buttonColor={theme.colors.white}
            isUppercase
            buttonSize="md"
            borderSize="md"
            clickHandler={downloadPdf}
          />
        )}
      </ButtonContainer>
    </>
  );
};

const StyledVerticalLine = styled(ScaledVerticalLine)`
  position: relative;
  bottom: 3px;
`;

const LinkWrapper = styled.div`
  width: 280px;
  overflow-wrap: break-word;
  padding-right: 10px;
  box-sizing: border-box;
`;

const AccountInfoItem = styled(InformationItem)`
  align-items: flex-start;
`;

const NoteInfoItem = styled(InformationItem)`
  align-items: flex-start;

  div {
    word-break: break-word;
    width: 250px;
  }

  p {
    display: inline;
  }
`;

const HeaderInfo = styled(Header)`
  margin-top: 30px;
`;

const InfoContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 23px;
  position: relative;
  left: -2px;
  display: flex;
  align-items: flex-start;
`;

const HeaderSettings = styled(Header)`
  margin-top: 28px;
  margin-bottom: 9px;
`;

const SettingsContainer = styled.div`
  margin-bottom: 23px;
  position: relative;
  top: 1px;
  right: 2px;
  display: flex;
`;

const HeaderAssets = styled(Header)`
  margin-top: 4px;
`;

const AssetsInformationContainer = styled(InformationContainer)`
  grid-template-columns: repeat(2, 209px);

  svg {
    width: 24px;
    height: 24px;
  }
`;

const QrInformationItem = styled(InformationItem)`
  *:first-child {
    margin-right: 4px;
    width: auto;
  }

  svg {
    margin-top: 2px;
  }
`;

const InformationContainerFlexOffer = styled(InformationContainerFlex)`
  margin: 0;
`;

export default CorporateOfferInformation;
