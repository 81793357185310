import React from 'react';

type GraphDotProps = {
  innerColor: string;
  color: string;
  cx?: number;
  cy?: number;
};

const GraphDot = ({ cx, cy, color, innerColor }: GraphDotProps) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={4}
      stroke={color}
      strokeWidth={1}
      fill={innerColor}
    />
  );
};

export default GraphDot;
