import React from 'react';

type ActiveGraphDotProps = {
  color: string;
  cx?: number;
  cy?: number;
};

const ActiveGraphDot = ({ cx, cy, color }: ActiveGraphDotProps) => {
  return (
    <circle cx={cx} cy={cy} r={6} stroke="white" strokeWidth={2} fill={color} />
  );
};

export default ActiveGraphDot;
