import React from 'react';
import styled from 'styled-components';

type DashedCircleProps = {
  size: number;
  borderColor: string;
};

const DashedCircle = ({ size, borderColor }: DashedCircleProps) => (
  <Circle $size={size} $borderColor={borderColor} />
);

const Circle = styled.div<{ $size: number; $borderColor: string }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: 100;
  border: 2px dashed ${({ $borderColor }) => $borderColor};
`;

export default DashedCircle;
