import React from 'react';

type GooglePlayIconProps = {
  color: string;
};

const GooglePlayIcon = ({ color }: GooglePlayIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="22.842"
    height="21.54"
    viewBox="0 0 22.842 21.54"
  >
    <defs>
      <clipPath id="googleplay-clip-path">
        <path
          id="Path_8772"
          data-name="Path 8772"
          d="M22-13.27l-1.87-.88a.5.5,0,0,0-.55.09l-2.83,2.65a.5.5,0,0,0-.158.365.5.5,0,0,0,.158.365L19.62-8a.5.5,0,0,0,.55.09L22-8.75a2.5,2.5,0,0,0,1.442-2.265A2.5,2.5,0,0,0,22-13.28Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="googleplay-clip-path-2">
        <path
          id="Path_8774"
          data-name="Path 8774"
          d="M2.61-.23,13.73-10.66a.5.5,0,0,0,.158-.365.5.5,0,0,0-.158-.365L2.5-21.77a2.48,2.48,0,0,0-.74.3A2.49,2.49,0,0,0,.6-19.36v16.7A2.49,2.49,0,0,0,1.76-.55,2.48,2.48,0,0,0,2.61-.23Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="googleplay-clip-path-3">
        <path
          id="Path_8776"
          data-name="Path 8776"
          d="M17.79-14.47a.5.5,0,0,0,.154-.445.5.5,0,0,0-.284-.375L5.6-21l9.28,8.58a.5.5,0,0,0,.68,0Z"
          fill={color}
        />
      </clipPath>
      <clipPath id="googleplay-clip-path-4">
        <path
          id="Path_8778"
          data-name="Path 8778"
          d="M15.57-9.69a.5.5,0,0,0-.68,0L5.8-1.16l11.93-5.6a.5.5,0,0,0,.284-.375.5.5,0,0,0-.154-.445Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g
      id="Group_10944"
      data-name="Group 10944"
      transform="translate(-0.6 21.77)"
    >
      <g
        id="Group_10940"
        data-name="Group 10940"
        clipPath="url(#googleplay-clip-path)"
      >
        <path
          id="Path_8771"
          data-name="Path 8771"
          d="M11.592-19.2h16.85V-2.865H11.592Z"
          fill={color}
        />
      </g>
      <g
        id="Group_10941"
        data-name="Group 10941"
        clipPath="url(#googleplay-clip-path-2)"
      >
        <path
          id="Path_8773"
          data-name="Path 8773"
          d="M-4.4-26.77H18.888V4.77H-4.4Z"
          fill={color}
        />
      </g>
      <g
        id="Group_10942"
        data-name="Group 10942"
        clipPath="url(#googleplay-clip-path-3)"
      >
        <path
          id="Path_8775"
          data-name="Path 8775"
          d="M.6-26H22.95V-7.287H.6Z"
          fill={color}
        />
      </g>
      <g
        id="Group_10943"
        data-name="Group 10943"
        clipPath="url(#googleplay-clip-path-4)"
      >
        <path
          id="Path_8777"
          data-name="Path 8777"
          d="M.8-14.823H23.02V3.84H.8Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default GooglePlayIcon;
