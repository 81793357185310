import { AxiosProgressEvent } from 'axios';
import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import { ERROR_IMAGE_MESSAGE } from 'components/actionModal/forms/uploadOfferPageField/UploadOfferPageField';
import { REMOVE_ERROR_TIMER } from 'components/actionModal/models';

import {
  AffiliateOfferPageAttributes,
  AffiliateOfferResponse,
  AffiliateSettingsPageAttributes,
} from '../models';

interface AllAffiliateOfferData extends AffiliateOfferPageAttributes {
  promotionTermId: string;
}

type AffiliateOfferDraftPayload = {
  data: AllAffiliateOfferData;
};

export const createAffiliateOfferDraft = ({
  data,
}: AffiliateOfferDraftPayload) => {
  return axiosClient
    .post(`/api/v${config.apiVersionFive}/AffiliateOffers/CreateDraft`, data)
    .then((response) => response.data.payload.data);
};

type CreateAffiliateSettingsPayload = {
  data: AffiliateSettingsPageAttributes;
  id: string;
};
export const publishAffiliateOffer = ({
  id,
  data,
}: CreateAffiliateSettingsPayload) => {
  return axiosClient
    .put(`/api/v${config.apiVersionFive}/AffiliateOffers/Publish`, {
      id,
      ...data,
    })
    .then((response) => response.data.payload.data);
};

export type AffiliateOffersResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  items: AffiliateOfferResponse[];
};

type PageInfo = {
  page: number;
  pageSize: number;
  sortBy: string;
  search: string;
};

type AffiliateOffersPayload = {
  data: PageInfo;
};

export const getAffiliateOffers = ({
  data,
}: AffiliateOffersPayload): Promise<AffiliateOffersResponse> => {
  return axiosClient
    .post(`/api/v${config.apiVersionFive}/AffiliateOffers/GetPage`, data)
    .then((response) => response.data.payload.data);
};

type EditAffiliateOfferData = {
  affiliateName: string;
  intro: string;
  bodyText: string;
  affiliateHandle: string;
  backgroundColour: string;
  affiliateStatement: string;
};

type EditAffiliateOfferDraftPayload = {
  data: EditAffiliateOfferData;
  offerId: string;
};

export const editAffiliateOffer = ({
  data,
  offerId,
}: EditAffiliateOfferDraftPayload) => {
  return axiosClient
    .put(`/api/v${config.apiVersionFive}/AffiliateOffers/${offerId}`, data)
    .then((response) => response.data.payload.data);
};

type DeleteAffiliateOfferPayload = {
  affiliateOfferId: string;
};

export const deleteAffiliateOffer = ({
  affiliateOfferId,
}: DeleteAffiliateOfferPayload) =>
  axiosClient
    .delete(
      `/api/v${config.apiVersionFive}/AffiliateOffers/${affiliateOfferId}`
    )
    .then((response) => response.data.payload);

type ImageDraftPayloadAffiliate = {
  id: string;
  options: {
    onUploadProgress: (progress: AxiosProgressEvent) => void;
  };
  formData: FormData;
  openError: (value: boolean) => void;
  setErrorText: (value: string) => void;
  toggleFormView: () => void;
};

export const uploadAffiliateImage = async ({
  id,
  options,
  formData,
  openError,
  setErrorText,
  toggleFormView,
}: ImageDraftPayloadAffiliate) => {
  try {
    const response = await axiosClient.put(
      `/api/v${config.apiVersionFive}/AffiliateOffers/${id}/UploadImage`,
      formData,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    toggleFormView();

    return response.data.payload;
  } catch {
    openError(true);
    setErrorText(ERROR_IMAGE_MESSAGE);

    setTimeout(() => {
      openError(false);
    }, REMOVE_ERROR_TIMER);
  }
};
