import { isAfter } from 'date-fns';

import { AffiliateOfferResponse } from 'modules/offers/models';

import {
  AffiliateOffersDashboardGroup,
  AffiliateOffersGroupBy,
  groupAffiliateOffers,
} from './groupOffers';

const descSort = (
  aGroup: AffiliateOffersDashboardGroup,
  bGroup: AffiliateOffersDashboardGroup
) => bGroup.offers.length - aGroup.offers.length;

const getAffiliateOffersStats = (offers: AffiliateOfferResponse[]) => {
  const now = new Date();
  const thisYear = new Date(now.getFullYear(), 0, 1);
  const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  return {
    conversionFunnels: [
      {
        name: 'All Time',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.ConversionFunnel,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.ConversionFunnel,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.ConversionFunnel,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisMonth)
          )
        ).sort(descSort),
      },
    ],
    bgColors: [
      {
        name: 'All Time',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.BackgroundColor,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.BackgroundColor,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.BackgroundColor,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisMonth)
          )
        ).sort(descSort),
      },
    ],
    promotionTerms: [
      {
        name: 'All Time',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.PromotionTerm,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.PromotionTerm,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupAffiliateOffers(
          AffiliateOffersGroupBy.PromotionTerm,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDateUtc), thisMonth)
          )
        ).sort(descSort),
      },
    ],
  };
};

export type AffiliateOffersStatName = keyof ReturnType<
  typeof getAffiliateOffersStats
>;

export const getActiveAffiliateOffersStats = (
  stats: ReturnType<typeof getAffiliateOffersStats>,
  activeParams: Record<AffiliateOffersStatName, number>
): Record<AffiliateOffersStatName, AffiliateOffersDashboardGroup[]> => ({
  bgColors: stats.bgColors[activeParams.bgColors].groups,
  promotionTerms: stats.promotionTerms[activeParams.promotionTerms].groups,
  conversionFunnels:
    stats.conversionFunnels[activeParams.conversionFunnels].groups,
});

export default getAffiliateOffersStats;
