import { format } from 'date-fns';

import {
  AffiliateOfferResponse,
  ConversionFunnelBE,
  PromotionOfferResponse,
} from 'modules/offers/models';
import { CorporateOffer } from 'modules/offers/offersServices/CorporateOffersService';

export type AffiliateOffersDashboardGroup = {
  name: string;
  offers: AffiliateOfferResponse[];
};

export enum AffiliateOffersGroupBy {
  ConversionFunnel = 'CONVERSION_FUNNEL',
  BackgroundColor = 'BACKGROUND_COLOR',
  PromotionTerm = 'PROMOTION_TERM',
}

export const groupAffiliateOffers = (
  groupBy: AffiliateOffersGroupBy,
  affiliateOffers: AffiliateOfferResponse[]
) =>
  affiliateOffers.reduce<AffiliateOffersDashboardGroup[]>((groups, offer) => {
    let groupName: string;

    switch (groupBy) {
      case AffiliateOffersGroupBy.PromotionTerm:
        groupName = offer.promotionTerm.termDescription;
        break;
      case AffiliateOffersGroupBy.BackgroundColor:
        groupName = offer.backgroundColour.replace(/([A-Z])/g, ' $1');
        break;
      case AffiliateOffersGroupBy.ConversionFunnel:
      default:
        if (!offer.conversionFunnel) return groups;
        groupName =
          offer.conversionFunnel === ConversionFunnelBE.App
            ? 'QR Code'
            : 'Stripe Checkout';
        break;
    }

    const offerGroup = groups.find((fGroup) => fGroup.name === groupName);

    if (!offerGroup) {
      return [...groups, { name: groupName, offers: [offer] }];
    }

    offerGroup.offers.push(offer);

    return groups;
  }, []);

export type CorporateOffersDashboardGroup = {
  name: string;
  offers: CorporateOffer[];
};

export enum CorporateOffersGroupBy {
  AccountCountry = 'ACCOUNT_COUNTRY',
  OfferTerm = 'OFFER_TERM',
  CreatedDateMonth = 'CREATED_DATE_MONTH',
  CreatedDateYear = 'CREATED_DATE_YEAR',
  CreatedDateAllTime = 'CREATED_DATE_ALL_TIME',
}

export const groupCorporateOffers = (
  groupBy: CorporateOffersGroupBy,
  corporateOffers: CorporateOffer[]
) =>
  corporateOffers.reduce<CorporateOffersDashboardGroup[]>((groups, offer) => {
    let groupName: string;

    switch (groupBy) {
      case CorporateOffersGroupBy.CreatedDateMonth:
        groupName = format(new Date(offer.createdDate), 'EEEE do');
        break;
      case CorporateOffersGroupBy.CreatedDateYear:
        groupName = format(new Date(offer.createdDate), 'MMMM');
        break;
      case CorporateOffersGroupBy.CreatedDateAllTime:
        groupName = format(new Date(offer.createdDate), 'yyyy');
        break;
      case CorporateOffersGroupBy.OfferTerm:
        groupName = offer.offerTerm.replace(/([A-Z])/g, ' $1');
        break;
      case CorporateOffersGroupBy.AccountCountry:
      default:
        groupName = offer.corporateAccount.country.replace(/([A-Z])/g, ' $1');
        break;
    }

    const offerGroup = groups.find((fGroup) => fGroup.name === groupName);

    if (!offerGroup) {
      return [...groups, { name: groupName, offers: [offer] }];
    }

    offerGroup.offers.push(offer);

    return groups;
  }, []);

export type PromotionOffersDashboardGroup = {
  name: string;
  offers: PromotionOfferResponse[];
};

export enum PromotionOffersGroupBy {
  BackgroundColor = 'BACKGROUND_COLOR',
  CreatedDateMonth = 'CREATED_DATE_MONTH',
  CreatedDateYear = 'CREATED_DATE_YEAR',
  CreatedDateAllTime = 'CREATED_DATE_ALL_TIME',
  PromotionTerm = 'PROMOTION_TERM',
}

export const groupPromotionOffers = (
  groupBy: PromotionOffersGroupBy,
  promotionOffers: PromotionOfferResponse[]
) =>
  promotionOffers.reduce<PromotionOffersDashboardGroup[]>((groups, offer) => {
    let groupName: string;

    switch (groupBy) {
      case PromotionOffersGroupBy.CreatedDateMonth:
        groupName = format(new Date(offer.createdDateUtc), 'EEEE do');
        break;
      case PromotionOffersGroupBy.CreatedDateYear:
        groupName = format(new Date(offer.createdDateUtc), 'MMMM');
        break;
      case PromotionOffersGroupBy.CreatedDateAllTime:
        groupName = format(new Date(offer.createdDateUtc), 'yyyy');
        break;
      case PromotionOffersGroupBy.PromotionTerm:
        groupName = offer.promotionTerm.termDescription;
        break;
      case PromotionOffersGroupBy.BackgroundColor:
      default:
        groupName = offer.backgroundColour.replace(/([A-Z])/g, ' $1');
        break;
    }

    const offerGroup = groups.find((fGroup) => fGroup.name === groupName);

    if (!offerGroup) {
      return [...groups, { name: groupName, offers: [offer] }];
    }

    offerGroup.offers.push(offer);

    return groups;
  }, []);
