import React from 'react';
import { PatternFormat } from 'react-number-format';
import theme from 'theme';

import {
  ButtonContainer,
  Header,
  IconWrapper,
  InformationContainer,
  InformationItem,
} from 'components/detailsModal/styledElements/informationStyledElements';
import { HorizontalLine } from 'components/Divider';
import EmailIcon from 'components/icons/EmailIcon';
import MobileIcon from 'components/icons/MobileIcon';
import { countriesCode } from 'components/listForSelectingData/dataForLists';

import DefaultButton from '../../buttons/DefaultButton';
import ActionAgreement from '../ActionAgreement';
import { StatusBE } from '../models';
import { Button } from '../styledElements/detailsModalStyledElements';

type Validation = {
  bankValidation: string;
  agreementsValidation: string;
};

type Commission = {
  thisMonthCommission: number;
  allTimeCommission: number;
};

type ReferralsInAccount = {
  primaryReferralsCount?: number;
  downlineReferralsCount?: number;
};

type PrimaryContact = {
  email: string;
  mobile: {
    code: string;
    number: string;
  };
};

type ReferrerAccountInformationProps = {
  color: string;
  isChecked: boolean;
  referralsInAccount: ReferralsInAccount;
  validation: Validation;
  commission: Commission;
  accountStatus: StatusBE;
  primaryContact: PrimaryContact;
  closeModal: () => void;
  activateAccount: () => void;
  toggleStatus: () => void;
};

const ReferrerAccountInformation = ({
  color,
  isChecked,
  closeModal,
  accountStatus,
  toggleStatus,
  activateAccount,
  referralsInAccount: { primaryReferralsCount, downlineReferralsCount },
  validation: { bankValidation, agreementsValidation },
  commission: { thisMonthCommission, allTimeCommission },
  primaryContact: { email, mobile },
}: ReferrerAccountInformationProps) => (
  <>
    {accountStatus === StatusBE.Suspend ? (
      <div>
        <ActionAgreement
          note="Commission payments will be activated"
          isChecked={isChecked}
          actionTypeText="YES ACTIVATE THIS ACCOUNT"
          toggleStatus={toggleStatus}
        />
      </div>
    ) : (
      <>
        <InformationContainer>
          <Header>Primary contact</Header>
          <br />
          <InformationItem>
            <IconWrapper>
              <MobileIcon color={theme.colors.white} width={10} height={16} />
            </IconWrapper>
            {mobile.code}&nbsp;
            <PatternFormat
              format={countriesCode[1].format}
              value={mobile.number}
              renderText={(value: string) => <>{value}</>}
              displayType="text"
            />
          </InformationItem>
          <InformationItem>
            <IconWrapper>
              <EmailIcon />
            </IconWrapper>
            {email}
          </InformationItem>
        </InformationContainer>
        <HorizontalLine />
        <InformationContainer>
          <Header>Referrals</Header>
          <br />
          <InformationItem>
            <span>Primary:</span> {primaryReferralsCount}
          </InformationItem>
          <InformationItem>
            <span>Downline:</span> {downlineReferralsCount}
          </InformationItem>
        </InformationContainer>
        <HorizontalLine />
        <InformationContainer>
          <Header>Commission</Header>
          <br />
          <InformationItem>
            <span>This month:</span> €{thisMonthCommission}
          </InformationItem>
          <InformationItem>
            <span>All time:</span> €{allTimeCommission}
          </InformationItem>
        </InformationContainer>
        <HorizontalLine />
        <InformationContainer>
          <Header>Validation</Header>
          <br />
          <InformationItem>
            <span>Bank:</span> {bankValidation}
          </InformationItem>
          <InformationItem>
            <span>Agreements:</span> {agreementsValidation}
          </InformationItem>
        </InformationContainer>
      </>
    )}
    <ButtonContainer $color={color}>
      {accountStatus === StatusBE.Suspend ? (
        <>
          <DefaultButton
            text="activate"
            textColor={color}
            isActive={isChecked}
            withShadow
            buttonColor={theme.colors.white}
            isUppercase
            buttonSize="md"
            borderSize="md"
            clickHandler={activateAccount}
          />
          <Button onClick={closeModal}>cancel</Button>
        </>
      ) : (
        <>
          <DefaultButton
            text="activate"
            textColor={theme.colors.funtainBlue}
            isActive
            withShadow
            buttonColor={theme.colors.white}
            isUppercase
            buttonSize="md"
            borderSize="md"
            clickHandler={() => null}
          />
          <Button>manage referrals</Button>
        </>
      )}
    </ButtonContainer>
  </>
);

export default ReferrerAccountInformation;
