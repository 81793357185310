import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Navbar from 'components/navbar/Navbar';
import { NavbarLabel, EmployerRoutes } from 'modules/router/models';

const NAVBAR_ITEMS = [
  {
    path: `../${EmployerRoutes.Insights}`,
    label: NavbarLabel.Insights,
  },
  {
    path: `../${EmployerRoutes.Events}`,
    label: NavbarLabel.Events,
  },
  {
    path: `../${EmployerRoutes.Content}`,
    label: NavbarLabel.Content,
  },
  {
    path: `../${EmployerRoutes.Onboarding}`,
    label: NavbarLabel.Onboarding,
  },
  {
    path: `../${EmployerRoutes.Delegates}`,
    label: NavbarLabel.Delegates,
  },
];

type Props = {
  children: ReactNode;
};

const EmployersPrivateRoute = ({ children }: Props) => {
  return (
    <Container>
      <Navbar employerHeader linksArray={NAVBAR_ITEMS} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.lightYellow};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export default EmployersPrivateRoute;
