import React from 'react';

type DashedCircleIconProps = {
  size: number;
  color: string;
};

const DashedCircleIconV1 = ({ size, color }: DashedCircleIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 185.439 185.439"
  >
    <g
      id="Group_5192"
      data-name="Group 5192"
      transform="translate(184.439 1) rotate(90)"
    >
      <path
        id="Path_688"
        data-name="Path 688"
        d="M91.72,0A91.72,91.72,0,1,1,0,91.72,91.72,91.72,0,0,1,91.72,0Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeDasharray="5 25 80"
      />
    </g>
  </svg>
);

export default DashedCircleIconV1;
