import React from 'react';

const DEFAULT_WIDTH = 16;
const DEFAULT_HEIGHT = 23;

type MobileIconProps = {
  color: string;
  width?: number;
  height?: number;
};

const MobileIcon = ({ color, width, height }: MobileIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width ? width : DEFAULT_WIDTH}
    height={height ? height : DEFAULT_HEIGHT}
    viewBox="0 0 16 23.932"
  >
    <defs>
      <clipPath id="mobile-clip-path">
        <path
          id="Path_6906"
          data-name="Path 6906"
          d="M13-23.966H3a3,3,0,0,0-3,3V-3.034a3,3,0,0,0,3,3H13a3,3,0,0,0,3-3V-20.966A3,3,0,0,0,13-23.966ZM8-1.534a1,1,0,0,1-1-1,1,1,0,0,1,1-1,1,1,0,0,1,1,1A1,1,0,0,1,8-1.534Zm6-4.5a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V-20.466a1,1,0,0,1,1-1H13a1,1,0,0,1,1,1Z"
          fill={color}
        />
      </clipPath>
    </defs>
    <g id="Group_6228" data-name="Group 6228" transform="translate(0 23.966)">
      <g
        id="Group_6227"
        data-name="Group 6227"
        clipPath="url(#mobile-clip-path)"
      >
        <path
          id="Path_6905"
          data-name="Path 6905"
          d="M-5-28.966H21V4.966H-5Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default MobileIcon;
