import { QueryFunctionContext, useQuery } from 'react-query';

import { QueryKey } from '../models';
import { CorporateOffersResponse } from '../offersServices/CorporateOffersService';

const useGetCorporateOffers = (
  queryKeys: (string | number)[],
  queryFn: (data: QueryFunctionContext) => Promise<CorporateOffersResponse>
) =>
  useQuery([QueryKey.GetCorporateOffers, ...queryKeys], queryFn, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

export default useGetCorporateOffers;
