import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from '../../../icons/LogoIcon';

type LogoProps = {
  width: number;
  height: number;
  withMark?: boolean;
};

const Logo = ({ width, height, withMark }: LogoProps) => {
  return (
    <Wrapper>
      <Link href="https://www.shido.com/">
        <LogoIcon width={width} height={height} />
        {withMark && (
          <RegisterSymbolContainer>
            <RegisterSymbol>®</RegisterSymbol>
          </RegisterSymbolContainer>
        )}
      </Link>
    </Wrapper>
  );
};

const RegisterSymbolContainer = styled.span`
  position: relative;
`;

const RegisterSymbol = styled.span`
  font-weight: 600;
  font-size: 14px;
  position: absolute;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const Link = styled.a`
  cursor: pointer;
`;

export default Logo;
