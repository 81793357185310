import React, { createContext, FC, PropsWithChildren, useState } from 'react';

type ModalContextType = {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
};

const initialValue: ModalContextType = {
  isModalOpen: false,
  setModalOpen: () => null,
};

export const ModalContext = createContext<ModalContextType>(initialValue);

const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setModalOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
