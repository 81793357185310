/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { areEqual } from 'react-window';
import styled from 'styled-components';

import CheckIcon from 'components/icons/CheckIcon';

import { EditableCourseType } from '../../models';

export type SelectTopicItemProps = {
  index: number;
  style: React.CSSProperties;
  data: {
    items: EditableCourseType[];
    toggleItemActive: (id: string) => void;
  };
};

const SelectCourseItem = ({ data, index, style }: any) => {
  const { items, toggleItemActive } = data;
  const item = items[Number(index)] as EditableCourseType;

  return (
    <div
      style={{
        ...style,
        left: '20px',
        width: '95%',
      }}
    >
      <Container>
        <InfoContainer>
          <div>
            <Title>
              {item.title} - {item.lessonNr} lessons
            </Title>
            {!!item.description && (
              <Items>
                <span>Description: </span>
                <p>{item.description}</p>
              </Items>
            )}
            <Items>
              <span>Length: </span>
              <p>{item.courseLength}</p>
            </Items>
          </div>
        </InfoContainer>
        <CheckContainer onClick={() => toggleItemActive(item.id)}>
          {item.isChosen && <CheckIcon />}
        </CheckContainer>
      </Container>
    </div>
  );
};

const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
`;

const Items = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  top: 2px;

  span {
    font-size: 12px;
    opacity: 0.75;
  }

  p {
    font-size: 12px;
    opacity: 0.75;
    margin-top: 5px;
    font-weight: 700;
    margin-left: 2px;
  }
`;

const CheckContainer = styled.div`
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: relative;
  top: -1px;
`;

const Container = styled.div`
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  padding: 10px 20px 10px 10px;
  box-shadow: 0 8px 15px 1px rgb(0 0 0 / 20%);
  border-radius: 4px;
  position: relative;
  margin-top: 20px;
`;

export default memo(SelectCourseItem, areEqual);
