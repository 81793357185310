import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export type ProgressBarProps = {
  color: string;
  percent: number;
  isVisible: boolean;
};

const ProgressBar = ({ color, percent, isVisible }: ProgressBarProps) => {
  const [localProcess, setLocalProcess] = useState(0);

  useEffect(() => {
    if (percent && isVisible) {
      setLocalProcess(percent <= 100 ? percent : 100);
    }
  }, [percent, isVisible]);

  return (
    <ParentProgressBarElement>
      <ChildProgressBarElement
        data-testid="child-progress-bar"
        $color={color}
        $percent={localProcess}
      />
    </ParentProgressBarElement>
  );
};

const ParentProgressBarElement = styled.div`
  height: 8px;
  width: 100%;
  border-radius: 40px;
  background: #dfdfda;
`;

const ChildProgressBarElement = styled.div<{
  $percent: number;
  $color: string;
}>`
  height: 100%;
  width: ${({ $percent }) => $percent}%;
  background: ${({ $color }) => $color};
  transition: width 1s ease-in-out;
  border-radius: 40px;
  box-shadow: -1px 2px 5px ${({ theme }) => theme.colors.shadowColor};
`;

export default ProgressBar;
