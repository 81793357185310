import React, { useCallback, useContext, useState } from 'react';

import SearchBar from 'components/searchBar/SearchBar';
import { cleanAccountLocalStorage } from 'helpers/connectToLocalStorage';

import { ReferrerAccountsContext } from '../../context/ReferrerAccountsContext';
import { ReferrerAccount } from '../../models';
import CreateAccountModal from '../CreateAccountModal';

import EditReferrerModal from './EditReferrerModal';
import ReferrerTable from './ReferrerTable';

const ReferrerAccounts = () => {
  const [nameForSearching, setNameForSearching] = useState('');
  const [sortByListItemIndex, setSortByListItemIndex] = useState(0);
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const { deleteAccount, accounts } = useContext(ReferrerAccountsContext);
  const [accountForEditing, setAccountForEditing] = useState<ReferrerAccount>();

  const toggleCreatingModalWindow = useCallback(() => {
    setIsCreatingModalOpen(!isCreatingModalOpen);
    cleanAccountLocalStorage();
  }, [isCreatingModalOpen]);

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen(!isEditingModalOpen);
    cleanAccountLocalStorage();
  }, [isEditingModalOpen]);

  return (
    <>
      <SearchBar
        title="Accounts:"
        buttonText="Create account"
        nameToFilter={nameForSearching}
        openModalWindow={toggleCreatingModalWindow}
        activeFilterParameter="Referrer"
        setNewNameToFilter={setNameForSearching}
      />
      <ReferrerTable
        data={accounts}
        sortAccounts={setSortByListItemIndex}
        sortByListItemIndex={sortByListItemIndex}
        setDataForEditing={setAccountForEditing}
        deleteItem={deleteAccount}
        openEditingModalWindow={toggleEditingModalWindow}
      />
      {isCreatingModalOpen && (
        <CreateAccountModal closeModalWindow={toggleCreatingModalWindow} />
      )}
      {isEditingModalOpen && accountForEditing && (
        <EditReferrerModal
          account={accountForEditing}
          closeModalWindow={toggleEditingModalWindow}
        />
      )}
    </>
  );
};

export default ReferrerAccounts;
