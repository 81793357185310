import React, { useState } from 'react';
import styled from 'styled-components';

import TeamDemographics from '../modules/demographics/components/TeamDemographics';
import TeamGoals from '../modules/goals/components/TeamGoals';

import DashboardMonthNavigation from './DashboardMonthNavigation';
import TeamHealth from './TeamHealth';

const months = ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const InsightsDashboard = () => {
  const [activeMonth, setActiveMonth] = useState(months[0]);

  return (
    <>
      <Container>
        <DashboardMonthNavigation
          months={months}
          activeMonth={activeMonth}
          setMonth={setActiveMonth}
        />
        <ContentWrapper>
          <TeamHealth activeMonth={activeMonth} />
          <TeamDemographics />
          <TeamGoals />
        </ContentWrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 1326px;
  margin: auto;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  border-radius: 5px;
  min-height: 100vh;
  background: white;
`;

export default InsightsDashboard;
