import { compareAsc, isAfter } from 'date-fns';

import { CorporateOffer } from 'modules/offers/offersServices/CorporateOffersService';

import {
  CorporateOffersDashboardGroup,
  CorporateOffersGroupBy,
  groupCorporateOffers,
} from '../helpers/groupOffers';

const descSort = (
  aGroup: CorporateOffersDashboardGroup,
  bGroup: CorporateOffersDashboardGroup
) => bGroup.offers.length - aGroup.offers.length;

const offerDateSort = (aOffer: CorporateOffer, bOffer: CorporateOffer) =>
  compareAsc(new Date(aOffer.createdDate), new Date(bOffer.createdDate));

const getCorporateOffersStats = (offers: CorporateOffer[]) => {
  const now = new Date();
  const thisYear = new Date(now.getFullYear(), 0, 1);
  const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  return {
    countries: [
      {
        name: 'All Time',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.AccountCountry,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.AccountCountry,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDate), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.AccountCountry,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDate), thisMonth)
          )
        ).sort(descSort),
      },
    ],
    offerTerms: [
      {
        name: 'All Time',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.OfferTerm,
          offers
        ).sort(descSort),
      },
      {
        name: 'This Year',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.OfferTerm,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDate), thisYear)
          )
        ).sort(descSort),
      },
      {
        name: 'This Month',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.OfferTerm,
          offers.filter((offer) =>
            isAfter(new Date(offer.createdDate), thisMonth)
          )
        ).sort(descSort),
      },
    ],
    createdDates: [
      {
        name: 'All Time',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.CreatedDateAllTime,
          [...offers].sort(offerDateSort)
        ),
      },
      {
        name: 'This Year',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.CreatedDateYear,
          offers
            .filter((offer) => isAfter(new Date(offer.createdDate), thisYear))
            .sort(offerDateSort)
        ),
      },
      {
        name: 'This Month',
        groups: groupCorporateOffers(
          CorporateOffersGroupBy.CreatedDateMonth,
          offers
            .filter((offer) => isAfter(new Date(offer.createdDate), thisMonth))
            .sort(offerDateSort)
        ),
      },
    ],
  };
};

export type CorporateOffersStatName = keyof ReturnType<
  typeof getCorporateOffersStats
>;

export const getActiveCorporateOffersStats = (
  stats: ReturnType<typeof getCorporateOffersStats>,
  activeParams: Record<CorporateOffersStatName, number>
): Record<CorporateOffersStatName, CorporateOffersDashboardGroup[]> => ({
  countries: stats.countries[activeParams.countries].groups,
  createdDates: stats.createdDates[activeParams.createdDates].groups,
  offerTerms: stats.offerTerms[activeParams.offerTerms].groups,
});

export default getCorporateOffersStats;
