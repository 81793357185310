import React from 'react';
import styled from 'styled-components';

import CheckedCircleIcon from 'components/icons/CheckedCircleIcon';

type NavigationItemProps = {
  text: string;
  active: boolean;
  chooseActiveItem: () => void;
};

const NavigationItem = ({
  text,
  active,
  chooseActiveItem,
}: NavigationItemProps) => (
  <Container onClick={chooseActiveItem}>
    {active ? <CheckedCircleIcon /> : <EmptyCircle />}
    <NavigationLabel>{text}</NavigationLabel>
  </Container>
);

const Container = styled.div`
  display: flex;
  column-gap: 10px;
  height: 24px;
  align-items: center;
  cursor: pointer;

  &:nth-child(1) {
    padding-right: 20px;
  }

  &:nth-child(n + 2) {
    padding-left: 20px;
  }
`;

const EmptyCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  background: transparent;
`;

const NavigationLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.funtainBlue};
  font-weight: bold;
  text-transform: uppercase;
`;

export default NavigationItem;
